/**
 * @generated SignedSource<<ba97ba5aa863915303fc086ef5ffd1a5>>
 * @relayHash 75df3a889411cf01f6f98d6bdf3e84a3
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5da67a5126cf0c6b5843a5ce3d2d3a0e38e870e313027bc869a92cacfb87cee7

import type { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type onCalendarIssueDeletedSubscription$variables = {
  cloudId: string;
  projectIds: ReadonlyArray<string>;
};
export type onCalendarIssueDeletedSubscription$data = {
  readonly jira: {
    readonly onCalendarIssueDeleted: {
      readonly resource: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type onCalendarIssueDeletedSubscription = {
  response: onCalendarIssueDeletedSubscription$data;
  variables: onCalendarIssueDeletedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectIds"
  }
],
v1 = [
  {
    "concreteType": "JiraSubscription",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
          },
          {
            "kind": "Variable",
            "name": "projectIds",
            "variableName": "projectIds"
          }
        ],
        "concreteType": "JiraStreamHubResourceIdentifier",
        "kind": "LinkedField",
        "name": "onCalendarIssueDeleted",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "resource"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "onCalendarIssueDeletedSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "onCalendarIssueDeletedSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "5da67a5126cf0c6b5843a5ce3d2d3a0e38e870e313027bc869a92cacfb87cee7",
    "metadata": {},
    "name": "onCalendarIssueDeletedSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "348a0bbcf5d984ddf7f0c334edc2ddca";

export default node;
