import React, { type UIEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from '@atlassian/jira-servicedesk-queues-common/src/view/messages.tsx';
import { fg } from '@atlassian/jira-feature-gating';

const getIssueNavUrl = (projectKey: string | undefined, queueJql: string) =>
	projectKey
		? `/jira/servicedesk/projects/${projectKey}/issues/?jql=${encodeURIComponent(queueJql)}`
		: `/issues/?jql=${encodeURIComponent(queueJql)}`;

export type Props = {
	projectKey?: string;
	queueJql: string;
};

export const OpenInIssueNavButton = (_props: Props) => {
	const { projectKey, queueJql } = _props;
	const issueNavUrl = getIssueNavUrl(projectKey, queueJql);
	const { formatMessage } = useIntl();

	const onClick = (_: UIEvent<HTMLElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'navigateToIssueNavFromQueues', {
			hasProjectKey: typeof projectKey === 'string',
		});
	};

	return (
		<Tooltip
			content={formatMessage(
				fg('itsm-issue-renaming')
					? messages.openWithIssueNavIssueTermRefresh
					: messages.openWithIssueNav,
			)}
		>
			<Button
				testId="servicedesk-queues-agent-view.layout.header.actions.open-in-issue-nav-button.open-in-issue-nav-button"
				href={issueNavUrl}
				target="_blank"
				onClick={onClick}
				iconAfter={<ShortcutIcon label="" size="medium" />}
				appearance="subtle"
			/>
		</Tooltip>
	);
};
