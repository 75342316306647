import React, { useEffect, memo, useState, useCallback, type FormEvent, useMemo } from 'react';
import differenceWith from 'lodash/differenceWith';
import noop from 'lodash/noop';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import type { OptionsType, OptionType } from '@atlaskit/select';
import { JiraProjectAri } from '@atlassian/ari/jira/project';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { jwmMultiProjectPickerEntrypoint } from '@atlassian/jira-multi-project-picker/entrypoint.tsx';
import type { PickedProject } from '@atlassian/jira-project-picker/src/model/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import type { connectedProjectsPicker_calendarQuery } from '@atlassian/jira-relay/src/__generated__/connectedProjectsPicker_calendarQuery.graphql';
import { UpsellBanner } from '@atlassian/jira-shared-software-dates/src/ui/upsell-banner/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { TEAM_NAME } from '../../../../common/constants.tsx';
import type { ConnectedProjectProxy } from '../../../../common/types.tsx';
import { useCalendarRefetchApiRef } from '../../../../controllers/calendar-store/index.tsx';
import { useConnectExternalProjectMutation } from '../../../../controllers/use-connect-external-project-mutation/index.tsx';
import { useDisconnectExternalProjectMutation } from '../../../../controllers/use-disconnect-external-project-mutation/index.tsx';
import { useLogger } from '../../../../controllers/use-logger/index.tsx';
import messages from './messages.tsx';

const CONTROL_ID = 'connected-projects-picker';

const PROJECT_SEARCH_PARAMS = {
	action: 'browse',
	typeKey: SOFTWARE_PROJECT,
} as const;

export const ENTRYPOINT_ID = 'jira.calendar.view-settings.connected-projects-picker-entrypoint';

const MAX_CONNECTED_PROJECTS = 5;

export const DEFAULT_RUNTIME_PROPS = {
	id: CONTROL_ID,
	name: CONTROL_ID,
	maxCount: MAX_CONNECTED_PROJECTS,
	projectSearchParams: PROJECT_SEARCH_PARAMS,
	isRequired: false,
	isDisabled: false,
	isInvalid: false,
	onBlur: noop,
	onFocus: noop,
	'aria-invalid': 'false' as const,
	'aria-labelledby': '',
};

const transformOptionToPickedProject = (
	option: OptionType,
	cloudId: string,
): PickedProject & { id: string } => {
	const projectAri = JiraProjectAri.create({
		siteId: cloudId,
		projectId: String(option.value),
	}).toString();

	return {
		name: option.label,
		value: String(option.value),
		avatarUrl: option.avatar || '',
		id: projectAri,
	};
};

const transformOptionToConnectedProjectProxy = (
	cloudId: string,
	projectToConnect: PickedProject,
): ConnectedProjectProxy => ({
	id: projectToConnect.value,
	name: projectToConnect.name,
	avatarUrl: projectToConnect.avatarUrl,
	resourceId: JiraProjectAri.create({
		siteId: cloudId,
		projectId: projectToConnect.value,
	}),
});

export const ConnectedProjectsPicker = memo(() => {
	const { data: project, loading, error } = useProjectContext();
	const { logError } = useLogger();
	if (loading) {
		return <UFOLoadHold name="calendar-connected-project-context" />;
	}

	if (error || !project) {
		const errorMessage = 'Project data is unavailable.';
		logError(`calendar.${CONTROL_ID}.error`, errorMessage, error);
		throw new Error(errorMessage);
	}

	const projectId = String(project.projectId);

	return (
		<ConnectedProjectsPickerEntryPoint
			projectId={projectId}
			isProjectAdmin={project.isProjectAdmin}
		/>
	);
});

const ConnectedProjectsPickerEntryPoint = memo(
	({ projectId, isProjectAdmin }: { projectId: string; isProjectAdmin: boolean }) => {
		const { formatMessage } = useIntl();
		const cloudId = useCloudId();

		const projectAri = JiraProjectAri.create({
			siteId: cloudId,
			projectId,
		}).toString();

		const connectedProjects = useLazyLoadQuery<connectedProjectsPicker_calendarQuery>(
			graphql`
				query connectedProjectsPicker_calendarQuery($projectAri: ID!) {
					graphStore @optIn(to: "GraphStore") @required(action: THROW) {
						projectHasRelatedWorkWithProject(id: $projectAri)
							@optIn(to: "GraphStoreProjectHasRelatedWorkWithProject")
							@required(action: THROW) {
							edges @required(action: THROW) {
								node @required(action: THROW) {
									... on JiraProject {
										id
										projectId @required(action: THROW)
										name @required(action: THROW)
										avatar {
											large
										}
									}
								}
							}
						}
					}
				}
			`,
			{ projectAri },
		);
		const initiallySelectedProjects =
			connectedProjects.graphStore.projectHasRelatedWorkWithProject.edges.map((edge) => ({
				value: edge?.node.projectId || '',
				label: edge?.node.name || '',
				avatar: edge?.node.avatar?.large || '',
				id: edge?.node.id || '',
			})) || [];

		const [selectedProjects, setSelectedProjects] =
			useState<OptionsType>(initiallySelectedProjects);

		const { connectProject } = useConnectExternalProjectMutation(projectAri);
		const { disconnectProject } = useDisconnectExternalProjectMutation(projectAri);

		const refetchApiRef = useCalendarRefetchApiRef();

		const onProjectConnect = useCallback(
			(toProject: ConnectedProjectProxy) => {
				connectProject(toProject);
			},
			[connectProject],
		);

		const onProjectDisconnect = useCallback(
			(pickedProject: PickedProject) => {
				disconnectProject([
					JiraProjectAri.create({
						siteId: cloudId,
						projectId: pickedProject.value,
					}).toString(),
				]);
			},
			[disconnectProject, cloudId],
		);

		const onDisconnectAllProjects = useCallback(() => {
			const allProjectAris = selectedProjects.map((project) =>
				JiraProjectAri.create({
					siteId: cloudId,
					projectId: String(project.value),
				}).toString(),
			);
			disconnectProject(allProjectAris);
			setSelectedProjects([]);
		}, [cloudId, disconnectProject, selectedProjects]);

		useEffect(() => {
			refetchApiRef?.current?.refetchVersionsWithoutSuspense?.(
				selectedProjects.map((project) => project.id),
			);
		}, [refetchApiRef, selectedProjects]);

		const onProjectsSelectionChange = useCallback(
			(newlySelectedProjects: OptionsType | FormEvent<HTMLInputElement>) => {
				setSelectedProjects((previouslySelectedProjects) => {
					if (!Array.isArray(newlySelectedProjects)) {
						return previouslySelectedProjects;
					}

					if (previouslySelectedProjects.length > 1 && !newlySelectedProjects.length) {
						onDisconnectAllProjects();
						return [];
					}

					if (previouslySelectedProjects.length > newlySelectedProjects.length) {
						const [removedProject] = differenceWith(
							previouslySelectedProjects,
							newlySelectedProjects,
							(projectA, projectB) => projectA.value === projectB.value,
						);

						onProjectDisconnect(transformOptionToPickedProject(removedProject, cloudId));

						const filteredProjects = previouslySelectedProjects.filter(
							(project) => project.value !== removedProject.value,
						);

						return filteredProjects;
					}

					const [projectToConnect] = differenceWith<OptionType, OptionType>(
						newlySelectedProjects,
						previouslySelectedProjects,
						(projectA, projectB) => projectA.value === projectB.value,
					);

					if (!projectToConnect) {
						return previouslySelectedProjects;
					}

					const pickedProject = transformOptionToPickedProject(projectToConnect, cloudId);

					onProjectConnect(transformOptionToConnectedProjectProxy(cloudId, pickedProject));

					if (!projectToConnect.id) {
						projectToConnect.id = pickedProject.id;
					}

					// Replacing project with a Single Project Picker
					if (previouslySelectedProjects.length === 1 && newlySelectedProjects.length === 1) {
						onProjectDisconnect(
							transformOptionToPickedProject(previouslySelectedProjects[0], cloudId),
						);

						return [projectToConnect];
					}

					return [...previouslySelectedProjects, projectToConnect];
				});
			},
			[cloudId, onProjectConnect, onDisconnectAllProjects, onProjectDisconnect],
		);

		const runtimeProps = useMemo(
			() => ({
				...DEFAULT_RUNTIME_PROPS,
				value: selectedProjects,
				onChange: onProjectsSelectionChange,
				isClearable: true,
				isDisabled: !isProjectAdmin,
			}),
			[selectedProjects, onProjectsSelectionChange, isProjectAdmin],
		);

		const { entryPointReferenceSubject, entryPointActions } = useEntryPoint(
			jwmMultiProjectPickerEntrypoint,
			{},
		);

		// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
		useEffect(() => {
			entryPointActions.load();
		}, [entryPointActions]);

		return (
			<>
				<JiraEntryPointContainer
					id={ENTRYPOINT_ID}
					teamName={TEAM_NAME}
					runtimeProps={runtimeProps}
					entryPointReferenceSubject={entryPointReferenceSubject}
					fallback={<Spinner />}
				/>
				{!isProjectAdmin && (
					<Box xcss={projectAdminPermissionsStyles}>
						{formatMessage(messages.projectPermissionsWarningBannerText)}
					</Box>
				)}
				<UpsellBanner maxConnectedProjects={MAX_CONNECTED_PROJECTS} />
			</>
		);
	},
);

const projectAdminPermissionsStyles = xcss({
	backgroundColor: 'color.background.warning',
	padding: 'space.150',
	font: 'font.body.small',
});
