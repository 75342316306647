import { graphql, useMutation } from 'react-relay';
import { ConnectionHandler, type RecordProxy } from 'relay-runtime';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import type {
	useConnectExternalProjectMutation,
	useConnectExternalProjectMutation$data,
} from '@atlassian/jira-relay/src/__generated__/useConnectExternalProjectMutation.graphql';
import RelayDataID from '@atlassian/relay-data-id';
import {
	GRAPHQL_FIELD_AGS_RELATIONSHIP_PROJECTS,
	GRAPHQL_FIELD_GRAPH_STORE,
	GRAPHQL_TYPE_JIRA_AVATAR,
	GRAPHQL_TYPE_JIRA_PROJECT,
} from '../../common/constants.tsx';
import type { ConnectedProjectProxy } from '../../common/types.tsx';
import { useCalendarActions } from '../calendar-store/index.tsx';
import { useLogger } from '../use-logger/index.tsx';
import { messages } from './messages.tsx';

export function useConnectExternalProjectMutation(currentProjectAri: string) {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();
	const { logError } = useLogger();

	const [connectProjectMutation] = useMutation<useConnectExternalProjectMutation>(graphql`
		mutation useConnectExternalProjectMutation(
			$input: GraphStoreCreateProjectHasRelatedWorkWithProjectInput
		) {
			graphStore @optIn(to: "GraphStoreMutation") {
				createProjectHasRelatedWorkWithProject(input: $input)
					@optIn(to: "GraphStoreProjectHasRelatedWorkWithProject") {
					success
					errors {
						message
						extensions {
							statusCode
						}
					}
				}
			}
		}
	`);

	const { setIsLoading } = useCalendarActions();

	const connectProject = (toProject: ConnectedProjectProxy) => {
		setIsLoading(true);

		const showGenericFlag = () =>
			showFlag({
				type: 'error',
				id: 'connect-project-failure',
				title: formatMessage(messages.projectConnectionFailureTitle),
				description: formatMessage(messages.projectConnectionUnexpectedFailureDescription),
				testId: 'jira-calendar.connect-project-failure-flag',
			});

		connectProjectMutation({
			variables: {
				input: {
					relationships: [
						{
							from: currentProjectAri,
							to: toProject.resourceId.toString(),
						},
					],
				},
			},
			onCompleted(response: useConnectExternalProjectMutation$data) {
				setIsLoading(false);

				const connectProjectResponse = response?.graphStore?.createProjectHasRelatedWorkWithProject;

				if (!connectProjectResponse) {
					showGenericFlag();
				}

				if (!connectProjectResponse?.success) {
					const code = connectProjectResponse?.errors?.[0]?.extensions?.statusCode;

					switch (code) {
						case 404:
							showFlag({
								type: 'error',
								id: 'connect-project-failure',
								title: formatMessage(messages.projectConnectionNotFoundTitle),
								description: formatMessage(messages.projectConnectionNotFoundDescription),
								testId: 'jira-calendar.connect-project-failure-flag',
							});
							break;
						case 403:
							showFlag({
								type: 'error',
								id: 'connect-project-failure',
								title: formatMessage(messages.connectPermissionsFailFlagTitle),
								description: formatMessage(messages.connectPermissionsFailFlagDescription),
								testId: 'jira-calendar.connect-project-failure-flag',
							});
							break;
						default:
							showFlag({
								type: 'error',
								id: 'connect-project-failure',
								title: formatMessage(messages.projectConnectionFailureTitle),
								description: formatMessage(messages.projectConnectionUnexpectedFailureDescription),
								testId: 'jira-calendar.connect-project-failure-flag',
							});
							break;
					}

					showGenericFlag();
				}
			},
			onError(error: Error) {
				setIsLoading(false);

				logError(
					'calendar.connect-project-mutation.error',
					`Failed to connect project ${toProject.resourceId} to project ${currentProjectAri}`,
					error,
				);

				showGenericFlag();
			},
			updater: (store, data) => {
				if (data?.graphStore?.createProjectHasRelatedWorkWithProject?.success) {
					const graphStoreRecord = store.getRoot().getLinkedRecord(GRAPHQL_FIELD_GRAPH_STORE);

					if (!graphStoreRecord) {
						return;
					}

					const connection: RecordProxy = graphStoreRecord.getLinkedRecord(
						GRAPHQL_FIELD_AGS_RELATIONSHIP_PROJECTS,
						{
							id: currentProjectAri,
						},
					);

					if (!connection) {
						return;
					}

					const { id, name, avatarUrl, resourceId } = toProject;

					const projectNodeId = RelayDataID({ id: resourceId }, GRAPHQL_TYPE_JIRA_PROJECT);

					if (!projectNodeId) {
						return;
					}

					let projectNode = store.get(projectNodeId);

					if (!projectNode) {
						const clientRecordId = `client:__type:${GRAPHQL_TYPE_JIRA_PROJECT}`;
						let clientRecord = store.get(clientRecordId);

						if (!clientRecord) {
							clientRecord = store.create(clientRecordId, GRAPHQL_TYPE_JIRA_PROJECT);
							clientRecord.setValue(clientRecordId, '__id');
							clientRecord.setValue(true, '__isNode');
							clientRecord.setValue('__TypeSchema', '__typename');
						}
						projectNode = store.create(projectNodeId, GRAPHQL_TYPE_JIRA_PROJECT);
						projectNode.setValue(projectNodeId, 'id');
						projectNode.setValue(name, 'name');
						projectNode.setValue(id, 'projectId');
					}

					if (avatarUrl) {
						const avatarNodeId = RelayDataID({ id: resourceId }, GRAPHQL_TYPE_JIRA_AVATAR);
						if (avatarNodeId && !store.get(avatarNodeId)) {
							const avatarNode = store.create(avatarNodeId, GRAPHQL_TYPE_JIRA_AVATAR);
							avatarNode.setValue(avatarNodeId, 'id');
							avatarNode.setValue(avatarUrl, 'large');
							projectNode.setLinkedRecord(avatarNode, 'avatar');
						}
					}

					const newEdge = ConnectionHandler.createEdge(
						store,
						connection,
						projectNode,
						'GraphStoreSimplifiedProjectHasRelatedWorkWithProjectEdge',
					);
					ConnectionHandler.insertEdgeAfter(connection, newEdge);
				}
			},
		});
	};

	return { connectProject };
}
