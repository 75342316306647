import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	// delete unscheduledPanel message when cleaning up visual-refresh_drop_5 FG and visual refresh
	unscheduledPanel: {
		id: 'calendar.calendar-top-controls-bar.calendar-unscheduled-panel-toggle.unscheduled-panel',
		description: 'Label for the button to toggle the unscheduled panel on a Jira calendar',
		defaultMessage: 'Unscheduled',
	},
	unscheduledPanelWork: {
		id: 'calendar.calendar-top-controls-bar.calendar-unscheduled-panel-toggle.unscheduled-panel-work',
		description: 'Label for the button to toggle the unscheduled panel on a Jira calendar',
		defaultMessage: 'Unscheduled work',
	},
});
