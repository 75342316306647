/**
 * @generated SignedSource<<f444ce5412deaf31a9139eb83376e405>>
 * @relayHash 2a141cfba4b98e22726ad5304d16005d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 60bc8b6e9770728dc84a2b381a2c3626ca39acb61b0eb2852ef4e8b67abccf26

import type { ConcreteRequest, Query } from 'relay-runtime';
export type connectedProjectsPicker_calendarQuery$variables = {
  projectAri: string;
};
export type connectedProjectsPicker_calendarQuery$data = {
  readonly graphStore: {
    readonly projectHasRelatedWorkWithProject: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly avatar?: {
            readonly large: string | null | undefined;
          } | null | undefined;
          readonly id?: string;
          readonly name?: string;
          readonly projectId?: string;
        };
      } | null | undefined>;
    };
  };
};
export type connectedProjectsPicker_calendarQuery = {
  response: connectedProjectsPicker_calendarQuery$data;
  variables: connectedProjectsPicker_calendarQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectAri"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectAri"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "id"
},
v3 = {
  "kind": "ScalarField",
  "name": "projectId"
},
v4 = {
  "kind": "ScalarField",
  "name": "name"
},
v5 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "large"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "connectedProjectsPicker_calendarQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "GraphStore",
          "kind": "LinkedField",
          "name": "graphStore",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v1/*: any*/),
                "concreteType": "GraphStoreSimplifiedProjectHasRelatedWorkWithProjectConnection",
                "kind": "LinkedField",
                "name": "projectHasRelatedWorkWithProject",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "GraphStoreSimplifiedProjectHasRelatedWorkWithProjectEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "kind": "RequiredField",
                                    "field": (v3/*: any*/),
                                    "action": "THROW",
                                    "path": "graphStore.projectHasRelatedWorkWithProject.edges.node.projectId"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": (v4/*: any*/),
                                    "action": "THROW",
                                    "path": "graphStore.projectHasRelatedWorkWithProject.edges.node.name"
                                  },
                                  (v5/*: any*/)
                                ],
                                "type": "JiraProject"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "graphStore.projectHasRelatedWorkWithProject.edges.node"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "graphStore.projectHasRelatedWorkWithProject.edges"
                  }
                ]
              },
              "action": "THROW",
              "path": "graphStore.projectHasRelatedWorkWithProject"
            }
          ]
        },
        "action": "THROW",
        "path": "graphStore"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "connectedProjectsPicker_calendarQuery",
    "selections": [
      {
        "concreteType": "GraphStore",
        "kind": "LinkedField",
        "name": "graphStore",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "GraphStoreSimplifiedProjectHasRelatedWorkWithProjectConnection",
            "kind": "LinkedField",
            "name": "projectHasRelatedWorkWithProject",
            "plural": false,
            "selections": [
              {
                "concreteType": "GraphStoreSimplifiedProjectHasRelatedWorkWithProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "type": "JiraProject"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ]
                  },
                  (v2/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "60bc8b6e9770728dc84a2b381a2c3626ca39acb61b0eb2852ef4e8b67abccf26",
    "metadata": {},
    "name": "connectedProjectsPicker_calendarQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "06eaa2512e9558a119c293fb7f385002";

export default node;
