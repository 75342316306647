import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	projectConnectionFailureTitle: {
		id: 'calendar.controllers.use-connect-external-project-mutation.project-connection-failure-title',
		defaultMessage: "We couldn't connect to your project",
		description: 'Heading for projects connection failure flag',
	},
	projectConnectionUnexpectedFailureDescription: {
		id: 'calendar.controllers.use-connect-external-project-mutation.project-connection-unexpected-failure-description',
		defaultMessage:
			"Make sure you're connected to the right network or contact your workspace admin for support.",
		description: 'Explanation of unexpected error of changing connection between projects',
	},
	connectPermissionsFailFlagTitle: {
		id: 'calendar.controllers.use-connect-external-project-mutation.connect-permissions-fail-flag-title',
		defaultMessage: "You don't have permission to connect this project",
		description:
			'Text for the permissions warning underneath the project select when the user is not a project admin',
	},
	connectPermissionsFailFlagDescription: {
		id: 'calendar.controllers.use-connect-external-project-mutation.connect-permissions-fail-flag-description',
		defaultMessage:
			'Contact your Jira admin for permission to add release dates of a software project.',
		description:
			'Text for the permissions warning underneath the project select when the user is not a project admin',
	},
	projectConnectionNotFoundTitle: {
		id: 'calendar.controllers.use-connect-external-project-mutation.project-connection-not-found-title',
		defaultMessage: "We couldn't find the connected software project",
		description: 'Heading for projects connection failure flag',
	},
	projectConnectionNotFoundDescription: {
		id: 'calendar.controllers.use-connect-external-project-mutation.project-connection-not-found-description',
		defaultMessage:
			'The connected software project may have been deleted. Refresh the page, then try again.',
		description: 'Text for the projects connection failure flag',
	},
});
