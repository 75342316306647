import type { JQLModel } from '@atlassian/jira-software-filters-popup/src/common/utils/jql-model/index.tsx';

type UninitializedCalendarState = { stage: 'pending' };

/**
 * The calendar store state.
 *
 * We have a "pending" stage and a "ready" stage.
 *
 * The "pending" stage is the state before the react-sweet-state handlers::onInit has
 * ran. This separation is here so that consumers can add some initial state into the
 * store CONTAINER, such as the base query and date range.
 *
 * The store should never be pending, unless the app is used outside of a sweet-state
 * container (such as in tests/storybook).
 */
export type CalendarStoreState = CalendarState | UninitializedCalendarState;

/**
 * A RapidView scoped board
 */
export interface CalendarScopeBoard {
	type: 'board';
	/**
	 * Board ID for board scoped calendar
	 */
	boardId: string;
	projectKeys: string[];
	/**
	 * View ID of board
	 */
	viewId?: string;
}

/**
 * Scope defined by list of project keys
 */
export interface CalendarScopeProject {
	type: 'project';
	/**
	 * The base JQL query
	 */
	projectKeys: string[];
}

export interface CalendarScopePlan {
	type: 'plan';
	/**
	 * list of ARI IDs
	 */
	ids: string[];
}

export type DraggingEvent = { type: 'issue' | 'version' | 'sprint'; id: string };

/**
 * The scope of the calendar. This determines the identifiers which .
 */
export type CalendarScope = CalendarScopeBoard | CalendarScopeProject | CalendarScopePlan;

export type CalendarViewRange = 'month' | 'week' | 'day';
export type CalendarWeekStart = 'monday' | 'sunday' | 'saturday';
export type CalendarColorBy = 'status' | 'none';

/**
 * 09:00 AM -06:00 PM in 60 minute increments in Calendar View Settings
 */
export const availableTimes = [
	'09:00 AM',
	'10:00 AM',
	'11:00 AM',
	'12:00 PM',
	'1:00 PM',
	'2:00 PM',
	'3:00 PM',
	'4:00 PM',
	'5:00 PM',
	'6:00 PM',
] as const;

export type CalendarDayStart = (typeof availableTimes)[number];

export type CalendarIssueEventFields = {
	startDateField: string;
	endDateField: string;
};

type OnSchedulePayload = {
	id: string;
	key?: string;
	type: 'issue' | 'version' | 'sprint';
	startDate: Date | null | undefined;
	endDate: Date | null | undefined;
};

export type CalendarSettings = Partial<{
	weekStartsOn: CalendarWeekStart;
	dayStartsAt: CalendarDayStart;
	showWeekends: boolean;
	showVersions: boolean;
	showSprints: boolean;
	showIssues: boolean;
	showIssueKey: boolean;
	hideDoneItems: boolean;
	showIn24HourClock: boolean;
	colorBy: CalendarColorBy;
	/**
	 * Temporarily persist view range in view setting storage for now. This will be moved to user preferences API in the future.
	 */
	viewRange: CalendarViewRange;
}>;

export type ConsumerCustomApi = {
	/**
	 * Enable consumer to refetch the calendar data
	 */
	refetchApiRef?: React.MutableRefObject<{
		onRefetch: () => void;
		refetchVersionsWithoutSuspense?: (projects?: string[]) => void;
	} | null>;
	/**
	 * Signal when an item has been dragged/resized
	 */
	onScheduled?: (payload: OnSchedulePayload) => void;
	/**
	 * Signal when selected filters updated
	 */
	onFiltered?: (query: string) => void;
	/**
	 * Signal when view settings updated
	 */
	onSettingsChanged?: (settings: CalendarSettings) => void;
};

/**
 * The calendar store state once the container onInit handler has run.
 */
export interface CalendarState extends ConsumerCustomApi {
	stage: 'ready';
	/**
	 * The scope of the calendar
	 */
	scope: CalendarScope;
	/**
	 * The JQL string as passed through by the JQL builder component.
	 *
	 * This is a "sub-query" on top of the base query that the app is currently using.
	 */
	extraQuery: string;
	/**
	 * Current start and end dates. Don't forget to account for week start/end.
	 */
	dateRange: DateRange;
	/**
	 * State for the unscheduled issues panel
	 */
	unscheduledPanelState: UnscheduledPanelState;
	/**
	 * If set to false, releases will be hidden from the calendar
	 */
	showReleases: boolean;
	/**
	 * If set to false, sprints will be hidden from the calendar
	 */
	showSprints: boolean;
	/**
	 * If set to false, issues will be hidden from the calendar
	 */
	showIssues: boolean;
	/**
	 * The view mode of calendar
	 */
	viewRange: CalendarViewRange;
	/**
	 * Whether calendar should show done items
	 */
	hideDoneItems: boolean;
	/**
	 * The day of the week the calendar starts on
	 */
	weekStartsOn: CalendarWeekStart;
	/**
	 * The color settings of issue items
	 */
	colorBy: CalendarColorBy;
	/**
	 * The day of the day the calendar starts at
	 */
	dayStartsAt: CalendarDayStart;
	/**
	 * Whether calendar should show weekends
	 */
	showWeekends: boolean;
	/**
	 * Whether calendar should show issue keys
	 */
	showIssueKey: boolean;
	/**
	 * Whether calendar weekly view should use the 24 hour clock. default is false
	 */
	showIn24HourClock: boolean;
	/**
	 * Issue event fields used in the calendar
	 */
	issueEventFields: CalendarIssueEventFields;
	/**
	 * The current page of issues connection
	 */
	issuesPageCursor: string | null;
	/**
	 * The current event being dragged this is stored in shared state
	 * because a single event may have two React elements when it's rendered
	 * across two or more weeks.
	 */
	draggingEvent: DraggingEvent | null;
	/**
	 * Set to true when the calendar is loading or refreshing the data without suspending.
	 * This is used for all fetches after the initial fetch.
	 */
	isLoading: boolean;

	viewSettingsPanelState: {
		isOpen: boolean;
	};

	calendarFieldsConfig: CalendarFieldsConfig;
}

type UnscheduledPanelState = {
	/**
	 * Is the panel visible
	 */
	isOpen: boolean;
	/**
	 * The search input value
	 */
	search: string | null;
	/**
	 * The sorting direction of the panel
	 */
	sortDirection: 'ASC' | 'DESC';
	/**
	 * Extra JQL filters applied to the unscheduled panel
	 */
	extraFilters: JQLModel | null;
	/**
	 * Set to true if the unscheduled panel is refreshing its data
	 */
	isLoading: boolean;
	/**
	 * Indicates whether the issue view is open from the unscheduled panel
	 */
	isIssueViewOpen: boolean;
	/**
	 * Indicates whether the filter option is open from the unscheduled panel
	 */
	isFilterOpen: boolean;
};

/**
 * Visible date range on the calendar. start/end are inclusive of the weeks outside
 * of the current month but visible on the calendar.
 */
export interface DateRange {
	currentDate: Date;
	start: Date;
	end: Date;
}

type CalendarFieldsConfig = {
	isDateTimeConfigured: boolean;
};
