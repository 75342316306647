import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	closeButtonLabel: {
		id: 'calendar.calendar-renderer.event-renderer.issue-renderer.flyout.close-button-label',
		defaultMessage: 'Close the issue layout',
		description: 'Label for the close button in the issue layout',
	},
	startDateLabel: {
		id: 'calendar.calendar-renderer.event-renderer.issue-renderer.flyout.start-date-label',
		defaultMessage: 'Start date',
		description: 'Label for the start date',
	},
	endDateLabel: {
		id: 'calendar.calendar-renderer.event-renderer.issue-renderer.flyout.End-date-label',
		defaultMessage: 'End date',
		description: 'Label for the end date',
	},
	projectLabel: {
		id: 'calendar.calendar-renderer.event-renderer.issue-renderer.flyout.project-label',
		defaultMessage: 'Project',
		description: 'Label for the project',
	},
	assigneeLabel: {
		id: 'calendar.calendar-renderer.event-renderer.issue-renderer.flyout.assignee-label',
		defaultMessage: 'Assignee',
		description: 'Label for the assignee',
	},
	closeButtonLabelIssueTermRefresh: {
		id: 'calendar.calendar-renderer.event-renderer.issue-renderer.flyout.close-button-label-issue-term-refresh',
		defaultMessage: 'Close the layout',
		description: 'Label for the close button in the issue layout',
	},
});
