/**
 * @generated SignedSource<<f926fc6736c0ab0514154eb296d73e24>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_calendar_CalendarInlineCardCreate$data = {
  readonly jira: {
    readonly jiraCalendar: {
      readonly endDateField: {
        readonly fieldId: string;
      } | null | undefined;
      readonly projects: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly action: {
              readonly canPerform: boolean;
            } | null | undefined;
            readonly avatar: {
              readonly xsmall: string | null | undefined;
            } | null | undefined;
            readonly id: string;
            readonly name: string;
            readonly " $fragmentSpreads": FragmentRefs<"issueTypeField_calendar">;
          } | null | undefined;
        } | null | undefined> | null | undefined;
        readonly totalCount: number | null | undefined;
      } | null | undefined;
      readonly startDateField: {
        readonly fieldId: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "main_calendar_CalendarInlineCardCreate";
};
export type main_calendar_CalendarInlineCardCreate$key = {
  readonly " $data"?: main_calendar_CalendarInlineCardCreate$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_calendar_CalendarInlineCardCreate">;
};

import inlineCardCreate_calendar_RefetchPaginated_Query_graphql from './inlineCardCreate_calendar_RefetchPaginated_Query.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "jira",
  "jiraCalendar",
  "projects"
],
v1 = [
  {
    "kind": "ScalarField",
    "name": "fieldId"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "configurationInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": 30,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeInput"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": inlineCardCreate_calendar_RefetchPaginated_Query_graphql
    }
  },
  "name": "main_calendar_CalendarInlineCardCreate",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "configuration",
              "variableName": "configurationInput"
            },
            {
              "kind": "Variable",
              "name": "scope",
              "variableName": "scopeInput"
            }
          ],
          "concreteType": "JiraCalendar",
          "kind": "LinkedField",
          "name": "jiraCalendar",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "startDateField",
              "plural": false,
              "selections": (v1/*: any*/)
            },
            {
              "kind": "LinkedField",
              "name": "endDateField",
              "plural": false,
              "selections": (v1/*: any*/)
            },
            {
              "alias": "projects",
              "concreteType": "JiraProjectConnection",
              "kind": "LinkedField",
              "name": "__inlineCardCreate_calendar_projects_connection",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraProjectEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "concreteType": "JiraProject",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "id"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "name"
                        },
                        {
                          "concreteType": "JiraAvatar",
                          "kind": "LinkedField",
                          "name": "avatar",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "name": "xsmall"
                            }
                          ]
                        },
                        {
                          "args": [
                            {
                              "kind": "Literal",
                              "name": "type",
                              "value": "CREATE_ISSUES"
                            }
                          ],
                          "concreteType": "JiraProjectAction",
                          "kind": "LinkedField",
                          "name": "action",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "name": "canPerform"
                            }
                          ],
                          "storageKey": "action(type:\"CREATE_ISSUES\")"
                        },
                        {
                          "kind": "FragmentSpread",
                          "name": "issueTypeField_calendar"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "__typename"
                        }
                      ]
                    },
                    {
                      "kind": "ScalarField",
                      "name": "cursor"
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "name": "totalCount"
                },
                {
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "endCursor"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "hasNextPage"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "014dae0a79309dafe4880f6fbfc971ea";

export default node;
