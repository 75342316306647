/**
 * @generated SignedSource<<871ec752c85e8e8765935148409d7ed1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issuesPaginator_calendar$data = {
  readonly isCappingIssueSearchResult: boolean | null | undefined;
  readonly issueNavigatorPageInfo: {
    readonly firstIssuePosition: number | null | undefined;
    readonly lastIssuePosition: number | null | undefined;
  } | null | undefined;
  readonly pageCursors: {
    readonly around: ReadonlyArray<{
      readonly cursor: string | null | undefined;
      readonly pageNumber: number | null | undefined;
    } | null | undefined> | null | undefined;
    readonly first: {
      readonly cursor: string | null | undefined;
      readonly pageNumber: number | null | undefined;
    } | null | undefined;
    readonly last: {
      readonly cursor: string | null | undefined;
      readonly pageNumber: number | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly pageInfo: {
    readonly endCursor: string | null | undefined;
    readonly hasNextPage: boolean;
    readonly hasPreviousPage: boolean;
  };
  readonly totalIssueSearchResultCount: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"LazyLoadIssues">;
  readonly " $fragmentType": "issuesPaginator_calendar";
};
export type issuesPaginator_calendar$key = {
  readonly " $data"?: issuesPaginator_calendar$data;
  readonly " $fragmentSpreads": FragmentRefs<"issuesPaginator_calendar">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "name": "pageNumber"
  },
  {
    "kind": "ScalarField",
    "name": "cursor"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageSize"
    }
  ],
  "kind": "Fragment",
  "name": "issuesPaginator_calendar",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "totalIssueSearchResultCount"
    },
    {
      "kind": "ScalarField",
      "name": "isCappingIssueSearchResult"
    },
    {
      "concreteType": "JiraIssueNavigatorPageInfo",
      "kind": "LinkedField",
      "name": "issueNavigatorPageInfo",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "firstIssuePosition"
        },
        {
          "kind": "ScalarField",
          "name": "lastIssuePosition"
        }
      ]
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "maxCursors",
          "value": 7
        },
        {
          "kind": "Variable",
          "name": "pageSize",
          "variableName": "pageSize"
        }
      ],
      "concreteType": "JiraPageCursors",
      "kind": "LinkedField",
      "name": "pageCursors",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraPageCursor",
          "kind": "LinkedField",
          "name": "first",
          "plural": false,
          "selections": (v0/*: any*/)
        },
        {
          "concreteType": "JiraPageCursor",
          "kind": "LinkedField",
          "name": "around",
          "plural": true,
          "selections": (v0/*: any*/)
        },
        {
          "concreteType": "JiraPageCursor",
          "kind": "LinkedField",
          "name": "last",
          "plural": false,
          "selections": (v0/*: any*/)
        }
      ]
    },
    {
      "concreteType": "PageInfo",
      "kind": "LinkedField",
      "name": "pageInfo",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "hasNextPage"
        },
        {
          "kind": "ScalarField",
          "name": "hasPreviousPage"
        },
        {
          "kind": "ScalarField",
          "name": "endCursor"
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "LazyLoadIssues"
    }
  ],
  "type": "JiraScenarioIssueLikeConnection"
};
})();

(node as any).hash = "a64827750c135a5be9ebda675a6ea3ca";

export default node;
