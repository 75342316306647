// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type ComponentType } from 'react';
import { styled } from '@compiled/react';
import type {
	RowListFocusFunctionChangedCallback,
	ReactPortalContainerRefChangedCallback,
} from '../../../../../model/callbacks/index.tsx';
import type { Optional } from '../../../../../model/optional/index.tsx';
import KeyboardListener from './common/keyboard-shortcut-listener/index.tsx';

type Props = {
	isAutoHeight: boolean;
	onRowListFocusFunctionChanged: Optional<RowListFocusFunctionChangedCallback>;
	onReactPortalContainerRefChanged: Optional<ReactPortalContainerRefChangedCallback>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	AutoHeightList: ComponentType<Record<any, any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	VirtualList: ComponentType<Record<any, any>>;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	outline: 'none',
});

// eslint-disable-next-line jira/react/no-class-components
export default class RowList extends PureComponent<Props> {
	onRefChanged = (el: HTMLDivElement | null) => {
		const { onRowListFocusFunctionChanged } = this.props;
		if (!onRowListFocusFunctionChanged) {
			return;
		}
		if (el != null) {
			onRowListFocusFunctionChanged(() => {
				el && el.focus();
			});
		} else {
			onRowListFocusFunctionChanged(null);
		}
	};

	onDivRefChanged = (el: HTMLDivElement | null) => {
		const { onReactPortalContainerRefChanged } = this.props;
		if (!onReactPortalContainerRefChanged) {
			return;
		}
		if (el != null) {
			onReactPortalContainerRefChanged(el);
		}
	};

	render() {
		const { isAutoHeight, AutoHeightList, VirtualList } = this.props;
		const Content = (
			// tabIndex is needed to be able to focus / navigate through rows and scroll vertically while navigating through the rows that are offscreen
			<Wrapper tabIndex={-1} ref={this.onRefChanged}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div ref={this.onDivRefChanged} className="virtual-table-row-list-portal" />
				{isAutoHeight ? <AutoHeightList /> : <VirtualList />}
			</Wrapper>
		);

		return (
			<>
				{/* @ts-expect-error - Type '{}' is missing the following properties from type 'Readonly<Omit<Pick<Props, "horizontalScrollOffset" | "navigationMode" | "setHorizontalScrollOffset" | "navigateToRow" | "activeRowIndex" | "maxHorizontalScrollOffset"> & InexactPartial<...> & InexactPartial<...>, never>>': horizontalScrollOffset, navigationMode, setHorizontalScrollOffset, navigateToRow, and 2 more. | Type '{}' is missing the following properties from type 'Readonly<Omit<Pick<Props, "navigationMode" | "horizontalScrollOffset" | "setHorizontalScrollOffset" | "navigateToRow" | "activeRowIndex" | "maxHorizontalScrollOffset"> & InexactPartial<...> & InexactPartial<...>, never>>': navigationMode, horizontalScrollOffset, setHorizontalScrollOffset, navigateToRow, and 2 more. */}
				<KeyboardListener />
				{Content}
			</>
		);
	}
}
