import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import AvatarGroup from '@atlaskit/avatar-group';
import { token } from '@atlaskit/tokens';
import type { JsonComponentProps } from '../../../model/fields/types.tsx';

type Props =
	| JsonComponentProps<'com.atlassian.servicedesk:sd-request-participants'>
	| JsonComponentProps<'com.atlassian.jira.plugin.system.customfieldtypes:multiuserpicker'>;

const MultiUserField = (props: Props) => {
	const {
		dataSelectorProps: { value: items },
	} = props;

	const userData = useMemo(
		() =>
			items.map((user) => ({
				email: user.email,
				key: user.email,
				name: user.name,
				src: user.avatarUrl,
			})),
		[items],
	);

	if (!items || items.length === 0) {
		return null;
	}

	return (
		<Container>
			<Content>
				<AvatarGroup size="small" maxCount={5} appearance="stack" data={userData} />
			</Content>
		</Container>
	);
};

export default MultiUserField;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Content = styled.div({
	paddingTop: token('space.100', '8px'),
	paddingRight: token('space.300', '24px'),
	paddingBottom: token('space.100', '8px'),
	paddingLeft: token('space.100', '8px'),
	overflowWrap: 'break-word',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Container = styled.div({
	overflow: 'auto',
	cursor: 'pointer',
});
