import { useCallback } from 'react';
import log, { type Event } from '@atlassian/jira-common-util-logging/src/log.tsx';
import { useCalendarUFOExperienceContext } from '../ufo-context-provider/index.tsx';

const enrichError = (
	suffixKey: string,
	message: string,
	event: Event | undefined,
): Event | undefined => {
	if (event) {
		if (event instanceof Error) {
			return new Error(`[${suffixKey}] ${event.message}`, { cause: event });
		}
		return {
			...event,
			message: `[${suffixKey}] ${event.message ?? ''}`,
		};
	}
	return new Error(`[${suffixKey}] ${message}`);
};

export const useLogger = () => {
	const { suffixKey } = useCalendarUFOExperienceContext();

	const logError = useCallback(
		(location: string, message: string, event?: Event) => {
			log.safeErrorWithoutCustomerData(location, message, enrichError(suffixKey, message, event));
		},
		[suffixKey],
	);

	const logWarn = useCallback((location: string, message: string) => {
		log.safeWarnWithoutCustomerData(location, message);
	}, []);

	return {
		logError,
		logWarn,
	};
};
