/**
 * @generated SignedSource<<3b776dc6e78aa78a004044f9bcfc6e0a>>
 * @relayHash ee4cdc51446f840dc1c6dec81e4c26f0
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 789f39393931cb9b576f35bd5930439d6dc81d470bcddb88b003ed3d08afd1d5

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraCalendarMode = "DAY" | "MONTH" | "WEEK" | "%future added value";
export type JiraCalendarWeekStart = "MONDAY" | "SATURDAY" | "SUNDAY" | "%future added value";
export type JiraViewScopeInput = {
  ids?: ReadonlyArray<string> | null | undefined;
  projectKeys?: JiraProjectKeysInput | null | undefined;
};
export type JiraProjectKeysInput = {
  cloudId: string;
  keys?: ReadonlyArray<string> | null | undefined;
};
export type JiraCalendarViewConfigurationInput = {
  date?: AGG$DateTime | null | undefined;
  endDateField?: string | null | undefined;
  mode?: JiraCalendarMode | null | undefined;
  startDateField?: string | null | undefined;
  viewId?: string | null | undefined;
  weekStart?: JiraCalendarWeekStart | null | undefined;
};
export type useCreateCalendarIssueMutation$variables = {
  configuration: JiraCalendarViewConfigurationInput;
  endDateInput: AGG$DateTime;
  issueTypeId: string;
  schedulePermissionsEnabled: boolean;
  scope: JiraViewScopeInput;
  startDateInput?: AGG$DateTime | null | undefined;
  summary: string;
  viewId?: string | null | undefined;
};
export type useCreateCalendarIssueMutation$data = {
  readonly jira: {
    readonly createCalendarIssue: {
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly errorType: string | null | undefined;
          readonly statusCode: number | null | undefined;
        } | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly issue: {
        readonly __id: string;
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"issueRenderer_calendar_IssueEventRenderer">;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type useCreateCalendarIssueMutation = {
  response: useCreateCalendarIssueMutation$data;
  variables: useCreateCalendarIssueMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "configuration"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDateInput"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueTypeId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "schedulePermissionsEnabled"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scope"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDateInput"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "summary"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v8 = [
  {
    "kind": "Variable",
    "name": "configuration",
    "variableName": "configuration"
  },
  {
    "kind": "Variable",
    "name": "endDateInput",
    "variableName": "endDateInput"
  },
  {
    "kind": "Variable",
    "name": "issueTypeId",
    "variableName": "issueTypeId"
  },
  {
    "kind": "Variable",
    "name": "scope",
    "variableName": "scope"
  },
  {
    "kind": "Variable",
    "name": "startDateInput",
    "variableName": "startDateInput"
  },
  {
    "kind": "Variable",
    "name": "summary",
    "variableName": "summary"
  }
],
v9 = {
  "kind": "ScalarField",
  "name": "success"
},
v10 = {
  "kind": "ScalarField",
  "name": "message"
},
v11 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v12 = {
  "kind": "ScalarField",
  "name": "errorType"
},
v13 = {
  "kind": "ScalarField",
  "name": "id"
},
v14 = {
  "kind": "Variable",
  "name": "viewId",
  "variableName": "viewId"
},
v15 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v16 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v17 = {
  "kind": "ScalarField",
  "name": "key"
},
v18 = {
  "kind": "ScalarField",
  "name": "name"
},
v19 = [
  (v18/*: any*/),
  {
    "concreteType": "JiraAvatar",
    "kind": "LinkedField",
    "name": "avatar",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "small"
      }
    ]
  },
  (v13/*: any*/)
],
v20 = {
  "concreteType": "JiraIssueTypeField",
  "kind": "LinkedField",
  "name": "issueTypeField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": (v19/*: any*/)
    },
    (v13/*: any*/)
  ]
},
v21 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "text"
    },
    (v13/*: any*/)
  ]
},
v22 = [
  (v14/*: any*/)
],
v23 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "date"
    }
  ],
  "type": "JiraDatePickerField"
},
v24 = [
  (v16/*: any*/),
  (v13/*: any*/),
  (v23/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "kind": "ScalarField",
        "name": "dateTime"
      }
    ],
    "type": "JiraDateTimePickerField"
  },
  (v15/*: any*/)
],
v25 = {
  "concreteType": "JiraStatusField",
  "kind": "LinkedField",
  "name": "statusField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraStatusCategory",
          "kind": "LinkedField",
          "name": "statusCategory",
          "plural": false,
          "selections": [
            (v17/*: any*/),
            (v13/*: any*/)
          ]
        },
        (v13/*: any*/),
        (v18/*: any*/)
      ]
    },
    (v13/*: any*/)
  ]
},
v26 = [
  (v16/*: any*/),
  (v13/*: any*/),
  (v23/*: any*/),
  (v15/*: any*/)
],
v27 = {
  "kind": "LinkedField",
  "name": "startDateViewField",
  "plural": false,
  "selections": (v26/*: any*/)
},
v28 = {
  "kind": "LinkedField",
  "name": "endDateViewField",
  "plural": false,
  "selections": (v26/*: any*/)
},
v29 = {
  "concreteType": "JiraSingleSelectUserPickerField",
  "kind": "LinkedField",
  "name": "assigneeField",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v16/*: any*/),
        (v18/*: any*/),
        {
          "kind": "ScalarField",
          "name": "picture"
        },
        (v13/*: any*/)
      ]
    },
    (v13/*: any*/)
  ]
},
v30 = {
  "concreteType": "JiraRichTextField",
  "kind": "LinkedField",
  "name": "descriptionField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraRichText",
      "kind": "LinkedField",
      "name": "richText",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraADF",
          "kind": "LinkedField",
          "name": "adfValue",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraAdfToConvertedPlainText",
              "kind": "LinkedField",
              "name": "convertedPlainText",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "plainText"
                }
              ]
            }
          ]
        }
      ]
    },
    (v13/*: any*/)
  ]
},
v31 = {
  "concreteType": "JiraProjectField",
  "kind": "LinkedField",
  "name": "projectField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": (v19/*: any*/)
    },
    (v13/*: any*/)
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "name": "useCreateCalendarIssueMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v8/*: any*/),
            "concreteType": "JiraCreateCalendarIssuePayload",
            "kind": "LinkedField",
            "name": "createCalendarIssue",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v10/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ]
                  }
                ]
              },
              {
                "concreteType": "JiraIssue",
                "kind": "LinkedField",
                "name": "issue",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "schedulePermissionsEnabled",
                        "variableName": "schedulePermissionsEnabled"
                      },
                      (v14/*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "issueRenderer_calendar_IssueEventRenderer"
                  },
                  (v15/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "useCreateCalendarIssueMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v8/*: any*/),
            "concreteType": "JiraCreateCalendarIssuePayload",
            "kind": "LinkedField",
            "name": "createCalendarIssue",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v10/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v16/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v13/*: any*/)
                        ],
                        "type": "BulkMutationErrorExtension"
                      }
                    ]
                  }
                ]
              },
              {
                "concreteType": "JiraIssue",
                "kind": "LinkedField",
                "name": "issue",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v17/*: any*/),
                          (v20/*: any*/),
                          {
                            "alias": "requestTypeField",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "idOrAlias",
                                "value": "requesttype"
                              },
                              {
                                "kind": "Literal",
                                "name": "ignoreMissingField",
                                "value": true
                              }
                            ],
                            "kind": "LinkedField",
                            "name": "fieldByIdOrAlias",
                            "plural": false,
                            "selections": [
                              (v16/*: any*/),
                              (v13/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "concreteType": "JiraServiceManagementRequestType",
                                    "kind": "LinkedField",
                                    "name": "requestType",
                                    "plural": false,
                                    "selections": (v19/*: any*/)
                                  }
                                ],
                                "type": "JiraServiceManagementRequestTypeField"
                              }
                            ],
                            "storageKey": "fieldByIdOrAlias(idOrAlias:\"requesttype\",ignoreMissingField:true)"
                          },
                          (v21/*: any*/),
                          {
                            "args": (v22/*: any*/),
                            "kind": "LinkedField",
                            "name": "startDateViewField",
                            "plural": false,
                            "selections": (v24/*: any*/)
                          },
                          {
                            "args": (v22/*: any*/),
                            "kind": "LinkedField",
                            "name": "endDateViewField",
                            "plural": false,
                            "selections": (v24/*: any*/)
                          },
                          (v25/*: any*/),
                          {
                            "args": (v22/*: any*/),
                            "concreteType": "JiraScenarioIssueValues",
                            "kind": "LinkedField",
                            "name": "planScenarioValues",
                            "plural": false,
                            "selections": [
                              (v21/*: any*/),
                              (v20/*: any*/),
                              (v27/*: any*/),
                              (v28/*: any*/),
                              (v25/*: any*/),
                              (v29/*: any*/),
                              (v30/*: any*/),
                              (v31/*: any*/)
                            ]
                          },
                          (v29/*: any*/),
                          (v30/*: any*/),
                          (v31/*: any*/),
                          {
                            "condition": "schedulePermissionsEnabled",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              {
                                "alias": "canSchedule",
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "permission",
                                    "value": "SCHEDULE_ISSUES"
                                  }
                                ],
                                "kind": "ScalarField",
                                "name": "hasProjectPermission",
                                "storageKey": "hasProjectPermission(permission:\"SCHEDULE_ISSUES\")"
                              }
                            ]
                          }
                        ],
                        "type": "JiraIssue"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "args": (v22/*: any*/),
                            "concreteType": "JiraScenarioIssueValues",
                            "kind": "LinkedField",
                            "name": "planScenarioValues",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "scenarioKey"
                              },
                              (v20/*: any*/),
                              (v21/*: any*/),
                              (v27/*: any*/),
                              (v28/*: any*/),
                              (v25/*: any*/),
                              (v29/*: any*/),
                              (v30/*: any*/),
                              (v31/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraScenarioIssue"
                      },
                      (v15/*: any*/)
                    ],
                    "type": "JiraScenarioIssueLike",
                    "abstractKey": "__isJiraScenarioIssueLike"
                  },
                  (v15/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "789f39393931cb9b576f35bd5930439d6dc81d470bcddb88b003ed3d08afd1d5",
    "metadata": {},
    "name": "useCreateCalendarIssueMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "36cf4b79523c1484987d7dc95b6e2f61";

export default node;
