import React, { memo, useCallback, useMemo, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Text } from '@atlaskit/primitives';
import Anchor from '@atlaskit/primitives/anchor';
import { useCanUpgradeAndPayForCurrentProduct } from '@atlassian/jira-billing/src/controllers/billing-permissions/index.tsx';
import { ModalEntryPointContainer } from '@atlassian/jira-entry-point-modal-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { entryPointPremiumUpgradeModal } from '@atlassian/jira-premium-upgrade-modal/src/entrypoint.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { FREE_EDITION, STANDARD_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import messages from './messages.tsx';

type UpsellBannerProps = {
	maxConnectedProjects: number;
};

const entryPointParams = {};
export const UpsellBanner = memo<UpsellBannerProps>(({ maxConnectedProjects }) => {
	const { formatMessage } = useIntl();
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		entryPointPremiumUpgradeModal,
		entryPointParams,
	);
	const onCtaClick = useCallback(
		(_: MouseEvent<HTMLAnchorElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'multiConnectedProjectPickerTryItFreeButton');
			entryPointActions.load();
		},
		[entryPointActions],
	);
	const { canUpgradeAndPay } = useCanUpgradeAndPayForCurrentProduct({
		caller: 'multi-project-picker',
	});
	const appEditions = useAppEditions();
	const isOnFreeOrStandardEdition = useMemo(
		() => appEditions.software === FREE_EDITION || appEditions.software === STANDARD_EDITION,
		[appEditions],
	);

	if (!isOnFreeOrStandardEdition) {
		return null;
	}

	return (
		<>
			<ModalEntryPointContainer
				entryPointReferenceSubject={entryPointReferenceSubject}
				id="jira-premium-upgrade-modal"
				packageName="jiraPremiumUpgradeModal"
				errorFallback="flag"
				runtimeProps={{ onClose: entryPointActions.unload }}
				entryPointActions={entryPointActions}
			/>
			<Text size="small" as="p">
				{canUpgradeAndPay ? (
					<FormattedMessage
						values={{
							maxConnectedProjects,
							cta: (text) => (
								<>
									<br />
									<Anchor href="#" onClick={onCtaClick}>
										{text}
									</Anchor>
								</>
							),
						}}
						{...messages.message}
					/>
				) : (
					formatMessage(messages.messageNoBillingPermission)
				)}
			</Text>
		</>
	);
});
