import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyQueueHeader: {
		id: 'servicedesk-queues-empty-queue.empty-queue-header',
		defaultMessage: 'Your queue is empty',
		description:
			'Header displayed to user when their queue is empty. Directly below it is emptyQueueDescription.',
	},
	emptyQueueDescription: {
		id: 'servicedesk-queues-empty-queue.empty-queue-description',
		defaultMessage:
			'<p>Incoming requests from multiple channels are filtered and displayed in your queues as issues, ready to be triaged and assigned. {break} {IPHArticle} </p>',
		description:
			'Secondary text displayed to user when their queue is empty. Directly above it is emptyQueueHeader.',
	},
	emptyQueueButtonLabel: {
		id: 'servicedesk-queues-empty-queue.empty-queue-button-label',
		defaultMessage: 'Create an issue',
		description: 'Button Label when queue is empty',
	},
	emptyQueueLearnMoreText: {
		id: 'servicedesk-queues-empty-queue.empty-queue-learn-more-text',
		defaultMessage: 'Learn more about queues',
		description: 'Text to display for what are queues documentation page',
	},
	emptyQueueDescriptionIssueTermRefresh: {
		id: 'servicedesk-queues-empty-queue.empty-queue-description-issue-term-refresh',
		defaultMessage:
			'<p>Incoming requests from multiple channels are filtered and displayed in your queues as issues, ready to be triaged and assigned. {break} {IPHArticle} </p>',
		description:
			'Secondary text displayed to user when their queue is empty. Directly above it is emptyQueueHeader.',
	},
	emptyQueueButtonLabelIssueTermRefresh: {
		id: 'servicedesk-queues-empty-queue.empty-queue-button-label-issue-term-refresh',
		defaultMessage: 'Create an issue',
		description: 'Button Label when queue is empty',
	},
});
