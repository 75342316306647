import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import { sanitiseJql } from '@atlassian/jira-business-filters/src/controllers/default-query-input/utils.tsx';
import { useFilters } from '@atlassian/jira-business-filters/src/controllers/index.tsx';
import { useSimpleSearchJQL } from '@atlassian/jira-business-filters/src/controllers/simple-search/index.tsx';
import { Filters as BusinessFilters } from '@atlassian/jira-business-filters/src/ui/filters/index.tsx';
import { SimpleSearchField } from '@atlassian/jira-business-filters/src/ui/simple-search-field/index.tsx';
import AddPeopleWithAvatar from '@atlassian/jira-business-invite-people/src/ui/add-people-with-avatars/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import {
	useCalendarActions,
	useCalendarCallbacks,
} from '../../../controllers/calendar-store/index.tsx';
import messages from './messages.tsx';

export const BusinessFiltersLeft = () => (
	<>
		<SimpleSearchField placeholder={messages.simpleSearch} view="calendar" />
		<AddPeopleWithAvatar
			view="calendar"
			isPresenceEnabledInContext
			isOpenNewTabOnClick={false}
			hasInviteButton={!isVisualRefreshEnabled()}
			allowCustomFields
		/>
	</>
);

export const BusinessFiltersRight = () => {
	const { setExtraQuery } = useCalendarActions();

	const { onFiltered } = useCalendarCallbacks();
	const { addSimpleSearchToJQL } = useSimpleSearchJQL();

	const { jql } = useFilters();

	const jqlSanitised = useMemo(() => sanitiseJql(jql), [jql]);

	const jqlWithSearchText = addSimpleSearchToJQL(jqlSanitised || null);
	const previousJqlRef = useRef<string | null>(null);

	const onSearchCallback = useCallback(
		(query: string) => {
			setExtraQuery(query);
			onFiltered?.(query);
		},
		[setExtraQuery, onFiltered],
	);

	useEffect(() => {
		const previousJql = previousJqlRef.current;
		if (!(previousJql === null && jqlWithSearchText === null)) {
			if (jqlWithSearchText !== '') {
				onSearchCallback(jqlWithSearchText || '');
			}
		}
		previousJqlRef.current = jqlWithSearchText;
	}, [addSimpleSearchToJQL, jqlWithSearchText, onSearchCallback]);

	return <BusinessFilters view="calendar" appearance="default" allowCustomFields />;
};
