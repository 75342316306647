import React, { useMemo, useCallback, useEffect } from 'react';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import type { AnalyticsSource } from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import { useViewMode } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { IssueDeleteCallbackArgs } from '@atlassian/jira-issue-view-foundation/src/issue-actions/delete-issue/types.tsx';
import type { OnChangeCallback } from '@atlassian/jira-issue-view-model/src/change-type.tsx';
import type { ViewModeOptions } from '@atlassian/jira-issue-view-model/src/view-mode-options.tsx';
import type ModalIssueAppType from '@atlassian/jira-issue-view/src/views/issue-details/modal-issue-app.tsx';
import { ModalBoundary } from '@atlassian/jira-modal/src/ui/modal-boundary/index.tsx';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { useQueryParam } from '@atlassian/react-resource-router';
import { SELECTED_ISSUE } from '../../../common/constants.tsx';
import { useCalendarIssueViewMode } from '../../../common/controllers/use-calendar-issue-view-mode/index.tsx';
import { useCalendarUFOExperienceContext } from '../../../controllers/ufo-context-provider/index.tsx';

export type Props = {
	analyticsSource: AnalyticsSource;
	onChange?: OnChangeCallback;
	onClose: () => void;
	onDeleteSuccess?: (arg1: IssueDeleteCallbackArgs) => void;
	viewModeSwitchDisabled?: boolean;
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyCalendarModalIssueApp = lazyForPaint<typeof ModalIssueAppType>(
	() =>
		import(
			/* webpackChunkName: "async-calendar-modal-issue-app" */ '@atlassian/jira-issue-view/src/views/issue-details/modal-issue-app'
		),
	{ ssr: false },
);

const CalendarModalIssueApp = ({
	onClose,
	onChange,
	onDeleteSuccess,
	analyticsSource,
	viewModeSwitchDisabled,
}: Props) => {
	const issueViewMode = useViewMode();
	const { setCalendarIssueViewAsModal, setCalendarIssueViewAsSidebar } = useCalendarIssueViewMode();
	const [selectedIssueKey, setSelectedIssueKey] = useQueryParam(SELECTED_ISSUE);

	const prevIssueKey = usePreviousWithInitial(selectedIssueKey);

	const onIssueKeyChange = useCallback(
		({ toIssueKey }: { toIssueKey: string }) => setSelectedIssueKey(toIssueKey),
		[setSelectedIssueKey],
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		if (prevIssueKey !== selectedIssueKey) {
			setSelectedIssueKey(selectedIssueKey);
		}
	}, [selectedIssueKey, prevIssueKey, setSelectedIssueKey]);

	const onSwitchToModal = useCallback(() => {
		setCalendarIssueViewAsModal();
		fireUIAnalytics(createAnalyticsEvent({}), 'issueAppMenuItem clicked', 'switchToModal');
	}, [createAnalyticsEvent, setCalendarIssueViewAsModal]);

	const onSwitchToSidebar = useCallback(() => {
		setCalendarIssueViewAsSidebar();
		fireUIAnalytics(createAnalyticsEvent({}), 'issueAppMenuItem clicked', 'switchToSidebar');
	}, [createAnalyticsEvent, setCalendarIssueViewAsSidebar]);

	const viewModeOptions = useMemo(
		(): ViewModeOptions | undefined =>
			!viewModeSwitchDisabled
				? {
						viewMode: issueViewMode,
						viewModeSwitchEnabled: true,
						onSwitchToModal,
						onSwitchToSidebar,
					}
				: { viewMode: issueViewMode },
		[issueViewMode, onSwitchToModal, onSwitchToSidebar, viewModeSwitchDisabled],
	);

	const { packageName } = useCalendarUFOExperienceContext();

	if (!selectedIssueKey) {
		return null;
	}

	return (
		<ModalBoundary packageName={packageName} id="calendar-issue-modal">
			<Placeholder name="calendar-modal-issue-app" fallback={null}>
				<LazyCalendarModalIssueApp
					analyticsSource={analyticsSource}
					issueKey={selectedIssueKey}
					onClose={onClose}
					onChange={onChange}
					onIssueDeleteSuccess={onDeleteSuccess}
					onIssueKeyChange={onIssueKeyChange}
					viewModeOptions={viewModeOptions}
				/>
			</Placeholder>
		</ModalBoundary>
	);
};

export default CalendarModalIssueApp;
