/**
 * @generated SignedSource<<15c4c74d873d409b9e342cfa049446cb>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type editReleaseItem_calendar$data = {
  readonly canEdit: boolean | null | undefined;
  readonly " $fragmentType": "editReleaseItem_calendar";
};
export type editReleaseItem_calendar$key = {
  readonly " $data"?: editReleaseItem_calendar$data;
  readonly " $fragmentSpreads": FragmentRefs<"editReleaseItem_calendar">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "editReleaseItem_calendar",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "canEdit"
    }
  ],
  "type": "JiraVersion"
};

(node as any).hash = "2eeece08824360f8bf78e03c22ea369a";

export default node;
