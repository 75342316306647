import React, { useCallback } from 'react';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	ANALYTICS_SOURCE,
	UI_EVENT_CHANGED,
	ANALYTICS_ACTION_VIEWRANGE_SUBJECT,
} from '../../../common/constants.tsx';
import {
	useCalendarActions,
	useCalendarCallbacks,
	useViewRange,
	useCalendarSettings,
} from '../../../controllers/calendar-store/index.tsx';
import type { CalendarViewRange } from '../../../controllers/calendar-store/types.tsx';
import { messages } from './messages.tsx';

export const ViewRangeControls = () => {
	const viewRange = useViewRange();
	const { setViewRange } = useCalendarActions();
	const { onSettingsChanged } = useCalendarCallbacks();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { formatMessage } = useIntl();
	const calendarSettings = useCalendarSettings();
	const uiAnalyticsEvent = createAnalyticsEvent({
		action: UI_EVENT_CHANGED,
		actionSubject: ANALYTICS_ACTION_VIEWRANGE_SUBJECT,
	});

	const onViewRangeClick = useCallback(
		(viewMode: CalendarViewRange) => {
			setViewRange(viewMode);
			onSettingsChanged?.({
				...calendarSettings,
				viewRange: viewMode,
			});
			fireUIAnalytics(uiAnalyticsEvent, ANALYTICS_SOURCE, { viewRange: viewMode });
		},
		[calendarSettings, onSettingsChanged, setViewRange, uiAnalyticsEvent],
	);
	return (
		<DropdownMenu
			trigger={formatMessage(viewRange === 'month' ? messages.month : messages.week)}
			shouldRenderToParent
			testId="calendar.ui.calendar-top-controls-bar.view-range-controls.dropdown-menu"
		>
			<DropdownItemGroup>
				<DropdownItem onClick={() => onViewRangeClick('month')}>
					{formatMessage(messages.month)}
				</DropdownItem>
				<DropdownItem onClick={() => onViewRangeClick('week')}>
					{formatMessage(messages.week)}
				</DropdownItem>
			</DropdownItemGroup>
		</DropdownMenu>
	);
};
