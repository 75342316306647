import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	unscheduledWorkOnboardingTitle: {
		id: 'calendar.common.onboarding.unscheduled-work-onboarding-title',
		defaultMessage: 'Select Unscheduled work to view issues without a due date.',
		description: 'Uscheduled work panel onboarding title',
	},
	unscheduledWorkOnboardingDescription: {
		id: 'calendar.common.onboarding.unscheduled-work-onboarding-description',
		defaultMessage: 'To schedule an issue, drag and drop it onto the calendar.',
		description: 'Unscheduled work panel onboarding action',
	},
	nextStepButton: {
		id: 'calendar.common.onboarding.next-step-button',
		defaultMessage: 'Next',
		description: 'Button to go to the next step in the onboarding flow',
	},
	dismissStepButton: {
		id: 'calendar.common.onboarding.dismiss-step-button',
		defaultMessage: 'Dismiss',
		description: 'Button to end the onboarding flow',
	},
	backStepButton: {
		id: 'calendar.common.onboarding.back-step-button',
		defaultMessage: 'Back',
		description: 'Button to go to the previous step of the onboarding flow',
	},
	doneStepButton: {
		id: 'calendar.common.onboarding.done-step-button',
		defaultMessage: 'Dismiss',
		description: 'Button to stop the onboarding flow',
	},
	viewSettingsOnboardingDescription: {
		id: 'calendar.common.onboarding.view-settings-onboarding-description',
		defaultMessage: "Show or hide your team's sprints and releases.",
		description: 'View settings panel onboarding first step title',
	},
	unscheduledWorkOnboardingTitleIssueTermRefresh: {
		id: 'calendar.common.onboarding.unscheduled-work-onboarding-title-issue-term-refresh',
		defaultMessage: 'Select unscheduled work to view work items without a due date.',
		description: 'Uscheduled work panel onboarding title',
	},
	unscheduledWorkOnboardingDescriptionIssueTermRefresh: {
		id: 'calendar.common.onboarding.unscheduled-work-onboarding-description-issue-term-refresh',
		defaultMessage: 'To schedule a work item, drag and drop it onto the calendar.',
		description: 'Unscheduled work panel onboarding action',
	},
});
