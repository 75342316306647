import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import { useEditReleaseExperienceFail } from '../../services/edit-release-experience-tracker/index.tsx';
import type { Props as EditReleaseModalProps } from './index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyEditReleaseModal = lazy(() =>
	import(/* webpackChunkName: "async-edit-release-modal" */ './index').then(
		(module) => module.EditReleaseModal,
	),
);

export const EditReleaseModal = (props: EditReleaseModalProps) => {
	const experienceFail = useEditReleaseExperienceFail();

	return props.isOpen ? (
		<JSErrorBoundary
			packageName="jiraSoftwareReleasesReleaseModalsRelay"
			teamName="fusion-solaris"
			id="editReleaseModal"
			fallback="flag"
			onError={(location: string, error: Error) => experienceFail(location, error)}
		>
			<Placeholder name="edit-release-modal" fallback={null}>
				<LazyEditReleaseModal {...props} />
			</Placeholder>
		</JSErrorBoundary>
	) : null;
};
