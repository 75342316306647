import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	viewOptions: {
		id: 'calendar.calendar-top-controls-bar.view-settings.view-options',
		defaultMessage: 'View options',
		description: 'Label for the "View options" section on Jira calendar view settings pop-up',
	},
	hideDoneItems: {
		id: 'calendar.calendar-top-controls-bar.view-settings.hide-done-items',
		defaultMessage: 'Hide done items',
		description: 'Label for the "Hide done items" toggle on Jira calendar view settings pop-up',
	},
	hideDoneItemsIssueTermRefresh: {
		id: 'calendar.calendar-top-controls-bar.view-settings.hide-done-items-issue-term-refresh',
		defaultMessage: 'Hide done work items',
		description: 'Label for the "Hide done items" toggle on Jira calendar view settings pop-up',
	},
	calendarSettings: {
		id: 'calendar.calendar-top-controls-bar.view-settings.calendar-settings',
		defaultMessage: 'Settings',
		description: 'Label for the "Calendar settings" section on Jira calendar view settings pop-up',
	},
	showCalendarItems: {
		id: 'calendar.calendar-top-controls-bar.view-settings.show-calendar-items',
		defaultMessage: 'Show calendar items',
		description:
			'Label for the "Show calendar items" section on Jira calendar view settings pop-up',
	},
	releases: {
		id: 'calendar.calendar-top-controls-bar.view-settings.releases',
		defaultMessage: 'Releases',
		description:
			'Label for the show "Releases" toggle on Jira calendar view settings pop-up "Show calendar items"\' section',
	},
	issues: {
		id: 'calendar.calendar-top-controls-bar.view-settings.issues',
		defaultMessage: 'Issues',
		description:
			'Label for the show "Issues" toggle on Jira calendar view settings pop-up "Show calendar items"\' section',
	},
	softwareReleaseDates: {
		id: 'calendar.calendar-top-controls-bar.view-settings.shared-releases',
		defaultMessage: 'Software release dates',
		description:
			'Label for the "Software release dates" section on Jira calendar view settings pop-up',
	},
	softwareReleaseDatesDescription: {
		id: 'calendar.calendar-top-controls-bar.view-settings.shared-releases-description',
		defaultMessage:
			'<t>Work closely with your software teams</t>, and <a>surface their release dates in your calendar.</a>',
		description:
			'Description for the "Software release dates" project picker on Jira calendar view settings pop-up',
	},
	sprints: {
		id: 'calendar.calendar-top-controls-bar.view-settings.sprints',
		defaultMessage: 'Sprints',
		description:
			'Label for the show "Sprints" toggle on Jira calendar view settings pop-up "Show calendar items"\' section',
	},
	weekSettings: {
		id: 'calendar.calendar-top-controls-bar.view-settings.week-settings',
		defaultMessage: 'Week settings',
		description: 'Label for the "Week settings" section on Jira calendar view settings pop-up',
	},
	showWeekends: {
		id: 'calendar.calendar-top-controls-bar.view-settings.show-weekends',
		defaultMessage: 'Show weekends',
		description:
			'Label for the show "Weekends" toggle on Jira calendar view settings pop-up "Show weekends items"\' section',
	},
	showIssueKeys: {
		id: 'calendar.calendar-top-controls-bar.view-settings.show-issue-keys',
		defaultMessage: 'Issue key',
		description:
			'Label for the show "Issue key" toggle on Jira calendar view settings pop-up "Show calendar items"\' section',
	},
	weekStartsOn: {
		id: 'calendar.calendar-top-controls-bar.view-settings.week-starts-on',
		defaultMessage: 'Week starts on',
		description:
			'Label for the "Week starts on" select control on Jira calendar view settings pop-up, which controls what day of the week the calendar starts on',
	},
	monday: {
		id: 'calendar.calendar-top-controls-bar.view-settings.monday',
		defaultMessage: 'Monday',
		description:
			'"Monday" day of the week option used to select what day of the week the Jira calendar starts on',
	},
	saturday: {
		id: 'calendar.calendar-top-controls-bar.view-settings.saturday',
		defaultMessage: 'Saturday',
		description:
			'"Saturday" day of the week option used to select what day of the week the Jira calendar starts on',
	},
	sunday: {
		id: 'calendar.calendar-top-controls-bar.view-settings.sunday',
		defaultMessage: 'Sunday',
		description:
			'"Sunday" day of the week option used to select what day of the week the Jira calendar starts on',
	},
	colorBy: {
		id: 'calendar.calendar-top-controls-bar.view-settings.color-by',
		defaultMessage: 'Color by',
		description:
			'Label for the "Color by" select control on Jira calendar view settings pop-up, which controls what color by the items will be based on',
	},
	status: {
		id: 'calendar.calendar-top-controls-bar.view-settings.status',
		defaultMessage: 'Status',
		description: '"Status" option used to select what color settings the item will be based on',
	},
	none: {
		id: 'calendar.calendar-top-controls-bar.view-settings.none',
		defaultMessage: 'None',
		description: '"None" option used to select what color settings the item will be based on',
	},
	openIssuesInSidebar: {
		id: 'calendar.calendar-top-controls-bar.view-settings.open-issues-in-sidebar',
		defaultMessage: 'Open issues in sidebar',
		description: 'A dropdown option to feature to open issues in a sidebar menu',
	},
	timeSettings: {
		id: 'calendar.calendar-top-controls-bar.view-settings.time-settings',
		defaultMessage: 'Time settings',
		description: 'Label for the "Week settings" section on Jira calendar view settings pop-up',
	},
	use24HourClock: {
		id: 'calendar.calendar-top-controls-bar.view-settings.use-24-hour-clock',
		defaultMessage: '24 hour time',
		description: 'Label for the time settings toggle on Jira calendar view settings pop-up',
	},
	openIssuesInSidebarIssueTermRefresh: {
		id: 'calendar.calendar-top-controls-bar.view-settings.open-issues-in-sidebar-issue-term-refresh',
		defaultMessage: 'Open work items in sidebar',
		description: 'A dropdown option to feature to open issues in a sidebar menu',
	},
	showIssueKeysIssueTermRefresh: {
		id: 'calendar.calendar-top-controls-bar.view-settings.show-issue-keys-issue-term-refresh',
		defaultMessage: 'Work item key',
		description: 'Label for the show "Work item key" toggle on Jira calendar view settings pop-up',
	},
	issuesIssueTermRefresh: {
		id: 'calendar.calendar-top-controls-bar.view-settings.issues-issue-term-refresh',
		defaultMessage: 'Work items',
		description:
			'Label for the show "Issues" toggle on Jira calendar view settings pop-up "Show calendar items"\' section',
	},
});
