import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	projectPermissionsWarningBannerText: {
		id: 'calendar.calendar-top-controls-bar.view-settings.connected-projects-picker.project-permissions-warning-banner-text',
		defaultMessage: 'Contact your project admin to add software release dates to this calendar.',
		description:
			'Text for the permissions warning underneath the project select when the user is not a project admin',
	},
});
