import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	manageIssueTypes: {
		id: 'calendar.calendar-renderer.event-renderer.placeholder-renderer.inline-card-create.issue-type-field.manage-issue-types',
		defaultMessage: 'Manage types',
		description:
			'Dropdown menu item. Clicking this will take the user to a page where they can manage the project issue types.',
	},
	issueTypeFieldLabel: {
		id: 'calendar.calendar-renderer.event-renderer.placeholder-renderer.inline-card-create.issue-type-field.issue-type-field-label',
		defaultMessage: 'Issue type',
		description: 'Field label for the issue type input when creating a new issue in calendar view',
	},
	issueTypeTrigger: {
		id: 'calendar.calendar-renderer.event-renderer.placeholder-renderer.inline-card-create.issue-type-field.issue-type-trigger',
		defaultMessage: 'Select issue type.',
		description: 'Button label for opening issue type selection dropdown when creating an issue',
	},
	issueTypeTriggerWithSelection: {
		id: 'calendar.calendar-renderer.event-renderer.placeholder-renderer.inline-card-create.issue-type-field.issue-type-trigger-with-selection',
		defaultMessage: 'Select issue type. {selectedIssueType} currently selected',
		description:
			'Button label for opening issue type selection dropdown when an issue type is currently selected',
	},
	loadingMore: {
		id: 'calendar.calendar-renderer.event-renderer.placeholder-renderer.inline-card-create.issue-type-field.loading-more',
		defaultMessage: 'Loading more issue types',
		description:
			'Message shown when issue type selection dropdown is loading more issue type options',
	},
	issueTypeFieldLabelIssueTermRefresh: {
		id: 'calendar.calendar-renderer.event-renderer.placeholder-renderer.inline-card-create.issue-type-field.issue-type-field-label-issue-term-refresh',
		defaultMessage: 'Work type',
		description: 'Field label for the issue type input when creating a new issue in calendar view',
	},
	issueTypeTriggerIssueTermRefresh: {
		id: 'calendar.calendar-renderer.event-renderer.placeholder-renderer.inline-card-create.issue-type-field.issue-type-trigger-issue-term-refresh',
		defaultMessage: 'Select work type.',
		description: 'Button label for opening issue type selection dropdown when creating an issue',
	},
	issueTypeTriggerWithSelectionIssueTermRefresh: {
		id: 'calendar.calendar-renderer.event-renderer.placeholder-renderer.inline-card-create.issue-type-field.issue-type-trigger-with-selection-issue-term-refresh',
		defaultMessage: 'Select work type. {selectedIssueType} currently selected',
		description:
			'Button label for opening issue type selection dropdown when an issue type is currently selected',
	},
	loadingMoreIssueTermRefresh: {
		id: 'calendar.calendar-renderer.event-renderer.placeholder-renderer.inline-card-create.issue-type-field.loading-more-issue-term-refresh',
		defaultMessage: 'Loading more work types',
		description:
			'Message shown when issue type selection dropdown is loading more issue type options',
	},
});
