import React, { useCallback, useEffect, useMemo } from 'react';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import type { AnalyticsSource } from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import type { IssueDeleteCallbackArgs } from '@atlassian/jira-issue-view-foundation/src/issue-actions/delete-issue/types.tsx';
import type { ChangeEvent } from '@atlassian/jira-issue-view-model/src/change-type.tsx';
import type { ViewModeOptions } from '@atlassian/jira-issue-view-model/src/view-mode-options.tsx';
import { IssueBoundary } from '@atlassian/jira-issue-view/src/async.tsx';
import type IssueAppType from '@atlassian/jira-issue-view/src/views/issue-details/issue-app.tsx';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { IssueViewSkeleton } from '@atlassian/jira-skeletons/src/ui/issue/IssueViewSkeleton.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import { useQueryParam } from '@atlassian/react-resource-router';
import { SELECTED_ISSUE } from '../../common/constants.tsx';
import { useCalendarIssueViewMode } from '../../common/controllers/use-calendar-issue-view-mode/index.tsx';
import { useCalendarUFOExperienceContext } from '../../controllers/ufo-context-provider/index.tsx';

export type Props = {
	analyticsSource: AnalyticsSource;
	onClose: () => void;
	onChange?: (event: ChangeEvent) => void;
	onIssueDeleteSuccess?: (arg1: IssueDeleteCallbackArgs) => void;
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyCalendarSidebarIssueApp = lazy<typeof IssueAppType>(
	() =>
		import(
			/* webpackChunkName: "async-issue-app" */ '@atlassian/jira-issue-view/src/views/issue-details/issue-app'
		),
	{ ssr: false },
);

export function CalendarSidebarIssueApp({
	analyticsSource,
	onClose,
	onChange,
	onIssueDeleteSuccess,
}: Props) {
	const { setCalendarIssueViewAsModal, setCalendarIssueViewAsSidebar } = useCalendarIssueViewMode();
	const [selectedIssueKey, setSelectedIssueKey] = useQueryParam(SELECTED_ISSUE);

	const prevIssueKey = usePreviousWithInitial(selectedIssueKey);

	const onIssueKeyChange = useCallback(
		({ toIssueKey: nextIssueKey }: { toIssueKey: string }) => {
			setSelectedIssueKey(nextIssueKey);
		},
		[setSelectedIssueKey],
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		if (prevIssueKey !== selectedIssueKey) {
			setSelectedIssueKey(selectedIssueKey);
		}
	}, [setSelectedIssueKey, prevIssueKey, selectedIssueKey]);

	const onSwitchToModal = useCallback(() => {
		setCalendarIssueViewAsModal();
		fireUIAnalytics(createAnalyticsEvent({}), 'issueAppMenuItem clicked', 'switchToModal');
	}, [createAnalyticsEvent, setCalendarIssueViewAsModal]);

	const onSwitchToSidebar = useCallback(() => {
		setCalendarIssueViewAsSidebar();
		fireUIAnalytics(createAnalyticsEvent({}), 'issueAppMenuItem clicked', 'switchToSidebar');
	}, [createAnalyticsEvent, setCalendarIssueViewAsSidebar]);

	const viewModeOptions = useMemo(
		(): ViewModeOptions | undefined => ({
			viewMode: 'SIDEBAR',
			viewModeSwitchEnabled: true,
			onSwitchToModal,
			onSwitchToSidebar,
		}),
		[onSwitchToModal, onSwitchToSidebar],
	);

	const { packageName } = useCalendarUFOExperienceContext();

	if (!selectedIssueKey) {
		return null;
	}

	return (
		<Placeholder name="issue-app" fallback={<IssueViewSkeleton />}>
			<IssueBoundary packageName={packageName}>
				<LazyCalendarSidebarIssueApp
					viewModeOptions={viewModeOptions}
					issueKey={selectedIssueKey}
					analyticsSource={analyticsSource}
					shouldShowCloseButton
					onClose={onClose}
					onIssueKeyChange={onIssueKeyChange}
					shouldSetInitialFocus
					shouldShowRootProjectsBreadcrumb
					onIssueDeleteSuccess={onIssueDeleteSuccess}
					onIssueDeleteFailure={() => undefined}
					isSpaEnabled={false}
					isLoadedWithPage={false}
					onChange={onChange}
				/>
			</IssueBoundary>
		</Placeholder>
	);
}
