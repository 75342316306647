/**
 * @generated SignedSource<<4d6e3eef0224dc67eead2e66b5d47b8c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type calendarIssueViewModal_calendar$data = {
  readonly jira: {
    readonly jiraCalendar: {
      readonly endDateField: {
        readonly fieldId: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "calendarIssueViewModal_calendar";
};
export type calendarIssueViewModal_calendar$key = {
  readonly " $data"?: calendarIssueViewModal_calendar$data;
  readonly " $fragmentSpreads": FragmentRefs<"calendarIssueViewModal_calendar">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "configurationInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeInput"
    }
  ],
  "kind": "Fragment",
  "name": "calendarIssueViewModal_calendar",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "configuration",
              "variableName": "configurationInput"
            },
            {
              "kind": "Variable",
              "name": "scope",
              "variableName": "scopeInput"
            }
          ],
          "concreteType": "JiraCalendar",
          "kind": "LinkedField",
          "name": "jiraCalendar",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "endDateField",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "fieldId"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "680debdccca4be908227acec3530dc07";

export default node;
