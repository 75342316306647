import { useMemo } from 'react';
import { useCalendarScope } from '../calendar-store/index.tsx';
import type { CalendarScope } from '../calendar-store/types.tsx';

function buildViewId(scope: CalendarScope): string | null {
	if (scope.type === 'plan') {
		return scope.ids[0];
	}

	if (scope.type === 'board') {
		return scope.viewId ?? null;
	}
	return null;
}

export function useCalendarViewId(): {
	viewId: string | null;
} {
	const scope = useCalendarScope();

	const viewId = useMemo(() => buildViewId(scope), [scope]);

	return {
		viewId,
	};
}
