import React from 'react';
import { graphql, usePaginationFragment } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import type { fieldValuesList_softwareFiltersPopup$key } from '@atlassian/jira-relay/src/__generated__/fieldValuesList_softwareFiltersPopup.graphql';
import type { fieldValuesList_softwareFiltersPopup_RefetchQuery } from '@atlassian/jira-relay/src/__generated__/fieldValuesList_softwareFiltersPopup_RefetchQuery.graphql';
import { PaginationTrigger } from '@atlassian/jira-software-pagination-trigger/src/index.tsx';
import type { JQLModel } from '../../utils/jql-model/index.tsx';
import { FieldValueCheckbox } from '../field-value-checkbox/index.tsx';

interface FieldValuesListProps {
	/**
	 * The JQL model used to highlight selected filters.
	 */
	jqlModel: JQLModel;
	/**
	 * The name of the field shown.
	 */
	fieldName: string;
	/**
	 * This function is called when a filter value option is clicked on and toggled.
	 */
	onToggleFilter: (fieldName: string, value: string) => void;
	/**
	 * A relay fragment key for the `Query` that contains the field values.
	 *
	 * This component fragment is over query so that it can re-fetch pages on scroll.
	 */
	queryRef: fieldValuesList_softwareFiltersPopup$key | null;
}

/**
 * This is an infinite scrolling list of field value checkboxes.
 */
export function FieldValuesList({
	queryRef,
	fieldName,
	jqlModel,
	onToggleFilter,
}: FieldValuesListProps) {
	const { data, hasNext, loadNext, isLoadingNext } = usePaginationFragment<
		fieldValuesList_softwareFiltersPopup_RefetchQuery,
		fieldValuesList_softwareFiltersPopup$key
	>(
		graphql`
			fragment fieldValuesList_softwareFiltersPopup on Query
			@argumentDefinitions(
				cloudId: { type: "ID!" }
				fieldName: { type: "String!" }
				first: { type: "Int" }
				after: { type: "String" }
				jqlContext: { type: "String" }
				searchString: { type: "String" }
			)
			@refetchable(queryName: "fieldValuesList_softwareFiltersPopup_RefetchQuery") {
				jira {
					jqlBuilder(cloudId: $cloudId) {
						fieldValues(
							jqlTerm: $fieldName
							first: $first
							after: $after
							jqlContext: $jqlContext
							searchString: $searchString
						) @connection(key: "filterValuesList_softwareFiltersPopup_fieldValues") {
							pageInfo {
								hasNextPage
							}

							edges {
								cursor

								node {
									...fieldValueCheckbox_softwareFiltersPopup
								}
							}
						}
					}
				}
			}
		`,
		queryRef,
	);

	return (
		<Box xcss={styles}>
			{data?.jira?.jqlBuilder?.fieldValues?.edges?.map(
				(edge) =>
					edge?.node && (
						<FieldValueCheckbox
							key={edge.cursor}
							fieldName={fieldName}
							fieldValueRef={edge.node}
							jqlModel={jqlModel}
							onToggleFilter={onToggleFilter}
						/>
					),
			)}

			<PaginationTrigger
				hasNextPage={hasNext}
				isLoadingNext={isLoadingNext}
				loadNext={loadNext}
				pageSize={10}
			/>
		</Box>
	);
}

const styles = xcss({ width: '100%' });
