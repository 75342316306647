import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	btnLabel: {
		id: 'calendar.calendar-top-controls-bar.more-menu.btn-label',
		defaultMessage: 'More',
		description:
			'Button text for the More menu, includes options: Add People, Give feedback and possibly other options',
	},
	label: {
		id: 'calendar.calendar-top-controls-bar.more-menu.label',
		defaultMessage: 'More actions',
		description:
			'Label text for the More menu, includes options: Add People, Give feedback and possibly other options',
	},
	addPeople: {
		id: 'calendar.calendar-top-controls-bar.more-menu.add-people',
		defaultMessage: 'Add people',
		description: 'Button text to add people to the calendar',
	},
});
