import React from 'react';
import { useFragment, graphql } from 'react-relay';
import Lozenge from '@atlaskit/lozenge';
import type { labelFieldValueLabel_softwareFiltersPopup$key } from '@atlassian/jira-relay/src/__generated__/labelFieldValueLabel_softwareFiltersPopup.graphql';

export function LabelFieldValueLabel({
	fieldValueRef,
}: {
	fieldValueRef: labelFieldValueLabel_softwareFiltersPopup$key;
}) {
	const data = useFragment(
		graphql`
			fragment labelFieldValueLabel_softwareFiltersPopup on JiraJqlLabelFieldValue {
				label {
					name
				}
			}
		`,
		fieldValueRef,
	);

	return <Lozenge appearance="default">{data.label?.name}</Lozenge>;
}
