/**
 * @generated SignedSource<<45290f8181df87465be9df6e7f2c9285>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type priorityFieldValueLabel_softwareFiltersPopup$data = {
  readonly priority: {
    readonly iconUrl: AGG$URL | null | undefined;
    readonly name: string | null | undefined;
  };
  readonly " $fragmentType": "priorityFieldValueLabel_softwareFiltersPopup";
};
export type priorityFieldValueLabel_softwareFiltersPopup$key = {
  readonly " $data"?: priorityFieldValueLabel_softwareFiltersPopup$data;
  readonly " $fragmentSpreads": FragmentRefs<"priorityFieldValueLabel_softwareFiltersPopup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "priorityFieldValueLabel_softwareFiltersPopup",
  "selections": [
    {
      "concreteType": "JiraPriority",
      "kind": "LinkedField",
      "name": "priority",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "iconUrl"
        },
        {
          "kind": "ScalarField",
          "name": "name"
        }
      ]
    }
  ],
  "type": "JiraJqlPriorityFieldValue"
};

(node as any).hash = "1773d18006e3f99a74c059528d87a1c2";

export default node;
