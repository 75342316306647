import React from 'react';
import { styled } from '@compiled/react';
import { useFragment, graphql } from 'react-relay';
import { useIntl } from '@atlassian/jira-intl';
import type { calendarCardPriority_calendar$key } from '@atlassian/jira-relay/src/__generated__/calendarCardPriority_calendar.graphql';
import { messages } from './messages.tsx';

interface CalendarCardPriorityProps {
	issueRef: calendarCardPriority_calendar$key;
}

export function CalendarCardPriority({ issueRef }: CalendarCardPriorityProps) {
	const data = useFragment(
		graphql`
			fragment calendarCardPriority_calendar on JiraIssue {
				priorityField {
					name
					priority {
						iconUrl
					}
				}
			}
		`,
		issueRef,
	);
	const priority = data.priorityField;
	const { formatMessage } = useIntl();

	if (!priority || !priority.priority?.iconUrl) {
		return null;
	}

	return (
		<PriorityIcon
			data-testid="calendar.ui.calendar-unscheduled-panel.calendar-unscheduled-panel-card-list.calendar-unscheduled-panel-card.calendar-card-priority.priority-icon"
			src={priority.priority.iconUrl}
			alt={formatMessage(messages.priorityAltText, { priority: priority.name })}
		/>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PriorityIcon = styled.img({
	width: 16,
	heigth: 16,
});
