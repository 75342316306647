import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	shareTitle: {
		id: 'calendar.calendar-top-controls-bar.share-button.share-title',
		defaultMessage: 'Calendar',
		description:
			'Name of the entity type that is going to be shared to others, this will be used as email title for the share recipients',
	},
	share: {
		id: 'calendar.calendar-top-controls-bar.share-button.share',
		defaultMessage: 'Share',
		description: 'Message for button to show Share dialog',
	},
	shareTooltipText: {
		id: 'calendar.calendar-top-controls-bar.share-button.share-tooltip-text',
		defaultMessage: 'Embed this Smart Link in other Atlassian products such as Confluence',
		description: 'Message shown in copy link tooltip describing how the share link can be used',
	},
	shareHelperMessage: {
		id: 'calendar.calendar-top-controls-bar.share-button.share-helper-message',
		defaultMessage: 'This view, along with any applied groups and filters are shared',
		description:
			'Message indicating the recipients of the email share will be able to see the JWM view and any applied groups and filters',
	},
});
