import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';

type UFOExperienceFactoryOptions = {
	suffixKey: string;
};

export function createUFOExperiences({ suffixKey }: UFOExperienceFactoryOptions) {
	return {
		refetchCalendarIssuesExperience: new UFOExperience(
			`calendar-issues-refetch-experience-${suffixKey}`,
			{
				type: ExperienceTypes.Operation,
				performanceType: ExperiencePerformanceTypes.Custom,
			},
		),

		refetchCalendarVersionsExperience: new UFOExperience(
			`calendar-versions-refetch-experience-${suffixKey}`,
			{
				type: ExperienceTypes.Operation,
				performanceType: ExperiencePerformanceTypes.Custom,
			},
		),

		refetchCalendarExperience: new UFOExperience(`calendar-refetch-experience-${suffixKey}`, {
			type: ExperienceTypes.Operation,
			performanceType: ExperiencePerformanceTypes.Custom,
		}),

		scheduleIssueExperience: new UFOExperience(`calendar-schedule-issue-experience-${suffixKey}`, {
			type: ExperienceTypes.Experience,
			performanceType: ExperiencePerformanceTypes.InlineResult,
		}),

		scheduleVersionExperience: new UFOExperience(
			`calendar-schedule-version-experience-${suffixKey}`,
			{
				type: ExperienceTypes.Experience,
				performanceType: ExperiencePerformanceTypes.InlineResult,
			},
		),

		scheduleSprintExperience: new UFOExperience(
			`calendar-schedule-sprint-experience-${suffixKey}`,
			{
				type: ExperienceTypes.Experience,
				performanceType: ExperiencePerformanceTypes.InlineResult,
			},
		),

		viewUnscheduledPanelListExperience: new UFOExperience(
			`calendar-view-unscheduled-panel-experience-${suffixKey}`,
			{
				type: ExperienceTypes.Load,
				performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
			},
		),

		refetchUnscheduledPanelListExperience: new UFOExperience(
			`calendar-refetch-unscheduled-panel-experience-${suffixKey}`,
			{
				type: ExperienceTypes.Operation,
				performanceType: ExperiencePerformanceTypes.Custom,
			},
		),

		createCalendarIssueExperience: new UFOExperience(
			`calendar-create-issue-experience-${suffixKey}`,
			{
				type: ExperienceTypes.Experience,
				performanceType: ExperiencePerformanceTypes.InlineResult,
			},
		),

		createCalendarIssueSubscriptionExperience: new UFOExperience(
			`calendar-subscription-create-issue-experience-${suffixKey}`,
			{
				type: ExperienceTypes.Experience,
				performanceType: ExperiencePerformanceTypes.InlineResult,
			},
		),

		updateCalendarIssueSubscriptionExperience: new UFOExperience(
			`calendar-subscription-update-issue-experience-${suffixKey}`,
			{
				type: ExperienceTypes.Experience,
				performanceType: ExperiencePerformanceTypes.InlineResult,
			},
		),

		deleteCalendarIssueExperience: new UFOExperience(
			`calendar-subscription-delete-issue-experience-${suffixKey}`,
			{
				type: ExperienceTypes.Experience,
				performanceType: ExperiencePerformanceTypes.InlineResult,
			},
		),
	};
}

export type CalendarExperiences = ReturnType<typeof createUFOExperiences>;
