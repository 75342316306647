import React from 'react';
import { useFragment, graphql } from 'react-relay';
import Avatar from '@atlaskit/avatar';
import type { eventAssignee_calendar$key } from '@atlassian/jira-relay/src/__generated__/eventAssignee_calendar.graphql';

interface EventAssigneeProps {
	issueKey: eventAssignee_calendar$key | null;
}

export function EventAssignee({ issueKey }: EventAssigneeProps) {
	const data = useFragment(
		graphql`
			fragment eventAssignee_calendar on JiraScenarioIssueLike
			@argumentDefinitions(viewId: { type: "ID" }) {
				... on JiraIssue {
					assigneeField {
						user {
							name
							picture
						}
					}
					planScenarioValues(viewId: $viewId) @optIn(to: "JiraPlansSupport") {
						assigneeField {
							user {
								name
								picture
							}
						}
					}
				}
				... on JiraScenarioIssue {
					planScenarioValues(viewId: $viewId) @optIn(to: "JiraPlansSupport") {
						assigneeField {
							user {
								name
								picture
							}
						}
					}
				}
			}
		`,
		issueKey,
	);

	const assigneeField = data?.planScenarioValues?.assigneeField ?? data?.assigneeField;

	const user = assigneeField?.user;

	if (!user) return null;

	return (
		<Avatar name={user.name ?? ''} src={user.picture} size="xsmall" borderColor="transparent" />
	);
}
