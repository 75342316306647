import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	requestType: {
		id: 'spa-apps-servicedesk-calendar.request-type',
		defaultMessage: 'Request type',
		description: 'This text is displayed in a JQL filter for calendar',
	},
	configureCalendarMenuItemTitle: {
		id: 'spa-apps-servicedesk-calendar.configure-calendar-menu-item-title',
		defaultMessage: 'Configure calendar',
		description: 'Title for additional memnu item to be renderd in calendar header control',
	},
});
