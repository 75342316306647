import React, {
	useEffect,
	type FormEvent,
	type MouseEvent,
	useCallback,
	useState,
	useMemo,
} from 'react';
import { styled } from '@compiled/react';
import debounce from 'lodash/debounce';
import { graphql, useFragment } from 'react-relay';
import Button from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { Box, Inline } from '@atlaskit/primitives';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import Spinner from '@atlaskit/spinner';
import Textfield from '@atlaskit/textfield';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	fireUIAnalytics,
	type UIAnalyticsEvent,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { calendarUnscheduledPanel_calendar$key } from '@atlassian/jira-relay/src/__generated__/calendarUnscheduledPanel_calendar.graphql';
import { useQueryParam } from '@atlassian/react-resource-router';
import { SELECTED_ISSUE } from '../../common/constants.tsx';
import { UnscheduledPanelSpotlightTarget } from '../../common/ui/onboarding/index.tsx';
import {
	useCalendarActions,
	useCalendarPanelIsLoading,
	useIsUnscheduledPanelFilterOptionOpen,
} from '../../controllers/calendar-store/index.tsx';
import {
	CalendarUnscheduledPanelCardList,
	CalendarUnscheduledPanelCardListFallback,
} from './calendar-unscheduled-panel-card-list/index.tsx';
import { messages } from './messages.tsx';

export interface CalendarUnscheduledPanelProps {
	queryRef: calendarUnscheduledPanel_calendar$key;
	jqlBuilderQuery?: string;
}

/**
 * The unscheduled cards calendar view panel.
 */
export function CalendarUnscheduledPanel({
	queryRef,
	jqlBuilderQuery,
}: CalendarUnscheduledPanelProps) {
	const { formatMessage } = useIntl();
	const data = useFragment(
		graphql`
			fragment calendarUnscheduledPanel_calendar on Query
			@argumentDefinitions(
				scopeInput: { type: "JiraViewScopeInput!" }
				configurationInput: { type: "JiraCalendarViewConfigurationInput!" }
				unscheduledIssuesSearchInput: { type: "JiraCalendarIssuesInput" }
				viewId: { type: "ID" }
				schedulePermissionsEnabled: { type: "Boolean!" }
			) {
				...calendarUnscheduledPanelCardList_calendar_queryRef
					@arguments(
						scopeInput: $scopeInput
						configurationInput: $configurationInput
						unscheduledIssuesSearchInput: $unscheduledIssuesSearchInput
						viewId: $viewId
						schedulePermissionsEnabled: $schedulePermissionsEnabled
					)
			}
		`,
		queryRef,
	);

	const [search, setSearch] = useState('');
	const { setPanelSearchString, toggleUnscheduledPanelVisibility } = useCalendarActions();
	const setPanelSearchStringDebounced = useMemo(
		() => debounce(setPanelSearchString, 300),
		[setPanelSearchString],
	);
	const onChangeSearch = (e: FormEvent<HTMLInputElement>) => {
		setSearch(e.currentTarget.value);
		setPanelSearchStringDebounced(e.currentTarget.value);
	};
	const [selectedIssueKey] = useQueryParam(SELECTED_ISSUE);

	const isLoading = useCalendarPanelIsLoading();
	const isFilterOptionOpen = useIsUnscheduledPanelFilterOptionOpen();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClickClose = (_: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
		toggleUnscheduledPanelVisibility();
		fireUIAnalytics(analyticsEvent, 'unscheduledPanelClose');
	};

	const onEscapePress = useCallback(
		(event: KeyboardEvent) => {
			if (fg('calendar_unscheduled_esc_issue_modal_open')) {
				if (selectedIssueKey) return;
			}
			if (
				(event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) &&
				!isFilterOptionOpen
			) {
				toggleUnscheduledPanelVisibility();
				fireUIAnalytics(
					createAnalyticsEvent({
						actionSubject: 'keyboardShortcut',
						action: 'pressed',
					}),
					'unscheduledPanelClose',
				);
			}
		},
		[selectedIssueKey, isFilterOptionOpen, createAnalyticsEvent, toggleUnscheduledPanelVisibility],
	);

	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('keydown', onEscapePress);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		return () => document.removeEventListener('keydown', onEscapePress);
	}, [onEscapePress, toggleUnscheduledPanelVisibility]);

	const panel = (
		<>
			<Inline testId="calendar.ui.calendar-unscheduled-panel.heading">
				<Inline grow="fill" space="space.100" alignBlock="center">
					<Heading>{formatMessage(messages.scheduleYourWork)}</Heading>
					{isLoading && <Spinner size="medium" />}
				</Inline>
				<Box>
					<Button appearance="subtle" onClick={onClickClose} spacing="none">
						<CrossIcon label={formatMessage(messages.closePanel)} />
					</Button>
				</Box>
			</Inline>
			<>
				{formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.unscheduledDescriptionIssueTermRefresh
						: messages.unscheduledDescription,
				)}
			</>

			<Inline>
				<VisuallyHidden>
					<label htmlFor="calendar.ui.calendar-unscheduled-panel.textField">
						{formatMessage(messages.searchPlaceholder)}
					</label>
				</VisuallyHidden>
				<Textfield
					isCompact
					placeholder={formatMessage(messages.searchPlaceholder)}
					onChange={onChangeSearch}
					value={search ?? ''}
					id="calendar.ui.calendar-unscheduled-panel.textField"
				/>
			</Inline>

			{/* TODO: This needs to be moved inwards so that the button does not unmount */}
			<Placeholder
				fallback={<CalendarUnscheduledPanelCardListFallback />}
				name="CalendarUnscheduledPanelCardList"
			>
				<CalendarUnscheduledPanelCardList queryRef={data} jqlBuilderQuery={jqlBuilderQuery} />
			</Placeholder>
		</>
	);

	return fg('software_calendar_quickstart_onboarding') ? (
		<UnscheduledPanelSpotlightTarget>{panel}</UnscheduledPanelSpotlightTarget>
	) : (
		panel
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Heading = styled.h2({
	margin: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.heading.medium', fontFallback.heading.medium),
});
