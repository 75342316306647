import React, { forwardRef } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';

import { type PopupComponentProps } from '@atlaskit/popup/types';
import { N0, N50A, N60A } from '@atlaskit/theme/colors';
import { borderRadius, layers } from '@atlaskit/theme/constants';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Trigger = styled.div<{
	top: number;
	left: number;
	width: number;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>((props) => ({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top: `${props.top}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	left: `${props.left}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${props.width}px`,
}));

Trigger.displayName = 'Trigger';

// These styles are copied from @atlaskit/popup's container styles
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledPopupComponent = styled.div({
	backgroundColor: token('elevation.surface.overlay', N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius()}px`,
	boxShadow: token('elevation.shadow.overlay', `0 4px 8px 0 ${N50A}, 0 0 1px 0 ${N60A}`),
	boxSizing: 'border-box',
	display: 'block',
	flex: '1 1 auto',
	zIndex: layers.layer(),
	'&:focus': {
		outline: 'none',
	},
});

export const PopupComponentWithDropShadow = forwardRef<HTMLDivElement, PopupComponentProps>(
	// styled-components has a very permissive type saying that _any_ DOM element could be
	// set as the inner ref (which sort of makes sense because of the .withComponent(type) function)
	// but in this case it'll never not be a div because we don't use that tomfoolery!
	// @ts-expect-error: incorrect innerRef typing
	(props, ref) => <StyledPopupComponent {...props} innerRef={ref} />,
);
