import { useCallback } from 'react';
import { useRelayEnvironment, type RefetchFnDynamic } from 'react-relay';
import { createOperationDescriptor, fetchQuery, type Subscription } from 'relay-runtime';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { startCaptureGraphQlErrors } from '@atlassian/jira-relay-errors/src/index.tsx';
import type { calendarRendererVersions_jira$key } from '@atlassian/jira-relay/src/__generated__/calendarRendererVersions_jira.graphql';
import VERSIONS_REFETCH_QUERY, {
	type calendarRendererVersions_jira_refetchQuery,
} from '@atlassian/jira-relay/src/__generated__/calendarRendererVersions_jira_refetchQuery.graphql';
import { startCapturingTraceIds, stopCapturingTraceIds } from '@atlassian/relay-traceid';
import { setInteractionError } from '@atlassian/ufo-set-interaction-error';
import { getGraphQLErrors } from '../../common/utils/agg-errors/index.tsx';
import { useCalendarActions } from '../calendar-store/index.tsx';
import { useCalendarExperience } from '../use-calendar-experience/index.tsx';
import { useRefetchVersionsCalendarVariables } from '../use-refetch-versions-variables/index.tsx';
import { messages } from './messages.tsx';

type CalendarVersionsRefetcher = {
	refetchVersionsWithoutSuspense: (selectedProjects?: string[]) => Subscription;
};

const operationName = VERSIONS_REFETCH_QUERY.params.name;

/**
 * Custom hook that returns a refetch function for calendar versions that does not suspend.
 *
 * @param refetch - The refetch function for the calendar versions query.
 * @returns An object containing the refetch function for calendar versions.
 */
export function useRefetchCalendarVersions({
	refetch,
}: {
	refetch?: RefetchFnDynamic<
		calendarRendererVersions_jira_refetchQuery,
		calendarRendererVersions_jira$key
	>;
}): CalendarVersionsRefetcher {
	const environment = useRelayEnvironment();
	const { variables } = useRefetchVersionsCalendarVariables();

	const { refetchCalendarVersionsExperience } = useCalendarExperience();
	const { setIsLoading } = useCalendarActions();

	const { showFlag, dismissFlag } = useFlagService();
	const { formatMessage } = useIntl();

	const refetchVersionsWithoutSuspense = useCallback(
		(selectedProjects?: string[]) => {
			const variableOptions = {
				...variables,
				versionsSearchInput: {
					...variables.versionsSearchInput,
					additionalProjectAris: selectedProjects,
					includeSharedReleases: !selectedProjects,
				},
			};

			setIsLoading(true);

			const queryId = startCaptureGraphQlErrors();
			startCapturingTraceIds(operationName);
			refetchCalendarVersionsExperience.start();

			const operationAvailability = environment.check(
				createOperationDescriptor(VERSIONS_REFETCH_QUERY, variables),
			);
			if (refetch && operationAvailability?.status === 'available') {
				refetch(variables, { fetchPolicy: 'store-only' });
			}

			const subscription = fetchQuery(environment, VERSIONS_REFETCH_QUERY, variableOptions, {
				fetchPolicy: 'network-only',
			}).subscribe({
				complete() {
					setIsLoading(false);
					if (fg('calendar_handles_graphql_error')) {
						const graphQLError = getGraphQLErrors(queryId, operationName);
						if (graphQLError) {
							setInteractionError(refetchCalendarVersionsExperience.id, {
								name: 'fetch.calendar.versions.failure',
								errorMessage: graphQLError.message,
							});
							refetchCalendarVersionsExperience.failure();

							log.safeErrorWithoutCustomerData(
								'fetch.calendar.versions.failure',
								'Failed to fetch calendar versions.',
								graphQLError,
							);

							showFlag({
								id: 'jira-calendar-versions-fetch-error',
								title: formatMessage(messages.errorTitle),
								description: formatMessage(messages.failedToFetchDataError),
								error: graphQLError,
								type: 'error',
							});
						} else {
							refetchCalendarVersionsExperience.success();
						}
					} else {
						refetchCalendarVersionsExperience.success();
					}

					stopCapturingTraceIds(operationName);
				},
				error(error: Error) {
					setIsLoading(false);

					setInteractionError(refetchCalendarVersionsExperience.id, {
						name: 'fetch.calendar.versions.failure',
						errorMessage: `Failed to fetch calendar versions. Error: ${error}`,
					});
					refetchCalendarVersionsExperience.failure();

					log.safeErrorWithoutCustomerData(
						'fetch.calendar.versions.failure',
						'Failed to fetch calendar versions',
						error,
					);

					showFlag({
						id: 'jira-calendar-versions-fetch-error',
						title: formatMessage(messages.errorTitle),
						description: formatMessage(messages.failedToFetchDataError),
						error,
						type: 'error',
					});
				},
				next() {
					refetch?.(variableOptions, { fetchPolicy: 'store-only' });

					dismissFlag({
						id: 'jira-calendar-versions-fetch-error',
						command: 'DISMISS',
					});
					stopCapturingTraceIds(operationName);
				},
			});

			return subscription;
		},
		[
			dismissFlag,
			environment,
			formatMessage,
			refetch,
			refetchCalendarVersionsExperience,
			setIsLoading,
			showFlag,
			variables,
		],
	);

	return { refetchVersionsWithoutSuspense };
}
