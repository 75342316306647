import React, { useCallback, useEffect, useRef } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';

interface ResizePanelHandleProps {
	setWidth: (newWidth: number) => void;
	isMouseDown: boolean;
	setIsMouseDown: (d: boolean) => void;
	onMouseOver: () => void;
	onMouseOut: () => void;
	getCurrentWidth: () => number;
	minWidth: number;
	maxWidth: number;
}

export function ResizePanelHandle({
	setWidth,
	isMouseDown,
	onMouseOver,
	onMouseOut,
	setIsMouseDown,
	getCurrentWidth,
	minWidth,
	maxWidth,
}: ResizePanelHandleProps) {
	const initialPosition = useRef(0);
	const initialWidth = useRef(0);
	const desiredWidth = useRef(0);
	const animationFrameId = useRef<number>();

	// Use requestAnimationFrame to throttle the mouse move event
	const onMouseMove = useCallback(
		(e: MouseEvent) => {
			if (animationFrameId.current) {
				cancelAnimationFrame(animationFrameId.current);
			}

			animationFrameId.current = requestAnimationFrame(() => {
				const deltaX = e.clientX - initialPosition.current;
				desiredWidth.current = initialWidth.current - deltaX; // Corrected calculation for right-mounted column

				const clampedWidth = Math.max(minWidth, Math.min(desiredWidth.current, maxWidth));
				setWidth(clampedWidth);
			});
		},
		[setWidth, minWidth, maxWidth],
	);

	const onMouseUp = useCallback(() => {
		setIsMouseDown(false);
		if (typeof document !== 'undefined') {
			document.body.classList.remove('calendar-disable-user-select');
		}
		if (typeof window !== 'undefined') {
			window.removeEventListener('mouseup', onMouseUp);
			window.removeEventListener('mousemove', onMouseMove);
		}
		setWidth(desiredWidth.current);
	}, [onMouseMove, setIsMouseDown, setWidth]);

	const onMouseDown = useCallback(
		(e: React.MouseEvent) => {
			setIsMouseDown(true);
			if (typeof document !== 'undefined') {
				document.body.classList.add('calendar-disable-user-select');
			}

			initialPosition.current = e.clientX;
			initialWidth.current = getCurrentWidth();
			if (typeof window !== 'undefined') {
				window.addEventListener('mousemove', onMouseMove);
				window.addEventListener('mouseup', onMouseUp);
			}
		},
		[onMouseMove, onMouseUp, setIsMouseDown, getCurrentWidth],
	);

	// Cleanup event listeners on unmount
	useEffect(
		() => () => {
			if (typeof document !== 'undefined') {
				document.body.classList.remove('calendar-disable-user-select');
			}
			if (typeof window !== 'undefined') {
				window.removeEventListener('mouseup', onMouseUp);
				window.removeEventListener('mousemove', onMouseMove);
			}
			if (animationFrameId.current) {
				cancelAnimationFrame(animationFrameId.current);
			}
		},
		[onMouseMove, onMouseUp],
	);

	const testId = fg('jira-calendar-remove-old-resizable-panel')
		? 'calendar.common.ui.resizable-sidebar-panel.resize-panel-handle.resize-handle-container'
		: 'calendar.common.ui.resizeable-sidebar-panel-new.resize-panel-handle.resize-handle-container';

	return (
		<ResizeHandleContainer
			data-testid={testId}
			isMouseDown={isMouseDown}
			onMouseDown={onMouseDown}
			onMouseOver={onMouseOver}
			onMouseOut={onMouseOut}
		>
			<ResizeHandleIndicator data-component-selector="resize-handle-indicator" />
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles */}
			<style>
				{`
                .calendar-disable-user-select * {
                    user-select: none;
                }
                `}
			</style>
		</ResizeHandleContainer>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResizeHandleContainer = styled.div<{ isMouseDown: boolean }>({
	pointerEvents: 'initial',
	position: 'absolute',
	left: '-8px',
	width: '16px',
	height: 'calc(100% - 4px)',
	top: token('space.050', '4px'),
	borderRadius: '100%',
	cursor: 'ew-resize',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	opacity: ({ isMouseDown }) => (isMouseDown ? 0.7 : 1),
	transition: 'opacity 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.12)',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'&:hover > [data-component-selector="resize-handle-indicator"]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		backgroundColor: token('color.background.accent.blue.bolder', colors.B100),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResizeHandleIndicator = styled.div({
	position: 'absolute',
	left: token('space.075', '6px'),
	top: 'calc(50% - 25px)',
	width: '2px',
	height: '50px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
	backgroundColor: token('color.background.accent.gray.subtle', colors.N100),
	transition: 'background-color 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.12)',
});
