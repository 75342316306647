/**
 * @generated SignedSource<<014f1196692b1364c5b09d1c1b2a55ad>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type calendarUnscheduledPanelCardList_calendar_queryRef$data = {
  readonly " $fragmentSpreads": FragmentRefs<"calendarUnscheduledPanelCardList_calendar_CalendarUnscheduledPanelCardListInner_queryRef">;
  readonly " $fragmentType": "calendarUnscheduledPanelCardList_calendar_queryRef";
};
export type calendarUnscheduledPanelCardList_calendar_queryRef$key = {
  readonly " $data"?: calendarUnscheduledPanelCardList_calendar_queryRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"calendarUnscheduledPanelCardList_calendar_queryRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "configurationInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "schedulePermissionsEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "unscheduledIssuesSearchInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "name": "calendarUnscheduledPanelCardList_calendar_queryRef",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "configurationInput",
          "variableName": "configurationInput"
        },
        {
          "kind": "Variable",
          "name": "cursor",
          "variableName": "cursor"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "schedulePermissionsEnabled",
          "variableName": "schedulePermissionsEnabled"
        },
        {
          "kind": "Variable",
          "name": "scopeInput",
          "variableName": "scopeInput"
        },
        {
          "kind": "Variable",
          "name": "unscheduledIssuesSearchInput",
          "variableName": "unscheduledIssuesSearchInput"
        },
        {
          "kind": "Variable",
          "name": "viewId",
          "variableName": "viewId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "calendarUnscheduledPanelCardList_calendar_CalendarUnscheduledPanelCardListInner_queryRef"
    }
  ],
  "type": "Query"
};

(node as any).hash = "08c4eac5a59d714eda247c02d5dc2106";

export default node;
