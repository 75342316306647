/**
 * @generated SignedSource<<219b31fc0f0b66da0138106160599a9d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraSprintState = "ACTIVE" | "CLOSED" | "FUTURE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type sprintRenderer_calendar_SprintEventRenderer$data = {
  readonly __id: string;
  readonly completionDate: AGG$DateTime | null | undefined;
  readonly endDate: AGG$DateTime | null | undefined;
  readonly goal: string | null | undefined;
  readonly id: string;
  readonly name: string | null | undefined;
  readonly sprintId: string;
  readonly startDate: AGG$DateTime | null | undefined;
  readonly state: JiraSprintState | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ui_SprintFlyout">;
  readonly " $fragmentType": "sprintRenderer_calendar_SprintEventRenderer";
};
export type sprintRenderer_calendar_SprintEventRenderer$key = {
  readonly " $data"?: sprintRenderer_calendar_SprintEventRenderer$data;
  readonly " $fragmentSpreads": FragmentRefs<"sprintRenderer_calendar_SprintEventRenderer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "sprintRenderer_calendar_SprintEventRenderer",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "sprintId"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "goal"
    },
    {
      "kind": "ScalarField",
      "name": "state"
    },
    {
      "kind": "ScalarField",
      "name": "startDate"
    },
    {
      "kind": "ScalarField",
      "name": "endDate"
    },
    {
      "kind": "ScalarField",
      "name": "completionDate"
    },
    {
      "kind": "FragmentSpread",
      "name": "ui_SprintFlyout"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__id"
        }
      ]
    }
  ],
  "type": "JiraSprint"
};

(node as any).hash = "5c30cae7493a702789bbaeac1d0db176";

export default node;
