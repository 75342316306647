/**
 * @generated SignedSource<<a178146bc351942a5909c8923183b553>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type calendarCardStatus_calendar$data = {
  readonly statusField: {
    readonly status: {
      readonly name: string | null | undefined;
      readonly statusCategory: {
        readonly statusCategoryId: string;
      } | null | undefined;
    };
  } | null | undefined;
  readonly " $fragmentType": "calendarCardStatus_calendar";
};
export type calendarCardStatus_calendar$key = {
  readonly " $data"?: calendarCardStatus_calendar$data;
  readonly " $fragmentSpreads": FragmentRefs<"calendarCardStatus_calendar">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "calendarCardStatus_calendar",
  "selections": [
    {
      "concreteType": "JiraStatusField",
      "kind": "LinkedField",
      "name": "statusField",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraStatus",
          "kind": "LinkedField",
          "name": "status",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "name"
            },
            {
              "concreteType": "JiraStatusCategory",
              "kind": "LinkedField",
              "name": "statusCategory",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "statusCategoryId"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "40233ea8b632211e805b4a90f532a3f2";

export default node;
