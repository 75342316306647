import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'calendar.calendar-unscheduled-panel.calendar-unscheduled-panel-card-list.calendar-unscheduled-panel-empty-state.title',
		defaultMessage: 'All work has been scheduled',
		description: 'Title of the empty state for unscheduled sidebar',
	},
	dropzoneDescription: {
		id: 'calendar.calendar-unscheduled-panel.calendar-unscheduled-panel-card-list.calendar-unscheduled-panel-empty-state.dropzone-description',
		defaultMessage:
			'Remove an issue from the calendar by dragging it back into the unscheduled work panel',
		description: 'Description of the empty state for unscheduled work panel',
	},
	dismissButton: {
		id: 'calendar.calendar-unscheduled-panel.calendar-unscheduled-panel-card-list.calendar-unscheduled-panel-empty-state.dismiss-button',
		defaultMessage: 'Dismiss',
		description: 'Button text to close the unscheduled sidebar',
	},
	errorTitle: {
		id: 'calendar.calendar-unscheduled-panel.calendar-unscheduled-panel-card-list.calendar-unscheduled-panel-empty-state.error-title',
		defaultMessage: "Something's gone wrong",
		description: 'Title that tells the user there is an issue fetching unscheduled items',
	},
	errorDescription: {
		id: 'calendar.calendar-unscheduled-panel.calendar-unscheduled-panel-card-list.calendar-unscheduled-panel-empty-state.error-description',
		defaultMessage:
			'Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.',
		description: 'Instructions given to the user when there is an issue fetching unscheduled items',
	},
	dropzoneDescriptionIssueTermRefresh: {
		id: 'calendar.calendar-unscheduled-panel.calendar-unscheduled-panel-card-list.calendar-unscheduled-panel-empty-state.dropzone-description-issue-term-refresh',
		defaultMessage:
			'To remove a work item from the calendar, drag it back into the unscheduled work panel',
		description: 'Description of the empty state for unscheduled work panel',
	},
});
