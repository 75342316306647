import React from 'react';
import { TimePicker } from '@atlaskit/datetime-picker';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { Inline, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import {
	availableTimes,
	type CalendarDayStart,
} from '../../../../controllers/calendar-store/types.tsx';
import { messages } from './messages.tsx';

export type DayStartsAtOptionsProps = {
	dayStartsAt: string;
	onDayStartsAtChange: (value: CalendarDayStart) => void;
	testId: string;
};

export const DayStartsAtOptions = ({
	dayStartsAt,
	onDayStartsAtChange,
	testId,
}: DayStartsAtOptionsProps) => {
	const { formatMessage } = useIntl();

	const getNewOptionAsCalendarDayStartType = (newOption: string): CalendarDayStart | undefined => {
		return availableTimes.find((time) => time === newOption);
	};

	const handleDayStartsAtChange = (value: string) => {
		const optionAsCalendarDayStartType = getNewOptionAsCalendarDayStartType(value);
		if (optionAsCalendarDayStartType !== undefined) {
			onDayStartsAtChange(optionAsCalendarDayStartType);
		}
	};

	return (
		<Inline
			xcss={popupMarginStyles}
			alignBlock="center"
			grow="fill"
			space="space.100"
			spread="space-between"
		>
			<label htmlFor="calendar-view-settings-week-settings">
				{formatMessage(messages.dayStartsAt)}
			</label>

			{/* menuPortalTarget is set to Prevent mounting into menu, which will overflow */}
			<TimePicker
				data-testid={testId}
				id="day-starts-at-calendar-view-settings"
				value={dayStartsAt}
				times={[...availableTimes]}
				onChange={handleDayStartsAtChange}
				selectProps={{
					isClearable: false,
					components: <ChevronDownIcon label={formatMessage(messages.chevronDownIconLabel)} />,
					styles: {
						menuPortal: (base: unknown) => ({
							// @ts-expect-error - TS2698 - Spread types may only be created from object types.
							...base,
							zIndex: 9999,
						}),
					},
					menuPortalTarget: globalThis.document?.body,
				}}
				label={formatMessage(messages.currentDayStartsAt, { time: dayStartsAt })}
			/>
		</Inline>
	);
};

const popupMarginStyles = xcss({
	paddingLeft: 'space.200',
	paddingRight: 'space.200',
	minWidth: '300px',
});
