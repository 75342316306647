import React, { createContext, useContext, useMemo, type ReactNode } from 'react';
// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridgeStrict } from '@atlassian/jira-common-bridge/src';
import type { IssueId, IssueKey, IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';

type BindableEvents = {
	bind: (eventName: string, callback: IssueCreateCallback) => void;
};

const isBindableEvents = (Events: unknown): Events is BindableEvents => {
	if (!Events) {
		return false;
	}

	return typeof Events === 'object' && 'bind' in Events && typeof Events.bind === 'function';
};

const bindToOnJiraGlobalIssueCreateComplete = (
	handleGlobalIssueCreateCallback: IssueCreateCallback,
) => {
	// eslint-disable-next-line jira/wrm/no-load-bridge
	loadBridgeStrict({
		name: 'jira/util/events',
		wrmKeys: ['wrc!jira.webresources:jira-events'],
	}).then((Events) => {
		if (isBindableEvents(Events)) {
			Events.bind('QuickCreateIssue.sessionComplete', handleGlobalIssueCreateCallback);
		}
	});
};

export type GlobalIssueCreatePayload = {
	issueId: IssueId;
	issueKey: IssueKey;
	issueTypeId: IssueTypeId;
	summary: string;
};

export type IssueCreateCallback = (event: Event, issues: GlobalIssueCreatePayload[]) => void;

export type CreateIssueHandler = (callback: IssueCreateCallback) => void;

export type GlobalIssueCreateContextType = {
	handleGlobalIssueCreate: CreateIssueHandler;
	hasManageTypesLink: boolean;
};

// Replace with lodash/noop
// eslint-disable-next-line @typescript-eslint/no-empty-function
const defaultCreateIssueHandler = () => {};

const GlobalIssueCreateContext = createContext<GlobalIssueCreateContextType>({
	handleGlobalIssueCreate: defaultCreateIssueHandler,
	hasManageTypesLink: false,
});

type Props = {
	handleGlobalIssueCreate?: CreateIssueHandler;
	hasManageTypesLink?: boolean;
	children: ReactNode;
};

export function GlobalIssueCreateContextProvider({
	handleGlobalIssueCreate = bindToOnJiraGlobalIssueCreateComplete,
	hasManageTypesLink = false,
	children,
}: Props) {
	const defaultValue = useMemo(
		() => ({ handleGlobalIssueCreate, hasManageTypesLink }),
		[handleGlobalIssueCreate, hasManageTypesLink],
	);

	return (
		<GlobalIssueCreateContext.Provider value={defaultValue}>
			{children}
		</GlobalIssueCreateContext.Provider>
	);
}

export function useGlobalIssueCreateContext() {
	return useContext(GlobalIssueCreateContext);
}
