import React from 'react';
import { useFragment, graphql } from 'react-relay';
import StatusLozenge from '@atlassian/jira-common-components-status-lozenge/src/view.tsx';
import {
	statusCategoryForId,
	type StatusCategory,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';
import type { statusFieldValueLabel_softwareFiltersPopup$key } from '@atlassian/jira-relay/src/__generated__/statusFieldValueLabel_softwareFiltersPopup.graphql';

export function StatusFieldValueLabel({
	fieldValueRef,
}: {
	fieldValueRef: statusFieldValueLabel_softwareFiltersPopup$key;
	mode: 'dropdown' | 'popup';
}) {
	const data = useFragment(
		graphql`
			fragment statusFieldValueLabel_softwareFiltersPopup on JiraJqlStatusFieldValue {
				displayName
				statusCategory {
					statusCategoryId
				}
			}
		`,
		fieldValueRef,
	);

	const {
		displayName: statusName,
		statusCategory: { statusCategoryId: statusCategory },
	} = data;

	return (
		<StatusLozenge
			name={statusName ?? ''}
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			category={statusCategoryForId(statusCategory ?? '1') as StatusCategory}
		/>
	);
}
