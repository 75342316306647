import { useEffect, useRef } from 'react';
import type { RefetchFnDynamic } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import type { calendarRendererIssues_jira$key } from '@atlassian/jira-relay/src/__generated__/calendarRendererIssues_jira.graphql';
import type { calendarRendererIssues_jira_refetchQuery } from '@atlassian/jira-relay/src/__generated__/calendarRendererIssues_jira_refetchQuery.graphql';
import {
	useCalendarActions,
	useDateRange,
	useExtraQuery,
	useHideDoneItems,
} from '../../../controllers/calendar-store/index.tsx';
import { useRefetchCalendarIssues } from '../../../controllers/use-refetch-issues/index.tsx';

/**
 * Custom hook that handles refetching calendar issues when inputs such filter, pagination or date period change.
 * @param {RefetchFnDynamic<calendarRendererIssues_jira_refetchQuery, calendarRendererIssues_jira$key>} options.refetch - The refetch function.
 */
export function useRefetchCalendarIssuesOnInputsChanges({
	refetch,
}: {
	refetch: RefetchFnDynamic<
		calendarRendererIssues_jira_refetchQuery,
		calendarRendererIssues_jira$key
	>;
}) {
	const { refetchIssuesWithoutSuspense } = useRefetchCalendarIssues({ refetch });

	const { resetIssuesPageCursor } = useCalendarActions();
	const currentDateRange = useDateRange();
	const currentFilter = useExtraQuery();
	const hideDoneItems = useHideDoneItems();

	const lastDateRef = useRef<Date | null>(currentDateRange.currentDate);
	const lastFilterRef = useRef<string | null>(currentFilter);
	const lastHideDoneItems = useRef<boolean | null>(hideDoneItems);

	const isFirstRun = useRef(true);

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}

		if (
			lastDateRef.current !== currentDateRange.currentDate ||
			lastFilterRef.current !== currentFilter ||
			(lastHideDoneItems.current !== hideDoneItems && fg('jira_calendar_load_more_pagination'))
		) {
			lastDateRef.current = currentDateRange.currentDate;
			lastFilterRef.current = currentFilter;
			lastHideDoneItems.current = hideDoneItems;
			resetIssuesPageCursor();
		}

		const subscription = refetchIssuesWithoutSuspense();

		return () => subscription?.unsubscribe?.();
	}, [
		currentDateRange.currentDate,
		currentFilter,
		hideDoneItems,
		refetchIssuesWithoutSuspense,
		resetIssuesPageCursor,
	]);
}
