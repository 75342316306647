import { di } from 'react-magnetic-di';
import type {
	UserProps,
	StatusProps,
} from '@atlassian/jira-issue-table/src/model/fields/json-fields/common/types.tsx';
import {
	type IssueKeyDataSelectorProps,
	type PriorityDataSelectorProps,
	REQUEST_PARTICIPANTS_FIELD_TYPE,
} from '@atlassian/jira-issue-table/src/model/fields/json-fields/system-fields/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { Issue } from '../../../../../../model/index.tsx';
import type {
	AssigneeFieldResponse,
	StatusFieldResponse,
} from '../../../../../../rest/issue/fields/types.tsx';
import type { FieldDataTransformer, StoredFieldUpdater } from '../common/types.tsx';

const onChangeAssignee =
	(
		originalStoredValue: AssigneeFieldResponse,
		storedFieldUpdater: StoredFieldUpdater<AssigneeFieldResponse>,
	) =>
	(issueKey: string, newValue?: UserProps): void => {
		let newValueReverseTransformed = null;
		if (newValue) {
			const { displayName, avatarUrl, accountId, emailAddress } = newValue;
			newValueReverseTransformed = {
				displayName,
				avatarUrls: { '48x48': avatarUrl },
				accountId,
				emailAddress,
			};
		}
		storedFieldUpdater({ value: newValueReverseTransformed }, originalStoredValue);
	};

export const assigneeFieldTransformer: FieldDataTransformer<'assignee'> = ({
	storedValue,
	storedFieldUpdater,
}) => {
	const { isEditable, value: responseValue } = storedValue;

	let value;
	if (responseValue) {
		const {
			accountId,
			emailAddress,
			displayName,
			avatarUrls: { '48x48': avatarUrl },
		} = responseValue;
		value = {
			displayName,
			avatarUrl,
			accountId,
			emailAddress,
		};
	}

	return {
		fieldType: 'assignee',
		value,
		key: responseValue ? responseValue.key : undefined,
		isEditable,
		onChange: onChangeAssignee(storedValue, storedFieldUpdater),
	};
};

export const creatorFieldTransformer: FieldDataTransformer<'creator'> = ({ storedValue }) => {
	const {
		value: {
			accountId,
			emailAddress,
			displayName,
			avatarUrls: { '48x48': avatarUrl },
		},
	} = storedValue;

	const value = {
		displayName,
		avatarUrl,
		accountId,
		emailAddress,
	};

	return {
		fieldType: 'creator',
		value,
	};
};

export const issueKeyTransformer = (issue: Issue): IssueKeyDataSelectorProps => ({
	fieldType: 'issuekey',
	value: issue.key,
});

export const priorityTransformer = (issue: Issue): PriorityDataSelectorProps => {
	di(window);
	const parser = new DOMParser();
	// @ts-expect-error: Property 'fieldAsHtml' does not exist on type 'ResponseField'.
	const doc = parser.parseFromString(issue.fields.priority.fieldAsHtml, 'text/html');
	const priorityHtml = doc.querySelector('img');
	if (!priorityHtml) {
		// Remove when cleaning up, just for monitoring rollout
		if (
			typeof window !== 'undefined' &&
			window.location.pathname.includes('/escalations') &&
			fg('jsm_cs_fix_escalations_priority_field')
		) {
			fireErrorAnalytics({
				meta: {
					id: 'priorityTransformer',
					packageName: 'servicedeskQueuesCommon',
					teamName: 'boysenberry',
				},
				error: new Error('Priority field not in html format'),
				sendToPrivacyUnsafeSplunk: true,
			});
		}
		return {
			fieldType: 'priority',
			value: undefined,
		};
	}

	return {
		fieldType: 'priority',
		value: {
			name: priorityHtml.title,
			iconUrl: priorityHtml.src,
		},
	};
};

const onChangeStatus =
	(
		originalStoredValue: StatusFieldResponse,
		storedFieldUpdater: StoredFieldUpdater<StatusFieldResponse>,
	) =>
	(issueKey: string, newValue: StatusProps): void => {
		let newValueReverseTransformed = null;
		const {
			description,
			name,
			id,
			statusCategory: { id: statusCategoryId },
		} = newValue;
		newValueReverseTransformed = {
			id,
			description,
			name,
			statusCategory: { id: statusCategoryId },
		};
		storedFieldUpdater({ value: newValueReverseTransformed }, originalStoredValue);
	};

export const issueTypeTransformer: FieldDataTransformer<'issuetype'> = ({ storedValue }) => {
	const {
		value: { name, iconUrl, description },
	} = storedValue;

	const value = {
		name,
		iconUrl,
		description,
	};

	return {
		fieldType: 'issuetype',
		value,
	};
};

export const statusFieldTransformer: FieldDataTransformer<'status'> = ({
	storedValue,
	storedFieldUpdater,
}) => {
	const {
		value: {
			id,
			name,
			description,
			statusCategory: { id: statusCategoryId },
		},
		isEditable,
	} = storedValue;

	const value = {
		id,
		name,
		description,
		statusCategory: {
			id: statusCategoryId,
		},
	};

	return {
		key: value.id.toString(),
		fieldType: 'status',
		value,
		onChange: onChangeStatus(storedValue, storedFieldUpdater),
		isEditable,
	};
};

export const reporterFieldTransformer: FieldDataTransformer<'reporter'> = ({ storedValue }) => {
	const { value } = storedValue;

	if (!value) {
		return {
			fieldType: 'reporter',
			value: null,
		};
	}

	const { displayName, emailAddress, active } = value;

	const transformedValue = {
		displayName,
		emailAddress,
		active: !!active,
	};

	return {
		fieldType: 'reporter',
		value: transformedValue,
	};
};

export const summaryFieldTransformer: FieldDataTransformer<'summary'> = ({ storedValue }) => ({
	fieldType: 'summary',
	value: storedValue.value,
});

export const labelsFieldTransformer: FieldDataTransformer<'labels'> = ({ storedValue }) => ({
	fieldType: 'labels',
	value: storedValue.value.map((label) => ({
		name: label,
		href: `/issues/?jql=labels+%3D+${label}`,
	})),
});

export const requestParticipantsPickerTransformer: FieldDataTransformer<
	typeof REQUEST_PARTICIPANTS_FIELD_TYPE
> = ({ storedValue: { value } }) => ({
	fieldType: REQUEST_PARTICIPANTS_FIELD_TYPE,
	value: value
		? value.map((user) => ({
				name: user.displayName,
				email: user.emailAddress,
				avatarUrl: user.avatarUrls['48x48'],
			}))
		: [],
});
