/**
 * @generated SignedSource<<f07779c5a6248170ea2afee129c92be1>>
 * @relayHash 1dc95ac8b168cc480fed1516bd8b67eb
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c7776410a4d107c34fcc42724dba4235de90d9ab77e16443ad24310310f90ef7

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type srcfilterPopupButton_softwareFiltersPopup_Query$variables = {
  cloudId: string;
  jqlBuilderQuery?: string | null | undefined;
};
export type srcfilterPopupButton_softwareFiltersPopup_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ui_softwareFiltersPopup_FilterPopup">;
};
export type srcfilterPopupButton_softwareFiltersPopup_Query = {
  response: srcfilterPopupButton_softwareFiltersPopup_Query$data;
  variables: srcfilterPopupButton_softwareFiltersPopup_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jqlBuilderQuery"
  }
],
v1 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v2 = {
  "kind": "Literal",
  "name": "first",
  "value": 15
},
v3 = {
  "kind": "Variable",
  "name": "jqlContext",
  "variableName": "jqlBuilderQuery"
},
v4 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v5 = {
  "kind": "ScalarField",
  "name": "name"
},
v6 = {
  "kind": "ScalarField",
  "name": "id"
},
v7 = [
  {
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "hasNextPage"
      }
    ]
  },
  {
    "concreteType": "JiraJqlFieldValueEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "cursor"
      },
      {
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "name": "jqlTerm"
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isJiraJqlFieldValue"
          },
          {
            "kind": "ScalarField",
            "name": "displayName"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "picture"
                  },
                  (v6/*: any*/)
                ]
              }
            ],
            "type": "JiraJqlUserFieldValue"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "concreteType": "JiraPriority",
                "kind": "LinkedField",
                "name": "priority",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "iconUrl"
                  },
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              }
            ],
            "type": "JiraJqlPriorityFieldValue"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraAvatar",
                    "kind": "LinkedField",
                    "name": "avatar",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "xsmall"
                      }
                    ]
                  },
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              }
            ],
            "type": "JiraJqlProjectFieldValue"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "concreteType": "JiraStatusCategory",
                "kind": "LinkedField",
                "name": "statusCategory",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "statusCategoryId"
                  },
                  (v6/*: any*/)
                ]
              }
            ],
            "type": "JiraJqlStatusFieldValue"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "concreteType": "JiraLabel",
                "kind": "LinkedField",
                "name": "label",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ]
              }
            ],
            "type": "JiraJqlLabelFieldValue"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "concreteType": "JiraGroup",
                "kind": "LinkedField",
                "name": "group",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              }
            ],
            "type": "JiraJqlGroupFieldValue"
          }
        ]
      }
    ]
  },
  {
    "kind": "ScalarField",
    "name": "totalCount"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "srcfilterPopupButton_softwareFiltersPopup_Query",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "jqlBuilderQuery",
            "variableName": "jqlBuilderQuery"
          }
        ],
        "kind": "FragmentSpread",
        "name": "ui_softwareFiltersPopup_FilterPopup"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "srcfilterPopupButton_softwareFiltersPopup_Query",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              (v1/*: any*/)
            ],
            "concreteType": "JiraJqlBuilder",
            "kind": "LinkedField",
            "name": "jqlBuilder",
            "plural": false,
            "selections": [
              {
                "alias": "assignee",
                "args": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "jqlTerm",
                    "value": "assignee"
                  }
                ],
                "concreteType": "JiraJqlFieldValueConnection",
                "kind": "LinkedField",
                "name": "fieldValues",
                "plural": false,
                "selections": (v7/*: any*/)
              },
              {
                "alias": "status",
                "args": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "jqlTerm",
                    "value": "status"
                  }
                ],
                "concreteType": "JiraJqlFieldValueConnection",
                "kind": "LinkedField",
                "name": "fieldValues",
                "plural": false,
                "selections": (v7/*: any*/)
              },
              {
                "alias": "priority",
                "args": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "jqlTerm",
                    "value": "priority"
                  }
                ],
                "concreteType": "JiraJqlFieldValueConnection",
                "kind": "LinkedField",
                "name": "fieldValues",
                "plural": false,
                "selections": (v7/*: any*/)
              },
              {
                "alias": "labels",
                "args": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "jqlTerm",
                    "value": "labels"
                  }
                ],
                "concreteType": "JiraJqlFieldValueConnection",
                "kind": "LinkedField",
                "name": "fieldValues",
                "plural": false,
                "selections": (v7/*: any*/)
              },
              {
                "alias": "versions",
                "args": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "jqlTerm",
                    "value": "fixVersions"
                  }
                ],
                "concreteType": "JiraJqlFieldValueConnection",
                "kind": "LinkedField",
                "name": "fieldValues",
                "plural": false,
                "selections": (v7/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "c7776410a4d107c34fcc42724dba4235de90d9ab77e16443ad24310310f90ef7",
    "metadata": {},
    "name": "srcfilterPopupButton_softwareFiltersPopup_Query",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "0b83fe1a9c9c7fb78c450dce628ada92";

export default node;
