import { ExperiencePerformanceTypes, UFOExperience, ExperienceTypes } from '@atlassian/ufo';

/**
 * Create a "view release flyout" experience.
 *
 * @param location - The location to make it unique to the view the release flyout is used e.g. calendar
 * @param suffixKey - The suffix key to make it even more unique to where the release flyout is used e.g. software
 */
export const createViewReleaseFlyoutExperience = (location: string, suffixKey: string) =>
	new UFOExperience(`view-release-flyout-${location}-${suffixKey}`, {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	});
