/**
 * @generated SignedSource<<0b8b9abcbd7be910d7312da52050fb21>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type calendarUnscheduledPanelToggle_calendar$data = {
  readonly unscheduledPanelToggleJira: {
    readonly jiraCalendar: {
      readonly unscheduledIssues: {
        readonly __id: string;
        readonly totalIssueSearchResultCount: number | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "calendarUnscheduledPanelToggle_calendar";
};
export type calendarUnscheduledPanelToggle_calendar$key = {
  readonly " $data"?: calendarUnscheduledPanelToggle_calendar$data;
  readonly " $fragmentSpreads": FragmentRefs<"calendarUnscheduledPanelToggle_calendar">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "configurationInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "unscheduledIssuesSearchInput"
    }
  ],
  "kind": "Fragment",
  "name": "calendarUnscheduledPanelToggle_calendar",
  "selections": [
    {
      "alias": "unscheduledPanelToggleJira",
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "configuration",
              "variableName": "configurationInput"
            },
            {
              "kind": "Variable",
              "name": "scope",
              "variableName": "scopeInput"
            }
          ],
          "concreteType": "JiraCalendar",
          "kind": "LinkedField",
          "name": "jiraCalendar",
          "plural": false,
          "selections": [
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "input",
                  "variableName": "unscheduledIssuesSearchInput"
                }
              ],
              "concreteType": "JiraIssueConnection",
              "kind": "LinkedField",
              "name": "unscheduledIssues",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "totalIssueSearchResultCount"
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "__id"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "c8ab636d059fa203bb5f39c95a1492a5";

export default node;
