/**
 * @generated SignedSource<<b8b87c378ea974959e001e61d0cfdf2f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraScenarioType = "ADDED" | "DELETED" | "DELETEDFROMJIRA" | "UPDATED" | "%future added value";
export type JiraVersionStatus = "ARCHIVED" | "RELEASED" | "UNRELEASED" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type ui_ReleaseFlyout$data = {
  readonly description: string | null | undefined;
  readonly doneIssues: {
    readonly totalCount: number | null | undefined;
  } | null | undefined;
  readonly driver: {
    readonly name: string;
    readonly picture: AGG$URL;
  } | null | undefined;
  readonly inprogressIssues: {
    readonly totalCount: number | null | undefined;
  } | null | undefined;
  readonly name: string | null | undefined;
  readonly planScenarioValues: {
    readonly description: string | null | undefined;
    readonly name: string | null | undefined;
    readonly releaseDate?: AGG$DateTime | null | undefined;
    readonly releaseDateValue?: {
      readonly date: AGG$DateTime | null | undefined;
    } | null | undefined;
    readonly scenarioType: JiraScenarioType | null | undefined;
    readonly startDate?: AGG$DateTime | null | undefined;
    readonly startDateValue?: {
      readonly date: AGG$DateTime | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly project: {
    readonly avatar: {
      readonly __typename: "JiraAvatar";
      readonly xsmall: string | null | undefined;
    } | null | undefined;
    readonly key: string;
    readonly name: string;
    readonly projectTypeName: string | null | undefined;
  } | null | undefined;
  readonly releaseDate: AGG$DateTime | null | undefined;
  readonly startDate: AGG$DateTime | null | undefined;
  readonly status: JiraVersionStatus | null | undefined;
  readonly todoIssues: {
    readonly totalCount: number | null | undefined;
  } | null | undefined;
  readonly versionId: string;
  readonly " $fragmentType": "ui_ReleaseFlyout";
};
export type ui_ReleaseFlyout$key = {
  readonly " $data"?: ui_ReleaseFlyout$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_ReleaseFlyout">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "name"
},
v1 = {
  "kind": "ScalarField",
  "name": "description"
},
v2 = {
  "kind": "ScalarField",
  "name": "startDate"
},
v3 = {
  "kind": "ScalarField",
  "name": "releaseDate"
},
v4 = [
  {
    "kind": "ScalarField",
    "name": "totalCount"
  }
],
v5 = [
  {
    "kind": "ScalarField",
    "name": "date"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "skipVersionsV1Search"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "skipVersionsV2Search"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "name": "ui_ReleaseFlyout",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "name": "status"
    },
    {
      "kind": "ScalarField",
      "name": "versionId"
    },
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "name": "key"
        },
        {
          "concreteType": "JiraAvatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "xsmall"
            },
            {
              "kind": "ScalarField",
              "name": "__typename"
            }
          ]
        },
        {
          "kind": "ScalarField",
          "name": "projectTypeName"
        }
      ]
    },
    {
      "kind": "LinkedField",
      "name": "driver",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "name": "picture"
        }
      ]
    },
    {
      "alias": "doneIssues",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusCategories": "DONE"
          }
        }
      ],
      "concreteType": "JiraIssueConnection",
      "kind": "LinkedField",
      "name": "issues",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": "issues(filters:{\"statusCategories\":\"DONE\"})"
    },
    {
      "alias": "inprogressIssues",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusCategories": "IN_PROGRESS"
          }
        }
      ],
      "concreteType": "JiraIssueConnection",
      "kind": "LinkedField",
      "name": "issues",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": "issues(filters:{\"statusCategories\":\"IN_PROGRESS\"})"
    },
    {
      "alias": "todoIssues",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusCategories": "TODO"
          }
        }
      ],
      "concreteType": "JiraIssueConnection",
      "kind": "LinkedField",
      "name": "issues",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": "issues(filters:{\"statusCategories\":\"TODO\"})"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "viewId",
          "variableName": "viewId"
        }
      ],
      "concreteType": "JiraVersionPlanScenarioValues",
      "kind": "LinkedField",
      "name": "planScenarioValues",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "name": "scenarioType"
        },
        {
          "condition": "skipVersionsV1Search",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/)
          ]
        },
        {
          "condition": "skipVersionsV2Search",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "concreteType": "JiraDateScenarioValueField",
              "kind": "LinkedField",
              "name": "startDateValue",
              "plural": false,
              "selections": (v5/*: any*/)
            },
            {
              "concreteType": "JiraDateScenarioValueField",
              "kind": "LinkedField",
              "name": "releaseDateValue",
              "plural": false,
              "selections": (v5/*: any*/)
            }
          ]
        },
        (v1/*: any*/)
      ]
    }
  ],
  "type": "JiraVersion"
};
})();

(node as any).hash = "a94b11d06c02164e5c0cb0760711be58";

export default node;
