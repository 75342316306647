/**
 * @generated SignedSource<<ecc0b76da0592856ad5449eb9c844df9>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type labelFieldValueLabel_softwareFiltersPopup$data = {
  readonly label: {
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "labelFieldValueLabel_softwareFiltersPopup";
};
export type labelFieldValueLabel_softwareFiltersPopup$key = {
  readonly " $data"?: labelFieldValueLabel_softwareFiltersPopup$data;
  readonly " $fragmentSpreads": FragmentRefs<"labelFieldValueLabel_softwareFiltersPopup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "labelFieldValueLabel_softwareFiltersPopup",
  "selections": [
    {
      "concreteType": "JiraLabel",
      "kind": "LinkedField",
      "name": "label",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "name"
        }
      ]
    }
  ],
  "type": "JiraJqlLabelFieldValue"
};

(node as any).hash = "156a1ae7088b8260321d04ef8371c494";

export default node;
