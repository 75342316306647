import React, { useState, useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button/standard-button';
import EditorWarningIcon from '@atlaskit/icon/glyph/editor/warning';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import InlineDialog from '@atlaskit/inline-dialog'; // ignore-for-ENGHEALTH-17759
import { colors } from '@atlaskit/theme';

import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/project/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { useCategorizedNavItems } from '@atlassian/jira-servicedesk-queues-categorized-store/src/main.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { messages } from './messages.tsx';
import type { IssueCountErrorMessageProps } from './types.tsx';

export const useIsProjectAdmin = () => {
	const isAdmin = useIsAdmin();
	const projectContext = useProjectContext();
	const isProjectAdmin = isAdmin || projectContext?.data?.isProjectAdmin || false;

	return {
		isProjectAdmin,
	};
};

const learnMoreUrl =
	'https://community.atlassian.com/t5/Jira-Service-Management-articles/Managing-queues-at-scale-in-Jira-Service-Management/ba-p/2102021';

export const IssueCountErrorMessage = ({ queue }: IssueCountErrorMessageProps) => {
	const [showDialog, setShowDialog] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const { data: project } = useProject();

	const [, , , methods] = useCategorizedNavItems('', project ? project.key : '', queue.category);
	const onReload = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			// stopping the propagration and default behavior, otherwise the queue menu item will be clicked
			e.stopPropagation();
			e.preventDefault();
			fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'ReloadBadQueueButton');
			methods.enableRefreshForQueues([queue.id]);
			methods.refreshIssueCountForItems([queue.id]);
		},
		[methods, queue.id, createAnalyticsEvent],
	);

	const onEditQueue = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			// stopping the propagration and default behavior, otherwise the queue menu item will be clicked
			e.stopPropagation();
			e.preventDefault();

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.open(`${queue.url}/edit`, '_blank');
			setShowDialog(false);

			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'EditQueueFromIssueCountErrorDialog',
				}),
				{
					meta: {
						id: 'ViewEditQueueFromIssueCountErrorDialog',
						packageName: 'jiraNavigationAppsSidebarServicedesk',
						teamName: 'jsd-shield',
					},
					canBeHidden: queue.canBeHidden,
					isStarred: queue.starred,
				},
			);
		},
		[setShowDialog, queue.url, queue.canBeHidden, queue.starred, createAnalyticsEvent],
	);

	const onLearnMoreClick = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			// stopping the propagration and default behavior, otherwise the queue menu item will be clicked
			e.stopPropagation();
			e.preventDefault();
			setShowDialog(false);
			fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'BadQueueLearnMoreButton');

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.open(learnMoreUrl, '_blank');
		},
		[createAnalyticsEvent],
	);

	const learnMoreButton = useMemo(
		() => (
			<Button appearance="link" target="_blank" onClick={onLearnMoreClick}>
				{formatMessage(messages.learnMoreButtonLabel)}
			</Button>
		),
		[formatMessage, onLearnMoreClick],
	);

	const reloadButton = useMemo(
		() => (
			<Button appearance="link" onClick={onReload}>
				{formatMessage(messages.reloadButtonLabel)}
			</Button>
		),
		[onReload, formatMessage],
	);

	const editQueueButton = useMemo(
		() => (
			<Button
				appearance="link"
				onClick={onEditQueue}
				iconAfter={<ShortcutIcon label="" size="small" />}
			>
				{formatMessage(messages.editQueueButtonLabel)}
			</Button>
		),
		[onEditQueue, formatMessage],
	);

	const { isProjectAdmin } = useIsProjectAdmin();
	const userActionButton = isProjectAdmin ? editQueueButton : reloadButton;

	const agentMessage = queue.starred
		? messages.issueCountErrorMessageAgentStarred
		: messages.issueCountErrorMessageAgentNotStarred;
	const contentMessage = isProjectAdmin ? messages.issueCountErrorMessageAdmin : agentMessage;

	const content = useMemo(
		() => (
			<InlineDialogContainer>
				<InlineDialogHeader>
					{formatMessage(
						fg('itsm-issue-renaming')
							? messages.issueCountErrorMessageHeaderIssueTermRefresh
							: messages.issueCountErrorMessageHeader,
					)}
				</InlineDialogHeader>
				<p>{formatMessage(contentMessage)}</p>
				<InlineDialogButtons>
					{learnMoreButton}
					{userActionButton}
				</InlineDialogButtons>
			</InlineDialogContainer>
		),
		[contentMessage, formatMessage, learnMoreButton, userActionButton],
	);

	const onIssueCountErrorButtonClick = useCallback(
		// @ts-expect-error - TS2315 - Type 'Mouse Event' is not generic.
		(e: MouseEvent<HTMLElement, MouseEvent>) => {
			e.stopPropagation(); // prevent underlying queue button from being pressed
			e.preventDefault();
			fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'IssueCountErrorButton');
			setShowDialog(!showDialog);
		},
		[showDialog, setShowDialog, createAnalyticsEvent],
	);

	return (
		<InlineDialog
			onClose={() => {
				setShowDialog(false);
			}}
			content={content}
			isOpen={showDialog}
		>
			<ButtonWrapper>
				<Button
					appearance="subtle"
					spacing="none"
					onClick={onIssueCountErrorButtonClick}
					iconBefore={
						<EditorWarningIcon
							label=""
							size="small"
							primaryColor={token('color.icon.subtle', colors.N200)}
						/>
					}
				/>
			</ButtonWrapper>
		</InlineDialog>
	);
};

export default IssueCountErrorMessage;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineDialogContainer = styled.div({
	font: token('font.body'),
	maxWidth: '305px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineDialogHeader = styled.div({
	fontWeight: token('font.weight.bold'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineDialogButtons = styled.div({
	marginLeft: token('space.negative.150', '-12px'),
	marginTop: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.div({
	display: 'inline-flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: `${gridSize * 3}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		height: `${gridSize * 3}px`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const visibleForTesting = {
	InlineDialogContainer,
	InlineDialogHeader,
	InlineDialogButtons,
	ButtonWrapper,
};
