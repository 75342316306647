import React, { type ComponentType } from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { IssueNavigatorJQLBuilderSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/jql-builder.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { JQLBuilderProps } from './common/types.tsx';

const JQLBuilderAsync = lazyForPaint<ComponentType<JQLBuilderProps>>(
	() => import(/* webpackChunkName: "async-jql-builder" */ './index'),
);

const JQLEditorBasicUIAsync: ComponentType<JQLBuilderProps> = (props: JQLBuilderProps) => (
	<Placeholder name="async-jql-builder" fallback={<IssueNavigatorJQLBuilderSkeleton />}>
		<JQLBuilderAsync {...props} />
	</Placeholder>
);

export default JQLEditorBasicUIAsync;
