/**
 * @generated SignedSource<<d432af19b24efc646a17504cd6cb9caf>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type filterOptions_softwareFiltersPopup$data = {
  readonly edges: ReadonlyArray<{
    readonly cursor: string;
    readonly node: {
      readonly __typename: string;
      readonly jqlTerm: string;
      readonly " $fragmentSpreads": FragmentRefs<"fieldValueLabel_softwareFiltersPopup">;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly pageInfo: {
    readonly hasNextPage: boolean;
  };
  readonly " $fragmentType": "filterOptions_softwareFiltersPopup";
};
export type filterOptions_softwareFiltersPopup$key = {
  readonly " $data"?: filterOptions_softwareFiltersPopup$data;
  readonly " $fragmentSpreads": FragmentRefs<"filterOptions_softwareFiltersPopup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "filterOptions_softwareFiltersPopup",
  "selections": [
    {
      "concreteType": "PageInfo",
      "kind": "LinkedField",
      "name": "pageInfo",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "hasNextPage"
        }
      ]
    },
    {
      "concreteType": "JiraJqlFieldValueEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "cursor"
        },
        {
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "__typename"
            },
            {
              "kind": "ScalarField",
              "name": "jqlTerm"
            },
            {
              "kind": "FragmentSpread",
              "name": "fieldValueLabel_softwareFiltersPopup"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraJqlFieldValueConnection"
};

(node as any).hash = "aec69bd30654fa969ca72a446515ebb5";

export default node;
