import React, { useCallback } from 'react';
import {
	commitLocalUpdate,
	useRelayEnvironment,
	ConnectionHandler,
	graphql,
	useFragment,
} from 'react-relay';
import { JiraIssueAri } from '@atlassian/ari/jira/issue';
import { fg } from '@atlassian/jira-feature-gating';
import {
	ChangeEventTypes,
	type ChangeEvent,
} from '@atlassian/jira-issue-view-model/src/change-type.tsx';
import type { calendarIssueViewModal_calendar$key } from '@atlassian/jira-relay/src/__generated__/calendarIssueViewModal_calendar.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import RelayDataID from '@atlassian/relay-data-id';
import { ANALYTICS_SOURCE } from '../../common/constants.tsx';
import { useCalendarIssueViewMode } from '../../common/controllers/use-calendar-issue-view-mode/index.tsx';
import {
	useCalendarActions,
	useIsUnscheduledPanelIssueViewOpen,
} from '../../controllers/calendar-store/index.tsx';
import {
	useCalendarRendererConnectionId,
	useUnscheduledPanelConnectionId,
	useUnscheduledToggleConnectionId,
} from '../../controllers/connection-store/index.tsx';
import CalendarModalIssueApp from './calendar-modal-issue-app/index.tsx';

const IssueResourceType = 'issue';
const JiraIssue = 'JiraIssue';
const IssueCountField = 'totalIssueSearchResultCount';

export function CalendarIssueViewModal({
	queryRef,
}: {
	queryRef: calendarIssueViewModal_calendar$key;
}) {
	const { closeIssueView } = useCalendarIssueViewMode();
	const { setUnscheduledPanelIssueViewVisibility } = useCalendarActions();
	const isUnscheduledPanelIssueViewOpen = useIsUnscheduledPanelIssueViewOpen();
	const environment = useRelayEnvironment();
	const cloudId = useCloudId();

	const calendarRendererConnectionId = useCalendarRendererConnectionId();
	const unscheduledPanelConnectionId = useUnscheduledPanelConnectionId();
	const unscheduledToggleConnectionId = useUnscheduledToggleConnectionId();

	const data = useFragment(
		graphql`
			fragment calendarIssueViewModal_calendar on Query
			@argumentDefinitions(
				scopeInput: { type: "JiraViewScopeInput!" }
				configurationInput: { type: "JiraCalendarViewConfigurationInput!" }
			) {
				jira {
					jiraCalendar(scope: $scopeInput, configuration: $configurationInput)
						@optIn(to: "JiraCalendar") {
						endDateField {
							fieldId
						}
					}
				}
			}
		`,
		queryRef,
	);

	const onClose = () => {
		setUnscheduledPanelIssueViewVisibility(false);
		closeIssueView();
	};

	// Note: this callback is being used to temporarily fix a problem and will be replaced by relay subscriptions.
	// Do not extend upon this code or use it as an example.
	// CLEAN UP WITH fg `calendar_issue_subscriptions`
	const handleOnChange = useCallback(
		(event: ChangeEvent) => {
			if (fg('calendar_issue_subscriptions')) return;
			if (!fg('calendar_update_on_issue_modal_schedule')) return;
			const dueDateFieldId = data?.jira?.jiraCalendar?.endDateField?.fieldId;
			if (
				event.type === ChangeEventTypes.FIELD_CHANGE_REQUESTED &&
				event.meta.fieldId === dueDateFieldId &&
				calendarRendererConnectionId &&
				unscheduledPanelConnectionId &&
				unscheduledToggleConnectionId
			) {
				commitLocalUpdate(environment, (store) => {
					// Get target issue
					const targetIssueAri = JiraIssueAri.create({
						siteId: cloudId,
						issueId: event.issueId,
					}).toString();
					const targetIssueId = RelayDataID({ id: targetIssueAri }, JiraIssue);
					if (targetIssueId == null) return;
					const nodeRecordFromStore = store.get(targetIssueId);
					if (nodeRecordFromStore == null) return;

					// Get connection record for toggle count
					const unscheduledPanelToggleConnectionRecord = store.get(unscheduledToggleConnectionId);
					// Get connection record for scheduled events list
					const scheduledConnectionRecord = store.get(calendarRendererConnectionId);
					// Get connection record for unscheduled events list
					const unscheduledIssueConnectionRecord = store.get(unscheduledPanelConnectionId);

					if (
						!unscheduledPanelToggleConnectionRecord ||
						!scheduledConnectionRecord ||
						!unscheduledIssueConnectionRecord
					)
						return;

					// Get current count of unscheduled issues
					const unscheduledIssueCount =
						unscheduledPanelToggleConnectionRecord?.getValue(IssueCountField);
					if (typeof unscheduledIssueCount !== 'number') return;

					if (event.meta.fieldValue) {
						// Remove edges from unscheduled panel
						ConnectionHandler.deleteNode(unscheduledIssueConnectionRecord, targetIssueId);

						// Add edges to calendar
						const newIssueEdge = ConnectionHandler.createEdge(
							store,
							scheduledConnectionRecord,
							nodeRecordFromStore,
							IssueResourceType,
						);
						ConnectionHandler.insertEdgeAfter(scheduledConnectionRecord, newIssueEdge);

						// Decrement unscheduled count
						unscheduledPanelToggleConnectionRecord?.setValue(
							unscheduledIssueCount - 1,
							IssueCountField,
						);
					} else {
						// Remove edges from calendar
						ConnectionHandler.deleteNode(scheduledConnectionRecord, targetIssueId);

						// Add edges to unscheduled panel
						const newIssueEdge = ConnectionHandler.createEdge(
							store,
							unscheduledIssueConnectionRecord,
							nodeRecordFromStore,
							IssueResourceType,
						);
						ConnectionHandler.insertEdgeAfter(unscheduledIssueConnectionRecord, newIssueEdge);

						// Increment unscheduled count
						unscheduledPanelToggleConnectionRecord?.setValue(
							unscheduledIssueCount + 1,
							IssueCountField,
						);
					}
				});
			}
		},
		[
			unscheduledToggleConnectionId,
			calendarRendererConnectionId,
			unscheduledPanelConnectionId,
			data,
			environment,
			cloudId,
		],
	);

	return (
		<UFOSegment name="jira-calendar-modal-issue-view">
			<CalendarModalIssueApp
				analyticsSource={ANALYTICS_SOURCE}
				viewModeSwitchDisabled={isUnscheduledPanelIssueViewOpen}
				onClose={onClose}
				onChange={handleOnChange}
			/>
		</UFOSegment>
	);
}
