/**
 * @generated SignedSource<<bcaca3d33dd15cc1969aa8c6eeec4263>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type calendarRendererIssues_jira$data = {
  readonly jira: {
    readonly jiraCalendar: {
      readonly issuesV2?: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly __id: string;
            readonly canSchedule?: boolean | null | undefined;
            readonly endDateViewField?: {
              readonly __typename: "JiraDatePickerField";
              readonly __id: string;
              readonly date?: AGG$Date | null | undefined;
              readonly dateTime?: AGG$DateTime | null | undefined;
              readonly id: string;
            } | null | undefined;
            readonly id: string;
            readonly key?: string;
            readonly planScenarioValues?: {
              readonly endDateViewField: {
                readonly __typename: "JiraDatePickerField";
                readonly __id: string;
                readonly date?: AGG$Date | null | undefined;
                readonly id: string;
              } | null | undefined;
              readonly scenarioKey?: string | null | undefined;
              readonly startDateViewField: {
                readonly __typename: "JiraDatePickerField";
                readonly __id: string;
                readonly date?: AGG$Date | null | undefined;
                readonly id: string;
              } | null | undefined;
              readonly summaryField: {
                readonly text: string | null | undefined;
              } | null | undefined;
            } | null | undefined;
            readonly startDateViewField?: {
              readonly __typename: "JiraDatePickerField";
              readonly __id: string;
              readonly date?: AGG$Date | null | undefined;
              readonly dateTime?: AGG$DateTime | null | undefined;
              readonly id: string;
            } | null | undefined;
            readonly summaryField?: {
              readonly text: string | null | undefined;
            } | null | undefined;
            readonly " $fragmentSpreads": FragmentRefs<"issueRenderer_calendar_IssueEventRenderer">;
          } | null | undefined;
        } | null | undefined> | null | undefined;
        readonly isCappingIssueSearchResult: boolean | null | undefined;
        readonly pageInfo: {
          readonly hasNextPage: boolean;
          readonly hasPreviousPage: boolean;
        };
        readonly totalIssueSearchResultCount: number | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"issuesPaginator_calendar">;
      } | null | undefined;
      readonly paginatedIssuesV2?: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly __id: string;
            readonly canSchedule?: boolean | null | undefined;
            readonly endDateViewField?: {
              readonly __typename: "JiraDatePickerField";
              readonly __id: string;
              readonly date?: AGG$Date | null | undefined;
              readonly dateTime?: AGG$DateTime | null | undefined;
              readonly id: string;
            } | null | undefined;
            readonly id: string;
            readonly key?: string;
            readonly planScenarioValues?: {
              readonly endDateViewField: {
                readonly __typename: "JiraDatePickerField";
                readonly __id: string;
                readonly date?: AGG$Date | null | undefined;
                readonly id: string;
              } | null | undefined;
              readonly scenarioKey?: string | null | undefined;
              readonly startDateViewField: {
                readonly __typename: "JiraDatePickerField";
                readonly __id: string;
                readonly date?: AGG$Date | null | undefined;
                readonly id: string;
              } | null | undefined;
              readonly summaryField: {
                readonly text: string | null | undefined;
              } | null | undefined;
            } | null | undefined;
            readonly startDateViewField?: {
              readonly __typename: "JiraDatePickerField";
              readonly __id: string;
              readonly date?: AGG$Date | null | undefined;
              readonly dateTime?: AGG$DateTime | null | undefined;
              readonly id: string;
            } | null | undefined;
            readonly summaryField?: {
              readonly text: string | null | undefined;
            } | null | undefined;
            readonly " $fragmentSpreads": FragmentRefs<"issueRenderer_calendar_IssueEventRenderer">;
          } | null | undefined;
        } | null | undefined> | null | undefined;
        readonly isCappingIssueSearchResult: boolean | null | undefined;
        readonly pageInfo: {
          readonly hasNextPage: boolean;
          readonly hasPreviousPage: boolean;
        };
        readonly totalIssueSearchResultCount: number | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"issuesPaginator_calendar">;
      } | null | undefined;
      readonly startDateField?: {
        readonly type: string;
      };
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "calendarRendererIssues_jira";
};
export type calendarRendererIssues_jira$key = {
  readonly " $data"?: calendarRendererIssues_jira$data;
  readonly " $fragmentSpreads": FragmentRefs<"calendarRendererIssues_jira">;
};

import calendarRendererIssues_jira_refetchQuery_graphql from './calendarRendererIssues_jira_refetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "jira",
  "jiraCalendar",
  "paginatedIssuesV2"
],
v1 = {
  "kind": "Variable",
  "name": "input",
  "variableName": "issuesSearchInput"
},
v2 = {
  "kind": "ScalarField",
  "name": "totalIssueSearchResultCount"
},
v3 = {
  "kind": "ScalarField",
  "name": "isCappingIssueSearchResult"
},
v4 = {
  "kind": "ScalarField",
  "name": "hasNextPage"
},
v5 = {
  "kind": "ScalarField",
  "name": "hasPreviousPage"
},
v6 = {
  "kind": "ScalarField",
  "name": "id"
},
v7 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "text"
    }
  ]
},
v8 = {
  "kind": "Variable",
  "name": "viewId",
  "variableName": "viewId"
},
v9 = [
  (v8/*: any*/)
],
v10 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    (v10/*: any*/),
    {
      "kind": "ScalarField",
      "name": "date"
    }
  ],
  "type": "JiraDatePickerField"
},
v12 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v13 = [
  (v6/*: any*/),
  (v11/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      (v10/*: any*/),
      {
        "kind": "ScalarField",
        "name": "dateTime"
      }
    ],
    "type": "JiraDateTimePickerField"
  },
  (v12/*: any*/)
],
v14 = [
  (v6/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/)
],
v15 = {
  "kind": "LinkedField",
  "name": "startDateViewField",
  "plural": false,
  "selections": (v14/*: any*/)
},
v16 = {
  "kind": "LinkedField",
  "name": "endDateViewField",
  "plural": false,
  "selections": (v14/*: any*/)
},
v17 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "key"
    },
    (v7/*: any*/),
    {
      "condition": "schedulePermissionsEnabled",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "canSchedule",
          "args": [
            {
              "kind": "Literal",
              "name": "permission",
              "value": "SCHEDULE_ISSUES"
            }
          ],
          "kind": "ScalarField",
          "name": "hasProjectPermission",
          "storageKey": "hasProjectPermission(permission:\"SCHEDULE_ISSUES\")"
        }
      ]
    },
    {
      "args": (v9/*: any*/),
      "kind": "LinkedField",
      "name": "startDateViewField",
      "plural": false,
      "selections": (v13/*: any*/)
    },
    {
      "args": (v9/*: any*/),
      "kind": "LinkedField",
      "name": "endDateViewField",
      "plural": false,
      "selections": (v13/*: any*/)
    },
    {
      "args": (v9/*: any*/),
      "concreteType": "JiraScenarioIssueValues",
      "kind": "LinkedField",
      "name": "planScenarioValues",
      "plural": false,
      "selections": [
        (v7/*: any*/),
        (v15/*: any*/),
        (v16/*: any*/)
      ]
    }
  ],
  "type": "JiraIssue"
},
v18 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": (v9/*: any*/),
      "concreteType": "JiraScenarioIssueValues",
      "kind": "LinkedField",
      "name": "planScenarioValues",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "scenarioKey"
        },
        (v7/*: any*/),
        (v15/*: any*/),
        (v16/*: any*/)
      ]
    }
  ],
  "type": "JiraScenarioIssue"
},
v19 = {
  "args": [
    {
      "kind": "Variable",
      "name": "schedulePermissionsEnabled",
      "variableName": "schedulePermissionsEnabled"
    },
    (v8/*: any*/)
  ],
  "kind": "FragmentSpread",
  "name": "issueRenderer_calendar_IssueEventRenderer"
},
v20 = {
  "args": [
    {
      "kind": "Variable",
      "name": "pageSize",
      "variableName": "issuesPageSize"
    }
  ],
  "kind": "FragmentSpread",
  "name": "issuesPaginator_calendar"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "configurationInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "issuesPageCursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "issuesPageSize"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "issuesSearchInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageLoadMoreEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "schedulePermissionsEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeInput"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "skipFieldConfig"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "issuesPageSize",
        "cursor": "issuesPageCursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "issuesPageSize",
          "cursor": "issuesPageCursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": calendarRendererIssues_jira_refetchQuery_graphql
    }
  },
  "name": "calendarRendererIssues_jira",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "configuration",
              "variableName": "configurationInput"
            },
            {
              "kind": "Variable",
              "name": "scope",
              "variableName": "scopeInput"
            }
          ],
          "concreteType": "JiraCalendar",
          "kind": "LinkedField",
          "name": "jiraCalendar",
          "plural": false,
          "selections": [
            {
              "condition": "pageLoadMoreEnabled",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "alias": "paginatedIssuesV2",
                  "args": [
                    (v1/*: any*/)
                  ],
                  "concreteType": "JiraScenarioIssueLikeConnection",
                  "kind": "LinkedField",
                  "name": "__calendarRendererIssues_paginatedIssuesV2_connection",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v3/*: any*/),
                    {
                      "concreteType": "PageInfo",
                      "kind": "LinkedField",
                      "name": "pageInfo",
                      "plural": false,
                      "selections": [
                        (v4/*: any*/),
                        (v5/*: any*/),
                        {
                          "kind": "ScalarField",
                          "name": "endCursor"
                        }
                      ]
                    },
                    {
                      "concreteType": "JiraScenarioIssueLikeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v6/*: any*/),
                            (v17/*: any*/),
                            (v18/*: any*/),
                            (v19/*: any*/),
                            (v12/*: any*/),
                            (v10/*: any*/)
                          ]
                        },
                        {
                          "kind": "ScalarField",
                          "name": "cursor"
                        }
                      ]
                    },
                    (v20/*: any*/),
                    (v12/*: any*/)
                  ]
                }
              ]
            },
            {
              "condition": "pageLoadMoreEnabled",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "after",
                      "variableName": "issuesPageCursor"
                    },
                    {
                      "kind": "Variable",
                      "name": "first",
                      "variableName": "issuesPageSize"
                    },
                    (v1/*: any*/)
                  ],
                  "concreteType": "JiraScenarioIssueLikeConnection",
                  "kind": "LinkedField",
                  "name": "issuesV2",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v3/*: any*/),
                    {
                      "concreteType": "PageInfo",
                      "kind": "LinkedField",
                      "name": "pageInfo",
                      "plural": false,
                      "selections": [
                        (v4/*: any*/),
                        (v5/*: any*/)
                      ]
                    },
                    {
                      "concreteType": "JiraScenarioIssueLikeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v6/*: any*/),
                            (v17/*: any*/),
                            (v18/*: any*/),
                            (v19/*: any*/),
                            (v12/*: any*/)
                          ]
                        }
                      ]
                    },
                    (v20/*: any*/),
                    (v12/*: any*/)
                  ]
                }
              ]
            },
            {
              "condition": "skipFieldConfig",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "kind": "LinkedField",
                    "name": "startDateField",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": {
                          "kind": "ScalarField",
                          "name": "type"
                        },
                        "action": "THROW",
                        "path": "jira.jiraCalendar.startDateField.type"
                      }
                    ]
                  },
                  "action": "THROW",
                  "path": "jira.jiraCalendar.startDateField"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "de599f809676d1dfe91c4d9de074bc70";

export default node;
