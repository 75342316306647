/**
 * @generated SignedSource<<b4883fa29d9e97e9abe6d6ee9f6ccd49>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type LazyLoadIssues$data = {
  readonly pageInfo: {
    readonly endCursor: string | null | undefined;
    readonly hasNextPage: boolean;
  };
  readonly " $fragmentType": "LazyLoadIssues";
};
export type LazyLoadIssues$key = {
  readonly " $data"?: LazyLoadIssues$data;
  readonly " $fragmentSpreads": FragmentRefs<"LazyLoadIssues">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "LazyLoadIssues",
  "selections": [
    {
      "concreteType": "PageInfo",
      "kind": "LinkedField",
      "name": "pageInfo",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "hasNextPage"
        },
        {
          "kind": "ScalarField",
          "name": "endCursor"
        }
      ]
    }
  ],
  "type": "JiraScenarioIssueLikeConnection"
};

(node as any).hash = "be04eff9fc85d4a0f0c045b017bb4f64";

export default node;
