import {
  JiraVersionAriResourceOwner,
  JiraVersionAriResourceType
} from "./chunk-LN36NVHR.mjs";
import {
  RegisteredAri
} from "./chunk-YG2E6ZVD.mjs";
import {
  AriParser
} from "./chunk-Q7FABYST.mjs";

// src/jira/version/manifest.ts
var jiraVersionAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^[a-zA-Z0-9_\\-.]{1,255}$"),
  // eslint-disable-line no-useless-escape
  resourceOwner: JiraVersionAriResourceOwner,
  resourceType: JiraVersionAriResourceType,
  resourceIdSlug: "activation/{activationId}/{versionId}",
  resourceIdSegmentFormats: {
    activationId: /[a-zA-Z0-9\-]+/,
    // eslint-disable-line no-useless-escape
    versionId: /[!a-zA-Z0-9\-_.~@:{}=]+(\/[!a-zA-Z0-9\-_.~@:{}=]+)*/
    // eslint-disable-line no-useless-escape
  }
};

// src/jira/version/index.ts
var JiraVersionAri = class _JiraVersionAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._siteId = opts.cloudId || "", this._activationId = opts.resourceIdSegmentValues.activationId, this._versionId = opts.resourceIdSegmentValues.versionId;
  }
  get siteId() {
    return this._siteId;
  }
  get activationId() {
    return this._activationId;
  }
  get versionId() {
    return this._versionId;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: jiraVersionAriStaticOpts.qualifier,
      platformQualifier: jiraVersionAriStaticOpts.platformQualifier,
      cloudId: opts.siteId,
      resourceOwner: jiraVersionAriStaticOpts.resourceOwner,
      resourceType: jiraVersionAriStaticOpts.resourceType,
      resourceId: `activation/${opts.activationId}/${opts.versionId}`,
      resourceIdSegmentValues: {
        activationId: opts.activationId,
        versionId: opts.versionId
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, jiraVersionAriStaticOpts);
    return new _JiraVersionAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, jiraVersionAriStaticOpts);
    return new _JiraVersionAri(opts);
  }
  getVariables() {
    return {
      siteId: this.siteId,
      activationId: this.activationId,
      versionId: this.versionId
    };
  }
};

export {
  JiraVersionAri
};
