import React from 'react';
import { JiraVersionAri } from '@atlassian/ari/jira';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { ActionMenuItem } from '@atlassian/jira-release-flyout/src/ui/actions-menu/index.tsx';
import { getVersionUrl } from '../../../../../common/utils/get-version-url/index.tsx';
import { messages } from './messages.tsx';

export const ViewReleaseItem = ({
	versionAri,
	projectKey,
	onClosePopup,
}: {
	versionAri: string;
	projectKey: string;
	onClosePopup: () => void;
}) => {
	const { formatMessage } = useIntl();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onViewRelease = () => {
		const versionId = JiraVersionAri.parse(versionAri).versionId;
		const url = getVersionUrl(versionId, projectKey);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.open(url, '_blank');
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'releaseViewed',
			}),
			'releaseFlyout',
		);
		onClosePopup();
	};

	return (
		<ActionMenuItem onClick={onViewRelease}>{formatMessage(messages.viewRelease)}</ActionMenuItem>
	);
};
