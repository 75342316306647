import React, {
	// eslint-disable-next-line jira/restricted/react
	PureComponent,
	useEffect,
	useRef,
	useState,
	type ComponentType,
	type ReactNode,
} from 'react';
import { enableBatching } from 'redux-batched-actions';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import AnalyticsData from '@atlassian/jira-analytics-web-react/src/components/analytics-data.tsx';
import { AnalyticsSource } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useAnalyticsEvents,
	SCREEN,
	ContextualAnalyticsData,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { ServiceDeskAnalyticsContext } from '@atlassian/jira-servicedesk-analytics/src/ui/servicedesk-analytics-context/index.tsx';
import ServiceDeskSubAppBase from '@atlassian/jira-servicedesk-common/src/ui/components/servicedesk-subapp-base/index.tsx';
import { ANALYTIC_CONTEXTS } from '@atlassian/jira-servicedesk-common/src/utils/analytics/index.tsx';
import { APP_NAMES } from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import {
	AsyncProjectBuilderDrawer as ProjectBuilderDrawer,
	canUseProjectBuilder,
} from '@atlassian/jira-servicedesk-project-builder/src/async.tsx';
import { SHOW_PROJECT_BUILDER_QUERY_PARAM_DEV } from '@atlassian/jira-servicedesk-project-builder/src/common/constants.tsx';
import { SSR_SOURCE } from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import { QueuesErrorView } from '@atlassian/jira-servicedesk-queues-common/src/view/queues-error-state/index.tsx';
import type { QueuesPush } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/queues-push-provider/index.tsx';
import { toProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useIsStagingOrDevOrLocal } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useQueryParam, useResource } from '@atlassian/react-resource-router';
import { issueListSsrResource } from '@atlassian/jira-router-resources-service-desk-queues/src/services/index.tsx';
import type {
	OnAtLeastOneIssueLoadedCallback,
	OnQueueRenderFailureCallback,
	SortedQueue,
} from '../model/index.tsx';
import rootEpic from '../ops/index.tsx';
import transformIssueData from '../services/issue/transform/index.tsx';
import { createSortedQueue } from '../services/sort/index.tsx';
import { sendAnalyticCreatorAction } from '../state/actions/analytic/index.tsx';
import { resetProjectContextAction } from '../state/actions/app-props/index.tsx';
import rootReducer from '../state/reducers/index.tsx';
import {
	addIssuesResponseToState,
	DEFAULT_ISSUES_STATE,
} from '../state/reducers/persisted/issues/utils.tsx';
import type { InitialState } from '../state/reducers/types.tsx';
import { ChangeManagementTour } from './layout/change-management-tour/view.tsx';
import QueuesExperienceTrackerProvider from './layout/experience-tracker/provider/index.tsx';
import FlagsManager from './layout/flags-manager/view.tsx';
import { IncidentManagementTour } from './layout/incident-management-tour/view.tsx';
import AgentView from './layout/index.tsx';

export type Props = {
	isQueueVisible: boolean;
	initialState: InitialState;
	// SHIELD-3608: payload should be of type Queue. Currently used as SortedQueue
	// to support legacy
	queue: SortedQueue;
	locale: Locale;
	push: QueuesPush;
	onAtLeastOneIssueLoaded: OnAtLeastOneIssueLoadedCallback;
	onQueueRenderFailure: OnQueueRenderFailureCallback | undefined;
	onIssuesChanged?: (keys: (string | undefined)[]) => void;
	onIssuesCountChanged?: (count: number) => void;
};

type ReduxAnalyticsProps = {
	children?: ReactNode;
	setAnalyticsToRedux: (createAnalyticsEvent: CreateUIAnalyticsEvent) => void;
};

// for local dev, to be able to open Project Builder without creating project each time
const ProjectBuilderWithQueryParamDev = () => {
	const isAdmin = useIsAdmin();
	const isStagingOrDev = useIsStagingOrDevOrLocal();

	const [projectBuilderQueryParamDev] = useQueryParam(SHOW_PROJECT_BUILDER_QUERY_PARAM_DEV);

	const [isDrawerOpen] = useState(() => isStagingOrDev && projectBuilderQueryParamDev === 'true');

	if (isDrawerOpen) {
		return canUseProjectBuilder(isAdmin) ? <ProjectBuilderDrawer isOpenInitial /> : null;
	}
	return null;
};

const ReduxAnalytics = connect(null, (dispatch) => ({
	setAnalyticsToRedux: (createAnalyticsEvent: CreateUIAnalyticsEvent) => {
		dispatch(sendAnalyticCreatorAction(createAnalyticsEvent));
	},
}))(({ setAnalyticsToRedux, children }: ReduxAnalyticsProps) => {
	const [isAnalyticsSet, setIsAnalyticsSet] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	// Save setAnalyticsToReduxRef to ref to avoid useEffect reevaluation on every rerender
	const setAnalyticsToReduxRef = useRef<ReduxAnalyticsProps['setAnalyticsToRedux']>();
	setAnalyticsToReduxRef.current = setAnalyticsToRedux;

	useEffect(() => {
		setAnalyticsToReduxRef.current && setAnalyticsToReduxRef.current(createAnalyticsEvent);
		setIsAnalyticsSet(true);
	}, [createAnalyticsEvent]);
	// in server context we need to return children
	if (__SERVER__ && fg('issuelist_render_ssr_state_initialisation')) {
		return children;
	}

	return isAnalyticsSet ? children : null;
});

// eslint-disable-next-line jira/react/no-class-components
class AgentApp extends PureComponent<Props> {
	static defaultProps = {
		queue: undefined,
		push: undefined,
	};

	renderAgentView() {
		const {
			isQueueVisible,
			onAtLeastOneIssueLoaded,
			queue,
			onIssuesChanged,
			onIssuesCountChanged,
		} = this.props;

		return fg('jsm_flash_servicedesk_analytics_removal') ? (
			<>
				<AgentView
					queue={queue}
					isQueueVisible={isQueueVisible}
					onAtLeastOneIssueLoaded={onAtLeastOneIssueLoaded}
					onIssuesChanged={onIssuesChanged}
					onIssuesCountChanged={onIssuesCountChanged}
				/>
				<FlagsManager />
			</>
		) : (
			<ServiceDeskAnalyticsContext forceRenderWhileLoading>
				<AgentView
					queue={queue}
					isQueueVisible={isQueueVisible}
					onAtLeastOneIssueLoaded={onAtLeastOneIssueLoaded}
					onIssuesChanged={onIssuesChanged}
					onIssuesCountChanged={onIssuesCountChanged}
				/>
				<FlagsManager />
			</ServiceDeskAnalyticsContext>
		);
	}

	render() {
		const { initialState, locale, onQueueRenderFailure, push } = this.props;
		const state = {
			appProps: initialState.appProps,
		};

		return (
			<ServiceDeskSubAppBase
				appName={APP_NAMES.QUEUES_AGENT_VIEW}
				initialState={state}
				locale={locale}
				rootReducer={enableBatching(rootReducer)}
				rootEpic={rootEpic(push)}
				onErrorHandler={onQueueRenderFailure}
				ErrorView={QueuesErrorView}
				resetProjectContextAction={resetProjectContextAction}
			>
				{
					// forceRenderWhileLoading guards against remounting caused by
					// ServiceDeskAnalyticsContext in rare scenarios, which can in turn
					// result in "Cannot update during an existing state transition".
					//
					// See: `this.triggerQueueChange()` called during render() in https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/browse/jira/src/packages/servicedesk/queues-agent-view/src/view/layout/view.tsx#313
					<ServiceDeskAnalyticsContext forceRenderWhileLoading>
						<ReduxAnalytics>
							<QueuesExperienceTrackerProvider>
								{this.renderAgentView()}
							</QueuesExperienceTrackerProvider>
							<ProjectBuilderWithQueryParamDev />
							<ChangeManagementTour projectId={toProjectId(`${state.appProps.projectId}`)} />
							<JSErrorBoundary
								id="incidentManagementTourQueues"
								packageName="jiraServicedeskIncidentManagementTour"
								fallback="flag"
							>
								<IncidentManagementTour projectId={toProjectId(`${state.appProps.projectId}`)} />
							</JSErrorBoundary>
						</ReduxAnalytics>
					</ServiceDeskAnalyticsContext>
				}
			</ServiceDeskSubAppBase>
		);
	}
}

const AgentViewComponent = (props: Props) => {
	const { isQueueVisible, onAtLeastOneIssueLoaded, queue, onIssuesChanged, onIssuesCountChanged } =
		props;
	return fg('jsm_flash_servicedesk_analytics_removal') ? (
		<>
			<AgentView
				queue={queue}
				isQueueVisible={isQueueVisible}
				onAtLeastOneIssueLoaded={onAtLeastOneIssueLoaded}
				onIssuesChanged={onIssuesChanged}
				onIssuesCountChanged={onIssuesCountChanged}
			/>
			<FlagsManager />
		</>
	) : (
		<ServiceDeskAnalyticsContext forceRenderWhileLoading>
			<AgentView
				queue={queue}
				isQueueVisible={isQueueVisible}
				onAtLeastOneIssueLoaded={onAtLeastOneIssueLoaded}
				onIssuesChanged={onIssuesChanged}
				onIssuesCountChanged={onIssuesCountChanged}
			/>
			<FlagsManager />
		</ServiceDeskAnalyticsContext>
	);
};

const AgentAppComponent = (props: Props) => {
	const { initialState, locale, onQueueRenderFailure, push, queue } = props;
	const { data, error } = useResource(issueListSsrResource);
	const ssrIssueList = data ?? null;

	const state = {
		appProps: initialState.appProps,
		persisted: {
			ssrIssuesData: {
				ssrIssueList,
				error,
				isInitialLoading: true,
			},
			issues: DEFAULT_ISSUES_STATE,
		},
		ui: {},
	};

	// Server side state initialisation
	if (__SERVER__ && ssrIssueList && !error && fg('issuelist_render_ssr_state_initialisation')) {
		state.ui = {
			currentQueue: {
				...createSortedQueue(queue),
			},
			isLoadingIssues: false,
		};

		const { totalCount, isUsingDefaultSorting, issues } = transformIssueData(
			ssrIssueList,
			SSR_SOURCE,
			false,
			false,
		);
		// @ts-expect-error - TS2322: Type 'never' is not assignable to type 'CollectionItem'.
		state.persisted.issues = addIssuesResponseToState(0, totalCount, isUsingDefaultSorting, issues);
	}

	return (
		<ServiceDeskSubAppBase
			appName={APP_NAMES.QUEUES_AGENT_VIEW}
			initialState={state}
			locale={locale}
			rootReducer={enableBatching(rootReducer)}
			rootEpic={rootEpic(push)}
			onErrorHandler={onQueueRenderFailure}
			ErrorView={QueuesErrorView}
			resetProjectContextAction={resetProjectContextAction}
		>
			<ContextualAnalyticsData sourceType={SCREEN} sourceName="viewQueue">
				<ServiceDeskAnalyticsContext forceRenderWhileLoading>
					<ReduxAnalytics>
						<QueuesExperienceTrackerProvider>
							<AgentViewComponent {...props} />
						</QueuesExperienceTrackerProvider>
						<ProjectBuilderWithQueryParamDev />
						<ChangeManagementTour projectId={toProjectId(`${state.appProps.projectId}`)} />
						<JSErrorBoundary
							id="incidentManagementTourQueues"
							packageName="jiraServicedeskIncidentManagementTour"
							fallback="flag"
						>
							<IncidentManagementTour projectId={toProjectId(`${state.appProps.projectId}`)} />
						</JSErrorBoundary>
					</ReduxAnalytics>
				</ServiceDeskAnalyticsContext>
				<FireScreenAnalytics attributes={{ context: ANALYTIC_CONTEXTS.QUEUES, redesign: true }} />
			</ContextualAnalyticsData>
		</ServiceDeskSubAppBase>
	);
};

const AgentAppWithFF = () => {
	if (!fg('issuelist_skip_fetch_cache_hit')) {
		return AgentAppWithAnalytics;
	}
	return AgentAppComponent;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const AgentAppWithAnalytics: ComponentType<Props> = AnalyticsSource(
	'viewQueue',
	SCREEN,
)(
	AnalyticsData({
		attributes: {
			context: ANALYTIC_CONTEXTS.QUEUES,
			redesign: true,
		},
	})(AgentApp),
) as any; // eslint-disable-line @typescript-eslint/no-explicit-any

export default AgentAppWithFF();
