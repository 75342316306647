import { addMinutes, addHours, differenceInMinutes, isBefore } from 'date-fns';
import { type UIAnalyticsEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	ANALYTICS_SOURCE,
	DEFAULT_SLOT_DURATION_HOUR,
	MINIMUM_SLOT_DURATION_MINUTES,
	UI_EVENT_DROPPED,
	UNSCHEDULE_PANEL_SOURCE,
} from '../../../common/constants.tsx';
import {
	type DraggableCalendarIssueData,
	type DateRange,
	type NullableDateRange,
	isDraggableCalendarIssue,
} from '../../../services/draggable-card-type/index.tsx';
import type { WeekViewCalendarMutation } from './types.tsx';

export type DragEnterProps = {
	source: { data: Record<string, unknown> };
};

export const getExpectedDateTimeRange = ({
	startDate,
	endDate,
	droppingOverCell,
}: NullableDateRange & {
	droppingOverCell: Date;
	draggedDate?: Date;
}): DateRange => {
	// Drag from unscheduled panel (or another source)
	if (!endDate) {
		if (!startDate) {
			return {
				startDate: droppingOverCell,
				endDate: addHours(droppingOverCell, DEFAULT_SLOT_DURATION_HOUR),
			};
		}

		// if the droppingOverCell date is before the start date,
		// we will take the droppingOverCell as the new start date
		const newStartDate = isBefore(droppingOverCell, startDate) ? droppingOverCell : startDate;

		return {
			endDate: addHours(droppingOverCell, DEFAULT_SLOT_DURATION_HOUR),
			startDate: newStartDate,
		};
	}

	// If issue has both start and end date, retain min diff when dragging
	if (startDate && endDate) {
		const newEndDate = shiftEndDate(startDate, endDate, droppingOverCell /* new start date */);
		return { startDate: droppingOverCell, endDate: newEndDate };
	}

	// If there is no start date, we will set the droppingOverCell as the new start date
	return {
		startDate: droppingOverCell,
		endDate: addHours(droppingOverCell, DEFAULT_SLOT_DURATION_HOUR),
	};
};

export const shiftEndDate = (
	originalStartDate: Date,
	originalEndDate: Date,
	newStartDate: Date,
) => {
	// Calcualte original event length
	const shiftMinutes = Math.max(
		Math.abs(differenceInMinutes(originalEndDate, originalStartDate)),
		MINIMUM_SLOT_DURATION_MINUTES,
	);
	return addMinutes(newStartDate, shiftMinutes);
};

export class WeekViewDropTargetController {
	constructor(
		private readonly el: HTMLElement,
		private readonly droppingOverCell: Date,
		private readonly calendarMutations: WeekViewCalendarMutation,
		private readonly tableCellsByDate: Map<number, HTMLElement>,
		private readonly uiAnalyticsEvent: UIAnalyticsEvent,
		private readonly isDateTimeConfigured: boolean,
		private readonly onError?: () => void,
	) {
		// Do nothing
	}

	onDragEnter(args: DragEnterProps): boolean {
		const { data } = args.source;
		if (!isDraggableCalendarIssue(data)) {
			return false;
		}

		this.highlightCells(data);

		return true;
	}

	onDrop(args: DragEnterProps): boolean {
		// Run clean-up functions
		this.onDragLeave();

		const { data } = args.source;
		if (!isDraggableCalendarIssue(data)) {
			return false;
		}

		if (!this.isDateTimeConfigured) {
			this.onError?.();
			return false;
		}

		const { startDate, endDate } = this.getDateRange(data);

		this.calendarMutations.scheduleCalendarIssue({
			issueAri: data.issueAri,
			issueRecordId: data.issueRecordId,
			startDateFieldRecordId: data.startDateFieldRecordId,
			endDateFieldRecordId: data.endDateFieldRecordId,
			// If the card is dragged from the panel, we remove it from the panel.
			// If the card is dragged from the calendar, we still want to keep it on the calendar.
			removeCardFromConnectionId:
				data.source === UNSCHEDULE_PANEL_SOURCE ? data.connectionRecordId : undefined,
			startDate,
			endDate,
			issueKey: data.issueKey,
		});

		fireUIAnalytics(this.uiAnalyticsEvent, ANALYTICS_SOURCE, {
			issueSource: data.source,
			calendarEvent: UI_EVENT_DROPPED,
			isUnscheduled: !data.endDateFieldRecordId,
			viewRange: 'week',
		});

		return true;
	}

	onDragLeave() {
		const elements = Array.from(this.tableCellsByDate.values());
		elements.forEach((element) => {
			element.classList.remove('jira-calendar-view-cell-highlight');
		});
	}

	getDateRange(data: DraggableCalendarIssueData): DateRange {
		return getExpectedDateTimeRange({
			startDate: data?.startDate ? new Date(data.startDate) : undefined,
			endDate: data?.endDate ? new Date(data.endDate) : undefined,
			droppingOverCell: this.droppingOverCell,
		});
	}

	highlightCell(dayCellElement: HTMLElement | null | undefined) {
		dayCellElement?.classList.add('jira-calendar-view-cell-highlight');
	}

	private highlightCells(data: DraggableCalendarIssueData) {
		const { startDate, endDate } = this.getDateRange(data);
		this.highlightCell(this.el);

		if (startDate) {
			for (
				let date = startDate;
				differenceInMinutes(endDate, date) > 0;
				date = addMinutes(date, MINIMUM_SLOT_DURATION_MINUTES)
			) {
				const dayCellElement = this.tableCellsByDate.get(date.getTime());
				this.highlightCell(dayCellElement);
			}
		}
	}
}
