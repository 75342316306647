import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// @fullcalendar/react must be imported before other FullCalendar
// imports as it configures FullCalendar to use React
/* eslint-disable import/order */
import FullCalendar from '@fullcalendar/react';
import type {
	DateInput,
	DidMountHandler,
	FormatDateOptions,
	SlotLabelContentArg,
	ViewMountArg,
} from '@fullcalendar/core';
import allLocales from '@fullcalendar/core/locales-all';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timezonePlugin from '@fullcalendar/moment-timezone';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { WidthObserver } from '@atlaskit/width-detector';

import type {
	CalendarView,
	CalendarViewProps,
	CalendarViewRange,
	Event,
	EventPopupContext,
} from '../../common/types';
import { usePopupController } from '../../common/ui/popup-controller';
import { useEventStateSync } from '../../controllers/event-state-sync';

import { useAllDayContent } from './all-day-content';
import { useDayCellContent } from './day-cell-content';
import { useDayHeaderContent } from './day-header-content';
import { type EventContentProps, useEventContentWrapper } from './event-content-wrapper';
import { useMoreLinkPopup } from './more-link-popup';
import { FullCalendarStyledWrapper, RelativeWrapper } from './styled';
import type {
	CalendarHookOptions,
	CalendarProps,
	CalendarProviderProps,
	EventAddPopupContentProps,
	EventClickPopupContentProps,
	PopupOptions,
} from './types';
import { fg } from '@atlaskit/platform-feature-flags';
import { formatTimezone, getFcView, mapToFcLocale, useFcCallbacks } from './utils';
import { useToFcEvent } from '../../controllers/event-state-sync/utils';

export type {
	EventContentProps,
	CalendarProps,
	CalendarHookOptions,
	CalendarProviderProps,
	EventClickPopupContentProps,
	EventAddPopupContentProps,
	PopupOptions,
};

export const eventTimeFormat: FormatDateOptions = {
	hour: 'numeric',
	minute: '2-digit',
	meridiem: 'short',
	omitZeroMinute: true,
};

export const listViewEventTimeFormat: FormatDateOptions = {
	hour: 'numeric',
	minute: '2-digit',
	meridiem: 'uppercase' as FormatDateOptions['meridiem'],
};

const SlotLabelWrapper = ({ text }: SlotLabelContentArg) => <Box xcss={slotLabelStyle}>{text}</Box>;

export { DefaultEventContent } from './event-content-wrapper';

export const useCalendar = <T extends Record<string, any>>({
	initialView = 'grid',
	initialViewRange = 'month',
	events,
	defaultEventBackgroundColor = token('color.background.accent.blue.subtle', '#7AB2FF'),
	hideScrollBar,
	hideAllDaySlot,
	EventContent,
	locale = 'en-US',
	timezone = 'local',
	enableSelectOnMonthView = false,
	scrollTime = '06:00:00',
	testId,
	onDateRangeChange,
	onEventClick,
	onEventDrag,
	onEventResize,
	onEventAdd,
	eventDidMount,
	todayWrapper,
	hasSimulateClickOnKeyDownWrapper = true,
	...rest
}: CalendarHookOptions<T>): CalendarProps => {
	const draggable = !!onEventDrag;
	const resizable = !!onEventResize;

	const calendarRef = useRef<FullCalendar>(null);
	const [calendarView, setCalendarView] = useState<CalendarViewProps>({
		view: initialView as CalendarView,
		viewRange: initialViewRange as CalendarViewRange,
		formatDate: (date: DateInput, options: FormatDateOptions) =>
			calendarRef.current?.getApi().formatDate(date, options) ?? '',
	});

	// Provide FullCalendar with event data
	const [placeholderEvents, setPlaceholderEvents] = useState<Event<T>[]>([]);
	const eventMap = useMemo(
		() => new Map([...events, ...placeholderEvents].map((event) => [event.id, event])),
		[events, placeholderEvents],
	);

	// Callbacks exposed to consumers
	const setView = useCallback((view: CalendarView) => {
		setCalendarView((prevCalendarView) => ({
			...prevCalendarView,
			view,
		}));
	}, []);
	const setViewRange = useCallback((viewRange: CalendarViewRange) => {
		setCalendarView((prevCalendarView) => ({
			...prevCalendarView,
			viewRange,
		}));
	}, []);
	const navigateNext = useCallback(() => {
		calendarRef.current?.getApi().next();
	}, []);
	const navigatePrev = useCallback(() => {
		calendarRef.current?.getApi().prev();
	}, []);
	const navigateToday = useCallback(() => {
		calendarRef.current?.getApi().today();
	}, []);
	const setDate = useCallback((date: Date) => {
		calendarRef.current?.getApi().gotoDate(date);
	}, []);
	const scrollToTime = useCallback((timeInput: string) => {
		calendarRef.current?.getApi().scrollToTime(timeInput);
	}, []);

	// Handle updates to the calendar view
	useEffect(() => {
		calendarRef.current?.getApi().changeView(getFcView(calendarView.view, calendarView.viewRange));
	}, [calendarView.view, calendarView.viewRange]);

	// Handle popup rendering
	const popupContext = useMemo(
		() => ({
			calendarView,
			eventMap,
			eventTimeFormat,
		}),
		[calendarView, eventMap],
	);

	const [{ arePopupsOpen, openPopupIds, popupContainer }, { openPopup }] =
		usePopupController<EventPopupContext<T>>(popupContext);

	const toFcEvent = useToFcEvent(draggable, resizable, defaultEventBackgroundColor);

	const processedEvents = useMemo(() => {
		// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
		if (fg('jira-calendar-performance-refactor')) {
			return [...events, ...placeholderEvents].map(toFcEvent);
		}
		return undefined;
	}, [events, placeholderEvents, toFcEvent]);

	// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
	if (!fg('jira-calendar-performance-refactor')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEventStateSync({
			calendarApi: calendarRef.current?.getApi() ?? null,
			eventMap,
			openPopupIds,
			globalDraggable: draggable,
			globalResizable: resizable,
			globalBackgroundColor: defaultEventBackgroundColor,
		});
	}

	// Translate FullCalendar callbacks to provided callbacks
	const eventContent = useEventContentWrapper({
		calendarView,
		eventMap,
		EventContent,
		hasSimulateClickOnKeyDownWrapper,
	});

	const selectable = !!(
		onEventAdd &&
		!arePopupsOpen &&
		calendarView.view === 'grid' &&
		(enableSelectOnMonthView || calendarView.viewRange !== 'month')
	);

	const callbacks = useFcCallbacks({
		calendarView,
		arePopupsOpen,
		openPopup,
		enableSelectOnMonthView,
		timezone,
		eventMap,
		calendarRef,
		onDateRangeChange,
		onEventClick,
		onEventDrag,
		onEventResize,
		onEventAdd,
		setCalendarView,
		setPlaceholderEvents,
		selectable,
	});

	const moreLinkPopup = useMoreLinkPopup(
		eventTimeFormat,
		callbacks.eventClick,
		openPopup,
		calendarView.formatDate,
		eventContent,
	);

	const formattedTimezone = formatTimezone(timezone);

	const allDayContent = useAllDayContent();
	const dayHeaderContent = useDayHeaderContent(
		calendarView.view,
		calendarView.viewRange,
		calendarView.formatDate,
	);
	const dayCellContent = useDayCellContent(
		calendarView.viewRange,
		timezone,
		calendarView.formatDate,
		todayWrapper,
	);

	// https://github.com/fullcalendar/fullcalendar/issues/7481
	// This is a workaround. If the linked github issue resolves, you may update full calendar and remove this workaround
	const viewDidMount: DidMountHandler<ViewMountArg> = ({ el }) => {
		el.querySelectorAll('.fc-scroller-liquid-absolute, .fc-scroller-liquid').forEach((element) => {
			element.setAttribute('tabindex', '0');
		});
		el.querySelectorAll('.fc-scrollgrid').forEach((element) => {
			element.setAttribute('role', 'grid group');
			element.querySelectorAll('tbody').forEach((tbodyEl) => {
				tbodyEl.setAttribute('role', 'rowgroup group');
			});
		});
	};

	const calendar = (
		<FullCalendarStyledWrapper
			data-testid={testId}
			hideScrollBar={hideScrollBar || arePopupsOpen}
			hideAllDaySlot={hideAllDaySlot}
			formattedTimezone={formattedTimezone}
			isColorContrastFix={fg('plan-calender-color-contrast-a11y-fix')}
		>
			<RelativeWrapper>
				<WidthObserver setWidth={() => calendarRef.current?.getApi().updateSize()} />
			</RelativeWrapper>
			<FullCalendar
				/*
				 * FSD-5171: Set the 'key' prop to the timezone so the FullCalendar remounts
				 *           to ensure the current day is highlighted correctly according
				 *           to the given timezone and not whatever timezone the calendar
				 *           was first rendered with
				 */
				{...rest}
				key={timezone}
				ref={calendarRef}
				plugins={[timezonePlugin, interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin]}
				scrollTime={scrollTime}
				height="100%"
				slotDuration="00:15:00"
				slotLabelInterval="01:00:00"
				slotLabelContent={SlotLabelWrapper}
				fixedWeekCount={false}
				dayMaxEventRows={calendarView.viewRange === 'month' ? true : 4}
				eventDisplay="block"
				eventTimeFormat={calendarView.view === 'list' ? listViewEventTimeFormat : eventTimeFormat}
				listDayFormat={{
					weekday: 'long',
					year: 'numeric',
					month: 'long',
					day: 'numeric',
				}}
				views={{
					timeGridFiveDay: {
						type: 'timeGrid',
						duration: { days: 5 },
					},
					listFiveDay: {
						type: 'list',
						duration: { days: 5 },
					},
				}}
				listDaySideFormat={false}
				headerToolbar={false}
				// Forces FullCalendar to always provide all-day events with an end
				// value when dragged
				forceEventDuration
				eventStartEditable={draggable}
				eventDurationEditable={resizable}
				selectable={selectable}
				selectMirror
				initialView={getFcView(initialView, initialViewRange)}
				locales={allLocales}
				locale={mapToFcLocale(locale) ?? 'en'}
				events={processedEvents}
				{...callbacks}
				{...(moreLinkPopup ?? {})}
				timeZone={timezone}
				allDayContent={allDayContent}
				dayHeaderContent={dayHeaderContent}
				dayCellContent={dayCellContent}
				eventContent={eventContent}
				allDaySlot={!hideAllDaySlot}
				handleWindowResize={false}
				eventDidMount={eventDidMount}
				{...(fg('fix_calendar_a11y_issue') && { viewDidMount: viewDidMount })}
			/>
			{popupContainer}
		</FullCalendarStyledWrapper>
	);

	return {
		calendar,
		...calendarView,
		setView,
		setViewRange,
		navigateNext,
		navigatePrev,
		navigateToday,
		scrollToTime,
		setDate,
	};
};

export const CalendarProvider = <T extends Record<string, any>>(
	props: CalendarProviderProps<T>,
) => {
	const { children, ...options } = props;
	const calendar = useCalendar(options);

	return <>{children(calendar)}</>;
};

const slotLabelStyle = xcss({
	overflow: 'visible',
});
