import { useCallback } from 'react';
import { useIssueContextActions } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';
import { SELECTED_ISSUE } from '../../constants.tsx';
import { useCalendarCapabilities, NONE } from '../capabilities-provider/index.tsx';

export function useCalendarIssueViewMode() {
	const [, { setDetailViewAsSidebar, setDetailViewAsModal }] = useIssueContextActions();

	const [, setSelectedIssueKey] = useQueryParam(SELECTED_ISSUE);

	const {
		issue: { modalType },
	} = useCalendarCapabilities();

	const openIssueView = useCallback(
		(issueKey: IssueKey) => {
			if (modalType === NONE || !issueKey) {
				return;
			}

			// Issue key in query params triggers issue view to open in modal or sidebar
			setSelectedIssueKey(issueKey);
		},
		[setSelectedIssueKey, modalType],
	);

	const closeIssueView = useCallback(() => {
		// No issue key in query params causes issue view not to render
		setSelectedIssueKey(undefined);
	}, [setSelectedIssueKey]);

	return {
		openIssueView,
		closeIssueView,
		setCalendarIssueViewAsSidebar: setDetailViewAsSidebar,
		setCalendarIssueViewAsModal: setDetailViewAsModal,
	};
}
