import React from 'react';
import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItem } from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/glyph/more';
import type { EntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages.tsx';

export type Props = {
	editSprintModalTriggerRef: EntryPointButtonTrigger;
	onClosePopup: () => void;
};

export const SprintActionsMenu = ({ editSprintModalTriggerRef, onClosePopup }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onEdit = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'sprintEdit',
			}),
			'sprintFlyout',
		);
		onClosePopup();
	};

	return (
		<>
			<DropdownMenu
				testId="sprint-flyout.ui.sprint-actions-menu.dropdown-menu"
				placement="bottom-start"
				trigger={({ triggerRef, ...triggerProps }) => (
					<Button
						{...triggerProps}
						testId="sprint-flyout.ui.sprint-actions-menu.button"
						aria-label={formatMessage(messages.menuButton)}
						ref={triggerRef}
						appearance="subtle"
						iconAfter={<MoreIcon label="" size="small" />}
					/>
				)}
			>
				<DropdownItem onClick={onEdit} ref={editSprintModalTriggerRef}>
					{formatMessage(messages.editSprint)}
				</DropdownItem>
			</DropdownMenu>
		</>
	);
};
