import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	loadingMoreMessage: {
		id: 'calendar.calendar-unscheduled-panel.calendar-unscheduled-panel-card-list.loading-more-message',
		defaultMessage: 'Loading more issues...',
		description: 'Loading more indicator for the unscheduled panel in Jira Calendar view',
	},
	mostRecent: {
		id: 'calendar.calendar-unscheduled-panel.calendar-unscheduled-panel-card-list.most-recent',
		defaultMessage: 'Most recent',
		description:
			'Sorting order indicator ("Most recent") for the unscheduled panel in Jira Calendar view',
	},
	sortingDirectionAsc: {
		id: 'calendar.calendar-unscheduled-panel.calendar-unscheduled-panel-card-list.sorting-direction-asc',
		defaultMessage: 'Ascending',
		description: 'Sorting order label ascending',
	},
	sortingDirectionDesc: {
		id: 'calendar.calendar-unscheduled-panel.calendar-unscheduled-panel-card-list.sorting-direction-desc',
		defaultMessage: 'Descending',
		description: 'Sorting order label descending',
	},
	loadingMoreMessageIssueTermRefresh: {
		id: 'calendar.calendar-unscheduled-panel.calendar-unscheduled-panel-card-list.loading-more-message-issue-term-refresh',
		defaultMessage: 'Loading more work items...',
		description: 'Loading more indicator for the unscheduled panel in Jira Calendar view',
	},
});
