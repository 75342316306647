import React, {
	Component,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	type SyntheticEvent,
	type KeyboardEvent,
} from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import Icon from '@atlaskit/icon/base';
import { colors } from '@atlaskit/theme';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { ColumnTitleType } from '@atlassian/jira-virtual-table/src/model/columns/index.tsx';
import fieldConfigurationsMap, {
	type FieldConfigurationsMap,
} from '../../model/fields/field-configurations-map/index.tsx';
import { type SortOrder, NONE, ASC, DESC } from '../../model/fields/sort-order/index.tsx';
import messages from './messages.tsx';
import SortOrderTooltip from './sort-order-tooltip/view.tsx';

const ascendingGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 25">
		<path
			d="M10.7 16.7v-5.6l-1.3 1.3c-.4.4-1 .4-1.4 0-.2-.2-.3-.5-.3-.7 0-.3.1-.5.3-.7l3-3c.2-.2.5-.3.7-.3.3 0 .5.1.7.3l3 3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0l-1.3-1.3v5.6c0 .6-.5 1-1 1s-1-.5-1-1z"
			fillRule="evenodd"
			clipRule="evenodd"
			fill="currentColor"
		/>
	</svg>
);

const descendingGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 25">
		<path
			d="M12.7 8.7v5.6L14 13c.4-.4 1-.4 1.4 0 .2.2.3.5.3.7 0 .3-.1.5-.3.7l-3 3c-.2.2-.5.3-.7.3-.3 0-.5-.1-.7-.3l-3-3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l1.3 1.3V8.7c0-.6.4-1 1-1s1 .4 1 1z"
			fillRule="evenodd"
			clipRule="evenodd"
			fill="currentColor"
		/>
	</svg>
);

export type Props = {
	isDefaultSortOrder: boolean;
	isSortable: boolean;
	isDisabled: boolean;
	columnId: string;
	allowClearingSort: boolean;
	columnName: ColumnTitleType;
	fieldType: keyof FieldConfigurationsMap;
	sortOrder: SortOrder;
	width: number | undefined;
	onSortOrderChange:
		| ((columnId: string, newSortOrder: SortOrder, analyticsEvent: UIAnalyticsEvent) => void)
		| undefined;
	intl: IntlShape;
};

type State = {
	sortOrderChanged: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export class SortColumnHeader extends Component<Props, State> {
	static defaultProps = {
		isDefaultSortOrder: false,
		allowClearingSort: false,
		sortOrder: NONE,
		width: undefined,
		onSortOrderChange: undefined,
	};

	state = {
		sortOrderChanged: false,
	};

	onSortOrderChange = (e: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
		const { onSortOrderChange, sortOrder, columnId } = this.props;
		const nextSortOrder = this.getNextSortOrder(sortOrder);
		onSortOrderChange && onSortOrderChange(columnId, nextSortOrder, analyticsEvent);
		this.setState({
			sortOrderChanged: true,
		});
	};

	onSortOrderTooltipShow = () => {
		this.setState({
			sortOrderChanged: false,
		});
	};

	onKeyDownCapture(event: KeyboardEvent<HTMLSpanElement>) {
		if (event.key === 'Enter') {
			event.stopPropagation();
		}
	}

	getTooltipText(columnName: ColumnTitleType) {
		if (typeof columnName === 'string') {
			return `${columnName} • ${this.getSortOrderTooltipText()}`;
		}
		return this.getSortOrderTooltipText();
	}

	getSortOrderTooltipText() {
		switch (this.props.sortOrder) {
			case NONE:
				return this.getNoOrderTooltipText();
			case ASC:
				return this.getAscOrderTooltipText();
			case DESC:
				return this.getDescOrderTooltipText();
			default:
				return 'Invalid state';
		}
	}

	getNoOrderTooltipText() {
		const {
			fieldType,
			intl: { formatMessage: format },
		} = this.props;
		const fieldConfigs = fieldConfigurationsMap[fieldType];

		if (fieldConfigs && fieldConfigs.ascText) {
			return format(messages.sortOrder, {
				sortOrder: format(fieldConfigs.ascText),
			});
		}

		return format(messages.defaultSortOrder, {
			isAscending: true,
		});
	}

	getAscOrderTooltipText() {
		const {
			fieldType,
			isDefaultSortOrder,
			intl: { formatMessage: format },
		} = this.props;
		const fieldConfigs = fieldConfigurationsMap[fieldType];

		if (fieldConfigs && fieldConfigs.ascText) {
			return format(messages.sortedOrder, {
				sortOrder: format(fieldConfigs.ascText),
				isDefault: isDefaultSortOrder,
			});
		}

		return format(messages.defaultSortedOrder, {
			isAscending: true,
			isDefault: isDefaultSortOrder,
		});
	}

	getDescOrderTooltipText() {
		const {
			fieldType,
			isDefaultSortOrder,
			intl: { formatMessage: format },
		} = this.props;
		const fieldConfigs = fieldConfigurationsMap[fieldType];

		if (fieldConfigs && fieldConfigs.descText) {
			return format(messages.sortedOrder, {
				sortOrder: format(fieldConfigs.descText),
				isDefault: isDefaultSortOrder,
			});
		}

		return format(messages.defaultSortedOrder, {
			isAscending: false,
			isDefault: isDefaultSortOrder,
		});
	}

	getNextSortOrder(currentSortOrder: SortOrder): SortOrder {
		switch (currentSortOrder) {
			case NONE:
				return ASC;
			case ASC:
				return DESC;
			case DESC:
			default:
				return this.props.allowClearingSort ? NONE : ASC;
		}
	}

	getHeaderIcon() {
		switch (this.props.sortOrder) {
			case ASC:
				return <Icon glyph={ascendingGlyph} label="Ascending icon" size="medium" />;
			case DESC:
				return <Icon glyph={descendingGlyph} label="Descending icon" size="medium" />;
			default:
				return (
					<Icon
						glyph={ascendingGlyph}
						label="Sort-by icon"
						size="medium"
						primaryColor={token('color.icon.subtle', colors.N60)}
					/>
				);
		}
	}

	getButtonWithTooltip() {
		const { columnName, sortOrder, width, isDisabled } = this.props;
		const buttonAppearance = sortOrder === NONE ? 'subtle' : 'default';
		const isHeaderSelected = sortOrder !== NONE;

		return (
			<SortOrderTooltip
				isSortOrderChanged={this.state.sortOrderChanged}
				tooltipText={this.getTooltipText(columnName)}
				onShow={this.onSortOrderTooltipShow}
			>
				<Button
					appearance={buttonAppearance}
					onClick={this.onSortOrderChange}
					spacing="none"
					isDisabled={isDisabled}
				>
					<HeaderContent hasIcon={isHeaderSelected} hasFixedWidth={!!width}>
						<HeaderText hasIcon={isHeaderSelected} maxWidth={width}>
							{columnName}
						</HeaderText>
						{
							/* The icon must be part of the tooltip target, so we can't use the
                            `iconAfter` prop of the atlaskit button */
							<IconWrapper isVisible={isHeaderSelected}>{this.getHeaderIcon()}</IconWrapper>
						}
					</HeaderContent>
				</Button>
			</SortOrderTooltip>
		);
	}

	render() {
		const { isSortable, columnName, width, isDisabled } = this.props;

		if (isSortable || isDisabled) {
			return (
				<HeaderWrapperExperiment
					onKeyDownCapture={this.onKeyDownCapture}
					data-component-selector="header-wrapper-experiment-component-selector"
				>
					{this.getButtonWithTooltip()}
				</HeaderWrapperExperiment>
			);
		}

		return <PlainHeader maxWidth={width}>{columnName}</PlainHeader>;
	}
}

export default injectIntl(SortColumnHeader);

/* Icon also appears under these pseudo-classes */
const addWhenInteracted =
	'button:focus &, [data-component-selector="header-wrapper-experiment-component-selector"]:hover &';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderWrapperExperiment = styled.span({
	display: 'inline-block',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'button:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		boxShadow: `inset 0 0 0 2px ${token('color.border.focused', colors.B200)}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'button:focus-visible': {
		outline: 'unset',
		outlineOffset: 'unset',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderContent = styled.span<{
	hasIcon: boolean;
	hasFixedWidth: boolean;
}>({
	boxSizing: 'border-box',
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ hasFixedWidth, hasIcon }) =>
		`${token('space.0', '0px')} ${hasFixedWidth || hasIcon ? token('space.0', '0px') : token('space.300', '24px')} ${token('space.0', '0px')} ${token('space.100', '8px')}`,
	textAlign: 'left',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[addWhenInteracted]: {
		paddingRight: token('space.0', '0px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderText = styled.span<{ hasIcon: boolean; maxWidth: number | undefined }>({
	display: 'inline-block',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	lineHeight: `${gridSize * 3}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: ({ hasIcon, maxWidth }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		maxWidth ? `${maxWidth - gridSize * (hasIcon ? 5 : 2)}px` : undefined,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[addWhenInteracted]: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		maxWidth: ({ maxWidth }) => (maxWidth ? `${maxWidth - gridSize * 5}px` : undefined),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlainHeader = styled.div<{ maxWidth: number | undefined }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N400),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	lineHeight: `${gridSize * 3}px`,
	paddingTop: 0,
	paddingRight: token('space.100', '8px'),
	paddingBottom: 0,
	paddingLeft: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: ({ maxWidth }) => (maxWidth ? `${maxWidth}px` : undefined), // Use `undefined` to avoid applying the style if `maxWidth` is not set
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.span<{ isVisible: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isVisible }) => (isVisible ? 'inherit' : 'none'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[addWhenInteracted]: {
		display: 'inherit',
	},
});
