import React, { memo } from 'react';
import { styled } from '@compiled/react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import AddPeople from './add-people/index.tsx';
import type { AddPeopleWithAvatarsProps } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyAddPeopleWithAvatars = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-business-add-people-with-avatars" */ './main'),
	{ ssr: false },
);

const AddPeopleWithAvatar = (props: AddPeopleWithAvatarsProps) => (
	<ErrorBoundary
		id="async.jira-business.invite-people.add-people-with-avatars"
		packageName="jiraBusinessInvitePeople"
		teamName="jira-warepil"
	>
		<Placeholder name="invite-button-with-avatars" fallback={<AvatarFallback />}>
			{props?.hasInviteButton === false ? (
				// not including the Add People provider if the invite button is not needed
				<LazyAddPeopleWithAvatars {...props} />
			) : (
				<AddPeople>
					<LazyAddPeopleWithAvatars {...props} />
				</AddPeople>
			)}
		</Placeholder>
	</ErrorBoundary>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarFallback = styled.div({
	height: '40px',
});

export default memo<AddPeopleWithAvatarsProps>(AddPeopleWithAvatar);
