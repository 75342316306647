/**
 * @generated SignedSource<<4ccf3536de62b81cc4eb3e2807eb8cff>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type defaultFieldValueLabel_softwareFiltersPopup$data = {
  readonly displayName: string;
  readonly " $fragmentType": "defaultFieldValueLabel_softwareFiltersPopup";
};
export type defaultFieldValueLabel_softwareFiltersPopup$key = {
  readonly " $data"?: defaultFieldValueLabel_softwareFiltersPopup$data;
  readonly " $fragmentSpreads": FragmentRefs<"defaultFieldValueLabel_softwareFiltersPopup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "defaultFieldValueLabel_softwareFiltersPopup",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "displayName"
    }
  ],
  "type": "JiraJqlFieldValue",
  "abstractKey": "__isJiraJqlFieldValue"
};

(node as any).hash = "76d9cbed320c5044941274edab3e856e";

export default node;
