import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { DropdownItemCheckbox } from '@atlaskit/dropdown-menu';
import { Inline } from '@atlaskit/primitives';
import type { fieldValueCheckbox_softwareFiltersPopup$key } from '@atlassian/jira-relay/src/__generated__/fieldValueCheckbox_softwareFiltersPopup.graphql';
import type { JQLModel } from '../../utils/jql-model/index.tsx';
import { FieldValueLabel } from '../field-value-label/index.tsx';

export interface FieldValueCheckboxProps {
	/**
	 * The field value fragment to render
	 */
	fieldValueRef: fieldValueCheckbox_softwareFiltersPopup$key;
	/**
	 * The current filter state
	 */
	jqlModel: JQLModel;
	/**
	 * The field name
	 */
	fieldName: string;
	/**
	 * Will be called when the value is toggled
	 */
	onToggleFilter: (fieldName: string, value: string) => void;
}

/**
 * This is a checkbox for an issue field value. It can render all issue
 * field values with a fallback label renderer.
 */
export function FieldValueCheckbox({
	fieldValueRef,
	jqlModel,
	fieldName,
	onToggleFilter,
}: FieldValueCheckboxProps) {
	const data = useFragment(
		graphql`
			fragment fieldValueCheckbox_softwareFiltersPopup on JiraJqlFieldValue {
				jqlTerm

				...fieldValueLabel_softwareFiltersPopup
			}
		`,
		fieldValueRef,
	);
	const isSelected = data.jqlTerm
		? jqlModel.whereClauses[fieldName]?.includes(data.jqlTerm)
		: false;
	const onClick = () => {
		onToggleFilter(fieldName, data.jqlTerm);
	};

	return (
		<Inline space="space.100" alignBlock="center" grow="fill" role="menu">
			<DropdownItemCheckbox
				id={data.jqlTerm}
				isSelected={isSelected}
				onClick={onClick}
				testId="software-filters-popup.common.ui.field-value-checkbox.dropdown-item-checkbox"
			>
				<FieldValueLabel fieldValueRef={data} mode="dropdown" />
			</DropdownItemCheckbox>
		</Inline>
	);
}
