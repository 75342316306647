import { useMemo } from 'react';
import type { JiraViewScopeInput } from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useCalendarScope } from '../calendar-store/index.tsx';
import type { CalendarScope } from '../calendar-store/types.tsx';

function buildScopeInput({
	scope,
	cloudId,
}: {
	scope: CalendarScope;
	cloudId: string;
}): JiraViewScopeInput {
	if (scope.type === 'board') {
		return {
			ids: [scope.boardId],
			projectKeys: {
				cloudId,
				keys: scope.projectKeys,
			},
		};
	}

	if (scope.type === 'plan') {
		return {
			ids: scope.ids,
		};
	}

	return {
		projectKeys: {
			cloudId,
			keys: scope.projectKeys,
		},
	};
}

export function useCalendarScopeInput(): {
	scopeInput: JiraViewScopeInput;
} {
	const scope = useCalendarScope();
	const cloudId = useCloudId();

	const scopeInput = useMemo(() => buildScopeInput({ scope, cloudId }), [scope, cloudId]);

	return {
		scopeInput,
	};
}
