/**
 * @generated SignedSource<<3b8dc2120aaee723b873f7304fd90b90>>
 * @relayHash 950a7192ba759b8e3776a4f9812b4f0d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6ef725b8ee1dcfee7980244a7a35b2e30d06e32d4fb9304019707179ec148a0f

import type { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraCalendarMode = "DAY" | "MONTH" | "WEEK" | "%future added value";
export type JiraCalendarWeekStart = "MONDAY" | "SATURDAY" | "SUNDAY" | "%future added value";
export type JiraViewScopeInput = {
  ids?: ReadonlyArray<string> | null | undefined;
  projectKeys?: JiraProjectKeysInput | null | undefined;
};
export type JiraProjectKeysInput = {
  cloudId: string;
  keys?: ReadonlyArray<string> | null | undefined;
};
export type JiraCalendarViewConfigurationInput = {
  date?: AGG$DateTime | null | undefined;
  endDateField?: string | null | undefined;
  mode?: JiraCalendarMode | null | undefined;
  startDateField?: string | null | undefined;
  viewId?: string | null | undefined;
  weekStart?: JiraCalendarWeekStart | null | undefined;
};
export type JiraCalendarIssuesInput = {
  additionalFilterQuery?: string | null | undefined;
};
export type onCalendarIssueCreatedSubscription$variables = {
  cloudId: string;
  configuration?: JiraCalendarViewConfigurationInput | null | undefined;
  issuesSearchInput?: JiraCalendarIssuesInput | null | undefined;
  projectIds: ReadonlyArray<string>;
  scope?: JiraViewScopeInput | null | undefined;
  unscheduledIssuesSearchInput?: JiraCalendarIssuesInput | null | undefined;
  viewId?: string | null | undefined;
};
export type onCalendarIssueCreatedSubscription$data = {
  readonly jira: {
    readonly onCalendarIssueCreated: {
      readonly errors: ReadonlyArray<{
        readonly extensions: ReadonlyArray<{
          readonly statusCode: number | null | undefined;
        }> | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly isInScope: boolean | null | undefined;
      readonly isUnscheduled: boolean | null | undefined;
      readonly scenarioIssueLike: {
        readonly __typename: string;
        readonly __id: string;
        readonly " $fragmentSpreads": FragmentRefs<"issueRenderer_calendar_IssueEventRenderer">;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type onCalendarIssueCreatedSubscription = {
  response: onCalendarIssueCreatedSubscription$data;
  variables: onCalendarIssueCreatedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "configuration"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issuesSearchInput"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectIds"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scope"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "unscheduledIssuesSearchInput"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v7 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "configuration",
    "variableName": "configuration"
  },
  {
    "kind": "Variable",
    "name": "issuesInput",
    "variableName": "issuesSearchInput"
  },
  {
    "kind": "Variable",
    "name": "projectIds",
    "variableName": "projectIds"
  },
  {
    "kind": "Variable",
    "name": "scope",
    "variableName": "scope"
  },
  {
    "kind": "Variable",
    "name": "unscheduledIssuesInput",
    "variableName": "unscheduledIssuesSearchInput"
  }
],
v8 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v9 = {
  "kind": "Variable",
  "name": "viewId",
  "variableName": "viewId"
},
v10 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v12 = {
  "kind": "ScalarField",
  "name": "message"
},
v13 = {
  "kind": "ScalarField",
  "name": "isUnscheduled"
},
v14 = {
  "kind": "ScalarField",
  "name": "isInScope"
},
v15 = {
  "kind": "ScalarField",
  "name": "id"
},
v16 = {
  "kind": "ScalarField",
  "name": "key"
},
v17 = {
  "kind": "ScalarField",
  "name": "name"
},
v18 = [
  (v17/*: any*/),
  {
    "concreteType": "JiraAvatar",
    "kind": "LinkedField",
    "name": "avatar",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "small"
      }
    ]
  },
  (v15/*: any*/)
],
v19 = {
  "concreteType": "JiraIssueTypeField",
  "kind": "LinkedField",
  "name": "issueTypeField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": (v18/*: any*/)
    },
    (v15/*: any*/)
  ]
},
v20 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "text"
    },
    (v15/*: any*/)
  ]
},
v21 = [
  (v9/*: any*/)
],
v22 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "date"
    }
  ],
  "type": "JiraDatePickerField"
},
v23 = [
  (v8/*: any*/),
  (v15/*: any*/),
  (v22/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "kind": "ScalarField",
        "name": "dateTime"
      }
    ],
    "type": "JiraDateTimePickerField"
  },
  (v10/*: any*/)
],
v24 = {
  "concreteType": "JiraStatusField",
  "kind": "LinkedField",
  "name": "statusField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraStatusCategory",
          "kind": "LinkedField",
          "name": "statusCategory",
          "plural": false,
          "selections": [
            (v16/*: any*/),
            (v15/*: any*/)
          ]
        },
        (v15/*: any*/),
        (v17/*: any*/)
      ]
    },
    (v15/*: any*/)
  ]
},
v25 = [
  (v8/*: any*/),
  (v15/*: any*/),
  (v22/*: any*/),
  (v10/*: any*/)
],
v26 = {
  "kind": "LinkedField",
  "name": "startDateViewField",
  "plural": false,
  "selections": (v25/*: any*/)
},
v27 = {
  "kind": "LinkedField",
  "name": "endDateViewField",
  "plural": false,
  "selections": (v25/*: any*/)
},
v28 = {
  "concreteType": "JiraSingleSelectUserPickerField",
  "kind": "LinkedField",
  "name": "assigneeField",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v8/*: any*/),
        (v17/*: any*/),
        {
          "kind": "ScalarField",
          "name": "picture"
        },
        (v15/*: any*/)
      ]
    },
    (v15/*: any*/)
  ]
},
v29 = {
  "concreteType": "JiraRichTextField",
  "kind": "LinkedField",
  "name": "descriptionField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraRichText",
      "kind": "LinkedField",
      "name": "richText",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraADF",
          "kind": "LinkedField",
          "name": "adfValue",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraAdfToConvertedPlainText",
              "kind": "LinkedField",
              "name": "convertedPlainText",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "plainText"
                }
              ]
            }
          ]
        }
      ]
    },
    (v15/*: any*/)
  ]
},
v30 = {
  "concreteType": "JiraProjectField",
  "kind": "LinkedField",
  "name": "projectField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": (v18/*: any*/)
    },
    (v15/*: any*/)
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "name": "onCalendarIssueCreatedSubscription",
    "selections": [
      {
        "concreteType": "JiraSubscription",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v7/*: any*/),
            "concreteType": "JiraIssueWithScenario",
            "kind": "LinkedField",
            "name": "onCalendarIssueCreated",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "name": "scenarioIssueLike",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "schedulePermissionsEnabled",
                            "value": false
                          },
                          (v9/*: any*/)
                        ],
                        "kind": "FragmentSpread",
                        "name": "issueRenderer_calendar_IssueEventRenderer"
                      }
                    ],
                    "type": "JiraIssue"
                  },
                  (v10/*: any*/)
                ]
              },
              {
                "concreteType": "QueryError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": true,
                    "selections": [
                      (v11/*: any*/)
                    ]
                  },
                  (v12/*: any*/)
                ]
              },
              (v13/*: any*/),
              (v14/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Subscription"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "onCalendarIssueCreatedSubscription",
    "selections": [
      {
        "concreteType": "JiraSubscription",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v7/*: any*/),
            "concreteType": "JiraIssueWithScenario",
            "kind": "LinkedField",
            "name": "onCalendarIssueCreated",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "name": "scenarioIssueLike",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v15/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v16/*: any*/),
                              (v19/*: any*/),
                              {
                                "alias": "requestTypeField",
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "idOrAlias",
                                    "value": "requesttype"
                                  },
                                  {
                                    "kind": "Literal",
                                    "name": "ignoreMissingField",
                                    "value": true
                                  }
                                ],
                                "kind": "LinkedField",
                                "name": "fieldByIdOrAlias",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/),
                                  (v15/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "concreteType": "JiraServiceManagementRequestType",
                                        "kind": "LinkedField",
                                        "name": "requestType",
                                        "plural": false,
                                        "selections": (v18/*: any*/)
                                      }
                                    ],
                                    "type": "JiraServiceManagementRequestTypeField"
                                  }
                                ],
                                "storageKey": "fieldByIdOrAlias(idOrAlias:\"requesttype\",ignoreMissingField:true)"
                              },
                              (v20/*: any*/),
                              {
                                "args": (v21/*: any*/),
                                "kind": "LinkedField",
                                "name": "startDateViewField",
                                "plural": false,
                                "selections": (v23/*: any*/)
                              },
                              {
                                "args": (v21/*: any*/),
                                "kind": "LinkedField",
                                "name": "endDateViewField",
                                "plural": false,
                                "selections": (v23/*: any*/)
                              },
                              (v24/*: any*/),
                              {
                                "args": (v21/*: any*/),
                                "concreteType": "JiraScenarioIssueValues",
                                "kind": "LinkedField",
                                "name": "planScenarioValues",
                                "plural": false,
                                "selections": [
                                  (v20/*: any*/),
                                  (v19/*: any*/),
                                  (v26/*: any*/),
                                  (v27/*: any*/),
                                  (v24/*: any*/),
                                  (v28/*: any*/),
                                  (v29/*: any*/),
                                  (v30/*: any*/)
                                ]
                              },
                              (v28/*: any*/),
                              (v29/*: any*/),
                              (v30/*: any*/)
                            ],
                            "type": "JiraIssue"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "args": (v21/*: any*/),
                                "concreteType": "JiraScenarioIssueValues",
                                "kind": "LinkedField",
                                "name": "planScenarioValues",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "scenarioKey"
                                  },
                                  (v19/*: any*/),
                                  (v20/*: any*/),
                                  (v26/*: any*/),
                                  (v27/*: any*/),
                                  (v24/*: any*/),
                                  (v28/*: any*/),
                                  (v29/*: any*/),
                                  (v30/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraScenarioIssue"
                          },
                          (v10/*: any*/)
                        ],
                        "type": "JiraScenarioIssueLike",
                        "abstractKey": "__isJiraScenarioIssueLike"
                      }
                    ],
                    "type": "JiraIssue"
                  },
                  (v10/*: any*/)
                ]
              },
              {
                "concreteType": "QueryError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      (v11/*: any*/)
                    ]
                  },
                  (v12/*: any*/)
                ]
              },
              (v13/*: any*/),
              (v14/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "6ef725b8ee1dcfee7980244a7a35b2e30d06e32d4fb9304019707179ec148a0f",
    "metadata": {},
    "name": "onCalendarIssueCreatedSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "dd1630885e51a70881d396e2b029248b";

export default node;
