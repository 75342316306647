import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import parameters from '@atlassian/jira-relay/src/__generated__/ui_releaseFlyoutQuery.graphql';

type EntryPointParams = {
	id: string;
	skipVersionsV1Search: boolean;
	skipVersionsV2Search: boolean;
	viewId: string | null;
};

const ReleaseFlyoutEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-release-flyout" */ './ui'),
	),
	getPreloadProps: ({
		id,
		skipVersionsV1Search,
		skipVersionsV2Search,
		viewId,
	}: EntryPointParams) => ({
		queries: {
			releaseFlyout: {
				options: {
					fetchPolicy: 'store-and-network' as const,
				},
				parameters,
				variables: {
					id,
					skipVersionsV1Search,
					skipVersionsV2Search,
					viewId,
				},
			},
		},
	}),
});

export default ReleaseFlyoutEntryPoint;
