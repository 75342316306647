import { useMemo } from 'react';
import { graphql, useSubscription } from 'react-relay';
import type { onCalendarIssueDeletedSubscription } from '@atlassian/jira-relay/src/__generated__/onCalendarIssueDeletedSubscription.graphql.ts';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import RelayDataID from '@atlassian/relay-data-id';
import { isKnownWebSocketError } from '../../../common/utils/is-websocket-error/index.tsx';
import { useCalendarExperience } from '../../../controllers/use-calendar-experience/index.tsx';
import { useLogger } from '../../../controllers/use-logger/index.tsx';
import type { SubscriptionProps } from '../types.tsx';

export const ERROR_LOCATION = 'calendar.delete-calendar-issue.error';
export const ERROR_MESSAGE_FAILED_TO_DELETE = 'Failed to delete issue';
export const INVALID_ISSUE_DELETED = 'Invalid issue deleted';

export const useCalendarIssueDeleted = ({ projectId }: Pick<SubscriptionProps, 'projectId'>) => {
	const cloudId = useCloudId();
	const { deleteCalendarIssueExperience } = useCalendarExperience();
	const { logError } = useLogger();

	useSubscription<onCalendarIssueDeletedSubscription>(
		useMemo(
			() => ({
				subscription: graphql`
					subscription onCalendarIssueDeletedSubscription($cloudId: ID!, $projectIds: [String!]!) {
						jira {
							onCalendarIssueDeleted(projectIds: $projectIds, cloudId: $cloudId) {
								resource
							}
						}
					}
				`,
				variables: {
					cloudId,
					projectIds: [projectId],
				},
				onError: (error) => {
					if (isKnownWebSocketError(error)) {
						return;
					}
					logError(ERROR_LOCATION, ERROR_MESSAGE_FAILED_TO_DELETE, error);
				},
				updater: (store, data) => {
					deleteCalendarIssueExperience.start();

					const resourceId = data?.jira?.onCalendarIssueDeleted?.resource;
					if (!resourceId) {
						deleteCalendarIssueExperience.failure();
						logError(ERROR_LOCATION, INVALID_ISSUE_DELETED);
						return;
					}

					const nodeId = RelayDataID({ id: resourceId }, 'JiraIssue') || '';
					store.delete(nodeId);

					deleteCalendarIssueExperience.success();
				},
			}),
			[cloudId, deleteCalendarIssueExperience, logError, projectId],
		),
	);
};
