import format from 'date-fns/format';
import { ConnectionHandler, graphql, useMutation } from 'react-relay';
import type { RecordSourceSelectorProxy } from 'relay-runtime';
import { AnyAri } from '@atlassian/ari/any-ari';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPE_MAP } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/constants.tsx';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { useScheduleCalendarIssueMutation } from '@atlassian/jira-relay/src/__generated__/useScheduleCalendarIssueMutation.graphql';
import { useCalendarCapabilities } from '../../common/controllers/capabilities-provider/index.tsx';
import { isErrorAllowed } from '../../common/utils/agg-errors/index.tsx';
import { isRelayErrorAllowed } from '../../common/utils/relay-errors/index.tsx';
import { fireIssueUpdatedAnalytics } from '../../common/utils/analytics/index.tsx';
import { toMidnightUTCString, toUTCString } from '../../common/utils/dates/index.tsx';
import { combineErrorMessages } from '../../common/utils/graphql/index.tsx';
import {
	useCalendarActions,
	useCalendarCallbacks,
	useIsDateTimeConfigured,
	useShowIssues,
} from '../calendar-store/index.tsx';
import { useCalendarConfigurationInput } from '../use-calendar-configuration-input/index.tsx';
import { useCalendarExperience } from '../use-calendar-experience/index.tsx';
import { useCalendarScopeInput } from '../use-calendar-scope-input/index.tsx';
import { useCalendarViewId } from '../use-calendar-view-id/index.tsx';
import { useLogger } from '../use-logger/index.tsx';
import { messages } from './messages.tsx';

interface ScheduleCalendarIssueOptimisticUpdaterParams {
	/**
	 * The relay store proxy
	 */
	store: RecordSourceSelectorProxy;
	/**
	 * The relay store record ID for the JiraIssueConnection object of the list of events.
	 * The optimistic update will PUSH the issue record onto this connection if it's not there.
	 */
	connectionId: string | undefined;
	/**
	 * The relay store record ID for the JiraIssue object that is being updated.
	 */
	issueRecordId: string;

	startDateFieldRecordId: string | undefined;

	endDateFieldRecordId: string | undefined;
	/**
	 * The current relay store record ID for a JiraIssueConnection object to remove the card from.
	 */
	removeCardFromConnectionId?: string;
	startDateFormatted: string | null;
	endDateFormatted: string | null;
	weekViewEnabled?: boolean;
}

/**
 * This optimistic updater handles the case where an issue is dragged into the calendar from an external list
 * like the calendar unscheduled panel.
 *
 * On that case, we use the `removeCardFromConnectionId` and `connectionId` record IDs to move the card from
 * its previous list and onto the calendar list.
 */
export function scheduleCalendarIssueOptimisticUpdater({
	store,
	connectionId,
	issueRecordId,
	startDateFieldRecordId,
	endDateFieldRecordId,
	removeCardFromConnectionId,
	startDateFormatted,
	endDateFormatted,
	weekViewEnabled = false,
}: ScheduleCalendarIssueOptimisticUpdaterParams) {
	const issueRecord = store.get(issueRecordId);
	if (startDateFieldRecordId) {
		const startDateFieldRecord = store.get(startDateFieldRecordId);
		if (startDateFieldRecord) {
			startDateFieldRecord.setValue(startDateFormatted, 'date');
		}

		// if week view is enabled, we also need to update the dateTime field
		if (weekViewEnabled && startDateFieldRecord?.getValue('dateTime')) {
			startDateFieldRecord.setValue(startDateFormatted, 'dateTime');
		}
	}

	if (endDateFieldRecordId) {
		const endDateFieldRecord = store.get(endDateFieldRecordId);
		if (endDateFieldRecord) {
			endDateFieldRecord.setValue(endDateFormatted, 'date');
		}

		// if week view is enabled, we also need to update the dateTime field
		if (weekViewEnabled && endDateFieldRecord?.getValue('dateTime')) {
			endDateFieldRecord.setValue(endDateFormatted, 'dateTime');
		}
	}

	if (connectionId != null) {
		const connection = store.get(connectionId);
		const edges = connection?.getLinkedRecords('edges');
		if (edges?.find((record) => issueRecord?.getDataID() === record.getDataID())) {
			return;
		}

		if (issueRecord && connection) {
			const newEdge = ConnectionHandler.createEdge(store, connection, issueRecord, 'JiraIssueEdge');
			edges?.push(newEdge);
			connection.setLinkedRecords(edges, 'edges');
		}
	}

	if (removeCardFromConnectionId != null) {
		const oldConnection = store.get(removeCardFromConnectionId);
		if (!oldConnection) return;
		const oldConnectionEdges = oldConnection.getLinkedRecords('edges');
		const oldConnectionEdgesWithoutTarget = oldConnectionEdges?.filter(
			(edge) => edge.getLinkedRecord('node')?.getDataID() !== issueRecordId,
		);
		oldConnection.setLinkedRecords(oldConnectionEdgesWithoutTarget, 'edges');
	}
}

export interface ScheduleCalendarIssueMutationParams {
	issueAri: string;
	/**
	 * The relay record ID is used for optimistic updates.
	 */
	issueRecordId: string;

	startDateFieldRecordId: string | undefined;
	endDateFieldRecordId: string | undefined;

	startDate: Date | null | undefined;
	endDate: Date | null | undefined;
	/**
	 * If provided will remove the card from this connection after it is dropped.
	 *
	 * Provide if you want to remove the card from a list while adding it into the
	 * calendar list. For example, if you want to remove the card from the unscheduled
	 * panel.
	 */
	removeCardFromConnectionId?: string;
	/**
	 * The issue key of the issue
	 */
	issueKey: string;
	/**
	 * Can schedule issue permission
	 */
	canSchedule?: boolean | null;
	/**
	 * Revert function to revert full calendar update
	 */
	revert?: () => void;
	/**
	 * List of field types
	 */
	analyticsAttributes?: string[];
}

/**
 * This function schedules a calendar issue by updating the start and end dates.
 * It takes in a params object of type ScheduleCalendarIssueMutationParams
 * and returns a Promise that resolves to void.
 * Note: It returns a promise purely for testing purposes.
 */
export type ScheduleCalendarIssueMutation = (params: ScheduleCalendarIssueMutationParams) => void;

export type Props = {
	/**
	 * This is the Relay internal cache ID for the issueSearchStable JiraIssueConnection object.
	 * This is required to write a proper optimistic updater & updater
	 */
	connectionId: string | undefined;
};

/**
 * Custom hook for scheduling a calendar issue via graphql mutation.
 *
 * @param {Object} options - The options for the mutation.
 * @param {string | undefined} options.connectionId - The Relay internal connection cache ID for the issues JiraIssueConnection object.
 * @returns {Object} - An object containing the scheduleCalendarIssue function.
 * @returns {Function} scheduleCalendarIssue - A function for scheduling a calendar issue mutation.
 */
export function useScheduleCalendarIssueMutation({ connectionId }: Props): {
	scheduleCalendarIssue: ScheduleCalendarIssueMutation;
} {
	const { scopeInput } = useCalendarScopeInput();
	const { configurationInput } = useCalendarConfigurationInput();
	const { onScheduled } = useCalendarCallbacks();
	const { scheduleIssueExperience } = useCalendarExperience();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { viewId } = useCalendarViewId();
	const { weekViewEnabled } = useCalendarCapabilities();
	const isDateTimeConfigured = useIsDateTimeConfigured();

	const showIssues = useShowIssues();

	const [commitRangeUpdate] = useMutation<useScheduleCalendarIssueMutation>(graphql`
		mutation useScheduleCalendarIssueMutation(
			$scope: JiraViewScopeInput!
			$configuration: JiraCalendarViewConfigurationInput!
			$issueId: ID!
			$startDateInput: DateTime
			$endDateInput: DateTime
			$viewId: ID
			$schedulePermissionsEnabled: Boolean!
		) {
			jira {
				scheduleCalendarIssueV2(
					scope: $scope
					configuration: $configuration
					issueId: $issueId
					startDateInput: $startDateInput
					endDateInput: $endDateInput
				) @optIn(to: ["JiraCalendar", "JiraPlansSupport"]) {
					success
					errors {
						message
						extensions {
							statusCode
							errorType
						}
					}
					issue {
						id
						...issueRenderer_calendar_IssueEventRenderer
							@arguments(viewId: $viewId, schedulePermissionsEnabled: $schedulePermissionsEnabled)
					}
				}
			}
		}
	`);

	const { setIsLoading } = useCalendarActions();

	const { showFlag } = useFlagService();
	const { formatMessage } = useIntl();
	const { logError } = useLogger();

	const scheduleCalendarIssue = ({
		issueKey,
		issueAri,
		issueRecordId,
		startDateFieldRecordId,
		endDateFieldRecordId,
		startDate,
		endDate,
		removeCardFromConnectionId,
		canSchedule = true,
		revert,
		analyticsAttributes,
	}: ScheduleCalendarIssueMutationParams) => {
		scheduleIssueExperience.start();

		if (!canSchedule) {
			showFlag({
				id: 'schedule-calendar-issue-permission-error',
				title: formatMessage(messages.scheduleIssuePermissionErrorTitle, { issueKey }),
				description: formatMessage(messages.scheduleIssuePermissionErrorMessage),
				type: 'error',
			});

			revert?.();
			scheduleIssueExperience.abort();
			return;
		}

		const dateFormat =
			weekViewEnabled && isDateTimeConfigured ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd';
		const utcCallBack = weekViewEnabled && isDateTimeConfigured ? toUTCString : toMidnightUTCString;

		const startUtcDateFormatted = startDate != null ? utcCallBack(startDate) : null;
		const endUtcDateFormatted = endDate != null ? utcCallBack(endDate) : null;

		const startDateFormatted = startDate != null ? format(startDate, dateFormat) : null;
		const endDateFormatted = endDate != null ? format(endDate, dateFormat) : null;

		setIsLoading(true);

		commitRangeUpdate({
			variables: {
				scope: scopeInput,
				configuration: configurationInput,
				issueId: issueAri,
				startDateInput: startUtcDateFormatted,
				endDateInput: endUtcDateFormatted,
				viewId,
				schedulePermissionsEnabled: fg('calendar_schedule_issue_permissions_check'),
			},
			updater: (store, response) => {
				const scheduleIssueResponse = response?.jira?.scheduleCalendarIssueV2;

				if (scheduleIssueResponse?.success === false) {
					return;
				}

				scheduleCalendarIssueOptimisticUpdater({
					store,
					issueRecordId,
					startDateFieldRecordId,
					endDateFieldRecordId,
					removeCardFromConnectionId,
					connectionId,
					startDateFormatted,
					endDateFormatted,
					weekViewEnabled,
				});
			},
			optimisticUpdater: (store, response) => {
				if (!showIssues) {
					showFlag({
						id: 'schedule-calendar-issue-hidden',
						title: fg('jira-issue-terminology-refresh-m3')
							? messages.issueHiddenTitleIssueTermRefresh
							: messages.issueHiddenTitle,
						description: messages.issueHiddenBody,
						type: 'warning',
					});
				}
				const scheduleIssueResponse = response?.jira?.scheduleCalendarIssueV2;

				if (scheduleIssueResponse?.success === false) {
					return;
				}

				scheduleCalendarIssueOptimisticUpdater({
					store,
					issueRecordId,
					startDateFieldRecordId,
					endDateFieldRecordId,
					removeCardFromConnectionId,
					connectionId,
					startDateFormatted,
					endDateFormatted,
					weekViewEnabled,
				});
			},
			onError: (error: Error) => {
				setIsLoading(false);

				if (fg('calendar-schedule-issue-handle-rate-limiting-error')) {
					isRelayErrorAllowed(error)
						? scheduleIssueExperience.abort()
						: scheduleIssueExperience.failure();
				} else {
					scheduleIssueExperience.failure();
				}

				logError(
					'calendar.schedule-calendar-issue-mutation.error',
					'Failed to schedule calendar issue event',
					error,
				);

				showFlag({
					id: 'schedule-calendar-issue-error',
					title: formatMessage(messages.errorTitle),
					description: formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.failedToScheduleIssueIssueTermRefresh
							: messages.failedToScheduleIssue,
					),
					type: 'error',
					testId: 'jira-calendar.schedule-calendar-issue-error-flag',
				});
			},
			onCompleted(response) {
				setIsLoading(false);

				const scheduleIssueResponse = response?.jira?.scheduleCalendarIssueV2;

				if (scheduleIssueResponse?.success === false) {
					const errorResponse = scheduleIssueResponse?.errors;
					if (fg('calendar_handles_graphql_error')) {
						if (isErrorAllowed(errorResponse)) {
							scheduleIssueExperience.abort();
						} else {
							scheduleIssueExperience.failure();
						}
					} else {
						scheduleIssueExperience.abort();
					}

					const errorMessage =
						combineErrorMessages(scheduleIssueResponse?.errors) ??
						formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.failedToScheduleIssueIssueTermRefresh
								: messages.failedToScheduleIssue,
						);

					logError(
						'calendar.schedule-calendar-issue-mutation.failure',
						`Failed to schedule calendar issue event: ${errorMessage}`,
					);

					showFlag({
						id: 'schedule-calendar-issue-failure',
						title: formatMessage(messages.errorTitle),
						description: errorMessage,
						type: 'error',
						testId: 'jira-calendar.schedule-calendar-issue-failure-flag',
					});

					return;
				}

				scheduleIssueExperience.success();

				if (
					analyticsAttributes &&
					analyticsAttributes.length > 0 &&
					fg('one_event_rules_them_all_fg')
				) {
					analyticsAttributes.forEach((fieldKey: string) =>
						getUpdateAnalyticsFlowHelper().fireAnalyticsEnd(fieldKey, {
							analytics: createAnalyticsEvent({}),
							attributes: {
								fieldType: FIELD_TYPE_MAP[fieldKey],
								isDragEditing: true,
							},
						}),
					);
				}

				fireIssueUpdatedAnalytics(createAnalyticsEvent({}), AnyAri.parse(issueAri)?.resourceId);

				onScheduled?.({
					id: AnyAri.parse(issueAri)?.resourceId,
					key: issueKey,
					startDate: startUtcDateFormatted ? new Date(startUtcDateFormatted) : undefined,
					endDate: endUtcDateFormatted ? new Date(endUtcDateFormatted) : undefined,
					type: 'issue',
				});
			},
		});
	};

	return { scheduleCalendarIssue };
}
