import { useMemo } from 'react';
import { ConnectionHandler, graphql, useSubscription } from 'react-relay';
import type { onCalendarIssueCreatedSubscription } from '@atlassian/jira-relay/src/__generated__/onCalendarIssueCreatedSubscription.graphql.ts';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { isKnownWebSocketError } from '../../../common/utils/is-websocket-error/index.tsx';
import {
	useCalendarRendererConnectionId,
	useUnscheduledPanelConnectionId,
} from '../../../controllers/connection-store/index.tsx';
import { useCalendarExperience } from '../../../controllers/use-calendar-experience/index.tsx';
import { useIssuesSearchInput } from '../../../controllers/use-issues-search-input/index.tsx';
import { useLogger } from '../../../controllers/use-logger/index.tsx';
import { useUnscheduledIssuesSearchInput } from '../../../controllers/use-unscheduled-issues-search-input/index.tsx';
import type { SubscriptionProps } from '../types.tsx';
import { isClientSideResponse, isServerSideResponse } from '../utils.tsx';

export const ERROR_LOCATION = 'calendar.calendar-subscription-create-issue.error';
export const ERROR_MESSAGE_FAILED_TO_CREATE = 'Failed to create issue';
export const INVALID_ISSUE_CREATED = 'Invalid issue created';

export const useCalendarIssueCreated = ({
	projectId,
	scope,
	configuration,
	viewId,
}: SubscriptionProps) => {
	const cloudId = useCloudId();
	const { createCalendarIssueSubscriptionExperience } = useCalendarExperience();
	const { logError, logWarn } = useLogger();
	const { issuesSearchInput } = useIssuesSearchInput();
	const { unscheduledIssuesSearchInput } = useUnscheduledIssuesSearchInput();

	const calendarRendererConnectionId = useCalendarRendererConnectionId();
	const unscheduledPanelConnectionId = useUnscheduledPanelConnectionId();

	useSubscription<onCalendarIssueCreatedSubscription>(
		useMemo(
			() => ({
				subscription: graphql`
					subscription onCalendarIssueCreatedSubscription(
						$scope: JiraViewScopeInput
						$configuration: JiraCalendarViewConfigurationInput
						$cloudId: ID!
						$projectIds: [String!]!
						$viewId: ID
						$issuesSearchInput: JiraCalendarIssuesInput
						$unscheduledIssuesSearchInput: JiraCalendarIssuesInput
					) {
						jira {
							onCalendarIssueCreated(
								scope: $scope
								configuration: $configuration
								projectIds: $projectIds
								cloudId: $cloudId
								issuesInput: $issuesSearchInput
								unscheduledIssuesInput: $unscheduledIssuesSearchInput
							) {
								scenarioIssueLike {
									__id
									__typename
									... on JiraIssue {
										...issueRenderer_calendar_IssueEventRenderer
											@arguments(viewId: $viewId, schedulePermissionsEnabled: false)
									}
								}
								errors {
									extensions {
										statusCode
									}
									message
								}
								isUnscheduled
								isInScope
							}
						}
					}
				`,
				variables: {
					cloudId,
					scope,
					configuration,
					projectIds: [projectId],
					viewId,
					issuesSearchInput,
					unscheduledIssuesSearchInput,
				},
				onError: (error) => {
					if (isKnownWebSocketError(error)) {
						return;
					}
					logError(ERROR_LOCATION, ERROR_MESSAGE_FAILED_TO_CREATE, error);
				},
				updater: (store, data) => {
					createCalendarIssueSubscriptionExperience.start();

					try {
						if (
							data?.jira?.onCalendarIssueCreated?.errors?.length &&
							data.jira.onCalendarIssueCreated.errors.length > 0
						) {
							data.jira.onCalendarIssueCreated.errors.forEach((e) => {
								if (e.extensions?.some((ex) => isClientSideResponse(ex?.statusCode))) {
									logWarn(ERROR_LOCATION, e.message ?? 'Unknown error');
								} else if (e.extensions?.some((ex) => isServerSideResponse(ex?.statusCode))) {
									logError(ERROR_LOCATION, e.message ?? 'Unknown error');
								}
							});

							if (
								data.jira.onCalendarIssueCreated.errors.some((e) =>
									e.extensions?.some((ex) => isServerSideResponse(ex.statusCode)),
								)
							) {
								// Fail when a server side error is thrown.
								createCalendarIssueSubscriptionExperience.failure();
							} else {
								// Silently handle when created issue is out of calendar scope (i.e. 4xx error).
								createCalendarIssueSubscriptionExperience.abort();
							}
							return;
						}

						const issue = data?.jira?.onCalendarIssueCreated?.scenarioIssueLike;
						if (!issue) {
							throw Error(INVALID_ISSUE_CREATED);
						} else if (issue.__typename === 'JiraScenarioIssue') {
							// Silently handle when created issue is a JiraScenarioIssue.
							createCalendarIssueSubscriptionExperience.abort();
							return;
						}
						const record = store.get(issue.__id);
						if (!record) {
							throw Error('Issue missing from store.');
						}

						if (data?.jira?.onCalendarIssueCreated?.isUnscheduled && unscheduledPanelConnectionId) {
							const unscheduledPanelRecord = store.get(unscheduledPanelConnectionId);
							if (!unscheduledPanelRecord) {
								throw Error('Unable to get unscheduled panel record from store.');
							}
							const unscheduledPanelEdge = ConnectionHandler.createEdge(
								store,
								unscheduledPanelRecord,
								record,
								'JiraIssue',
							);
							ConnectionHandler.insertEdgeBefore(unscheduledPanelRecord, unscheduledPanelEdge);
						} else if (data?.jira?.onCalendarIssueCreated?.isInScope) {
							if (!calendarRendererConnectionId) {
								throw Error('Calendar renderer connection id is unknown.');
							}
							const calendarRendererRecord = store.get(calendarRendererConnectionId);
							if (!calendarRendererRecord) {
								throw Error('Unable to get calendar renderer record from store.');
							}
							const calendarRendererEdge = ConnectionHandler.createEdge(
								store,
								calendarRendererRecord,
								record,
								'JiraIssue',
							);
							ConnectionHandler.insertEdgeBefore(calendarRendererRecord, calendarRendererEdge);
						}

						createCalendarIssueSubscriptionExperience.success();
					} catch (error) {
						if (error instanceof Error && !isKnownWebSocketError(error)) {
							createCalendarIssueSubscriptionExperience.failure();
							logError(ERROR_LOCATION, error.message);
						}
					}
				},
			}),
			[
				calendarRendererConnectionId,
				cloudId,
				configuration,
				createCalendarIssueSubscriptionExperience,
				issuesSearchInput,
				logError,
				logWarn,
				projectId,
				scope,
				unscheduledIssuesSearchInput,
				unscheduledPanelConnectionId,
				viewId,
			],
		),
	);
};
