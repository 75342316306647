import format from 'date-fns/format';
import { ConnectionHandler, graphql, useMutation } from 'react-relay';
import type { RecordSourceSelectorProxy } from 'relay-runtime';
import { v4 as uuid } from 'uuid';
import { JiraIssueTypeAri } from '@atlassian/ari/jira/issue-type';
import { JiraProjectAri } from '@atlassian/ari/jira/project';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagService, type ShowFlagFn } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import { SUMMARY_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type {
	useCreateCalendarIssueMutation,
	useCreateCalendarIssueMutation$data,
} from '@atlassian/jira-relay/src/__generated__/useCreateCalendarIssueMutation.graphql';
import { isErrorAllowed } from '../../common/utils/agg-errors/index.tsx';
import { toMidnightUTCString } from '../../common/utils/dates/index.tsx';
import { combineErrorMessages } from '../../common/utils/graphql/index.tsx';
import { useShowIssues } from '../calendar-store/index.tsx';
import { useCalendarConfigurationInput } from '../use-calendar-configuration-input/index.tsx';
import { useCalendarExperience } from '../use-calendar-experience/index.tsx';
import { useCalendarViewId } from '../use-calendar-view-id/index.tsx';
import { useLogger } from '../use-logger/index.tsx';
import { messages } from './messages.tsx';

export const OPTIMISTIC_ISSUE_ID_PREFIX = 'optimistic-id';

interface UpdateCreateCalendarIssueOptimisticUpdaterParams {
	store: RecordSourceSelectorProxy;
	connectionId: string | undefined;
	startDate?: string;
	endDate: string;
	summary: string;
	issueTypeRecordId?: string;
	viewId: string | null;
}

export function updateCreateCalendarIssueOptimisticUpdater({
	store,
	connectionId,
	startDate,
	endDate,
	summary,
	issueTypeRecordId,
	viewId,
}: UpdateCreateCalendarIssueOptimisticUpdaterParams) {
	const viewIdArg = viewId ? `(viewId: "${viewId}")` : '';
	const optimisticId = `${OPTIMISTIC_ISSUE_ID_PREFIX}-${uuid()}`;
	const newIssueRecord = store.create(optimisticId, 'JiraIssue');
	newIssueRecord.setValue(optimisticId, 'id');

	if (startDate) {
		const startDateRecord = store.create(`${optimisticId}/startDate`, 'JiraDatePickerField');
		startDateRecord.setValue(`${optimisticId}/startDate`, 'id');
		startDateRecord.setValue(startDate, 'date');
		newIssueRecord.setLinkedRecord(startDateRecord, `startDateViewField${viewIdArg}`);
	}

	const endDateRecord = store.create(`${optimisticId}/endDate`, 'JiraDatePickerField');
	endDateRecord.setValue(`${optimisticId}/endDate`, 'id');
	endDateRecord.setValue(endDate, 'date');
	newIssueRecord.setLinkedRecord(endDateRecord, `endDateViewField${viewIdArg}`);

	const summaryRecord = store.create(`${optimisticId}/summary`, 'JiraSingleLineTextField');
	summaryRecord.setValue(`${optimisticId}/summary`, 'id');
	summaryRecord.setValue(summary, 'text');
	newIssueRecord.setLinkedRecord(summaryRecord, 'summaryField');

	if (issueTypeRecordId) {
		const issueTypeRecord = store.get(issueTypeRecordId);

		if (issueTypeRecord) {
			const newIssueTypeField = store.create(uuid(), 'JiraIssueTypeField');
			newIssueTypeField.setLinkedRecord(issueTypeRecord, 'issueType');
			newIssueRecord.setLinkedRecord(newIssueTypeField, 'issueTypeField');
		}
	}

	if (connectionId) {
		const connection = store.get(connectionId);
		if (!connection) {
			return;
		}

		const edges = connection.getLinkedRecords('edges');

		const newEdge = ConnectionHandler.createEdge(
			store,
			connection,
			newIssueRecord,
			'JiraIssueEdge',
		);
		edges?.push(newEdge);
		connection.setLinkedRecords(edges, 'edges');
	}
}

interface UpdateCreateCalendarIssueUpdaterParams {
	store: RecordSourceSelectorProxy;
	response: useCreateCalendarIssueMutation$data;
	showFlag: ShowFlagFn;
	calendarConnectionId: string | undefined;
}

export function updateCreateCalendarIssueUpdater({
	store,
	response,
	calendarConnectionId,
}: UpdateCreateCalendarIssueUpdaterParams) {
	const newIssue = response?.jira?.createCalendarIssue?.issue;
	if (!newIssue) {
		return;
	}

	const newIssueRecord = store.get(newIssue.__id);
	if (!newIssueRecord) {
		return;
	}

	if (calendarConnectionId) {
		const connection = store.get(calendarConnectionId);
		if (!connection) {
			return;
		}

		const edges = connection?.getLinkedRecords('edges');
		const newEdge = ConnectionHandler.createEdge(
			store,
			connection,
			newIssueRecord,
			'JiraIssueEdge',
		);
		edges?.push(newEdge);
		connection.setLinkedRecords(edges, 'edges');
	}
}

export interface CreateCalendarIssueMutationParams {
	startDate?: Date;
	endDate: Date;
	issueTypeRecordId?: string;
	issueTypeAri: string;
	summary: string;
	projectAri: string;
}

export function useCreateCalendarIssueMutation({
	connectionId,
	dateFieldIds,
}: {
	connectionId: string | undefined;
	dateFieldIds: {
		startDateId: string;
		endDateId: string;
	};
}) {
	const { configurationInput } = useCalendarConfigurationInput();
	const { createCalendarIssueExperience } = useCalendarExperience();
	const { viewId } = useCalendarViewId();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { showFlag } = useFlagService();
	const [_, { openIssueCreateModal }] = useTriggerIssueCreateModal();
	const { logError } = useLogger();

	const showIssues = useShowIssues();

	const [createIssue] = useMutation<useCreateCalendarIssueMutation>(graphql`
		mutation useCreateCalendarIssueMutation(
			$scope: JiraViewScopeInput!
			$configuration: JiraCalendarViewConfigurationInput!
			$issueTypeId: ID!
			$startDateInput: DateTime
			$endDateInput: DateTime!
			$summary: String!
			$viewId: ID
			$schedulePermissionsEnabled: Boolean!
		) {
			jira {
				createCalendarIssue(
					scope: $scope
					configuration: $configuration
					issueTypeId: $issueTypeId
					summary: $summary
					startDateInput: $startDateInput
					endDateInput: $endDateInput
				) @optIn(to: "JiraCalendar") {
					success
					errors {
						message
						extensions {
							statusCode
							errorType
						}
					}
					issue {
						__id
						id

						...issueRenderer_calendar_IssueEventRenderer
							@arguments(viewId: $viewId, schedulePermissionsEnabled: $schedulePermissionsEnabled)
					}
				}
			}
		}
	`);

	const createCalendarIssue = ({
		startDate,
		endDate,
		issueTypeAri,
		issueTypeRecordId,
		summary,
		projectAri,
	}: CreateCalendarIssueMutationParams) => {
		createCalendarIssueExperience.start();

		const startUtcDateFormatted = startDate ? toMidnightUTCString(startDate) : undefined;
		const endUtcDateFormatted = toMidnightUTCString(endDate);

		const startDateFormatted = startDate ? format(startDate, 'yyyy-MM-dd') : undefined;
		const endDateFormatted = format(endDate, 'yyyy-MM-dd');

		createIssue({
			variables: {
				scope: { ids: [projectAri] },
				configuration: configurationInput,
				issueTypeId: issueTypeAri,
				summary,
				startDateInput: startUtcDateFormatted,
				endDateInput: endUtcDateFormatted,
				viewId,
				schedulePermissionsEnabled: fg('calendar_schedule_issue_permissions_check'),
			},
			optimisticUpdater: (store, response) => {
				if (!showIssues) {
					showFlag({
						id: 'create-calendar-issue-hidden',
						title: fg('jira-issue-terminology-refresh-m3')
							? messages.createIssueHiddenTitleIssueTermRefresh
							: messages.createIssueHiddenTitle,
						description: messages.createIssueHiddenBody,
						type: 'warning',
					});
				}
				if (response?.jira?.createCalendarIssue?.success === false) {
					return;
				}
				updateCreateCalendarIssueOptimisticUpdater({
					store,
					connectionId,
					startDate: startDateFormatted,
					endDate: endDateFormatted,
					summary,
					issueTypeRecordId,
					viewId,
				});
			},
			updater: (store, response) => {
				if (!response || response?.jira?.createCalendarIssue?.success === false) {
					return;
				}
				updateCreateCalendarIssueUpdater({
					store,
					response,
					showFlag,
					calendarConnectionId: connectionId,
				});
			},
			onError: (error: Error) => {
				createCalendarIssueExperience.failure();
				logError('calendar.create-issue-mutation.error', 'Failed to create issue', error);
				showFlag({
					id: 'create-calendar-issue-error',
					title: messages.failedToCreateErrorTitle,
					description: fg('jira-issue-terminology-refresh-m3')
						? messages.failedToCreateErrorBodyIssueTermRefresh
						: messages.failedToCreateErrorBody,
					type: 'error',
				});
			},
			onCompleted(response) {
				if (response.jira?.createCalendarIssue?.success === false) {
					const errorResponse = response?.jira?.createCalendarIssue?.errors;

					// If error code is 400, assume the error is due to the issue type
					// having a required field which is not submitted as part of this
					// mutation. In this case, bring up the GIC and prefill fields from
					// mutation.
					const hasRequiredFieldError = response.jira.createCalendarIssue.errors?.find(
						(error) => error.extensions?.statusCode === 400,
					);

					const abortOnRequiredFields =
						hasRequiredFieldError && fg('create_calendar_issue_mutation_required_skip_error');

					// Abort create calendar experience if the error is due to issue type
					// having a required field.
					if (fg('calendar_handles_graphql_error')) {
						if (isErrorAllowed(errorResponse) || abortOnRequiredFields) {
							createCalendarIssueExperience.abort();
						} else {
							createCalendarIssueExperience.failure();
						}
					} else {
						createCalendarIssueExperience.abort();
					}

					if (fg('create_calendar_issue_mutation_required_skip_error')) {
						if (errorResponse && !hasRequiredFieldError) {
							showFlag({
								id: 'create-calendar-issue-error',
								title: messages.failedToCreateErrorTitle,
								description: fg('jira-issue-terminology-refresh-m3')
									? messages.failedToCreateErrorBodyIssueTermRefresh
									: messages.failedToCreateErrorBody,
								type: 'error',
							});

							logError('calendar.create-calendar-issue-error', errorResponse[0].message || '');
						}
					} else if (errorResponse) {
						showFlag({
							id: 'create-calendar-issue-error',
							title: messages.failedToCreateErrorTitle,
							description: fg('jira-issue-terminology-refresh-m3')
								? messages.failedToCreateErrorBodyIssueTermRefresh
								: messages.failedToCreateErrorBody,
							type: 'error',
						});
						logError('calendar.create-calendar-issue-error', errorResponse[0].message || '');
					}

					if (hasRequiredFieldError) {
						const projectId = JiraProjectAri.parse(projectAri).projectId;
						const issueTypeId = JiraIssueTypeAri.parse(issueTypeAri).issueTypeId;
						openIssueCreateModal({
							triggerPointKey: 'jira-calendar-create-issue',
							minimizableModalView: 'mini',
							displayMinimizableButtons: false,
							displayHeaderActionContainer: false,
							displayCreateAnotherIssueToggle: false,
							payload: {
								project: {
									projectId,
								},
								issueType: {
									issueTypeId,
								},
								defaultValues: {
									datePickerFields: [
										...(startDateFormatted
											? [
													{
														fieldId: dateFieldIds.startDateId,
														date: {
															formattedDate: startDateFormatted,
														},
													},
												]
											: []),
										{
											fieldId: dateFieldIds.endDateId,
											date: {
												formattedDate: endDateFormatted,
											},
										},
									],
									singleLineTextFields: [
										{
											fieldId: SUMMARY_TYPE,
											text: summary,
										},
									],
								},
							},
						});
						return;
					}
					const errorMessage =
						combineErrorMessages(response?.jira?.createCalendarIssue?.errors) ??
						'Failed to create issue';
					logError(
						'calendar.create-issue-mutation.failure',
						`Failed to create calendar issue: ${errorMessage}`,
					);
				} else {
					createCalendarIssueExperience.success();
					fireTrackAnalytics(
						createAnalyticsEvent({}),
						'issue created',
						String(response.jira?.createCalendarIssue?.issue?.id),
						{ location: 'calendarDate' },
					);
				}
			},
		});
	};

	return { createCalendarIssue };
}
