/**
 * @generated SignedSource<<f7662f9e39cb357a627960b080459ab0>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type calendarUnscheduledPanel_calendar$data = {
  readonly " $fragmentSpreads": FragmentRefs<"calendarUnscheduledPanelCardList_calendar_queryRef">;
  readonly " $fragmentType": "calendarUnscheduledPanel_calendar";
};
export type calendarUnscheduledPanel_calendar$key = {
  readonly " $data"?: calendarUnscheduledPanel_calendar$data;
  readonly " $fragmentSpreads": FragmentRefs<"calendarUnscheduledPanel_calendar">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "configurationInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "schedulePermissionsEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "unscheduledIssuesSearchInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "name": "calendarUnscheduledPanel_calendar",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "configurationInput",
          "variableName": "configurationInput"
        },
        {
          "kind": "Variable",
          "name": "schedulePermissionsEnabled",
          "variableName": "schedulePermissionsEnabled"
        },
        {
          "kind": "Variable",
          "name": "scopeInput",
          "variableName": "scopeInput"
        },
        {
          "kind": "Variable",
          "name": "unscheduledIssuesSearchInput",
          "variableName": "unscheduledIssuesSearchInput"
        },
        {
          "kind": "Variable",
          "name": "viewId",
          "variableName": "viewId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "calendarUnscheduledPanelCardList_calendar_queryRef"
    }
  ],
  "type": "Query"
};

(node as any).hash = "8b4255093e50def56737c9d69119fcb6";

export default node;
