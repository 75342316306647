import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { Stack } from '@atlaskit/primitives';
import type { filterSection_softwareFiltersPopup$key } from '@atlassian/jira-relay/src/__generated__/filterSection_softwareFiltersPopup.graphql';
import type { JQLModel } from '../../common/utils/jql-model/index.tsx';
import { FilterOptions } from './filter-options/index.tsx';
import { SectionHeader } from './section-header/index.tsx';

interface FilterSectionProps {
	title: string;
	onClickClearFilter: (fieldName: string) => void;
	connectionRef: filterSection_softwareFiltersPopup$key | null;
	fieldName: string;
	jqlModel: JQLModel;
	onToggleFilter: (fieldName: string, value: string) => void;
	nodePredicate?: (node: { __typename: string }) => boolean;
}

export function FilterSection({
	fieldName,
	title,
	connectionRef,
	nodePredicate,
	jqlModel,
	onClickClearFilter,
	onToggleFilter,
}: FilterSectionProps) {
	const data = useFragment(
		graphql`
			fragment filterSection_softwareFiltersPopup on JiraJqlFieldValueConnection {
				...filterOptions_softwareFiltersPopup
				totalCount
			}
		`,
		connectionRef,
	);

	if (data?.totalCount === 0) return null;

	return (
		<section>
			<Stack space="space.100">
				<SectionHeader
					jqlModel={jqlModel}
					fieldName={fieldName}
					onClickClearFilter={onClickClearFilter}
				>
					{title}
				</SectionHeader>
				<FilterOptions
					connectionRef={data}
					fieldName={fieldName}
					jqlModel={jqlModel}
					onToggleFilter={onToggleFilter}
					nodePredicate={nodePredicate}
				/>
			</Stack>
		</section>
	);
}
