import { useMemo } from 'react';
import type { JiraCalendarIssuesInput } from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery.graphql';
import {
	buildJQLQuery,
	type JQLModel,
} from '@atlassian/jira-software-filters-popup/src/common/utils/jql-model/index.tsx';
import { and } from '../../common/utils/jql-query-helper/index.tsx';
import { useUnscheduledPanelState } from '../calendar-store/index.tsx';

function getCalendarPanelJQLQuery({
	extraFilters,
	search,
	sortDirection,
}: {
	extraFilters: JQLModel | null;
	search: string | null;
	sortDirection: 'ASC' | 'DESC';
}) {
	return (
		[
			and(
				[
					...(search ? [`text ~ '${search}*'`] : []),
					...(extraFilters ? [buildJQLQuery(extraFilters)] : []),
				].filter((s) => s),
			),
			`ORDER BY created ${sortDirection}, Rank ${sortDirection}`,
		]
			.filter((expression: string) => expression?.trim()?.length > 0)
			.join(' ')
			.trim() || ''
	);
}

export function useUnscheduledIssuesSearchInput(): {
	unscheduledIssuesSearchInput: JiraCalendarIssuesInput;
} {
	const { search, sortDirection, extraFilters } = useUnscheduledPanelState();

	const unscheduledIssuesSearchInput = useMemo<JiraCalendarIssuesInput>(
		() => ({
			additionalFilterQuery: getCalendarPanelJQLQuery({
				extraFilters,
				search,
				sortDirection,
			}),
		}),
		[extraFilters, search, sortDirection],
	);

	return { unscheduledIssuesSearchInput };
}
