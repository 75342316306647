import type { FormatDateOptions } from '@atlassian/jira-intl';
import type { CalendarDayStart } from '../controllers/calendar-store/types.tsx';

export const PACKAGE_NAME = '@atlassian/jira-calendar';
export const APP_ID = 'jira-calendar';
export const TEAM_NAME = 'jira-yurree';
export const ANALYTICS_SOURCE = 'jiraCalendar';

// Constants - Used for external project connection / disconnection
export const GRAPHQL_FIELD_GRAPH_STORE = 'graphStore';
export const GRAPHQL_FIELD_AGS_RELATIONSHIP_PROJECTS = 'projectHasRelatedWorkWithProject';
export const GRAPHQL_TYPE_JIRA_PROJECT = 'JiraProject';
export const GRAPHQL_TYPE_JIRA_AVATAR = 'JiraAvatar';

export const DEFAULT_FORMAT_DATE_OPTIONS: FormatDateOptions = {
	day: '2-digit',
	month: 'short',
	year: 'numeric',
};

export const UI_EVENT_CHANGED = 'changed';
export const UI_EVENT_RESIZED = 'resized';
export const UI_EVENT_DROPPED = 'dropped';

export const CALENDAR_SOURCE = 'calendar';
export const UNSCHEDULE_PANEL_SOURCE = 'panel';
export const ANALYTICS_ACTION_ISSUE_SUBJECT = 'calendarIssue';
export const ANALYTICS_ACTION_SCHEDULED = 'scheduled';
export const ANALYTICS_ISSUE_UPDATED_TRACK_EVENT_NAME = 'issue updated';
export const ANALYTICS_ACTION_VIEWRANGE_SUBJECT = 'calendarViewRange';

export const SELECTED_ISSUE = 'selectedIssue';

export const DEFAULT_MAX_ISSUES_PAGE_SIZE_OLD = 50;
export const DEFAULT_MAX_ISSUES_PAGE_SIZE = 200;
export const DEFAULT_MAX_ISSUES_PAGES_TO_LOAD = 5;

export const MINIMUM_SLOT_DURATION_MINUTES = 15;
export const DEFAULT_SLOT_DURATION_HOUR = 1;

export const CALENDAR_FIELD_CONFIG_TYPE_DATETIME_FIELD =
	'com.atlassian.jira.plugin.system.customfieldtypes:datetime';

/**
 * Default Day Starts At Time in Calendar View Settings
 */
export const DEFAULT_DAY_STARTS_AT: CalendarDayStart = '09:00 AM';

// Default no of events to show in a all day cell in week view
export const WEEK_VIEW_DEFAULT_MAX_DAY_EVENTS = 2;
