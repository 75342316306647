import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { Stack, Inline } from '@atlaskit/primitives';
import type {
	filterOptions_softwareFiltersPopup$key,
	filterOptions_softwareFiltersPopup$data,
} from '@atlassian/jira-relay/src/__generated__/filterOptions_softwareFiltersPopup.graphql';
import { fg } from '@atlassian/jira-feature-gating';
import { FieldValueLabel } from '../../../common/ui/field-value-label/index.tsx';
import { FilterButton } from '../../../common/ui/filter-button/index.tsx';
import { MoreOptionsDropdown } from '../../../common/ui/more-options-dropdown/index.tsx';
import type { JQLModel } from '../../../common/utils/jql-model/index.tsx';
import type { GetPath } from '../../../common/utils/type-get/index.tsx';
import { MoreOptionsFilterDropdownContent } from './more-options-filter-dropdown-content/index.tsx';

export interface FilterOptionsProps {
	/**
	 * A fragment to a connection of filter values to render here.
	 */
	connectionRef: filterOptions_softwareFiltersPopup$key | null;
	/**
	 * The field name of these filter options
	 */
	fieldName: string;
	/**
	 * The current JQL filter state.
	 */
	jqlModel: JQLModel;
	/**
	 * Called when a filter option is toggled.
	 */
	onToggleFilter: (fieldName: string, value: string) => void;
	/**
	 * For the assignee field only we're filtering options to hide the user-groups from
	 * the icons views.
	 *
	 * Ideally we'd use different connections like `JiraQuery::recentlyUsedUsers` for
	 * the top-level display, which won't have this issue.
	 */
	nodePredicate?: (node: { __typename: string }) => boolean;
}

/**
 * This component is able to render filter popup options as icons for a given field.
 *
 * If the field has more than 15 options a more dropdown is rendered and users can open
 * that to see all options on an inifite scrolling list.
 *
 * This is meant to support all field types. It's up to the label renderers to
 * handle rendering them.
 */
export function FilterOptions({
	connectionRef,
	jqlModel,
	fieldName,
	onToggleFilter,
	nodePredicate,
}: FilterOptionsProps) {
	const data = useFragment(
		graphql`
			fragment filterOptions_softwareFiltersPopup on JiraJqlFieldValueConnection {
				pageInfo {
					hasNextPage
				}

				edges {
					cursor
					node {
						__typename

						jqlTerm
						...fieldValueLabel_softwareFiltersPopup
					}
				}
			}
		`,
		connectionRef,
	);
	const renderCell = (
		edge: GetPath<filterOptions_softwareFiltersPopup$data, ['edges', 0]> | null | undefined,
	) =>
		edge &&
		edge.node && (
			<FilterButton
				key={edge.cursor}
				jqlModel={jqlModel}
				fieldName={fieldName}
				optionValue={edge.node.jqlTerm}
				onToggleFilter={onToggleFilter}
			>
				<FieldValueLabel fieldValueRef={edge.node} mode="popup" />
			</FilterButton>
		);

	const validEdges = nodePredicate
		? data?.edges?.filter((edge) => edge?.node?.__typename && nodePredicate(edge.node))
		: data?.edges;
	if (!validEdges?.length) return null;

	return (
		<Stack space="space.050">
			{fg('fix_calendar_unschedule_panel_bug') ? (
				<Inline space="space.050" shouldWrap role="group">
					{validEdges?.slice(0, 15)?.map(renderCell)}
					{data?.pageInfo?.hasNextPage && (
						<MoreOptionsDropdown
							content={() => (
								<MoreOptionsFilterDropdownContent
									fieldName={fieldName}
									onToggleFilter={onToggleFilter}
									jqlModel={jqlModel}
								/>
							)}
						/>
					)}
				</Inline>
			) : (
				<>
					<Inline space="space.050" shouldWrap role="group">
						{validEdges?.slice(0, 8)?.map(renderCell)}{' '}
					</Inline>
					{Number(validEdges.length) > 8 && (
						<Inline space="space.050" shouldWrap>
							{validEdges?.slice(8, 15).map(renderCell)}
							{data?.pageInfo?.hasNextPage && (
								<MoreOptionsDropdown
									content={() => (
										<MoreOptionsFilterDropdownContent
											fieldName={fieldName}
											onToggleFilter={onToggleFilter}
											jqlModel={jqlModel}
										/>
									)}
								/>
							)}
						</Inline>
					)}
				</>
			)}
		</Stack>
	);
}
