import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { FormattedDate } from '@atlassian/jira-intl';
import type { JsonComponentProps } from '../../../model/fields/types.tsx';

type Props = JsonComponentProps<'date'>;

export default function DateField(props: Props) {
	const {
		dataSelectorProps: { value },
	} = props;
	return (
		<Wrapper>
			<FormattedDate value={value} year="numeric" month="short" day="numeric" />
		</Wrapper>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	paddingTop: token('space.100', '8px'),
	paddingRight: 0,
	paddingBottom: token('space.100', '8px'),
	paddingLeft: token('space.100', '8px'),
	overflowWrap: 'break-word',
});
