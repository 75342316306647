import React, { useState, useMemo, type MouseEvent } from 'react';
import Button from '@atlaskit/button';
import PersonAddIcon from '@atlaskit/icon/core/migration/person-add--invite-team';
import { Hide } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, type UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import InvitePeopleModalAsync from '@atlassian/jira-project-invite-people-modal/async.tsx';
import { JIRA_SOFTWARE } from '@atlassian/jira-shared-types/src/application.tsx';
import { getEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../constants.tsx';
import { messages } from './messages.tsx';

type AddPeopleModalProps = {
	isOpen: boolean;
	onClose: () => void;
	projectId: string;
	jiraProjectKey: string;
	name: string;
};

export const AddPeopleModal = ({
	isOpen,
	onClose,
	projectId,
	jiraProjectKey,
	name,
}: AddPeopleModalProps) => {
	const { appEditions, cloudId, isSiteAdmin } = useTenantContext();

	const appEdition = getEdition(JIRA_SOFTWARE, appEditions);
	return (
		<JSErrorBoundary
			id="jira-calendar-add-people"
			packageName={PACKAGE_NAME}
			teamName={TEAM_NAME}
			fallback="flag"
		>
			<InvitePeopleModalAsync
				appEdition={appEdition}
				cloudId={cloudId}
				isOpen={isOpen}
				onClose={onClose}
				jiraProjectName={name}
				projectId={Number(projectId)}
				jiraProjectKey={jiraProjectKey}
				isSiteAdmin={isSiteAdmin}
			/>
		</JSErrorBoundary>
	);
};

type AddPeopleButtonProps = {
	projectId: string;
	jiraProjectKey: string;
	name: string;
};

export const AddPeopleButton = ({ projectId, jiraProjectKey, name }: AddPeopleButtonProps) => {
	const [showAddPeopleModal, setShowAddPeopleModal] = useState<boolean>(false);
	const { formatMessage } = useIntl();

	const icon = useMemo(() => <PersonAddIcon label="" color={token('color.icon')} />, []);

	const handleClick = (_: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
		setShowAddPeopleModal(true);
		fireUIAnalytics(analyticsEvent, 'addPeople');
	};

	const closeAddPeopleModal = () => {
		setShowAddPeopleModal(false);
	};

	return (
		<UFOSegment name="jira-calendar-add-people">
			<Button
				testId="calendar.common.add-people-button.add-people-button"
				appearance="subtle"
				iconBefore={<Hide below="lg">{icon}</Hide>}
				onClick={handleClick}
			>
				<Hide above="lg">{icon}</Hide>
				<Hide below="lg">{formatMessage(messages.addPeople)}</Hide>
			</Button>
			<AddPeopleModal
				isOpen={showAddPeopleModal}
				onClose={closeAddPeopleModal}
				projectId={projectId}
				jiraProjectKey={jiraProjectKey}
				name={name}
			/>
		</UFOSegment>
	);
};
