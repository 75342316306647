import React, { useCallback, useEffect, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Stack } from '@atlaskit/primitives';
import type { ui_softwareFiltersPopup_FilterPopup$key } from '@atlassian/jira-relay/src/__generated__/ui_softwareFiltersPopup_FilterPopup.graphql';
import { buildJQLQuery, type JQLModel } from '../common/utils/jql-model/index.tsx';
import { FilterSection } from './filter-section/index.tsx';

export interface FilterPopupProps {
	/**
	 * Query reference to load filter options.
	 */
	queryRef: ui_softwareFiltersPopup_FilterPopup$key;
	/**
	 * Called with a JQL string whenever filters are changed.
	 */
	onChange: (jql: string, jqlModel: JQLModel) => void;
	/**
	 * The initial value of the filters
	 */
	initialValue?: JQLModel | null;
}

/**
 * This is a component in the spirit of "JQL query builder", but with more limited options.
 *
 * It implements a compact JQL query builder for use in panels. Currently this supports the
 * fields:
 *
 * * priority
 * * assignee
 * * status
 *
 * Adding new fields will benefit all consumers of the JQL builder as well as this component.
 *
 * https://hello.atlassian.net/wiki/spaces/~465484674/pages/3406761244/One+Jira+and+seven+ways+to+find+issues
 */
export function FilterPopup({ queryRef, onChange, initialValue }: FilterPopupProps) {
	const [jqlModel, setJQLModel] = useState<JQLModel>(
		() =>
			initialValue ?? {
				whereClauses: {},
			},
	);

	// TODO: The filter options fragment should be over query so that it can get proper pagination params.
	const data = useFragment(
		graphql`
			fragment ui_softwareFiltersPopup_FilterPopup on Query
			@argumentDefinitions(cloudId: { type: "ID!" }, jqlBuilderQuery: { type: "String" }) {
				jira {
					jqlBuilder(cloudId: $cloudId) {
						assignee: fieldValues(jqlContext: $jqlBuilderQuery, jqlTerm: "assignee", first: 15) {
							...filterSection_softwareFiltersPopup
						}

						status: fieldValues(jqlContext: $jqlBuilderQuery, jqlTerm: "status", first: 15) {
							...filterSection_softwareFiltersPopup
						}

						priority: fieldValues(jqlContext: $jqlBuilderQuery, jqlTerm: "priority", first: 15) {
							...filterSection_softwareFiltersPopup
						}

						labels: fieldValues(jqlContext: $jqlBuilderQuery, jqlTerm: "labels", first: 15) {
							...filterSection_softwareFiltersPopup
						}

						versions: fieldValues(jqlContext: $jqlBuilderQuery, jqlTerm: "fixVersions", first: 15) {
							...filterSection_softwareFiltersPopup
						}
					}
				}
			}
		`,
		queryRef,
	);

	const onToggleFilter = useCallback(
		(fieldName: string, fieldValue: string) => {
			setJQLModel((prev) => {
				const prevClauses = prev.whereClauses[fieldName] ?? [];
				const wasSelected = prevClauses.includes(fieldValue);
				const newClauses = wasSelected
					? prevClauses.filter((value) => value !== fieldValue)
					: [...prevClauses, fieldValue];

				return {
					whereClauses: {
						...prev.whereClauses,
						[fieldName]: newClauses,
					},
				};
			});
		},
		[setJQLModel],
	);

	useEffect(() => {
		onChange(buildJQLQuery(jqlModel), jqlModel);
	}, [jqlModel, onChange]);

	const filterSections = [
		{
			fieldName: 'assignee',
			title: 'Assignee',
			connectionRef: data.jira?.jqlBuilder?.assignee,
			nodePredicate: (node: { __typename: string }) => node.__typename === 'JiraJqlUserFieldValue',
		},
		{
			fieldName: 'priority',
			title: 'Priority',
			connectionRef: data.jira?.jqlBuilder?.priority,
		},
		{
			fieldName: 'status',
			title: 'Status',
			connectionRef: data.jira?.jqlBuilder?.status,
		},
		{
			fieldName: 'labels',
			title: 'Labels',
			connectionRef: data.jira?.jqlBuilder?.labels,
		},
		{
			fieldName: 'version',
			title: 'Versions',
			connectionRef: data.jira?.jqlBuilder?.versions,
		},
	];

	const onClickClearFilter = useCallback((fieldName: string) => {
		setJQLModel((prev) => ({
			whereClauses: {
				...prev.whereClauses,
				[fieldName]: [],
			},
		}));
	}, []);

	return (
		<Stack space="space.100" testId="software-filters-popup.ui.container">
			{filterSections.map(({ fieldName, title, connectionRef, nodePredicate }, i) => (
				<FilterSection
					key={i}
					title={title}
					nodePredicate={nodePredicate}
					onClickClearFilter={onClickClearFilter}
					connectionRef={connectionRef ?? null}
					fieldName={fieldName}
					jqlModel={jqlModel}
					onToggleFilter={onToggleFilter}
				/>
			))}
		</Stack>
	);
}
