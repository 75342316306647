import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box, Inline } from '@atlaskit/primitives';
import type { priorityFieldValueLabel_softwareFiltersPopup$key } from '@atlassian/jira-relay/src/__generated__/priorityFieldValueLabel_softwareFiltersPopup.graphql';

export function PriorityFieldValueLabel({
	fieldValueRef,
	mode,
}: {
	fieldValueRef: priorityFieldValueLabel_softwareFiltersPopup$key;
	mode: 'dropdown' | 'popup';
}) {
	const data = useFragment(
		graphql`
			fragment priorityFieldValueLabel_softwareFiltersPopup on JiraJqlPriorityFieldValue {
				priority {
					iconUrl
					name
				}
			}
		`,
		fieldValueRef,
	);

	if (mode === 'popup') {
		return (
			<Box aria-label={data.priority?.name || undefined}>
				<img src={data.priority.iconUrl ?? ''} height={32} width={32} alt="" />
			</Box>
		);
	}

	return (
		<Inline space="space.100" alignBlock="center">
			<img src={data.priority.iconUrl ?? ''} height={16} width={16} alt="" />

			<Box>{data.priority?.name}</Box>
		</Inline>
	);
}
