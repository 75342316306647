/**
 * @generated SignedSource<<5df8e9e85471c07bfb5d7e5f017ea30a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type calendarUnscheduledPanelCardList_calendar_CalendarUnscheduledPanelCardListInner_queryRef$data = {
  readonly panelJira: {
    readonly jiraCalendar: {
      readonly unscheduledIssues: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"calendarUnscheduledPanelCard_calendar">;
          } | null | undefined;
        } | null | undefined> | null | undefined;
        readonly errors: ReadonlyArray<{
          readonly extensions: ReadonlyArray<{
            readonly errorType: string | null | undefined;
            readonly statusCode: number | null | undefined;
          }> | null | undefined;
          readonly identifier: string | null | undefined;
          readonly message: string | null | undefined;
        }> | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "calendarUnscheduledPanelCardList_calendar_CalendarUnscheduledPanelCardListInner_queryRef";
};
export type calendarUnscheduledPanelCardList_calendar_CalendarUnscheduledPanelCardListInner_queryRef$key = {
  readonly " $data"?: calendarUnscheduledPanelCardList_calendar_CalendarUnscheduledPanelCardListInner_queryRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"calendarUnscheduledPanelCardList_calendar_CalendarUnscheduledPanelCardListInner_queryRef">;
};

import calendarUnscheduledPanelCardList_calendar_RefetchPaginated_Query_graphql from './calendarUnscheduledPanelCardList_calendar_RefetchPaginated_Query.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "panelJira",
  "jiraCalendar",
  "unscheduledIssues"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "configurationInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "schedulePermissionsEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "unscheduledIssuesSearchInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": calendarUnscheduledPanelCardList_calendar_RefetchPaginated_Query_graphql
    }
  },
  "name": "calendarUnscheduledPanelCardList_calendar_CalendarUnscheduledPanelCardListInner_queryRef",
  "selections": [
    {
      "alias": "panelJira",
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "configuration",
              "variableName": "configurationInput"
            },
            {
              "kind": "Variable",
              "name": "scope",
              "variableName": "scopeInput"
            }
          ],
          "concreteType": "JiraCalendar",
          "kind": "LinkedField",
          "name": "jiraCalendar",
          "plural": false,
          "selections": [
            {
              "alias": "unscheduledIssues",
              "args": [
                {
                  "kind": "Variable",
                  "name": "input",
                  "variableName": "unscheduledIssuesSearchInput"
                }
              ],
              "concreteType": "JiraIssueConnection",
              "kind": "LinkedField",
              "name": "__calendarUnscheduledPanelCardList_calendar__unscheduledIssues_connection",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "concreteType": "JiraIssue",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "id"
                        },
                        {
                          "args": [
                            {
                              "kind": "Variable",
                              "name": "schedulePermissionsEnabled",
                              "variableName": "schedulePermissionsEnabled"
                            },
                            {
                              "kind": "Variable",
                              "name": "viewId",
                              "variableName": "viewId"
                            }
                          ],
                          "kind": "FragmentSpread",
                          "name": "calendarUnscheduledPanelCard_calendar"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "__typename"
                        }
                      ]
                    },
                    {
                      "kind": "ScalarField",
                      "name": "cursor"
                    }
                  ]
                },
                {
                  "concreteType": "QueryError",
                  "kind": "LinkedField",
                  "name": "errors",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "identifier"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "message"
                    },
                    {
                      "kind": "LinkedField",
                      "name": "extensions",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "statusCode"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "errorType"
                        }
                      ]
                    }
                  ]
                },
                {
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "endCursor"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "hasNextPage"
                    }
                  ]
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "__id"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "3d8a78fb843c11e3eb20fe514b8bec1e";

export default node;
