import React from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { token } from '@atlaskit/tokens';
import { Inline, xcss } from '@atlaskit/primitives';
import type { calendarCardKey_calendar$key } from '@atlassian/jira-relay/src/__generated__/calendarCardKey_calendar.graphql';

interface CalendarCardKeyProps {
	issueRef: calendarCardKey_calendar$key;
}

export function CalendarCardKey({ issueRef }: CalendarCardKeyProps) {
	const data = useFragment(
		graphql`
			fragment calendarCardKey_calendar on JiraIssue {
				key
			}
		`,
		issueRef,
	);
	return (
		<Inline xcss={overflowStyles} grow="fill">
			<Overflow>{data.key}</Overflow>
		</Inline>
	);
}

// Need extra box for ellipsis to work
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Overflow = styled.div({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const overflowStyles = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	color: 'color.text.subtle',
	fontWeight: token('font.weight.semibold'),
	// required for box to shrink https://drafts.csswg.org/css-flexbox/#min-size-auto
	minWidth: '0px',
});
