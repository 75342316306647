import { useState, useEffect, useRef } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { checkEmailRequestStatus } from '../services/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../common/constants.tsx';

const useEmailRequestStatus = (projectKey: string | undefined, isProjectAdmin: boolean) => {
	const [emailAddress, setEmailAddress] = useState<string | undefined>(undefined);
	const [isEmailedRequestCreated, setIsEmailedRequestCreated] = useState<boolean | undefined>(
		undefined,
	);
	const [error, setError] = useState<Error | null>(null);
	const intervalIdRef = useRef<ReturnType<typeof setInterval> | null>(null);

	const stopPolling = () => {
		if (intervalIdRef.current) {
			clearInterval(intervalIdRef.current);
		}
	};

	useEffect(() => {
		if (
			!projectKey ||
			!isProjectAdmin ||
			!expValEquals('jsm_2d_fo_core_actions', 'cohort', 'variation')
		) {
			return;
		}

		const fetchEmailAddressAndStatus = async () => {
			try {
				const response = await checkEmailRequestStatus(projectKey);
				const incomingEmails = response.incomingEmails || [];
				const defaultEmail = incomingEmails.find(
					(email: { defaultReplyTo: boolean }) => email.defaultReplyTo === true,
				);
				if (defaultEmail) {
					setEmailAddress(defaultEmail.emailAddress);
				}
				if (
					incomingEmails.some(
						(email: { lastProcessedTime: number }) => email.lastProcessedTime !== 0,
					)
				) {
					setIsEmailedRequestCreated(true);
				} else {
					setIsEmailedRequestCreated(false);
				}
			} catch (e) {
				const errorInstance = e instanceof Error ? e : new Error('Unknown error');
				setError(errorInstance);
				fireErrorAnalytics({
					meta: {
						id: 'fetchEmailAddressAndStatus',
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					error: errorInstance,
					sendToPrivacyUnsafeSplunk: true,
				});
			}
		};

		fetchEmailAddressAndStatus();
		intervalIdRef.current = setInterval(fetchEmailAddressAndStatus, 5000);

		return () => {
			if (intervalIdRef.current !== null) {
				clearInterval(intervalIdRef.current);
			}
		};
	}, [projectKey, isProjectAdmin]);

	useEffect(() => {
		if (isEmailedRequestCreated && intervalIdRef.current) {
			clearInterval(intervalIdRef.current);
		}
	}, [isEmailedRequestCreated]);

	return { emailAddress, isEmailedRequestCreated, error, stopPolling };
};

export default useEmailRequestStatus;
