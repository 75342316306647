/**
 * @generated SignedSource<<fe1342a2eab6e753180b3fe0c97564cc>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type fieldValueLabel_softwareFiltersPopup$data = {
  readonly __typename: string;
  readonly " $fragmentSpreads": FragmentRefs<"defaultFieldValueLabel_softwareFiltersPopup" | "groupFieldValueLabel_softwareFiltersPopup" | "labelFieldValueLabel_softwareFiltersPopup" | "priorityFieldValueLabel_softwareFiltersPopup" | "projectFieldValueLabel_softwareFiltersPopup" | "statusFieldValueLabel_softwareFiltersPopup" | "userFieldValueLabel_softwareFiltersPopup">;
  readonly " $fragmentType": "fieldValueLabel_softwareFiltersPopup";
};
export type fieldValueLabel_softwareFiltersPopup$key = {
  readonly " $data"?: fieldValueLabel_softwareFiltersPopup$data;
  readonly " $fragmentSpreads": FragmentRefs<"fieldValueLabel_softwareFiltersPopup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "fieldValueLabel_softwareFiltersPopup",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "FragmentSpread",
      "name": "defaultFieldValueLabel_softwareFiltersPopup"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "userFieldValueLabel_softwareFiltersPopup"
        }
      ],
      "type": "JiraJqlUserFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "priorityFieldValueLabel_softwareFiltersPopup"
        }
      ],
      "type": "JiraJqlPriorityFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "projectFieldValueLabel_softwareFiltersPopup"
        }
      ],
      "type": "JiraJqlProjectFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "statusFieldValueLabel_softwareFiltersPopup"
        }
      ],
      "type": "JiraJqlStatusFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "labelFieldValueLabel_softwareFiltersPopup"
        }
      ],
      "type": "JiraJqlLabelFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "groupFieldValueLabel_softwareFiltersPopup"
        }
      ],
      "type": "JiraJqlGroupFieldValue"
    }
  ],
  "type": "JiraJqlFieldValue",
  "abstractKey": "__isJiraJqlFieldValue"
};

(node as any).hash = "e0e0b5e0dc33da2c630f6e60c2503fff";

export default node;
