import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import messages from './messages';

export const useAllDayContent = () =>
	function AllDayContent() {
		return <FormattedMessage {...messages.allDay} />;
	};
