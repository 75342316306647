/**
 * @generated SignedSource<<ba654f11089dd29d06e27e6cd9cc81f3>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type eventSummary_calendar$data = {
  readonly " $fragmentSpreads": FragmentRefs<"eventAssignee_calendar">;
  readonly " $fragmentType": "eventSummary_calendar";
};
export type eventSummary_calendar$key = {
  readonly " $data"?: eventSummary_calendar$data;
  readonly " $fragmentSpreads": FragmentRefs<"eventSummary_calendar">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "name": "eventSummary_calendar",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "viewId",
          "variableName": "viewId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "eventAssignee_calendar"
    }
  ],
  "type": "JiraScenarioIssueLike",
  "abstractKey": "__isJiraScenarioIssueLike"
};

(node as any).hash = "3d1e7e4a161e29e81b32b6f0a7bca431";

export default node;
