import { useMemo } from 'react';
import type {
	JiraCalendarViewConfigurationInput,
	JiraCalendarMode,
	JiraCalendarWeekStart,
} from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery.graphql';
import { toMidnightUTCString } from '../../common/utils/dates/index.tsx';
import {
	useDateRange,
	useIssueEndDateField,
	useIssueStartDateField,
	useViewRange,
	useWeekStartsOn,
} from '../calendar-store/index.tsx';
import { useCalendarViewId } from '../use-calendar-view-id/index.tsx';

function toViewRange(value: string): JiraCalendarMode {
	switch (value) {
		case 'month':
			return 'MONTH';
		case 'week':
			return 'WEEK';
		case 'day':
			return 'DAY';
		default:
			return 'MONTH';
	}
}

function toWeekStart(value: string): JiraCalendarWeekStart {
	switch (value) {
		case 'sunday':
			return 'SUNDAY';
		case 'monday':
			return 'MONDAY';
		case 'saturday':
			return 'SATURDAY';
		default:
			return 'SUNDAY';
	}
}

export function useCalendarConfigurationInput(): {
	configurationInput: JiraCalendarViewConfigurationInput;
} {
	const dateRange = useDateRange();
	const viewRange = useViewRange();
	const weekStartsOn = useWeekStartsOn();
	const startDateField = useIssueStartDateField();
	const endDateField = useIssueEndDateField();
	const { viewId } = useCalendarViewId();

	const configurationInput: JiraCalendarViewConfigurationInput = useMemo(
		() => ({
			date: toMidnightUTCString(dateRange.currentDate),
			mode: toViewRange(viewRange),
			weekStart: toWeekStart(weekStartsOn),
			startDateField,
			endDateField,
			viewId,
		}),
		[dateRange.currentDate, endDateField, startDateField, viewRange, weekStartsOn, viewId],
	);

	return {
		configurationInput,
	};
}
