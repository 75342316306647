import React, { createContext, useContext, useMemo, memo, type ReactNode } from 'react';

export const DEFAULT_UNKNOWN_LOCATION_OR_SUFFIX_KEY = 'UNKNOWN';

export type ReleaseFlyoutUFOContext = {
	location: string;
	suffixKey: string;
};

const defaultUFOContext: ReleaseFlyoutUFOContext = {
	location: DEFAULT_UNKNOWN_LOCATION_OR_SUFFIX_KEY,
	suffixKey: DEFAULT_UNKNOWN_LOCATION_OR_SUFFIX_KEY,
};

const ReleaseFlyoutUFOContext = createContext<ReleaseFlyoutUFOContext>(defaultUFOContext);

export const useReleaseFlyoutUFOExperienceContext = () => useContext(ReleaseFlyoutUFOContext);

type Props = Partial<ReleaseFlyoutUFOContext> & {
	children: ReactNode;
};

export const ReleaseFlyoutUFOContextProvider = memo(({ children, ...props }: Props) => {
	const context = useMemo(() => ({ ...defaultUFOContext, ...props }), [props]);
	return (
		<ReleaseFlyoutUFOContext.Provider value={context}>{children}</ReleaseFlyoutUFOContext.Provider>
	);
});
