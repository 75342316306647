/** @jsx jsx */
import React, { forwardRef, type Ref } from 'react';
import { css, jsx } from '@compiled/react';
import { Box, xcss, type BackgroundColor } from '@atlaskit/primitives';
import { Y400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

const TooltipTag = forwardRef<HTMLElement>((props, ref) => (
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	<span css={wrapperStyles} {...props} ref={ref as Ref<HTMLDivElement>} />
));

const TooltipTriangleTag = forwardRef<HTMLElement>((props, ref) => (
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	<span css={triangleStyles} {...props} ref={ref as Ref<HTMLDivElement>} />
));

type Props = {
	appearance?: 'default' | 'triangle';
};
export const ChangeIndicator = ({ appearance = 'default' }: Props) => {
	const intl = useIntl();

	return (
		<Tooltip
			tag={appearance === 'triangle' ? TooltipTriangleTag : TooltipTag}
			content={intl.formatMessage(messages.changeIndicatorTooltip)}
			position="mouse"
		>
			{appearance === 'triangle' ? (
				<svg
					viewBox="0 0 100 100"
					xmlns="http://www.w3.org/2000/svg"
					width="10" // Seems to be a minimum width/height else the svg doesn't render properly in chrome
					height="10"
				>
					<polygon
						points="0,0  100,0  100,100"
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, jira/react/no-style-attribute
						style={{ fill: token('color.background.warning.bold.hovered', Y400) }}
					/>
				</svg>
			) : (
				<Box as="span" xcss={[indicatorStyles]} testId="calendar.common.ui.change-indicator" />
			)}
		</Tooltip>
	);
};

const wrapperStyles = css({
	position: 'absolute',
	width: '5px',
	height: '100%',
	top: '0',
	left: '0',
	display: 'block',
});

const indicatorStyles = xcss({
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: 'color.background.warning.bold.hovered' as BackgroundColor,
	width: '100%',
	height: '100%',
	display: 'block',
});

const triangleStyles = css({
	position: 'absolute',
	width: '10px',
	top: '-5px',
	right: 0,
	zIndex: 0,
	overflow: 'hidden',
});
