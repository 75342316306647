import React, { useEffect, useMemo, useRef } from 'react';
import noop from 'lodash/noop';
import MegaphoneIcon from '@atlaskit/icon/core/megaphone';
import type { ChoreographerMessageTypes } from '@atlassian/jira-choreographer-services/src/types.tsx';
import CoordinationClient from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagsService, AkFlag, type FlagId } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import type { FlagCustomRenderProps } from '@atlassian/jira-flags/src/services/types'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge/src/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { useIsItsmTemplateProject } from '@atlassian/jira-router-resources-service-desk-is-itsm-project/src/controllers/index.tsx';
import {
	INCIDENTS,
	CHANGES,
} from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { EP_MESSAGES } from './constants.tsx';
import messages from './messages.tsx';

type Props = {
	// eslint-disable-next-line jira/react/handler-naming
	stop?: () => void;
	category: typeof CHANGES | typeof INCIDENTS;
	messageId?: string;
	messageType?: ChoreographerMessageTypes;
};

const OnboardingTrigger = ({ stop = noop, category, messageId, messageType }: Props) => {
	const flagId = useRef<FlagId>();
	const { formatMessage } = useIntl();
	const { showFlag, dismissFlag } = useFlagsService();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isAdmin = useIsAdmin();
	const { data: projectContext } = useProjectContext();
	const { data: isItsmProject } = useIsItsmTemplateProject();

	const shouldRender = isItsmProject && (projectContext?.isProjectAdmin || isAdmin);

	const flagMeta = useMemo(
		() =>
			({
				[CHANGES]: {
					title: messages.changeManagementFlagTitle,
					description: messages.changeManagementFlagDescription,
					action: messages.changeManagementFlagActionText,
					actionUrl: `/jira/servicedesk/projects/${projectContext?.projectKey}/settings/change-management?tab=assets`,
				},
				[INCIDENTS]: {
					title: messages.incidentManagementFlagTitle,
					description: messages.incidentManagementFlagDescription,
					action: messages.incidentManagementFlagActionText,
					actionUrl: `/jira/servicedesk/projects/${projectContext?.projectKey}/settings/incident-management`,
				},
			})[category],
		[category, projectContext?.projectKey],
	);

	useEffect(() => {
		stop?.();
	}, [stop]);

	useEffect(() => {
		if (shouldRender && flagId.current == null) {
			flagId.current = showFlag({
				render: (props: FlagCustomRenderProps) => (
					<AkFlag
						{...props}
						// @ts-expect-error Type error after Flag props have been fixed globally, to be fixed by owning team
						id={flagId}
						icon={<MegaphoneIcon label={formatMessage(flagMeta.title)} color="currentColor" />}
						title={formatMessage(flagMeta.title)}
						description={formatMessage(flagMeta.description)}
						// eslint-disable-next-line jira/react-no-inline-function-prop
						onDismissed={() => {
							if (flagId.current) {
								dismissFlag(flagId.current);
							}
						}}
						actions={[
							{
								href: projectContext?.projectKey ? flagMeta.actionUrl : '/jira',
								content: formatMessage(flagMeta.action),
							},
						]}
						messageId={messageId}
						messageType={messageType}
					/>
				),
			});

			// https://data-portal.internal.atlassian.com/analytics/registry/73694
			fireTrackAnalytics(createAnalyticsEvent({}), 'jsmAssetsOnboardingFlag rendered', {
				category,
			});
		}
	}, [
		category,
		createAnalyticsEvent,
		dismissFlag,
		flagMeta,
		formatMessage,
		messageId,
		messageType,
		projectContext?.projectKey,
		shouldRender,
		showFlag,
	]);

	return null;
};

export const JsmAssetsOnboarding = ({ category }: Props) => {
	const messageId = EP_MESSAGES[category];
	const messageType = 'engagement';
	return (
		<JSErrorBoundary
			id="jsm-assets-onboarding"
			packageName="jiraServicedeskAssetsOnboarding"
			teamName="jenga"
			fallback="unmount"
		>
			<CoordinationClient fallback={null} messageId={messageId} messageType={messageType}>
				<OnboardingTrigger
					category={category}
					messageId={fg('choreographed_jsm_assets_onboarding') ? messageId : undefined}
					messageType={fg('choreographed_jsm_assets_onboarding') ? messageType : undefined}
				/>
			</CoordinationClient>
		</JSErrorBoundary>
	);
};
