import React from 'react';
import { ADD_ITEMS_CALENDAR_NUDGE_ID } from '@atlassian/jira-onboarding-core/src/constants.tsx';
import { OnboardingNudgeErrorBoundary } from '@atlassian/jira-onboarding-quickstart-core/src/common/ui/onboarding-nudge-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { ERROR_BOUNDARY_ID_JWM_CALENDAR_TOUR_NUDGE } from '../../../common/constants.tsx';
import type { WrapperProps } from '../../../common/types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AddItemsCalendarNudgeLazy = lazyAfterPaint(
	() =>
		import(/* webpackChunkName: "async-add-items-calendar-onboarding-nudge" */ './index').then(
			(exportedModule) => exportedModule.AddItemsCalendarNudge,
		),
	{
		ssr: false,
	},
);

export const AddItemsCalendarNudgeAsync = ({ children }: WrapperProps) => (
	<OnboardingNudgeErrorBoundary
		id={ERROR_BOUNDARY_ID_JWM_CALENDAR_TOUR_NUDGE}
		fallback={children}
		attributes={{
			nudgeId: ADD_ITEMS_CALENDAR_NUDGE_ID,
		}}
	>
		<Placeholder name="add-items-calendar-onboarding-nudge" fallback={children}>
			<AddItemsCalendarNudgeLazy>{children}</AddItemsCalendarNudgeLazy>
		</Placeholder>
	</OnboardingNudgeErrorBoundary>
);

AddItemsCalendarNudgeAsync.displayName = 'AddItemsCalendarNudgeAsync';
