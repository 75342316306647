import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	paginationInfo: {
		id: 'calendar.calendar-renderer.issues-paginator.page-info.pagination-info',
		defaultMessage:
			'{from}-{to} of {total}{hasSurpassedMaxCount, select, true {+} other {}} {total, plural, one {issue} other {issues}}',
		description:
			'The pagination information when the total count has surpassed issue search max count.',
	},
	paginationInfoIssueTermRefresh: {
		id: 'calendar.calendar-renderer.issues-paginator.page-info.pagination-info-issue-term-refresh',
		defaultMessage:
			'{from}-{to} of {total}{hasSurpassedMaxCount, select, true {+} other {}} {total, plural, one {work item} other {work items}}',
		description:
			'The pagination information when the total count has surpassed issue search max count.',
	},
});
