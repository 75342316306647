/**
 * @generated SignedSource<<17e0c75dedcdae24bac315d02c4e9617>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type calendarCardPriority_calendar$data = {
  readonly priorityField: {
    readonly name: string;
    readonly priority: {
      readonly iconUrl: AGG$URL | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "calendarCardPriority_calendar";
};
export type calendarCardPriority_calendar$key = {
  readonly " $data"?: calendarCardPriority_calendar$data;
  readonly " $fragmentSpreads": FragmentRefs<"calendarCardPriority_calendar">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "calendarCardPriority_calendar",
  "selections": [
    {
      "concreteType": "JiraPriorityField",
      "kind": "LinkedField",
      "name": "priorityField",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "name"
        },
        {
          "concreteType": "JiraPriority",
          "kind": "LinkedField",
          "name": "priority",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "iconUrl"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "532140af11958202f05bf4ac1ca330c2";

export default node;
