import React from 'react';
import { Hide } from '@atlaskit/primitives/responsive';
import { JiraSiteAri } from '@atlassian/ari/jira/site';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { integrationTypes } from '@atlassian/jira-share-button/src/constants.tsx';
import ShareButton from '@atlassian/jira-share-button/src/index.tsx';
import { useShareIntegrations } from '@atlassian/jira-share-integrations/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useCalendarUFOExperienceContext } from '../../../controllers/ufo-context-provider/index.tsx';
import { messages } from './messages.tsx';

export function CalendarShareButton() {
	const { cloudId: siteId } = useTenantContext();
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { shareIntegrations } = useShareIntegrations('calendar');

	const handleTriggerButtonClick = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			'shareButton',
		);
	};

	const objectAri = JiraSiteAri.create({ siteId }).toString();
	const { packageName, teamName } = useCalendarUFOExperienceContext();

	return (
		<UFOSegment name="jira-calendar-share-button">
			<JSErrorBoundary
				id="jira-calendar-share-button"
				packageName={packageName}
				teamName={teamName}
				fallback="flag"
			>
				<Hide above="lg">
					<ShareButton
						integrationType={integrationTypes.CALENDAR}
						triggerButtonStyle="icon-only"
						productId="jira"
						// TODO: Make it configurable
						subProductId="jira-software"
						shareTitle={formatMessage(messages.shareTitle)}
						shareFormTitle={formatMessage(messages.shareFormTitle)}
						shareContentType="calendar"
						objectAri={objectAri}
						copyTooltipText={formatMessage(messages.shareTooltipText)}
						shareIntegrations={shareIntegrations}
						shareFormHelperMessage={formatMessage(messages.shareHelperMessage)}
						onTriggerButtonClick={handleTriggerButtonClick}
					/>
				</Hide>
				<Hide below="lg">
					<ShareButton
						integrationType={integrationTypes.CALENDAR}
						triggerButtonStyle="icon-with-text"
						productId="jira"
						// TODO: Make it configurable
						subProductId="jira-software"
						shareTitle={formatMessage(messages.shareTitle)}
						shareFormTitle={formatMessage(messages.shareFormTitle)}
						shareContentType="calendar"
						objectAri={objectAri}
						copyTooltipText={formatMessage(messages.shareTooltipText)}
						shareIntegrations={shareIntegrations}
						shareFormHelperMessage={formatMessage(messages.shareHelperMessage)}
						onTriggerButtonClick={handleTriggerButtonClick}
					/>
				</Hide>
			</JSErrorBoundary>
		</UFOSegment>
	);
}
