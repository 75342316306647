import { stopCaptureGraphQlErrors } from '@atlassian/jira-relay-errors/src/index.tsx';
import { getTraceIds } from '@atlassian/relay-traceid';
import { CalendarGraphQLError, type GraphQLErrors } from './types.tsx';

const ALLOWED_STATUS_CODES = [404, 401, 403, 408, 426, 429];
const ALLOWED_ERROR_MESSAGES = [
	'no-plan-permissions',
	'You do not have permission to perform this action',
];
const ALLOWED_ERROR_TYPE = ['INSUFFICIENT_PERMISSION'];

export const isErrorAllowed = (errors: GraphQLErrors): boolean => {
	if (!errors || errors.length === 0) {
		return true;
	}
	return errors.some((error) => {
		const statusCode = error.extensions?.statusCode;
		const userMessages = error.message ?? '';
		const errorType = error.extensions?.errorType;

		return (
			ALLOWED_STATUS_CODES.includes(statusCode) ||
			ALLOWED_ERROR_MESSAGES.includes(userMessages) ||
			ALLOWED_ERROR_TYPE.includes(errorType)
		);
	});
};

/**
 * Get graphql errors captured by the query and check it against the whitelist;
 * Return CalendarGraphQLError if error is not allowed.
 */
export const getGraphQLErrors = (
	key: string,
	operationName: string,
): CalendarGraphQLError | undefined => {
	const errors = stopCaptureGraphQlErrors(key)
		.filter((errorWrapper) => errorWrapper.meta.operationName === operationName)
		.flatMap((errorWrapper) => errorWrapper.errors);

	if (!isErrorAllowed(errors)) {
		return new CalendarGraphQLError(
			`Query ${operationName} had graphql errors`,
			errors,
			getTraceIds(operationName),
		);
	}
	return undefined;
};
