import { type RefObject, useEffect, useState } from 'react';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { makeDraggableCalendarIssue } from '../../../../../services/draggable-card-type/index.tsx';

interface UseDraggablePanelCardParams {
	containerRef: RefObject<HTMLDivElement | undefined>;
	issueAri: string;
	issueRecordId: string;
	startDateFieldRecordId: string | undefined;
	endDateFieldRecordId: string | undefined;
	endDate: string | null | undefined;
	startDate: string | null | undefined;
	connectionRecordId: string | undefined;
	issueKey: string;
	canSchedule?: boolean | null;
}

/**
 * Attach pragmatic dnd listeners to the calendar card.
 *
 * The card will carry its ARI onto the drop-zone. The calendar drop-zone will support
 * assigning dates to the card.
 */
export function useDraggablePanelCard({
	issueAri,
	issueRecordId,
	startDateFieldRecordId,
	endDateFieldRecordId,
	endDate,
	startDate,
	containerRef,
	connectionRecordId,
	issueKey,
	canSchedule,
}: UseDraggablePanelCardParams): {
	isDragging: boolean;
} {
	const [isDragging, setIsDragging] = useState(false);

	useEffect(() => {
		if (!containerRef.current) return;

		return draggable({
			element: containerRef.current,
			getInitialData() {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return makeDraggableCalendarIssue({
					issueAri,
					issueRecordId,
					startDateFieldRecordId,
					endDateFieldRecordId,
					connectionRecordId,
					startDate,
					endDate,
					source: 'panel',
					issueKey,
					canSchedule,
				}) as unknown as Record<string, unknown>;
			},
			onDragStart() {
				setIsDragging(true);
			},
			onDrop() {
				setIsDragging(false);
			},
		});
	}, [
		connectionRecordId,
		containerRef,
		endDate,
		endDateFieldRecordId,
		issueAri,
		issueRecordId,
		startDate,
		startDateFieldRecordId,
		issueKey,
		canSchedule,
	]);

	return {
		isDragging,
	};
}
