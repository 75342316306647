import React, { Component } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { JsonComponentProps } from '../../../model/fields/types.tsx';
import { UnselectableChildGutterPaddingContainer } from '../common/styled/index.tsx';
import messages from './messages.tsx';

type Props = JsonComponentProps<'reporter'> & {
	intl: IntlShape;
};

const ANONYMOUS_STYLE = 'anonymous' as const;
type AnonymousStyle = {
	type: typeof ANONYMOUS_STYLE;
};

const TOOLTIP_STYLE = 'tooltip' as const;
type TooltipStyle = {
	type: typeof TOOLTIP_STYLE;
	emailAddress: string;
	displayName: string;
};

const SIMPLE_STYLE = 'simple' as const;
type SimpleStyle = {
	type: typeof SIMPLE_STYLE;
	value: string;
};

type DisplayStyle = AnonymousStyle | TooltipStyle | SimpleStyle;

const getTooltipMessage = (style: DisplayStyle): string | null => {
	if (style.type === TOOLTIP_STYLE) {
		return `${style.displayName} - ${style.emailAddress}`;
	}

	return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderWithTooltipIfRequired = (content: any, tooltipText: string | null) => {
	if (tooltipText) {
		return <Tooltip content={tooltipText}>{content}</Tooltip>;
	}
	return content;
};

// eslint-disable-next-line jira/react/no-class-components
class Reporter extends Component<Props> {
	getDisplayMessage(style: DisplayStyle): string {
		const {
			intl: { formatMessage },
		} = this.props;

		switch (style.type) {
			case TOOLTIP_STYLE:
				return this.formatForDisplay(style.displayName);
			case SIMPLE_STYLE:
				return this.formatForDisplay(style.value);
			case ANONYMOUS_STYLE:
				return formatMessage(messages.anonymous);
			default: {
				// prettier-ignore
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(style as never);
				return '';
			}
		}
	}

	getStyle(): DisplayStyle {
		const {
			dataSelectorProps: { value },
		} = this.props;

		if (!value || (!value.emailAddress && !value.displayName)) {
			return { type: ANONYMOUS_STYLE };
		}

		const { emailAddress, displayName } = value;

		if (!emailAddress || !displayName || emailAddress === displayName) {
			return {
				type: SIMPLE_STYLE,
				value: emailAddress || displayName || '',
			};
		}

		return {
			type: TOOLTIP_STYLE,
			emailAddress,
			displayName,
		};
	}

	formatForDisplay(name: string) {
		const {
			dataSelectorProps: { value },
			intl: { formatMessage },
		} = this.props;

		const active = !value || value.active;

		if (active) {
			return name;
		}
		return formatMessage(messages.inactive, { name });
	}

	render() {
		const {
			tableInjectedProps: { isLastColumn },
		} = this.props;

		const style = this.getStyle();

		const tooltip = getTooltipMessage(style);

		return (
			<UnselectableChildGutterPaddingContainer isLastColumn={isLastColumn}>
				{renderWithTooltipIfRequired(
					<ReporterWrapper>{this.getDisplayMessage(style)}</ReporterWrapper>,
					tooltip,
				)}
			</UnselectableChildGutterPaddingContainer>
		);
	}
}

export default injectIntl(Reporter);

// gridSize + 2 to account for summary field {padding: gridSize} + 2px from transparent border
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReporterWrapper = styled.div({
	paddingTop: token('space.100', '8px'),
	paddingRight: token('space.0', '0px'),
	paddingBottom: token('space.100', '8px'),
	paddingLeft: token('space.0', '0px'),
	overflowWrap: 'break-word',
});
