import { defineMessages } from 'react-intl-next';

export default defineMessages({
	closeLabel: {
		id: 'jsm.calendar.calendar-provider.more-link-popup.close',
		description:
			'Label for a button that closes a popup window showing all of the events on the selected day.',
		defaultMessage: 'Close',
	},
});
