import React, { type ReactNode } from 'react';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import PagesIcon from '@atlaskit/icon/core/pages';
import SendIcon from '@atlaskit/icon/core/send';
import Link from '@atlaskit/link';
import { Flex, Box, Text, xcss } from '@atlaskit/primitives';
import { IconButton } from '@atlaskit/button/new';
import CloseIcon from '@atlaskit/icon/core/close';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useHelpPanelLink } from '@atlassian/jira-help-panel-button/src/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { useRouterActions } from '@atlassian/react-resource-router';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
	fireUIAnalytics,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import {
	PACKAGE_NAME,
	TEAM_NAME,
	addEmailAccountArticleId,
	readMoreArticleId,
} from '../common/constants.tsx';
import useEmailRequestStatus from '../hooks/useEmailRequestStatus.tsx';
import { useSectionDismiss } from '../hooks/useSectionDismiss.tsx';
import messages from './messages.tsx';

const EmailRequestSectionMessage = () => {
	const { formatMessage } = useIntl();
	const { push } = useRouterActions();
	const openInProductHelp = useHelpPanelLink(true);
	const { data: projectContext } = useProjectContext();
	const { projectKey, isProjectAdmin = false } = projectContext ?? {};

	const { emailAddress, isEmailedRequestCreated, error, stopPolling } = useEmailRequestStatus(
		projectKey,
		isProjectAdmin,
	);

	const { isLoading, showSectionMessage, dismissSectionMessage } = useSectionDismiss(
		projectKey,
		'emailRequestIntegrationSectionMessageVisible',
	);

	const handleIPHOpenAndRedirection = (articleId: string, url?: string) => {
		openInProductHelp?.(articleId);
		if (url) {
			push(url);
		}
	};

	const handleDismissSectionMessage = (
		_e: React.MouseEvent<HTMLButtonElement>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		fireUIAnalytics(analyticsEvent, 'closeSectionMessage', {
			isEmailedRequestCreated,
		});
		stopPolling();
		dismissSectionMessage();
	};

	const mailtoLink = emailAddress
		? `mailto:${encodeURIComponent(emailAddress)}?subject=${encodeURIComponent(formatMessage(messages.defaultEmailSubject))}&body=${encodeURIComponent(formatMessage(messages.defaultEmailBody))}`
		: '#';

	const actions = [
		isEmailedRequestCreated ? (
			<SectionMessageAction
				key="add-email"
				onClick={(_e, analyticsEvent) => {
					fireUIAnalytics(analyticsEvent, 'addEmailAccount');
					handleIPHOpenAndRedirection(
						addEmailAccountArticleId,
						`/jira/servicedesk/projects/${projectKey}/settings/email`,
					);
				}}
			>
				{formatMessage(messages.addEmailAccountSectionMessageAction1)}
			</SectionMessageAction>
		) : (
			<SectionMessageAction
				key="send-email"
				href={mailtoLink}
				onClick={(_e, analyticsEvent) => {
					fireUIAnalytics(analyticsEvent, 'sendTestEmail');
				}}
			>
				<Flex xcss={sectionMessageActionStyle}>
					<SendIcon label={formatMessage(messages.sendEmailIconLabel)} />
					{formatMessage(messages.emailRequestSectionMessageAction)}
				</Flex>
			</SectionMessageAction>
		),
		isEmailedRequestCreated && (
			<SectionMessageAction
				key="add-email-2"
				onClick={(_e, analyticsEvent) => {
					fireUIAnalytics(analyticsEvent, 'readMoreEmailedRequest');
					handleIPHOpenAndRedirection(readMoreArticleId);
				}}
			>
				{formatMessage(messages.addEmailAccountSectionMessageAction2)}
			</SectionMessageAction>
		),
	].filter(Boolean);

	if (
		!projectKey ||
		!isProjectAdmin ||
		error ||
		isLoading ||
		!showSectionMessage ||
		!emailAddress ||
		(emailAddress === undefined && isEmailedRequestCreated === undefined) ||
		!expValEquals('jsm_2d_fo_core_actions', 'cohort', 'variation')
	) {
		return null;
	}

	return (
		<>
			<Box xcss={sectionMessageContainerStyle}>
				<SectionMessage
					title={formatMessage(
						isEmailedRequestCreated
							? messages.addEmailAccountSectionMessageTitle
							: messages.emailRequestSectionMessageTitle,
					)}
					appearance="discovery"
					icon={PagesIcon}
					actions={actions}
				>
					<Box xcss={closeButtonStyle}>
						<IconButton
							appearance="subtle"
							icon={CloseIcon}
							label={formatMessage(messages.closeSectionMessageIconLabel)}
							onClick={handleDismissSectionMessage}
						/>
					</Box>
					<Text as="p">
						{isEmailedRequestCreated ? (
							formatMessage(messages.addEmailAccountSectionMessageDescription)
						) : (
							<>
								{formatMessage(messages.emailRequestSectionMessageDescription, {
									email: emailAddress,
									link: (chunks: ReactNode) => (
										<Link
											href={`mailto:${emailAddress}`}
											onClick={(_e, analyticsEvent) => {
												fireUIAnalytics(analyticsEvent, 'emailAddress');
											}}
										>
											{chunks}
										</Link>
									),
								})}
							</>
						)}
					</Text>
				</SectionMessage>
			</Box>
			<FireScreenAnalytics
				attributes={{
					isEmailedRequestCreated,
				}}
			/>
		</>
	);
};

export const EmailRequestSectionMessageWithErrorBoundary = () => (
	<JSErrorBoundary
		id="email-request-section-message-error-boundary"
		packageName={PACKAGE_NAME}
		teamName={TEAM_NAME}
		fallback="unmount"
	>
		<ContextualAnalyticsData sourceType={SCREEN} sourceName={PACKAGE_NAME}>
			<EmailRequestSectionMessage />
		</ContextualAnalyticsData>
	</JSErrorBoundary>
);

const sectionMessageActionStyle = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	gap: 'space.075',
});

const closeButtonStyle = xcss({
	position: 'absolute',
	top: 'space.100',
	right: 'space.100',
});

const sectionMessageContainerStyle = xcss({
	position: 'relative',
	paddingBottom: 'space.300',
});

export default EmailRequestSectionMessageWithErrorBoundary;
