// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type ComponentType } from 'react';
import { styled } from '@compiled/react';
import isFunction from 'lodash/isFunction';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type {
	ColumnId,
	ColumnTitleType,
	HeaderComponentType,
} from '../../../../../../../../model/columns/index.tsx';
import type { Optional } from '../../../../../../../../model/optional/index.tsx';
import { ellipsis } from '../../../../../../../common/styles/index.tsx';
import { getWidth } from '../../../../../../common/styled.tsx';

// we only show the coloured top border for child columns that themselves are no parents
// @ts-expect-error - TS7006 - Parameter 'isChild' implicitly has an 'any' type. | TS7006 - Parameter 'isParent' implicitly has an 'any' type.
const hasChildColumnAppearance = (isChild, isParent) => isChild && !isParent;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{ isFirstColumn: boolean; width: number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	width: ({ isFirstColumn, width }) => getWidth({ width, isFirstColumn }),
	flex: '0 0 auto',
	display: 'flex',
	position: 'relative',
	boxSizing: 'border-box',
	paddingTop: 0,
	paddingRight: token('space.100', '8px'),
	paddingBottom: 0,
	paddingLeft: token('space.100', '8px'),
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		backgroundColor: colors.N30,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.div<{ isChild: boolean; isParent: boolean }>({
	flex: '0 1 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	font: ({ isChild, isParent }) =>
		hasChildColumnAppearance(isChild, isParent)
			? token('font.heading.xxsmall')
			: token('font.heading.xsmall'),
	lineHeight: '34px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	...ellipsis,
});

type Props = {
	id: ColumnId;
	title: ColumnTitleType;
	HeaderComponent: HeaderComponentType | undefined;
	tooltip: Optional<string | Element>;
	width: number;
	isChild: boolean;
	isParent: boolean;
	isFirstColumn: boolean;
	isCoreColumn: boolean;
	ChildBar: ComponentType<{
		columnId: ColumnId;
	}>;
	ColumnChildOperationIndicator: ComponentType<{
		columnId: ColumnId;
	}>;
	ColumnSortOperationIndicator: ComponentType<{
		columnId: ColumnId;
	}>;
};

// eslint-disable-next-line jira/react/no-class-components
class DumbColumn extends PureComponent<Props> {
	renderChildOperationIndicator() {
		const { id, ColumnChildOperationIndicator } = this.props;
		return <ColumnChildOperationIndicator columnId={id} />;
	}

	renderSortingOperationIndicator() {
		const { id, ColumnSortOperationIndicator } = this.props;

		return <ColumnSortOperationIndicator columnId={id} />;
	}

	renderChildBar() {
		const { isChild, isParent, ChildBar, id } = this.props;

		if (hasChildColumnAppearance(isChild, isParent)) {
			return <ChildBar columnId={id} />;
		}
		return null;
	}

	renderTitle() {
		const { title } = this.props;

		if (!isFunction(title)) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return title as string;
		}

		const InnerTitle = title;
		return <InnerTitle />;
	}

	render() {
		const { id, title, width, tooltip, isChild, isParent, HeaderComponent, isFirstColumn } =
			this.props;

		return HeaderComponent ? (
			<HeaderComponent columnId={id} title={title} width={width} />
		) : (
			<Wrapper
				// @ts-expect-error - TS2322 - Type 'Optional<string | Element>' is not assignable to type 'string | undefined'.
				title={tooltip}
				width={width}
				isFirstColumn={isFirstColumn}
			>
				{this.renderChildOperationIndicator()}
				<Title isChild={isChild} isParent={isParent}>
					{this.renderTitle()}
				</Title>
				{this.renderSortingOperationIndicator()}
				{this.renderChildBar()}
			</Wrapper>
		);
	}
}

export default DumbColumn;
