import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	inprogressLabel: {
		id: 'calendar.calendar-renderer.event-renderer.issue-renderer.event-icon.inprogress-label',
		defaultMessage: 'In Progress',
		description:
			'Label for the in progress icon shown on Jira calendar view events that are in progress',
	},
	overdueLabel: {
		id: 'calendar.calendar-renderer.event-renderer.issue-renderer.event-icon.overdue-label',
		defaultMessage: 'Overdue',
		description:
			'Label for the overdue icon shown on Jira calendar view events that have a due-date in the past',
	},
	doneLabel: {
		id: 'calendar.calendar-renderer.event-renderer.issue-renderer.event-icon.done-label',
		defaultMessage: 'Done',
		description:
			'Label for the done icon shown on Jira calendar view events that are already resolved',
	},
});
