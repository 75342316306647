import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	errorTitle: {
		id: 'calendar.controllers.use-schedule-calendar-issue-mutation.error-title',
		defaultMessage: 'Something went wrong',
		description:
			'Title for the error message displayed when the calendar fails to update issue dates',
	},
	failedToScheduleIssue: {
		id: 'calendar.controllers.use-schedule-calendar-issue-mutation.failed-to-schedule-issue',
		defaultMessage: 'Failed to schedule calendar issue.',
		description:
			'Error message displayed when the calendar fails to update issues on drag-and-drop.',
	},
	scheduleIssuePermissionErrorTitle: {
		id: 'calendar.controllers.use-schedule-calendar-issue-mutation.schedule-issue-permission-error-title',
		defaultMessage: "{issueKey} couldn't be moved",
		description:
			'Title for the error message displayed when the calendar fails to update issue dates due to schedule permissions',
	},
	scheduleIssuePermissionErrorMessage: {
		id: 'calendar.controllers.use-schedule-calendar-issue-mutation.schedule-issue-permission-error-message',
		defaultMessage: "The item isn't editable, or you don't have permission to move it.",
		description:
			'Error message displayed when the calendar fails to update issues on drag-and-drop due to schedule permissions.',
	},
	issueHiddenTitle: {
		id: 'calendar.controllers.use-schedule-calendar-issue-mutation.issue-hidden-title',
		defaultMessage: "Issue isn't visible",
		description:
			'Title for the error message displayed when the scheduled issue is hidden on calendar',
	},
	issueHiddenBody: {
		id: 'calendar.controllers.use-schedule-calendar-issue-mutation.issue-hidden-body',
		defaultMessage: 'It is currently hidden on your calendar because of your view settings.',
		description: 'Error message displayed when the scheduled issue is hidden on calendar',
	},
	failedToScheduleIssueIssueTermRefresh: {
		id: 'calendar.controllers.use-schedule-calendar-issue-mutation.failed-to-schedule-issue-issue-term-refresh',
		defaultMessage: 'Failed to schedule calendar work items.',
		description:
			'Error message displayed when the calendar fails to update issues on drag-and-drop.',
	},
	issueHiddenTitleIssueTermRefresh: {
		id: 'calendar.controllers.use-schedule-calendar-issue-mutation.issue-hidden-title-issue-term-refresh',
		defaultMessage: "Work item isn't visible",
		description:
			'Title for the error message displayed when the scheduled issue is hidden on calendar',
	},
});
