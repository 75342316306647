import React from 'react';
import { useFragment, graphql } from 'react-relay';
import type { fieldValueLabel_softwareFiltersPopup$key } from '@atlassian/jira-relay/src/__generated__/fieldValueLabel_softwareFiltersPopup.graphql';
import { DefaultFieldValueLabel } from './default-field-value-label/index.tsx';
import { GroupFieldValueLabel } from './group-field-value-label/index.tsx';
import { LabelFieldValueLabel } from './label-field-value-label/index.tsx';
import { PriorityFieldValueLabel } from './priority-field-value-label/index.tsx';
import { ProjectFieldValueLabel } from './project-field-value-label/index.tsx';
import { StatusFieldValueLabel } from './status-field-value-label/index.tsx';
import { UserFieldValueLabel } from './user-field-value-label/index.tsx';

interface FieldValueLabelProps {
	/**
	 * The field value fragment to render.
	 */
	fieldValueRef: fieldValueLabel_softwareFiltersPopup$key;
	/**
	 * When in `popup` mode a compact icon representation of the label will be rendered.
	 * When in `dropdown` mode a full-line label will be rendered, usually represented
	 * as an icon plus a label.
	 */
	mode: 'dropdown' | 'popup';
}

/**
 * This is a "JiraJqlFieldValue" label renderer.
 *
 * It can render all issue field values with a fallback label renderer.
 *
 * The following field value types are supported:
 *
 * * JiraJqlStatusFieldValue
 * * JiraJqlPriorityFieldValue
 * * JiraJqlUserFieldValue
 * * JiraJqlProjectFieldValue
 * * JiraJqlLabelFieldValue
 * * JiraJqlGroupFieldValue
 *
 * The following could be supported with minor effort and will current
 * be rendered using a fallback that shows only the displayName of the option:
 *
 * * JiraJqlSprintFieldValue
 * * JiraJqlVersionFieldValue
 * * JiraJqlIssueTypeFieldValue
 * * JiraJqlResolutionFieldValue
 * * JiraJqlStatusCategoryFieldValue
 * * JiraJqlComponentFieldValue
 * * JiraJqlOptionFieldValue
 * * JiraJqlIssueFieldValue
 *
 * The following would require API changes to be supported and likely aren't part
 * of this "label" component's scope:
 *
 * * JiraJqlCascadingOptionFieldValue
 * * JiraJqlPlainTextFieldValue
 */
export function FieldValueLabel({ fieldValueRef, mode }: FieldValueLabelProps) {
	const data = useFragment(
		graphql`
			fragment fieldValueLabel_softwareFiltersPopup on JiraJqlFieldValue {
				__typename

				...defaultFieldValueLabel_softwareFiltersPopup

				... on JiraJqlUserFieldValue {
					...userFieldValueLabel_softwareFiltersPopup
				}

				... on JiraJqlPriorityFieldValue {
					...priorityFieldValueLabel_softwareFiltersPopup
				}

				... on JiraJqlProjectFieldValue {
					...projectFieldValueLabel_softwareFiltersPopup
				}

				... on JiraJqlStatusFieldValue {
					...statusFieldValueLabel_softwareFiltersPopup
				}

				... on JiraJqlLabelFieldValue {
					...labelFieldValueLabel_softwareFiltersPopup
				}

				... on JiraJqlGroupFieldValue {
					...groupFieldValueLabel_softwareFiltersPopup
				}
			}
		`,
		fieldValueRef,
	);

	switch (data.__typename) {
		case 'JiraJqlUserFieldValue': {
			return <UserFieldValueLabel fieldValueRef={data} mode={mode} />;
		}
		case 'JiraJqlPriorityFieldValue': {
			return <PriorityFieldValueLabel fieldValueRef={data} mode={mode} />;
		}
		case 'JiraJqlProjectFieldValue': {
			return <ProjectFieldValueLabel fieldValueRef={data} mode={mode} />;
		}
		case 'JiraJqlStatusFieldValue': {
			return <StatusFieldValueLabel fieldValueRef={data} mode={mode} />;
		}
		case 'JiraJqlLabelFieldValue': {
			return <LabelFieldValueLabel fieldValueRef={data} />;
		}
		case 'JiraJqlGroupFieldValue': {
			return <GroupFieldValueLabel fieldValueRef={data} mode={mode} />;
		}
		default: {
			return <DefaultFieldValueLabel fieldValueRef={data} mode={mode} />;
		}
	}
}
