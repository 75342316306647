import { useMemo } from 'react';
import type { ServiceDeskQueuePage } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import {
	isExtensionVisible,
	isExtensionHiddenByAppAccessRules,
} from '@atlassian/jira-forge-ui-utils/src/utils/extension/index.tsx';
import { forgeServiceDeskQueueModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { useResourceWithCustomRouterContext } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/use-resource-with-custom-router-context/index.tsx';
import { useForgeItemStack } from '../../common/utils/find-item-stack/index.tsx';
import type { UseNavigationResourceReturnWithStackAndAN } from '../types.tsx';

export const useServiceDeskQueueForgeApps = (
	routeName?: string,
	parentStack?: string[],
	projectKey?: string,
): UseNavigationResourceReturnWithStackAndAN<ServiceDeskQueuePage[]> => {
	const { data, loading, error } = useResourceWithCustomRouterContext(
		forgeServiceDeskQueueModuleResource,
		projectKey
			? {
					matchParams: { projectKey },
				}
			: undefined,
	);

	const serviceDeskQueueData = useMemo(() => data?.filter?.(isExtensionVisible) || null, [data]);
	const accessNarrowedData = useMemo(
		() => data?.filter?.(isExtensionHiddenByAppAccessRules) || null,
		[data],
	);
	const stack = useForgeItemStack(serviceDeskQueueData, routeName, parentStack);

	return useMemo<UseNavigationResourceReturnWithStackAndAN<ServiceDeskQueuePage[]>>(
		() => ({
			data: serviceDeskQueueData,
			accessNarrowedData,
			loading,
			error,
			stack,
		}),
		[serviceDeskQueueData, accessNarrowedData, loading, error, stack],
	);
};

export const useServiceDeskQueueForgeAppsNav4 = (
	projectKey: string,
): UseNavigationResourceReturnWithStackAndAN<ServiceDeskQueuePage[]> =>
	useServiceDeskQueueForgeApps(undefined, undefined, projectKey);
