import { differenceInCalendarDays, format, parse } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import addDays from 'date-fns/addDays';
import startOfDay from 'date-fns/startOfDay';
import { PLACEHOLDER_EVENT_ID } from '@atlassian/calendar';
import { fg } from '@atlassian/jira-feature-gating';
import type { Optional } from '../../common/types.tsx';
import type {
	CalendarDayStart,
	CalendarViewRange,
} from '../../controllers/calendar-store/types.tsx';
import { OPTIMISTIC_ISSUE_ID_PREFIX } from '../../controllers/use-create-calendar-issue-mutation/index.tsx';
import type {
	JiraCalendarExtendedEvent,
	IssueCalendarEvent,
	VersionCalendarEvent,
	SprintCalendarEvent,
	CalendarEventConnections,
} from './types.tsx';

export const isIssueCalendarEvent = (
	event: JiraCalendarExtendedEvent,
): event is IssueCalendarEvent => event?.extendedProps?.type === 'issue';

export const isVersionCalendarEvent = (
	event: JiraCalendarExtendedEvent,
): event is VersionCalendarEvent => event?.extendedProps?.type === 'version';

export const isSprintCalendarEvent = (
	event: JiraCalendarExtendedEvent,
): event is SprintCalendarEvent => event?.extendedProps?.type === 'sprint';

export function getFirstDay({ weekStartsOn }: { weekStartsOn: 'monday' | 'sunday' | 'saturday' }) {
	if (weekStartsOn === 'monday') {
		return 1;
	}
	if (weekStartsOn === 'saturday') {
		return 6;
	}

	return 0;
}

export function getScrollTime(dayStartsAt: CalendarDayStart) {
	const parsedDate = parse(dayStartsAt, 'h:mm a', new Date()); // Parse 12-hour time with AM/PM
	return format(parsedDate, 'HH:mm:ss'); // Format to 24-hour time (HH:mm:ss)
}

export function getTotalPerPage(connection: Optional<CalendarEventConnections>) {
	return connection?.edges?.length ?? 0;
}

export function hasConnectionPages(connection: Optional<CalendarEventConnections>) {
	return (
		(connection?.pageInfo?.hasNextPage ?? false) || (connection?.pageInfo?.hasPreviousPage ?? false)
	);
}

export function isStartOfDay(time: Date): boolean {
	return format(time, 'HH:mm:ss') === '00:00:00';
}

export function isAllDayEvent(
	startDateTime: string | undefined | null,
	endDateTime: string | undefined | null,
	userTimeZone: string,
	viewRange: CalendarViewRange,
): boolean {
	// if event is missing startDatetime, then we presume it is an all-day event
	// Always return true for month view, needs to be true to correctly show resize handles for month view
	if (
		!fg('jsd_shield_jsm_calendar_weekly_view') ||
		viewRange === 'month' ||
		!startDateTime ||
		!endDateTime
	) {
		return true;
	}

	const start = utcToZonedTime(startDateTime ?? endDateTime, userTimeZone);
	const end = utcToZonedTime(endDateTime, userTimeZone);

	return isStartOfDay(start) && isStartOfDay(end);
}

export function eventStartAndEndDates(
	startDateFormatted: Date,
	endDateFormatted: Date,
	allDay: boolean,
) {
	if (!fg('jsd_shield_jsm_calendar_weekly_view')) {
		return {
			start: startOfDay(startDateFormatted),
			end: startOfDay(addDays(endDateFormatted, 1)),
		};
	}

	const startDateInclusive = allDay ? startOfDay(startDateFormatted) : startDateFormatted;
	const exclusiveEnd = allDay ? startOfDay(addDays(endDateFormatted, 1)) : endDateFormatted;

	return {
		start: startDateInclusive,
		end: exclusiveEnd,
	};
}

type JiraCalendarExtendedEventPartial = Pick<
	JiraCalendarExtendedEvent,
	'id' | 'start' | 'end' | 'title'
>;

const isJiraCalendarExtendedEventPartial = (
	event: unknown,
): event is JiraCalendarExtendedEventPartial =>
	!!event &&
	typeof event === 'object' &&
	'id' in event &&
	'start' in event &&
	'end' in event &&
	'title' in event;

export const isPlaceholderCalendarEvent = (
	event: JiraCalendarExtendedEventPartial,
): event is IssueCalendarEvent => event?.id === PLACEHOLDER_EVENT_ID;

export const isOptimisticCalendarEvent = (
	event: JiraCalendarExtendedEventPartial,
): event is IssueCalendarEvent => event?.id.includes(OPTIMISTIC_ISSUE_ID_PREFIX);

export const isVersionEvent = (
	event: JiraCalendarExtendedEventPartial,
): event is VersionCalendarEvent => event?.id.includes('version');

export const isSprintEvent = (
	event: JiraCalendarExtendedEventPartial,
): event is SprintCalendarEvent => event?.id.includes('sprint');

const getCalendarEventLengthInCalendarDays = (event: JiraCalendarExtendedEventPartial) =>
	differenceInCalendarDays(event.end, event.start);

// Requires evaluation at runtime due to FullCalendar static type checking unable to determine types for sorting.
export function calendarEventOrderSort(a: unknown, b: unknown) {
	if (!isJiraCalendarExtendedEventPartial(a) || !isJiraCalendarExtendedEventPartial(b)) return 0;

	if (fg('calendar-event-sorting')) {
		// Move up if the item is a placeholder and comparison item is not.
		if (isPlaceholderCalendarEvent(a) && !isPlaceholderCalendarEvent(b)) {
			return -1;
		}

		// Move down if the item is not a placeholder and comparison item is.
		if (isPlaceholderCalendarEvent(b) && !isPlaceholderCalendarEvent(a)) {
			return 1;
		}

		if (isSprintEvent(a) && !isSprintEvent(b)) {
			return -1;
		}

		if (isSprintEvent(b) && !isSprintEvent(a)) {
			return 1;
		}

		// If this is a release and comparison item is a multi-day issue or optimistic item, move down, otherwise move up.
		if (isVersionEvent(a) && !isVersionEvent(b)) {
			return getCalendarEventLengthInCalendarDays(b) > 1 ? 1 : -1;
		}

		// If this is a multi-day issue and comparison item is a release or optimistic item, move up, otherwise move down.
		if (isVersionEvent(b) && !isVersionEvent(a)) {
			return getCalendarEventLengthInCalendarDays(a) > 1 ? -1 : 1;
		}

		// Sort multi-day issues by length descending.
		if (
			getCalendarEventLengthInCalendarDays(a) > 1 ||
			getCalendarEventLengthInCalendarDays(b) > 1
		) {
			const daysDifference =
				getCalendarEventLengthInCalendarDays(b) - getCalendarEventLengthInCalendarDays(a);
			if (daysDifference < 0) {
				return -1;
			}
			if (daysDifference > 0) {
				return 1;
			}
		}

		// Sort issue by start ascending.
		if (a.start && b.start) {
			const timeDifference = new Date(a.start).getTime() - new Date(b.start).getTime();
			if (timeDifference < 0) {
				return -1;
			}
			if (timeDifference > 0) {
				return 1;
			}
		}

		// Move optimistic item up if comparison item is not optimistic.
		if (isOptimisticCalendarEvent(a) && !isOptimisticCalendarEvent(b)) {
			return -1;
		}

		// Move non-optimistic item down if comparison item is an optimistic item.
		if (isOptimisticCalendarEvent(b) && !isOptimisticCalendarEvent(a)) {
			return 1;
		}

		// Sort issue by title descending.
		if (a.title && b.title) {
			// Numeric option required as localeCompare sorts in lexicographical order by default.
			return a.title.localeCompare(b.title, undefined, { numeric: true });
		}
	} else {
		// Move up if the item is a placeholder and comparison item is not.
		if (isPlaceholderCalendarEvent(a) && !isPlaceholderCalendarEvent(b)) {
			return -1;
		}

		// Move down if the item is not a placeholder and comparison item is.
		if (isPlaceholderCalendarEvent(b) && !isPlaceholderCalendarEvent(a)) {
			return 1;
		}

		// Move optimistic item up if comparison item is not optimistic.
		if (isOptimisticCalendarEvent(a) && !isOptimisticCalendarEvent(b)) {
			return -1;
		}

		// Move non-optimistic item down if comparison item is an optimistic item.
		if (isOptimisticCalendarEvent(b) && !isOptimisticCalendarEvent(a)) {
			return 1;
		}
	}

	return 0;
}

/**
 * Default event order for calendar events. https://fullcalendar.io/docs/eventOrder
 * Use in weekly view
 * - The earliest starting event is always on the bottom of the stack (most to the left), with the later ones on top
 * - When events start at the same time, the longest event is also at the start/bottom of the stack
 */
export const DEFAULT_EVENT_ORDER = 'start,-duration,allDay,title';

export function getCalendarAspectRatio(containerRect: DOMRect | null): number {
	if (!containerRect?.width || !containerRect?.height) {
		return 1;
	}

	return containerRect.width / containerRect.height;
}
