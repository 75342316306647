import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { performGetRequest, performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';

export const checkEmailRequestStatus = async (projectKey: string) =>
	fetchJson(`/rest/servicedesk/1/servicedesk/${projectKey}/incomingemail`);

const getUrl = (projectKeyOrId: string | undefined, propertyKey: string) =>
	`/rest/api/3/project/${projectKeyOrId}/properties/${propertyKey}`;

export const dismissSectionMessageRequest =
	(projectKeyOrId: string | undefined, messageKey: string) => () =>
		performPutRequest(getUrl(projectKeyOrId, messageKey), {
			body: JSON.stringify(false),
		});

export const getProjectProperty = (projectKey: string | undefined, key: string) =>
	performGetRequest(`/rest/api/3/project/${projectKey}/properties/${key}`);
