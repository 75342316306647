import React from 'react';
import { graphql, useFragment } from 'react-relay';
import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItem, type DropdownItemProps } from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { actionsMenu_ReleaseFlyout$key } from '@atlassian/jira-relay/src/__generated__/actionsMenu_ReleaseFlyout.graphql';
import { messages } from './messages.tsx';
import { getTimelineUrl } from './utils.tsx';

export type ActionsMenuProps = {
	children: React.ReactElement<ActionMenuItemProps>[] | React.ReactElement<ActionMenuItemProps>;
};

export const ActionsMenu = ({ children }: ActionsMenuProps) => {
	const { formatMessage } = useIntl();

	return (
		<DropdownMenu
			testId="release-flyout.ui.actions-menu.dropdown-menu"
			placement="bottom-start"
			trigger={({ triggerRef, ...triggerProps }) => (
				<Button
					{...triggerProps}
					testId="release-flyout.ui.actions-menu.button"
					aria-label={formatMessage(messages.menuButton)}
					ref={triggerRef}
					appearance="subtle"
					iconAfter={<MoreIcon label="" size="small" />}
				/>
			)}
		>
			{children}
		</DropdownMenu>
	);
};

export type ActionMenuItemProps = DropdownItemProps;

export const ActionMenuItem = ({ children, ...props }: ActionMenuItemProps) => (
	<DropdownItem {...props}>{children}</DropdownItem>
);

type ActionsMenuPropsOld = {
	versionRef: actionsMenu_ReleaseFlyout$key;
	onEditRelease: () => void;
	onClosePopup: () => void;
};

export const ActionsMenuOld = ({
	versionRef,
	onEditRelease,
	onClosePopup,
}: ActionsMenuPropsOld) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	/* eslint-disable @atlassian/relay/graphql-naming */
	const data = useFragment(
		graphql`
			fragment actionsMenu_ReleaseFlyout on JiraVersion {
				versionId
				canEdit
			}
		`,
		versionRef,
	);
	/* eslint-enable @atlassian/relay/graphql-naming */

	const onViewRelease = () => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.open(getTimelineUrl(), '_blank');
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'releaseViewed',
			}),
			'releaseFlyout',
		);
		onClosePopup();
	};

	const onViewFilteredIssues = () => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.open(getTimelineUrl(data.versionId), '_blank');
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'viewFilteredIssues',
			}),
			'releaseFlyout',
		);
		onClosePopup();
	};

	const onEdit = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'versionEdit',
			}),
			'releaseFlyout',
		);
		onEditRelease();
		onClosePopup();
	};

	return (
		<DropdownMenu
			testId="release-flyout.ui.actions-menu.dropdown-menu"
			placement="bottom-start"
			trigger={({ triggerRef, ...triggerProps }) => (
				<Button
					{...triggerProps}
					testId="release-flyout.ui.actions-menu.button"
					aria-label={formatMessage(messages.menuButton)}
					ref={triggerRef}
					appearance="subtle"
					iconAfter={<MoreIcon label="" size="small" />}
				/>
			)}
		>
			<DropdownItem onClick={onViewRelease}>{formatMessage(messages.viewRelease)}</DropdownItem>
			{/* TODO: Check if filtering issue by release is available */}
			<DropdownItem onClick={onViewFilteredIssues}>
				{formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.filterIssuesByReleaseIssueTermRefresh
						: messages.filterIssuesByRelease,
				)}
			</DropdownItem>
			<DropdownItem isDisabled={!data.canEdit} onClick={onEdit}>
				{formatMessage(messages.editRelease)}
			</DropdownItem>
		</DropdownMenu>
	);
};
