import { useCallback } from 'react';
import { useRelayEnvironment, type RefetchFnDynamic } from 'react-relay';
import { createOperationDescriptor, fetchQuery, type Subscription } from 'relay-runtime';
import { setInteractionError } from '@atlaskit/react-ufo/set-interaction-error';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { startCaptureGraphQlErrors } from '@atlassian/jira-relay-errors/src/index.tsx';
import type { calendarRendererIssues_jira$key } from '@atlassian/jira-relay/src/__generated__/calendarRendererIssues_jira.graphql';
import ISSUES_REFETCH_QUERY, {
	type calendarRendererIssues_jira_refetchQuery,
} from '@atlassian/jira-relay/src/__generated__/calendarRendererIssues_jira_refetchQuery.graphql';
import { startCapturingTraceIds, stopCapturingTraceIds } from '@atlassian/relay-traceid';
import { getGraphQLErrors } from '../../common/utils/agg-errors/index.tsx';
import { useCalendarActions } from '../calendar-store/index.tsx';
import { useCalendarExperience } from '../use-calendar-experience/index.tsx';
import { useLogger } from '../use-logger/index.tsx';
import { useRefetchIssuesCalendarVariables } from '../use-refetch-issues-variables/index.tsx';
import { messages } from './messages.tsx';

type CalendarIssuesRefetcher = {
	refetchIssuesWithoutSuspense: () => Subscription;
};

const operationName = ISSUES_REFETCH_QUERY.params.name;

/**
 * Custom hook that returns a refetch function for calendar issues that does not suspend.
 *
 * @param refetch - The refetch function for the calendar issues query.
 * @returns An object containing the refetch function for calendar issues.
 */
export function useRefetchCalendarIssues({
	refetch,
}: {
	refetch: RefetchFnDynamic<
		calendarRendererIssues_jira_refetchQuery,
		calendarRendererIssues_jira$key
	>;
}): CalendarIssuesRefetcher {
	const environment = useRelayEnvironment();
	const { variables } = useRefetchIssuesCalendarVariables();

	const { refetchCalendarIssuesExperience } = useCalendarExperience();
	const { setIsLoading } = useCalendarActions();

	const { showFlag, dismissFlag } = useFlagService();
	const { formatMessage } = useIntl();
	const { logError } = useLogger();

	const refetchIssuesWithoutSuspense = useCallback(() => {
		setIsLoading(true);

		const queryId = startCaptureGraphQlErrors();
		startCapturingTraceIds(operationName);
		refetchCalendarIssuesExperience.start();

		const operationAvailability = environment.check(
			createOperationDescriptor(ISSUES_REFETCH_QUERY, variables),
		);
		if (operationAvailability?.status === 'available') {
			refetch(variables, { fetchPolicy: 'store-only' });
		}

		const subscription = fetchQuery(environment, ISSUES_REFETCH_QUERY, variables, {
			fetchPolicy: 'network-only',
		}).subscribe({
			complete() {
				setIsLoading(false);

				const graphQLError = getGraphQLErrors(queryId, operationName);
				if (graphQLError) {
					logError(
						'fetch.calendar.issues.failure',
						'Failed to fetch calendar issues',
						graphQLError,
					);

					if (fg('calendar_handles_graphql_error')) {
						setInteractionError(refetchCalendarIssuesExperience.id, {
							name: 'fetch.calendar.issues.failure',
							errorMessage: graphQLError.message,
						});
						refetchCalendarIssuesExperience.failure();
						showFlag({
							id: 'jira-calendar-issues-fetch-error',
							title: formatMessage(messages.errorTitle),
							description: formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.failedToFetchDataErrorIssueTermRefresh
									: messages.failedToFetchDataError,
							),
							error: graphQLError,
							type: 'error',
						});
					} else {
						refetchCalendarIssuesExperience.success();
					}
				} else {
					refetchCalendarIssuesExperience.success();
				}

				stopCapturingTraceIds(operationName);
			},
			error(error: Error) {
				setIsLoading(false);

				setInteractionError(refetchCalendarIssuesExperience.id, {
					name: 'fetch.calendar.issues.failure',
					errorMessage: `Failed to fetch calendar issues. Error: ${error}`,
				});
				refetchCalendarIssuesExperience.failure();

				logError('fetch.calendar.issues.failure', 'Failed to fetch calendar issues', error);

				showFlag({
					id: 'jira-calendar-issues-fetch-error',
					title: formatMessage(messages.errorTitle),
					description: formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.failedToFetchDataErrorIssueTermRefresh
							: messages.failedToFetchDataError,
					),
					error,
					type: 'error',
				});
			},
			next() {
				refetch(variables, { fetchPolicy: 'store-only' });

				dismissFlag({
					id: 'jira-calendar-issues-fetch-error',
					command: 'DISMISS',
				});
				stopCapturingTraceIds(operationName);
			},
		});

		return subscription;
	}, [
		dismissFlag,
		environment,
		formatMessage,
		refetch,
		refetchCalendarIssuesExperience,
		setIsLoading,
		showFlag,
		variables,
		logError,
	]);

	return { refetchIssuesWithoutSuspense };
}
