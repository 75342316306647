import React, { useCallback, useMemo, type MouseEvent } from 'react';
import Link from '@atlassian/jira-common-analytics-v2-wrapped-components/src/link.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, type UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages.tsx';

const SCHEDULE_ERROR_FLAG_ID = 'calendar.common.ui.error-flags.unable-to-schedule';

const useScheduleErrorFlag = (): { showErrorFlag: () => void } => {
	const { showFlag, dismissFlag } = useFlagService();
	const { formatMessage } = useIntl();

	const onClickHandler = useCallback(
		(_: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			dismissFlag({
				id: SCHEDULE_ERROR_FLAG_ID,
				command: 'DISMISS',
			});
			fireUIAnalytics(analyticsEvent, 'calendarConfigLinkClicked');
		},
		[dismissFlag],
	);

	const showErrorFlag = useCallback(() => {
		showFlag({
			id: SCHEDULE_ERROR_FLAG_ID,
			type: 'error',
			title: formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.unableToScheduleTitleIssueTermRefresh
					: messages.unableToScheduleTitle,
			),
			description: [
				fg('jira-issue-terminology-refresh-m3')
					? messages.unableToScheduleDescriptionIssueTermRefresh
					: messages.unableToScheduleDescription,
				{
					a: (str) => (
						<Link
							onClick={onClickHandler}
							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							href={`${window.location.pathname.split('/calendar')[0]}?config=dateFields`}
						>
							{str}
						</Link>
					),
				},
			],
			isAutoDismiss: false,
		});
	}, [formatMessage, onClickHandler, showFlag]);

	return useMemo(() => ({ showErrorFlag }), [showErrorFlag]);
};

export { useScheduleErrorFlag };
