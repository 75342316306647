import {
	fireTrackAnalytics,
	type AnalyticsEvent,
	type AnalyticsAttributes,
} from '@atlassian/jira-product-analytics-bridge';
import { ANALYTICS_ISSUE_UPDATED_TRACK_EVENT_NAME } from '../../constants.tsx';

/**
 * Fires the analytics event for an updated issue.
 *
 * This function must be called whenever an issue is updated, as it is a critical event for tracking broader metrics within the Jira organization.
 *
 * @param analyticsEvent - The analytics event to be fired.
 * @param issueId - The ID of the issue being updated.
 * @param attributes - Additional attributes for the analytics event.
 */
export function fireIssueUpdatedAnalytics(
	analyticsEvent: AnalyticsEvent,
	issueId: string | undefined,
	attributes?: AnalyticsAttributes,
) {
	const clonedAnalyticsEvent = analyticsEvent?.clone();
	if (!clonedAnalyticsEvent || !issueId) {
		return;
	}

	// Adds the issue ID to the analytics context of the event being emitted as objectId for company track events metrics impact analysis.
	// Before modifying this, please consult with the analytics team.
	clonedAnalyticsEvent?.context?.push({
		objectId: issueId,
		objectType: 'issue',
	});

	fireTrackAnalytics(
		clonedAnalyticsEvent,
		ANALYTICS_ISSUE_UPDATED_TRACK_EVENT_NAME,
		issueId,
		attributes ?? {},
	);
}
