/**
 * @generated SignedSource<<0d32b17945c09d05fd2a580fa34c0728>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraSprintState = "ACTIVE" | "CLOSED" | "FUTURE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type ui_SprintFlyout$data = {
  readonly completionDate: AGG$DateTime | null | undefined;
  readonly endDate: AGG$DateTime | null | undefined;
  readonly goal: string | null | undefined;
  readonly name: string | null | undefined;
  readonly startDate: AGG$DateTime | null | undefined;
  readonly state: JiraSprintState | null | undefined;
  readonly " $fragmentType": "ui_SprintFlyout";
};
export type ui_SprintFlyout$key = {
  readonly " $data"?: ui_SprintFlyout$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_SprintFlyout">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_SprintFlyout",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "state"
    },
    {
      "kind": "ScalarField",
      "name": "goal"
    },
    {
      "kind": "ScalarField",
      "name": "startDate"
    },
    {
      "kind": "ScalarField",
      "name": "endDate"
    },
    {
      "kind": "ScalarField",
      "name": "completionDate"
    }
  ],
  "type": "JiraSprint"
};

(node as any).hash = "f5a1ab4835bb455f9632293309a99b98";

export default node;
