import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Inline } from '@atlaskit/primitives';
import BreadcrumbRenderer from '@atlassian/jira-breadcrumbs/src/breadcrumb-renderer/index.tsx';
import PageHeader from '@atlassian/jira-common-components-page-header/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { headerAddPeopleButton_calendarQuery } from '@atlassian/jira-relay/src/__generated__/headerAddPeopleButton_calendarQuery.graphql';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { AddPeopleButton } from '../../common/add-people-button/index.tsx';
import { useCalendarCapabilities } from '../../common/controllers/capabilities-provider/index.tsx';
import { FeedbackButton } from '../../common/feedback-collector-button/index.tsx';
import { useCalendarComponents } from '../../controllers/calendar-components-provider/index.tsx';
import { useCalendarScope } from '../../controllers/calendar-store/index.tsx';
import { AdditionalMenuItems } from './additional-menu-items/index.tsx';
import { messages } from './messages.tsx';
import { CalendarShareButton } from './share-button/index.tsx';

export function Header() {
	const { formatMessage } = useIntl();
	const { hasAddPeopleButton, hasShareButton } = useCalendarCapabilities();
	const { additionalMenuItems, renderFeedbackButton } = useCalendarComponents();
	const scope = useCalendarScope();
	const { cloudId } = useTenantContext();

	const breadcrumbs = <BreadcrumbRenderer />;

	const projectKeys = scope.type === 'board' || scope.type === 'project' ? scope?.projectKeys : [];
	const projectKey = projectKeys?.[0] ?? '';
	const hasProjectKey = projectKeys?.length > 0;

	/**
	 * This is a temporary solution to avoid breaking the existing AddPeopleButton component.
	 * The use of useLazyLoadQuery will be replace for useFrgament with queryRef directly when
	 * via TNK-1308.
	 * The main goal is to remove depdency of projectKey as main graphql variable for calendar API
	 */
	const data = useLazyLoadQuery<headerAddPeopleButton_calendarQuery>(
		graphql`
			query headerAddPeopleButton_calendarQuery(
				$cloudId: ID!
				$projectKey: String!
				$hasProjectKey: Boolean!
			) {
				jira {
					jiraProjectByKey(cloudId: $cloudId, key: $projectKey) @include(if: $hasProjectKey) {
						projectId
						key
						name
					}
				}
			}
		`,
		{ cloudId, projectKey, hasProjectKey },
		{ fetchPolicy: 'store-or-network' },
	);

	const { projectId, key, name } = data?.jira?.jiraProjectByKey ?? {};

	function renderActions() {
		return (
			<Inline space="space.100">
				<FeedbackButton renderFeedbackButton={renderFeedbackButton} scope={scope} />
				{hasAddPeopleButton && projectId && key && name ? (
					<AddPeopleButton projectId={projectId} jiraProjectKey={key} name={name} />
				) : null}
				{hasShareButton ? <CalendarShareButton /> : null}
				{additionalMenuItems.length > 0 && <AdditionalMenuItems items={additionalMenuItems} />}
			</Inline>
		);
	}

	return (
		<>
			<PageHeader truncateTitle breadcrumbs={breadcrumbs} actions={renderActions()}>
				{formatMessage(messages.title)}
			</PageHeader>
		</>
	);
}
