import { useMemo } from 'react';
import type { calendarRenderer_jira_refetchQuery$variables } from '@atlassian/jira-relay/src/__generated__/calendarRenderer_jira_refetchQuery.graphql';
import { useCalendarCapabilities } from '../../common/controllers/capabilities-provider/index.tsx';
import { useCalendarConfigurationInput } from '../use-calendar-configuration-input/index.tsx';
import { useCalendarScopeInput } from '../use-calendar-scope-input/index.tsx';
import { useCalendarViewId } from '../use-calendar-view-id/index.tsx';
import { useCrossProjectVersionsSearchInput } from '../use-cross-project-versions-search-input/index.tsx';
import { useSprintsSearchInput } from '../use-sprints-search-input/index.tsx';
import { useVersionsSearchInput } from '../use-versions-search-input/index.tsx';

export function useRefetchCalendarVariables(): {
	variables: calendarRenderer_jira_refetchQuery$variables;
} {
	const { scopeInput } = useCalendarScopeInput();
	const { configurationInput } = useCalendarConfigurationInput();
	const { sprintsSearchInput } = useSprintsSearchInput();
	const { viewId } = useCalendarViewId();
	const { crossProjectVersionsSearchInput } = useCrossProjectVersionsSearchInput();

	const {
		hasUnscheduledPanel,
		sprint: { visible: isSprintVisible },
		version: { visible: isVersionVisible },
		hasSharedReleases,
	} = useCalendarCapabilities();
	const { versionsSearchInput } = useVersionsSearchInput(hasSharedReleases);

	const skipSprintSearch = !isSprintVisible;
	const skipVersionSearch = !isVersionVisible;
	const skipPanelIssueSearch = !hasUnscheduledPanel;
	const skipCrossProjectVersionSearch = !isVersionVisible || !viewId;

	const variables = useMemo(
		() => ({
			scopeInput,
			configurationInput,
			sprintsSearchInput,
			versionsSearchInput,
			skipSprintSearch,
			skipVersionSearch,
			skipPanelIssueSearch,
			skipCrossProjectVersionSearch,
			viewId,
			crossProjectVersionsSearchInput,
		}),
		[
			scopeInput,
			configurationInput,
			sprintsSearchInput,
			versionsSearchInput,
			skipSprintSearch,
			skipVersionSearch,
			skipPanelIssueSearch,
			skipCrossProjectVersionSearch,
			viewId,
			crossProjectVersionsSearchInput,
		],
	);

	return {
		variables,
	};
}
