/**
 * @generated SignedSource<<8d7d7e26bd219b3969914d35d2c8887d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_softwareFiltersPopup_FilterPopup$data = {
  readonly jira: {
    readonly jqlBuilder: {
      readonly assignee: {
        readonly " $fragmentSpreads": FragmentRefs<"filterSection_softwareFiltersPopup">;
      } | null | undefined;
      readonly labels: {
        readonly " $fragmentSpreads": FragmentRefs<"filterSection_softwareFiltersPopup">;
      } | null | undefined;
      readonly priority: {
        readonly " $fragmentSpreads": FragmentRefs<"filterSection_softwareFiltersPopup">;
      } | null | undefined;
      readonly status: {
        readonly " $fragmentSpreads": FragmentRefs<"filterSection_softwareFiltersPopup">;
      } | null | undefined;
      readonly versions: {
        readonly " $fragmentSpreads": FragmentRefs<"filterSection_softwareFiltersPopup">;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ui_softwareFiltersPopup_FilterPopup";
};
export type ui_softwareFiltersPopup_FilterPopup$key = {
  readonly " $data"?: ui_softwareFiltersPopup_FilterPopup$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_softwareFiltersPopup_FilterPopup">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 15
},
v1 = {
  "kind": "Variable",
  "name": "jqlContext",
  "variableName": "jqlBuilderQuery"
},
v2 = [
  {
    "kind": "FragmentSpread",
    "name": "filterSection_softwareFiltersPopup"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "jqlBuilderQuery"
    }
  ],
  "kind": "Fragment",
  "name": "ui_softwareFiltersPopup_FilterPopup",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "cloudId",
              "variableName": "cloudId"
            }
          ],
          "concreteType": "JiraJqlBuilder",
          "kind": "LinkedField",
          "name": "jqlBuilder",
          "plural": false,
          "selections": [
            {
              "alias": "assignee",
              "args": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "Literal",
                  "name": "jqlTerm",
                  "value": "assignee"
                }
              ],
              "concreteType": "JiraJqlFieldValueConnection",
              "kind": "LinkedField",
              "name": "fieldValues",
              "plural": false,
              "selections": (v2/*: any*/)
            },
            {
              "alias": "status",
              "args": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "Literal",
                  "name": "jqlTerm",
                  "value": "status"
                }
              ],
              "concreteType": "JiraJqlFieldValueConnection",
              "kind": "LinkedField",
              "name": "fieldValues",
              "plural": false,
              "selections": (v2/*: any*/)
            },
            {
              "alias": "priority",
              "args": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "Literal",
                  "name": "jqlTerm",
                  "value": "priority"
                }
              ],
              "concreteType": "JiraJqlFieldValueConnection",
              "kind": "LinkedField",
              "name": "fieldValues",
              "plural": false,
              "selections": (v2/*: any*/)
            },
            {
              "alias": "labels",
              "args": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "Literal",
                  "name": "jqlTerm",
                  "value": "labels"
                }
              ],
              "concreteType": "JiraJqlFieldValueConnection",
              "kind": "LinkedField",
              "name": "fieldValues",
              "plural": false,
              "selections": (v2/*: any*/)
            },
            {
              "alias": "versions",
              "args": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "Literal",
                  "name": "jqlTerm",
                  "value": "fixVersions"
                }
              ],
              "concreteType": "JiraJqlFieldValueConnection",
              "kind": "LinkedField",
              "name": "fieldValues",
              "plural": false,
              "selections": (v2/*: any*/)
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "a07fbd9b2d88c5b1f53da4e3e78930b1";

export default node;
