import React from 'react';
import { ForgeScreenEvent } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { TriggerAnalyticsWrapper } from '@atlassian/jira-forge-ui-analytics/src/ui/servicedesk-queue-page/index.tsx';
import { SERVICEDESK_QUEUE_PAGE_MODULE } from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type { ServiceDeskQueuePage } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { parseExtensionId } from '@atlassian/jira-forge-ui-utils-internal/src/utils/parse-extension-id/index.tsx';
import { toSections } from '@atlassian/jira-navigation-apps-sidebar-forge/src/common/utils/subpages/index.tsx';
import { useServiceDeskQueueForgeAppsNav4 } from '@atlassian/jira-navigation-apps-sidebar-forge/src/controllers/servicedesk-queue/index.tsx';
import { ForgeItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-forge/src/common/ui/forge-item/index.tsx';
import type { ForgeItemProps } from '../../common/types.tsx';

type Props = {
	projectKey: string;
	analytics: ForgeItemProps['analytics'];
};

export const getItemHref = (extension: ServiceDeskQueuePage, projectKey: string) => {
	const { appId, envId } = parseExtensionId(extension.id);
	return `/jira/servicedesk/projects/${projectKey}/queues/apps/${appId}/${envId}`;
};

export const ForgeServiceDeskQueueSection = ({ projectKey, analytics }: Props) => {
	const { data } = useServiceDeskQueueForgeAppsNav4(projectKey);

	if (!data?.length || !projectKey) return null;

	return (
		<TriggerAnalyticsWrapper>
			{data.map((extension) => (
				<ForgeItem
					key={extension.id}
					id={extension.id}
					analytics={analytics}
					environmentType={extension.environmentType}
					environmentKey={extension.environmentKey}
					url={getItemHref(extension, projectKey)}
					name={extension.properties.title}
					sections={toSections(extension)}
					iconUrl={extension.properties.icon}
					showIcon
				/>
			))}
			<ForgeScreenEvent
				attributes={{
					moduleType: SERVICEDESK_QUEUE_PAGE_MODULE,
				}}
			/>
		</TriggerAnalyticsWrapper>
	);
};
