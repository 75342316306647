export const TEAMS_QUERY = `
    query JWMTeamFilterValues($cloudId: String!, $orgId: ID!, $queryString: String, $first: Int, $after: String) {
        team @optIn(to: "Team-search-v2") {
            teamSearchV2(
                organizationId: $orgId
                siteId: $cloudId
                first: $first
                after: $after
                filter: {query: $queryString}
            ) {
                pageInfo {
                    hasNextPage
                    endCursor
                }
                edges {
                    node {
                        team {
                            id
                            displayName
                            smallAvatarImageUrl
                        }
                    }
                }
            }
        }
    }
`;

export const TEAM_BY_ID_QUERY = `
    query JWMTeamFilterById($teamId: ID!, $cloudId: String!) {
        team @optIn(to: "Team-search-v2") {
            teamV2(id: $teamId, siteId: $cloudId) {
                id
                displayName
                smallAvatarImageUrl
            }
        }
    }
`;
