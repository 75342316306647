/**
 * @generated SignedSource<<937435b67aa5fe671742ab5d8a7eab53>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraVersionStatus = "ARCHIVED" | "RELEASED" | "UNRELEASED" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type flyout_calendar_CrossProjectVersionFlyout$data = {
  readonly crossProjectVersionScenarioValues: {
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly name: string;
  readonly projects: ReadonlyArray<{
    readonly avatar: {
      readonly xsmall: string | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly name: string;
    readonly projectTypeName: string | null | undefined;
  } | null | undefined> | null | undefined;
  readonly releaseDate: AGG$DateTime | null | undefined;
  readonly startDate: AGG$DateTime | null | undefined;
  readonly status: JiraVersionStatus;
  readonly " $fragmentType": "flyout_calendar_CrossProjectVersionFlyout";
};
export type flyout_calendar_CrossProjectVersionFlyout$key = {
  readonly " $data"?: flyout_calendar_CrossProjectVersionFlyout$data;
  readonly " $fragmentSpreads": FragmentRefs<"flyout_calendar_CrossProjectVersionFlyout">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "flyout_calendar_CrossProjectVersionFlyout",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "startDate"
    },
    {
      "kind": "ScalarField",
      "name": "releaseDate"
    },
    {
      "kind": "ScalarField",
      "name": "status"
    },
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "projects",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "id"
        },
        (v0/*: any*/),
        {
          "concreteType": "JiraAvatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "xsmall"
            }
          ]
        },
        {
          "kind": "ScalarField",
          "name": "projectTypeName"
        }
      ]
    },
    {
      "concreteType": "JiraCrossProjectVersionPlanScenarioValues",
      "kind": "LinkedField",
      "name": "crossProjectVersionScenarioValues",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    }
  ],
  "type": "JiraCrossProjectVersion"
};
})();

(node as any).hash = "28edb93890d4adf167bce3c6a5415968";

export default node;
