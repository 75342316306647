import { useRef } from 'react';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import { utcToZonedTime } from 'date-fns-tz';
import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import startOfDay from 'date-fns/startOfDay';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { fg } from '@atlassian/jira-feature-gating';
import {
	DUE_DATE_TYPE,
	START_DATE_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	type UIAnalyticsEvent,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import {
	ANALYTICS_ACTION_ISSUE_SUBJECT,
	ANALYTICS_ACTION_SCHEDULED,
	ANALYTICS_SOURCE,
	UI_EVENT_DROPPED,
	UNSCHEDULE_PANEL_SOURCE,
} from '../../../common/constants.tsx';
import { useUserTimezone } from '../../../common/controllers/use-user-timezone/index.tsx';
import { swapDate, swapTime, toDateOnly } from '../../../common/utils/dates/index.tsx';
import { useCalendarActions, useViewRange } from '../../../controllers/calendar-store/index.tsx';
import type { CalendarViewRange } from '../../../controllers/calendar-store/types.tsx';
import { moreLinkPopoverBinder } from '../../../controllers/more-link-popover-binder/index.tsx';
import {
	type DroppableCalendarDayCell,
	type DraggableCalendarIssueData,
	type DateRange,
	type NullableDateRange,
	isDraggableCalendarIssue,
	type DraggableCalendarVersionData,
	isDraggableCalendarVersion,
	type DraggableCalendarData,
	makeDroppableCalendarDayCell,
	type DraggableCalendarSprintData,
	isDraggableCalendarSprint,
} from '../../../services/draggable-card-type/index.tsx';

export function getExpectedDateRange({
	startDate,
	endDate,
	droppingOverCell,
	draggedDate,
}: NullableDateRange & {
	droppingOverCell: Date;
	draggedDate?: Date;
}): DateRange {
	const makeDate = (date: Date) => startOfDay(date);

	// Drag from unscheduled issues panel (or another source)
	if (!endDate) {
		if (!startDate) {
			return { startDate: undefined, endDate: droppingOverCell };
		}

		const newStartDate =
			differenceInDays(droppingOverCell, startDate) >= 0 ? makeDate(startDate) : droppingOverCell;
		return { endDate: droppingOverCell, startDate: newStartDate };
	}

	const shiftDays = differenceInDays(droppingOverCell, makeDate(draggedDate ?? endDate));

	const newStartDate = startDate ? addDays(makeDate(startDate), shiftDays) : undefined;
	const newEndDate = addDays(makeDate(endDate), shiftDays);

	return { startDate: newStartDate, endDate: newEndDate };
}

/*
 * Returns the expected start and end date after an event is dropped over a cell.
 *
 * If an event start date is not set, then the event is coming from the unscheduled panel
 * or another source. In this case, the range is calculated from the event starting cell up
 * to the drop over cell.
 * This function presevers the time of the start date and end date if it's set.
 */
export function getExpectedDateTimeRange({
	startDate,
	endDate,
	droppingOverCell,
	draggedDate,
}: NullableDateRange & {
	droppingOverCell: Date;
	draggedDate?: Date;
}): DateRange {
	// Drag from unscheduled panel (or another source)
	if (!endDate) {
		if (!startDate) {
			return { startDate: undefined, endDate: droppingOverCell };
		}

		// retain the time of the start date to not change user's original time
		const targetDate = swapTime(droppingOverCell, startDate);

		const newStartDate =
			differenceInDays(targetDate, startDate) >= 0
				? startDate
				: swapDate(startDate, droppingOverCell);

		return { endDate: droppingOverCell, startDate: newStartDate };
	}

	// retain the time of the dragged/end date to not change user's original time
	const targetDate = swapTime(droppingOverCell, draggedDate ?? endDate);

	const shiftDays = differenceInDays(targetDate, draggedDate ?? endDate);

	const newStartDate = startDate ? addDays(startDate, shiftDays) : undefined;
	const newEndDate = addDays(endDate, shiftDays);

	return { startDate: newStartDate, endDate: newEndDate };
}

/**
 * VISIBLE FOR TESTING
 *
 * Returns the expected start and end date after an issue is dropped over a cell.
 *
 * If an issue due date is not set, then the issue is coming from the unscheduled issues panel
 * or another source. In this case, the range is calculated from the issue starting cell up
 * to the drop over cell.
 */
export function getExpectedRangeForIssue(
	issueData: Pick<DraggableCalendarIssueData, 'startDate' | 'endDate' | 'draggedDate'>,
	droppingOverCell: Date,
): DateRange {
	return getExpectedDateRange({
		startDate: issueData?.startDate ? new Date(issueData.startDate) : undefined,
		endDate: issueData?.endDate ? new Date(issueData.endDate) : undefined,
		draggedDate: issueData.draggedDate ? new Date(issueData.draggedDate) : undefined,
		droppingOverCell,
	});
}

/**
 * VISIBLE FOR TESTING
 *
 * Returns the expected start and end date after a version is dropped over a cell.
 *
 * If a version release date is not set, then the version is coming from the unscheduled panel
 * or another source. In this case, the range is calculated from the version starting cell up
 * to the drop over cell.
 */
export function getExpectedRangeForVersionOld(
	versionData: Pick<DraggableCalendarVersionData, 'startDate' | 'releaseDate' | 'draggedDate'>,
	droppingOverCell: Date,
): DateRange {
	return getExpectedDateRange({
		startDate: versionData?.startDate ? new Date(versionData.startDate) : undefined,
		endDate: versionData?.releaseDate ? new Date(versionData.releaseDate) : undefined,
		draggedDate: versionData.draggedDate ? new Date(versionData.draggedDate) : undefined,
		droppingOverCell,
	});
}

/**
 * VISIBLE FOR TESTING
 *
 * Returns the end date after a version is dropped over a cell.
 * Returns an undefined start date to render only the end date in the UI.
 *
 */
export function getExpectedRangeForVersion(droppingOverCell: Date): DateRange {
	return { startDate: undefined, endDate: startOfDay(droppingOverCell) };
}

/**
 * VISIBLE FOR TESTING
 *
 * Returns the expected start and end date after a sprint is dropped over a cell.
 *
 * If a sprint end date is not set, then the sprint is coming from the unscheduled panel
 * or another source. In this case, the range is calculated from the sprint starting cell up
 * to the drop over cell.
 */
export function getExpectedRangeForSprint(
	sprintData: Pick<DraggableCalendarSprintData, 'startDate' | 'endDate' | 'draggedDate'>,
	droppingOverCell: Date,
): DateRange {
	return getExpectedDateTimeRange({
		startDate: sprintData?.startDate ? new Date(sprintData.startDate) : undefined,
		endDate: sprintData?.endDate ? new Date(sprintData.endDate) : undefined,
		draggedDate: sprintData.draggedDate ? new Date(sprintData.draggedDate) : undefined,
		droppingOverCell,
	});
}

/**
 * VISIBLE FOR TESTING
 *
 * Each day calendar cell gets a pramatic dnd drop target attached to it.
 *
 * This class holds logic associated to that drop target. It's stateful only for clean-up
 * callbacks that need to be executed on drag leave or drop.
 *
 * These callbacks will undo changes made to add the highlight onto day cells.
 *
 * As an input to this object, we have a Map of all the date cells by timestamp. When a
 * drag enters a cell, we calculate where the issue would be placed and highlight
 * corresponding cells.
 *
 * Clean-up functions are ran to remove the highlight. When the issue is dropped, it's
 * updated with the new start and due dates.
 */
export class CalendarDropTargetController {
	/**
	 * Clean-up functions queued to be executed on drag leave.
	 */
	private releaseOnDragLeave: (() => void)[] = [];

	constructor(
		private readonly el: HTMLElement,
		private readonly droppingOverCell: Date,
		/**
		 * Each HTML day cell element on the calendar by Date timestamp.
		 */
		private readonly tableCellsByDate: ReadonlyMap<number, HTMLElement>,
		/**
		 * The mutation functions to update issue dates and versions
		 */
		private readonly calendarMutations: CalendarMutations,

		private readonly timeZone: string,

		/**
		 * UI Analytics Event
		 */
		private readonly uiAnalyticsEvent: UIAnalyticsEvent,
		/**
		 * Month, week, or day view range.
		 */
		private readonly viewRange: CalendarViewRange | undefined,
	) {
		/* empty */
	}

	/**
	 * Returns true if the drag was handled and false if it wasn't.
	 */
	onDragEnter(args: { source: { data: Record<string, unknown> } }): boolean {
		const { data } = args.source;
		if (
			!isDraggableCalendarIssue(data) &&
			!isDraggableCalendarVersion(data) &&
			!isDraggableCalendarSprint(data)
		) {
			return false;
		}

		this.highlightCells(data);

		return true;
	}

	/**
	 * Returns true if the drop was handled and false if it wasn't.
	 */
	onDrop(args: { source: { data: Record<string, unknown> } }): boolean {
		// Run clean-up functions
		this.onDragLeave();

		const { data } = args.source;
		if (
			!isDraggableCalendarIssue(data) &&
			!isDraggableCalendarVersion(data) &&
			!isDraggableCalendarSprint(data)
		) {
			return false;
		}

		const { startDate, endDate } = this.getDateRange(data);
		const analyticsAttributes = [];
		startDate && analyticsAttributes.push(START_DATE_TYPE);
		endDate && analyticsAttributes.push(DUE_DATE_TYPE);

		if (isDraggableCalendarIssue(data)) {
			this.calendarMutations.scheduleCalendarIssue({
				issueAri: data.issueAri,
				issueRecordId: data.issueRecordId,
				startDateFieldRecordId: data.startDateFieldRecordId,
				endDateFieldRecordId: data.endDateFieldRecordId,
				// If the card is dragged from the panel, we remove it from the panel.
				// If the card is dragged from the calendar, we still want to keep it on the calendar.
				removeCardFromConnectionId:
					data.source === UNSCHEDULE_PANEL_SOURCE ? data.connectionRecordId : undefined,
				startDate,
				endDate,
				issueKey: data.issueKey,
				canSchedule: data.canSchedule,
				analyticsAttributes,
			});

			if (this.uiAnalyticsEvent) {
				fireUIAnalytics(this.uiAnalyticsEvent, ANALYTICS_SOURCE, {
					issueSource: data.source,
					calendarEvent: UI_EVENT_DROPPED,
					isUnscheduled: !data.endDateFieldRecordId,
					viewRange: this.viewRange,
				});
			}
		}

		if (isDraggableCalendarVersion(data)) {
			// Use the original startDate so that it remains unmodified
			const originalStartDate = data?.startDate ? new Date(data.startDate) : undefined;

			this.calendarMutations.executeUpdateVersionDateMutation({
				versionId: data.versionId,
				versionName: data.versionName,
				startDate: originalStartDate,
				releaseDate: endDate,
			});
		}

		if (isDraggableCalendarSprint(data)) {
			this.calendarMutations.executeUpdateSprintDateMutation({
				id: data.id,
				sprintId: data.sprintId,
				name: data.name,
				goal: data.goal,
				state: data.state,
				startDate,
				endDate,
				completionDate: data.completionDate,
			});
		}

		return true;
	}

	onDragLeave() {
		this.releaseOnDragLeave.forEach((fn) => fn());
		this.releaseOnDragLeave = [];
	}

	getDateRange(data: DraggableCalendarData): DateRange {
		if (isDraggableCalendarIssue(data)) {
			return getExpectedRangeForIssue(data, this.droppingOverCell);
		}
		if (isDraggableCalendarVersion(data)) {
			return getExpectedRangeForVersion(this.droppingOverCell);
		}
		return getExpectedRangeForSprint(data, this.droppingOverCell);
	}

	private highlightCells(data: DraggableCalendarData) {
		const { startDate, endDate } = this.getDateRange(data);

		this.highlightCell(this.el);

		// Highlight only the dragged over cell for week view for now
		if (this.viewRange === 'week') {
			return;
		}

		if (startDate) {
			const isTimeZoneAware = isDraggableCalendarSprint(data);

			const start = isTimeZoneAware
				? startOfDay(utcToZonedTime(startDate, this.timeZone))
				: startDate;
			const end = isTimeZoneAware ? startOfDay(utcToZonedTime(endDate, this.timeZone)) : endDate;

			for (let date = start; differenceInDays(end, date) >= 0; date = addDays(date, 1)) {
				const dayCellElement = this.tableCellsByDate.get(toDateOnly(date).getTime());
				this.highlightCell(dayCellElement);
			}
		}
	}

	highlightCell(dayCellElement: HTMLElement | null | undefined) {
		dayCellElement?.classList.add('jira-calendar-view-cell-highlight');
		this.releaseOnDragLeave.push(() => {
			dayCellElement?.classList.remove('jira-calendar-view-cell-highlight');
		});
	}
}

export interface CalendarMutations {
	scheduleCalendarIssue: (params: {
		issueAri: string;
		issueRecordId: string;
		startDateFieldRecordId: string | undefined;
		endDateFieldRecordId: string | undefined;
		startDate: Date | undefined;
		endDate: Date;
		removeCardFromConnectionId?: string;
		issueKey: string;
		canSchedule?: boolean | null;
		analyticsAttributes?: string[];
	}) => void;
	executeUpdateVersionDateMutation: (params: {
		versionId: string;
		versionName: string;
		startDate: Date | undefined;
		releaseDate: Date;
	}) => void;
	executeUpdateSprintDateMutation: (params: {
		id: string;
		sprintId: string;
		name: string | null | undefined;
		goal: string | null | undefined;
		state: string | null | undefined;
		startDate: Date | undefined;
		endDate: Date;
		completionDate: string | null | undefined;
	}) => void;
}

/**
 * Calendar DND is based on attaching pragmatic dnd into the fullcalendar event cell elements.
 */
export function useCalendarDropTarget(calendarMutations: CalendarMutations) {
	const releaseDayCell = useRef(new Map<HTMLElement, () => void>());
	const tableCellsByDate = useRef(new Map<number, HTMLElement>());

	// fullcalendar would possibly call the stale dayCellDidMount,
	// so we store the latest calendarMutations in a ref and use it in the callbacks.
	const calendarMutationsRef = useRef<CalendarMutations>(calendarMutations);
	Object.assign(calendarMutationsRef.current, calendarMutations);

	const { userTimeZone } = useUserTimezone();
	const { setDraggingEvent } = useCalendarActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const viewRange = fg('jsd_shield_jsm_calendar_weekly_view') ? useViewRange() : undefined;

	const uiAnalyticsEvent = createAnalyticsEvent({
		action: ANALYTICS_ACTION_SCHEDULED,
		actionSubject: ANALYTICS_ACTION_ISSUE_SUBJECT,
	});

	const dayCellDidMount = ({ el, date: droppingOverCell }: { el: HTMLElement; date: Date }) => {
		if (!el) return;
		const controller = new CalendarDropTargetController(
			el,
			droppingOverCell,
			tableCellsByDate.current,
			calendarMutationsRef.current,
			userTimeZone,
			uiAnalyticsEvent,
			viewRange,
		);

		const unregisterClickEvent = moreLinkPopoverBinder.registerMoreLinkClick(el);

		const release = dropTargetForElements({
			element: el,
			onDragEnter(args) {
				unstable_batchedUpdates(() => {
					controller.onDragEnter(args);
				});
			},
			onDragLeave(_args) {
				unstable_batchedUpdates(() => {
					controller.onDragLeave();
				});
			},
			onDrop(args) {
				unstable_batchedUpdates(() => {
					controller.onDrop(args);
					setDraggingEvent(null);
				});
			},
			getData(args): DroppableCalendarDayCell {
				const { data } = args.source;
				if (
					(isDraggableCalendarIssue(data) || isDraggableCalendarVersion(data)) &&
					data.draggedDate
				) {
					const draggedDate = new Date(data.draggedDate);
					const { startDate, endDate } = controller.getDateRange(data);

					// If the current day cell is no more within the range of the dragged event, we want to hide it from the popover.
					if (
						(startDate && startDate.getTime() > draggedDate.getTime()) ||
						endDate.getTime() < draggedDate.getTime()
					) {
						return makeDroppableCalendarDayCell({ isEventDraggedAway: true });
					}
				}

				return makeDroppableCalendarDayCell({ isEventDraggedAway: false });
			},
		});

		tableCellsByDate.current.set(droppingOverCell.getTime(), el);
		releaseDayCell.current.set(el, () => {
			release();
			tableCellsByDate.current.delete(droppingOverCell.getTime());
			unregisterClickEvent?.();
		});
	};

	const dayCellWillUnmount = ({ el }: { el: HTMLElement }) => {
		const release = releaseDayCell.current.get(el);
		if (release) {
			release();
		}

		releaseDayCell.current.delete(el);
	};

	return {
		dayCellDidMount,
		dayCellWillUnmount,
	};
}
