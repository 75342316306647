import { graphql, useMutation } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import type {
	useDisconnectExternalProjectMutation,
	useDisconnectExternalProjectMutation$data,
} from '@atlassian/jira-relay/src/__generated__/useDisconnectExternalProjectMutation.graphql';
import RelayDataID from '@atlassian/relay-data-id';
import {
	GRAPHQL_FIELD_AGS_RELATIONSHIP_PROJECTS,
	GRAPHQL_FIELD_GRAPH_STORE,
	GRAPHQL_TYPE_JIRA_PROJECT,
} from '../../common/constants.tsx';
import { useCalendarActions } from '../calendar-store/index.tsx';
import { useLogger } from '../use-logger/index.tsx';
import { messages } from './messages.tsx';

export function useDisconnectExternalProjectMutation(currentProjectAri: string) {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();
	const { setIsLoading } = useCalendarActions();
	const { logError } = useLogger();

	const [disconnectExternalProjectMutation] = useMutation<useDisconnectExternalProjectMutation>(
		graphql`
			mutation useDisconnectExternalProjectMutation(
				$input: GraphStoreDeleteProjectHasRelatedWorkWithProjectInput
			) {
				graphStore @optIn(to: "GraphStoreMutation") {
					deleteProjectHasRelatedWorkWithProject(input: $input)
						@optIn(to: "GraphStoreProjectHasRelatedWorkWithProject") {
						success
						errors {
							message
							extensions {
								statusCode
							}
						}
					}
				}
			}
		`,
	);

	const disconnectProject = (toProjectAris: string[]) => {
		setIsLoading(true);

		const showGenericFlag = () =>
			showFlag({
				type: 'error',
				id: 'disconnect-project-failure',
				title: formatMessage(messages.projectConnectionFailureTitle),
				description: formatMessage(messages.projectConnectionUnexpectedFailureDescription),
				testId: 'jira-calendar.disconnect-project-failure-flag',
			});

		disconnectExternalProjectMutation({
			variables: {
				input: {
					relationships: toProjectAris.map((toProjectAri) => ({
						from: currentProjectAri,
						to: toProjectAri,
					})),
				},
			},
			onCompleted(response: useDisconnectExternalProjectMutation$data) {
				setIsLoading(false);

				const disconnectProjectResponse =
					response?.graphStore?.deleteProjectHasRelatedWorkWithProject;

				if (!disconnectProjectResponse) {
					showGenericFlag();
				}

				if (!disconnectProjectResponse?.success) {
					const code = disconnectProjectResponse?.errors?.[0]?.extensions?.statusCode;

					switch (code) {
						case 404:
							showFlag({
								type: 'error',
								id: 'disconnect-project-failure',
								title: formatMessage(messages.projectConnectionNotFoundTitle),
								description: formatMessage(messages.projectConnectionNotFoundDescription),
								testId: 'jira-calendar.disconnect-project-failure-flag',
							});
							break;
						case 403:
							showFlag({
								type: 'error',
								id: 'disconnect-project-failure',
								title: formatMessage(messages.disconnectPermissionsFailFlagTitle),
								description: formatMessage(messages.disconnectPermissionsFailFlagDescription),
								testId: 'jira-calendar.disconnect-project-failure-flag',
							});
							break;
						default:
							showGenericFlag();
							break;
					}
				}
			},
			onError(error: Error) {
				setIsLoading(false);

				logError(
					'calendar.disconnect-project-mutation.error',
					`Failed to disconnect projects ${toProjectAris} from project ${currentProjectAri}`,
					error,
				);

				showGenericFlag();
			},
			updater: (store, data) => {
				if (data?.graphStore?.deleteProjectHasRelatedWorkWithProject?.success) {
					const graphStoreRecord = store.getRoot().getLinkedRecord(GRAPHQL_FIELD_GRAPH_STORE);

					if (!graphStoreRecord) {
						return;
					}

					const connection = graphStoreRecord.getLinkedRecord(
						GRAPHQL_FIELD_AGS_RELATIONSHIP_PROJECTS,
						{
							id: currentProjectAri,
						},
					);

					if (!connection) {
						return;
					}

					toProjectAris.forEach((toProjectAri) => {
						const deletedProjectNodeId = RelayDataID(
							{ id: toProjectAri },
							GRAPHQL_TYPE_JIRA_PROJECT,
						);

						if (deletedProjectNodeId) {
							ConnectionHandler.deleteNode(connection, deletedProjectNodeId);
						}
					});
				}
			},
		});
	};

	return { disconnectProject };
}
