import { useMemo } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import type { calendarRendererIssues_jira_refetchQuery$variables } from '@atlassian/jira-relay/src/__generated__/calendarRendererIssues_jira_refetchQuery.graphql';
import {
	DEFAULT_MAX_ISSUES_PAGE_SIZE,
	DEFAULT_MAX_ISSUES_PAGE_SIZE_OLD,
} from '../../common/constants.tsx';
import { useCalendarCapabilities } from '../../common/controllers/capabilities-provider/index.tsx';
import { useIssuesPageCursor } from '../calendar-store/index.tsx';
import { useCalendarConfigurationInput } from '../use-calendar-configuration-input/index.tsx';
import { useCalendarScopeInput } from '../use-calendar-scope-input/index.tsx';
import { useCalendarViewId } from '../use-calendar-view-id/index.tsx';
import { useIssuesSearchInput } from '../use-issues-search-input/index.tsx';

export function useRefetchIssuesCalendarVariables(): {
	variables: calendarRendererIssues_jira_refetchQuery$variables;
} {
	const { scopeInput } = useCalendarScopeInput();
	const { configurationInput } = useCalendarConfigurationInput();
	const { issuesSearchInput } = useIssuesSearchInput();
	const { viewId } = useCalendarViewId();
	const issuesPageCursor = useIssuesPageCursor();
	const issuesPageSize = fg('jira_calendar_lazy_load')
		? DEFAULT_MAX_ISSUES_PAGE_SIZE
		: DEFAULT_MAX_ISSUES_PAGE_SIZE_OLD;
	const { weekViewEnabled } = useCalendarCapabilities();

	const variables = useMemo(
		() => ({
			scopeInput,
			configurationInput,
			issuesPageSize,
			issuesSearchInput,
			issuesPageCursor,
			viewId,
			...(fg('jsd_shield_jsm_calendar_weekly_view') && { skipFieldConfig: !weekViewEnabled }),
			schedulePermissionsEnabled: fg('calendar_schedule_issue_permissions_check'),
			pageLoadMoreEnabled: fg('jira_calendar_load_more_pagination'),
		}),
		[
			scopeInput,
			configurationInput,
			issuesPageSize,
			issuesSearchInput,
			issuesPageCursor,
			viewId,
			weekViewEnabled,
		],
	);

	return {
		variables,
	};
}
