import { useMemo } from 'react';
import type { JiraCalendarSprintsInput } from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery.graphql';

export function useSprintsSearchInput(): {
	sprintsSearchInput: JiraCalendarSprintsInput;
} {
	const sprintsSearchInput = useMemo<JiraCalendarSprintsInput>(
		() => ({ sprintStates: ['ACTIVE', 'FUTURE', 'CLOSED'] }),
		[],
	);

	return { sprintsSearchInput };
}
