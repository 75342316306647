import { useEffect, useRef } from 'react';
import type { RefetchFnDynamic } from 'react-relay';
import type { calendarRenderer_jira$key } from '@atlassian/jira-relay/src/__generated__/calendarRenderer_jira.graphql';
import type { calendarRenderer_jira_refetchQuery } from '@atlassian/jira-relay/src/__generated__/calendarRenderer_jira_refetchQuery.graphql';
import { useRefetchCalendarEvents } from '../../../controllers/use-refetch-calendar/index.tsx';

export function useRefetchCalendarEventsOnInputsChanges({
	refetch,
}: {
	refetch: RefetchFnDynamic<calendarRenderer_jira_refetchQuery, calendarRenderer_jira$key>;
}) {
	const { refetchCalendarWithoutSuspense } = useRefetchCalendarEvents({ refetch });
	const isFirstRun = useRef(true);

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}

		const subscription = refetchCalendarWithoutSuspense();

		return () => subscription.unsubscribe();
	}, [refetchCalendarWithoutSuspense]);
}
