import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	dayStartsAt: {
		id: 'calendar.calendar-top-controls-bar.view-settings.day-starts-at',
		defaultMessage: 'Day starts at',
		description: 'Label for the day starts at button on Jira calendar view settings pop-up',
	},
	chevronDownIconLabel: {
		id: 'calendar.calendar-top-controls-bar.view-settings.chevron-down-icon-label',
		defaultMessage: 'Chevron down icon in time picker',
		description:
			'Label for the chevron down icon in time picker on Jira calendar view settings pop-up',
	},
	currentDayStartsAt: {
		id: 'calendar.calendar-top-controls-bar.view-settings.current-day-starts-at',
		defaultMessage: 'Day starts at currently set as {time}',
		description: 'Label for the current day starts at on Jira calendar view settings pop-up',
	},
});
