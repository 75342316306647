/**
 * @generated SignedSource<<f2e0dea8282b4175c3a77be7b160315d>>
 * @relayHash 25abbbb8be4da753a558797d89d1d07e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 667eda32ac84dd38ec2d31bbb3fc3b8bc000584504b7119c07e1345d8ce6155a

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueTypeField_calendar_RefetchPaginated_Query$variables = {
  cursor?: string | null | undefined;
  first?: number | null | undefined;
  id: string;
};
export type issueTypeField_calendar_RefetchPaginated_Query$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"issueTypeField_calendar">;
  } | null | undefined;
};
export type issueTypeField_calendar_RefetchPaginated_Query = {
  response: issueTypeField_calendar_RefetchPaginated_Query$data;
  variables: issueTypeField_calendar_RefetchPaginated_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": 10,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "issueTypeField_calendar_RefetchPaginated_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v2/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "issueTypeField_calendar"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "issueTypeField_calendar_RefetchPaginated_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v5/*: any*/),
                "concreteType": "JiraIssueTypeConnection",
                "kind": "LinkedField",
                "name": "issueTypes",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssueType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "name"
                          },
                          {
                            "concreteType": "JiraAvatar",
                            "kind": "LinkedField",
                            "name": "avatar",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "small"
                              }
                            ]
                          },
                          {
                            "concreteType": "JiraIssueTypeHierarchyLevel",
                            "kind": "LinkedField",
                            "name": "hierarchy",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "level"
                              }
                            ]
                          },
                          {
                            "kind": "ClientExtension",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "__id"
                              }
                            ]
                          },
                          (v3/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "totalCount"
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      }
                    ]
                  }
                ]
              },
              {
                "args": (v5/*: any*/),
                "handle": "connection",
                "key": "inlineCardCreate_calendar_issueTypes",
                "kind": "LinkedHandle",
                "name": "issueTypes"
              },
              {
                "alias": "admin",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "EDIT_PROJECT_CONFIG"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "canPerform"
                  }
                ],
                "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
              },
              {
                "kind": "ScalarField",
                "name": "projectType"
              },
              {
                "kind": "ScalarField",
                "name": "projectStyle"
              },
              {
                "kind": "ScalarField",
                "name": "key"
              }
            ],
            "type": "JiraProject"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "667eda32ac84dd38ec2d31bbb3fc3b8bc000584504b7119c07e1345d8ce6155a",
    "metadata": {},
    "name": "issueTypeField_calendar_RefetchPaginated_Query",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "0446f4c6405c741ed2d1a251879aa2d4";

export default node;
