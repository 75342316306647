/**
 * @generated SignedSource<<5da4ebe8936ba420c2a0086277d61ce4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraScenarioType = "ADDED" | "DELETED" | "DELETEDFROMJIRA" | "UPDATED" | "%future added value";
export type JiraVersionStatus = "ARCHIVED" | "RELEASED" | "UNRELEASED" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type versionRenderer_calendar_VersionEventRenderer$data = {
  readonly id: string;
  readonly name?: string | null | undefined;
  readonly planScenarioValues: {
    readonly name: string | null | undefined;
    readonly releaseDate?: AGG$DateTime | null | undefined;
    readonly releaseDateValue?: {
      readonly date: AGG$DateTime | null | undefined;
    } | null | undefined;
    readonly scenarioType: JiraScenarioType | null | undefined;
    readonly startDate?: AGG$DateTime | null | undefined;
    readonly startDateValue?: {
      readonly date: AGG$DateTime | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly project?: {
    readonly avatar: {
      readonly xsmall: string | null | undefined;
    } | null | undefined;
    readonly key: string;
    readonly name: string;
  } | null | undefined;
  readonly releaseDate?: AGG$DateTime | null | undefined;
  readonly startDate?: AGG$DateTime | null | undefined;
  readonly status?: JiraVersionStatus | null | undefined;
  readonly versionId?: string;
  readonly " $fragmentSpreads": FragmentRefs<"editReleaseItem_calendar" | "ui_ReleaseFlyout">;
  readonly " $fragmentType": "versionRenderer_calendar_VersionEventRenderer";
};
export type versionRenderer_calendar_VersionEventRenderer$key = {
  readonly " $data"?: versionRenderer_calendar_VersionEventRenderer$data;
  readonly " $fragmentSpreads": FragmentRefs<"versionRenderer_calendar_VersionEventRenderer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "viewId",
  "variableName": "viewId"
},
v1 = {
  "kind": "ScalarField",
  "name": "name"
},
v2 = {
  "kind": "ScalarField",
  "name": "startDate"
},
v3 = {
  "kind": "ScalarField",
  "name": "releaseDate"
},
v4 = [
  {
    "kind": "ScalarField",
    "name": "date"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjiracalendarreleaseflyoutlazyloadingenabledrelayprovider"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipVersionsV1Search"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipVersionsV2Search"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "name": "versionRenderer_calendar_VersionEventRenderer",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "args": [
        (v0/*: any*/)
      ],
      "concreteType": "JiraVersionPlanScenarioValues",
      "kind": "LinkedField",
      "name": "planScenarioValues",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "condition": "skipVersionsV1Search",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/)
          ]
        },
        {
          "condition": "skipVersionsV2Search",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "concreteType": "JiraDateScenarioValueField",
              "kind": "LinkedField",
              "name": "startDateValue",
              "plural": false,
              "selections": (v4/*: any*/)
            },
            {
              "concreteType": "JiraDateScenarioValueField",
              "kind": "LinkedField",
              "name": "releaseDateValue",
              "plural": false,
              "selections": (v4/*: any*/)
            }
          ]
        },
        {
          "kind": "ScalarField",
          "name": "scenarioType"
        }
      ]
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "versionId"
        },
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "kind": "ScalarField",
          "name": "status"
        },
        {
          "concreteType": "JiraProject",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "key"
            },
            (v1/*: any*/),
            {
              "concreteType": "JiraAvatar",
              "kind": "LinkedField",
              "name": "avatar",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "xsmall"
                }
              ]
            }
          ]
        },
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjiracalendarreleaseflyoutlazyloadingenabledrelayprovider",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "skipVersionsV1Search",
                  "variableName": "skipVersionsV1Search"
                },
                {
                  "kind": "Variable",
                  "name": "skipVersionsV2Search",
                  "variableName": "skipVersionsV2Search"
                },
                (v0/*: any*/)
              ],
              "kind": "FragmentSpread",
              "name": "ui_ReleaseFlyout"
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "editReleaseItem_calendar"
        }
      ],
      "type": "JiraVersion"
    }
  ],
  "type": "JiraScenarioVersionLike",
  "abstractKey": "__isJiraScenarioVersionLike"
};
})();

(node as any).hash = "3de8329a067912599b5536926bfaf388";

export default node;
