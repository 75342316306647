import React from 'react';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, { DropdownItem } from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/glyph/more';
import type { MenuItem } from '../../../controllers/calendar-components-provider/index.tsx';

type Props = {
	items: MenuItem[];
};

const AdditionalMenuItems = ({ items }: Props) => (
	<DropdownMenu<HTMLButtonElement>
		trigger={({ triggerRef, ...props }) => (
			<IconButton {...props} icon={MoreIcon} label="more" ref={triggerRef} />
		)}
		shouldRenderToParent
		testId="calendar.ui.header.additional-menu-items.additional-menu-items"
	>
		{items.map((item: MenuItem, index) => (
			<DropdownItem key={index} href={item.href} UNSAFE_shouldDisableRouterLink>
				{item.title}
			</DropdownItem>
		))}
	</DropdownMenu>
);

export { AdditionalMenuItems };
