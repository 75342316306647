import React, { type ReactNode } from 'react';

import type { TodayWrapperType } from '../../../../common/types';

export const ConditionalTodayWrapper = ({
	Wrapper,
	children,
	isToday,
}: {
	Wrapper?: TodayWrapperType;
	children: ReactNode;
	isToday: boolean;
}) => {
	if (Wrapper && isToday) {
		return <Wrapper>{children}</Wrapper>;
	}
	return <>{children}</>;
};
