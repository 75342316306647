import { useEffect, useRef } from 'react';
import { useQueryParam } from '@atlassian/react-resource-router';
import { SELECTED_ISSUE } from '../../../common/constants.tsx';
import { useCalendarIssueViewMode } from '../../../common/controllers/use-calendar-issue-view-mode/index.tsx';

export function OpenIssueViewOnLoad() {
	const [selectedIssueKey, setSelectedIssueKey] = useQueryParam(SELECTED_ISSUE);
	const { openIssueView } = useCalendarIssueViewMode();
	const isPageLoadedRef = useRef<boolean>(true);

	useEffect(() => {
		if (selectedIssueKey && isPageLoadedRef.current) {
			openIssueView(selectedIssueKey);
			isPageLoadedRef.current = false;
		}
	}, [openIssueView, selectedIssueKey, setSelectedIssueKey, isPageLoadedRef]);

	return null;
}
