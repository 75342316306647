/**
 * @generated SignedSource<<49d38d11752de8fb85e66722a5492628>>
 * @relayHash 7ca580f3f5edd1c4adf0524b4d29f234
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 72f806bda28214873495762d1f97b501dfbd65493a41644b68714968cdc1bb2a

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraCalendarMode = "DAY" | "MONTH" | "WEEK" | "%future added value";
export type JiraCalendarWeekStart = "MONDAY" | "SATURDAY" | "SUNDAY" | "%future added value";
export type JiraSprintState = "ACTIVE" | "CLOSED" | "FUTURE" | "%future added value";
export type JiraVersionStatus = "ARCHIVED" | "RELEASED" | "UNRELEASED" | "%future added value";
export type JiraViewScopeInput = {
  ids?: ReadonlyArray<string> | null | undefined;
  projectKeys?: JiraProjectKeysInput | null | undefined;
};
export type JiraProjectKeysInput = {
  cloudId: string;
  keys?: ReadonlyArray<string> | null | undefined;
};
export type JiraCalendarViewConfigurationInput = {
  date?: AGG$DateTime | null | undefined;
  endDateField?: string | null | undefined;
  mode?: JiraCalendarMode | null | undefined;
  startDateField?: string | null | undefined;
  viewId?: string | null | undefined;
  weekStart?: JiraCalendarWeekStart | null | undefined;
};
export type JiraCalendarIssuesInput = {
  additionalFilterQuery?: string | null | undefined;
};
export type JiraCalendarVersionsInput = {
  additionalProjectAris?: ReadonlyArray<string> | null | undefined;
  includeSharedReleases?: boolean | null | undefined;
  versionStatuses?: ReadonlyArray<JiraVersionStatus> | null | undefined;
};
export type JiraCalendarCrossProjectVersionsInput = {
  activeWithin?: JiraDateTimeWindow | null | undefined;
  searchString?: string | null | undefined;
  statuses?: ReadonlyArray<JiraVersionStatus | null | undefined> | null | undefined;
};
export type JiraDateTimeWindow = {
  end?: AGG$DateTime | null | undefined;
  start?: AGG$DateTime | null | undefined;
};
export type JiraCalendarSprintsInput = {
  sprintStates?: ReadonlyArray<JiraSprintState> | null | undefined;
};
export type ui_jiraCalendarQuery$variables = {
  cloudId: string;
  configurationInput: JiraCalendarViewConfigurationInput;
  crossProjectVersionsSearchInput?: JiraCalendarCrossProjectVersionsInput | null | undefined;
  issuesPageCursor?: string | null | undefined;
  issuesPageSize?: number | null | undefined;
  issuesSearchInput: JiraCalendarIssuesInput;
  pageLoadMoreEnabled: boolean;
  schedulePermissionsEnabled: boolean;
  scopeInput: JiraViewScopeInput;
  skipCrossProjectVersionSearch?: boolean | null | undefined;
  skipFieldConfig?: boolean | null | undefined;
  skipPanelIssueSearch?: boolean | null | undefined;
  skipSprintSearch?: boolean | null | undefined;
  skipVersionsV1Search?: boolean | null | undefined;
  skipVersionsV2Search?: boolean | null | undefined;
  sprintsSearchInput?: JiraCalendarSprintsInput | null | undefined;
  unscheduledIssuesSearchInput?: JiraCalendarIssuesInput | null | undefined;
  versionsSearchInput?: JiraCalendarVersionsInput | null | undefined;
  viewId?: string | null | undefined;
};
export type ui_jiraCalendarQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"calendarIssueViewModal_calendar" | "calendarRendererIssues_jira" | "calendarRendererVersions_jira" | "calendarRenderer_jira" | "calendarTopControlsBar_calendar" | "calendarUnscheduledPanel_calendar">;
};
export type ui_jiraCalendarQuery = {
  response: ui_jiraCalendarQuery$data;
  variables: ui_jiraCalendarQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcisjiracalendarreleaseflyoutlazyloadingenabledrelayprovider": isJiraCalendarReleaseFlyoutLazyLoadingEnabled_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisjiracalendarreleaseflyoutlazyloadingenabledrelayprovider: {
    readonly get: () => boolean;
  };
});

import isJiraCalendarReleaseFlyoutLazyLoadingEnabled_provider from '@atlassian/jira-relay-provider/src/is-jira-calendar-release-flyout-lazy-loading-enabled.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "configurationInput"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "crossProjectVersionsSearchInput"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issuesPageCursor"
},
v4 = {
  "defaultValue": 50,
  "kind": "LocalArgument",
  "name": "issuesPageSize"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issuesSearchInput"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageLoadMoreEnabled"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "schedulePermissionsEnabled"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scopeInput"
},
v9 = {
  "defaultValue": true,
  "kind": "LocalArgument",
  "name": "skipCrossProjectVersionSearch"
},
v10 = {
  "defaultValue": true,
  "kind": "LocalArgument",
  "name": "skipFieldConfig"
},
v11 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "skipPanelIssueSearch"
},
v12 = {
  "defaultValue": true,
  "kind": "LocalArgument",
  "name": "skipSprintSearch"
},
v13 = {
  "defaultValue": true,
  "kind": "LocalArgument",
  "name": "skipVersionsV1Search"
},
v14 = {
  "defaultValue": true,
  "kind": "LocalArgument",
  "name": "skipVersionsV2Search"
},
v15 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sprintsSearchInput"
},
v16 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "unscheduledIssuesSearchInput"
},
v17 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "versionsSearchInput"
},
v18 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v19 = {
  "kind": "Variable",
  "name": "configurationInput",
  "variableName": "configurationInput"
},
v20 = {
  "kind": "Variable",
  "name": "issuesPageCursor",
  "variableName": "issuesPageCursor"
},
v21 = {
  "kind": "Variable",
  "name": "issuesPageSize",
  "variableName": "issuesPageSize"
},
v22 = {
  "kind": "Variable",
  "name": "issuesSearchInput",
  "variableName": "issuesSearchInput"
},
v23 = {
  "kind": "Variable",
  "name": "schedulePermissionsEnabled",
  "variableName": "schedulePermissionsEnabled"
},
v24 = {
  "kind": "Variable",
  "name": "scopeInput",
  "variableName": "scopeInput"
},
v25 = {
  "kind": "Variable",
  "name": "viewId",
  "variableName": "viewId"
},
v26 = {
  "kind": "Variable",
  "name": "skipVersionsV1Search",
  "variableName": "skipVersionsV1Search"
},
v27 = {
  "kind": "Variable",
  "name": "skipVersionsV2Search",
  "variableName": "skipVersionsV2Search"
},
v28 = {
  "kind": "Variable",
  "name": "versionsSearchInput",
  "variableName": "versionsSearchInput"
},
v29 = {
  "kind": "Variable",
  "name": "skipSprintSearch",
  "variableName": "skipSprintSearch"
},
v30 = {
  "kind": "Variable",
  "name": "sprintsSearchInput",
  "variableName": "sprintsSearchInput"
},
v31 = {
  "kind": "Variable",
  "name": "unscheduledIssuesSearchInput",
  "variableName": "unscheduledIssuesSearchInput"
},
v32 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v33 = [
  {
    "kind": "Variable",
    "name": "configuration",
    "variableName": "configurationInput"
  },
  {
    "kind": "Variable",
    "name": "scope",
    "variableName": "scopeInput"
  }
],
v34 = {
  "kind": "ScalarField",
  "name": "projectId"
},
v35 = [
  {
    "kind": "ScalarField",
    "name": "canPerform"
  }
],
v36 = {
  "args": [
    {
      "kind": "Literal",
      "name": "type",
      "value": "CREATE_ISSUES"
    }
  ],
  "concreteType": "JiraProjectAction",
  "kind": "LinkedField",
  "name": "action",
  "plural": false,
  "selections": (v35/*: any*/),
  "storageKey": "action(type:\"CREATE_ISSUES\")"
},
v37 = {
  "kind": "ScalarField",
  "name": "id"
},
v38 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v39 = [
  (v38/*: any*/),
  {
    "kind": "ScalarField",
    "name": "fieldId"
  },
  (v37/*: any*/)
],
v40 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 30
  }
],
v41 = {
  "kind": "ScalarField",
  "name": "name"
},
v42 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v43 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v44 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "small"
    }
  ]
},
v45 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v46 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v47 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v48 = {
  "kind": "ScalarField",
  "name": "endCursor"
},
v49 = {
  "kind": "ScalarField",
  "name": "hasNextPage"
},
v50 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    (v48/*: any*/),
    (v49/*: any*/)
  ]
},
v51 = {
  "kind": "ScalarField",
  "name": "key"
},
v52 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "issuesPageCursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "issuesPageSize"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "issuesSearchInput"
  }
],
v53 = {
  "kind": "ScalarField",
  "name": "totalIssueSearchResultCount"
},
v54 = {
  "kind": "ScalarField",
  "name": "isCappingIssueSearchResult"
},
v55 = {
  "kind": "ScalarField",
  "name": "hasPreviousPage"
},
v56 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    (v49/*: any*/),
    (v55/*: any*/),
    (v48/*: any*/)
  ]
},
v57 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "text"
    },
    (v37/*: any*/)
  ]
},
v58 = [
  (v25/*: any*/)
],
v59 = [
  {
    "kind": "ScalarField",
    "name": "date"
  }
],
v60 = {
  "kind": "InlineFragment",
  "selections": (v59/*: any*/),
  "type": "JiraDatePickerField"
},
v61 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "dateTime"
    }
  ],
  "type": "JiraDateTimePickerField"
},
v62 = [
  (v38/*: any*/),
  (v37/*: any*/),
  (v60/*: any*/),
  (v61/*: any*/),
  (v45/*: any*/)
],
v63 = {
  "args": (v58/*: any*/),
  "kind": "LinkedField",
  "name": "endDateViewField",
  "plural": false,
  "selections": (v62/*: any*/)
},
v64 = [
  (v38/*: any*/),
  (v37/*: any*/),
  (v60/*: any*/),
  (v45/*: any*/)
],
v65 = {
  "kind": "LinkedField",
  "name": "startDateViewField",
  "plural": false,
  "selections": (v64/*: any*/)
},
v66 = {
  "kind": "LinkedField",
  "name": "endDateViewField",
  "plural": false,
  "selections": (v64/*: any*/)
},
v67 = [
  (v41/*: any*/),
  (v44/*: any*/),
  (v37/*: any*/)
],
v68 = {
  "concreteType": "JiraIssueTypeField",
  "kind": "LinkedField",
  "name": "issueTypeField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": (v67/*: any*/)
    },
    (v37/*: any*/)
  ]
},
v69 = {
  "alias": "requestTypeField",
  "args": [
    {
      "kind": "Literal",
      "name": "idOrAlias",
      "value": "requesttype"
    },
    {
      "kind": "Literal",
      "name": "ignoreMissingField",
      "value": true
    }
  ],
  "kind": "LinkedField",
  "name": "fieldByIdOrAlias",
  "plural": false,
  "selections": [
    (v38/*: any*/),
    (v37/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraServiceManagementRequestType",
          "kind": "LinkedField",
          "name": "requestType",
          "plural": false,
          "selections": (v67/*: any*/)
        }
      ],
      "type": "JiraServiceManagementRequestTypeField"
    }
  ],
  "storageKey": "fieldByIdOrAlias(idOrAlias:\"requesttype\",ignoreMissingField:true)"
},
v70 = {
  "concreteType": "JiraStatusField",
  "kind": "LinkedField",
  "name": "statusField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraStatusCategory",
          "kind": "LinkedField",
          "name": "statusCategory",
          "plural": false,
          "selections": [
            (v51/*: any*/),
            (v37/*: any*/)
          ]
        },
        (v37/*: any*/),
        (v41/*: any*/)
      ]
    },
    (v37/*: any*/)
  ]
},
v71 = [
  (v38/*: any*/),
  (v41/*: any*/),
  {
    "kind": "ScalarField",
    "name": "picture"
  },
  (v37/*: any*/)
],
v72 = {
  "concreteType": "JiraSingleSelectUserPickerField",
  "kind": "LinkedField",
  "name": "assigneeField",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": (v71/*: any*/)
    },
    (v37/*: any*/)
  ]
},
v73 = {
  "concreteType": "JiraRichTextField",
  "kind": "LinkedField",
  "name": "descriptionField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraRichText",
      "kind": "LinkedField",
      "name": "richText",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraADF",
          "kind": "LinkedField",
          "name": "adfValue",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraAdfToConvertedPlainText",
              "kind": "LinkedField",
              "name": "convertedPlainText",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "plainText"
                }
              ]
            }
          ]
        }
      ]
    },
    (v37/*: any*/)
  ]
},
v74 = {
  "concreteType": "JiraProjectField",
  "kind": "LinkedField",
  "name": "projectField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": (v67/*: any*/)
    },
    (v37/*: any*/)
  ]
},
v75 = {
  "condition": "schedulePermissionsEnabled",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": "canSchedule",
      "args": [
        {
          "kind": "Literal",
          "name": "permission",
          "value": "SCHEDULE_ISSUES"
        }
      ],
      "kind": "ScalarField",
      "name": "hasProjectPermission",
      "storageKey": "hasProjectPermission(permission:\"SCHEDULE_ISSUES\")"
    }
  ]
},
v76 = {
  "kind": "ScalarField",
  "name": "scenarioKey"
},
v77 = {
  "kind": "LinkedField",
  "name": "node",
  "plural": false,
  "selections": [
    (v38/*: any*/),
    (v37/*: any*/),
    {
      "kind": "TypeDiscriminator",
      "abstractKey": "__isJiraScenarioIssueLike"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v51/*: any*/),
        (v57/*: any*/),
        {
          "args": (v58/*: any*/),
          "kind": "LinkedField",
          "name": "startDateViewField",
          "plural": false,
          "selections": (v62/*: any*/)
        },
        (v63/*: any*/),
        {
          "args": (v58/*: any*/),
          "concreteType": "JiraScenarioIssueValues",
          "kind": "LinkedField",
          "name": "planScenarioValues",
          "plural": false,
          "selections": [
            (v57/*: any*/),
            (v65/*: any*/),
            (v66/*: any*/)
          ]
        },
        (v68/*: any*/),
        (v69/*: any*/),
        (v70/*: any*/),
        {
          "args": (v58/*: any*/),
          "concreteType": "JiraScenarioIssueValues",
          "kind": "LinkedField",
          "name": "planScenarioValues",
          "plural": false,
          "selections": [
            (v57/*: any*/),
            (v68/*: any*/),
            (v65/*: any*/),
            (v66/*: any*/),
            (v70/*: any*/),
            (v72/*: any*/),
            (v73/*: any*/),
            (v74/*: any*/)
          ]
        },
        (v72/*: any*/),
        (v73/*: any*/),
        (v74/*: any*/),
        (v75/*: any*/)
      ],
      "type": "JiraIssue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": (v58/*: any*/),
          "concreteType": "JiraScenarioIssueValues",
          "kind": "LinkedField",
          "name": "planScenarioValues",
          "plural": false,
          "selections": [
            (v76/*: any*/),
            (v57/*: any*/),
            (v65/*: any*/),
            (v66/*: any*/)
          ]
        },
        {
          "args": (v58/*: any*/),
          "concreteType": "JiraScenarioIssueValues",
          "kind": "LinkedField",
          "name": "planScenarioValues",
          "plural": false,
          "selections": [
            (v76/*: any*/),
            (v68/*: any*/),
            (v57/*: any*/),
            (v65/*: any*/),
            (v66/*: any*/),
            (v70/*: any*/),
            (v72/*: any*/),
            (v73/*: any*/),
            (v74/*: any*/)
          ]
        }
      ],
      "type": "JiraScenarioIssue"
    },
    (v45/*: any*/)
  ]
},
v78 = {
  "concreteType": "JiraIssueNavigatorPageInfo",
  "kind": "LinkedField",
  "name": "issueNavigatorPageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "firstIssuePosition"
    },
    {
      "kind": "ScalarField",
      "name": "lastIssuePosition"
    }
  ]
},
v79 = [
  {
    "kind": "ScalarField",
    "name": "pageNumber"
  },
  (v46/*: any*/)
],
v80 = {
  "args": [
    {
      "kind": "Literal",
      "name": "maxCursors",
      "value": 7
    },
    {
      "kind": "Variable",
      "name": "pageSize",
      "variableName": "issuesPageSize"
    }
  ],
  "concreteType": "JiraPageCursors",
  "kind": "LinkedField",
  "name": "pageCursors",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraPageCursor",
      "kind": "LinkedField",
      "name": "first",
      "plural": false,
      "selections": (v79/*: any*/)
    },
    {
      "concreteType": "JiraPageCursor",
      "kind": "LinkedField",
      "name": "around",
      "plural": true,
      "selections": (v79/*: any*/)
    },
    {
      "concreteType": "JiraPageCursor",
      "kind": "LinkedField",
      "name": "last",
      "plural": false,
      "selections": (v79/*: any*/)
    }
  ]
},
v81 = [
  "input"
],
v82 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "versionsSearchInput"
  }
],
v83 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    (v49/*: any*/),
    (v55/*: any*/)
  ]
},
v84 = {
  "kind": "ScalarField",
  "name": "startDate"
},
v85 = {
  "kind": "ScalarField",
  "name": "releaseDate"
},
v86 = {
  "kind": "ScalarField",
  "name": "scenarioType"
},
v87 = {
  "concreteType": "JiraDateScenarioValueField",
  "kind": "LinkedField",
  "name": "startDateValue",
  "plural": false,
  "selections": (v59/*: any*/)
},
v88 = {
  "concreteType": "JiraDateScenarioValueField",
  "kind": "LinkedField",
  "name": "releaseDateValue",
  "plural": false,
  "selections": (v59/*: any*/)
},
v89 = {
  "kind": "ScalarField",
  "name": "versionId"
},
v90 = {
  "kind": "ScalarField",
  "name": "status"
},
v91 = {
  "concreteType": "JiraProject",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v51/*: any*/),
    (v41/*: any*/),
    (v42/*: any*/),
    (v37/*: any*/)
  ]
},
v92 = {
  "kind": "ScalarField",
  "name": "canEdit"
},
v93 = {
  "kind": "ScalarField",
  "name": "description"
},
v94 = {
  "kind": "ScalarField",
  "name": "projectTypeName"
},
v95 = [
  (v47/*: any*/)
],
v96 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjiracalendarreleaseflyoutlazyloadingenabledrelayprovider",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    (v93/*: any*/),
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraAvatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            (v38/*: any*/)
          ]
        },
        (v94/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "name": "driver",
      "plural": false,
      "selections": (v71/*: any*/)
    },
    {
      "alias": "doneIssues",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusCategories": "DONE"
          }
        }
      ],
      "concreteType": "JiraIssueConnection",
      "kind": "LinkedField",
      "name": "issues",
      "plural": false,
      "selections": (v95/*: any*/),
      "storageKey": "issues(filters:{\"statusCategories\":\"DONE\"})"
    },
    {
      "alias": "inprogressIssues",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusCategories": "IN_PROGRESS"
          }
        }
      ],
      "concreteType": "JiraIssueConnection",
      "kind": "LinkedField",
      "name": "issues",
      "plural": false,
      "selections": (v95/*: any*/),
      "storageKey": "issues(filters:{\"statusCategories\":\"IN_PROGRESS\"})"
    },
    {
      "alias": "todoIssues",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusCategories": "TODO"
          }
        }
      ],
      "concreteType": "JiraIssueConnection",
      "kind": "LinkedField",
      "name": "issues",
      "plural": false,
      "selections": (v95/*: any*/),
      "storageKey": "issues(filters:{\"statusCategories\":\"TODO\"})"
    },
    {
      "args": (v58/*: any*/),
      "concreteType": "JiraVersionPlanScenarioValues",
      "kind": "LinkedField",
      "name": "planScenarioValues",
      "plural": false,
      "selections": [
        (v93/*: any*/)
      ]
    }
  ]
},
v97 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "unscheduledIssuesSearchInput"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/),
      (v18/*: any*/)
    ],
    "kind": "Fragment",
    "name": "ui_jiraCalendarQuery",
    "selections": [
      {
        "args": [
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          {
            "kind": "Variable",
            "name": "pageLoadMoreEnabled",
            "variableName": "pageLoadMoreEnabled"
          },
          (v23/*: any*/),
          (v24/*: any*/),
          {
            "kind": "Variable",
            "name": "skipFieldConfig",
            "variableName": "skipFieldConfig"
          },
          (v25/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "calendarRendererIssues_jira"
      },
      {
        "args": [
          (v19/*: any*/),
          (v24/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v25/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "calendarRendererVersions_jira"
      },
      {
        "args": [
          (v19/*: any*/),
          {
            "kind": "Variable",
            "name": "crossProjectVersionsSearchInput",
            "variableName": "crossProjectVersionsSearchInput"
          },
          (v24/*: any*/),
          {
            "kind": "Variable",
            "name": "skipCrossProjectVersionSearch",
            "variableName": "skipCrossProjectVersionSearch"
          },
          (v29/*: any*/),
          (v30/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "calendarRenderer_jira"
      },
      {
        "condition": "skipPanelIssueSearch",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "args": [
              (v19/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v31/*: any*/),
              (v25/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "calendarUnscheduledPanel_calendar"
          }
        ]
      },
      {
        "args": [
          (v32/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v24/*: any*/),
          {
            "kind": "Variable",
            "name": "skipPanelIssueSearch",
            "variableName": "skipPanelIssueSearch"
          },
          (v29/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v28/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "calendarTopControlsBar_calendar"
      },
      {
        "args": [
          (v19/*: any*/),
          (v24/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "calendarIssueViewModal_calendar"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v8/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/),
      (v2/*: any*/),
      (v15/*: any*/),
      (v9/*: any*/),
      (v12/*: any*/),
      (v11/*: any*/),
      (v18/*: any*/),
      (v10/*: any*/),
      (v7/*: any*/),
      (v6/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjiracalendarreleaseflyoutlazyloadingenabledrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "ui_jiraCalendarQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v33/*: any*/),
            "concreteType": "JiraCalendar",
            "kind": "LinkedField",
            "name": "jiraCalendar",
            "plural": false,
            "selections": [
              {
                "alias": "projectsPermission",
                "concreteType": "JiraProjectConnection",
                "kind": "LinkedField",
                "name": "projects",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraProjectEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v34/*: any*/),
                          (v36/*: any*/),
                          (v37/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "name": "startDateField",
                "plural": false,
                "selections": (v39/*: any*/)
              },
              {
                "kind": "LinkedField",
                "name": "endDateField",
                "plural": false,
                "selections": (v39/*: any*/)
              },
              {
                "args": (v40/*: any*/),
                "concreteType": "JiraProjectConnection",
                "kind": "LinkedField",
                "name": "projects",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraProjectEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v37/*: any*/),
                          (v41/*: any*/),
                          (v42/*: any*/),
                          (v36/*: any*/),
                          {
                            "args": (v43/*: any*/),
                            "concreteType": "JiraIssueTypeConnection",
                            "kind": "LinkedField",
                            "name": "issueTypes",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueType",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v37/*: any*/),
                                      (v41/*: any*/),
                                      (v44/*: any*/),
                                      {
                                        "concreteType": "JiraIssueTypeHierarchyLevel",
                                        "kind": "LinkedField",
                                        "name": "hierarchy",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "level"
                                          }
                                        ]
                                      },
                                      (v45/*: any*/),
                                      (v38/*: any*/)
                                    ]
                                  },
                                  (v46/*: any*/)
                                ]
                              },
                              (v47/*: any*/),
                              (v50/*: any*/)
                            ],
                            "storageKey": "issueTypes(first:10)"
                          },
                          {
                            "args": (v43/*: any*/),
                            "handle": "connection",
                            "key": "inlineCardCreate_calendar_issueTypes",
                            "kind": "LinkedHandle",
                            "name": "issueTypes"
                          },
                          {
                            "alias": "admin",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "type",
                                "value": "EDIT_PROJECT_CONFIG"
                              }
                            ],
                            "concreteType": "JiraProjectAction",
                            "kind": "LinkedField",
                            "name": "action",
                            "plural": false,
                            "selections": (v35/*: any*/),
                            "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "projectType"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "projectStyle"
                          },
                          (v51/*: any*/),
                          (v38/*: any*/),
                          (v34/*: any*/)
                        ]
                      },
                      (v46/*: any*/)
                    ]
                  },
                  (v47/*: any*/),
                  (v50/*: any*/)
                ],
                "storageKey": "projects(first:30)"
              },
              {
                "args": (v40/*: any*/),
                "handle": "connection",
                "key": "inlineCardCreate_calendar_projects",
                "kind": "LinkedHandle",
                "name": "projects"
              },
              {
                "args": (v52/*: any*/),
                "concreteType": "JiraScenarioIssueLikeConnection",
                "kind": "LinkedField",
                "name": "issuesV2",
                "plural": false,
                "selections": [
                  (v53/*: any*/)
                ]
              },
              {
                "condition": "pageLoadMoreEnabled",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": "paginatedIssuesV2",
                    "args": (v52/*: any*/),
                    "concreteType": "JiraScenarioIssueLikeConnection",
                    "kind": "LinkedField",
                    "name": "issuesV2",
                    "plural": false,
                    "selections": [
                      (v53/*: any*/),
                      (v54/*: any*/),
                      (v56/*: any*/),
                      {
                        "concreteType": "JiraScenarioIssueLikeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          (v77/*: any*/),
                          (v46/*: any*/)
                        ]
                      },
                      (v78/*: any*/),
                      (v80/*: any*/),
                      (v45/*: any*/)
                    ]
                  },
                  {
                    "alias": "paginatedIssuesV2",
                    "args": (v52/*: any*/),
                    "filters": (v81/*: any*/),
                    "handle": "connection",
                    "key": "calendarRendererIssues_paginatedIssuesV2",
                    "kind": "LinkedHandle",
                    "name": "issuesV2"
                  }
                ]
              },
              {
                "condition": "pageLoadMoreEnabled",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "args": (v52/*: any*/),
                    "concreteType": "JiraScenarioIssueLikeConnection",
                    "kind": "LinkedField",
                    "name": "issuesV2",
                    "plural": false,
                    "selections": [
                      (v54/*: any*/),
                      (v56/*: any*/),
                      {
                        "concreteType": "JiraScenarioIssueLikeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          (v77/*: any*/)
                        ]
                      },
                      (v78/*: any*/),
                      (v80/*: any*/),
                      (v45/*: any*/)
                    ]
                  }
                ]
              },
              {
                "condition": "skipFieldConfig",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "startDateField",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "type"
                      }
                    ]
                  }
                ]
              },
              {
                "condition": "skipVersionsV1Search",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "args": (v82/*: any*/),
                    "concreteType": "JiraVersionConnection",
                    "kind": "LinkedField",
                    "name": "versions",
                    "plural": false,
                    "selections": [
                      (v83/*: any*/),
                      {
                        "concreteType": "JiraVersionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraVersion",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v37/*: any*/),
                              (v41/*: any*/),
                              (v84/*: any*/),
                              (v85/*: any*/),
                              {
                                "args": (v58/*: any*/),
                                "concreteType": "JiraVersionPlanScenarioValues",
                                "kind": "LinkedField",
                                "name": "planScenarioValues",
                                "plural": false,
                                "selections": [
                                  (v41/*: any*/),
                                  (v84/*: any*/),
                                  (v85/*: any*/)
                                ]
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "args": (v58/*: any*/),
                                    "concreteType": "JiraVersionPlanScenarioValues",
                                    "kind": "LinkedField",
                                    "name": "planScenarioValues",
                                    "plural": false,
                                    "selections": [
                                      (v86/*: any*/),
                                      {
                                        "condition": "skipVersionsV2Search",
                                        "kind": "Condition",
                                        "passingValue": false,
                                        "selections": [
                                          (v87/*: any*/),
                                          (v88/*: any*/)
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v89/*: any*/),
                                      (v90/*: any*/),
                                      (v91/*: any*/),
                                      (v92/*: any*/),
                                      (v96/*: any*/)
                                    ],
                                    "type": "JiraVersion"
                                  }
                                ],
                                "type": "JiraScenarioVersionLike",
                                "abstractKey": "__isJiraScenarioVersionLike"
                              }
                            ]
                          },
                          (v38/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "condition": "skipVersionsV2Search",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "args": (v82/*: any*/),
                    "concreteType": "JiraScenarioVersionLikeConnection",
                    "kind": "LinkedField",
                    "name": "versionsV2",
                    "plural": false,
                    "selections": [
                      (v83/*: any*/),
                      {
                        "concreteType": "JiraScenarioVersionLikeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v38/*: any*/),
                              (v37/*: any*/),
                              {
                                "args": (v58/*: any*/),
                                "concreteType": "JiraVersionPlanScenarioValues",
                                "kind": "LinkedField",
                                "name": "planScenarioValues",
                                "plural": false,
                                "selections": [
                                  (v41/*: any*/),
                                  (v88/*: any*/),
                                  (v86/*: any*/),
                                  {
                                    "condition": "skipVersionsV1Search",
                                    "kind": "Condition",
                                    "passingValue": false,
                                    "selections": [
                                      (v84/*: any*/),
                                      (v85/*: any*/)
                                    ]
                                  },
                                  {
                                    "condition": "skipVersionsV2Search",
                                    "kind": "Condition",
                                    "passingValue": false,
                                    "selections": [
                                      (v87/*: any*/)
                                    ]
                                  }
                                ]
                              },
                              {
                                "kind": "TypeDiscriminator",
                                "abstractKey": "__isJiraScenarioVersionLike"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v41/*: any*/),
                                  (v84/*: any*/),
                                  (v85/*: any*/),
                                  (v89/*: any*/),
                                  (v90/*: any*/),
                                  (v91/*: any*/),
                                  (v92/*: any*/),
                                  (v96/*: any*/)
                                ],
                                "type": "JiraVersion"
                              }
                            ]
                          },
                          (v38/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "condition": "skipCrossProjectVersionSearch",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "input",
                        "variableName": "crossProjectVersionsSearchInput"
                      }
                    ],
                    "concreteType": "JiraCrossProjectVersionConnection",
                    "kind": "LinkedField",
                    "name": "crossProjectVersions",
                    "plural": false,
                    "selections": [
                      (v83/*: any*/),
                      {
                        "concreteType": "JiraCrossProjectVersionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraCrossProjectVersion",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v37/*: any*/),
                              (v41/*: any*/),
                              (v84/*: any*/),
                              (v85/*: any*/),
                              {
                                "concreteType": "JiraCrossProjectVersionPlanScenarioValues",
                                "kind": "LinkedField",
                                "name": "crossProjectVersionScenarioValues",
                                "plural": false,
                                "selections": [
                                  (v41/*: any*/),
                                  (v86/*: any*/)
                                ]
                              },
                              (v90/*: any*/),
                              {
                                "concreteType": "JiraProject",
                                "kind": "LinkedField",
                                "name": "projects",
                                "plural": true,
                                "selections": [
                                  (v37/*: any*/),
                                  (v41/*: any*/),
                                  (v42/*: any*/),
                                  (v94/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "condition": "skipSprintSearch",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "input",
                        "variableName": "sprintsSearchInput"
                      }
                    ],
                    "concreteType": "JiraSprintConnection",
                    "kind": "LinkedField",
                    "name": "sprints",
                    "plural": false,
                    "selections": [
                      (v83/*: any*/),
                      {
                        "concreteType": "JiraSprintEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraSprint",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v37/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "sprintId"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "boardId"
                              },
                              (v41/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "goal"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "state"
                              },
                              (v84/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "endDate"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "completionDate"
                              },
                              (v45/*: any*/)
                            ]
                          }
                        ]
                      },
                      (v47/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          {
            "args": [
              (v32/*: any*/)
            ],
            "concreteType": "JiraUserPreferences",
            "kind": "LinkedField",
            "name": "userPreferences",
            "plural": false,
            "selections": [
              (v45/*: any*/),
              {
                "kind": "ScalarField",
                "name": "jqlBuilderSearchMode"
              }
            ]
          }
        ]
      },
      {
        "condition": "skipPanelIssueSearch",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "panelJira",
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "args": (v33/*: any*/),
                "concreteType": "JiraCalendar",
                "kind": "LinkedField",
                "name": "jiraCalendar",
                "plural": false,
                "selections": [
                  {
                    "args": (v97/*: any*/),
                    "concreteType": "JiraIssueConnection",
                    "kind": "LinkedField",
                    "name": "unscheduledIssues",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraIssueEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraIssue",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v37/*: any*/),
                              (v51/*: any*/),
                              (v57/*: any*/),
                              (v72/*: any*/),
                              {
                                "concreteType": "JiraPriorityField",
                                "kind": "LinkedField",
                                "name": "priorityField",
                                "plural": false,
                                "selections": [
                                  (v41/*: any*/),
                                  {
                                    "concreteType": "JiraPriority",
                                    "kind": "LinkedField",
                                    "name": "priority",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "iconUrl"
                                      },
                                      (v37/*: any*/)
                                    ]
                                  },
                                  (v37/*: any*/)
                                ]
                              },
                              {
                                "concreteType": "JiraStatusField",
                                "kind": "LinkedField",
                                "name": "statusField",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraStatus",
                                    "kind": "LinkedField",
                                    "name": "status",
                                    "plural": false,
                                    "selections": [
                                      (v41/*: any*/),
                                      {
                                        "concreteType": "JiraStatusCategory",
                                        "kind": "LinkedField",
                                        "name": "statusCategory",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "statusCategoryId"
                                          },
                                          (v37/*: any*/)
                                        ]
                                      },
                                      (v37/*: any*/)
                                    ]
                                  },
                                  (v37/*: any*/)
                                ]
                              },
                              {
                                "args": (v58/*: any*/),
                                "kind": "LinkedField",
                                "name": "startDateViewField",
                                "plural": false,
                                "selections": [
                                  (v38/*: any*/),
                                  (v41/*: any*/),
                                  (v37/*: any*/),
                                  (v60/*: any*/),
                                  (v61/*: any*/),
                                  (v45/*: any*/)
                                ]
                              },
                              (v68/*: any*/),
                              (v69/*: any*/),
                              (v63/*: any*/),
                              (v38/*: any*/),
                              (v75/*: any*/),
                              (v45/*: any*/)
                            ]
                          },
                          (v46/*: any*/)
                        ]
                      },
                      {
                        "concreteType": "QueryError",
                        "kind": "LinkedField",
                        "name": "errors",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "identifier"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "message"
                          },
                          {
                            "kind": "LinkedField",
                            "name": "extensions",
                            "plural": true,
                            "selections": [
                              (v38/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "statusCode"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "errorType"
                              }
                            ]
                          }
                        ]
                      },
                      (v50/*: any*/),
                      (v45/*: any*/)
                    ]
                  },
                  {
                    "args": (v97/*: any*/),
                    "filters": (v81/*: any*/),
                    "handle": "connection",
                    "key": "calendarUnscheduledPanelCardList_calendar__unscheduledIssues",
                    "kind": "LinkedHandle",
                    "name": "unscheduledIssues"
                  }
                ]
              }
            ]
          },
          {
            "alias": "unscheduledPanelToggleJira",
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "args": (v33/*: any*/),
                "concreteType": "JiraCalendar",
                "kind": "LinkedField",
                "name": "jiraCalendar",
                "plural": false,
                "selections": [
                  {
                    "args": (v97/*: any*/),
                    "concreteType": "JiraIssueConnection",
                    "kind": "LinkedField",
                    "name": "unscheduledIssues",
                    "plural": false,
                    "selections": [
                      (v53/*: any*/),
                      (v45/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "72f806bda28214873495762d1f97b501dfbd65493a41644b68714968cdc1bb2a",
    "metadata": {},
    "name": "ui_jiraCalendarQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcisjiracalendarreleaseflyoutlazyloadingenabledrelayprovider": isJiraCalendarReleaseFlyoutLazyLoadingEnabled_provider
    }
  }
};
})();

(node as any).hash = "b27a1c1f2095b08f035ccb23bcd27dd8";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
