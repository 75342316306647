/**
 * @generated SignedSource<<0e2c6027a63a4b243484dd595c4122e4>>
 * @relayHash 37576ab32048cc7cbca1397ea6b8530e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 053135f7414058ca6fe33f925deed677d09f195278a95bf2d60dc1ea57d97916

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type GraphStoreDeleteProjectHasRelatedWorkWithProjectInput = {
  relationships: ReadonlyArray<GraphStoreDeleteProjectHasRelatedWorkWithProjectRelationshipInput>;
  synchronousWrite?: boolean | null | undefined;
};
export type GraphStoreDeleteProjectHasRelatedWorkWithProjectRelationshipInput = {
  from: string;
  to: string;
};
export type useDisconnectExternalProjectMutation$variables = {
  input?: GraphStoreDeleteProjectHasRelatedWorkWithProjectInput | null | undefined;
};
export type useDisconnectExternalProjectMutation$data = {
  readonly graphStore: {
    readonly deleteProjectHasRelatedWorkWithProject: {
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly statusCode: number | null | undefined;
        } | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type useDisconnectExternalProjectMutation = {
  response: useDisconnectExternalProjectMutation$data;
  variables: useDisconnectExternalProjectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "kind": "ScalarField",
  "name": "message"
},
v4 = {
  "kind": "ScalarField",
  "name": "statusCode"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "useDisconnectExternalProjectMutation",
    "selections": [
      {
        "concreteType": "GraphStoreMutation",
        "kind": "LinkedField",
        "name": "graphStore",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "GraphStoreDeleteProjectHasRelatedWorkWithProjectPayload",
            "kind": "LinkedField",
            "name": "deleteProjectHasRelatedWorkWithProject",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDisconnectExternalProjectMutation",
    "selections": [
      {
        "concreteType": "GraphStoreMutation",
        "kind": "LinkedField",
        "name": "graphStore",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "GraphStoreDeleteProjectHasRelatedWorkWithProjectPayload",
            "kind": "LinkedField",
            "name": "deleteProjectHasRelatedWorkWithProject",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v4/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "id"
                          }
                        ],
                        "type": "BulkMutationErrorExtension"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "053135f7414058ca6fe33f925deed677d09f195278a95bf2d60dc1ea57d97916",
    "metadata": {},
    "name": "useDisconnectExternalProjectMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "6e1b10fbb05056c742efc0dc1a2ddc8c";

export default node;
