import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	simpleSearch: {
		id: 'calendar.calendar-top-controls-bar.business-filters.simple-search',
		defaultMessage: 'Search calendar',
		description:
			'Placeholder of the text field above the calendar that allows the user to search by text',
	},
});
