import type { Action } from '@atlassian/react-sweet-state';
import type { ConnectionStoreState } from './types.tsx';
import { withReadyCheck } from './utils.tsx';

/** @deprecated This store is being removed once subscriptions are in place for its use case. */
export function setCalendarRendererConnectionId(
	calendarRendererConnectionId: string | undefined,
): Action<ConnectionStoreState> {
	return withReadyCheck(({ setState }) => {
		setState({
			calendarRendererConnectionId,
		});
	});
}

/** @deprecated This store is being removed once subscriptions are in place for its use case. */
export function setUnscheduledPanelConnectionId(
	unscheduledPanelConnectionId: string | undefined,
): Action<ConnectionStoreState> {
	return withReadyCheck(({ setState }) => {
		setState({
			unscheduledPanelConnectionId,
		});
	});
}

/** @deprecated This store is being removed once subscriptions are in place for its use case. */
export function setUnscheduledToggleConnectionId(
	unscheduledToggleConnectionId: string | undefined,
): Action<ConnectionStoreState> {
	return withReadyCheck(({ setState }) => {
		setState({
			unscheduledToggleConnectionId,
		});
	});
}
