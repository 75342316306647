import React, { useCallback } from 'react';

import type { DayHeaderContentArg, FormatDateOptions } from '@fullcalendar/core';
import format from 'date-fns/format';

import type { CalendarView, CalendarViewRange } from '../../../common/types';

import { DayText, GridDayContainer, GridDayNumber } from './styled';

export type DayHeaderContentProps = Pick<
	DayHeaderContentArg,
	'text' | 'date' | 'isPast' | 'isToday'
>;

export const useDayHeaderContent = (
	view: CalendarView,
	viewRange: CalendarViewRange,
	formatDate: (date: Date, options: FormatDateOptions) => string,
) =>
	useCallback(
		function DayHeader({ text, date, isPast, isToday }: DayHeaderContentProps) {
			if (view === 'list') {
				return <>{format(date, 'EEEE, do MMMM yyyy')}</>;
			}
			if (viewRange === 'month') {
				return <DayText>{text}</DayText>;
			}
			return (
				<GridDayContainer isPast={isPast}>
					<DayText>{formatDate(date, { weekday: 'short' })}</DayText>
					<GridDayNumber isToday={isToday}>{formatDate(date, { day: 'numeric' })}</GridDayNumber>
				</GridDayContainer>
			);
		},
		[view, viewRange, formatDate],
	);
