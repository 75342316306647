import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	errorTitle: {
		id: 'calendar.controllers.use-update-version-date-mutation.error-title',
		defaultMessage: 'Something went wrong',
		description:
			'Title for the error message displayed when the calendar fails to update version dates',
	},
	failedToUpdateDatesError: {
		id: 'calendar.controllers.use-update-version-date-mutation.failed-to-update-dates-error',
		defaultMessage: 'Failed to update version dates.',
		description:
			'Error message displayed when the calendar fails to update version on drag-and-drop.',
	},
	releaseBeforeStartDateErrorTitle: {
		id: 'calendar.controllers.use-update-version-date-mutation.release-before-start-date-error-title',
		defaultMessage: 'Release date conflicts with start date',
		description:
			'Title for the error message displayed when the user tries to move the release date before the start date',
	},
	releaseBeforeStartDateError: {
		id: 'calendar.controllers.use-update-version-date-mutation.release-before-start-date-error',
		defaultMessage:
			'The release date can’t be moved before the start date. To change the dates, open the release, select <b>More actions (•••)</b>, then <b>Edit release</b>.',
		description:
			'Error message displayed when the user tries to move the release date before the start date',
	},
});
