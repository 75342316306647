/**
 * @generated SignedSource<<050dfaaf1edd1425fa4e915625bdf141>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type actionsMenu_ReleaseFlyout$data = {
  readonly canEdit: boolean | null | undefined;
  readonly versionId: string;
  readonly " $fragmentType": "actionsMenu_ReleaseFlyout";
};
export type actionsMenu_ReleaseFlyout$key = {
  readonly " $data"?: actionsMenu_ReleaseFlyout$data;
  readonly " $fragmentSpreads": FragmentRefs<"actionsMenu_ReleaseFlyout">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "actionsMenu_ReleaseFlyout",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "versionId"
    },
    {
      "kind": "ScalarField",
      "name": "canEdit"
    }
  ],
  "type": "JiraVersion"
};

(node as any).hash = "74085ef5ab69dbc936e42420ec5c32a0";

export default node;
