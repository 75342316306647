export const APP_INFO_MAP = {
	zoom: {
		scopeType: 'jsm-chat-ops-zoom-integration',
		chatType: 'zoom',
		appType: 'jsm-zoom-app',
	},
	msteams: {
		scopeType: 'jsm-chat-ops-msteams-integration',
		chatType: 'msteams',
		appType: 'jsm-msteams-app',
	},
};

export const ISSUER_TYPE = 'jsm-issue';
export const ENTITY_TYPE = 'jsm-project';

export const CONVERSATION_TYPE_MAP = {
	zoom: 'zoom-meeting',
};
