import { useEffect } from 'react';
import type { RefetchFnDynamic } from 'react-relay';
import type { Subscription } from 'relay-runtime';
import type { calendarRendererIssues_jira$key } from '@atlassian/jira-relay/src/__generated__/calendarRendererIssues_jira.graphql';
import type { calendarRendererIssues_jira_refetchQuery } from '@atlassian/jira-relay/src/__generated__/calendarRendererIssues_jira_refetchQuery.graphql';
import { useGlobalIssueCreateContext } from '../../../controllers/global-issue-create-provider/index.tsx';
import { useRefetchCalendarIssues } from '../../../controllers/use-refetch-issues/index.tsx';

/**
 * Refetch calendar when a global issue is created.
 *
 * @param refetch - The refetch function.
 */
export function useRefetchOnGlobalIssueCreate({
	refetch,
}: {
	refetch: RefetchFnDynamic<
		calendarRendererIssues_jira_refetchQuery,
		calendarRendererIssues_jira$key
	>;
}) {
	const { refetchIssuesWithoutSuspense } = useRefetchCalendarIssues({ refetch });
	const { handleGlobalIssueCreate } = useGlobalIssueCreateContext();

	useEffect(() => {
		let subscription: Subscription;

		handleGlobalIssueCreate((_, issues) => {
			if (!issues?.length) {
				return;
			}
			subscription = refetchIssuesWithoutSuspense();
		});

		return () => subscription?.unsubscribe?.();
	}, [handleGlobalIssueCreate, refetch, refetchIssuesWithoutSuspense]);
}
