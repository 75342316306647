/**
 * @generated SignedSource<<a8e5f258b8fab90ff0c701a1f42c3efd>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type groupFieldValueLabel_softwareFiltersPopup$data = {
  readonly group: {
    readonly name: string;
  };
  readonly " $fragmentType": "groupFieldValueLabel_softwareFiltersPopup";
};
export type groupFieldValueLabel_softwareFiltersPopup$key = {
  readonly " $data"?: groupFieldValueLabel_softwareFiltersPopup$data;
  readonly " $fragmentSpreads": FragmentRefs<"groupFieldValueLabel_softwareFiltersPopup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "groupFieldValueLabel_softwareFiltersPopup",
  "selections": [
    {
      "concreteType": "JiraGroup",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "name"
        }
      ]
    }
  ],
  "type": "JiraJqlGroupFieldValue"
};

(node as any).hash = "0871dc403adf51b1b9f2182303d02fe2";

export default node;
