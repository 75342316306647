import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	filtersCount: {
		id: 'software-filters-popup.filters-count',
		defaultMessage:
			'{filters, number} {filters, plural, one {filter applied} other {filters applied}}',
		description: 'Filter button text that shows number of filters',
	},
	filters: {
		id: 'software-filters-popup.filters',
		defaultMessage: 'Filters',
		description: 'Label for the filters popup button',
	},
});
