/**
 * @generated SignedSource<<cd8891cd68f8836e825b777482f02c1c>>
 * @relayHash bd7050fa650e17e75c7213d9ce2eebcf
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1abb7aa4625b4051e3889bfee2bc6606d7f3de82dca66cb1b6fba897f93497f5

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraCalendarMode = "DAY" | "MONTH" | "WEEK" | "%future added value";
export type JiraCalendarWeekStart = "MONDAY" | "SATURDAY" | "SUNDAY" | "%future added value";
export type JiraViewScopeInput = {
  ids?: ReadonlyArray<string> | null | undefined;
  projectKeys?: JiraProjectKeysInput | null | undefined;
};
export type JiraProjectKeysInput = {
  cloudId: string;
  keys?: ReadonlyArray<string> | null | undefined;
};
export type JiraCalendarViewConfigurationInput = {
  date?: AGG$DateTime | null | undefined;
  endDateField?: string | null | undefined;
  mode?: JiraCalendarMode | null | undefined;
  startDateField?: string | null | undefined;
  viewId?: string | null | undefined;
  weekStart?: JiraCalendarWeekStart | null | undefined;
};
export type useScheduleCalendarIssueMutation$variables = {
  configuration: JiraCalendarViewConfigurationInput;
  endDateInput?: AGG$DateTime | null | undefined;
  issueId: string;
  schedulePermissionsEnabled: boolean;
  scope: JiraViewScopeInput;
  startDateInput?: AGG$DateTime | null | undefined;
  viewId?: string | null | undefined;
};
export type useScheduleCalendarIssueMutation$data = {
  readonly jira: {
    readonly scheduleCalendarIssueV2: {
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly errorType: string | null | undefined;
          readonly statusCode: number | null | undefined;
        } | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly issue: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"issueRenderer_calendar_IssueEventRenderer">;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type useScheduleCalendarIssueMutation = {
  response: useScheduleCalendarIssueMutation$data;
  variables: useScheduleCalendarIssueMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "configuration"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDateInput"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "schedulePermissionsEnabled"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scope"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDateInput"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v7 = [
  {
    "kind": "Variable",
    "name": "configuration",
    "variableName": "configuration"
  },
  {
    "kind": "Variable",
    "name": "endDateInput",
    "variableName": "endDateInput"
  },
  {
    "kind": "Variable",
    "name": "issueId",
    "variableName": "issueId"
  },
  {
    "kind": "Variable",
    "name": "scope",
    "variableName": "scope"
  },
  {
    "kind": "Variable",
    "name": "startDateInput",
    "variableName": "startDateInput"
  }
],
v8 = {
  "kind": "ScalarField",
  "name": "success"
},
v9 = {
  "kind": "ScalarField",
  "name": "message"
},
v10 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v11 = {
  "kind": "ScalarField",
  "name": "errorType"
},
v12 = {
  "kind": "ScalarField",
  "name": "id"
},
v13 = {
  "kind": "Variable",
  "name": "viewId",
  "variableName": "viewId"
},
v14 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v15 = {
  "kind": "ScalarField",
  "name": "key"
},
v16 = {
  "kind": "ScalarField",
  "name": "name"
},
v17 = [
  (v16/*: any*/),
  {
    "concreteType": "JiraAvatar",
    "kind": "LinkedField",
    "name": "avatar",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "small"
      }
    ]
  },
  (v12/*: any*/)
],
v18 = {
  "concreteType": "JiraIssueTypeField",
  "kind": "LinkedField",
  "name": "issueTypeField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": (v17/*: any*/)
    },
    (v12/*: any*/)
  ]
},
v19 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "text"
    },
    (v12/*: any*/)
  ]
},
v20 = [
  (v13/*: any*/)
],
v21 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "date"
    }
  ],
  "type": "JiraDatePickerField"
},
v22 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v23 = [
  (v14/*: any*/),
  (v12/*: any*/),
  (v21/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "kind": "ScalarField",
        "name": "dateTime"
      }
    ],
    "type": "JiraDateTimePickerField"
  },
  (v22/*: any*/)
],
v24 = {
  "concreteType": "JiraStatusField",
  "kind": "LinkedField",
  "name": "statusField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraStatusCategory",
          "kind": "LinkedField",
          "name": "statusCategory",
          "plural": false,
          "selections": [
            (v15/*: any*/),
            (v12/*: any*/)
          ]
        },
        (v12/*: any*/),
        (v16/*: any*/)
      ]
    },
    (v12/*: any*/)
  ]
},
v25 = [
  (v14/*: any*/),
  (v12/*: any*/),
  (v21/*: any*/),
  (v22/*: any*/)
],
v26 = {
  "kind": "LinkedField",
  "name": "startDateViewField",
  "plural": false,
  "selections": (v25/*: any*/)
},
v27 = {
  "kind": "LinkedField",
  "name": "endDateViewField",
  "plural": false,
  "selections": (v25/*: any*/)
},
v28 = {
  "concreteType": "JiraSingleSelectUserPickerField",
  "kind": "LinkedField",
  "name": "assigneeField",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v14/*: any*/),
        (v16/*: any*/),
        {
          "kind": "ScalarField",
          "name": "picture"
        },
        (v12/*: any*/)
      ]
    },
    (v12/*: any*/)
  ]
},
v29 = {
  "concreteType": "JiraRichTextField",
  "kind": "LinkedField",
  "name": "descriptionField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraRichText",
      "kind": "LinkedField",
      "name": "richText",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraADF",
          "kind": "LinkedField",
          "name": "adfValue",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraAdfToConvertedPlainText",
              "kind": "LinkedField",
              "name": "convertedPlainText",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "plainText"
                }
              ]
            }
          ]
        }
      ]
    },
    (v12/*: any*/)
  ]
},
v30 = {
  "concreteType": "JiraProjectField",
  "kind": "LinkedField",
  "name": "projectField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": (v17/*: any*/)
    },
    (v12/*: any*/)
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "name": "useScheduleCalendarIssueMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v7/*: any*/),
            "concreteType": "JiraScheduleCalendarIssueWithScenarioPayload",
            "kind": "LinkedField",
            "name": "scheduleCalendarIssueV2",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "name": "issue",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "schedulePermissionsEnabled",
                        "variableName": "schedulePermissionsEnabled"
                      },
                      (v13/*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "issueRenderer_calendar_IssueEventRenderer"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "useScheduleCalendarIssueMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v7/*: any*/),
            "concreteType": "JiraScheduleCalendarIssueWithScenarioPayload",
            "kind": "LinkedField",
            "name": "scheduleCalendarIssueV2",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v12/*: any*/)
                        ],
                        "type": "BulkMutationErrorExtension"
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "name": "issue",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  (v12/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isJiraScenarioIssueLike"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v15/*: any*/),
                      (v18/*: any*/),
                      {
                        "alias": "requestTypeField",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "idOrAlias",
                            "value": "requesttype"
                          },
                          {
                            "kind": "Literal",
                            "name": "ignoreMissingField",
                            "value": true
                          }
                        ],
                        "kind": "LinkedField",
                        "name": "fieldByIdOrAlias",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          (v12/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraServiceManagementRequestType",
                                "kind": "LinkedField",
                                "name": "requestType",
                                "plural": false,
                                "selections": (v17/*: any*/)
                              }
                            ],
                            "type": "JiraServiceManagementRequestTypeField"
                          }
                        ],
                        "storageKey": "fieldByIdOrAlias(idOrAlias:\"requesttype\",ignoreMissingField:true)"
                      },
                      (v19/*: any*/),
                      {
                        "args": (v20/*: any*/),
                        "kind": "LinkedField",
                        "name": "startDateViewField",
                        "plural": false,
                        "selections": (v23/*: any*/)
                      },
                      {
                        "args": (v20/*: any*/),
                        "kind": "LinkedField",
                        "name": "endDateViewField",
                        "plural": false,
                        "selections": (v23/*: any*/)
                      },
                      (v24/*: any*/),
                      {
                        "args": (v20/*: any*/),
                        "concreteType": "JiraScenarioIssueValues",
                        "kind": "LinkedField",
                        "name": "planScenarioValues",
                        "plural": false,
                        "selections": [
                          (v19/*: any*/),
                          (v18/*: any*/),
                          (v26/*: any*/),
                          (v27/*: any*/),
                          (v24/*: any*/),
                          (v28/*: any*/),
                          (v29/*: any*/),
                          (v30/*: any*/)
                        ]
                      },
                      (v28/*: any*/),
                      (v29/*: any*/),
                      (v30/*: any*/),
                      {
                        "condition": "schedulePermissionsEnabled",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "alias": "canSchedule",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "permission",
                                "value": "SCHEDULE_ISSUES"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "hasProjectPermission",
                            "storageKey": "hasProjectPermission(permission:\"SCHEDULE_ISSUES\")"
                          }
                        ]
                      }
                    ],
                    "type": "JiraIssue"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "args": (v20/*: any*/),
                        "concreteType": "JiraScenarioIssueValues",
                        "kind": "LinkedField",
                        "name": "planScenarioValues",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "scenarioKey"
                          },
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v26/*: any*/),
                          (v27/*: any*/),
                          (v24/*: any*/),
                          (v28/*: any*/),
                          (v29/*: any*/),
                          (v30/*: any*/)
                        ]
                      }
                    ],
                    "type": "JiraScenarioIssue"
                  },
                  (v22/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "1abb7aa4625b4051e3889bfee2bc6606d7f3de82dca66cb1b6fba897f93497f5",
    "metadata": {},
    "name": "useScheduleCalendarIssueMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "729d10099605dd7aaca9bc6b2b207531";

export default node;
