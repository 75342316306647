/**
 * @generated SignedSource<<d18f3cdd45f30497b9b05e23116a0c9f>>
 * @relayHash 6b9b440a3506310fa15456ce569ff3d0
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID dd2996174d39839cf0748404dcb8a51dffb92a44f61ab91b224d055b61a18c0c

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraCalendarMode = "DAY" | "MONTH" | "WEEK" | "%future added value";
export type JiraCalendarWeekStart = "MONDAY" | "SATURDAY" | "SUNDAY" | "%future added value";
export type JiraCalendarViewConfigurationInput = {
  date?: AGG$DateTime | null | undefined;
  endDateField?: string | null | undefined;
  mode?: JiraCalendarMode | null | undefined;
  startDateField?: string | null | undefined;
  viewId?: string | null | undefined;
  weekStart?: JiraCalendarWeekStart | null | undefined;
};
export type JiraViewScopeInput = {
  ids?: ReadonlyArray<string> | null | undefined;
  projectKeys?: JiraProjectKeysInput | null | undefined;
};
export type JiraProjectKeysInput = {
  cloudId: string;
  keys?: ReadonlyArray<string> | null | undefined;
};
export type inlineCardCreate_calendar_RefetchPaginated_Query$variables = {
  configurationInput: JiraCalendarViewConfigurationInput;
  cursor?: string | null | undefined;
  first?: number | null | undefined;
  scopeInput: JiraViewScopeInput;
};
export type inlineCardCreate_calendar_RefetchPaginated_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_calendar_CalendarInlineCardCreate">;
};
export type inlineCardCreate_calendar_RefetchPaginated_Query = {
  response: inlineCardCreate_calendar_RefetchPaginated_Query$data;
  variables: inlineCardCreate_calendar_RefetchPaginated_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "configurationInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": 30,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scopeInput"
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = [
  (v2/*: any*/),
  {
    "kind": "ScalarField",
    "name": "fieldId"
  },
  (v3/*: any*/)
],
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v1/*: any*/)
],
v6 = {
  "kind": "ScalarField",
  "name": "name"
},
v7 = [
  {
    "kind": "ScalarField",
    "name": "canPerform"
  }
],
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v9 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v10 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v11 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "endCursor"
    },
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "inlineCardCreate_calendar_RefetchPaginated_Query",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "configurationInput",
            "variableName": "configurationInput"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "scopeInput",
            "variableName": "scopeInput"
          }
        ],
        "kind": "FragmentSpread",
        "name": "main_calendar_CalendarInlineCardCreate"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "inlineCardCreate_calendar_RefetchPaginated_Query",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "configuration",
                "variableName": "configurationInput"
              },
              {
                "kind": "Variable",
                "name": "scope",
                "variableName": "scopeInput"
              }
            ],
            "concreteType": "JiraCalendar",
            "kind": "LinkedField",
            "name": "jiraCalendar",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "name": "startDateField",
                "plural": false,
                "selections": (v4/*: any*/)
              },
              {
                "kind": "LinkedField",
                "name": "endDateField",
                "plural": false,
                "selections": (v4/*: any*/)
              },
              {
                "args": (v5/*: any*/),
                "concreteType": "JiraProjectConnection",
                "kind": "LinkedField",
                "name": "projects",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraProjectEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v6/*: any*/),
                          {
                            "concreteType": "JiraAvatar",
                            "kind": "LinkedField",
                            "name": "avatar",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "xsmall"
                              }
                            ]
                          },
                          {
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "type",
                                "value": "CREATE_ISSUES"
                              }
                            ],
                            "concreteType": "JiraProjectAction",
                            "kind": "LinkedField",
                            "name": "action",
                            "plural": false,
                            "selections": (v7/*: any*/),
                            "storageKey": "action(type:\"CREATE_ISSUES\")"
                          },
                          {
                            "args": (v8/*: any*/),
                            "concreteType": "JiraIssueTypeConnection",
                            "kind": "LinkedField",
                            "name": "issueTypes",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueType",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v6/*: any*/),
                                      {
                                        "concreteType": "JiraAvatar",
                                        "kind": "LinkedField",
                                        "name": "avatar",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "small"
                                          }
                                        ]
                                      },
                                      {
                                        "concreteType": "JiraIssueTypeHierarchyLevel",
                                        "kind": "LinkedField",
                                        "name": "hierarchy",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "level"
                                          }
                                        ]
                                      },
                                      {
                                        "kind": "ClientExtension",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "__id"
                                          }
                                        ]
                                      },
                                      (v2/*: any*/)
                                    ]
                                  },
                                  (v9/*: any*/)
                                ]
                              },
                              (v10/*: any*/),
                              (v11/*: any*/)
                            ],
                            "storageKey": "issueTypes(first:10)"
                          },
                          {
                            "args": (v8/*: any*/),
                            "handle": "connection",
                            "key": "inlineCardCreate_calendar_issueTypes",
                            "kind": "LinkedHandle",
                            "name": "issueTypes"
                          },
                          {
                            "alias": "admin",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "type",
                                "value": "EDIT_PROJECT_CONFIG"
                              }
                            ],
                            "concreteType": "JiraProjectAction",
                            "kind": "LinkedField",
                            "name": "action",
                            "plural": false,
                            "selections": (v7/*: any*/),
                            "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "projectType"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "projectStyle"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "key"
                          },
                          (v2/*: any*/)
                        ]
                      },
                      (v9/*: any*/)
                    ]
                  },
                  (v10/*: any*/),
                  (v11/*: any*/)
                ]
              },
              {
                "args": (v5/*: any*/),
                "handle": "connection",
                "key": "inlineCardCreate_calendar_projects",
                "kind": "LinkedHandle",
                "name": "projects"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "dd2996174d39839cf0748404dcb8a51dffb92a44f61ab91b224d055b61a18c0c",
    "metadata": {},
    "name": "inlineCardCreate_calendar_RefetchPaginated_Query",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "014dae0a79309dafe4880f6fbfc971ea";

export default node;
