/**
 * @generated SignedSource<<9ea330256b51e7faa26d6733b92e35ad>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type viewSettings_calendar_CalendarViewSettings$data = {
  readonly jira: {
    readonly jiraCalendar: {
      readonly issuesV2: {
        readonly totalIssueSearchResultCount: number | null | undefined;
      } | null | undefined;
      readonly sprints?: {
        readonly totalCount: number | null | undefined;
      } | null | undefined;
      readonly versions?: {
        readonly edges: ReadonlyArray<{
          readonly __typename: "JiraVersionEdge";
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly versionsV2?: {
        readonly edges: ReadonlyArray<{
          readonly __typename: "JiraScenarioVersionLikeEdge";
        } | null | undefined> | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "viewSettings_calendar_CalendarViewSettings";
};
export type viewSettings_calendar_CalendarViewSettings$key = {
  readonly " $data"?: viewSettings_calendar_CalendarViewSettings$data;
  readonly " $fragmentSpreads": FragmentRefs<"viewSettings_calendar_CalendarViewSettings">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "versionsSearchInput"
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "name": "__typename"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "configurationInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "issuesPageCursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "issuesPageSize"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "issuesSearchInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipSprintSearch"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipVersionsV1Search"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipVersionsV2Search"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sprintsSearchInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "versionsSearchInput"
    }
  ],
  "kind": "Fragment",
  "name": "viewSettings_calendar_CalendarViewSettings",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "configuration",
              "variableName": "configurationInput"
            },
            {
              "kind": "Variable",
              "name": "scope",
              "variableName": "scopeInput"
            }
          ],
          "concreteType": "JiraCalendar",
          "kind": "LinkedField",
          "name": "jiraCalendar",
          "plural": false,
          "selections": [
            {
              "condition": "skipVersionsV1Search",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "args": (v0/*: any*/),
                  "concreteType": "JiraVersionConnection",
                  "kind": "LinkedField",
                  "name": "versions",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraVersionEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": (v1/*: any*/)
                    }
                  ]
                }
              ]
            },
            {
              "condition": "skipVersionsV2Search",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "args": (v0/*: any*/),
                  "concreteType": "JiraScenarioVersionLikeConnection",
                  "kind": "LinkedField",
                  "name": "versionsV2",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraScenarioVersionLikeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": (v1/*: any*/)
                    }
                  ]
                }
              ]
            },
            {
              "condition": "skipSprintSearch",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "input",
                      "variableName": "sprintsSearchInput"
                    }
                  ],
                  "concreteType": "JiraSprintConnection",
                  "kind": "LinkedField",
                  "name": "sprints",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "totalCount"
                    }
                  ]
                }
              ]
            },
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "after",
                  "variableName": "issuesPageCursor"
                },
                {
                  "kind": "Variable",
                  "name": "first",
                  "variableName": "issuesPageSize"
                },
                {
                  "kind": "Variable",
                  "name": "input",
                  "variableName": "issuesSearchInput"
                }
              ],
              "concreteType": "JiraScenarioIssueLikeConnection",
              "kind": "LinkedField",
              "name": "issuesV2",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "totalIssueSearchResultCount"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "efcfa1c1938ccb14f411e3a6d46e6c97";

export default node;
