/**
 * @generated SignedSource<<0ac29591d755f9dc732c80c33c498c06>>
 * @relayHash ea8dea996b9787a0689cefeae82f89ef
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9abf59b1e25572f2093f9e0db8425fdb344958687405a2824edc2c27a5c93bb6

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraCalendarMode = "DAY" | "MONTH" | "WEEK" | "%future added value";
export type JiraCalendarWeekStart = "MONDAY" | "SATURDAY" | "SUNDAY" | "%future added value";
export type JiraVersionStatus = "ARCHIVED" | "RELEASED" | "UNRELEASED" | "%future added value";
export type JiraCalendarViewConfigurationInput = {
  date?: AGG$DateTime | null | undefined;
  endDateField?: string | null | undefined;
  mode?: JiraCalendarMode | null | undefined;
  startDateField?: string | null | undefined;
  viewId?: string | null | undefined;
  weekStart?: JiraCalendarWeekStart | null | undefined;
};
export type JiraViewScopeInput = {
  ids?: ReadonlyArray<string> | null | undefined;
  projectKeys?: JiraProjectKeysInput | null | undefined;
};
export type JiraProjectKeysInput = {
  cloudId: string;
  keys?: ReadonlyArray<string> | null | undefined;
};
export type JiraCalendarVersionsInput = {
  additionalProjectAris?: ReadonlyArray<string> | null | undefined;
  includeSharedReleases?: boolean | null | undefined;
  versionStatuses?: ReadonlyArray<JiraVersionStatus> | null | undefined;
};
export type calendarRendererVersions_jira_refetchQuery$variables = {
  configurationInput: JiraCalendarViewConfigurationInput;
  scopeInput: JiraViewScopeInput;
  skipVersionsV1Search: boolean;
  skipVersionsV2Search: boolean;
  versionsSearchInput?: JiraCalendarVersionsInput | null | undefined;
  viewId?: string | null | undefined;
};
export type calendarRendererVersions_jira_refetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"calendarRendererVersions_jira">;
};
export type calendarRendererVersions_jira_refetchQuery = {
  response: calendarRendererVersions_jira_refetchQuery$data;
  variables: calendarRendererVersions_jira_refetchQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcisjiracalendarreleaseflyoutlazyloadingenabledrelayprovider": isJiraCalendarReleaseFlyoutLazyLoadingEnabled_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisjiracalendarreleaseflyoutlazyloadingenabledrelayprovider: {
    readonly get: () => boolean;
  };
});

import isJiraCalendarReleaseFlyoutLazyLoadingEnabled_provider from '@atlassian/jira-relay-provider/src/is-jira-calendar-release-flyout-lazy-loading-enabled.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "configurationInput"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scopeInput"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipVersionsV1Search"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipVersionsV2Search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "versionsSearchInput"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v6 = {
  "kind": "Variable",
  "name": "viewId",
  "variableName": "viewId"
},
v7 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "versionsSearchInput"
  }
],
v8 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    },
    {
      "kind": "ScalarField",
      "name": "hasPreviousPage"
    }
  ]
},
v9 = {
  "kind": "ScalarField",
  "name": "id"
},
v10 = {
  "kind": "ScalarField",
  "name": "name"
},
v11 = {
  "kind": "ScalarField",
  "name": "startDate"
},
v12 = {
  "kind": "ScalarField",
  "name": "releaseDate"
},
v13 = [
  (v6/*: any*/)
],
v14 = {
  "kind": "ScalarField",
  "name": "scenarioType"
},
v15 = [
  {
    "kind": "ScalarField",
    "name": "date"
  }
],
v16 = {
  "concreteType": "JiraDateScenarioValueField",
  "kind": "LinkedField",
  "name": "startDateValue",
  "plural": false,
  "selections": (v15/*: any*/)
},
v17 = {
  "concreteType": "JiraDateScenarioValueField",
  "kind": "LinkedField",
  "name": "releaseDateValue",
  "plural": false,
  "selections": (v15/*: any*/)
},
v18 = {
  "kind": "ScalarField",
  "name": "versionId"
},
v19 = {
  "kind": "ScalarField",
  "name": "status"
},
v20 = {
  "concreteType": "JiraProject",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "key"
    },
    (v10/*: any*/),
    {
      "concreteType": "JiraAvatar",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "xsmall"
        }
      ]
    },
    (v9/*: any*/)
  ]
},
v21 = {
  "kind": "ScalarField",
  "name": "canEdit"
},
v22 = {
  "kind": "ScalarField",
  "name": "description"
},
v23 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v24 = [
  {
    "kind": "ScalarField",
    "name": "totalCount"
  }
],
v25 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjiracalendarreleaseflyoutlazyloadingenabledrelayprovider",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    (v22/*: any*/),
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraAvatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            (v23/*: any*/)
          ]
        },
        {
          "kind": "ScalarField",
          "name": "projectTypeName"
        }
      ]
    },
    {
      "kind": "LinkedField",
      "name": "driver",
      "plural": false,
      "selections": [
        (v23/*: any*/),
        (v10/*: any*/),
        {
          "kind": "ScalarField",
          "name": "picture"
        },
        (v9/*: any*/)
      ]
    },
    {
      "alias": "doneIssues",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusCategories": "DONE"
          }
        }
      ],
      "concreteType": "JiraIssueConnection",
      "kind": "LinkedField",
      "name": "issues",
      "plural": false,
      "selections": (v24/*: any*/),
      "storageKey": "issues(filters:{\"statusCategories\":\"DONE\"})"
    },
    {
      "alias": "inprogressIssues",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusCategories": "IN_PROGRESS"
          }
        }
      ],
      "concreteType": "JiraIssueConnection",
      "kind": "LinkedField",
      "name": "issues",
      "plural": false,
      "selections": (v24/*: any*/),
      "storageKey": "issues(filters:{\"statusCategories\":\"IN_PROGRESS\"})"
    },
    {
      "alias": "todoIssues",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusCategories": "TODO"
          }
        }
      ],
      "concreteType": "JiraIssueConnection",
      "kind": "LinkedField",
      "name": "issues",
      "plural": false,
      "selections": (v24/*: any*/),
      "storageKey": "issues(filters:{\"statusCategories\":\"TODO\"})"
    },
    {
      "args": (v13/*: any*/),
      "concreteType": "JiraVersionPlanScenarioValues",
      "kind": "LinkedField",
      "name": "planScenarioValues",
      "plural": false,
      "selections": [
        (v22/*: any*/)
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "name": "calendarRendererVersions_jira_refetchQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "configurationInput",
            "variableName": "configurationInput"
          },
          {
            "kind": "Variable",
            "name": "scopeInput",
            "variableName": "scopeInput"
          },
          {
            "kind": "Variable",
            "name": "skipVersionsV1Search",
            "variableName": "skipVersionsV1Search"
          },
          {
            "kind": "Variable",
            "name": "skipVersionsV2Search",
            "variableName": "skipVersionsV2Search"
          },
          {
            "kind": "Variable",
            "name": "versionsSearchInput",
            "variableName": "versionsSearchInput"
          },
          (v6/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "calendarRendererVersions_jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjiracalendarreleaseflyoutlazyloadingenabledrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "calendarRendererVersions_jira_refetchQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "configuration",
                "variableName": "configurationInput"
              },
              {
                "kind": "Variable",
                "name": "scope",
                "variableName": "scopeInput"
              }
            ],
            "concreteType": "JiraCalendar",
            "kind": "LinkedField",
            "name": "jiraCalendar",
            "plural": false,
            "selections": [
              {
                "condition": "skipVersionsV1Search",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "args": (v7/*: any*/),
                    "concreteType": "JiraVersionConnection",
                    "kind": "LinkedField",
                    "name": "versions",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "concreteType": "JiraVersionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraVersion",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              {
                                "args": (v13/*: any*/),
                                "concreteType": "JiraVersionPlanScenarioValues",
                                "kind": "LinkedField",
                                "name": "planScenarioValues",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  (v12/*: any*/)
                                ]
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "args": (v13/*: any*/),
                                    "concreteType": "JiraVersionPlanScenarioValues",
                                    "kind": "LinkedField",
                                    "name": "planScenarioValues",
                                    "plural": false,
                                    "selections": [
                                      (v14/*: any*/),
                                      {
                                        "condition": "skipVersionsV2Search",
                                        "kind": "Condition",
                                        "passingValue": false,
                                        "selections": [
                                          (v16/*: any*/),
                                          (v17/*: any*/)
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v18/*: any*/),
                                      (v19/*: any*/),
                                      (v20/*: any*/),
                                      (v21/*: any*/),
                                      (v25/*: any*/)
                                    ],
                                    "type": "JiraVersion"
                                  }
                                ],
                                "type": "JiraScenarioVersionLike",
                                "abstractKey": "__isJiraScenarioVersionLike"
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "condition": "skipVersionsV2Search",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "args": (v7/*: any*/),
                    "concreteType": "JiraScenarioVersionLikeConnection",
                    "kind": "LinkedField",
                    "name": "versionsV2",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "concreteType": "JiraScenarioVersionLikeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v23/*: any*/),
                              (v9/*: any*/),
                              {
                                "args": (v13/*: any*/),
                                "concreteType": "JiraVersionPlanScenarioValues",
                                "kind": "LinkedField",
                                "name": "planScenarioValues",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  (v17/*: any*/),
                                  (v14/*: any*/),
                                  {
                                    "condition": "skipVersionsV1Search",
                                    "kind": "Condition",
                                    "passingValue": false,
                                    "selections": [
                                      (v11/*: any*/),
                                      (v12/*: any*/)
                                    ]
                                  },
                                  {
                                    "condition": "skipVersionsV2Search",
                                    "kind": "Condition",
                                    "passingValue": false,
                                    "selections": [
                                      (v16/*: any*/)
                                    ]
                                  }
                                ]
                              },
                              {
                                "kind": "TypeDiscriminator",
                                "abstractKey": "__isJiraScenarioVersionLike"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  (v12/*: any*/),
                                  (v18/*: any*/),
                                  (v19/*: any*/),
                                  (v20/*: any*/),
                                  (v21/*: any*/),
                                  (v25/*: any*/)
                                ],
                                "type": "JiraVersion"
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "9abf59b1e25572f2093f9e0db8425fdb344958687405a2824edc2c27a5c93bb6",
    "metadata": {},
    "name": "calendarRendererVersions_jira_refetchQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcisjiracalendarreleaseflyoutlazyloadingenabledrelayprovider": isJiraCalendarReleaseFlyoutLazyLoadingEnabled_provider
    }
  }
};
})();

(node as any).hash = "23ba44ef414e290a016d13df8d7f6fc6";

export default node;
