import { useEffect, useRef } from 'react';
import type { RefetchFnDynamic } from 'react-relay';
import type { calendarRendererVersions_jira$key } from '@atlassian/jira-relay/src/__generated__/calendarRendererVersions_jira.graphql';
import type { calendarRendererVersions_jira_refetchQuery } from '@atlassian/jira-relay/src/__generated__/calendarRendererVersions_jira_refetchQuery.graphql';
import { useDateRange, useExtraQuery } from '../../../controllers/calendar-store/index.tsx';
import { useRefetchCalendarVersions } from '../../../controllers/use-refetch-versions/index.tsx';

/**
 * Custom hook that handles refetching calendar versions when inputs such filter, pagination or date period change.
 * @param {RefetchFnDynamic<calendarRendererVersions_jira_refetchQuery, calendarRendererVersions_jira$key>} options.refetch - The refetch function.
 */
export function useRefetchCalendarVersionsOnInputsChanges({
	refetch,
}: {
	refetch: RefetchFnDynamic<
		calendarRendererVersions_jira_refetchQuery,
		calendarRendererVersions_jira$key
	>;
}) {
	const { refetchVersionsWithoutSuspense } = useRefetchCalendarVersions({ refetch });
	const currentDateRange = useDateRange();
	const currentFilter = useExtraQuery();

	const lastDateRef = useRef<Date | null>(currentDateRange.currentDate);
	const lastFilterRef = useRef<string | null>(currentFilter);

	const isFirstRun = useRef(true);

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}

		if (
			lastDateRef.current !== currentDateRange.currentDate ||
			lastFilterRef.current !== currentFilter
		) {
			lastDateRef.current = currentDateRange.currentDate;
			lastFilterRef.current = currentFilter;
		}

		const subscription = refetchVersionsWithoutSuspense();

		return () => subscription?.unsubscribe?.();
	}, [currentDateRange.currentDate, currentFilter, refetchVersionsWithoutSuspense]);
}
