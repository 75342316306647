import React from 'react';
import { useFragment, graphql } from 'react-relay';
import Avatar from '@atlaskit/avatar';
import type { calendarCardAssignee_calendar$key } from '@atlassian/jira-relay/src/__generated__/calendarCardAssignee_calendar.graphql';

interface EventAssigneeProps {
	issueRef: calendarCardAssignee_calendar$key | null;
}

export function CalendarCardAssignee({ issueRef }: EventAssigneeProps) {
	const data = useFragment(
		graphql`
			fragment calendarCardAssignee_calendar on JiraIssue {
				assigneeField {
					user {
						name
						picture
					}
				}
			}
		`,
		issueRef,
	);

	const user = data?.assigneeField?.user;
	if (!user) return null;

	return (
		<Avatar
			testId="calendar.ui.calendar-unscheduled-panel.calendar-unscheduled-panel-card-list.calendar-unscheduled-panel-card.calendar-card-assignee.avatar"
			name={user.name ?? ''}
			src={user.picture}
			size="xsmall"
		/>
	);
}
