/**
 * Simplified JQL query structured representation.
 */
export interface JQLModel {
	/**
	 * $fieldName IN ($values)
	 */
	whereClauses: {
		[fieldName: string]: string[];
	};
}

/**
 * Stringify a JQL model into a JQL string
 */
export function buildJQLQuery(jqlModel: JQLModel): string {
	return Object.entries(jqlModel.whereClauses)
		.filter(([_, values]) => values.length > 0)
		.map(([fieldName, values]) => `${fieldName} IN (${values.join(', ')})`)
		.join(' OR ');
}
