import React from 'react';
import { styled } from '@compiled/react';
import { useFragment, graphql } from 'react-relay';
import Avatar from '@atlaskit/avatar';
import { Box, Inline } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import VisuallyHidden from '@atlaskit/visually-hidden';
import type { userFieldValueLabel_softwareFiltersPopup$key } from '@atlassian/jira-relay/src/__generated__/userFieldValueLabel_softwareFiltersPopup.graphql';

export function UserFieldValueLabel({
	fieldValueRef,
	mode,
}: {
	fieldValueRef: userFieldValueLabel_softwareFiltersPopup$key;
	mode: 'dropdown' | 'popup';
}) {
	const data = useFragment(
		graphql`
			fragment userFieldValueLabel_softwareFiltersPopup on JiraJqlUserFieldValue {
				user {
					name
					picture
				}
			}
		`,
		fieldValueRef,
	);

	if (mode === 'popup') {
		return (
			<AvatarContainer>
				<Avatar appearance="circle" src={data.user?.picture} />
				<VisuallyHidden>{data.user?.name}</VisuallyHidden>
			</AvatarContainer>
		);
	}

	return (
		<Inline space="space.100" alignBlock="center">
			<AvatarContainer>
				<Avatar size="small" appearance="circle" src={data.user?.picture} />
			</AvatarContainer>

			<Box>{data.user?.name}</Box>
		</Inline>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarContainer = styled.div({
	// Compensate the avatar margin
	marginTop: token('space.negative.025', '-2px'),
	marginRight: token('space.negative.025', '-2px'),
	marginBottom: token('space.negative.025', '-2px'),
	marginLeft: token('space.negative.025', '-2px'),
});
