/**
 * @generated SignedSource<<19079e5b1af185532a8d9b5cccd8f629>>
 * @relayHash 03ea828fc45dc822a0a772e405ceca4d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5531862d2b7b97f87b7968ac076333b24c82e351613eeaa72f78cd8e232b614b

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type fieldValuesList_softwareFiltersPopup_RefetchQuery$variables = {
  after?: string | null | undefined;
  cloudId: string;
  fieldName: string;
  first?: number | null | undefined;
  jqlContext?: string | null | undefined;
  searchString?: string | null | undefined;
};
export type fieldValuesList_softwareFiltersPopup_RefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"fieldValuesList_softwareFiltersPopup">;
};
export type fieldValuesList_softwareFiltersPopup_RefetchQuery = {
  response: fieldValuesList_softwareFiltersPopup_RefetchQuery$data;
  variables: fieldValuesList_softwareFiltersPopup_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fieldName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jqlContext"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchString"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v3 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v4 = {
  "kind": "Variable",
  "name": "jqlContext",
  "variableName": "jqlContext"
},
v5 = {
  "kind": "Variable",
  "name": "searchString",
  "variableName": "searchString"
},
v6 = [
  (v1/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "kind": "Variable",
    "name": "jqlTerm",
    "variableName": "fieldName"
  },
  (v5/*: any*/)
],
v7 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v8 = {
  "kind": "ScalarField",
  "name": "name"
},
v9 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "fieldValuesList_softwareFiltersPopup_RefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "fieldName",
            "variableName": "fieldName"
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "fieldValuesList_softwareFiltersPopup"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "fieldValuesList_softwareFiltersPopup_RefetchQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              (v2/*: any*/)
            ],
            "concreteType": "JiraJqlBuilder",
            "kind": "LinkedField",
            "name": "jqlBuilder",
            "plural": false,
            "selections": [
              {
                "args": (v6/*: any*/),
                "concreteType": "JiraJqlFieldValueConnection",
                "kind": "LinkedField",
                "name": "fieldValues",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      }
                    ]
                  },
                  {
                    "concreteType": "JiraJqlFieldValueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      },
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isJiraJqlFieldValue"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "jqlTerm"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "displayName"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "picture"
                                  },
                                  (v9/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraJqlUserFieldValue"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraPriority",
                                "kind": "LinkedField",
                                "name": "priority",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "iconUrl"
                                  },
                                  (v8/*: any*/),
                                  (v9/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraJqlPriorityFieldValue"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraProject",
                                "kind": "LinkedField",
                                "name": "project",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraAvatar",
                                    "kind": "LinkedField",
                                    "name": "avatar",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "xsmall"
                                      }
                                    ]
                                  },
                                  (v8/*: any*/),
                                  (v9/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraJqlProjectFieldValue"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraStatusCategory",
                                "kind": "LinkedField",
                                "name": "statusCategory",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "statusCategoryId"
                                  },
                                  (v9/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraJqlStatusFieldValue"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraLabel",
                                "kind": "LinkedField",
                                "name": "label",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraJqlLabelFieldValue"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraGroup",
                                "kind": "LinkedField",
                                "name": "group",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/),
                                  (v9/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraJqlGroupFieldValue"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "args": (v6/*: any*/),
                "filters": [
                  "jqlTerm",
                  "jqlContext",
                  "searchString"
                ],
                "handle": "connection",
                "key": "filterValuesList_softwareFiltersPopup_fieldValues",
                "kind": "LinkedHandle",
                "name": "fieldValues"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "5531862d2b7b97f87b7968ac076333b24c82e351613eeaa72f78cd8e232b614b",
    "metadata": {},
    "name": "fieldValuesList_softwareFiltersPopup_RefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "2acb39e122378da101af04d30f619809";

export default node;
