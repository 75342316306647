import parseISO from 'date-fns/parseISO';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

/**
 * Swaps the date portion of the original date with the date portion of the target date.
 * The time portion of the original date remains unchanged.
 *
 * @param original - The original date.
 * @param target - The target date whose date portion will be used.
 * @returns A new Date object with the swapped date portion.
 */
export function swapDate(original: Date, target: Date): Date {
	return new Date(
		target.getFullYear(),
		target.getMonth(),
		target.getDate(),
		original.getHours(),
		original.getMinutes(),
		original.getSeconds(),
		original.getMilliseconds(),
	);
}

/**
 * Swaps the time portion of the original date with the time portion of the target date.
 * @param original - The original date.
 * @param target - The target date whose time portion will be used.
 * @returns A new Date object with the time portion swapped.
 */
export function swapTime(original: Date, target: Date): Date {
	return new Date(
		original.getFullYear(),
		original.getMonth(),
		original.getDate(),
		target.getHours(),
		target.getMinutes(),
		target.getSeconds(),
		target.getMilliseconds(),
	);
}

/**
 * Converts a given date to a date with only the year, month, and day components.
 * The time components are set to 0.
 *
 * @param original - The original date to convert.
 * @returns A new Date object with only the year, month, and day components.
 */
export function toDateOnly(original: Date): Date {
	return new Date(original.getFullYear(), original.getMonth(), original.getDate(), 0, 0, 0, 0);
}

/**
 * Removes the time portion from a date string.
 *
 * @param date - The date string to trim.
 * @returns The date string without the time portion.
 */
export function removeTimeFromDateString(
	dateString: string | undefined | null,
): string | undefined {
	return dateString?.replace(/T.*/, '');
}

/**
 * Converts a UTC date string to a local Date object.
 *
 * This function is designed to handle date strings where only the date portion is relevant and the time is always set to midnight UTC.
 * It first removes the time portion from the date string, then converts the resulting date-only string into a local Date object.
 * This ensures that the date is correctly adjusted for the local timezone, regardless of the original UTC time.
 *
 * @param utcDateString - A UTC date string in ISO 8601 format (e.g., "2022-01-01T00:00:00Z").
 * @returns A Date object representing the same date, but adjusted to the local timezone.
 */
export function toLocalDate(utcDateString: string | undefined): Date {
	const dateString = removeTimeFromDateString(utcDateString);

	if (!dateString) {
		return new Date(NaN);
	}
	return parseISO(dateString);
}

/**
 * Converts a UTC date string to a local Date object.
 *
 * @param utcDateString - A UTC date string in ISO 8601 format (e.g., "2022-01-01T00:00:00Z").
 * @returns A Date object representing the same datetime, but adjusted to the local timezone.
 */
export function toLocalDateTime(utcDateString: string | undefined): Date {
	if (!utcDateString) {
		return new Date(NaN);
	}
	return parseISO(utcDateString);
}

/**
 * Converts a given date to its corresponding UTC string representation at midnight.
 * @param date - The date to convert.
 * @returns The UTC string representation of the given date.
 */
export function toMidnightUTCString(date: Date): string {
	// Get the UTC milliseconds since the epoch
	const utcMilliseconds = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

	// Create a new Date object with the UTC milliseconds
	return new Date(utcMilliseconds).toISOString();
}

export function toUTCString(date: Date): string {
	return new Date(date).toISOString();
}

/**
 * Converts a UTC date string to a local time string.
 *
 * @param utcString
 * @returns The local time string in the format "HH:mm AM/PM".
 */
export const useFormatToLocalTimeString = (utcString: string | undefined | null): string | null => {
	const { formatMessage } = useIntl();

	if (!utcString) {
		return null;
	}

	const date = new Date(utcString);

	// Check if the date is valid
	if (Number.isNaN(date.getTime())) {
		return null;
	}

	let hours = date.getHours();
	const minutes = date.getMinutes();
	const ampm = hours >= 12 ? formatMessage(messages.pm) : formatMessage(messages.am);

	hours %= 12;
	hours = hours || 12; // the hour '0' should be '12'
	const minutesStr = minutes < 10 ? `0${minutes}` : minutes;

	return formatMessage(messages.localTimeString, { hours, minutes: minutesStr, ampm });
};
