/**
 * @generated SignedSource<<216a41e0313c941f5e66e1ebc74b993f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type inlineCardCreate_calendar$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_calendar_CalendarInlineCardCreate">;
  readonly " $fragmentType": "inlineCardCreate_calendar";
};
export type inlineCardCreate_calendar$key = {
  readonly " $data"?: inlineCardCreate_calendar$data;
  readonly " $fragmentSpreads": FragmentRefs<"inlineCardCreate_calendar">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "configurationInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeInput"
    }
  ],
  "kind": "Fragment",
  "name": "inlineCardCreate_calendar",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "configurationInput",
          "variableName": "configurationInput"
        },
        {
          "kind": "Variable",
          "name": "scopeInput",
          "variableName": "scopeInput"
        }
      ],
      "kind": "FragmentSpread",
      "name": "main_calendar_CalendarInlineCardCreate"
    }
  ],
  "type": "Query"
};

(node as any).hash = "fa8f7d9242766decc34fd32bb2569536";

export default node;
