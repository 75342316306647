import React, { type ReactNode, useLayoutEffect, useRef } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useResizeObserver } from '@atlassian/jira-react-use-resize-observer/src/index.tsx';

interface CalendarUnscheduledPanelRowProps {
	/**
	 * Absolute position of the row
	 */
	top: number;
	/**
	 * Width of the row
	 */
	width: number;
	/**
	 * Contents for the row
	 */
	children: ReactNode;
	/**
	 * Called on resize of the row
	 */
	forceRemeasure: (el: HTMLDivElement | null) => void;
	/**
	 * Called on mount of the row
	 */
	measure: (el: HTMLDivElement | null) => void;
}

/**
 * This is a virtual list row on the unscheduled panel. It will dynamically measure its height and
 * observe it for changes.
 */
export function CalendarUnscheduledPanelRow({
	top,
	width,
	forceRemeasure,
	measure,
	children,
}: CalendarUnscheduledPanelRowProps) {
	const rowRef = useRef<HTMLDivElement>(null);
	useResizeObserver({
		ref: rowRef,
		onResize() {
			forceRemeasure(rowRef.current);
		},
	});

	useLayoutEffect(() => {
		if (!rowRef.current) return;
		measure(rowRef.current);
	}, [measure]);

	return (
		<RowContainer
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{
				top,
				width,
			}}
			ref={rowRef}
		>
			{children}
		</RowContainer>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RowContainer = styled.div({
	position: 'absolute',
	display: 'flex',
	flexDirection: 'column',
	paddingBottom: token('space.050', '4px'),
});
