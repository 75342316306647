/** @jsx jsx */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { jsx } from '@compiled/react';
import { commitLocalUpdate, graphql, useFragment, useRelayEnvironment } from 'react-relay';
import SprintIcon from '@atlaskit/icon/glyph/sprint';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import {
	Pressable,
	Box,
	xcss,
	type BorderRadius,
	type BackgroundColor,
} from '@atlaskit/primitives';
import { N20A, N500 } from '@atlaskit/theme/colors';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { JiraPopup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useIsSimplifiedProject } from '@atlassian/jira-providers-project-context/src/index.tsx';
import type { sprintRenderer_calendar_SprintEventRenderer$key } from '@atlassian/jira-relay/src/__generated__/sprintRenderer_calendar_SprintEventRenderer.graphql';
import {
	EditSprintModalEntryPointContainer,
	type EditSprintModalEntryPointProps,
} from '@atlassian/jira-software-edit-sprint-modal-relay/src/common/utils/edit-sprint-modal-entrypoint-container/index.tsx';
import { useEditSprintModalEntryPoint } from '@atlassian/jira-software-edit-sprint-modal-relay/src/common/utils/use-edit-sprint-modal-entrypoint/index.tsx';
import { SprintFlyout } from '@atlassian/jira-sprint-flyout/src/ui/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import { useCalendarCapabilities } from '../../../../common/controllers/capabilities-provider/index.tsx';
import { useUserTimezone } from '../../../../common/controllers/use-user-timezone/index.tsx';
import { findDraggedDate } from '../../../../common/utils/drag-and-drop/index.tsx';
import {
	useCalendarActions,
	useColorBy,
	useDraggingEvent,
} from '../../../../controllers/calendar-store/index.tsx';
import { moreLinkPopoverBinder } from '../../../../controllers/more-link-popover-binder/index.tsx';
import { useIsVisibleInMoreLinkPopover } from '../../../../controllers/use-is-visible-in-more-link-popover/index.tsx';
import {
	isDroppableCalendarDayCell,
	makeDraggableCalendarSprint,
} from '../../../../services/draggable-card-type/index.tsx';

interface Props {
	sprintRef: sprintRenderer_calendar_SprintEventRenderer$key;
}

export function SprintEventRenderer({ sprintRef }: Props) {
	const sprint = useFragment(
		graphql`
			fragment sprintRenderer_calendar_SprintEventRenderer on JiraSprint {
				__id
				id
				sprintId
				name
				goal
				state
				startDate
				endDate
				completionDate
				...ui_SprintFlyout
			}
		`,
		sprintRef,
	);

	const sprintEventRef = useRef<HTMLDivElement>(null);

	const [isOpen, setIsOpen] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isCompanyManaged = !useIsSimplifiedProject();

	const { setDraggingEvent } = useCalendarActions();
	const draggingEvent = useDraggingEvent();
	const { isVisible, onDropEvent } = useIsVisibleInMoreLinkPopover(sprintEventRef);

	const {
		sprint: { canSchedule },
		userTimeZoneForSprintEnabled,
	} = useCalendarCapabilities();

	const colorBy = useColorBy();

	const [{ match }] = useRouter();
	const boardId = Number(match.params.boardId);

	const canManageSprint = canSchedule;

	const isDraggable = sprint.state !== 'CLOSED' && canSchedule && canManageSprint;

	const isDragging = draggingEvent?.type === 'sprint' && draggingEvent?.id === sprint.id;

	useEffect(() => {
		const el = sprintEventRef.current;
		if (!el) {
			return;
		}

		// Sprint is not draggable if it is already completed
		if (!isDraggable) {
			return;
		}

		return draggable({
			element: el,
			getInitialData: (args) => {
				const { input } = args;
				const draggedDate =
					findDraggedDate(el, input) || moreLinkPopoverBinder.getCurrentDayCellDate();

				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return makeDraggableCalendarSprint({
					id: sprint.id,
					sprintId: sprint.sprintId,
					name: sprint.name,
					goal: sprint.goal,
					state: sprint.state,
					startDate: sprint.startDate,
					endDate: sprint.endDate,
					completionDate: sprint.completionDate,
					draggedDate,
				}) as unknown as Record<string, unknown>;
			},
			onDragStart(_args) {
				setDraggingEvent({ type: 'sprint', id: sprint.id });
			},
			onDrop(args) {
				setDraggingEvent(null);
				const dropTargetData = args.location.current.dropTargets[0]?.data;
				if (isDroppableCalendarDayCell(dropTargetData)) {
					onDropEvent(dropTargetData.isEventDraggedAway);
				}
			},
		});
	}, [
		sprint.id,
		sprint.startDate,
		sprint.endDate,
		sprint.sprintId,
		sprint.name,
		sprint.goal,
		sprint.state,
		sprint.completionDate,
		setDraggingEvent,
		onDropEvent,
		isDraggable,
	]);

	const { entryPointActions, entryPointReferenceSubject, stopMetric, editSprintModalTriggerRef } =
		useEditSprintModalEntryPoint(boardId, sprint.sprintId, false);

	const onPopupClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	const { userTimeZone } = useUserTimezone();
	const onFlyoutContentRendering = useCallback(
		() => (
			<SprintFlyout
				sprintRef={sprint}
				editSprintModalTriggerRef={editSprintModalTriggerRef}
				onClose={onPopupClose}
				isSprintActionMenuDisabled={!canManageSprint}
				userTimeZone={userTimeZone}
				headingAs="h3"
				userTimeZoneForSprintEnabled={userTimeZoneForSprintEnabled}
			/>
		),
		[
			canManageSprint,
			editSprintModalTriggerRef,
			onPopupClose,
			sprint,
			userTimeZone,
			userTimeZoneForSprintEnabled,
		],
	);

	const onEventCardClick = useCallback(() => {
		setIsOpen(!isOpen);

		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'sprintEventCard',
			}),
			'calendar',
		);
	}, [createAnalyticsEvent, isOpen]);

	const onStopPropagation = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
		// Prevents fullcalendar from handling mouse events and interfering with pragmatic dnd
		e.stopPropagation();
	}, []);

	const environment = useRelayEnvironment();
	const onEditSprint = useCallback<EditSprintModalEntryPointProps['onSubmit']>(
		async (data, requestPromise) => {
			const currentSprint = { ...sprint };
			// Currently EditSprintModal is updating a different sprint field in relay store,
			// so we need to update the sprint record on the calendar manually
			commitLocalUpdate(environment, (store) => {
				const sprintRecord = store.get(sprint.__id);
				if (sprintRecord) {
					sprintRecord
						.setValue(data.name, 'name')
						.setValue(data.goal, 'goal')
						.setValue(new Date(data.startDate).toISOString(), 'startDate')
						.setValue(new Date(data.endDate).toISOString(), 'endDate');
				}
			});

			try {
				await requestPromise;
			} catch {
				// Revert the changes in case of an error
				commitLocalUpdate(environment, (store) => {
					const sprintRecord = store.get(sprint.__id);
					if (sprintRecord) {
						sprintRecord
							.setValue(currentSprint.name, 'name')
							.setValue(currentSprint.goal, 'goal')
							.setValue(currentSprint.startDate, 'startDate')
							.setValue(currentSprint.endDate, 'endDate');
					}
				});
			}
		},
		[environment, sprint],
	);

	if (!isVisible) return null;

	return (
		<>
			{canManageSprint && (
				<EditSprintModalEntryPointContainer
					entryPointReferenceSubject={entryPointReferenceSubject}
					entryPointActions={entryPointActions}
					onReady={stopMetric}
					boardId={boardId}
					onSubmit={onEditSprint}
					isCompanyManaged={isCompanyManaged}
				/>
			)}

			<JiraPopup
				isOpen={isOpen}
				onClose={onPopupClose}
				content={onFlyoutContentRendering}
				placement="bottom-start"
				messageId="calendar.ui.calendar-renderer.event-renderer.sprint-renderer.jira-popup"
				messageType="transactional"
				trigger={({ ref: popupRef, ...triggerProps }) => (
					<Tooltip position="bottom" content={sprint.name}>
						{({ ref: tooltipRef, ...tooltipProps }) => (
							<Pressable
								{...triggerProps}
								{...tooltipProps}
								testId="calendar.ui.calendar-renderer.event-renderer.sprint-renderer.box"
								ref={mergeRefs(popupRef, tooltipRef, sprintEventRef)}
								xcss={[
									fg('plan-calender-color-contrast-a11y-fix')
										? containerStyles
										: containerStylesOld,
									canManageSprint && containerInteractiveStyles,
									...(colorBy === 'status'
										? [
												sprint.state === 'ACTIVE' && activeSprintStyles,
												sprint.state === 'ACTIVE' &&
													canManageSprint &&
													activeSprintInteractiveStyles,
												sprint.state === 'CLOSED' && closedSprintStyles,
												sprint.state === 'CLOSED' &&
													canManageSprint &&
													closedSprintInteractiveStyles,
											]
										: [defaultStyles]),
									isDragging && canManageSprint && sprintDraggingStyles,
								]}
								onMouseDown={onStopPropagation}
								onClick={onEventCardClick}
							>
								<SprintIcon label="" size="small" primaryColor={token('color.icon', N500)} />
								<Box as="span" xcss={sprintNameStyles}>
									{sprint.name}
								</Box>
							</Pressable>
						)}
					</Tooltip>
				)}
			/>
		</>
	);
}

const containerStyles = xcss({
	display: 'flex',
	gap: 'space.050',
	alignItems: 'center',
	padding: 'space.050',
	boxSizing: 'border-box',
	transition: '200ms',
	color: 'color.text',
	// NOTE: Fallback token is needed as Cypress tests won't recognise token value which will violate A11y (Storybook renders fine)
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @typescript-eslint/consistent-type-assertions, @atlaskit/ui-styling-standard/no-imported-style-values
	backgroundColor: token('color.background.accent.gray.subtlest', N20A) as BackgroundColor,
	cursor: 'pointer',
	width: '100%',
	height: '24px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
	borderRadius:
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		`var(--fc-event-border-left-radius, 0)
        var(--fc-event-border-right-radius, 0)
        var(--fc-event-border-right-radius, 0)
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
        var(--fc-event-border-left-radius, 0)` as BorderRadius,
});

const containerStylesOld = xcss({
	display: 'flex',
	gap: 'space.050',
	alignItems: 'center',
	padding: 'space.050',
	boxSizing: 'border-box',
	transition: '200ms',
	color: 'color.text',
	backgroundColor: 'color.background.accent.gray.subtlest',
	cursor: 'pointer',
	width: '100%',
	height: '24px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
	borderRadius:
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		`var(--fc-event-border-left-radius, 0)
        var(--fc-event-border-right-radius, 0)
        var(--fc-event-border-right-radius, 0)
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
        var(--fc-event-border-left-radius, 0)` as BorderRadius,
});

const containerInteractiveStyles = xcss({
	':hover': {
		backgroundColor: 'color.background.accent.gray.subtlest.hovered',
	},
	':active': {
		backgroundColor: 'color.background.accent.gray.subtlest.pressed',
	},
});

const activeSprintStyles = xcss({
	backgroundColor: 'color.background.accent.blue.subtlest',
});

const activeSprintInteractiveStyles = xcss({
	':hover': {
		backgroundColor: 'color.background.accent.blue.subtlest.hovered',
	},
	':active': {
		backgroundColor: 'color.background.accent.blue.subtlest.pressed',
	},
});

const closedSprintStyles = xcss({
	backgroundColor: 'color.background.accent.green.subtlest',
});

const closedSprintInteractiveStyles = xcss({
	':hover': {
		backgroundColor: 'color.background.accent.green.subtlest.hovered',
	},
	':active': {
		backgroundColor: 'color.background.accent.green.subtlest.pressed',
	},
});

const defaultStyles = xcss({
	backgroundColor: 'elevation.surface.raised',
	':hover': {
		backgroundColor: 'elevation.surface.raised.hovered',
	},
	':active': {
		backgroundColor: 'elevation.surface.raised.pressed',
	},
	border: '1px solid',
	borderColor: 'color.border',
});

const sprintDraggingStyles = xcss({
	opacity: 0.5,
});

const sprintNameStyles = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	userSelect: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
	fontWeight: token('font.weight.medium', '500'),
});
