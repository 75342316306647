import React, { useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Stack, xcss } from '@atlaskit/primitives';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import type { SearchMode } from '@atlassian/jira-jql-builder/src/common/types.tsx';
import JQLBuilder from '@atlassian/jira-jql-builder/src/main.tsx';
import type { filters_calendar_CalendarFilters$key } from '@atlassian/jira-relay/src/__generated__/filters_calendar_CalendarFilters.graphql';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { ANALYTICS_SOURCE } from '../../../common/constants.tsx';
import { useCalendarCapabilities } from '../../../common/controllers/capabilities-provider/index.tsx';
import {
	useCalendarActions,
	useCalendarCallbacks,
} from '../../../controllers/calendar-store/index.tsx';
import { useCalendarJqlBuilderContext } from '../../../controllers/jql-builder-context-provider/index.tsx';
import { useCalendarUFOExperienceContext } from '../../../controllers/ufo-context-provider/index.tsx';
import type { ProjectEdge } from './types.tsx';
import { messages } from './messages.tsx';

interface CalendarFiltersProps {
	/**
	 * This JQL query controls which "options" are shown on certain JQL builder dropdowns.
	 *
	 * For example, if this is `project = A` then only statuses from `A` will be shown on
	 * the status dropdown.
	 */
	jqlBuilderQuery?: string;
	/**
	 * Query-reference for JQL builder to work over
	 */
	jiraRef: filters_calendar_CalendarFilters$key | null;
}

const EMPTY_PROJECTS: string[] = [];
const mapJiraProjects = (edge: ProjectEdge | null | undefined) => {
	if (!edge || !edge.node) return null;
	const { node: project } = edge;
	return project.key;
};

/**
 * The Jira calendar view is built using the "Jira Query Language query builder", same as used on other
 * core Jira experiences like boards, dashboards, filters, issues, etc.
 */
export function CalendarFilters({ jiraRef, jqlBuilderQuery }: CalendarFiltersProps) {
	const { setExtraQuery } = useCalendarActions();
	const { onFiltered } = useCalendarCallbacks();
	const { viewContext, excludedFields, suggestedUsersCount } = useCalendarJqlBuilderContext();
	const { irremovableFilters } = useCalendarCapabilities();
	const hasIrremovableFilters = irremovableFilters.length > 0;
	const { formatMessage } = useIntl();

	const data = useFragment<filters_calendar_CalendarFilters$key>(
		graphql`
			fragment filters_calendar_CalendarFilters on JiraQuery
			@argumentDefinitions(
				cloudId: { type: "ID!" }
				scopeInput: { type: "JiraViewScopeInput!" }
				configurationInput: { type: "JiraCalendarViewConfigurationInput!" }
			) {
				userPreferences(cloudId: $cloudId) @optIn(to: "JiraUserPreferences") {
					...main_jqlBuilder_JQLBuilder
				}
				jiraCalendar(scope: $scopeInput, configuration: $configurationInput)
					@optIn(to: "JiraCalendar") {
					projects(first: 30) {
						edges {
							node {
								key
							}
						}
					}
				}
			}
		`,
		jiraRef,
	);

	const projectKeys =
		data?.jiraCalendar?.projects?.edges?.map(mapJiraProjects).filter(Boolean) ?? EMPTY_PROJECTS;

	const searchModes: [SearchMode, ...SearchMode[]] = ['basic'];
	const onSearchCallback = useCallback(
		(query: string) => {
			setExtraQuery(query);
			onFiltered?.(query);
		},
		[setExtraQuery, onFiltered],
	);
	const { packageName, teamName } = useCalendarUFOExperienceContext();

	return (
		<Stack grow="fill" xcss={stackStyles}>
			<UFOSegment name="jira-calendar-jql-builder">
				<JSErrorBoundary
					id="jira-calendar-jql-builder"
					packageName={packageName}
					teamName={teamName}
					fallback="flag"
				>
					<JQLBuilder
						query={jqlBuilderQuery ?? ''}
						userPreferencesData={data?.userPreferences ?? null}
						excludedFields={excludedFields}
						searchModes={searchModes}
						searchPlaceholder={
							isVisualRefreshEnabled() && fg('visual-refresh_drop_3')
								? formatMessage(messages.searchPlaceHolder)
								: undefined
						}
						onSearch={onSearchCallback}
						analyticsSource={ANALYTICS_SOURCE}
						{...(hasIrremovableFilters ? { basicModeVisibleFields: irremovableFilters } : {})}
						hideOperatorDropdownBasicMode
						viewContext={viewContext}
						projectKeys={projectKeys}
						suggestedUsersCount={suggestedUsersCount}
					/>
				</JSErrorBoundary>
			</UFOSegment>
		</Stack>
	);
}

const stackStyles = xcss({
	// Compensate JQL builder internal padding
	marginBottom: 'space.negative.200',
	marginTop: 'space.025',
});
