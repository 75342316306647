import { useMemo } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import type { calendarRendererVersions_jira_refetchQuery$variables } from '@atlassian/jira-relay/src/__generated__/calendarRendererVersions_jira_refetchQuery.graphql';
import { useCalendarCapabilities } from '../../common/controllers/capabilities-provider/index.tsx';
import { useCalendarConfigurationInput } from '../use-calendar-configuration-input/index.tsx';
import { useCalendarScopeInput } from '../use-calendar-scope-input/index.tsx';
import { useCalendarViewId } from '../use-calendar-view-id/index.tsx';
import { useVersionsSearchInput } from '../use-versions-search-input/index.tsx';

export function useRefetchVersionsCalendarVariables(): {
	variables: calendarRendererVersions_jira_refetchQuery$variables;
} {
	const { scopeInput } = useCalendarScopeInput();
	const { configurationInput } = useCalendarConfigurationInput();
	const { versionsSearchInput } = useVersionsSearchInput();
	const { viewId } = useCalendarViewId();

	const {
		version: { visible: isVersionVisible },
	} = useCalendarCapabilities();

	const variables = useMemo(
		() => ({
			scopeInput,
			configurationInput,
			versionsSearchInput,
			viewId,
			skipVersionsV1Search: !isVersionVisible || fg('plan-calendar-versions-v2-adoption'),
			skipVersionsV2Search: !isVersionVisible || !fg('plan-calendar-versions-v2-adoption'),
		}),
		[scopeInput, configurationInput, versionsSearchInput, viewId, isVersionVisible],
	);

	return {
		variables,
	};
}
