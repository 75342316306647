import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import type { StoreActionApi, Action } from '@atlassian/react-sweet-state';
import type { CalendarStoreState, CalendarState, DateRange } from './types.tsx';

function isCalendarState(state: CalendarStoreState): state is CalendarState {
	return state.stage === 'ready';
}

export function withReadyCheck(
	actionFn: (api: StoreActionApi<CalendarState>) => void,
): Action<CalendarStoreState> {
	return (api: StoreActionApi<CalendarStoreState>): void => {
		const { getState, setState, dispatch } = api;
		const state = getState();
		if (!isCalendarState(state)) {
			throw new Error('Trying to update a pending calendar store');
		}
		actionFn({
			getState: () => state,
			setState,
			dispatch,
		});
	};
}

export function dateRangeForDate(date: Date): DateRange {
	if (Number.isNaN(date.getTime())) {
		throw new Error('Invalid date provided');
	}
	return {
		start: startOfWeek(startOfMonth(date)),
		end: endOfWeek(endOfMonth(date)),
		currentDate: date,
	};
}
