import { differenceInMinutes } from 'date-fns';

type TypeEventSummaryLineClamp = {
	[key: number]: number;
};

const MAX_ALLOWED_SUMMARY_LINES = 10;
const MIN_ALLOWED_SUMMARY_LINES = 1;

// Includes line showing issue key
const EVENT_SUMMARY_LINE_CLAMPS: TypeEventSummaryLineClamp = {
	15: MIN_ALLOWED_SUMMARY_LINES,
	30: 2,
	45: 3,
	60: 5,
	75: 6,
	90: 8,
	105: 9,
	120: MAX_ALLOWED_SUMMARY_LINES,
};

export const getMaxAllowedSummaryInLines = (
	startDate: string | undefined | null,
	endDate: string | undefined | null,
	isAllDayEvent: boolean,
): number => {
	if (!startDate || !endDate || isAllDayEvent) {
		return MIN_ALLOWED_SUMMARY_LINES;
	}

	const startDateObj = new Date(startDate);
	const endDateObj = new Date(endDate);

	if (Number.isNaN(startDateObj.getTime()) || Number.isNaN(endDateObj.getTime())) {
		return MIN_ALLOWED_SUMMARY_LINES;
	}

	const diffInMinutes = Math.floor(differenceInMinutes(endDateObj, startDateObj) / 15) * 15;

	if (diffInMinutes <= 120) {
		return EVENT_SUMMARY_LINE_CLAMPS[diffInMinutes] || MIN_ALLOWED_SUMMARY_LINES;
	}

	// Calculate the max allowed summary lines for durations greater than 120 minutes
	let remainingMinutes = diffInMinutes;
	let maxLines = 0;

	const intervals = Object.keys(EVENT_SUMMARY_LINE_CLAMPS)
		.map(Number)
		.sort((a, b) => b - a); // Sort intervals in descending order

	for (const interval of intervals) {
		while (remainingMinutes >= interval) {
			maxLines += EVENT_SUMMARY_LINE_CLAMPS[interval];
			remainingMinutes -= interval;
		}
	}

	return maxLines;
};
