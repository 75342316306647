import type { JiraVersionStatus } from '@atlassian/jira-relay/src/__generated__/versionRenderer_calendar_VersionEventRenderer.graphql';
import { toLocalDate } from '../../utils/dates/index.tsx';
import { COMPLETE, OFF_TRACK, ON_TRACK } from './constants.tsx';

export const getReleaseStatus = (status: JiraVersionStatus, releaseDate: string) => {
	if (status === 'RELEASED') {
		return COMPLETE;
	}

	if (status === 'UNRELEASED') {
		const endDate = toLocalDate(releaseDate);
		if (Date.now() > endDate.getTime()) {
			return OFF_TRACK;
		}

		return ON_TRACK;
	}

	// INVALID STATUS
	// Just return a default
	return null;
};
