/**
 * @generated SignedSource<<c31fd9313a21a18023df94a479248141>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type eventAssignee_calendar$data = {
  readonly assigneeField?: {
    readonly user: {
      readonly name: string;
      readonly picture: AGG$URL;
    } | null | undefined;
  } | null | undefined;
  readonly planScenarioValues?: {
    readonly assigneeField: {
      readonly user: {
        readonly name: string;
        readonly picture: AGG$URL;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "eventAssignee_calendar";
};
export type eventAssignee_calendar$key = {
  readonly " $data"?: eventAssignee_calendar$data;
  readonly " $fragmentSpreads": FragmentRefs<"eventAssignee_calendar">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "concreteType": "JiraSingleSelectUserPickerField",
  "kind": "LinkedField",
  "name": "assigneeField",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "name"
        },
        {
          "kind": "ScalarField",
          "name": "picture"
        }
      ]
    }
  ]
},
v1 = {
  "args": [
    {
      "kind": "Variable",
      "name": "viewId",
      "variableName": "viewId"
    }
  ],
  "concreteType": "JiraScenarioIssueValues",
  "kind": "LinkedField",
  "name": "planScenarioValues",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ]
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "name": "eventAssignee_calendar",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "type": "JiraIssue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/)
      ],
      "type": "JiraScenarioIssue"
    }
  ],
  "type": "JiraScenarioIssueLike",
  "abstractKey": "__isJiraScenarioIssueLike"
};
})();

(node as any).hash = "386ae69862c89c5eedcd918d5dbd759e";

export default node;
