/**
 * @generated SignedSource<<5012cf3d2e5c5fcf6593d69f60c0e22b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type moreMenu_calendar$data = {
  readonly jiraCalendar: {
    readonly projects: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly key: string;
          readonly name: string;
          readonly projectId: string | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "moreMenu_calendar";
};
export type moreMenu_calendar$key = {
  readonly " $data"?: moreMenu_calendar$data;
  readonly " $fragmentSpreads": FragmentRefs<"moreMenu_calendar">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "configurationInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeInput"
    }
  ],
  "kind": "Fragment",
  "name": "moreMenu_calendar",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "configuration",
          "variableName": "configurationInput"
        },
        {
          "kind": "Variable",
          "name": "scope",
          "variableName": "scopeInput"
        }
      ],
      "concreteType": "JiraCalendar",
      "kind": "LinkedField",
      "name": "jiraCalendar",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 30
            }
          ],
          "concreteType": "JiraProjectConnection",
          "kind": "LinkedField",
          "name": "projects",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraProjectEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "concreteType": "JiraProject",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "key"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "projectId"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "name"
                    }
                  ]
                }
              ]
            }
          ],
          "storageKey": "projects(first:30)"
        }
      ]
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "8aa0bd7978d71eadebfacda4e57da04e";

export default node;
