import React, {
	createContext,
	useState,
	useContext,
	useCallback,
	useEffect,
	type ReactNode,
} from 'react';
import noop from 'lodash/noop';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import type { BM3Metric } from '@atlassian/jira-providers-spa-apdex-analytics/src/submit-apdex-mark/types.tsx';
import { JsmPageInteractiveEvent } from '@atlassian/jira-servicedesk-common/src/utils/browser-metric/index.tsx';
import { getAgentViewMarks } from '@atlassian/jira-servicedesk-queues-common/src/analytics/index.tsx';
import {
	type QueueId,
	fromQueueIdString,
	type QueueCategory,
} from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

export type AgentTableApdexProviderProps = {
	isLoading: boolean; // ignore updates to the queueId while isLoading is true (otherwise we will submit an apdex that is too fast),
	isVisible: boolean; // unmount the apdex tracker when the table is not visible, (instead of unmounting this whole provider) so that the table can remain mounted while viewing an issue from a queue,
	queueId: QueueId | null; // when the queueId changes, remount JsmPageInteractiveEvent,
	children: ReactNode;
	metricKey: string;
	category: QueueCategory;
	metric: BM3Metric;
	hasLoadedIssueDataFromCache: boolean;
};

export type AgentTableApdexContext = () => void;

type State = QueueId | null;

const context = createContext<AgentTableApdexContext>(noop);

/*
 * Mounts a JsmPageInteractiveEvent when AgentTableApdexSubmitter mounts within the context provided.
 *
 * Ignores remounts of the submitter. We need this because we count the first render of the first table cell as apdex complete.
 * However, the first cell can unmount and remount for various reasons and we should not count those. So the actual JsmPageInteractiveEvent
 * must sit higher in the react tree.
 */
export const AgentTableApdexProvider = ({
	children,
	queueId,
	isLoading,
	isVisible,
	metricKey,
	metric,
	category,
	hasLoadedIssueDataFromCache,
}: AgentTableApdexProviderProps) => {
	const [finishedQueueId, setFinishedQueueId] = useState<State>(null);
	const updateFinishedQueueId = useCallback(() => {
		if (!isLoading) {
			setFinishedQueueId(queueId);
		}
	}, [queueId, isLoading]);

	const loadingComponent = fg('add_ufo_load_hold_to_custom_queue') ? (
		<UFOLoadHold name="agent-table-apdex-provider" />
	) : null;

	return (
		<context.Provider value={updateFinishedQueueId}>
			<>
				{children}
				{
					// Do not mount initially (when queueId is null) or when switching queueIds (queueId in state mismatches props) or when table is hidden
					finishedQueueId && finishedQueueId === queueId && isVisible ? (
						<>
							<JsmPageInteractiveEvent
								key={queueId ? fromQueueIdString(queueId) : undefined}
								metricKey={metricKey}
								withMarks={getAgentViewMarks()}
								metric={metric}
								extra={{
									category: category || 'no-category',
									empty: false,
									hasLoadedIssueDataFromCache,
								}}
							/>
						</>
					) : (
						loadingComponent
					)
				}
			</>
		</context.Provider>
	);
};

/*
 * Functions as a JsmPageInteractiveEvent component that ignores remounts as long as the nearest AgentTableApdexProvider that is
 * sitting higher in the tree remains mounted.
 */
export const AgentTableApdexSubmitter = () => {
	const updateFinishedQueueId = useContext(context);

	useEffect(updateFinishedQueueId);

	return null;
};
