import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { performGetRequest, performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';

export const checkEmailRequestStatus = async (projectKey: string) =>
	fetchJson(`/rest/servicedesk/1/servicedesk/${projectKey}/incomingemail`);

const getUrl = (accountId: string | null, key: string) =>
	`/rest/api/3/user/properties/${key}?accountId=${accountId}`;

export const dismissSectionMessageRequest = (accountId: string | null, key: string) => () =>
	performPutRequest(getUrl(accountId, key), {
		body: JSON.stringify(false),
	});

export const getUserProperty = (accountId: string | null, key: string) =>
	performGetRequest(getUrl(accountId, key));
