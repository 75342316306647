import { graphql, useMutation } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import type { useUpdateSprintDateMutation_updateSprintDateMutation } from '@atlassian/jira-relay/src/__generated__/useUpdateSprintDateMutation_updateSprintDateMutation.graphql.ts';
import { isErrorAllowed } from '../../common/utils/agg-errors/index.tsx';
import { combineErrorMessages } from '../../common/utils/graphql/index.tsx';
import { useCalendarActions, useCalendarCallbacks } from '../calendar-store/index.tsx';
import { useCalendarExperience } from '../use-calendar-experience/index.tsx';
import { useLogger } from '../use-logger/index.tsx';
import { messages } from './messages.tsx';

export interface ExecuteSprintUpdateMutationParams {
	id: string;
	sprintId: string;
	name: string | null | undefined;
	goal: string | null | undefined;
	state: string | null | undefined;
	startDate: Date | undefined;
	endDate: Date;
	completionDate: string | null | undefined;
}

/**
 * Update startDate and endDate on dropping a Sprint
 */
export function useUpdateSprintDateMutation() {
	const [commitDateUpdate] = useMutation<useUpdateSprintDateMutation_updateSprintDateMutation>(
		graphql`
			mutation useUpdateSprintDateMutation_updateSprintDateMutation(
				$input: JiraSprintUpdateInput!
			) {
				jira {
					sprintUpdate(sprintUpdateInput: $input) @optIn(to: "JiraSprintUpdate") {
						success
						errors {
							message
							extensions {
								errorType
								statusCode
							}
						}
						jiraSprint {
							id
							sprintId
							name
							state
							goal
							startDate
							endDate
							completionDate
						}
					}
				}
			}
		`,
	);

	const { setIsLoading } = useCalendarActions();
	const { showFlag } = useFlagService();
	const { formatMessage } = useIntl();
	const { onScheduled } = useCalendarCallbacks();
	const { scheduleSprintExperience } = useCalendarExperience();
	const { logError } = useLogger();

	const executeUpdateSprintDateMutation = ({
		id,
		sprintId,
		name,
		goal,
		state,
		startDate,
		endDate,
		completionDate,
	}: ExecuteSprintUpdateMutationParams) => {
		if (state === 'CLOSED') {
			return;
		}

		setIsLoading(true);

		scheduleSprintExperience.start();

		commitDateUpdate({
			variables: {
				input: {
					sprintId: id,
					startDate: startDate ? startDate.toISOString() : undefined,
					endDate: endDate ? endDate.toISOString() : undefined,
				},
			},
			optimisticResponse: {
				jira: {
					sprintUpdate: {
						success: true,
						errors: [],
						jiraSprint: {
							id,
							name,
							goal,
							state,
							sprintId,
							startDate,
							endDate,
							completionDate,
						},
					},
				},
			},
			onError(error: Error) {
				setIsLoading(false);

				scheduleSprintExperience.failure();

				logError(
					'calendar.update-sprint-dates-mutation.error',
					'Error thrown when attempting to update sprint dates',
					error,
				);

				showFlag({
					id: 'jira-calendar-update-sprint-dates-error',
					title: formatMessage(messages.errorTitle),
					description: formatMessage(messages.failedToUpdateDatesError),
					type: 'error',
				});
			},
			onCompleted(response) {
				setIsLoading(false);

				if (!response?.jira?.sprintUpdate?.success) {
					const errorResponse = response?.jira?.sprintUpdate?.errors;
					if (fg('calendar_handles_graphql_error')) {
						if (isErrorAllowed(errorResponse)) {
							scheduleSprintExperience.abort();
						} else {
							scheduleSprintExperience.failure();
						}
					} else {
						scheduleSprintExperience.abort();
					}

					const errorMessage =
						combineErrorMessages(errorResponse) ?? formatMessage(messages.failedToUpdateDatesError);

					logError(
						'calendar.update-sprint-dates-mutation.failure',
						`Error thrown when attempting to update sprint dates: ${errorMessage}.`,
					);

					showFlag({
						id: 'jira-calendar-update-sprint-dates-failure',
						title: formatMessage(messages.errorTitle),
						description: errorMessage,
						type: 'error',
					});
					return;
				}

				onScheduled?.({
					id: sprintId,
					startDate,
					endDate,
					type: 'sprint',
				});

				scheduleSprintExperience.success();
			},
		});
	};

	return { executeUpdateSprintDateMutation };
}
