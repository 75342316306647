/**
 * @generated SignedSource<<9564816112c1cc5ae8a06e0547faf2d7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type calendarRendererVersions_jira$data = {
  readonly jira: {
    readonly jiraCalendar: {
      readonly versions?: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly name: string | null | undefined;
            readonly planScenarioValues: {
              readonly name: string | null | undefined;
              readonly releaseDate: AGG$DateTime | null | undefined;
              readonly startDate: AGG$DateTime | null | undefined;
            } | null | undefined;
            readonly releaseDate: AGG$DateTime | null | undefined;
            readonly startDate: AGG$DateTime | null | undefined;
            readonly " $fragmentSpreads": FragmentRefs<"versionRenderer_calendar_VersionEventRenderer">;
          } | null | undefined;
        } | null | undefined> | null | undefined;
        readonly pageInfo: {
          readonly hasNextPage: boolean;
          readonly hasPreviousPage: boolean;
        };
      } | null | undefined;
      readonly versionsV2?: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly name?: string | null | undefined;
            readonly planScenarioValues: {
              readonly name: string | null | undefined;
              readonly releaseDateValue: {
                readonly date: AGG$DateTime | null | undefined;
              } | null | undefined;
            } | null | undefined;
            readonly releaseDate?: AGG$DateTime | null | undefined;
            readonly startDate?: AGG$DateTime | null | undefined;
            readonly " $fragmentSpreads": FragmentRefs<"versionRenderer_calendar_VersionEventRenderer">;
          } | null | undefined;
        } | null | undefined> | null | undefined;
        readonly pageInfo: {
          readonly hasNextPage: boolean;
          readonly hasPreviousPage: boolean;
        };
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "calendarRendererVersions_jira";
};
export type calendarRendererVersions_jira$key = {
  readonly " $data"?: calendarRendererVersions_jira$data;
  readonly " $fragmentSpreads": FragmentRefs<"calendarRendererVersions_jira">;
};

import calendarRendererVersions_jira_refetchQuery_graphql from './calendarRendererVersions_jira_refetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "versionsSearchInput"
  }
],
v1 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    },
    {
      "kind": "ScalarField",
      "name": "hasPreviousPage"
    }
  ]
},
v2 = {
  "kind": "ScalarField",
  "name": "id"
},
v3 = {
  "kind": "ScalarField",
  "name": "name"
},
v4 = {
  "kind": "ScalarField",
  "name": "startDate"
},
v5 = {
  "kind": "ScalarField",
  "name": "releaseDate"
},
v6 = {
  "kind": "Variable",
  "name": "viewId",
  "variableName": "viewId"
},
v7 = [
  (v6/*: any*/)
],
v8 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
],
v9 = {
  "args": [
    {
      "kind": "Variable",
      "name": "skipVersionsV1Search",
      "variableName": "skipVersionsV1Search"
    },
    {
      "kind": "Variable",
      "name": "skipVersionsV2Search",
      "variableName": "skipVersionsV2Search"
    },
    (v6/*: any*/)
  ],
  "kind": "FragmentSpread",
  "name": "versionRenderer_calendar_VersionEventRenderer"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "configurationInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipVersionsV1Search"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipVersionsV2Search"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "versionsSearchInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "fragmentPathInResult": [],
      "operation": calendarRendererVersions_jira_refetchQuery_graphql
    }
  },
  "name": "calendarRendererVersions_jira",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "configuration",
              "variableName": "configurationInput"
            },
            {
              "kind": "Variable",
              "name": "scope",
              "variableName": "scopeInput"
            }
          ],
          "concreteType": "JiraCalendar",
          "kind": "LinkedField",
          "name": "jiraCalendar",
          "plural": false,
          "selections": [
            {
              "condition": "skipVersionsV1Search",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "args": (v0/*: any*/),
                  "concreteType": "JiraVersionConnection",
                  "kind": "LinkedField",
                  "name": "versions",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "concreteType": "JiraVersionEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "concreteType": "JiraVersion",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            (v3/*: any*/),
                            (v4/*: any*/),
                            (v5/*: any*/),
                            {
                              "args": (v7/*: any*/),
                              "concreteType": "JiraVersionPlanScenarioValues",
                              "kind": "LinkedField",
                              "name": "planScenarioValues",
                              "plural": false,
                              "selections": (v8/*: any*/)
                            },
                            (v9/*: any*/)
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "condition": "skipVersionsV2Search",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "args": (v0/*: any*/),
                  "concreteType": "JiraScenarioVersionLikeConnection",
                  "kind": "LinkedField",
                  "name": "versionsV2",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "concreteType": "JiraScenarioVersionLikeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "InlineFragment",
                              "selections": (v8/*: any*/),
                              "type": "JiraVersion"
                            },
                            (v2/*: any*/),
                            {
                              "args": (v7/*: any*/),
                              "concreteType": "JiraVersionPlanScenarioValues",
                              "kind": "LinkedField",
                              "name": "planScenarioValues",
                              "plural": false,
                              "selections": [
                                (v3/*: any*/),
                                {
                                  "concreteType": "JiraDateScenarioValueField",
                                  "kind": "LinkedField",
                                  "name": "releaseDateValue",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "ScalarField",
                                      "name": "date"
                                    }
                                  ]
                                }
                              ]
                            },
                            (v9/*: any*/)
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "23ba44ef414e290a016d13df8d7f6fc6";

export default node;
