/**
 * @generated SignedSource<<57fa2442dd4bd00a800cc5ef81ca9172>>
 * @relayHash b96ec7ab740ae91fe28596180a528143
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1a68d6ee8b9af848d4cb944b340de903bb37c656374af03bd0fc13707f1ee79e

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraCalendarMode = "DAY" | "MONTH" | "WEEK" | "%future added value";
export type JiraCalendarWeekStart = "MONDAY" | "SATURDAY" | "SUNDAY" | "%future added value";
export type JiraCalendarViewConfigurationInput = {
  date?: AGG$DateTime | null | undefined;
  endDateField?: string | null | undefined;
  mode?: JiraCalendarMode | null | undefined;
  startDateField?: string | null | undefined;
  viewId?: string | null | undefined;
  weekStart?: JiraCalendarWeekStart | null | undefined;
};
export type JiraCalendarIssuesInput = {
  additionalFilterQuery?: string | null | undefined;
};
export type JiraViewScopeInput = {
  ids?: ReadonlyArray<string> | null | undefined;
  projectKeys?: JiraProjectKeysInput | null | undefined;
};
export type JiraProjectKeysInput = {
  cloudId: string;
  keys?: ReadonlyArray<string> | null | undefined;
};
export type calendarRendererIssues_jira_refetchQuery$variables = {
  configurationInput: JiraCalendarViewConfigurationInput;
  issuesPageCursor?: string | null | undefined;
  issuesPageSize?: number | null | undefined;
  issuesSearchInput: JiraCalendarIssuesInput;
  pageLoadMoreEnabled: boolean;
  schedulePermissionsEnabled: boolean;
  scopeInput: JiraViewScopeInput;
  skipFieldConfig?: boolean | null | undefined;
  viewId?: string | null | undefined;
};
export type calendarRendererIssues_jira_refetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"calendarRendererIssues_jira">;
};
export type calendarRendererIssues_jira_refetchQuery = {
  response: calendarRendererIssues_jira_refetchQuery$data;
  variables: calendarRendererIssues_jira_refetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "configurationInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issuesPageCursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issuesPageSize"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issuesSearchInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageLoadMoreEnabled"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "schedulePermissionsEnabled"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scopeInput"
  },
  {
    "defaultValue": true,
    "kind": "LocalArgument",
    "name": "skipFieldConfig"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "viewId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "viewId",
  "variableName": "viewId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "issuesPageCursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "issuesPageSize"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "issuesSearchInput"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "totalIssueSearchResultCount"
},
v4 = {
  "kind": "ScalarField",
  "name": "isCappingIssueSearchResult"
},
v5 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    },
    {
      "kind": "ScalarField",
      "name": "hasPreviousPage"
    },
    {
      "kind": "ScalarField",
      "name": "endCursor"
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v7 = {
  "kind": "ScalarField",
  "name": "id"
},
v8 = {
  "kind": "ScalarField",
  "name": "key"
},
v9 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "text"
    },
    (v7/*: any*/)
  ]
},
v10 = [
  (v1/*: any*/)
],
v11 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "date"
    }
  ],
  "type": "JiraDatePickerField"
},
v12 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v13 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v11/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "kind": "ScalarField",
        "name": "dateTime"
      }
    ],
    "type": "JiraDateTimePickerField"
  },
  (v12/*: any*/)
],
v14 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/)
],
v15 = {
  "kind": "LinkedField",
  "name": "startDateViewField",
  "plural": false,
  "selections": (v14/*: any*/)
},
v16 = {
  "kind": "LinkedField",
  "name": "endDateViewField",
  "plural": false,
  "selections": (v14/*: any*/)
},
v17 = {
  "kind": "ScalarField",
  "name": "name"
},
v18 = [
  (v17/*: any*/),
  {
    "concreteType": "JiraAvatar",
    "kind": "LinkedField",
    "name": "avatar",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "small"
      }
    ]
  },
  (v7/*: any*/)
],
v19 = {
  "concreteType": "JiraIssueTypeField",
  "kind": "LinkedField",
  "name": "issueTypeField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": (v18/*: any*/)
    },
    (v7/*: any*/)
  ]
},
v20 = {
  "concreteType": "JiraStatusField",
  "kind": "LinkedField",
  "name": "statusField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraStatusCategory",
          "kind": "LinkedField",
          "name": "statusCategory",
          "plural": false,
          "selections": [
            (v8/*: any*/),
            (v7/*: any*/)
          ]
        },
        (v7/*: any*/),
        (v17/*: any*/)
      ]
    },
    (v7/*: any*/)
  ]
},
v21 = {
  "concreteType": "JiraSingleSelectUserPickerField",
  "kind": "LinkedField",
  "name": "assigneeField",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        (v17/*: any*/),
        {
          "kind": "ScalarField",
          "name": "picture"
        },
        (v7/*: any*/)
      ]
    },
    (v7/*: any*/)
  ]
},
v22 = {
  "concreteType": "JiraRichTextField",
  "kind": "LinkedField",
  "name": "descriptionField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraRichText",
      "kind": "LinkedField",
      "name": "richText",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraADF",
          "kind": "LinkedField",
          "name": "adfValue",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraAdfToConvertedPlainText",
              "kind": "LinkedField",
              "name": "convertedPlainText",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "plainText"
                }
              ]
            }
          ]
        }
      ]
    },
    (v7/*: any*/)
  ]
},
v23 = {
  "concreteType": "JiraProjectField",
  "kind": "LinkedField",
  "name": "projectField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": (v18/*: any*/)
    },
    (v7/*: any*/)
  ]
},
v24 = {
  "kind": "ScalarField",
  "name": "scenarioKey"
},
v25 = {
  "kind": "LinkedField",
  "name": "node",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v7/*: any*/),
    {
      "kind": "TypeDiscriminator",
      "abstractKey": "__isJiraScenarioIssueLike"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v8/*: any*/),
        (v9/*: any*/),
        {
          "args": (v10/*: any*/),
          "kind": "LinkedField",
          "name": "startDateViewField",
          "plural": false,
          "selections": (v13/*: any*/)
        },
        {
          "args": (v10/*: any*/),
          "kind": "LinkedField",
          "name": "endDateViewField",
          "plural": false,
          "selections": (v13/*: any*/)
        },
        {
          "args": (v10/*: any*/),
          "concreteType": "JiraScenarioIssueValues",
          "kind": "LinkedField",
          "name": "planScenarioValues",
          "plural": false,
          "selections": [
            (v9/*: any*/),
            (v15/*: any*/),
            (v16/*: any*/)
          ]
        },
        (v19/*: any*/),
        {
          "alias": "requestTypeField",
          "args": [
            {
              "kind": "Literal",
              "name": "idOrAlias",
              "value": "requesttype"
            },
            {
              "kind": "Literal",
              "name": "ignoreMissingField",
              "value": true
            }
          ],
          "kind": "LinkedField",
          "name": "fieldByIdOrAlias",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            (v7/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "concreteType": "JiraServiceManagementRequestType",
                  "kind": "LinkedField",
                  "name": "requestType",
                  "plural": false,
                  "selections": (v18/*: any*/)
                }
              ],
              "type": "JiraServiceManagementRequestTypeField"
            }
          ],
          "storageKey": "fieldByIdOrAlias(idOrAlias:\"requesttype\",ignoreMissingField:true)"
        },
        (v20/*: any*/),
        {
          "args": (v10/*: any*/),
          "concreteType": "JiraScenarioIssueValues",
          "kind": "LinkedField",
          "name": "planScenarioValues",
          "plural": false,
          "selections": [
            (v9/*: any*/),
            (v19/*: any*/),
            (v15/*: any*/),
            (v16/*: any*/),
            (v20/*: any*/),
            (v21/*: any*/),
            (v22/*: any*/),
            (v23/*: any*/)
          ]
        },
        (v21/*: any*/),
        (v22/*: any*/),
        (v23/*: any*/),
        {
          "condition": "schedulePermissionsEnabled",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "canSchedule",
              "args": [
                {
                  "kind": "Literal",
                  "name": "permission",
                  "value": "SCHEDULE_ISSUES"
                }
              ],
              "kind": "ScalarField",
              "name": "hasProjectPermission",
              "storageKey": "hasProjectPermission(permission:\"SCHEDULE_ISSUES\")"
            }
          ]
        }
      ],
      "type": "JiraIssue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": (v10/*: any*/),
          "concreteType": "JiraScenarioIssueValues",
          "kind": "LinkedField",
          "name": "planScenarioValues",
          "plural": false,
          "selections": [
            (v24/*: any*/),
            (v9/*: any*/),
            (v15/*: any*/),
            (v16/*: any*/)
          ]
        },
        {
          "args": (v10/*: any*/),
          "concreteType": "JiraScenarioIssueValues",
          "kind": "LinkedField",
          "name": "planScenarioValues",
          "plural": false,
          "selections": [
            (v24/*: any*/),
            (v19/*: any*/),
            (v9/*: any*/),
            (v15/*: any*/),
            (v16/*: any*/),
            (v20/*: any*/),
            (v21/*: any*/),
            (v22/*: any*/),
            (v23/*: any*/)
          ]
        }
      ],
      "type": "JiraScenarioIssue"
    },
    (v12/*: any*/)
  ]
},
v26 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v27 = {
  "concreteType": "JiraIssueNavigatorPageInfo",
  "kind": "LinkedField",
  "name": "issueNavigatorPageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "firstIssuePosition"
    },
    {
      "kind": "ScalarField",
      "name": "lastIssuePosition"
    }
  ]
},
v28 = [
  {
    "kind": "ScalarField",
    "name": "pageNumber"
  },
  (v26/*: any*/)
],
v29 = {
  "args": [
    {
      "kind": "Literal",
      "name": "maxCursors",
      "value": 7
    },
    {
      "kind": "Variable",
      "name": "pageSize",
      "variableName": "issuesPageSize"
    }
  ],
  "concreteType": "JiraPageCursors",
  "kind": "LinkedField",
  "name": "pageCursors",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraPageCursor",
      "kind": "LinkedField",
      "name": "first",
      "plural": false,
      "selections": (v28/*: any*/)
    },
    {
      "concreteType": "JiraPageCursor",
      "kind": "LinkedField",
      "name": "around",
      "plural": true,
      "selections": (v28/*: any*/)
    },
    {
      "concreteType": "JiraPageCursor",
      "kind": "LinkedField",
      "name": "last",
      "plural": false,
      "selections": (v28/*: any*/)
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "calendarRendererIssues_jira_refetchQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "configurationInput",
            "variableName": "configurationInput"
          },
          {
            "kind": "Variable",
            "name": "issuesPageCursor",
            "variableName": "issuesPageCursor"
          },
          {
            "kind": "Variable",
            "name": "issuesPageSize",
            "variableName": "issuesPageSize"
          },
          {
            "kind": "Variable",
            "name": "issuesSearchInput",
            "variableName": "issuesSearchInput"
          },
          {
            "kind": "Variable",
            "name": "pageLoadMoreEnabled",
            "variableName": "pageLoadMoreEnabled"
          },
          {
            "kind": "Variable",
            "name": "schedulePermissionsEnabled",
            "variableName": "schedulePermissionsEnabled"
          },
          {
            "kind": "Variable",
            "name": "scopeInput",
            "variableName": "scopeInput"
          },
          {
            "kind": "Variable",
            "name": "skipFieldConfig",
            "variableName": "skipFieldConfig"
          },
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "calendarRendererIssues_jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "calendarRendererIssues_jira_refetchQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "configuration",
                "variableName": "configurationInput"
              },
              {
                "kind": "Variable",
                "name": "scope",
                "variableName": "scopeInput"
              }
            ],
            "concreteType": "JiraCalendar",
            "kind": "LinkedField",
            "name": "jiraCalendar",
            "plural": false,
            "selections": [
              {
                "condition": "pageLoadMoreEnabled",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": "paginatedIssuesV2",
                    "args": (v2/*: any*/),
                    "concreteType": "JiraScenarioIssueLikeConnection",
                    "kind": "LinkedField",
                    "name": "issuesV2",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "concreteType": "JiraScenarioIssueLikeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          (v25/*: any*/),
                          (v26/*: any*/)
                        ]
                      },
                      (v27/*: any*/),
                      (v29/*: any*/),
                      (v12/*: any*/)
                    ]
                  },
                  {
                    "alias": "paginatedIssuesV2",
                    "args": (v2/*: any*/),
                    "filters": [
                      "input"
                    ],
                    "handle": "connection",
                    "key": "calendarRendererIssues_paginatedIssuesV2",
                    "kind": "LinkedHandle",
                    "name": "issuesV2"
                  }
                ]
              },
              {
                "condition": "pageLoadMoreEnabled",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "args": (v2/*: any*/),
                    "concreteType": "JiraScenarioIssueLikeConnection",
                    "kind": "LinkedField",
                    "name": "issuesV2",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "concreteType": "JiraScenarioIssueLikeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          (v25/*: any*/)
                        ]
                      },
                      (v27/*: any*/),
                      (v29/*: any*/),
                      (v12/*: any*/)
                    ]
                  }
                ]
              },
              {
                "condition": "skipFieldConfig",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "startDateField",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "type"
                      },
                      (v7/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "1a68d6ee8b9af848d4cb944b340de903bb37c656374af03bd0fc13707f1ee79e",
    "metadata": {},
    "name": "calendarRendererIssues_jira_refetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "de599f809676d1dfe91c4d9de074bc70";

export default node;
