/**
 * @generated SignedSource<<0bf8911d2be033ae751cf02b3c2bdb02>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type flyout_calendar_IssueFlyout$data = {
  readonly assigneeField?: {
    readonly user: {
      readonly name: string;
      readonly picture: AGG$URL;
    } | null | undefined;
  } | null | undefined;
  readonly descriptionField?: {
    readonly richText: {
      readonly adfValue: {
        readonly convertedPlainText: {
          readonly plainText: string | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly endDateViewField?: {
    readonly date?: AGG$Date | null | undefined;
  } | null | undefined;
  readonly issueTypeField?: {
    readonly issueType: {
      readonly avatar: {
        readonly small: string | null | undefined;
      } | null | undefined;
      readonly name: string;
    } | null | undefined;
  } | null | undefined;
  readonly key?: string;
  readonly planScenarioValues?: {
    readonly assigneeField: {
      readonly user: {
        readonly name: string;
        readonly picture: AGG$URL;
      } | null | undefined;
    } | null | undefined;
    readonly descriptionField: {
      readonly richText: {
        readonly adfValue: {
          readonly convertedPlainText: {
            readonly plainText: string | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly endDateViewField: {
      readonly date?: AGG$Date | null | undefined;
    } | null | undefined;
    readonly issueTypeField: {
      readonly issueType: {
        readonly avatar: {
          readonly small: string | null | undefined;
        } | null | undefined;
        readonly name: string;
      } | null | undefined;
    } | null | undefined;
    readonly projectField: {
      readonly project: {
        readonly avatar: {
          readonly small: string | null | undefined;
        } | null | undefined;
        readonly name: string;
      } | null | undefined;
    } | null | undefined;
    readonly startDateViewField: {
      readonly date?: AGG$Date | null | undefined;
    } | null | undefined;
    readonly statusField: {
      readonly status: {
        readonly name: string | null | undefined;
        readonly statusCategory: {
          readonly key: string | null | undefined;
        } | null | undefined;
      };
    } | null | undefined;
    readonly summaryField: {
      readonly text: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly projectField?: {
    readonly project: {
      readonly avatar: {
        readonly small: string | null | undefined;
      } | null | undefined;
      readonly name: string;
    } | null | undefined;
  } | null | undefined;
  readonly startDateViewField?: {
    readonly date?: AGG$Date | null | undefined;
  } | null | undefined;
  readonly statusField?: {
    readonly status: {
      readonly name: string | null | undefined;
      readonly statusCategory: {
        readonly key: string | null | undefined;
      } | null | undefined;
    };
  } | null | undefined;
  readonly summaryField?: {
    readonly text: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "flyout_calendar_IssueFlyout";
};
export type flyout_calendar_IssueFlyout$key = {
  readonly " $data"?: flyout_calendar_IssueFlyout$data;
  readonly " $fragmentSpreads": FragmentRefs<"flyout_calendar_IssueFlyout">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "key"
},
v1 = {
  "kind": "ScalarField",
  "name": "name"
},
v2 = [
  (v1/*: any*/),
  {
    "concreteType": "JiraAvatar",
    "kind": "LinkedField",
    "name": "avatar",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "small"
      }
    ]
  }
],
v3 = {
  "concreteType": "JiraIssueTypeField",
  "kind": "LinkedField",
  "name": "issueTypeField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": (v2/*: any*/)
    }
  ]
},
v4 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "text"
    }
  ]
},
v5 = {
  "concreteType": "JiraRichTextField",
  "kind": "LinkedField",
  "name": "descriptionField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraRichText",
      "kind": "LinkedField",
      "name": "richText",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraADF",
          "kind": "LinkedField",
          "name": "adfValue",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraAdfToConvertedPlainText",
              "kind": "LinkedField",
              "name": "convertedPlainText",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "plainText"
                }
              ]
            }
          ]
        }
      ]
    }
  ]
},
v6 = [
  {
    "kind": "Variable",
    "name": "viewId",
    "variableName": "viewId"
  }
],
v7 = [
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "kind": "ScalarField",
        "name": "date"
      }
    ],
    "type": "JiraDatePickerField"
  }
],
v8 = {
  "concreteType": "JiraStatusField",
  "kind": "LinkedField",
  "name": "statusField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "concreteType": "JiraStatusCategory",
          "kind": "LinkedField",
          "name": "statusCategory",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ]
        }
      ]
    }
  ]
},
v9 = {
  "concreteType": "JiraSingleSelectUserPickerField",
  "kind": "LinkedField",
  "name": "assigneeField",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "name": "picture"
        }
      ]
    }
  ]
},
v10 = {
  "concreteType": "JiraProjectField",
  "kind": "LinkedField",
  "name": "projectField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": (v2/*: any*/)
    }
  ]
},
v11 = {
  "kind": "LinkedField",
  "name": "startDateViewField",
  "plural": false,
  "selections": (v7/*: any*/)
},
v12 = {
  "kind": "LinkedField",
  "name": "endDateViewField",
  "plural": false,
  "selections": (v7/*: any*/)
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "name": "flyout_calendar_IssueFlyout",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "args": (v6/*: any*/),
          "kind": "LinkedField",
          "name": "startDateViewField",
          "plural": false,
          "selections": (v7/*: any*/)
        },
        {
          "args": (v6/*: any*/),
          "kind": "LinkedField",
          "name": "endDateViewField",
          "plural": false,
          "selections": (v7/*: any*/)
        },
        (v8/*: any*/),
        (v9/*: any*/),
        (v10/*: any*/),
        {
          "args": (v6/*: any*/),
          "concreteType": "JiraScenarioIssueValues",
          "kind": "LinkedField",
          "name": "planScenarioValues",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            (v5/*: any*/),
            (v3/*: any*/),
            (v11/*: any*/),
            (v12/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ]
        }
      ],
      "type": "JiraIssue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": (v6/*: any*/),
          "concreteType": "JiraScenarioIssueValues",
          "kind": "LinkedField",
          "name": "planScenarioValues",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v11/*: any*/),
            (v12/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ]
        }
      ],
      "type": "JiraScenarioIssue"
    }
  ],
  "type": "JiraScenarioIssueLike",
  "abstractKey": "__isJiraScenarioIssueLike"
};
})();

(node as any).hash = "685cb625c919cda75271b5da1f8756f8";

export default node;
