/**
 * @generated SignedSource<<1b7ce150f785a52021c80eff35af1e2b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueRenderer_calendar_IssueEventRenderer$data = {
  readonly __id: string;
  readonly canSchedule?: boolean | null | undefined;
  readonly endDateViewField?: {
    readonly __id: string;
    readonly date?: AGG$Date | null | undefined;
    readonly dateTime?: AGG$DateTime | null | undefined;
    readonly id: string;
  } | null | undefined;
  readonly id: string;
  readonly issueTypeField?: {
    readonly issueType: {
      readonly avatar: {
        readonly small: string | null | undefined;
      } | null | undefined;
      readonly name: string;
    } | null | undefined;
  } | null | undefined;
  readonly key?: string;
  readonly planScenarioValues?: {
    readonly endDateViewField: {
      readonly __typename: "JiraDatePickerField";
      readonly __id: string;
      readonly date?: AGG$Date | null | undefined;
      readonly id: string;
    } | null | undefined;
    readonly issueTypeField: {
      readonly issueType: {
        readonly avatar: {
          readonly small: string | null | undefined;
        } | null | undefined;
        readonly name: string;
      } | null | undefined;
    } | null | undefined;
    readonly scenarioKey?: string | null | undefined;
    readonly startDateViewField: {
      readonly __typename: "JiraDatePickerField";
      readonly __id: string;
      readonly date?: AGG$Date | null | undefined;
      readonly id: string;
    } | null | undefined;
    readonly statusField: {
      readonly status: {
        readonly statusCategory: {
          readonly key: string | null | undefined;
        } | null | undefined;
      };
    } | null | undefined;
    readonly summaryField: {
      readonly text: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly requestTypeField?: {
    readonly __typename: "JiraServiceManagementRequestTypeField";
    readonly id: string;
    readonly requestType?: {
      readonly avatar: {
        readonly small: string | null | undefined;
      } | null | undefined;
      readonly name: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly startDateViewField?: {
    readonly __id: string;
    readonly date?: AGG$Date | null | undefined;
    readonly dateTime?: AGG$DateTime | null | undefined;
    readonly id: string;
  } | null | undefined;
  readonly statusField?: {
    readonly status: {
      readonly statusCategory: {
        readonly key: string | null | undefined;
      } | null | undefined;
    };
  } | null | undefined;
  readonly summaryField?: {
    readonly text: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"eventSummary_calendar" | "flyout_calendar_IssueFlyout">;
  readonly " $fragmentType": "issueRenderer_calendar_IssueEventRenderer";
};
export type issueRenderer_calendar_IssueEventRenderer$key = {
  readonly " $data"?: issueRenderer_calendar_IssueEventRenderer$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueRenderer_calendar_IssueEventRenderer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "key"
},
v2 = [
  {
    "kind": "ScalarField",
    "name": "name"
  },
  {
    "concreteType": "JiraAvatar",
    "kind": "LinkedField",
    "name": "avatar",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "small"
      }
    ]
  }
],
v3 = {
  "concreteType": "JiraIssueTypeField",
  "kind": "LinkedField",
  "name": "issueTypeField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": (v2/*: any*/)
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v5 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "text"
    }
  ]
},
v6 = [
  {
    "kind": "Variable",
    "name": "viewId",
    "variableName": "viewId"
  }
],
v7 = {
  "kind": "ScalarField",
  "name": "date"
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    (v7/*: any*/)
  ],
  "type": "JiraDatePickerField"
},
v9 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v10 = [
  (v0/*: any*/),
  (v8/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "kind": "ScalarField",
        "name": "dateTime"
      }
    ],
    "type": "JiraDateTimePickerField"
  },
  (v9/*: any*/)
],
v11 = {
  "concreteType": "JiraStatusField",
  "kind": "LinkedField",
  "name": "statusField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraStatusCategory",
          "kind": "LinkedField",
          "name": "statusCategory",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ]
        }
      ]
    }
  ]
},
v12 = [
  (v0/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      (v4/*: any*/),
      (v7/*: any*/)
    ],
    "type": "JiraDatePickerField"
  },
  (v9/*: any*/)
],
v13 = [
  (v0/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "schedulePermissionsEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "name": "issueRenderer_calendar_IssueEventRenderer",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v3/*: any*/),
        {
          "condition": "schedulePermissionsEnabled",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "canSchedule",
              "args": [
                {
                  "kind": "Literal",
                  "name": "permission",
                  "value": "SCHEDULE_ISSUES"
                }
              ],
              "kind": "ScalarField",
              "name": "hasProjectPermission",
              "storageKey": "hasProjectPermission(permission:\"SCHEDULE_ISSUES\")"
            }
          ]
        },
        {
          "alias": "requestTypeField",
          "args": [
            {
              "kind": "Literal",
              "name": "idOrAlias",
              "value": "requesttype"
            },
            {
              "kind": "Literal",
              "name": "ignoreMissingField",
              "value": true
            }
          ],
          "kind": "LinkedField",
          "name": "fieldByIdOrAlias",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                (v4/*: any*/),
                {
                  "concreteType": "JiraServiceManagementRequestType",
                  "kind": "LinkedField",
                  "name": "requestType",
                  "plural": false,
                  "selections": (v2/*: any*/)
                }
              ],
              "type": "JiraServiceManagementRequestTypeField"
            }
          ],
          "storageKey": "fieldByIdOrAlias(idOrAlias:\"requesttype\",ignoreMissingField:true)"
        },
        (v5/*: any*/),
        {
          "args": (v6/*: any*/),
          "kind": "LinkedField",
          "name": "startDateViewField",
          "plural": false,
          "selections": (v10/*: any*/)
        },
        {
          "args": (v6/*: any*/),
          "kind": "LinkedField",
          "name": "endDateViewField",
          "plural": false,
          "selections": (v10/*: any*/)
        },
        (v11/*: any*/),
        {
          "args": (v6/*: any*/),
          "concreteType": "JiraScenarioIssueValues",
          "kind": "LinkedField",
          "name": "planScenarioValues",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v3/*: any*/),
            {
              "kind": "LinkedField",
              "name": "startDateViewField",
              "plural": false,
              "selections": (v12/*: any*/)
            },
            {
              "kind": "LinkedField",
              "name": "endDateViewField",
              "plural": false,
              "selections": (v12/*: any*/)
            },
            (v11/*: any*/)
          ]
        }
      ],
      "type": "JiraIssue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": (v6/*: any*/),
          "concreteType": "JiraScenarioIssueValues",
          "kind": "LinkedField",
          "name": "planScenarioValues",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "scenarioKey"
            },
            (v3/*: any*/),
            (v5/*: any*/),
            {
              "kind": "LinkedField",
              "name": "startDateViewField",
              "plural": false,
              "selections": (v13/*: any*/)
            },
            {
              "kind": "LinkedField",
              "name": "endDateViewField",
              "plural": false,
              "selections": (v13/*: any*/)
            },
            (v11/*: any*/)
          ]
        }
      ],
      "type": "JiraScenarioIssue"
    },
    {
      "args": (v6/*: any*/),
      "kind": "FragmentSpread",
      "name": "eventSummary_calendar"
    },
    {
      "args": (v6/*: any*/),
      "kind": "FragmentSpread",
      "name": "flyout_calendar_IssueFlyout"
    },
    (v9/*: any*/)
  ],
  "type": "JiraScenarioIssueLike",
  "abstractKey": "__isJiraScenarioIssueLike"
};
})();

(node as any).hash = "420981649bfe66a338a9a98c66697082";

export default node;
