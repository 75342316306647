/**
 * @generated SignedSource<<4765b1c22cd3c42236692a33270bb8d9>>
 * @relayHash ffdf0bfd2bc69d712a5ff065286ebc1e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6190eb8bbc90ab87bc0369ef57dbd21d89b78607929e18e14a34856483b1dfd0

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_releaseFlyoutQuery$variables = {
  id: string;
  skipVersionsV1Search?: boolean | null | undefined;
  skipVersionsV2Search?: boolean | null | undefined;
  viewId?: string | null | undefined;
};
export type ui_releaseFlyoutQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ui_ReleaseFlyout">;
  };
};
export type ui_releaseFlyoutQuery = {
  response: ui_releaseFlyoutQuery$data;
  variables: ui_releaseFlyoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": true,
  "kind": "LocalArgument",
  "name": "skipVersionsV1Search"
},
v2 = {
  "defaultValue": true,
  "kind": "LocalArgument",
  "name": "skipVersionsV2Search"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = {
  "kind": "Variable",
  "name": "viewId",
  "variableName": "viewId"
},
v6 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v7 = {
  "kind": "ScalarField",
  "name": "name"
},
v8 = {
  "kind": "ScalarField",
  "name": "description"
},
v9 = {
  "kind": "ScalarField",
  "name": "startDate"
},
v10 = {
  "kind": "ScalarField",
  "name": "releaseDate"
},
v11 = {
  "kind": "ScalarField",
  "name": "id"
},
v12 = [
  {
    "kind": "ScalarField",
    "name": "totalCount"
  }
],
v13 = [
  {
    "kind": "ScalarField",
    "name": "date"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "ui_releaseFlyoutQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "args": (v4/*: any*/),
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "skipVersionsV1Search",
                      "variableName": "skipVersionsV1Search"
                    },
                    {
                      "kind": "Variable",
                      "name": "skipVersionsV2Search",
                      "variableName": "skipVersionsV2Search"
                    },
                    (v5/*: any*/)
                  ],
                  "kind": "FragmentSpread",
                  "name": "ui_ReleaseFlyout"
                }
              ],
              "type": "JiraVersion"
            }
          ]
        },
        "action": "THROW",
        "path": "node"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ui_releaseFlyoutQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "kind": "ScalarField",
                "name": "status"
              },
              {
                "kind": "ScalarField",
                "name": "versionId"
              },
              {
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "key"
                  },
                  {
                    "concreteType": "JiraAvatar",
                    "kind": "LinkedField",
                    "name": "avatar",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "xsmall"
                      },
                      (v6/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "projectTypeName"
                  },
                  (v11/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "name": "driver",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "picture"
                  },
                  (v11/*: any*/)
                ]
              },
              {
                "alias": "doneIssues",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "filters",
                    "value": {
                      "statusCategories": "DONE"
                    }
                  }
                ],
                "concreteType": "JiraIssueConnection",
                "kind": "LinkedField",
                "name": "issues",
                "plural": false,
                "selections": (v12/*: any*/),
                "storageKey": "issues(filters:{\"statusCategories\":\"DONE\"})"
              },
              {
                "alias": "inprogressIssues",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "filters",
                    "value": {
                      "statusCategories": "IN_PROGRESS"
                    }
                  }
                ],
                "concreteType": "JiraIssueConnection",
                "kind": "LinkedField",
                "name": "issues",
                "plural": false,
                "selections": (v12/*: any*/),
                "storageKey": "issues(filters:{\"statusCategories\":\"IN_PROGRESS\"})"
              },
              {
                "alias": "todoIssues",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "filters",
                    "value": {
                      "statusCategories": "TODO"
                    }
                  }
                ],
                "concreteType": "JiraIssueConnection",
                "kind": "LinkedField",
                "name": "issues",
                "plural": false,
                "selections": (v12/*: any*/),
                "storageKey": "issues(filters:{\"statusCategories\":\"TODO\"})"
              },
              {
                "args": [
                  (v5/*: any*/)
                ],
                "concreteType": "JiraVersionPlanScenarioValues",
                "kind": "LinkedField",
                "name": "planScenarioValues",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "scenarioType"
                  },
                  {
                    "condition": "skipVersionsV1Search",
                    "kind": "Condition",
                    "passingValue": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ]
                  },
                  {
                    "condition": "skipVersionsV2Search",
                    "kind": "Condition",
                    "passingValue": false,
                    "selections": [
                      {
                        "concreteType": "JiraDateScenarioValueField",
                        "kind": "LinkedField",
                        "name": "startDateValue",
                        "plural": false,
                        "selections": (v13/*: any*/)
                      },
                      {
                        "concreteType": "JiraDateScenarioValueField",
                        "kind": "LinkedField",
                        "name": "releaseDateValue",
                        "plural": false,
                        "selections": (v13/*: any*/)
                      }
                    ]
                  },
                  (v8/*: any*/)
                ]
              }
            ],
            "type": "JiraVersion"
          },
          (v11/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "6190eb8bbc90ab87bc0369ef57dbd21d89b78607929e18e14a34856483b1dfd0",
    "metadata": {},
    "name": "ui_releaseFlyoutQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "91f7a8e98be3ed80a5ae98a4aeb9f15d";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
