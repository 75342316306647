import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	moreLinkText: {
		id: 'calendar.calendar-renderer.more-link-text',
		defaultMessage: '{number} more',
		description: 'Text for the "More" link in the calendar',
	},
	moreLinkHintText: {
		id: 'calendar.calendar-renderer.more-link-hint-text',
		defaultMessage: 'Show {number} more',
		description: 'Hint text for the "More" link in the calendar',
	},
});
