import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	previous: {
		id: 'calendar.calendar-top-controls-bar.date-controls.previous',
		description: 'Label for the button to go to the previous month on a Jira calendar',
		defaultMessage: 'Previous month',
	},
	next: {
		id: 'calendar.calendar-top-controls-bar.date-controls.next',
		description: 'Label for the button to go to the next month on a Jira calendar',
		defaultMessage: 'Next month',
	},
	today: {
		id: 'calendar.calendar-top-controls-bar.date-controls.today',
		description: 'Label for the button to go to the current month on a Jira calendar',
		defaultMessage: 'Today',
	},
});
