import { useEffect, useRef } from 'react';
import { graphql, useFragment } from 'react-relay';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';

import type { LazyLoadIssues$key } from '@atlassian/jira-relay/src/__generated__/LazyLoadIssues.graphql';
import { DEFAULT_MAX_ISSUES_PAGES_TO_LOAD } from '../../../common/constants.tsx';
import { useIssuesPageCursor } from '../../../controllers/calendar-store/index.tsx';

type LazyLoadIssuesProps = {
	onChange: (cursor: string) => void;
	queryRef: LazyLoadIssues$key;
	pagesToLoad?: number;
};
/**
 * LazyLoadIssues will load the first X pages of issues
 */
export const LazyLoadIssues: React.FC<LazyLoadIssuesProps> = ({
	onChange,
	queryRef,
	pagesToLoad = DEFAULT_MAX_ISSUES_PAGES_TO_LOAD,
}) => {
	const data = useFragment<LazyLoadIssues$key>(
		graphql`
			fragment LazyLoadIssues on JiraScenarioIssueLikeConnection {
				pageInfo {
					hasNextPage
					endCursor
				}
			}
		`,
		queryRef,
	);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const issuesPageCursor = useIssuesPageCursor();
	const pages = useRef(0);

	// Reset the page count if the cursor is reset to null, this happens when filters or dates change
	if (issuesPageCursor === null) {
		pages.current = 0;
	}

	useEffect(() => {
		if (data?.pageInfo?.hasNextPage && data?.pageInfo?.endCursor && pages.current < pagesToLoad) {
			onChange(data.pageInfo.endCursor);
			fireOperationalAnalytics(createAnalyticsEvent({}), 'calendar lazyLoadPage', {
				lazyLoadPageCount: pages.current + 1,
			});
			pages.current += 1;
		}
	}, [
		createAnalyticsEvent,
		data?.pageInfo?.endCursor,
		data.pageInfo?.hasNextPage,
		onChange,
		pagesToLoad,
	]);

	return null;
};
