import React from 'react';
import format from 'date-fns/format';
import { graphql, useMutation } from 'react-relay';
import { Text } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { toDateOnly } from '@atlassian/jira-platform-utils-date-only/src/index.tsx';
import type { useUpdateVersionDateMutation_updateVersionDateMutation } from '@atlassian/jira-relay/src/__generated__/useUpdateVersionDateMutation_updateVersionDateMutation.graphql';
import { isErrorAllowed } from '../../common/utils/agg-errors/index.tsx';
import { toMidnightUTCString } from '../../common/utils/dates/index.tsx';
import { combineErrorMessages } from '../../common/utils/graphql/index.tsx';
import { useCalendarActions, useCalendarCallbacks } from '../calendar-store/index.tsx';
import { useCalendarExperience } from '../use-calendar-experience/index.tsx';
import { useLogger } from '../use-logger/index.tsx';
import { messages } from './messages.tsx';

export interface ExecuteVersionUpdateMutationParams {
	versionId: string;
	versionName: string;
	startDate: Date | undefined;
	releaseDate: Date;
}

/**
 * Update startDate and releaseDate on dropping a Version
 */
export function useUpdateVersionDateMutation() {
	const [commitDateUpdate] = useMutation<useUpdateVersionDateMutation_updateVersionDateMutation>(
		graphql`
			mutation useUpdateVersionDateMutation_updateVersionDateMutation(
				$input: JiraVersionUpdateMutationInput!
			) {
				jira {
					updateJiraVersion(input: $input) @optIn(to: "JiraUpdateVersion") {
						success
						errors {
							message
							extensions {
								statusCode
								errorType
							}
						}
						version {
							id
							startDate
							releaseDate
						}
					}
				}
			}
		`,
	);

	const { setIsLoading } = useCalendarActions();
	const { showFlag } = useFlagService();
	const { formatMessage } = useIntl();
	const { onScheduled } = useCalendarCallbacks();
	const { scheduleVersionExperience } = useCalendarExperience();
	const { logError } = useLogger();

	const executeUpdateVersionDateMutation = ({
		versionId,
		versionName,
		startDate,
		releaseDate,
	}: ExecuteVersionUpdateMutationParams) => {
		const startDateTimeUtc = startDate
			? toDateOnly(format(startDate, 'yyyy-MM-dd'))?.toUTCDate()
			: null;
		const endDateTimeUtc = toDateOnly(format(releaseDate, 'yyyy-MM-dd'))?.toUTCDate();

		if (!endDateTimeUtc) {
			return;
		}

		const invalidReleaseDate = releaseDate && startDate && releaseDate < startDate;

		if (invalidReleaseDate) {
			const formatErrorMessage = {
				b: (strongText: string) => <Text as="strong">{strongText}</Text>,
			};

			logError(
				'calendar.schedule-version.failure',
				`Error thrown when attempting to update version dates: ${formatMessage(messages.releaseBeforeStartDateErrorTitle)}.`,
			);

			showFlag({
				id: 'jira-calendar-schedule-version-failure',
				title: formatMessage(messages.releaseBeforeStartDateErrorTitle),
				description: [messages.releaseBeforeStartDateError, formatErrorMessage],
				type: 'error',
			});
			return;
		}

		setIsLoading(true);

		scheduleVersionExperience.start();

		commitDateUpdate({
			variables: {
				input: {
					id: versionId,
					name: versionName,
					startDate: startDateTimeUtc ? toMidnightUTCString(startDateTimeUtc) : null,
					releaseDate: toMidnightUTCString(endDateTimeUtc),
				},
			},
			optimisticResponse: {
				jira: {
					updateJiraVersion: {
						success: true,
						errors: [],
						version: {
							id: versionId,
							startDate: startDateTimeUtc?.toISOString(),
							releaseDate: endDateTimeUtc?.toISOString(),
						},
					},
				},
			},
			onError(error: Error) {
				setIsLoading(false);

				scheduleVersionExperience.failure();

				logError('calendar.schedule-version.failure', 'Failed to update version dates', error);

				showFlag({
					id: 'jira-calendar-schedule-version-error',
					title: formatMessage(messages.errorTitle),
					description: formatMessage(messages.failedToUpdateDatesError),
					type: 'error',
				});
			},
			onCompleted(response) {
				setIsLoading(false);

				if (!response?.jira?.updateJiraVersion?.success) {
					const errorResponse = response?.jira?.updateJiraVersion?.errors;
					if (fg('calendar_handles_graphql_error')) {
						if (isErrorAllowed(errorResponse)) {
							scheduleVersionExperience.abort();
						} else {
							scheduleVersionExperience.failure();
						}
					} else {
						scheduleVersionExperience.abort();
					}
					const errorMessage =
						combineErrorMessages(errorResponse) ?? formatMessage(messages.failedToUpdateDatesError);
					logError(
						'calendar.schedule-version.failure',
						`Error thrown when attempting to update version dates: ${errorMessage}.`,
					);

					showFlag({
						id: 'jira-calendar-schedule-version-failure',
						title: formatMessage(messages.errorTitle),
						description: errorMessage,
						type: 'error',
					});
					return;
				}

				onScheduled?.({
					id: versionId,
					startDate: startDateTimeUtc ? new Date(toMidnightUTCString(startDateTimeUtc)) : undefined,
					endDate: new Date(toMidnightUTCString(endDateTimeUtc)),
					type: 'version',
				});

				scheduleVersionExperience.success();
			},
		});
	};

	return { executeUpdateVersionDateMutation };
}
