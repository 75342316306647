import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	errorTitle: {
		id: 'calendar.controllers.use-update-sprint-date-mutation.error-title',
		defaultMessage: 'Something went wrong',
		description:
			'Title for the error message displayed when the calendar fails to update sprint dates',
	},
	failedToUpdateDatesError: {
		id: 'calendar.controllers.use-update-sprint-date-mutation.failed-to-update-dates-error',
		defaultMessage: 'Failed to update sprint dates.',
		description:
			'Error message displayed when the calendar fails to update sprint on drag-and-drop.',
	},
});
