import {
	type Action,
	createContainer,
	createStore,
	createStateHook,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import * as actions from './actions.tsx';
import type { ConnectionState, ConnectionStoreState } from './types.tsx';

const initialState: ConnectionStoreState = { stage: 'pending' };

type ConnectionContainerProps = {
	calendarRendererConnectionId?: string;
	unscheduledPanelConnectionId?: string;
	unscheduledToggleConnectionId?: string;
};

/** @deprecated This store is being removed once subscriptions are in place for its use case. */
export const ConnectionStoreContainer = createContainer<ConnectionContainerProps>({
	displayName: 'ConnectionStoreContainer',
});

const ConnectionStore = createStore({
	containedBy: ConnectionStoreContainer,
	actions,
	initialState,
	handlers: {
		onInit:
			(): Action<ConnectionStoreState, {}> =>
			(
				{ setState },
				{
					calendarRendererConnectionId,
					unscheduledPanelConnectionId,
					unscheduledToggleConnectionId,
				}: ConnectionContainerProps,
			) => {
				setState({
					stage: 'ready',
					calendarRendererConnectionId,
					unscheduledPanelConnectionId,
					unscheduledToggleConnectionId,
				});
			},
	},
});

export const useConnectionActions = createActionsHook(ConnectionStore);

/**
 * Create a state hook asserting that the state is ready
 */
function createConnectionStateHook<T>(selector: (state: ConnectionState) => T) {
	return createStateHook(ConnectionStore, {
		selector: (state) => {
			if (state.stage !== 'ready') {
				throw new Error('Trying to select from pending store');
			}
			return selector(state);
		},
	});
}

/** @deprecated This store is being removed once subscriptions are in place for its use case. */
export const useCalendarRendererConnectionId = createConnectionStateHook(
	(state) => state.calendarRendererConnectionId,
);
/** @deprecated This store is being removed once subscriptions are in place for its use case. */
export const useUnscheduledPanelConnectionId = createConnectionStateHook(
	(state) => state.unscheduledPanelConnectionId,
);
/** @deprecated This store is being removed once subscriptions are in place for its use case. */
export const useUnscheduledToggleConnectionId = createConnectionStateHook(
	(state) => state.unscheduledToggleConnectionId,
);
