import React from 'react';
import { useFragment, graphql } from 'react-relay';
import Avatar from '@atlaskit/avatar';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import type { projectFieldValueLabel_softwareFiltersPopup$key } from '@atlassian/jira-relay/src/__generated__/projectFieldValueLabel_softwareFiltersPopup.graphql';

export function ProjectFieldValueLabel({
	fieldValueRef,
}: {
	fieldValueRef: projectFieldValueLabel_softwareFiltersPopup$key;
	mode: 'dropdown' | 'popup';
}) {
	const data = useFragment(
		graphql`
			fragment projectFieldValueLabel_softwareFiltersPopup on JiraJqlProjectFieldValue {
				project {
					avatar {
						xsmall
					}
					name
				}
			}
		`,
		fieldValueRef,
	);

	return (
		<Inline space="space.100" alignBlock="center">
			{data.project?.avatar?.xsmall && (
				<Box xcss={avatarContainerStyles}>
					<Avatar size="small" appearance="square" src={data.project?.avatar.xsmall} />
				</Box>
			)}

			<Box>{data.project.name}</Box>
		</Inline>
	);
}

const avatarContainerStyles = xcss({
	// Compensate the avatar margin
	margin: 'space.negative.025',
});
