import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'calendar.feedback-collector-button.title',
		defaultMessage: 'Give feedback to Atlassian',
		description: 'Title for a feedback dialog',
	},
	titleDescription: {
		id: 'calendar.feedback-collector-button.title-description',
		defaultMessage: 'Select Calendar feedback type',
		description: 'Description for a feedback dialog',
	},
});
