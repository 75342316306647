/** @jsx jsx */
import React from 'react';
import { jsx } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { Y400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export const ChangeIndicator = () => (
	<Box xcss={triangleStyles}>
		<svg
			viewBox="0 0 100 100"
			xmlns="http://www.w3.org/2000/svg"
			width="10" // Seems to be a minimum width/height else the svg doesn't render properly in chrome
			height="10"
		>
			<polygon
				points="0,0  100,0  100,100"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, jira/react/no-style-attribute
				style={{ fill: token('color.background.warning.bold.hovered', Y400) }}
			/>
		</svg>
	</Box>
);

const triangleStyles = xcss({
	position: 'absolute',
	width: '10px',
	top: '-5px',
	right: 'space.0',
	overflow: 'hidden',
});
