import React, { useEffect, type ComponentPropsWithoutRef } from 'react';
import { graphql, usePaginationFragment } from 'react-relay';
import Button from '@atlaskit/button/new';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import Image from '@atlaskit/image';
import Link from '@atlaskit/link';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { PopupSelect, components, type OptionProps, type PopupSelectProps } from '@atlaskit/select';
import Spinner from '@atlaskit/spinner';
import { N300A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { getIssueTypesSettingsUrl as oldGetIssueTypesSettingsUrl } from '@atlassian/jira-business-issue-types/src/index.tsx';
import { TEAM_MANAGED_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import type { issueTypeField_calendar$key } from '@atlassian/jira-relay/src/__generated__/issueTypeField_calendar.graphql';
import type { issueTypeField_calendar_RefetchPaginated_Query } from '@atlassian/jira-relay/src/__generated__/issueTypeField_calendar_RefetchPaginated_Query.graphql';
import { PaginationTrigger } from '@atlassian/jira-software-pagination-trigger/src/index.tsx';
import { useGlobalIssueCreateContext } from '../../../../../../controllers/global-issue-create-provider/index.tsx';
import messages from './messages.tsx';
import { getIssueTypesSettingsUrl } from './utils.tsx';

const ISSUE_TYPE = 'issueType';
const LINK = 'link';

export type IssueTypeOption = {
	id?: string;
	label: string;
	value: string;
	iconUrl: string;
	optionType: 'issueType';
};

type PaginationTriggerOption = {
	loadNext: ComponentPropsWithoutRef<typeof PaginationTrigger>['loadNext'];
	isLoadingNext: boolean;
};

type LinkOption = {
	label: string;
	value: string;
	url: string;
	optionType: 'link';
};

export type IssueTypeSelectOption = IssueTypeOption | PaginationTriggerOption | LinkOption;

const PopupSelectOption = ({ children, ...props }: OptionProps<IssueTypeSelectOption>) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	if ('loadNext' in props.data) {
		return (
			<Flex alignItems="center" justifyContent="center" xcss={paginationContainerStyles}>
				<PaginationTrigger
					pageSize={5}
					hasNextPage
					isLoadingNext={props.data.isLoadingNext}
					loadNext={props.data.loadNext}
				/>
				<Spinner
					interactionName="load"
					label={formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.loadingMoreIssueTermRefresh
							: messages.loadingMore,
					)}
				/>
			</Flex>
		);
	}

	if (props.data.optionType === ISSUE_TYPE) {
		return (
			<components.Option {...props}>
				<Box as="span" xcss={popupSelectOptionIconContainerStyles}>
					<Image src={props.data.iconUrl} width={16} height={16} alt="" />
				</Box>
				{children}
			</components.Option>
		);
	}
	return (
		<Box xcss={extraLinksStyles}>
			<Link
				appearance="subtle"
				href={props.data.url}
				onClick={() => {
					fireUIAnalytics(
						createAnalyticsEvent({}),
						'link clicked',
						'jiraCalendarViewIccManageIssueTypesLink',
					);
				}}
			>
				<components.Option {...props}>{children}</components.Option>
			</Link>
		</Box>
	);
};

type PopupSelectTriggerProps = Parameters<Exclude<PopupSelectProps['target'], undefined>>[0];

const IssueTypeTriggerButton = ({
	issueType,
	isOpen,
	...triggerProps
}: PopupSelectTriggerProps & { issueType?: IssueTypeOption | null }) => {
	const { formatMessage } = useIntl();

	return (
		<Button
			{...triggerProps}
			aria-label={
				issueType?.label
					? formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.issueTypeTriggerWithSelectionIssueTermRefresh
								: messages.issueTypeTriggerWithSelection,
							{
								selectedIssueType: issueType.label,
							},
						)
					: formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.issueTypeTriggerIssueTermRefresh
								: messages.issueTypeTrigger,
						)
			}
			iconBefore={() => <Image src={issueType?.iconUrl} alt="" height={16} width={16} />}
			iconAfter={ChevronDownIcon}
			spacing="compact"
			shouldFitContainer
		>
			{issueType?.label}
		</Button>
	);
};

type IssueTypeFieldProps = Omit<PopupSelectProps<IssueTypeSelectOption>, 'onChange'> & {
	queryRef: issueTypeField_calendar$key | null;
	onChange: (selectedOption: IssueTypeOption) => void;
	value?: IssueTypeOption | null;
};

export function IssueTypeField({ queryRef, onChange, ...popupSelectProps }: IssueTypeFieldProps) {
	const { formatMessage } = useIntl();
	const { hasManageTypesLink } = useGlobalIssueCreateContext();
	const { key, data: projectData } = useProjectContext();

	const { data, hasNext, loadNext, isLoadingNext } = usePaginationFragment<
		issueTypeField_calendar_RefetchPaginated_Query,
		issueTypeField_calendar$key
	>(
		graphql`
			fragment issueTypeField_calendar on JiraProject
			@argumentDefinitions(cursor: { type: "String" }, first: { type: "Int", defaultValue: 10 })
			@refetchable(queryName: "issueTypeField_calendar_RefetchPaginated_Query") {
				issueTypes(first: $first, after: $cursor)
					@connection(key: "inlineCardCreate_calendar_issueTypes") {
					edges {
						node {
							__id
							id
							name
							avatar {
								small
							}
							hierarchy {
								level
							}
						}
					}
					totalCount
				}
				admin: action(type: EDIT_PROJECT_CONFIG) {
					canPerform
				}
				projectType
				projectStyle
				key
			}
		`,
		queryRef,
	);

	let manageTypesLink: string | undefined;
	if (fg('calendar_manage_types_link_in_icc')) {
		manageTypesLink =
			(hasManageTypesLink &&
				(fg('manage-types-calendar-icc-link-software')
					? data?.projectStyle &&
						data?.key &&
						data?.projectType &&
						getIssueTypesSettingsUrl(
							data.key,
							data.projectStyle === TEAM_MANAGED_PROJECT,
							data?.projectType,
						)
					: (key && projectData && oldGetIssueTypesSettingsUrl(key, projectData.simplified)) ??
						undefined)) ||
			undefined;
	}

	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'issueTypeField listChanged', 'jiraCalendarViewIcc', {
			numIssueTypes: data?.issueTypes?.totalCount,
		});
	}, [createAnalyticsEvent, data?.issueTypes]);

	const issueTypeOptions: IssueTypeSelectOption[] | undefined = data?.issueTypes?.edges
		?.filter(
			(issueType) =>
				typeof issueType?.node?.hierarchy?.level === 'number' &&
				issueType?.node?.hierarchy?.level > -1,
		)
		.map((issueType) => ({
			id: issueType?.node?.__id,
			label: issueType?.node?.name ?? '',
			value: issueType?.node?.id ?? '',
			iconUrl: issueType?.node?.avatar?.small ?? '',
			optionType: ISSUE_TYPE,
		}));

	const loadNextHandler = (...args: Parameters<typeof loadNext>) => {
		fireUIAnalytics(
			createAnalyticsEvent({}),
			'issueTypeField loadedNextPage',
			'jiraCalendarViewIcc',
		);

		return loadNext(...args);
	};

	if (hasNext) {
		issueTypeOptions?.push({
			loadNext: loadNextHandler,
			isLoadingNext,
		});
	}

	const linkOption: LinkOption = {
		label: formatMessage(messages.manageIssueTypes),
		value: manageTypesLink ?? '',
		url: manageTypesLink ?? '',
		optionType: LINK,
	};

	let options: IssueTypeSelectOption[];
	if (fg('manage-types-calendar-icc-link-software')) {
		options = [
			...(issueTypeOptions ?? []),
			...(data?.admin?.canPerform && manageTypesLink ? [linkOption] : []),
		];
	} else {
		options = [
			...(issueTypeOptions ?? []),
			...(projectData?.isProjectAdmin && manageTypesLink ? [linkOption] : []),
		];
	}

	useEffect(() => {
		if (issueTypeOptions && issueTypeOptions?.length > 0) {
			if (!('loadNext' in issueTypeOptions[0])) {
				const valueNotSet = !popupSelectProps.value;
				const valueNotInOptionList =
					popupSelectProps.value &&
					!issueTypeOptions.find(
						(option) => 'id' in option && option.id === popupSelectProps.value?.id,
					);

				if ((valueNotSet || valueNotInOptionList) && issueTypeOptions[0].optionType !== LINK) {
					onChange(issueTypeOptions[0]);
				}
			}
		}
	}, [issueTypeOptions, onChange, popupSelectProps.value]);

	return (
		<PopupSelect<IssueTypeSelectOption>
			{...popupSelectProps}
			aria-label={formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.issueTypeFieldLabelIssueTermRefresh
					: messages.issueTypeFieldLabel,
			)}
			options={fg('calendar_manage_types_link_in_icc') ? options : issueTypeOptions}
			components={{ Option: PopupSelectOption }}
			isSearchable={false}
			onChange={(selectedOption) =>
				selectedOption &&
				!('loadNext' in selectedOption) &&
				selectedOption.optionType === ISSUE_TYPE
					? onChange(selectedOption)
					: undefined
			}
			target={(triggerProps) =>
				IssueTypeTriggerButton({
					...triggerProps,
					issueType: popupSelectProps.value,
				})
			}
		/>
	);
}

const paginationContainerStyles = xcss({
	height: '36px',
});

const popupSelectOptionIconContainerStyles = xcss({
	marginRight: 'space.100',
	verticalAlign: 'middle',
});

const extraLinksStyles = xcss({
	borderTop: `2px solid ${token('color.border', N300A)}`,
	marginTop: 'space.050',
	paddingBlockStart: 'space.050',
});
