import type { ComponentType, ReactNode, SyntheticEvent } from 'react';
import type { Dispatch } from 'redux';
import flow from 'lodash/flow';
import memoizeOne from 'memoize-one';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import { SelectedIssuesSubscriber } from '@atlassian/jira-issue-table-selection-services/src/services/index.tsx';
import { MAX_ISSUES_PER_PAGE } from '@atlassian/jira-issue-table/src/common/table/constants.tsx';
import IssueTable from '@atlassian/jira-issue-table/src/index.tsx';
import type { SortOrder } from '@atlassian/jira-issue-table/src/model/fields/sort-order/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { AsyncBulkActionToolbar } from '@atlassian/jira-servicedesk-bulk-actions-toolbar/src/async.tsx';
import type { Column } from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import { getProjectKey } from '@atlassian/jira-servicedesk-queues-common/src/state/selectors/app-props/index.tsx';
import type { VerticalPosition } from '../../../model/index.tsx';
import { loadIssuesActionForPage } from '../../../state/actions/issue/index.tsx';
import { displayQueuesErrorHeaderAction } from '../../../state/actions/page/index.tsx';
import {
	tableChangedAction,
	activeItemChangedAction,
	activeItemRemovedAction,
	pageChangedAction,
	sortOrderChangedAction,
	fetchIssueDataOnScrollAction,
	toggleQueueHeaderAction,
} from '../../../state/actions/table/index.tsx';
import {
	refreshSidebarIssueView,
	setSelectedIssueKeys,
	setSidebarIssueKey,
} from '../../../state/actions/ui/index.tsx';
import {
	restartPollAction,
	stopPollAction,
} from '../../../state/actions/update-metadata/index.tsx';
import type { State } from '../../../state/reducers/types.tsx';
import { getHasLoadedIssueDataFromCache } from '../../../state/selectors/experience-tracking/index.tsx';
import { getIsFilterFocused } from '../../../state/selectors/filter/index.tsx';
import { getCollection } from '../../../state/selectors/issues/index.tsx';
import {
	getCurrentPage,
	getIssueKeysForPage,
	getTotalPages,
} from '../../../state/selectors/pagination/index.tsx';
import {
	getCategory,
	getColumnsInOrder,
	getQueueId,
	isQueueTransient,
	getMetricKey,
	getQueueName,
} from '../../../state/selectors/queue/index.tsx';
import { getSortedByColumn, getSortOrder } from '../../../state/selectors/sorting/index.tsx';
import { getIssueTableAppProps } from '../../../state/selectors/table/index.tsx';
import { isQueueVisible, isLoadingIssues } from '../../../state/selectors/ui/index.tsx';
import DemoDripFeed from '../demo-drip-feed/index.tsx';
import FieldDataSelector from './field/index.tsx';
import KeyboardShortcutListener from './keyboard-shortcut-listener/index.tsx';
import { getColumnsForIssueTable } from './selectors.tsx';
import Table, { type Props } from './view.tsx';

type OwnProps = {
	useJSMQueueDurationEvent: () => void;
	renderSidebarIcon?: (arg1: string) => ReactNode;
	width: string | number;
	tableWidth: number;
	shouldRerenderRowOnHover?: boolean;
};

const onActiveItemChanged = (payload: { rowId: string; columnId: string }) =>
	(payload && activeItemChangedAction(payload.rowId, payload.columnId)) ||
	activeItemRemovedAction();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: State, ownProps: OwnProps): any => {
	const isLoading = isLoadingIssues(state);
	return {
		appProps: getIssueTableAppProps(state),
		queueId: getQueueId(state),
		queueName: fg('jsm_virtual_table_a11y') ? getQueueName(state) : undefined,
		queueCategory: getCategory(state),
		columns: getColumnsInOrder(state),
		DemoDripFeed,
		FieldDataSelector,
		issueKeys: getIssueKeysForPage(state),
		IssueTable,
		KeyboardShortcutListener,
		AsyncBulkActionToolbar,
		SelectedIssuesSubscriber,
		currentPage: getCurrentPage(state),
		totalPages: getTotalPages(state),
		sortedBy: getSortedByColumn(state),
		sortOrder: getSortOrder(state),
		isQueueVisible: isQueueVisible(state),
		isQueueTransient: isQueueTransient(state),
		projectKey: getProjectKey(state),
		isLoading,
		isContentStale: isLoading && getCollection(state).length > 0,
		metricKey: getMetricKey(state),
		hasLoadedIssueDataFromCache: getHasLoadedIssueDataFromCache(state),
		canFocusTable: !getIsFilterFocused(state),
		useJSMQueueDurationEvent: ownProps.useJSMQueueDurationEvent,
		renderSidebarIcon: ownProps.renderSidebarIcon,
		shouldRerenderRowOnHover: ownProps.shouldRerenderRowOnHover,
		width: ownProps.width,
		tableWidth: ownProps.tableWidth,
	};
};

// We want to avoid creating a new anonymous function every time the state has changed, so that later in `issue-table`
// we can compare the prop to decide whether we should update the component.
const onSortOrderChangedGenerator = memoizeOne(
	(dispatch) => (sortedBy: string, sortOrder: SortOrder) =>
		dispatch(sortOrderChangedAction(sortedBy, sortOrder)),
);

const mapDispatchToProps = (
	dispatch: Dispatch<{
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		type: any;
	}>,
) => ({
	// @ts-expect-error - TS7006 - Parameter 'payload' implicitly has an 'any' type.
	onActiveItemChanged: (payload) => dispatch(onActiveItemChanged(payload)),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onPageChange: (event: SyntheticEvent<any>, newPage: number) => {
		dispatch(pageChangedAction(newPage));
		dispatch(loadIssuesActionForPage(newPage));
	},
	onSortOrderChanged: onSortOrderChangedGenerator(dispatch),
	onIssueDataRequested: (startIndex: number) => dispatch(fetchIssueDataOnScrollAction(startIndex)),
	onVerticalScrollChanged: (verticalPosition: VerticalPosition) =>
		dispatch(toggleQueueHeaderAction(verticalPosition.direction)),
	onTableChange: (analyticsEvent: UIAnalyticsEvent) => dispatch(tableChangedAction(analyticsEvent)),
	onSetSelectedIssueKeys: (selectedIssueKeys: string[] | undefined) =>
		dispatch(setSelectedIssueKeys(selectedIssueKeys)),
	onRefreshSidebar: ({ issueKey }: { issueKey?: string } = {}) => {
		dispatch(refreshSidebarIssueView({ issueKey }));
	},
	onSetIssueKey: (key: string | null) => {
		dispatch(setSidebarIssueKey(key));
	},
	onNonPremiumSLAColumnError: () => dispatch(displayQueuesErrorHeaderAction()),
	controlPolling: {
		restartPolling: () => {
			dispatch(refreshSidebarIssueView());
			dispatch(restartPollAction());
		},
		stopPolling: () => dispatch(stopPollAction()),
	},
});

const getColumnsForIssueTableFactory = memoizeOne(
	(columns: Column[], onSortOrderChanged: (arg1: string, arg2: SortOrder) => void) =>
		getColumnsForIssueTable(columns, onSortOrderChanged),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mergeProps = (stateProps: any, dispatchProps: any): Props => ({
	...stateProps,
	...dispatchProps,
	columns: getColumnsForIssueTableFactory(stateProps.columns, dispatchProps.onSortOrderChanged),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const connectedTable: ComponentType<Record<any, any>> = flow(
	withFireUiAnalytics({
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onPageChange: (event: SyntheticEvent<any>, newPage: number) => ({
			name: 'queue',
			pageNumber: newPage,
			startIndex: MAX_ISSUES_PER_PAGE * (newPage - 1) + 1,
		}),
	}),
	connect(mapStateToProps, mapDispatchToProps, mergeProps),
	AnalyticsSubject('queue'),
)(Table);

export default connectedTable;
