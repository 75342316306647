import {
	JIRA_PROJECT_TYPE_CORE_PROJECT,
	JIRA_PROJECT_TYPE_SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { JiraProjectType } from '@atlassian/jira-relay/src/__generated__/issueTypeField_calendar.graphql';

export const getIssueTypesSettingsUrl = (
	projectKey: string,
	isSimplified: boolean,
	projectType: JiraProjectType,
) => {
	switch (projectType) {
		case JIRA_PROJECT_TYPE_SOFTWARE_PROJECT:
			return isSimplified
				? `/jira/software/projects/${projectKey}/settings/issuetypes`
				: `/plugins/servlet/project-config/${projectKey}/issuetypes`;
		case JIRA_PROJECT_TYPE_CORE_PROJECT:
			return isSimplified
				? `/jira/core/projects/${projectKey}/settings/issuetypes`
				: `/plugins/servlet/project-config/${projectKey}/issuetypes`;
		default:
			return undefined;
	}
};
