import React from 'react';
import {
	ContextualAnalyticsData,
	type AnalyticsContextData,
} from '@atlassian/jira-product-analytics-bridge';

const ANALYTICS_SOURCE = 'jiraCalendar';

export type CalendarAnalyticsContextProps = Omit<AnalyticsContextData, 'sourceName'>;

export const CalendarAnalyticsContext = ({
	children,
	sourceType,
	attributes,
}: CalendarAnalyticsContextProps) => (
	<ContextualAnalyticsData
		sourceName={ANALYTICS_SOURCE}
		sourceType={sourceType}
		attributes={attributes}
	>
		{children}
	</ContextualAnalyticsData>
);
