import React, { type ComponentPropsWithoutRef } from 'react';
import { Flex } from '@atlaskit/primitives';
import Select, { type FormatOptionLabelMeta } from '@atlaskit/select';
import Spinner from '@atlaskit/spinner';
import { useIntl } from '@atlassian/jira-intl';
import { formatOptionLabel } from '@atlassian/jira-issue-field-select-base/src/ui/format-option-label/index.tsx';
import type { main_calendar_CalendarInlineCardCreate$data } from '@atlassian/jira-relay/src/__generated__/main_calendar_CalendarInlineCardCreate.graphql';
import { PaginationTrigger } from '@atlassian/jira-software-pagination-trigger/src/index.tsx';
import { messages } from './messages.tsx';

export const PROJECT_FIELD_NEXT_PAGE_OPTION_VALUE = 'NEXT_PAGE';

type JiraProject = NonNullable<
	NonNullable<
		NonNullable<
			NonNullable<
				NonNullable<
					NonNullable<main_calendar_CalendarInlineCardCreate$data['jira']>['jiraCalendar']
				>['projects']
			>['edges']
		>[0]
	>['node']
>;

export type ProjectFieldOption = {
	label: string;
	value: string;
	iconUrl: string;
	isDisabled?: boolean;
	ref?: JiraProject;
};

type PaginationProps = Pick<
	ComponentPropsWithoutRef<typeof PaginationTrigger>,
	'loadNext' | 'isLoadingNext'
>;

const formatOptionLabelWithPagination = (
	option: ProjectFieldOption,
	context: FormatOptionLabelMeta<ProjectFieldOption>,
	{ loadNext, isLoadingNext }: PaginationProps,
	loadingMoreMessage: string,
) =>
	option.value === PROJECT_FIELD_NEXT_PAGE_OPTION_VALUE ? (
		<Flex alignItems="center" justifyContent="center">
			<PaginationTrigger
				pageSize={5}
				hasNextPage
				isLoadingNext={isLoadingNext}
				loadNext={loadNext}
			/>
			<Spinner interactionName="load" label={loadingMoreMessage} />
		</Flex>
	) : (
		formatOptionLabel(option, context)
	);

type ProjectFieldProps = {
	options?: ProjectFieldOption[];
	value?: ProjectFieldOption | null;
	onChange: (selectedValue: ProjectFieldOption | null) => void;
} & PaginationProps;

export function ProjectField({
	options,
	onChange,
	value,
	loadNext,
	isLoadingNext,
}: ProjectFieldProps) {
	const { formatMessage } = useIntl();

	const loadingMoreMessage = formatMessage(messages.loadingMoreProjects);

	return (
		<Select<ProjectFieldOption>
			placeholder=""
			isSearchable={false}
			options={options}
			formatOptionLabel={(option, context) =>
				formatOptionLabelWithPagination(
					option,
					context,
					{
						loadNext,
						isLoadingNext,
					},
					loadingMoreMessage,
				)
			}
			onChange={onChange}
			value={value}
			aria-label="Project"
		/>
	);
}
