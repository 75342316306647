import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	CalendarSkeleton,
	CalendarNoHeaderSkeleton,
	CalendarEmbedSkeleton,
} from '@atlassian/jira-skeletons/src/ui/calendar/CalendarSkeleton.tsx';
import { useCalendarCapabilities } from '../../common/controllers/capabilities-provider/index.tsx';

export function Skeleton() {
	const { hasHeader, hasBreadcrumbs, hasFilterBar } = useCalendarCapabilities();

	const NoHeaderComponent = hasFilterBar ? CalendarNoHeaderSkeleton : CalendarEmbedSkeleton;

	const renderContent = () => {
		if (hasHeader && hasBreadcrumbs) {
			return <CalendarSkeleton />;
		}
		return <NoHeaderComponent />;
	};

	return (
		<UFOLoadHold name="jira-calendar-skeleton">
			{getWillShowNav4() ? (
				<Box xcss={skeletonContainerStyle}>{renderContent()}</Box>
			) : (
				renderContent()
			)}
		</UFOLoadHold>
	);
}

const skeletonContainerStyle = xcss({
	paddingBlock: 'space.200',
});
