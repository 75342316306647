import { useCallback, useMemo } from 'react';
import type { RefetchFnDynamic } from 'react-relay';
import type { calendarRenderer_jira$key } from '@atlassian/jira-relay/src/__generated__/calendarRenderer_jira.graphql';
import type { calendarRenderer_jira_refetchQuery } from '@atlassian/jira-relay/src/__generated__/calendarRenderer_jira_refetchQuery.graphql';
import type { calendarRendererIssues_jira$key } from '@atlassian/jira-relay/src/__generated__/calendarRendererIssues_jira.graphql';
import type { calendarRendererIssues_jira_refetchQuery } from '@atlassian/jira-relay/src/__generated__/calendarRendererIssues_jira_refetchQuery.graphql';
import type { calendarRendererVersions_jira$key } from '@atlassian/jira-relay/src/__generated__/calendarRendererVersions_jira.graphql';
import type { calendarRendererVersions_jira_refetchQuery } from '@atlassian/jira-relay/src/__generated__/calendarRendererVersions_jira_refetchQuery.graphql';
import { useCalendarRefetchApiRef } from '../../../controllers/calendar-store/index.tsx';
import { useRefetchCalendarEvents } from '../../../controllers/use-refetch-calendar/index.tsx';
import { useRefetchCalendarIssues } from '../../../controllers/use-refetch-issues/index.tsx';
import { useRefetchCalendarVersions } from '../../../controllers/use-refetch-versions/index.tsx';
/**
 * This component updates the refetch API ref
 * it enables the consumer to trigger refresh with ref from outside calendar component.
 *
 * @param refetch - The refetch function.
 */
export const RefetchApiRef = ({
	issuesRefetch,
	refetch,
	versionRefetch,
}: {
	issuesRefetch: RefetchFnDynamic<
		calendarRendererIssues_jira_refetchQuery,
		calendarRendererIssues_jira$key
	>;
	refetch: RefetchFnDynamic<calendarRenderer_jira_refetchQuery, calendarRenderer_jira$key>;
	versionRefetch: RefetchFnDynamic<
		calendarRendererVersions_jira_refetchQuery,
		calendarRendererVersions_jira$key
	>;
}) => {
	const { refetchIssuesWithoutSuspense } = useRefetchCalendarIssues({ refetch: issuesRefetch });
	const { refetchCalendarWithoutSuspense } = useRefetchCalendarEvents({ refetch });
	const { refetchVersionsWithoutSuspense } = useRefetchCalendarVersions({
		refetch: versionRefetch,
	});

	const refetchApiRef = useCalendarRefetchApiRef();

	const onRefetch = useCallback(() => {
		refetchIssuesWithoutSuspense();
		refetchCalendarWithoutSuspense();
		refetchVersionsWithoutSuspense();
	}, [
		refetchIssuesWithoutSuspense,
		refetchCalendarWithoutSuspense,
		refetchVersionsWithoutSuspense,
	]);

	const refetchApi = useMemo(
		() => ({
			onRefetch,
			refetchVersionsWithoutSuspense,
		}),
		[onRefetch, refetchVersionsWithoutSuspense],
	);

	if (refetchApiRef) {
		refetchApiRef.current = refetchApi;
	}

	return null;
};
