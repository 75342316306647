import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	unableToScheduleTitle: {
		id: 'calendar.common.error-flags.unable-to-schedule-title',
		defaultMessage: 'Update fields to schedule issues',
		description:
			'Error message title shown to user on jira calendar when user is not allowed to schedule on calendar',
	},
	unableToScheduleDescription: {
		id: 'calendar.common.error-flags.unable-to-schedule-description',
		defaultMessage:
			'The calendar needs to use date-time fields to schedule issues on the week view. <a>Update date fields</a>.',
		description:
			'Error description shown to admin on jira calendar when user is not allowed to schedule on calendar',
	},
	unableToScheduleTitleIssueTermRefresh: {
		id: 'calendar.common.error-flags.unable-to-schedule-title-issue-term-refresh',
		defaultMessage: 'Update fields to schedule work items',
		description:
			'Error message title shown to user on jira calendar when user is not allowed to schedule on calendar',
	},
	unableToScheduleDescriptionIssueTermRefresh: {
		id: 'calendar.common.error-flags.unable-to-schedule-description-issue-term-refresh',
		defaultMessage:
			'The calendar needs to use date-time fields to schedule work items on the week view. <a>Update date fields</a>.',
		description:
			'Error description shown to admin on jira calendar when user is not allowed to schedule on calendar',
	},
});
