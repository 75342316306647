import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	errorTitle: {
		id: 'calendar.controllers.use-refetch-calendar.error-title',
		defaultMessage: 'Something went wrong',
		description: 'Title for the error message displayed when the calendar fails to re-fetch data',
	},
	failedToFetchDataError: {
		id: 'calendar.controllers.use-refetch-calendar.failed-to-fetch-data-error',
		defaultMessage: 'Failed to refresh calendar data.',
		description:
			'Error message displayed when the calendar fails to re-fetch data after filters have changed or the page has changed',
	},
});
