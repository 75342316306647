import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	timeFormat: {
		id: 'calendar.calendar-renderer.event-renderer.issue-renderer.event-summary.time-format',
		defaultMessage: '{startTime} - {endTime}',
		description: 'Time format for the event summary in the calendar view. Eg: 10:00 AM - 11:00 AM',
	},
	viewIssue: {
		id: 'calendar.calendar-renderer.event-renderer.issue-renderer.view-issue',
		defaultMessage: 'Open issue {issueKey}',
		description: 'Link to open the issue in a new tab',
	},
	overdueLabel: {
		id: 'calendar.calendar-renderer.event-renderer.issue-renderer.overdue-label',
		defaultMessage: 'Overdue',
		description:
			'Label for the overdue icon shown on Jira calendar view events that have a due-date in the past',
	},
	doneLabel: {
		id: 'calendar.calendar-renderer.event-renderer.issue-renderer.done-label',
		defaultMessage: 'Done',
		description:
			'Label for the done icon shown on Jira calendar view events that are already resolved',
	},
	viewIssueIssueTermRefresh: {
		id: 'calendar.calendar-renderer.event-renderer.issue-renderer.view-issue-issue-term-refresh',
		defaultMessage: 'Open work item {issueKey}',
		description: 'Link to open the issue in a new tab',
	},
});
