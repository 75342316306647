import parseISO from 'date-fns/parseISO';

/**
 * Removes the time portion from a date string.
 *
 * @param date - The date string to trim.
 * @returns The date string without the time portion.
 */
export function removeTimeFromDateString(
	dateString: string | undefined | null,
): string | undefined {
	return dateString?.replace(/T.*/, '');
}

/**
 * Converts a UTC date string to a local Date object.
 *
 * This function is designed to handle date strings where only the date portion is relevant and the time is always set to midnight UTC.
 * It first removes the time portion from the date string, then converts the resulting date-only string into a local Date object.
 * This ensures that the date is correctly adjusted for the local timezone, regardless of the original UTC time.
 *
 * @param utcDateString - A UTC date string in ISO 8601 format (e.g., "2022-01-01T00:00:00Z").
 * @returns A Date object representing the same date, but adjusted to the local timezone.
 */
export function toLocalDate(utcDateString: string | undefined): Date {
	const dateString = removeTimeFromDateString(utcDateString);
	if (!dateString) {
		return new Date(NaN);
	}
	return parseISO(dateString);
}
