/**
 * @generated SignedSource<<acc2b2bc7308968b963de9c7ad7f3f26>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type placeholderRenderer_calendar_PlaceholderEventRenderer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"inlineCardCreate_calendar">;
  readonly " $fragmentType": "placeholderRenderer_calendar_PlaceholderEventRenderer";
};
export type placeholderRenderer_calendar_PlaceholderEventRenderer$key = {
  readonly " $data"?: placeholderRenderer_calendar_PlaceholderEventRenderer$data;
  readonly " $fragmentSpreads": FragmentRefs<"placeholderRenderer_calendar_PlaceholderEventRenderer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "configurationInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeInput"
    }
  ],
  "kind": "Fragment",
  "name": "placeholderRenderer_calendar_PlaceholderEventRenderer",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "configurationInput",
          "variableName": "configurationInput"
        },
        {
          "kind": "Variable",
          "name": "scopeInput",
          "variableName": "scopeInput"
        }
      ],
      "kind": "FragmentSpread",
      "name": "inlineCardCreate_calendar"
    }
  ],
  "type": "Query"
};

(node as any).hash = "d846eb8bd2cea49b287373baeee987ba";

export default node;
