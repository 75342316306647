import React, { createContext, useMemo, memo, type ReactNode, useContext } from 'react';
import type { FeedbackCollectorButtonProps } from '@atlassian/jira-feedback-collector/src/ui/button/types.tsx';

export type MenuItem = {
	title: string;
	href?: string;
};

export type ComponentsContext = {
	/**
	 * Additional menu items, if passed used to render meatball menu along with action buttons
	 * on calendar header menu.
	 */
	additionalMenuItems: MenuItem[];

	/**
	 * Function that renders feedback collector button in calendar header. Receives default feedback collector
	 * button props that can be enriched with additional props before button is rendered.
	 */
	renderFeedbackButton?: (props: FeedbackCollectorButtonProps) => ReactNode;
};

const defaultComponentsContext: ComponentsContext = {
	additionalMenuItems: [],
	renderFeedbackButton: undefined,
};

const CalendarComponentsContext = createContext<ComponentsContext>(defaultComponentsContext);
export const useCalendarComponents = () => useContext(CalendarComponentsContext);

type Props = Partial<ComponentsContext> & {
	children: ReactNode;
};

/**
 * This provides additional components besides header menu.
 * For eg: Subtitle for calendar, additional menu items (meatball).
 */
export const CalendarComponentsProvider = memo(({ children, ...props }: Props) => {
	const components = useMemo(() => ({ ...defaultComponentsContext, ...props }), [props]);

	return (
		<CalendarComponentsContext.Provider value={components}>
			{children}
		</CalendarComponentsContext.Provider>
	);
});
