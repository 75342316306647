import React, { type SyntheticEvent, useCallback, useMemo, useEffect } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import { useThemeObserver } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import HelpPanelButton from '@atlassian/jira-help-panel-button/src/index.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { JSM_EMPTY_QUEUE_CREATE_REQUEST } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants.tsx';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import {
	fireScreenAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { usePracticeList } from '@atlassian/jira-servicedesk-queues-categorized-store/src/main.tsx';
import type { QueueCategory } from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import GenericEmptyQueueImage from './common/assets/generic.svg';
import GenericEmptyQueueImageInDarkMode from './common/assets/genericDark.svg';
import { EmptyPracticeQueue } from './common/ui/empty-practice-queue/index.tsx';
import { learnMoreUrl, MAX_IMAGE_HEIGHT, MAX_IMAGE_WIDTH } from './constants.tsx';
import messages from './messages.tsx';

export type EmptyStateProps = {
	header: string;
	description: string;
	category: QueueCategory;
	projectId: number;
	showCTA: boolean;
};

export const EmptyQueue = (props: EmptyStateProps) => {
	const { category, projectId, showCTA = true } = props;
	const [practices] = usePracticeList();
	const [, { openIssueCreateModal }] = useTriggerIssueCreateModal();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { colorMode } = useThemeObserver();
	const isDarkMode = colorMode === 'dark';
	const openCreateIssueDialogModern = useCallback(
		(event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'emptyQueueCreateIssueButton', {
				isAnyWorkCategoryEnabled: false,
			});
			openIssueCreateModal({
				triggerPointKey: JSM_EMPTY_QUEUE_CREATE_REQUEST,
			});
		},
		[openIssueCreateModal],
	);

	useEffect(() => {
		fireScreenAnalytics(createAnalyticsEvent({}));
	}, [createAnalyticsEvent]);

	const { formatMessage } = useIntl();

	const primaryAction = useMemo(() => {
		if (showCTA) {
			return (
				<Button
					appearance="primary"
					onClick={openCreateIssueDialogModern}
					data-pid={projectId}
					testId="servicedesk-queues-empty-queue.create-issue-button"
				>
					{formatMessage(
						fg('itsm-issue-renaming')
							? messages.emptyQueueButtonLabelIssueTermRefresh
							: messages.emptyQueueButtonLabel,
					)}
				</Button>
			);
		}
	}, [formatMessage, openCreateIssueDialogModern, projectId, showCTA]);

	// If ITSM Project, render the ITSM empty states
	if (category !== undefined && practices && practices.length > 1) {
		return <EmptyPracticeQueue projectId={projectId} />;
	}

	const IPHButton = (
		<HelpPanelButton
			articleId="5b7BuVaVJqmbyCZ2mbMYHD"
			appearance="link"
			spacing="none"
			fallbackComponent={
				<a target="_blank" href={learnMoreUrl}>
					{formatMessage(messages.emptyQueueLearnMoreText)}
				</a>
			}
		>
			{formatMessage(messages.emptyQueueLearnMoreText)}
		</HelpPanelButton>
	);

	return (
		<EmptyState
			header={formatMessage(messages.emptyQueueHeader)}
			description={
				<FormattedMessage
					{...(fg('itsm-issue-renaming')
						? messages.emptyQueueDescriptionIssueTermRefresh
						: messages.emptyQueueDescription)}
					values={{
						// @ts-expect-error - Type '(chunks: string) => JSX.Element' is not assignable to type 'ReactNode'.
						p: (chunks: string) => <p>{chunks}</p>,
						IPHArticle: IPHButton,
						break: <br />,
					}}
				/>
			}
			imageUrl={isDarkMode ? GenericEmptyQueueImageInDarkMode : GenericEmptyQueueImage}
			maxImageWidth={MAX_IMAGE_WIDTH}
			maxImageHeight={MAX_IMAGE_HEIGHT}
			primaryAction={primaryAction}
		/>
	);
};

export default EmptyQueue;
