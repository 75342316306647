import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	failedToCreateErrorTitle: {
		id: 'calendar.controllers.use-create-calendar-issue-mutation.failed-to-create-error-title',
		defaultMessage: 'Something went wrong',
		description: 'Title for the error message displayed when the calendar fails to create an issue',
	},
	failedToCreateErrorBody: {
		id: 'calendar.controllers.use-create-calendar-issue-mutation.failed-to-create-error-body',
		defaultMessage: 'Failed to create issue.',
		description: 'Error message displayed when the calendar fails to create an issue',
	},
	createIssueHiddenTitle: {
		id: 'calendar.controllers.use-create-calendar-issue-mutation.create-issue-hidden-title',
		defaultMessage: "Issue isn't visible",
		description:
			'Title for the error message displayed when the created issue is hidden on calendar',
	},
	createIssueHiddenBody: {
		id: 'calendar.controllers.use-create-calendar-issue-mutation.create-issue-hidden-body',
		defaultMessage: 'It is currently hidden on your calendar because of your view settings.',
		description: 'Error message displayed when the created issue is hidden on calendar',
	},
	failedToCreateErrorBodyIssueTermRefresh: {
		id: 'calendar.controllers.use-create-calendar-issue-mutation.failed-to-create-error-body-issue-term-refresh',
		defaultMessage: 'Failed to create work item.',
		description: 'Error message displayed when the calendar fails to create an issue',
	},
	createIssueHiddenTitleIssueTermRefresh: {
		id: 'calendar.controllers.use-create-calendar-issue-mutation.create-issue-hidden-title-issue-term-refresh',
		defaultMessage: "Work item isn't visible",
		description:
			'Title for the error message displayed when the created issue is hidden on calendar',
	},
});
