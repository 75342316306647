import React, { createContext, useContext, useMemo, memo, type ReactNode } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import type { IrremovableFieldsConfig } from '@atlassian/jira-jql-builder-basic/src/utils/irremovable-fields/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

export const PLATFORM = 'PLATFORM' as const;
export const FLYOUT = 'FLYOUT' as const;
export const NONE = 'NONE' as const;
export type ModalType = typeof PLATFORM | typeof FLYOUT | typeof NONE;

type EntityCapability = {
	canSchedule: boolean;
	visible: boolean;
	/**
	 * Determine how the modal will be displayed:
	 *
	 * PLATFORM: display the platform modal
	 * FLYOUT: display calendar flyout
	 * NONE: details will be opened in new tab
	 */
	modalType: ModalType;
	canCreate: boolean;
	canHide: boolean;
};

type ViewSettingsCapability = {
	hasHideDoneIssuesToggle: boolean;
};

export type CapabilitiesContext = {
	/** @deprecated The header option is being removed as part of the new navigation, see https://jbusiness.atlassian.net/browse/JSCAL-39 */
	hasHeader: boolean;
	hasFilterBar: boolean;
	hasBusinessFilter: boolean;
	hasSharedReleases: boolean;
	/** @deprecated The breadcrumbs are being removed as part of the new navigation, see https://jbusiness.atlassian.net/browse/JSCAL-39 */
	hasBreadcrumbs: boolean;
	hasUnscheduledPanel: boolean;
	hasAddPeopleButton: boolean;
	/** @deprecated The Share button is being removed in favour of a global Share button above the tab nav, see https://jbusiness.atlassian.net/browse/JSCAL-39 */
	hasShareButton: boolean;
	sprint: EntityCapability;
	issue: EntityCapability;
	version: EntityCapability;
	viewSettings: ViewSettingsCapability;
	irremovableFilters: IrremovableFieldsConfig;
	accessibleIconColors: boolean;
	weekViewEnabled: boolean;
	userTimeZoneForSprintEnabled: boolean;
	/** temporary flag for the business experiment */
	hasBusinessExperiment?: boolean;
};

export const defaultCapabilitiesContext: CapabilitiesContext = {
	hasHeader: true,
	hasFilterBar: true,
	hasBusinessFilter: false,
	hasSharedReleases: false,
	hasBreadcrumbs: true,
	hasUnscheduledPanel: true,
	hasAddPeopleButton: true,
	hasShareButton: false,
	hasBusinessExperiment: false,
	sprint: {
		visible: true,
		canSchedule: true,
		modalType: PLATFORM,
		canCreate: true,
		canHide: true,
	},
	issue: {
		visible: true,
		canSchedule: true,
		modalType: PLATFORM,
		canCreate: true,
		canHide: false,
	},
	version: {
		visible: true,
		canSchedule: true,
		modalType: PLATFORM,
		canCreate: true,
		canHide: true,
	},
	viewSettings: {
		hasHideDoneIssuesToggle: false,
	},
	irremovableFilters: [],
	accessibleIconColors: false,
	weekViewEnabled: false,
	userTimeZoneForSprintEnabled: true,
};

export const getEmbedCalendarCapabilities = (): CapabilitiesContext => ({
	hasHeader: false,
	hasFilterBar: false,
	hasBusinessFilter: false,
	hasSharedReleases: false,
	hasBreadcrumbs: false,
	hasUnscheduledPanel: false,
	hasAddPeopleButton: false,
	hasShareButton: false,
	hasBusinessExperiment: false,
	sprint: {
		visible: true,
		canSchedule: true,
		modalType: NONE,
		canCreate: false,
		canHide: true,
	},
	issue: {
		visible: true,
		canSchedule: true,
		modalType: NONE,
		canCreate: false,
		canHide: false,
	},
	version: {
		visible: true,
		canSchedule: true,
		modalType: NONE,
		canCreate: false,
		canHide: true,
	},
	viewSettings: {
		hasHideDoneIssuesToggle: false,
	},
	irremovableFilters: defaultCapabilitiesContext.irremovableFilters,
	accessibleIconColors: defaultCapabilitiesContext.accessibleIconColors,
	weekViewEnabled: defaultCapabilitiesContext.weekViewEnabled,
	userTimeZoneForSprintEnabled: true,
});

const CalendarCapabilitiesContext = createContext<CapabilitiesContext>(defaultCapabilitiesContext);

export const useCalendarCapabilities = () => useContext(CalendarCapabilitiesContext);

type Props = Partial<CapabilitiesContext> & {
	children: ReactNode;
};

export const CalendarCapabilitiesProvider = memo(({ children, ...props }: Props) => {
	// When cleaning up the code, set the hasAddPeopleButton as true on the defaultCapabilitiesContext
	const hasAddPeopleButton = !(isVisualRefreshEnabled() && fg('visual-refresh_drop_1'));

	const capabilities = useMemo(
		() => ({ ...defaultCapabilitiesContext, hasAddPeopleButton, ...props }),
		[props, hasAddPeopleButton],
	);

	return (
		<CalendarCapabilitiesContext.Provider value={capabilities}>
			{children}
		</CalendarCapabilitiesContext.Provider>
	);
});
