import React, { useCallback, useEffect, type MouseEvent } from 'react';
import { graphql, useFragment } from 'react-relay';
import Badge from '@atlaskit/badge';
import CalendarPlusIcon from '@atlaskit/icon/core/migration/calendar-plus--schedule';
import { Flex } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, type UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { ResponsiveThemedButton } from '@atlassian/jira-project-theme-components/src/ui/responsive-themed-button/ResponsiveThemedButton.tsx';
import { ThemedButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button/ThemedButton.tsx';
import type { calendarUnscheduledPanelToggle_calendar$key } from '@atlassian/jira-relay/src/__generated__/calendarUnscheduledPanelToggle_calendar.graphql';
import { ResponsiveButton } from '@atlassian/jira-responsive-button/src/ui/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';
import { SELECTED_ISSUE } from '../../../common/constants.tsx';
import { useCalendarIssueViewMode } from '../../../common/controllers/use-calendar-issue-view-mode/index.tsx';
import {
	useCalendarActions,
	useIsUnscheduledPanelOpen,
} from '../../../controllers/calendar-store/index.tsx';
import { useConnectionActions } from '../../../controllers/connection-store/index.tsx';
import { messages } from './messages.tsx';

interface CalendarUnscheduledPanelToggleProps {
	queryRef: calendarUnscheduledPanelToggle_calendar$key;
}

export function CalendarUnscheduledPanelToggle({ queryRef }: CalendarUnscheduledPanelToggleProps) {
	const { formatMessage } = useIntl();
	const isPanelOpen = useIsUnscheduledPanelOpen();
	const { toggleUnscheduledPanelVisibility } = useCalendarActions();
	const [selectedIssueKey] = useQueryParam(SELECTED_ISSUE);
	const { closeIssueView } = useCalendarIssueViewMode();
	const { setUnscheduledToggleConnectionId } = useConnectionActions();

	const data = useFragment(
		graphql`
			fragment calendarUnscheduledPanelToggle_calendar on Query
			@argumentDefinitions(
				scopeInput: { type: "JiraViewScopeInput!" }
				configurationInput: { type: "JiraCalendarViewConfigurationInput!" }
				unscheduledIssuesSearchInput: { type: "JiraCalendarIssuesInput" }
			) {
				unscheduledPanelToggleJira: jira {
					jiraCalendar(scope: $scopeInput, configuration: $configurationInput)
						@optIn(to: "JiraCalendar") {
						unscheduledIssues(input: $unscheduledIssuesSearchInput) {
							__id
							totalIssueSearchResultCount
						}
					}
				}
			}
		`,
		queryRef,
	);

	const totalIssueSearchResultCount =
		data?.unscheduledPanelToggleJira?.jiraCalendar?.unscheduledIssues?.totalIssueSearchResultCount;
	const totalCountString =
		totalIssueSearchResultCount != null ? ` (${totalIssueSearchResultCount})` : '';

	useEffect(() => {
		if (!fg('calendar_update_on_issue_modal_schedule')) return;
		setUnscheduledToggleConnectionId(
			data?.unscheduledPanelToggleJira?.jiraCalendar?.unscheduledIssues?.__id,
		);
	}, [setUnscheduledToggleConnectionId, data]);

	const handleClick = useCallback(
		(_: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			toggleUnscheduledPanelVisibility();
			if (selectedIssueKey) closeIssueView();
			fireUIAnalytics(analyticsEvent, 'unscheduledPanelToggle');
		},
		[closeIssueView, selectedIssueKey, toggleUnscheduledPanelVisibility],
	);

	if (isVisualRefreshEnabled() && fg('visual-refresh_drop_2')) {
		return (
			<Tooltip
				content={formatMessage(
					fg('visual-refresh_drop_5') ? messages.unscheduledPanelWork : messages.unscheduledPanel,
				)}
			>
				<ThemedButton
					testId="calendar.ui.calendar-top-controls-bar.calendar-unscheduled-panel-toggle.button"
					aria-label={`${fg('visual-refresh_drop_5') ? formatMessage(messages.unscheduledPanelWork) : formatMessage(messages.unscheduledPanel)} ${totalCountString}`}
					onClick={handleClick}
					isSelected={isPanelOpen}
					iconAfter={<CalendarPlusIcon color="currentColor" spacing="spacious" label="" />}
				/>
			</Tooltip>
		);
	}

	if (isVisualRefreshEnabled()) {
		return (
			<ThemedButton
				testId="calendar.ui.calendar-top-controls-bar.calendar-unscheduled-panel-toggle.button"
				aria-label={`${formatMessage(messages.unscheduledPanel)} ${totalCountString}`}
				onClick={handleClick}
				isSelected={isPanelOpen}
			>
				<Flex gap="space.100" alignItems="center">
					{formatMessage(messages.unscheduledPanel)}
					<Badge>
						{(totalIssueSearchResultCount || 0) > 999 ? '999+' : totalIssueSearchResultCount}
					</Badge>
				</Flex>
			</ThemedButton>
		);
	}

	return fg('jira-calendar-business-theme') ? (
		<ResponsiveThemedButton
			testId="calendar.ui.calendar-top-controls-bar.calendar-unscheduled-panel-toggle.button"
			icon={<CalendarPlusIcon color="currentColor" spacing="spacious" label="" />}
			label={`${formatMessage(messages.unscheduledPanel)} ${totalCountString}`}
			aria-label={`${formatMessage(messages.unscheduledPanel)} ${totalCountString}`}
			onClick={handleClick}
			isSelected={isPanelOpen}
		/>
	) : (
		<ResponsiveButton
			testId="calendar.ui.calendar-top-controls-bar.calendar-unscheduled-panel-toggle.button"
			icon={(props) => <CalendarPlusIcon color="currentColor" {...props} />}
			label={`${formatMessage(messages.unscheduledPanel)} ${totalCountString}`}
			aria-label={`${formatMessage(messages.unscheduledPanel)} ${totalCountString}`}
			onClick={handleClick}
			isSelected={isPanelOpen}
		/>
	);
}
