import React, { forwardRef, useState } from 'react';
import subDays from 'date-fns/subDays';
import { graphql, useFragment } from 'react-relay';
import type { PopupComponentProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { JiraPopup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import type { placeholderRenderer_calendar_PlaceholderEventRenderer$key } from '@atlassian/jira-relay/src/__generated__/placeholderRenderer_calendar_PlaceholderEventRenderer.graphql';
import { InlineCardCreate } from './inline-card-create/index.tsx';

interface PlaceholderEventRendererProps {
	event: {
		start: Date;
		end: Date;
	};
	iccContext: {
		queryRef: placeholderRenderer_calendar_PlaceholderEventRenderer$key | null;
		connectionId?: string;
		onClose: (() => void) | null;
	};
}

// @atlassian/calendar's built-in popup controller (provided by the onEventAdd callback)
// isn't used here as its implementation (at time of implementing this ICC) doesn't play nice with
// the use of pragmatic drag and drop in the calendar.
//
// The combination of pragmatic DnD and the package's popup controller implementation
// creates some undesirable interplay which can cause the popup position to be incorrect and the
// root cause could not be debugged, nor could a workaround be found.
const PopupContainer = forwardRef<HTMLDivElement, PopupComponentProps>(
	({ children, 'data-testid': testId, xcss: _xcss, ...props }, ref) => (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Box xcss={containerStyles} testId={testId} {...props} ref={ref}>
			{children}
		</Box>
	),
);

export function PlaceholderEventRenderer({ event, iccContext }: PlaceholderEventRendererProps) {
	const [eventElement, setEventElement] = useState<HTMLElement>();

	const { queryRef, connectionId, onClose } = iccContext;

	const projectsData = useFragment(
		graphql`
			fragment placeholderRenderer_calendar_PlaceholderEventRenderer on Query
			@argumentDefinitions(
				scopeInput: { type: "JiraViewScopeInput!" }
				configurationInput: { type: "JiraCalendarViewConfigurationInput!" }
			) {
				...inlineCardCreate_calendar
					@arguments(scopeInput: $scopeInput, configurationInput: $configurationInput)
			}
		`,
		queryRef,
	);

	// The FullCalendar container element that wraps this component
	const eventContainer = eventElement?.parentElement?.parentElement;

	// A placeholder event which spans multiple weeks will result in this component being rendered
	// multiple times (one for each week that it spans).
	// The `fc-event-end` class is only attached to the chronologically latest container element.
	const shouldShowIcc =
		eventContainer?.classList.contains('fc-event-end') && projectsData && connectionId && onClose;

	return (
		<>
			<Box ref={(ref: HTMLElement) => setEventElement(ref)} xcss={placeholderEventStyles} />
			{shouldShowIcc && (
				<JiraPopup
					isOpen
					popupComponent={PopupContainer}
					onClose={onClose ?? undefined}
					placement="top"
					messageId="calendar.ui.calendar-renderer.event-renderer.placeholder-renderer.jira-popup"
					messageType="transactional"
					content={({ setInitialFocusRef }) => (
						<InlineCardCreate
							setInitialFocusRef={setInitialFocusRef}
							startDate={event.start}
							endDate={subDays(event.end, 1)}
							queryRef={projectsData}
							connectionId={connectionId}
							onClose={onClose ?? undefined}
						/>
					)}
					trigger={({ ref }) => <Box ref={ref} xcss={popupTriggerStyles} />}
				/>
			)}
		</>
	);
}

const placeholderEventStyles = xcss({
	backgroundColor: 'color.background.accent.gray.subtlest',
	height: '24px',
	borderRadius: 'border.radius.100',
	// Negative margin allows this component to overlap/cover parent
	// wrapper element's transparent border set in @atlassian/calendar
	margin: 'space.negative.025',
});

const containerStyles = xcss({
	borderRadius: '3px',
	borderColor: 'color.border.focused',
	borderStyle: 'solid',
	borderWidth: '2px',
	backgroundColor: 'elevation.surface',
	outline: 'none',
});

const popupTriggerStyles = xcss({
	position: 'absolute',
	top: '0',
	width: '100%',
	left: '0',
});
