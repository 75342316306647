export function combineErrorMessages(
	errors: readonly { readonly message: string | null | undefined }[] | null | undefined,
): string | undefined {
	return (
		errors
			?.map((error) => error?.message)
			?.filter(Boolean)
			?.join('; ') || undefined
	);
}
