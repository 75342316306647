import React, { createContext, useContext, useMemo, memo, type ReactNode } from 'react';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';

export const DEFAULT_UNKNOWN_SUFFIX_KEY = 'UNKNOWN';

export type CalendarUFOContext = {
	suffixKey: string;
	packageName: string;
	teamName: string;
};

const defaultUFOContext: CalendarUFOContext = {
	suffixKey: DEFAULT_UNKNOWN_SUFFIX_KEY,
	packageName: PACKAGE_NAME,
	teamName: TEAM_NAME,
};

const CalendarUFOContext = createContext<CalendarUFOContext>(defaultUFOContext);

export const useCalendarUFOExperienceContext = () => useContext(CalendarUFOContext);

type Props = Partial<CalendarUFOContext> & {
	children: ReactNode;
};

export const CalendarUFOContextProvider = memo(({ children, ...props }: Props) => {
	const context = useMemo(() => ({ ...defaultUFOContext, ...props }), [props]);
	return <CalendarUFOContext.Provider value={context}>{children}</CalendarUFOContext.Provider>;
});
