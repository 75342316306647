import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import { JIRA_SOFTWARE } from '@atlassian/jira-shared-types/src/application.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';

export const EXPERIENCE_NAME = 'editRelease';

export const useEditReleaseExperienceStart = (analyticsSource: string) => {
	const appEditions = useAppEditions();
	return useExperienceStart({
		experience: EXPERIENCE_NAME,
		experienceId: `${Date.now()}`,
		analyticsSource,
		application: JIRA_SOFTWARE,
		edition: appEditions.software,
	});
};

export const useEditReleaseExperienceFail = () =>
	useExperienceFail({
		experience: EXPERIENCE_NAME,
	});

export const useEditReleaseExperienceSuccess = () =>
	useExperienceSuccess({
		experience: EXPERIENCE_NAME,
	});
