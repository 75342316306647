/**
 * @generated SignedSource<<4712737b6b10df77f23fbf16812cd289>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type calendarTopControlsBar_calendar$data = {
  readonly jira: {
    readonly " $fragmentSpreads": FragmentRefs<"filters_calendar_CalendarFilters" | "moreMenu_calendar">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"calendarUnscheduledPanelToggle_calendar" | "viewSettings_calendar_CalendarViewSettings">;
  readonly " $fragmentType": "calendarTopControlsBar_calendar";
};
export type calendarTopControlsBar_calendar$key = {
  readonly " $data"?: calendarTopControlsBar_calendar$data;
  readonly " $fragmentSpreads": FragmentRefs<"calendarTopControlsBar_calendar">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "configurationInput",
  "variableName": "configurationInput"
},
v1 = {
  "kind": "Variable",
  "name": "scopeInput",
  "variableName": "scopeInput"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "configurationInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "issuesPageCursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "issuesPageSize"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "issuesSearchInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipPanelIssueSearch"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipSprintSearch"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipVersionsV1Search"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipVersionsV2Search"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sprintsSearchInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "unscheduledIssuesSearchInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "versionsSearchInput"
    }
  ],
  "kind": "Fragment",
  "name": "calendarTopControlsBar_calendar",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "cloudId",
              "variableName": "cloudId"
            },
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "kind": "FragmentSpread",
          "name": "filters_calendar_CalendarFilters"
        },
        {
          "args": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "kind": "FragmentSpread",
          "name": "moreMenu_calendar"
        }
      ]
    },
    {
      "args": [
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "issuesPageCursor",
          "variableName": "issuesPageCursor"
        },
        {
          "kind": "Variable",
          "name": "issuesPageSize",
          "variableName": "issuesPageSize"
        },
        {
          "kind": "Variable",
          "name": "issuesSearchInput",
          "variableName": "issuesSearchInput"
        },
        (v1/*: any*/),
        {
          "kind": "Variable",
          "name": "skipSprintSearch",
          "variableName": "skipSprintSearch"
        },
        {
          "kind": "Variable",
          "name": "skipVersionsV1Search",
          "variableName": "skipVersionsV1Search"
        },
        {
          "kind": "Variable",
          "name": "skipVersionsV2Search",
          "variableName": "skipVersionsV2Search"
        },
        {
          "kind": "Variable",
          "name": "sprintsSearchInput",
          "variableName": "sprintsSearchInput"
        },
        {
          "kind": "Variable",
          "name": "versionsSearchInput",
          "variableName": "versionsSearchInput"
        }
      ],
      "kind": "FragmentSpread",
      "name": "viewSettings_calendar_CalendarViewSettings"
    },
    {
      "condition": "skipPanelIssueSearch",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "args": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "kind": "Variable",
              "name": "unscheduledIssuesSearchInput",
              "variableName": "unscheduledIssuesSearchInput"
            }
          ],
          "kind": "FragmentSpread",
          "name": "calendarUnscheduledPanelToggle_calendar"
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "1965e734a6b24c5e40568b41d43f08aa";

export default node;
