/**
 * @generated SignedSource<<61420ab83c98305e95bbc64b4fcc8536>>
 * @relayHash 2ead7c84ed23f257dbe236fbbbc61f8d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 46184df646e390f3f1a29b11aaa54c21d019a271a8395d3249d5348736bbdfdd

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraCalendarMode = "DAY" | "MONTH" | "WEEK" | "%future added value";
export type JiraCalendarWeekStart = "MONDAY" | "SATURDAY" | "SUNDAY" | "%future added value";
export type JiraCalendarViewConfigurationInput = {
  date?: AGG$DateTime | null | undefined;
  endDateField?: string | null | undefined;
  mode?: JiraCalendarMode | null | undefined;
  startDateField?: string | null | undefined;
  viewId?: string | null | undefined;
  weekStart?: JiraCalendarWeekStart | null | undefined;
};
export type JiraViewScopeInput = {
  ids?: ReadonlyArray<string> | null | undefined;
  projectKeys?: JiraProjectKeysInput | null | undefined;
};
export type JiraProjectKeysInput = {
  cloudId: string;
  keys?: ReadonlyArray<string> | null | undefined;
};
export type JiraCalendarIssuesInput = {
  additionalFilterQuery?: string | null | undefined;
};
export type calendarUnscheduledPanelCardList_calendar_RefetchPaginated_Query$variables = {
  configurationInput: JiraCalendarViewConfigurationInput;
  cursor?: string | null | undefined;
  first?: number | null | undefined;
  schedulePermissionsEnabled: boolean;
  scopeInput: JiraViewScopeInput;
  unscheduledIssuesSearchInput?: JiraCalendarIssuesInput | null | undefined;
  viewId?: string | null | undefined;
};
export type calendarUnscheduledPanelCardList_calendar_RefetchPaginated_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"calendarUnscheduledPanelCardList_calendar_CalendarUnscheduledPanelCardListInner_queryRef">;
};
export type calendarUnscheduledPanelCardList_calendar_RefetchPaginated_Query = {
  response: calendarUnscheduledPanelCardList_calendar_RefetchPaginated_Query$data;
  variables: calendarUnscheduledPanelCardList_calendar_RefetchPaginated_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "configurationInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "schedulePermissionsEnabled"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scopeInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "unscheduledIssuesSearchInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "viewId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "viewId",
  "variableName": "viewId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "unscheduledIssuesSearchInput"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v6 = {
  "kind": "ScalarField",
  "name": "name"
},
v7 = [
  (v2/*: any*/)
],
v8 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "date"
    }
  ],
  "type": "JiraDatePickerField"
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "dateTime"
    }
  ],
  "type": "JiraDateTimePickerField"
},
v10 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v11 = [
  (v6/*: any*/),
  {
    "concreteType": "JiraAvatar",
    "kind": "LinkedField",
    "name": "avatar",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "small"
      }
    ]
  },
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "calendarUnscheduledPanelCardList_calendar_RefetchPaginated_Query",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "configurationInput",
            "variableName": "configurationInput"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "schedulePermissionsEnabled",
            "variableName": "schedulePermissionsEnabled"
          },
          {
            "kind": "Variable",
            "name": "scopeInput",
            "variableName": "scopeInput"
          },
          {
            "kind": "Variable",
            "name": "unscheduledIssuesSearchInput",
            "variableName": "unscheduledIssuesSearchInput"
          },
          (v2/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "calendarUnscheduledPanelCardList_calendar_CalendarUnscheduledPanelCardListInner_queryRef"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "calendarUnscheduledPanelCardList_calendar_RefetchPaginated_Query",
    "selections": [
      {
        "alias": "panelJira",
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "configuration",
                "variableName": "configurationInput"
              },
              {
                "kind": "Variable",
                "name": "scope",
                "variableName": "scopeInput"
              }
            ],
            "concreteType": "JiraCalendar",
            "kind": "LinkedField",
            "name": "jiraCalendar",
            "plural": false,
            "selections": [
              {
                "args": (v3/*: any*/),
                "concreteType": "JiraIssueConnection",
                "kind": "LinkedField",
                "name": "unscheduledIssues",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "key"
                          },
                          {
                            "concreteType": "JiraSingleLineTextField",
                            "kind": "LinkedField",
                            "name": "summaryField",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "text"
                              },
                              (v4/*: any*/)
                            ]
                          },
                          {
                            "concreteType": "JiraSingleSelectUserPickerField",
                            "kind": "LinkedField",
                            "name": "assigneeField",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "picture"
                                  },
                                  (v4/*: any*/)
                                ]
                              },
                              (v4/*: any*/)
                            ]
                          },
                          {
                            "concreteType": "JiraPriorityField",
                            "kind": "LinkedField",
                            "name": "priorityField",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "concreteType": "JiraPriority",
                                "kind": "LinkedField",
                                "name": "priority",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "iconUrl"
                                  },
                                  (v4/*: any*/)
                                ]
                              },
                              (v4/*: any*/)
                            ]
                          },
                          {
                            "concreteType": "JiraStatusField",
                            "kind": "LinkedField",
                            "name": "statusField",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraStatus",
                                "kind": "LinkedField",
                                "name": "status",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "concreteType": "JiraStatusCategory",
                                    "kind": "LinkedField",
                                    "name": "statusCategory",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "statusCategoryId"
                                      },
                                      (v4/*: any*/)
                                    ]
                                  },
                                  (v4/*: any*/)
                                ]
                              },
                              (v4/*: any*/)
                            ]
                          },
                          {
                            "args": (v7/*: any*/),
                            "kind": "LinkedField",
                            "name": "startDateViewField",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v4/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/)
                            ]
                          },
                          {
                            "concreteType": "JiraIssueTypeField",
                            "kind": "LinkedField",
                            "name": "issueTypeField",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueType",
                                "kind": "LinkedField",
                                "name": "issueType",
                                "plural": false,
                                "selections": (v11/*: any*/)
                              },
                              (v4/*: any*/)
                            ]
                          },
                          {
                            "alias": "requestTypeField",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "idOrAlias",
                                "value": "requesttype"
                              },
                              {
                                "kind": "Literal",
                                "name": "ignoreMissingField",
                                "value": true
                              }
                            ],
                            "kind": "LinkedField",
                            "name": "fieldByIdOrAlias",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v4/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "concreteType": "JiraServiceManagementRequestType",
                                    "kind": "LinkedField",
                                    "name": "requestType",
                                    "plural": false,
                                    "selections": (v11/*: any*/)
                                  }
                                ],
                                "type": "JiraServiceManagementRequestTypeField"
                              }
                            ],
                            "storageKey": "fieldByIdOrAlias(idOrAlias:\"requesttype\",ignoreMissingField:true)"
                          },
                          {
                            "args": (v7/*: any*/),
                            "kind": "LinkedField",
                            "name": "endDateViewField",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v4/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/)
                            ]
                          },
                          (v5/*: any*/),
                          {
                            "condition": "schedulePermissionsEnabled",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              {
                                "alias": "canSchedule",
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "permission",
                                    "value": "SCHEDULE_ISSUES"
                                  }
                                ],
                                "kind": "ScalarField",
                                "name": "hasProjectPermission",
                                "storageKey": "hasProjectPermission(permission:\"SCHEDULE_ISSUES\")"
                              }
                            ]
                          },
                          (v10/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      }
                    ]
                  },
                  {
                    "concreteType": "QueryError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "identifier"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "message"
                      },
                      {
                        "kind": "LinkedField",
                        "name": "extensions",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "statusCode"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "errorType"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      }
                    ]
                  },
                  (v10/*: any*/)
                ]
              },
              {
                "args": (v3/*: any*/),
                "filters": [
                  "input"
                ],
                "handle": "connection",
                "key": "calendarUnscheduledPanelCardList_calendar__unscheduledIssues",
                "kind": "LinkedHandle",
                "name": "unscheduledIssues"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "46184df646e390f3f1a29b11aaa54c21d019a271a8395d3249d5348736bbdfdd",
    "metadata": {},
    "name": "calendarUnscheduledPanelCardList_calendar_RefetchPaginated_Query",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "3d8a78fb843c11e3eb20fe514b8bec1e";

export default node;
