import noop from 'lodash/noop';
import memoizeOne from 'memoize-one';
import type { APIState } from '../../../model/api/index.tsx';
import {
	type ColumnId,
	type Column,
	type ColumnTree,
	DYNAMIC_RESIZE,
} from '../../../model/columns/index.tsx';
import type { Optional } from '../../../model/optional/index.tsx';
import type { RowId, RowTree } from '../../../model/rows/index.tsx';
import { TABLE } from '../../../model/table/index.tsx';
import type { TableProps, TableColumn } from '../types.tsx';
import LoadingRow from '../view/loading-row/index.tsx';

const getValueOrFallback = <T,>(value: Optional<T>, fallback: T): T =>
	value === undefined ? fallback : value;

const traverseColumns = (
	columns: TableColumn[],
	columnMap: Record<ColumnId, Column>,
	parentChildrenIds: ColumnId[],
) => {
	columns.forEach((column: TableColumn) => {
		let childrenIds;

		// eslint-disable-next-line no-param-reassign
		columnMap[column.id] = {
			id: column.id,
			title: column.title,
			HeaderComponent: column.HeaderComponent,
			tooltip: column.tooltip,
			defaultWidth: column.minWidth,
			minWidth: column.minWidth,
			maxWidth: column.maxWidth,
			// FSN-4987 Remove resize strategy related logic during cleanup
			resizeStrategy: DYNAMIC_RESIZE,
			isHideable: false,
			isDraggable: false,
			isMarkup: false,
			isDefaultAdd: false,
			color: undefined,
			childrenIds,
			parentId: undefined,
			canBeMultiLine: getValueOrFallback(column.canBeMultiLine, false),
			CellComponent: column.CellComponent,
		};
		parentChildrenIds.push(column.id);
	});
};

const transformColumns = memoizeOne((columns: TableColumn[]): ColumnTree => {
	const columnTree: ColumnTree = {
		columns: {},
		rootCoreIds: [],
		rootAdditionalIds: [],
	};

	traverseColumns(columns, columnTree.columns, columnTree.rootAdditionalIds);

	return columnTree;
});

const transformRows = memoizeOne((rowIds: RowId[]): RowTree => {
	const rowTree: RowTree = {
		rootIds: [],
		rows: {},
	};

	rowIds.forEach((id) => {
		if (!rowTree.rows[id]) {
			rowTree.rows[id] = {
				id,
				parentId: undefined,
				childrenIds: [],
			};
		}

		// we've reached the root, add ourselves to rootIds
		rowTree.rootIds.push(id);
	});

	return rowTree;
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: TableProps): APIState => ({
	// Table general
	facade: TABLE,
	maxDepth: 0,
	DetailsPanelComponent: undefined,
	NonTemporaryCellWrapper: props.NonTemporaryCellWrapper,
	autoHeight: false,
	showDecalAtBottom: props.showDecalAtBottom,
	navigationMode: props.navigationMode,
	addLinkCaption: undefined,
	addedRows: undefined,
	isContentStale: props.isContentStale,
	shouldSkipVirtualization: props.shouldSkipVirtualization,
	shouldHideLoadingIndicator: props.shouldHideLoadingIndicator,
	shouldRerenderRowOnHover: props.shouldRerenderRowOnHover,
	contentKey: props.contentKey,
	ariaLabel: props.ariaLabel,

	// Rows
	rowTree: transformRows(props.rowIds),
	rowsConfiguration: props.rowsConfiguration,
	rowHeight: props.defaultRowHeight,
	autoRowHeight: props.autoRowHeight,
	draggableRows: props.draggableRows,
	defaultComparator: undefined,
	expandedRowIds: [],
	// @ts-expect-error - TS2322 - Type 'ComponentClass<Omit<DispatchProp<any>, "dispatch">> & { WrappedComponent: Component<DispatchProp<any>>; }' is not assignable to type 'Optional<LoadingRowComponent>'.
	LoadingRowComponent: LoadingRow,
	disableRowHighlight: props.disableRowHighlight,

	// Columns
	columnTree: transformColumns(props.columns),
	expandedColumnIds: props.expandedColumnIds,
	columnWidths: props.columnWidths,
	hiddenColumnIds: [],
	minAdditionalColumnsWidth: 0,
	defaultSortedColumn: undefined,
	columnComparators: {},
	sortedColumnIds: undefined,
	draggableColumns: false,
	premiumSLAColumnIds: props.premiumSLAColumnIds,

	callbacks: {
		onActiveRowIdChanged: props.onActiveRowIdChanged,
		onActiveItemChanged: props.onActiveItemChanged,
		onDisplayedRowsChanged: props.onDisplayedRowsChanged,
		onVerticalScrollOffsetChanged: props.onVerticalScrollOffsetChanged,
		onHorizontalScrollOffsetChanged: props.onHorizontalScrollOffsetChanged,
		onRowListFocusFunctionChanged: props.onRowListFocusFunctionChanged,
		onReactPortalContainerRefChanged: props.onReactPortalContainerRefChanged,
		onRowExpansionChangeRequested: noop,
		onColumnExpansionChangeRequested: noop,
		onColumnMoveRequested: undefined,
		onChangeHiddenColumnsRequested: undefined,
		onRowAddRequested: undefined,
		onClearAddedRowsRequested: noop,
		onPersistAddedRowRequested: noop,
		onCancelAddedRowRequested: noop,
		onSortedColumnChanged: noop,
		onSortedRowIdsChanged: noop,
	},
});
