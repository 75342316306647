import React, { useMemo, useEffect, useCallback, useRef, useState } from 'react';
import { styled, css } from '@compiled/react';
import noop from 'lodash/noop';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import addDays from 'date-fns/addDays';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import { graphql, useFragment, useRefetchableFragment } from 'react-relay';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import { N0, N20A, N300, N800, N400, N50A, N60A } from '@atlaskit/theme/colors';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import {
	type Event,
	type DateSelectArg,
	type SlotLaneContentArg,
	type DateSpanApi,
	useCalendar,
	PLACEHOLDER_EVENT_ID,
} from '@atlassian/calendar';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPE_MAP } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/constants.tsx';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import {
	DUE_DATE_TYPE,
	START_DATE_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	FireScreenAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useRectObserver } from '@atlassian/jira-react-use-resize-observer/src/index.tsx';
import type { calendarRenderer_calendar_EventRenderer_queryRef$key } from '@atlassian/jira-relay/src/__generated__/calendarRenderer_calendar_EventRenderer_queryRef.graphql';
import type {
	calendarRenderer_jira$data,
	calendarRenderer_jira$key,
} from '@atlassian/jira-relay/src/__generated__/calendarRenderer_jira.graphql';
import type { calendarRenderer_jira_refetchQuery } from '@atlassian/jira-relay/src/__generated__/calendarRenderer_jira_refetchQuery.graphql';
import type {
	calendarRendererIssues_jira$key,
	calendarRendererIssues_jira$data,
} from '@atlassian/jira-relay/src/__generated__/calendarRendererIssues_jira.graphql';
import type { calendarRendererIssues_jira_refetchQuery } from '@atlassian/jira-relay/src/__generated__/calendarRendererIssues_jira_refetchQuery.graphql';
import type {
	calendarRendererVersions_jira$key,
	calendarRendererVersions_jira$data,
} from '@atlassian/jira-relay/src/__generated__/calendarRendererVersions_jira.graphql';
import type { calendarRendererVersions_jira_refetchQuery } from '@atlassian/jira-relay/src/__generated__/calendarRendererVersions_jira_refetchQuery.graphql';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import {
	ANALYTICS_ACTION_ISSUE_SUBJECT,
	ANALYTICS_ACTION_SCHEDULED,
	ANALYTICS_SOURCE,
	CALENDAR_SOURCE,
	DEFAULT_MAX_ISSUES_PAGE_SIZE,
	UI_EVENT_RESIZED,
	CALENDAR_FIELD_CONFIG_TYPE_DATETIME_FIELD,
	WEEK_VIEW_DEFAULT_MAX_DAY_EVENTS,
	DEFAULT_MAX_ISSUES_PAGE_SIZE_OLD,
} from '../../common/constants.tsx';
import { useCalendarCapabilities } from '../../common/controllers/capabilities-provider/index.tsx';
import { useUserTimezone } from '../../common/controllers/use-user-timezone/index.tsx';
import { useScheduleErrorFlag } from '../../common/ui/error-flags/index.tsx';
import { swapTime, toLocalDate, toLocalDateTime } from '../../common/utils/dates/index.tsx';
import {
	useCalendarActions,
	useDateRange,
	useDraggingEvent,
	useShowReleases,
	useShowSprints,
	useShowIssues,
	useShowWeekends,
	useViewRange,
	useWeekStartsOn,
	useDayStartsAt,
	useShowIn24HourClock,
} from '../../controllers/calendar-store/index.tsx';
import type { CalendarViewRange } from '../../controllers/calendar-store/types.tsx';
import { useConnectionActions } from '../../controllers/connection-store/index.tsx';
import { useCalendarConfigurationInput } from '../../controllers/use-calendar-configuration-input/index.tsx';
import { useCalendarScopeInput } from '../../controllers/use-calendar-scope-input/index.tsx';
import { useCalendarViewId } from '../../controllers/use-calendar-view-id/index.tsx';
import { useScheduleCalendarIssueMutation } from '../../controllers/use-schedule-calendar-issue-mutation/index.tsx';
import { useUpdateSprintDateMutation } from '../../controllers/use-update-sprint-date-mutation/index.tsx';
import { useUpdateVersionDateMutation } from '../../controllers/use-update-version-date-mutation/index.tsx';
import { SubscribeToIssueUpdates } from '../../services/subscribe-to-issue-updates/index.tsx';
import { CrossProjectVersionEventRenderer } from './event-renderer/cross-project-version-renderer/index.tsx';
import { IssueEventRenderer } from './event-renderer/issue-renderer/index.tsx';
import { PlaceholderEventRenderer } from './event-renderer/placeholder-renderer/index.tsx';
import { SprintEventRenderer } from './event-renderer/sprint-renderer/index.tsx';
import { VersionEventRenderer } from './event-renderer/version-renderer/index.tsx';
import { IssuesPaginator } from './issues-paginator/index.tsx';
import { messages } from './messages.tsx';
import { OpenIssueViewOnLoad } from './open-issue-view-on-load/index.tsx';
import { RefetchApiRef } from './refetch-api-ref/index.tsx';
import { SlotLaneContent } from './slot-lane-content/index.tsx';
import { TodayWrapper } from './today-wrapper/index.tsx';
import type {
	IssueCalendarEvent,
	IssueLike,
	JiraCalendarExtendedEvent,
	VersionCalendarEvent,
	VersionLike,
	JiraCalendarEventExtension,
	SprintCalendarEvent,
	SprintLike,
	IssueEdge,
	VersionEdge,
	SprintEdge,
	CrossProjectVersionEdge,
	CrossProjectVersionLike,
	CrossProjectVersionCalendarEvent,
	ProjectsPermissionEdge,
	VersionLikeOld,
	VersionEdgeOld,
} from './types.tsx';
import { useCalendarDropTarget } from './use-calendar-drop-target/index.tsx';
import { useRefetchCalendarEventsOnInputsChanges } from './use-refetch-calendar-on-change/index.tsx';
import { useRefetchCalendarIssuesOnInputsChanges } from './use-refetch-issues-on-changes/index.tsx';
import { useRefetchOnGlobalIssueCreate } from './use-refetch-on-global-issue-create/index.tsx';
import { useRefetchCalendarVersionsOnInputsChanges } from './use-refetch-versions-on-changes/index.tsx';
import {
	isIssueCalendarEvent,
	isVersionCalendarEvent,
	getFirstDay,
	isSprintCalendarEvent,
	hasConnectionPages,
	getTotalPerPage,
	isAllDayEvent,
	eventStartAndEndDates,
	calendarEventOrderSort,
	getCalendarAspectRatio,
	getScrollTime,
	DEFAULT_EVENT_ORDER,
} from './utils.tsx';

/**
 * VISIBLE FOR TESTING
 * Transform an issue connection node into a calendar event for rendering.
 */
export function transformIssueEvent(
	issue: IssueLike,
	connectionRecordId: string | undefined,
	canSchedule: boolean,
	userTimeZone: string,
	viewRange: CalendarViewRange,
	weekViewEnabled: boolean,
	isDateTimeConfigured: boolean,
): IssueCalendarEvent | null {
	const hasScenarioStartDate = Boolean(issue.planScenarioValues?.startDateViewField);
	const hasScenarioEndDate = Boolean(issue.planScenarioValues?.endDateViewField);

	const startDate = hasScenarioStartDate
		? issue.planScenarioValues?.startDateViewField?.date
		: issue.startDateViewField?.date ?? issue.startDateViewField?.dateTime;

	const endDate = hasScenarioEndDate
		? issue.planScenarioValues?.endDateViewField?.date
		: issue.endDateViewField?.date ?? issue.endDateViewField?.dateTime;

	const summary = issue.planScenarioValues?.summaryField?.text ?? issue.summaryField?.text;

	const toLocalDateFn = weekViewEnabled && isDateTimeConfigured ? toLocalDateTime : toLocalDate;

	const startDateFormatted = startDate != null ? toLocalDateFn(startDate) : null;
	const endDateFormatted = endDate != null ? toLocalDateFn(endDate) : null;

	if (!endDateFormatted) return null;

	const startDateTime = startDateFormatted ?? endDateFormatted;
	const endDateTime = endDateFormatted;
	const allDay = isAllDayEvent(startDate, endDate, userTimeZone, viewRange);
	const { start, end } = eventStartAndEndDates(startDateTime, endDateTime, allDay);

	return {
		id: issue.id,
		allDay,
		title: summary ?? '',
		// we need to move to start of day otherwise there will be timezone issues
		start,
		// end is exclusive so we need to add a day
		end,
		resizable: canSchedule,
		extendedProps: {
			type: 'issue',
			id: issue.id,
			key: issue.key ?? '',
			item: issue,
			issueRef: issue,
			connectionRecordId,
		},
	};
}

/**
 * VISIBLE FOR TESTING
 * Transform an version connection node into a calendar event for rendering.
 */
export function transformVersionEvent(
	version: VersionLike | VersionLikeOld,
): VersionCalendarEvent | null {
	// Remove type cast when cleaning up 'plan-calendar-versions-v2-adoption' FG
	let releaseDate =
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		(version as VersionLikeOld).planScenarioValues?.releaseDate ?? version.releaseDate;
	if (fg('plan-calendar-versions-v2-adoption')) {
		// Use releseDateValue property if it exists even though the 'date' is null, it means the scenario value has been cleared
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		releaseDate = (version as VersionLike).planScenarioValues?.releaseDateValue
			? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(version as VersionLike).planScenarioValues?.releaseDateValue?.date
			: version.releaseDate;
	}

	const title = version.planScenarioValues?.name ?? version.name;

	if (!releaseDate) return null;

	const end = toLocalDate(releaseDate);
	// To only render the release date in the UI, set start to release date
	const startToRender = toLocalDate(releaseDate);

	return {
		id: version.id,
		allDay: true,
		title: title ?? '',
		start: startToRender,
		// end is exclusive so we need to add a day
		end: addDays(end, 1),
		// Instead of allowing resizing of this range through the UI, show a draggable release date that will update only the release date
		resizable: false,
		extendedProps: {
			type: 'version',
			id: version.id,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			item: version as VersionLike,
			versionRef: version,
		},
	};
}

/**
 * VISIBLE FOR TESTING
 * Transform an version connection node into a calendar event for rendering.
 */
export function transformCrossProjectVersionEvent(
	version: CrossProjectVersionLike,
): CrossProjectVersionCalendarEvent | null {
	const { releaseDate } = version;
	if (!releaseDate) return null;

	const title = version.crossProjectVersionScenarioValues?.name ?? version.name;
	const end = toLocalDate(releaseDate);
	// To only render the release date in the UI, set start to release date
	const startToRender = toLocalDate(releaseDate);

	return {
		id: version.id,
		allDay: true,
		title: title ?? '',
		start: startToRender,
		// end is exclusive so we need to add a day
		end: addDays(end, 1),
		// Instead of allowing resizing of this range through the UI, show a draggable release date that will update only the release date
		resizable: false,
		extendedProps: {
			type: 'cross-project-version',
			id: version.id,
			item: version,
			versionRef: version,
		},
	};
}

/**
 * VISIBLE FOR TESTING
 * Transform an sprint connection node into a calendar event for rendering.
 */
export function transformSprintEvent(
	sprint: SprintLike,
	userTimeZone: string,
	canSchedule: boolean,
	userTimeZoneForSprintEnabled: boolean,
): SprintCalendarEvent | null {
	const { startDate, endDate, completionDate } = sprint;
	if (!endDate && !completionDate) return null;

	const endDateTime = completionDate ?? endDate;
	if (!endDateTime) {
		return null;
	}

	let startEventDate;
	let endEventDate;

	if (userTimeZoneForSprintEnabled) {
		// Convert UTC start and end dates to local browser date
		const end = new Date(endDateTime);
		const start = startDate ? new Date(startDate) : end;

		// Convert local browser date to the user's timezone.
		// Note: This is the date user sees in the calendar and sprint dialog
		const startZonedTime = utcToZonedTime(start, userTimeZone);
		const endZonedTime = utcToZonedTime(end, userTimeZone);

		// Prepare FullCalendar event object (end date is exclusive).
		startEventDate = startOfDay(startZonedTime);
		endEventDate = addDays(startOfDay(endZonedTime), 1);
	} else {
		const localEndDate = toLocalDate(endDateTime);
		startEventDate = startDate ? toLocalDate(startDate) : localEndDate;
		endEventDate = addDays(localEndDate, 1);
	}

	return {
		id: sprint.id,
		allDay: true,
		title: sprint.name ?? '',
		start: startEventDate,
		end: endEventDate,
		resizable: canSchedule,
		extendedProps: {
			type: 'sprint',
			id: sprint.id,
			item: sprint,
			boardId: sprint.boardId,
			sprintRef: sprint,
		},
	};
}

/**
 * VISIBLE FOR TESTING
 * Transform relay date from `calendarRenderer_jira on JiraQuery` into a list of events.
 */
export function transformEvents(
	issuesData: calendarRendererIssues_jira$data | null,
	versionData: calendarRendererVersions_jira$data | null,
	data: calendarRenderer_jira$data | null,
	{
		showReleases,
		showSprints,
		showIssues,
		userTimeZone,
		canScheduleIssue,
		canScheduleSprint,
		viewRange,
		userTimeZoneForSprintEnabled,
		weekViewEnabled,
		isDateTimeConfigured,
	}: {
		showReleases: boolean;
		showSprints: boolean;
		showIssues: boolean;
		userTimeZone: string;
		canScheduleIssue: boolean;
		canScheduleSprint: boolean;
		viewRange: CalendarViewRange;
		userTimeZoneForSprintEnabled: boolean;
		weekViewEnabled: boolean;
		isDateTimeConfigured: boolean;
	},
): JiraCalendarExtendedEvent[] {
	const connectionRecordId = fg('jira_calendar_load_more_pagination')
		? issuesData?.jira?.jiraCalendar?.paginatedIssuesV2?.__id
		: issuesData?.jira?.jiraCalendar?.issuesV2?.__id;
	const issues = fg('jira_calendar_load_more_pagination')
		? issuesData?.jira?.jiraCalendar?.paginatedIssuesV2
		: issuesData?.jira?.jiraCalendar?.issuesV2;

	function mapIssueEvents(edge: IssueEdge | null | undefined): JiraCalendarExtendedEvent | null {
		if (!edge || !edge.node) return null;

		const { node: issue } = edge;

		return transformIssueEvent(
			issue,
			connectionRecordId,
			canScheduleIssue,
			userTimeZone,
			viewRange,
			weekViewEnabled,
			isDateTimeConfigured,
		);
	}

	const issueEvents = showIssues
		? issues?.edges?.map((edge: IssueEdge | null | undefined) => mapIssueEvents(edge)) ?? []
		: [];

	function mapVersionEdges(
		edge: VersionEdge | VersionEdgeOld | null | undefined,
	): JiraCalendarExtendedEvent | null {
		if (!edge || !edge.node) return null;

		const { node: version } = edge;
		return transformVersionEvent(version);
	}

	const versions = fg('plan-calendar-versions-v2-adoption')
		? versionData?.jira?.jiraCalendar?.versionsV2
		: versionData?.jira?.jiraCalendar?.versions;

	const versionEvents = showReleases ? versions?.edges?.map(mapVersionEdges) ?? [] : [];

	function mapCrossProjectVersionEdges(
		edge: CrossProjectVersionEdge | null | undefined,
	): JiraCalendarExtendedEvent | null {
		if (!edge || !edge.node) return null;

		const { node: crossProjectVersion } = edge;
		return transformCrossProjectVersionEvent(crossProjectVersion);
	}

	const crossProjectVersionEvents = showReleases
		? data?.jira?.jiraCalendar?.crossProjectVersions?.edges?.map(mapCrossProjectVersionEdges) ?? []
		: [];

	function mapSprintEdges(edge: SprintEdge | null | undefined): JiraCalendarExtendedEvent | null {
		if (!edge || !edge.node) return null;

		const { node: sprint } = edge;
		return transformSprintEvent(
			sprint,
			userTimeZone,
			canScheduleSprint,
			userTimeZoneForSprintEnabled,
		);
	}
	const sprintEvents = showSprints
		? data?.jira?.jiraCalendar?.sprints?.edges?.map(mapSprintEdges) ?? []
		: [];

	const allEvents = [
		...issueEvents,
		...versionEvents,
		...crossProjectVersionEvents,
		...sprintEvents,
	];

	return (
		allEvents.filter(
			(entry: JiraCalendarExtendedEvent | null): entry is JiraCalendarExtendedEvent => !!entry,
		) ?? []
	);
}

export function useCalendarEventsAnalyticsAttributes({
	issuesData,
	data,
	versionData,
}: {
	issuesData: calendarRendererIssues_jira$data | null;
	data: calendarRenderer_jira$data | null;
	versionData: calendarRendererVersions_jira$data | null;
}) {
	const issues = fg('jira_calendar_load_more_pagination')
		? issuesData?.jira?.jiraCalendar?.paginatedIssuesV2
		: issuesData?.jira?.jiraCalendar?.issuesV2;

	const isCapping = issues?.isCappingIssueSearchResult ?? false;
	const issuesPerPeriod = issues?.totalIssueSearchResultCount ?? issues?.edges?.length ?? 0;
	const numberOfIssuesPages = Math.ceil(
		issuesPerPeriod /
			(fg('jira_calendar_lazy_load')
				? DEFAULT_MAX_ISSUES_PAGE_SIZE
				: DEFAULT_MAX_ISSUES_PAGE_SIZE_OLD),
	);

	const versions = fg('plan-calendar-versions-v2-adoption')
		? versionData?.jira?.jiraCalendar?.versionsV2
		: versionData?.jira?.jiraCalendar?.versions;

	const additionalAttributes = useMemo(
		() => ({
			issues: {
				isCapping,
				totalPerPeriod: issuesPerPeriod,
				totalPerPage: getTotalPerPage(issues),
				totalPages: numberOfIssuesPages,
				hasPages: hasConnectionPages(issues),
			},
			versions: {
				totalPerPage: getTotalPerPage(versions),
				hasPages: hasConnectionPages(versions),
			},
			crossProjectVersions: {
				totalPerPage: getTotalPerPage(data?.jira?.jiraCalendar?.crossProjectVersions),
				hasPages: hasConnectionPages(data?.jira?.jiraCalendar?.crossProjectVersions),
			},
			sprints: {
				totalPerPage: getTotalPerPage(data?.jira?.jiraCalendar?.sprints),
				hasPages: hasConnectionPages(data?.jira?.jiraCalendar?.sprints),
			},
		}),
		[
			isCapping,
			issuesPerPeriod,
			numberOfIssuesPages,
			issues,
			versions,
			data?.jira?.jiraCalendar?.crossProjectVersions,
			data?.jira?.jiraCalendar?.sprints,
		],
	);

	return { additionalAttributes };
}

type IccContextType = {
	queryRef: calendarRenderer_calendar_EventRenderer_queryRef$key | null;
	connectionId?: string;
	onClose: (() => void) | null;
};

function EventRenderer({
	event,
	iccContext,
}: {
	event: Event<JiraCalendarEventExtension>;
	iccContext: IccContextType;
}) {
	const projectsData = useFragment(
		graphql`
			fragment calendarRenderer_calendar_EventRenderer_queryRef on Query
			@argumentDefinitions(
				scopeInput: { type: "JiraViewScopeInput!" }
				configurationInput: { type: "JiraCalendarViewConfigurationInput!" }
			) {
				...placeholderRenderer_calendar_PlaceholderEventRenderer
					@arguments(scopeInput: $scopeInput, configurationInput: $configurationInput)
			}
		`,
		iccContext.queryRef,
	);

	if (event.id === PLACEHOLDER_EVENT_ID) {
		return (
			<PlaceholderEventRenderer
				event={event}
				iccContext={{ ...iccContext, queryRef: projectsData }}
			/>
		);
	}

	const { extendedProps } = event;
	if (!extendedProps) return null;

	// We render a <Placeholder> around the event because if an optimistic update causes an event card to suspend we don't want the entire-app
	// to unmount.

	if (extendedProps.type === 'issue') {
		return (
			<Placeholder name="IssueEventRenderer" fallback={null}>
				<IssueEventRenderer
					issueRef={extendedProps.issueRef}
					connectionRecordId={extendedProps.connectionRecordId}
					{...(fg('jsd_shield_jsm_calendar_weekly_view') ? { isAllDayEvent: event.allDay } : {})}
				/>
			</Placeholder>
		);
	}

	if (extendedProps.type === 'version') {
		return (
			<Placeholder name="VersionEventRenderer" fallback={null}>
				<VersionEventRenderer versionRef={extendedProps.versionRef} />
			</Placeholder>
		);
	}

	if (extendedProps.type === 'cross-project-version') {
		return (
			<Placeholder name="CrossProjectVersionEventRenderer" fallback={null}>
				<CrossProjectVersionEventRenderer versionRef={extendedProps.versionRef} />
			</Placeholder>
		);
	}

	if (extendedProps.type === 'sprint') {
		return (
			<Placeholder name="SprintEventRenderer" fallback={null}>
				<SprintEventRenderer sprintRef={extendedProps.sprintRef} />
			</Placeholder>
		);
	}

	return null;
}

export interface CalendarRendererProps {
	issuesQueryRef?: calendarRendererIssues_jira$key | null;
	calendarQueryRef?: calendarRenderer_jira$key | null;
	versionsQueryRef?: calendarRendererVersions_jira$key | null;
}

/**
 * This is the point where we're rendering fullcalendar into the Jira calendar view.
 *
 * This view is getting events out of the main relay query, transforming them and configuring the calendar component.
 */
export function CalendarRenderer({
	issuesQueryRef,
	calendarQueryRef,
	versionsQueryRef,
}: CalendarRendererProps) {
	const { currentDate } = useDateRange();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const viewRange = fg('jsd_shield_jsm_calendar_weekly_view') ? useViewRange() : 'month';
	const { showErrorFlag } = fg('jsd_shield_jsm_calendar_weekly_view')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useScheduleErrorFlag()
		: { showErrorFlag: noop };

	const tableCellsBySlot = useRef(new Map<number, HTMLElement>());

	const [issuesData, issuesRefetch] = useRefetchableFragment<
		calendarRendererIssues_jira_refetchQuery,
		calendarRendererIssues_jira$key
	>(
		graphql`
			fragment calendarRendererIssues_jira on Query
			@refetchable(queryName: "calendarRendererIssues_jira_refetchQuery")
			@argumentDefinitions(
				scopeInput: { type: "JiraViewScopeInput!" }
				configurationInput: { type: "JiraCalendarViewConfigurationInput!" }
				issuesSearchInput: { type: "JiraCalendarIssuesInput!" }
				issuesPageSize: { type: "Int" }
				issuesPageCursor: { type: "String" }
				viewId: { type: "ID" }
				skipFieldConfig: { type: "Boolean", defaultValue: true }
				schedulePermissionsEnabled: { type: "Boolean!" }
				pageLoadMoreEnabled: { type: "Boolean!" }
			) {
				jira {
					jiraCalendar(scope: $scopeInput, configuration: $configurationInput)
						@optIn(to: "JiraCalendar") {
						paginatedIssuesV2: issuesV2(
							input: $issuesSearchInput
							first: $issuesPageSize
							after: $issuesPageCursor
						)
							@include(if: $pageLoadMoreEnabled)
							@connection(key: "calendarRendererIssues_paginatedIssuesV2")
							@optIn(to: "JiraPlansSupport") {
							__id
							totalIssueSearchResultCount
							isCappingIssueSearchResult
							pageInfo {
								hasNextPage
								hasPreviousPage
							}
							edges {
								node {
									__id
									id
									... on JiraIssue {
										key
										summaryField {
											text
										}

										canSchedule: hasProjectPermission(permission: SCHEDULE_ISSUES)
											@include(if: $schedulePermissionsEnabled)

										startDateViewField(viewId: $viewId) @optIn(to: "JiraPlansSupport") {
											__id
											id
											... on JiraDatePickerField {
												__typename
												date
											}
											... on JiraDateTimePickerField {
												__typename
												dateTime
											}
										}
										endDateViewField(viewId: $viewId) @optIn(to: "JiraPlansSupport") {
											__id
											id
											... on JiraDatePickerField {
												__typename
												date
											}
											... on JiraDateTimePickerField {
												__typename
												dateTime
											}
										}
										planScenarioValues(viewId: $viewId) {
											summaryField {
												text
											}
											startDateViewField {
												__id
												id
												... on JiraDatePickerField {
													__typename
													date
												}
											}
											endDateViewField {
												__id
												id
												... on JiraDatePickerField {
													__typename
													date
												}
											}
										}
									}
									... on JiraScenarioIssue {
										planScenarioValues(viewId: $viewId) {
											scenarioKey
											summaryField {
												text
											}
											startDateViewField {
												__id
												id
												... on JiraDatePickerField {
													__typename
													date
												}
											}
											endDateViewField {
												__id
												id
												... on JiraDatePickerField {
													__typename
													date
												}
											}
										}
									}

									...issueRenderer_calendar_IssueEventRenderer
										@arguments(
											viewId: $viewId
											schedulePermissionsEnabled: $schedulePermissionsEnabled
										)
								}
							}
							...issuesPaginator_calendar @arguments(pageSize: $issuesPageSize)
						}

						issuesV2(input: $issuesSearchInput, first: $issuesPageSize, after: $issuesPageCursor)
							@skip(if: $pageLoadMoreEnabled)
							@optIn(to: "JiraPlansSupport") {
							__id
							totalIssueSearchResultCount
							isCappingIssueSearchResult
							pageInfo {
								hasNextPage
								hasPreviousPage
							}
							edges {
								node {
									__id
									id
									... on JiraIssue {
										key
										summaryField {
											text
										}

										canSchedule: hasProjectPermission(permission: SCHEDULE_ISSUES)
											@include(if: $schedulePermissionsEnabled)

										startDateViewField(viewId: $viewId) @optIn(to: "JiraPlansSupport") {
											__id
											id
											... on JiraDatePickerField {
												__typename
												date
											}
											... on JiraDateTimePickerField {
												__typename
												dateTime
											}
										}
										endDateViewField(viewId: $viewId) @optIn(to: "JiraPlansSupport") {
											__id
											id
											... on JiraDatePickerField {
												__typename
												date
											}
											... on JiraDateTimePickerField {
												__typename
												dateTime
											}
										}
										planScenarioValues(viewId: $viewId) {
											summaryField {
												text
											}
											startDateViewField {
												__id
												id
												... on JiraDatePickerField {
													__typename
													date
												}
											}
											endDateViewField {
												__id
												id
												... on JiraDatePickerField {
													__typename
													date
												}
											}
										}
									}
									... on JiraScenarioIssue {
										planScenarioValues(viewId: $viewId) {
											scenarioKey
											summaryField {
												text
											}
											startDateViewField {
												__id
												id
												... on JiraDatePickerField {
													__typename
													date
												}
											}
											endDateViewField {
												__id
												id
												... on JiraDatePickerField {
													__typename
													date
												}
											}
										}
									}

									...issueRenderer_calendar_IssueEventRenderer
										@arguments(
											viewId: $viewId
											schedulePermissionsEnabled: $schedulePermissionsEnabled
										)
								}
							}
							...issuesPaginator_calendar @arguments(pageSize: $issuesPageSize)
						}
						startDateField @required(action: THROW) @skip(if: $skipFieldConfig) {
							type @required(action: THROW)
						}
					}
				}
			}
		`,
		issuesQueryRef ?? null,
	);

	const [versionData, versionRefetch] = useRefetchableFragment<
		calendarRendererVersions_jira_refetchQuery,
		calendarRendererVersions_jira$key
	>(
		graphql`
			fragment calendarRendererVersions_jira on Query
			@refetchable(queryName: "calendarRendererVersions_jira_refetchQuery")
			@argumentDefinitions(
				scopeInput: { type: "JiraViewScopeInput!" }
				configurationInput: { type: "JiraCalendarViewConfigurationInput!" }
				versionsSearchInput: { type: "JiraCalendarVersionsInput" }
				viewId: { type: "ID" }
				skipVersionsV1Search: { type: "Boolean!" }
				skipVersionsV2Search: { type: "Boolean!" }
			) {
				jira {
					jiraCalendar(scope: $scopeInput, configuration: $configurationInput)
						@optIn(to: "JiraCalendar") {
						versions(input: $versionsSearchInput) @skip(if: $skipVersionsV1Search) {
							pageInfo {
								hasNextPage
								hasPreviousPage
							}
							edges {
								node {
									id
									name
									startDate
									releaseDate
									planScenarioValues(viewId: $viewId) @optIn(to: "JiraPlansSupport") {
										name
										startDate
										releaseDate
									}
									...versionRenderer_calendar_VersionEventRenderer
										@arguments(
											viewId: $viewId
											skipVersionsV1Search: $skipVersionsV1Search
											skipVersionsV2Search: $skipVersionsV2Search
										)
								}
							}
						}
						versionsV2(input: $versionsSearchInput) @skip(if: $skipVersionsV2Search) {
							pageInfo {
								hasNextPage
								hasPreviousPage
							}
							edges {
								node {
									... on JiraVersion {
										name
										startDate
										releaseDate
									}
									id
									planScenarioValues(viewId: $viewId) @optIn(to: "JiraPlansSupport") {
										name
										releaseDateValue {
											date
										}
									}
									...versionRenderer_calendar_VersionEventRenderer
										@arguments(
											viewId: $viewId
											skipVersionsV1Search: $skipVersionsV1Search
											skipVersionsV2Search: $skipVersionsV2Search
										)
								}
							}
						}
					}
				}
			}
		`,
		versionsQueryRef ?? null,
	);

	const [data, refetch] = useRefetchableFragment<
		calendarRenderer_jira_refetchQuery,
		calendarRenderer_jira$key
	>(
		graphql`
			fragment calendarRenderer_jira on Query
			@refetchable(queryName: "calendarRenderer_jira_refetchQuery")
			@argumentDefinitions(
				scopeInput: { type: "JiraViewScopeInput!" }
				configurationInput: { type: "JiraCalendarViewConfigurationInput!" }
				sprintsSearchInput: { type: "JiraCalendarSprintsInput" }
				crossProjectVersionsSearchInput: { type: "JiraCalendarCrossProjectVersionsInput" }
				skipSprintSearch: { type: "Boolean!" }
				skipCrossProjectVersionSearch: { type: "Boolean!" }
			) {
				jira {
					jiraCalendar(scope: $scopeInput, configuration: $configurationInput)
						@optIn(to: "JiraCalendar") {
						projectsPermission: projects {
							edges {
								node {
									projectId
									action(type: CREATE_ISSUES) {
										canPerform
									}
								}
							}
						}
						crossProjectVersions(input: $crossProjectVersionsSearchInput)
							@optIn(to: "JiraPlansSupport")
							@skip(if: $skipCrossProjectVersionSearch) {
							pageInfo {
								hasNextPage
								hasPreviousPage
							}
							edges {
								node {
									id
									name
									startDate
									releaseDate
									crossProjectVersionScenarioValues {
										name
									}
									...crossProjectVersionRenderer_calendar_CrossProjectVersionEventRenderer
								}
							}
						}

						sprints(input: $sprintsSearchInput) @skip(if: $skipSprintSearch) {
							pageInfo {
								hasNextPage
								hasPreviousPage
							}
							edges {
								node {
									id
									sprintId
									boardId
									name
									goal
									state
									startDate
									endDate
									completionDate
									...sprintRenderer_calendar_SprintEventRenderer
								}
							}
						}
					}
				}

				...calendarRenderer_calendar_EventRenderer_queryRef
					@arguments(scopeInput: $scopeInput, configurationInput: $configurationInput)
			}
		`,
		calendarQueryRef ?? null,
	);

	useRefetchCalendarIssuesOnInputsChanges({
		refetch: issuesRefetch,
	});

	useRefetchCalendarVersionsOnInputsChanges({
		refetch: versionRefetch,
	});

	useRefetchCalendarEventsOnInputsChanges({
		refetch,
	});

	useRefetchOnGlobalIssueCreate({
		refetch: issuesRefetch,
	});
	const { setIssuesPageCursor, setIsDateTimeConfigured } = useCalendarActions();
	const { setCalendarRendererConnectionId } = useConnectionActions();
	const { userTimeZone } = useUserTimezone();
	const showReleases = useShowReleases();
	const showSprints = useShowSprints();
	const showIssues = useShowIssues();
	const showWeekends = useShowWeekends();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { locale, formatMessage } = useIntl();
	const {
		issue: { canCreate, canSchedule: canScheduleIssue },
		sprint: { canSchedule: canScheduleSprint },
		userTimeZoneForSprintEnabled,
		hasBusinessExperiment,
		weekViewEnabled,
	} = useCalendarCapabilities();

	const { scopeInput } = useCalendarScopeInput();
	const { configurationInput } = useCalendarConfigurationInput();
	const { viewId } = useCalendarViewId();

	const issues = fg('jira_calendar_load_more_pagination')
		? issuesData?.jira?.jiraCalendar?.paginatedIssuesV2
		: issuesData?.jira?.jiraCalendar?.issuesV2;

	useEffect(() => {
		if (!fg('calendar_update_on_issue_modal_schedule')) return;
		setCalendarRendererConnectionId(issues?.__id);
	}, [issues?.__id, setCalendarRendererConnectionId]);

	// Check permissions of the user in the current view.
	const getPermissions = useCallback((edge: ProjectsPermissionEdge | null | undefined) => {
		if (!edge || !edge.node) return false;

		const {
			node: { action },
		} = edge;

		return action?.canPerform;
	}, []);

	const hasCreatePermissions = hasBusinessExperiment
		? data?.jira?.jiraCalendar?.projectsPermission?.edges?.some(getPermissions)
		: true;

	const projectIds =
		data?.jira?.jiraCalendar?.projectsPermission?.edges
			?.map((e) => e?.node?.projectId)
			?.filter((p) => !!p)
			?.map<string>((e) => e ?? '') || [];

	const isDateTimeConfigured =
		fg('jsd_shield_jsm_calendar_weekly_view') &&
		issuesData?.jira?.jiraCalendar?.startDateField?.type?.toLowerCase() ===
			CALENDAR_FIELD_CONFIG_TYPE_DATETIME_FIELD;

	useEffect(() => {
		if (!fg('jsd_shield_jsm_calendar_weekly_view')) {
			return;
		}

		setIsDateTimeConfigured(isDateTimeConfigured);
	}, [isDateTimeConfigured, setIsDateTimeConfigured]);

	const events: JiraCalendarExtendedEvent[] = useMemo(
		() =>
			transformEvents(issuesData, versionData, data, {
				showReleases,
				showSprints,
				showIssues,
				userTimeZone,
				canScheduleIssue,
				canScheduleSprint,
				viewRange,
				userTimeZoneForSprintEnabled,
				weekViewEnabled,
				isDateTimeConfigured,
			}),
		[
			issuesData,
			versionData,
			data,
			showReleases,
			showSprints,
			showIssues,
			userTimeZone,
			canScheduleIssue,
			canScheduleSprint,
			viewRange,
			userTimeZoneForSprintEnabled,
			weekViewEnabled,
			isDateTimeConfigured,
		],
	);

	const connectionId = issues?.__id;

	const { scheduleCalendarIssue } = useScheduleCalendarIssueMutation({
		connectionId,
	});

	const { executeUpdateVersionDateMutation } = useUpdateVersionDateMutation();
	const { executeUpdateSprintDateMutation } = useUpdateSprintDateMutation();

	const { dayCellDidMount, dayCellWillUnmount } = useCalendarDropTarget({
		scheduleCalendarIssue,
		executeUpdateVersionDateMutation,
		executeUpdateSprintDateMutation,
	});

	const onIssuePaginationChange = useCallback(
		(cursor: string | null) => {
			setIssuesPageCursor(cursor);
		},
		[setIssuesPageCursor],
	);

	const onIssueEventResize = useCallback(
		(event: IssueCalendarEvent, nextEvent: IssueCalendarEvent, revert: () => void) => {
			const {
				extendedProps: { item: issue },
			} = nextEvent;

			const startDateFieldRecordId =
				issue.planScenarioValues?.startDateViewField?.__id ?? issue.startDateViewField?.__id;

			const endDateFieldRecordId =
				issue.planScenarioValues?.endDateViewField?.__id ?? issue.endDateViewField?.__id;

			const issueKey = issue.planScenarioValues?.scenarioKey ?? issue.key;

			const analyticsAttributes = [];

			if (
				event.start.getTime() !== nextEvent.start.getTime() &&
				fg('one_event_rules_them_all_fg')
			) {
				getUpdateAnalyticsFlowHelper().fireAnalyticsStart(START_DATE_TYPE, {
					analytics: createAnalyticsEvent({}),
					attributes: {
						fieldType: FIELD_TYPE_MAP[START_DATE_TYPE],
						isDragEditing: true,
					},
				});
				analyticsAttributes.push(START_DATE_TYPE);
			}

			if (event.end.getTime() !== nextEvent.end.getTime() && fg('one_event_rules_them_all_fg')) {
				getUpdateAnalyticsFlowHelper().fireAnalyticsStart(DUE_DATE_TYPE, {
					analytics: createAnalyticsEvent({}),
					attributes: {
						fieldType: FIELD_TYPE_MAP[DUE_DATE_TYPE],
						isDragEditing: true,
					},
				});
				analyticsAttributes.push(DUE_DATE_TYPE);
			}

			scheduleCalendarIssue({
				issueAri: issue.id,
				issueRecordId: issue.__id,
				startDateFieldRecordId,
				endDateFieldRecordId,
				startDate: nextEvent.start,
				// allow week view to resize to same day
				endDate:
					// For allDayEvent, fullcalendar is exclusive, so we need to patch the end date to be inclusive
					viewRange === 'week' && !nextEvent.allDay && fg('jsd_shield_jsm_calendar_weekly_view')
						? nextEvent.end
						: subDays(nextEvent.end, 1),
				issueKey: issueKey ?? '',
				canSchedule: issue.canSchedule,
				revert,
				analyticsAttributes,
			});

			fireUIAnalytics(
				createAnalyticsEvent({
					action: ANALYTICS_ACTION_SCHEDULED,
					actionSubject: ANALYTICS_ACTION_ISSUE_SUBJECT,
				}),
				ANALYTICS_SOURCE,
				{
					issueSource: CALENDAR_SOURCE,
					calendarEvent: UI_EVENT_RESIZED,
					isUnscheduled: false,
					viewRange,
				},
			);
		},
		[scheduleCalendarIssue, viewRange, createAnalyticsEvent],
	);

	const onVersionEventResize = useCallback(
		(event: VersionCalendarEvent, nextEvent: VersionCalendarEvent) => {
			const {
				extendedProps: { item: version },
			} = event;

			const startDate = nextEvent.start;
			const releaseDate = subDays(nextEvent.end, 1);

			if (version.name) {
				executeUpdateVersionDateMutation({
					versionId: version.id,
					versionName: version.name,
					startDate,
					releaseDate,
				});
			}
		},
		[executeUpdateVersionDateMutation],
	);

	const onSprintnEventResize = useCallback(
		(event: SprintCalendarEvent, nextEvent: SprintCalendarEvent) => {
			const {
				extendedProps: { item: sprint },
			} = event;

			if (sprint.state === 'CLOSED') {
				return;
			}

			// Calendar end date is exclusive, therefore subtract 1 day for monthly view.
			const calendarAdjustedEndDate = addDays(nextEvent.end, -1);

			// Convert sprint dates to the user's timezone is important when user timezone is different from local browser timezone.
			const startUserTimeZone = sprint.startDate
				? utcToZonedTime(new Date(sprint.startDate), userTimeZone)
				: null;
			const endUserTimeZone = sprint.endDate
				? utcToZonedTime(new Date(sprint.endDate), userTimeZone)
				: null;

			// Set new start date preserving sprint start original time.
			const newStartDate = startUserTimeZone
				? swapTime(nextEvent.start, startUserTimeZone)
				: nextEvent.start;

			// Set new end date preserving sprint end original time.
			const newEndDate = endUserTimeZone
				? swapTime(calendarAdjustedEndDate, endUserTimeZone)
				: calendarAdjustedEndDate;

			const startDateUtc = zonedTimeToUtc(newStartDate, userTimeZone);
			const endDateUtc = zonedTimeToUtc(newEndDate, userTimeZone);

			executeUpdateSprintDateMutation({
				id: sprint.id,
				sprintId: sprint.sprintId,
				name: sprint.name,
				goal: sprint.goal,
				state: sprint.state,
				startDate: startDateUtc,
				endDate: endDateUtc,
				completionDate: sprint.completionDate,
			});
		},
		[executeUpdateSprintDateMutation, userTimeZone],
	);

	const onEventResize = useCallback(
		(
			event: JiraCalendarExtendedEvent,
			nextEvent: JiraCalendarExtendedEvent,
			revert: () => void,
		) => {
			if (isIssueCalendarEvent(event) && isIssueCalendarEvent(nextEvent)) {
				onIssueEventResize(event, nextEvent, revert);
			}

			if (isVersionCalendarEvent(event) && isVersionCalendarEvent(nextEvent)) {
				onVersionEventResize(event, nextEvent);
			}

			if (isSprintCalendarEvent(event) && isSprintCalendarEvent(nextEvent)) {
				onSprintnEventResize(event, nextEvent);
			}
		},
		[onIssueEventResize, onVersionEventResize, onSprintnEventResize],
	);

	const [iccCloseHander, setIccCloseHandler] = useState<(() => void) | null>(null);

	const onEventAdd = useCallback(
		() =>
			// this event waits on the returned promise to resolve as a signal to
			// remove the placeholder event from the event list
			new Promise<void>((resolve) => {
				setIccCloseHandler(() => () => resolve());
			}),
		[],
	);

	const weekStartsOn = useWeekStartsOn();
	const dayStartsAt = useDayStartsAt();

	const EventContent = useMemo(
		() =>
			// eslint-disable-next-line react/no-unstable-nested-components
			({ event }: { event: Event<JiraCalendarEventExtension> }) => (
				<EventRenderer
					event={event}
					iccContext={{
						queryRef: data,
						connectionId,
						onClose: iccCloseHander,
					}}
				/>
			),
		[connectionId, data, iccCloseHander],
	);

	const slotLaneContent = useCallback(
		(args: SlotLaneContentArg) => (
			<SlotLaneContent
				slotLaneArgs={args}
				calendarMutations={{
					scheduleCalendarIssue,
				}}
				showWeekends={showWeekends}
				isDateTimeConfigured={isDateTimeConfigured}
				tableCellsBySlot={tableCellsBySlot}
			/>
		),
		[isDateTimeConfigured, scheduleCalendarIssue, showWeekends],
	);

	const selectAllow = useCallback(
		(selectInfo: DateSpanApi) => {
			if (!selectInfo.allDay && !isDateTimeConfigured) {
				showErrorFlag();
				return false;
			}

			return true;
		},
		[isDateTimeConfigured, showErrorFlag],
	);

	const containerRef = useRef<HTMLDivElement>(null);
	const containerRect = useRectObserver({ ref: containerRef });
	const showIn24HourClock = useShowIn24HourClock();
	// Use the default event order for weekly view
	const eventOrder =
		viewRange === 'week' && fg('jsd_shield_jsm_calendar_weekly_view')
			? DEFAULT_EVENT_ORDER
			: calendarEventOrderSort;

	const isVisualRefreshOverflowIssues = isVisualRefreshEnabled() && fg('visual-refresh_drop_5');

	const { calendar, setDate, setViewRange, scrollToTime } = useCalendar({
		events,
		initialDate: currentDate,
		initialView: 'grid',
		defaultEventBackgroundColor: 'transparent',
		initialViewRange: 'month',
		testId: 'calendar',
		...(fg('fix-wrong-timezone-prop-issue')
			? { timezone: userTimeZone }
			: {
					timeZone: userTimeZone,
				}),
		height: containerRect?.height ?? 'auto',
		contentHeight: containerRect?.height ? containerRect.height - 26.5 : 'auto',
		dayCellDidMount,
		dayCellWillUnmount,
		handleWindowResize: true,
		eventBorderColor: 'transparent',
		eventBackgroundColor: 'transparent',
		aspectRatio: getCalendarAspectRatio(containerRect),
		headerToolbar: false,
		editable: true,
		eventResizableFromStart: true,
		firstDay: getFirstDay({ weekStartsOn }),
		selectable: true,
		weekends: showWeekends,
		// Allow only WEEK_VIEW_DEFAULT_MAX_DAY_EVENTS no. of events to be displayed in a day cell in week view
		...(weekViewEnabled ? { dayMaxEvents: WEEK_VIEW_DEFAULT_MAX_DAY_EVENTS } : {}),
		scrollTime:
			weekViewEnabled && fg('jsd_shield_weekly_view_fast_follow')
				? getScrollTime(dayStartsAt)
				: undefined,
		EventContent,
		...(fg('calendar-fix-more-button') ? { eventOrder } : {}),
		...(weekViewEnabled ? { slotLaneContent } : {}),
		slotLabelFormat:
			weekViewEnabled && fg('jsd_shield_weekly_view_fast_follow')
				? {
						hour: 'numeric',
						hour12: !showIn24HourClock,
					}
				: undefined,
		...(fg('jira-shared-calendar-weekday-translations') ? { locale } : {}),
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		select: (_selectArg: DateSelectArg) => {},
		...(weekViewEnabled ? { selectAllow } : {}),
		enableSelectOnMonthView: true,
		onEventResize,
		onEventAdd: canCreate && data && hasCreatePermissions ? onEventAdd : undefined,
		todayWrapper: TodayWrapper,
		moreLinkText: (number) => formatMessage(messages.moreLinkText, { number }),
		moreLinkHint: (number) => formatMessage(messages.moreLinkHintText, { number }),
		...(fg('jira-calendar-open-keyboard-accessible-events')
			? { hasSimulateClickOnKeyDownWrapper: false }
			: {}),
		...(isVisualRefreshOverflowIssues
			? {
					dayPopoverFormat: {
						month: 'short',
						year: 'numeric',
						day: 'numeric',
					},
				}
			: {}),
	});

	useEffect(() => {
		setDate(currentDate);
	}, [currentDate, setDate]);

	useEffect(() => {
		setViewRange(viewRange);
	}, [viewRange, setViewRange]);

	useEffect(() => {
		if (viewRange === 'week' && fg('jsd_shield_weekly_view_fast_follow')) {
			/**
			 * scrollToTime() is called before the calendar fully renders, so setTimeout to 2 seconds ensures the DOM is ready.
			 * This is a workaround to scroll to the correct time on initial load of the weekly view in the calendar.
			 */
			setTimeout(() => {
				scrollToTime(getScrollTime(dayStartsAt));
			}, 2000);
		}
	}, [dayStartsAt, scrollToTime, viewRange]);

	const draggingEvent = useDraggingEvent();

	const { additionalAttributes } = useCalendarEventsAnalyticsAttributes({
		issuesData,
		data,
		versionData,
	});

	const hasIssuesPages = issues?.pageInfo?.hasNextPage;

	const isVisualRefresh = isVisualRefreshEnabled() && fg('visual-refresh_drop_4');

	return (
		<>
			{fg('jira-calendar-business-theme') ? (
				<FullCalendarContainer
					isDragging={draggingEvent != null}
					ref={containerRef}
					weekViewEnabled={fg('jsd_shield_jsm_calendar_weekly_view')}
					hasIssuesPages={hasIssuesPages}
					isVisualRefresh={isVisualRefresh}
					isVisualRefreshOverflowIssues={isVisualRefreshOverflowIssues}
					isColorContrastFix={fg('plan-calender-color-contrast-a11y-fix')}
					isDayHeightFix={fg('add_min_height_to_calendar_dates')}
					isMonthView={viewRange === 'month'}
				>
					{calendar}
					<RefetchApiRef
						issuesRefetch={issuesRefetch}
						refetch={refetch}
						versionRefetch={versionRefetch}
					/>
					<OpenIssueViewOnLoad />
				</FullCalendarContainer>
			) : (
				<FullCalendarContainerWithoutJwmTheming
					isDragging={draggingEvent != null}
					ref={containerRef}
					isVisualRefresh={isVisualRefresh}
					isVisualRefreshOverflowIssues={isVisualRefreshOverflowIssues}
					isColorContrastFix={fg('plan-calender-color-contrast-a11y-fix')}
					isDayHeightFix={fg('add_min_height_to_calendar_dates')}
					isMonthView={viewRange === 'month'}
				>
					{calendar}
					<RefetchApiRef
						issuesRefetch={issuesRefetch}
						refetch={refetch}
						versionRefetch={versionRefetch}
					/>
					<OpenIssueViewOnLoad />
				</FullCalendarContainerWithoutJwmTheming>
			)}
			{issues && <IssuesPaginator issues={issues} onChange={onIssuePaginationChange} />}
			{fg('calendar_issue_subscriptions') && (
				<SubscribeToIssueUpdates
					projectIds={projectIds}
					scope={scopeInput}
					configuration={configurationInput}
					viewId={viewId ?? undefined}
				/>
			)}
			<FireScreenAnalytics attributes={additionalAttributes} />
		</>
	);
}

const weekViewStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.fc-theme-standard th .fc-col-header-cell-cushion>div': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> div': {
			font: token('font.heading.xxsmall'),
			color: token('color.text.subtle', N800),
			fontWeight: token('font.weight.semibold', '600'),
			width: 'auto',
			height: 'auto',
			paddingLeft: token('space.050'),
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.fc-theme-standard th .fc-day-today .fc-col-header-cell-cushion>div': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> div': {
			color: token('color.text.brand'),
			background: 'none',
		},
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.fc .fc-timegrid': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'.fc-scrollgrid-section.fc-scrollgrid-section-header .fc-timegrid-axis': {
			zIndex: 3,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'.fc-scrollgrid-section.fc-scrollgrid-section-body:last-of-type > td::before': {
			top: token('space.negative.100', '-8px'),
			zIndex: 2,
			boxShadow: token('elevation.shadow.overflow'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'.fc-timegrid-col-events': {
			marginTop: '0px',
			marginRight: token('space.050', '4px'),
			marginBottom: '0px',
			marginLeft: token('space.050', '4px'),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-timegrid-event-harness': {
				borderWidth: '1px',
				borderStyle: 'solid',
				borderColor: 'transparent',
			},
		},

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'td.fc-timegrid-slot': {
			height: '1.438rem',
		},
	},

	// Margin to avatar in week view
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'a.fc-event div[role="img"] > span': {
		margin: 0,
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.fc .fc-daygrid-body-natural .fc-daygrid-day-events': {
		marginBottom: token('space.050'),
		minHeight: '4.5rem',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.fc-theme-standard td, .fc-theme-standard th': {
		borderRight: 0,
	},
});

const visualRefreshStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.fc-daygrid-day-top': {
		justifyContent: 'flex-start',
		position: 'relative',
		zIndex: 1,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'.fc .fc-daygrid-day-number': {
		paddingLeft: `${token('space.100', '8px')}`,
		paddingRight: `${token('space.100', '8px')}`,
		paddingTop: ` ${token('space.150', '12px')}`,
	},
});

const overflowIssuesVRStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.fc .fc-popover.fc-more-popover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'.fc-popover-header': {
			backgroundColor: token('elevation.surface.raised', '#fff'),
			paddingTop: token('space.100', '8px'),
			paddingLeft: token('space.100', '8px'),
			paddingRight: token('space.100', '8px'),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-popover-title': {
				margin: 'unset',
				textTransform: 'capitalize',

				font: token('font.body'),
				paddingLeft: token('space.050', '4px'),
			},
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.fc-popover-body': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'.fc-more-popover-misc': {
			display: 'none',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'.fc-daygrid-event-harness': {
			paddingBottom: token('space.025', '2px'),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FullCalendarContainer = styled.div<{
	isDragging: boolean;
	weekViewEnabled: boolean;
	hasIssuesPages?: boolean;
	isVisualRefresh?: boolean;
	isVisualRefreshOverflowIssues?: boolean;
	isColorContrastFix?: boolean;
	isDayHeightFix: boolean;
	isMonthView: boolean;
}>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ weekViewEnabled }) => weekViewEnabled && weekViewStyles,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ isVisualRefresh }) => isVisualRefresh && visualRefreshStyles,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ isVisualRefreshOverflowIssues }) => isVisualRefreshOverflowIssues && overflowIssuesVRStyles,
	{
		backgroundColor: token('elevation.surface', N0),
		'--fc-border-color': token('color.border', '#ebecf0'),
		borderWidth: '1px',
		borderStyle: 'solid',
		borderColor: `${token('color.border', '#ebecf0')}`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
		borderRadius: ({ hasIssuesPages, isVisualRefresh }) =>
			// eslint-disable-next-line no-nested-ternary
			hasIssuesPages
				? isVisualRefresh
					? `${token('space.075', '6px')} ${token('space.075', '6px')} 0px 0px`
					: '2px 2px 0px 0px'
				: isVisualRefresh
					? token('space.075', '6px')
					: token('border.radius.050', '2px'),
		overflowX: 'hidden',
		overflowY: 'auto',
		height: '100%',
		width: '100%',
		flex: 1,
		boxSizing: 'border-box',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'.fc .fc-daygrid-more-link': {
			textDecoration: 'none',
			font: token('font.body'),
			display: 'block',
			color: token('color.text'),
			borderRadius: token('border.radius.100'),
			'&:hover': {
				backgroundColor: token('color.background.neutral.subtle.hovered'),
			},
			paddingTop: token('space.025', '2px'),
			paddingRight: token('space.025', '2px'),
			paddingBottom: token('space.025', '2px'),
			paddingLeft: token('space.050', '4px'),
			width: '100%',
		},

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'.fc .fc-daygrid-day-bottom': {
			padding: 0,
			margin: 0,
			paddingLeft: token('space.050', '4px'),
			paddingRight: token('space.050', '4px'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&': {
			position: 'relative',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			tbody: {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
				backgroundColor: ({ weekViewEnabled }) =>
					weekViewEnabled ? 'none' : token('elevation.surface', N0),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
				'&:last-of-type': {
					borderRight: 0,
				},
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'thead, tbody, tfoot': {
				border: 0,
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-daygrid-day-events': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
				pointerEvents: ({ isDragging }) => (isDragging ? 'none' : 'unset'),
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-timegrid-slots': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
				zIndex: ({ isDragging, weekViewEnabled }) =>
					isDragging && weekViewEnabled ? 4 : 'inherit',
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-scroller-harness': {
				backgroundColor: token('elevation.surface', N0),
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-daygrid-day-top': {
				justifyContent: 'center',
				position: 'relative',
				zIndex: 1,
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc .fc-daygrid-day.fc-day-today': {
				background: 'none',
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-theme-standard .fc-scrollgrid': {
				border: 0,
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-theme-standard td, .fc-theme-standard th': {
				borderLeft: `1px solid ${token('color.border', '#ebecf0')}`,
				borderRight: `1px solid ${token('color.border', '#ebecf0')}`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
				'&:first-of-type': {
					borderLeft: 0,
				},
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
				'&:last-of-type': {
					borderRight: 0,
				},
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-theme-standard th': {
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
				backgroundColor: ({ isVisualRefresh, isColorContrastFix }) =>
					isVisualRefresh
						? token('elevation.surface.sunken')
						: token('color.background.accent.gray.subtlest', isColorContrastFix ? N20A : '#6C6F77'),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'.fc-scrollgrid-sync-inner': {
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
					height: ({ isVisualRefresh }) => (isVisualRefresh ? '40px' : '24px'),
				},
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'.fc-col-header-cell-cushion': {
					paddingBottom: 0,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
					' > div': {
						color: token('color.text.subtle', N800),
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						font: token('font.heading.xxsmall', fontFallback.heading.xxsmall),
						fontWeight: token('font.weight.bold', '700'),
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
						display: ({ weekViewEnabled }) => (weekViewEnabled ? 'flex' : 'block'),
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
						flexDirection: ({ weekViewEnabled }) => weekViewEnabled && 'row',
						textTransform: 'capitalize',
					},
				},
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'.fc-theme-standard tr:last-of-type > td': {
				borderBottom: 0,
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc .fc-daygrid .fc-scrollgrid-section.fc-scrollgrid-section-body > td': {
				borderTopLeftRadius: 0,
				borderTopRightRadius: 0,
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-daygrid-day-number': {
				color: token('color.text.subtle', N400),
				'&:hover': {
					textDecoration: 'none',
				},
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-daygrid-dot-event:hover': {
				background: 'none',
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-daygrid-day': {
				minHeight: '140px',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
				height: ({ isMonthView, isDayHeightFix }) =>
					isMonthView && isDayHeightFix ? '106px' : 'unset',
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-daygrid-more-link': {
				textDecoration: 'none',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				button: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					display: 'block',
				},
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-col-header-cell-cushion': {
				textDecoration: 'none',
				padding: 0,
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc .fc-highlight': {
				backgroundColor: token('color.blanket.selected', N20A),
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc .jira-calendar-view-cell-highlight': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				backgroundColor: `${token('color.blanket.selected', N20A)} !important`,
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'.fc-event-dragging:not(.fc-event-selected)': {
				cursor: 'grabbing',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'.fc-event-main': {
					backgroundColor: token('elevation.surface.overlay', N0),
					boxShadow: token('elevation.shadow.overlay', `0 4px 8px -2px ${N50A}, 0 0 1px ${N60A}`),
					borderRadius: '4px',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
					'& > div': {
						outline: 'none',
					},
				},
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc .fc-day-other .fc-daygrid-day-top': {
				opacity: 0.6,
				color: token('color.text.subtlest', N300),
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc .fc-popover.fc-more-popover': {
				zIndex: 100,
				width: '240px',
				border: 'none',
				borderRadius: '3px',
				boxShadow: token(
					'elevation.shadow.overlay',
					'0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31)',
				),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'.fc-popover-header': {
					backgroundColor: token('elevation.surface.raised', '#fff'),
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
					'.fc-popover-title': {
						margin: 'auto',
						textTransform: 'uppercase',
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						font: token('font.heading.xxsmall', fontFallback.heading.xxsmall),
					},
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
					'.fc-popover-close': {
						display: 'none',
					},
				},
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'.fc-popover-body': {
					paddingTop: token('space.050', '4px'),
					paddingRight: token('space.100', '8px'),
					paddingBottom: token('space.050', '4px'),
					paddingLeft: token('space.100', '8px'),
					backgroundColor: token('elevation.surface.raised', '#fff'),
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
					'.fc-more-popover-misc': {
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
						'> div': {
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
							font: token('font.heading.small', fontFallback.heading.small),
							marginTop: 0,
							marginRight: 'auto',
							marginBottom: token('space.050', '4px'),
							marginLeft: 'auto',
							cursor: 'unset',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						},
					},
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
					'.fc-daygrid-event-harness': {
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
						'.fc-daygrid-event.fc-event-start, .fc-daygrid-event.fc-event-end': {
							marginRight: 0,
							marginLeft: 0,
						},
					},
				},
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-direction-ltr .fc-daygrid-event': {
				marginTop: 0,
				marginBottom: token('space.025', '2px'),

				border: 'none',

				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'&.fc-event-start': {
					marginLeft: token('space.050', '4px'),
					'--fc-event-border-left-radius': token('border.radius', '2px'),
				},

				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'&.fc-event-end': {
					marginRight: token('space.050', '4px'),

					'--fc-event-border-right-radius': token('border.radius', '2px'),
				},
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-event-main > div': {
				outline: 'none',
			},
		},
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FullCalendarContainerWithoutJwmTheming = styled.div<{
	isDragging: boolean;
	isVisualRefresh?: boolean;
	isVisualRefreshOverflowIssues?: boolean;
	isColorContrastFix?: boolean;
	isDayHeightFix: boolean;
	isMonthView: boolean;
}>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ isVisualRefresh }) => isVisualRefresh && visualRefreshStyles,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ isVisualRefreshOverflowIssues }) => isVisualRefreshOverflowIssues && overflowIssuesVRStyles,
	{
		'--fc-border-color': token('color.border', '#ebecf0'),
		borderWidth: '1px',
		borderStyle: 'solid',
		borderColor: `${token('color.border', '#ebecf0')}`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
		borderRadius: ({ isVisualRefresh }) =>
			isVisualRefresh ? token('space.075', '6px') : token('border.radius.050', '2px'),
		overflowX: 'hidden',
		overflowY: 'auto',
		height: '100%',
		width: '100%',
		flex: 1,
		boxSizing: 'border-box',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'.fc .fc-daygrid-more-link': {
			textDecoration: 'none',
			font: token('font.body'),
			display: 'block',
			color: token('color.text'),
			borderRadius: token('border.radius.100'),
			'&:hover': {
				backgroundColor: token('color.background.neutral.subtle.hovered'),
			},
			paddingTop: token('space.025', '2px'),
			paddingRight: token('space.025', '2px'),
			paddingBottom: token('space.025', '2px'),
			paddingLeft: token('space.050', '4px'),
			width: '100%',
		},

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'.fc .fc-daygrid-day-bottom': {
			padding: 0,
			margin: 0,
			paddingLeft: token('space.050', '4px'),
			paddingRight: token('space.050', '4px'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&': {
			position: 'relative',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			tbody: {
				backgroundColor: token('elevation.surface', N0),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
				'&:last-of-type': {
					borderRight: 0,
				},
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'thead, tbody, tfoot': {
				border: 0,
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-daygrid-day-events': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
				pointerEvents: ({ isDragging }) => (isDragging ? 'none' : 'unset'),
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-scroller-harness': {
				backgroundColor: token('elevation.surface', N0),
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-daygrid-day-top': {
				justifyContent: 'center',
				position: 'relative',
				zIndex: 1,
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc .fc-daygrid-day.fc-day-today': {
				background: 'none',
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-theme-standard .fc-scrollgrid': {
				border: 0,
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-theme-standard td, .fc-theme-standard th': {
				borderLeft: `1px solid ${token('color.border', '#ebecf0')}`,
				borderRight: `1px solid ${token('color.border', '#ebecf0')}`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
				'&:first-of-type': {
					borderLeft: 0,
				},
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
				'&:last-of-type': {
					borderRight: 0,
				},
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-theme-standard th': {
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
				backgroundColor: ({ isVisualRefresh, isColorContrastFix }) =>
					isVisualRefresh
						? token('elevation.surface.sunken')
						: token('color.background.accent.gray.subtlest', isColorContrastFix ? N20A : '#6C6F77'),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'.fc-scrollgrid-sync-inner': {
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
					height: ({ isVisualRefresh }) => (isVisualRefresh ? '40px' : '24px'),
				},
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'.fc-col-header-cell-cushion': {
					paddingBottom: 0,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
					' > div': {
						color: token('color.text.subtle', N800),
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						font: token('font.heading.xxsmall', fontFallback.heading.xxsmall),
						fontWeight: token('font.weight.bold', '700'),
						textTransform: 'capitalize',
					},
				},
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'.fc-theme-standard tr:last-of-type > td': {
				borderBottom: 0,
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc .fc-daygrid .fc-scrollgrid-section.fc-scrollgrid-section-body > td': {
				borderTopLeftRadius: 0,
				borderTopRightRadius: 0,
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-daygrid-day-number': {
				color: token('color.text.subtle', N400),
				'&:hover': {
					textDecoration: 'none',
				},
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-daygrid-dot-event:hover': {
				background: 'none',
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-daygrid-day': {
				minHeight: '140px',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
				height: ({ isMonthView, isDayHeightFix }) =>
					isMonthView && isDayHeightFix ? '106px' : 'unset',
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-daygrid-more-link': {
				textDecoration: 'none',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				button: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					display: 'block',
				},
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-col-header-cell-cushion': {
				textDecoration: 'none',
				padding: 0,
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc .fc-highlight': {
				backgroundColor: token('color.blanket.selected', N20A),
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc .jira-calendar-view-cell-highlight': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				backgroundColor: `${token('color.blanket.selected', N20A)} !important`,
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'.fc-event-dragging:not(.fc-event-selected)': {
				cursor: 'grabbing',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'.fc-event-main': {
					backgroundColor: token('elevation.surface.overlay', N0),
					boxShadow: token('elevation.shadow.overlay', `0 4px 8px -2px ${N50A}, 0 0 1px ${N60A}`),
					borderRadius: '4px',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
					'& > div': {
						outline: 'none',
					},
				},
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc .fc-day-other .fc-daygrid-day-top': {
				opacity: 0.6,
				color: token('color.text.subtlest', N300),
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc .fc-popover.fc-more-popover': {
				zIndex: 100,
				width: '240px',
				border: 'none',
				borderRadius: '3px',
				boxShadow: token(
					'elevation.shadow.overlay',
					'0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31)',
				),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'.fc-popover-header': {
					backgroundColor: token('elevation.surface.raised', '#fff'),
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
					'.fc-popover-title': {
						margin: 'auto',
						textTransform: 'uppercase',
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						font: token('font.heading.xxsmall', fontFallback.heading.xxsmall),
					},
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
					'.fc-popover-close': {
						display: 'none',
					},
				},
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'.fc-popover-body': {
					paddingTop: token('space.050', '4px'),
					paddingRight: token('space.100', '8px'),
					paddingBottom: token('space.050', '4px'),
					paddingLeft: token('space.100', '8px'),
					backgroundColor: token('elevation.surface.raised', '#fff'),
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
					'.fc-more-popover-misc': {
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
						'> div': {
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
							font: token('font.heading.small', fontFallback.heading.small),
							marginTop: 0,
							marginRight: 'auto',
							marginBottom: token('space.050', '4px'),
							marginLeft: 'auto',
							cursor: 'unset',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						},
					},
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
					'.fc-daygrid-event-harness': {
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
						'.fc-daygrid-event.fc-event-start, .fc-daygrid-event.fc-event-end': {
							marginRight: 0,
							marginLeft: 0,
						},
					},
				},
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-direction-ltr .fc-daygrid-event': {
				marginTop: 0,
				marginBottom: token('space.025', '2px'),

				border: 'none',

				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'&.fc-event-start': {
					marginLeft: token('space.050', '4px'),
					'--fc-event-border-left-radius': token('border.radius', '4px'),
				},

				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'&.fc-event-end': {
					marginRight: token('space.050', '4px'),

					'--fc-event-border-right-radius': token('border.radius', '4px'),
				},
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.fc-event-main > div': {
				outline: 'none',
			},
		},
	},
);
