import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { Box } from '@atlaskit/primitives';
import type { defaultFieldValueLabel_softwareFiltersPopup$key } from '@atlassian/jira-relay/src/__generated__/defaultFieldValueLabel_softwareFiltersPopup.graphql';

export interface DefaultFieldValueLabelProps {
	fieldValueRef: defaultFieldValueLabel_softwareFiltersPopup$key;
	mode: 'dropdown' | 'popup';
}

export function DefaultFieldValueLabel({ fieldValueRef }: DefaultFieldValueLabelProps) {
	const data = useFragment(
		graphql`
			fragment defaultFieldValueLabel_softwareFiltersPopup on JiraJqlFieldValue {
				displayName
			}
		`,
		fieldValueRef,
	);

	return <Box>{data.displayName}</Box>;
}
