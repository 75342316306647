/**
 * @generated SignedSource<<d1ed51e2abb995daeb82dea63c7cee90>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type calendarCardStartDate_calendar$data = {
  readonly startDateViewField: {
    readonly __typename: "JiraDatePickerField";
    readonly date?: AGG$Date | null | undefined;
    readonly dateTime?: AGG$DateTime | null | undefined;
    readonly name: string;
  } | null | undefined;
  readonly " $fragmentType": "calendarCardStartDate_calendar";
};
export type calendarCardStartDate_calendar$key = {
  readonly " $data"?: calendarCardStartDate_calendar$data;
  readonly " $fragmentSpreads": FragmentRefs<"calendarCardStartDate_calendar">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "name": "calendarCardStartDate_calendar",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "viewId",
          "variableName": "viewId"
        }
      ],
      "kind": "LinkedField",
      "name": "startDateViewField",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "name"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "name": "date"
            }
          ],
          "type": "JiraDatePickerField"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "name": "dateTime"
            }
          ],
          "type": "JiraDateTimePickerField"
        }
      ]
    }
  ],
  "type": "JiraIssue"
};
})();

(node as any).hash = "2f57e46814d2c9f4b2ec644e713b33ff";

export default node;
