import React, { Component, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { Checkbox as CheckboxDI } from '@atlaskit/checkbox';
import { token } from '@atlaskit/tokens';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { Actions } from '@atlassian/jira-issue-table-selection-services/src/services/actions/types.tsx';
import type { SelectedIssuesSubscriberType } from '@atlassian/jira-issue-table-selection-services/src/services/index.tsx';
import type { State } from '@atlassian/jira-issue-table-selection-services/src/services/types.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages.tsx';

type Props = {
	allIssueKeys: IssueKey[];
	SelectedIssuesSubscriber: SelectedIssuesSubscriberType;
	Checkbox: typeof CheckboxDI;
	intl: IntlShape;
};

// eslint-disable-next-line jira/react/no-class-components
class CheckboxWrapperWithoutIntl extends Component<Props> {
	static isSelected(state: State) {
		const { issueKeys } = state;

		// for isIndeterminate to work, we must always also set isSelected. Thus, this condition becomes very simple.
		return !isEmpty(issueKeys);
	}

	isIndeterminate(state: State) {
		const { issueKeys } = state;
		const { allIssueKeys } = this.props;

		return issueKeys.length !== allIssueKeys.length && !isEmpty(issueKeys);
	}

	createOnChange(state: State, actions: Actions) {
		return (e: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			const { issueKeys } = state;
			const { allIssueKeys } = this.props;
			if (isEmpty(issueKeys)) {
				actions.selectIssues(allIssueKeys, analyticsEvent);
				fireUIAnalytics(analyticsEvent, 'issueTableHeaderBulkActionCheckbox', {
					usage: 'selectAll',
				});
			} else {
				// allIssueKeys not guaranteed to contain all issueKeys
				actions.deselectAllIssues(analyticsEvent);
				fireUIAnalytics(analyticsEvent, 'issueTableHeaderBulkActionCheckbox', {
					usage: 'deselectAll',
				});
			}
		};
	}

	render() {
		const { SelectedIssuesSubscriber, Checkbox, intl } = this.props;

		return (
			<SelectedIssuesSubscriber>
				{(state, actions) => (
					<StyledWrapper isDisabled={state.isLocked}>
						<Checkbox
							aria-label={intl.formatMessage(messages.selectAllCheckboxLabel)}
							isDisabled={state.isLocked}
							isChecked={CheckboxWrapperWithoutIntl.isSelected(state)}
							isIndeterminate={this.isIndeterminate(state)}
							// @ts-expect-error - TS2345 - Argument of type 'BoundActions<State, typeof import("/buildeng/bamboo-agent-home/xml-data/build-dir/JF-TSMIG123-APPLY/src/packages/platform/services/issue-table-selection/src/services/actions/index")>' is not assignable to parameter of type 'typeof import("/buildeng/bamboo-agent-home/xml-data/build-dir/JF-TSMIG123-APPLY/src/packages/platform/services/issue-table-selection/src/services/actions/index")'.
							onChange={this.createOnChange(state, actions)}
						/>
					</StyledWrapper>
				)}
			</SelectedIssuesSubscriber>
		);
	}
}

export const CheckboxWrapper = injectIntl(CheckboxWrapperWithoutIntl);

export default CheckboxWrapper;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledWrapper = styled.div<{ isDisabled: boolean }>({
	userSelect: 'none',
	boxSizing: 'border-box',
	width: '100%',
	paddingTop: 0,
	paddingRight: token('space.200', '16px'),
	paddingBottom: 0,
	paddingLeft: token('space.150', '12px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	input: ({ isDisabled }) => ({
		cursor: isDisabled ? 'not-allowed' : 'pointer',
	}),
});
