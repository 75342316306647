import type { StoreActionApi, Action } from '@atlassian/react-sweet-state';
import type { ConnectionStoreState, ConnectionState } from './types.tsx';

function isConnectionState(state: ConnectionStoreState): state is ConnectionState {
	return state.stage === 'ready';
}

export function withReadyCheck(
	actionFn: (api: StoreActionApi<ConnectionState>) => void,
): Action<ConnectionStoreState> {
	return (api: StoreActionApi<ConnectionStoreState>): void => {
		const { getState, setState, dispatch } = api;
		const state = getState();
		if (!isConnectionState(state)) {
			throw new Error('Trying to update a pending connection store');
		}
		actionFn({
			getState: () => state,
			setState,
			dispatch,
		});
	};
}
