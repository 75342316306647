import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	sprintLabel: {
		id: 'sprint-flyout.sprint-label',
		defaultMessage: 'SPRINT',
		description: 'Label for the sprint layout header',
	},
	startDateLabel: {
		id: 'sprint-flyout.start-date-label',
		defaultMessage: 'Start date',
		description: 'Label for the sprint start date in the sprint flyout popup in the calendar view',
	},
	endDateLabel: {
		id: 'sprint-flyout.end-date-label',
		defaultMessage: 'Planned end',
		description: 'Label for the sprint start end in the sprint flyout popup in the calendar view',
	},
	completionDateLabel: {
		id: 'sprint-flyout.completion-date-label',
		defaultMessage: 'Complete date',
		description:
			'Label for the sprint start completed in the sprint flyout popup in the calendar view',
	},
	activeSprintState: {
		id: 'sprint-flyout.active-sprint-state',
		defaultMessage: 'Active',
		description:
			'Label for the sprint start completed in the sprint flyout popup in the calendar view',
	},
	futureSprintState: {
		id: 'sprint-flyout.future-sprint-state',
		defaultMessage: 'Future',
		description:
			'Label for the sprint start completed in the sprint flyout popup in the calendar view',
	},
	closedSprintState: {
		id: 'sprint-flyout.closed-sprint-state',
		defaultMessage: 'Closed',
		description:
			'Label for the sprint start completed in the sprint flyout popup in the calendar view',
	},
	closeButtonLabel: {
		id: 'sprint-flyout.close-button-label',
		defaultMessage: 'Close the sprint layout',
		description: 'Label for the close button in the sprint layout',
	},
});
