import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	month: {
		id: 'calendar.calendar-top-controls-bar.view-range-controls.month',
		defaultMessage: 'Month',
		description:
			'Default calendar view mode, showing a month view of the calendar with all the events',
	},
	week: {
		id: 'calendar.calendar-top-controls-bar.view-range-controls.week',
		defaultMessage: 'Week',
		description: 'Calendar view mode week, showing a week view of the calendar with all the events',
	},
});
