/**
 * @generated SignedSource<<e97f35f6d509efcc22c152457570dcf5>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type JiraSprintState = "ACTIVE" | "CLOSED" | "FUTURE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type calendarRenderer_jira$data = {
  readonly jira: {
    readonly jiraCalendar: {
      readonly crossProjectVersions?: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly crossProjectVersionScenarioValues: {
              readonly name: string | null | undefined;
            } | null | undefined;
            readonly id: string;
            readonly name: string;
            readonly releaseDate: AGG$DateTime | null | undefined;
            readonly startDate: AGG$DateTime | null | undefined;
            readonly " $fragmentSpreads": FragmentRefs<"crossProjectVersionRenderer_calendar_CrossProjectVersionEventRenderer">;
          } | null | undefined;
        } | null | undefined> | null | undefined;
        readonly pageInfo: {
          readonly hasNextPage: boolean;
          readonly hasPreviousPage: boolean;
        };
      } | null | undefined;
      readonly projectsPermission: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly action: {
              readonly canPerform: boolean;
            } | null | undefined;
            readonly projectId: string | null | undefined;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly sprints?: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly boardId: AGG$Long | null | undefined;
            readonly completionDate: AGG$DateTime | null | undefined;
            readonly endDate: AGG$DateTime | null | undefined;
            readonly goal: string | null | undefined;
            readonly id: string;
            readonly name: string | null | undefined;
            readonly sprintId: string;
            readonly startDate: AGG$DateTime | null | undefined;
            readonly state: JiraSprintState | null | undefined;
            readonly " $fragmentSpreads": FragmentRefs<"sprintRenderer_calendar_SprintEventRenderer">;
          } | null | undefined;
        } | null | undefined> | null | undefined;
        readonly pageInfo: {
          readonly hasNextPage: boolean;
          readonly hasPreviousPage: boolean;
        };
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"calendarRenderer_calendar_EventRenderer_queryRef">;
  readonly " $fragmentType": "calendarRenderer_jira";
};
export type calendarRenderer_jira$key = {
  readonly " $data"?: calendarRenderer_jira$data;
  readonly " $fragmentSpreads": FragmentRefs<"calendarRenderer_jira">;
};

import calendarRenderer_jira_refetchQuery_graphql from './calendarRenderer_jira_refetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    },
    {
      "kind": "ScalarField",
      "name": "hasPreviousPage"
    }
  ]
},
v1 = {
  "kind": "ScalarField",
  "name": "id"
},
v2 = {
  "kind": "ScalarField",
  "name": "name"
},
v3 = {
  "kind": "ScalarField",
  "name": "startDate"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "configurationInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "crossProjectVersionsSearchInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipCrossProjectVersionSearch"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipSprintSearch"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sprintsSearchInput"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "fragmentPathInResult": [],
      "operation": calendarRenderer_jira_refetchQuery_graphql
    }
  },
  "name": "calendarRenderer_jira",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "configuration",
              "variableName": "configurationInput"
            },
            {
              "kind": "Variable",
              "name": "scope",
              "variableName": "scopeInput"
            }
          ],
          "concreteType": "JiraCalendar",
          "kind": "LinkedField",
          "name": "jiraCalendar",
          "plural": false,
          "selections": [
            {
              "alias": "projectsPermission",
              "concreteType": "JiraProjectConnection",
              "kind": "LinkedField",
              "name": "projects",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraProjectEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "concreteType": "JiraProject",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "projectId"
                        },
                        {
                          "args": [
                            {
                              "kind": "Literal",
                              "name": "type",
                              "value": "CREATE_ISSUES"
                            }
                          ],
                          "concreteType": "JiraProjectAction",
                          "kind": "LinkedField",
                          "name": "action",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "name": "canPerform"
                            }
                          ],
                          "storageKey": "action(type:\"CREATE_ISSUES\")"
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "condition": "skipCrossProjectVersionSearch",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "input",
                      "variableName": "crossProjectVersionsSearchInput"
                    }
                  ],
                  "concreteType": "JiraCrossProjectVersionConnection",
                  "kind": "LinkedField",
                  "name": "crossProjectVersions",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "concreteType": "JiraCrossProjectVersionEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "concreteType": "JiraCrossProjectVersion",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v2/*: any*/),
                            (v3/*: any*/),
                            {
                              "kind": "ScalarField",
                              "name": "releaseDate"
                            },
                            {
                              "concreteType": "JiraCrossProjectVersionPlanScenarioValues",
                              "kind": "LinkedField",
                              "name": "crossProjectVersionScenarioValues",
                              "plural": false,
                              "selections": [
                                (v2/*: any*/)
                              ]
                            },
                            {
                              "kind": "FragmentSpread",
                              "name": "crossProjectVersionRenderer_calendar_CrossProjectVersionEventRenderer"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "condition": "skipSprintSearch",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "input",
                      "variableName": "sprintsSearchInput"
                    }
                  ],
                  "concreteType": "JiraSprintConnection",
                  "kind": "LinkedField",
                  "name": "sprints",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "concreteType": "JiraSprintEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "concreteType": "JiraSprint",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "kind": "ScalarField",
                              "name": "sprintId"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "boardId"
                            },
                            (v2/*: any*/),
                            {
                              "kind": "ScalarField",
                              "name": "goal"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "state"
                            },
                            (v3/*: any*/),
                            {
                              "kind": "ScalarField",
                              "name": "endDate"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "completionDate"
                            },
                            {
                              "kind": "FragmentSpread",
                              "name": "sprintRenderer_calendar_SprintEventRenderer"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "configurationInput",
          "variableName": "configurationInput"
        },
        {
          "kind": "Variable",
          "name": "scopeInput",
          "variableName": "scopeInput"
        }
      ],
      "kind": "FragmentSpread",
      "name": "calendarRenderer_calendar_EventRenderer_queryRef"
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "8183c9ec4329ff42f1e3698e4bdce0bb";

export default node;
