/*!
 * This module implements safe type-casts to add type-safety into pragmatic DND records.
 * This works by using a `Symbol` value that is not obtainable from outside this module.
 * This way, we can safely cast the data to the expected type.
 */

const DRAGGABLE_CALENDAR_ISSUE = Symbol('DRAGGABLE_CALENDAR_ISSUE');
const DRAGGABLE_CALENDAR_VERSION = Symbol('DRAGGABLE_CALENDAR_VERSION');

export type DateRange = {
	startDate: Date | undefined;
	endDate: Date;
};

export type NullableDateRange = {
	[K in keyof DateRange]?: DateRange[K] | null;
};

/**
 * Record type attached to issues being dragged
 */
export interface DraggableCalendarIssueData {
	type: typeof DRAGGABLE_CALENDAR_ISSUE;
	/**
	 * Relay store record ID for this issue
	 */
	issueRecordId: string;

	startDateFieldRecordId: string | undefined;

	endDateFieldRecordId: string | undefined;
	/**
	 * This is the ARI for this issue
	 */
	issueAri: string;
	/**
	 * Current start date value
	 */
	startDate: string | null | undefined;
	/**
	 * Current due date value
	 */
	endDate: string | null | undefined;
	/**
	 * Current connection record ID, to remove the card from
	 */
	connectionRecordId?: string;
	/**
	 * Actual date being dragged, if the cursor is at a pivot point.
	 */
	draggedDate?: string | null;
	/**
	 * the source of the dragged issue
	 */
	source: 'calendar' | 'panel';
	/**
	 * Issue key
	 */
	issueKey: string;
	/**
	 * Can schedule issue permission
	 */
	canSchedule?: boolean | null;
}

/**
 * Construct a Symbol backed record type for draggable issues.
 */
export function makeDraggableCalendarIssue(
	params: Omit<DraggableCalendarIssueData, 'type'>,
): DraggableCalendarIssueData {
	return {
		...params,
		type: DRAGGABLE_CALENDAR_ISSUE,
	};
}

/**
 * This this a `DraggableCalendarIssueData`
 */
export function isDraggableCalendarIssue(data: unknown): data is DraggableCalendarIssueData {
	return (
		typeof data === 'object' &&
		data !== null &&
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		(data as DraggableCalendarIssueData).type === DRAGGABLE_CALENDAR_ISSUE
	);
}

/**
 * Record type attached to version being dragged
 */
export interface DraggableCalendarVersionData {
	type: typeof DRAGGABLE_CALENDAR_VERSION;
	/**
	 * This is the ARI for this version
	 */
	versionId: string;
	versionName: string;
	/**
	 * Current start date value
	 */
	startDate: string | null | undefined;
	/**
	 * Current due date value
	 */
	releaseDate: string | null | undefined;
	/**
	 * Actual date being dragged, if the cursor is at a pivot point.
	 */
	draggedDate?: string | null;
}

export function makeDraggableCalendarVersion(
	params: Omit<DraggableCalendarVersionData, 'type'>,
): DraggableCalendarVersionData {
	return {
		...params,
		type: DRAGGABLE_CALENDAR_VERSION,
	};
}

export function isDraggableCalendarVersion(data: unknown): data is DraggableCalendarVersionData {
	return (
		typeof data === 'object' &&
		data !== null &&
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		(data as DraggableCalendarVersionData).type === DRAGGABLE_CALENDAR_VERSION
	);
}

/**
 * Record type attached to sprint being dragged
 */

export const DRAGGABLE_CALENDAR_SPRINT = Symbol('DRAGGABLE_CALENDAR_SPRINT');

export interface DraggableCalendarSprintData {
	type: typeof DRAGGABLE_CALENDAR_SPRINT;
	/**
	 * This is the ARI for this sprint
	 */
	id: string;
	/**
	 * Sprint numeric ID
	 */
	sprintId: string;
	/**
	 * Sprint name
	 */
	name: string | null | undefined;
	/**
	 * Sprint goal
	 */
	goal: string | null | undefined;
	/**
	 * Sprint state
	 */
	state: string | null | undefined;
	/**
	 * Current start date value
	 */
	startDate: string | null | undefined;
	/**
	 * Current due date value
	 */
	endDate: string | null | undefined;
	/**
	 * Sprint completion date
	 */
	completionDate: string | null | undefined;
	/**
	 * Actual date being dragged, if the cursor is at a pivot point.
	 */
	draggedDate?: string | null;
}

export function makeDraggableCalendarSprint(
	params: Omit<DraggableCalendarSprintData, 'type'>,
): DraggableCalendarSprintData {
	return {
		...params,
		type: DRAGGABLE_CALENDAR_SPRINT,
	};
}

export function isDraggableCalendarSprint(data: unknown): data is DraggableCalendarSprintData {
	return (
		typeof data === 'object' &&
		data !== null &&
		'type' in data &&
		data.type === DRAGGABLE_CALENDAR_SPRINT
	);
}

export type DraggableCalendarData =
	| DraggableCalendarIssueData
	| DraggableCalendarVersionData
	| DraggableCalendarSprintData;

const DROPPABLE_CALENDAR_DAY_CELL = Symbol('DROPPABLE_CALENDAR_DAY_CELL');

export type DroppableCalendarDayCell = {
	type: typeof DROPPABLE_CALENDAR_DAY_CELL;
	isEventDraggedAway: boolean;
};

export function makeDroppableCalendarDayCell(
	params: Omit<DroppableCalendarDayCell, 'type'>,
): DroppableCalendarDayCell {
	return {
		type: DROPPABLE_CALENDAR_DAY_CELL,
		...params,
	};
}

export function isDroppableCalendarDayCell(data: unknown): data is DroppableCalendarDayCell {
	return (
		typeof data === 'object' &&
		data !== null &&
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		(data as DroppableCalendarDayCell).type === DROPPABLE_CALENDAR_DAY_CELL
	);
}
