import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const jwmMultiProjectPickerEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-jwm-multi-project-picker-entrypoint" */ './src').then(
			(module) => module.AsyncJwmMultiProjectPicker,
		),
	),
	getPreloadProps: () => ({}),
});
