/**
 * @generated SignedSource<<eec410b1dfc99a19adf33e772410c4d6>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type fieldValueCheckbox_softwareFiltersPopup$data = {
  readonly jqlTerm: string;
  readonly " $fragmentSpreads": FragmentRefs<"fieldValueLabel_softwareFiltersPopup">;
  readonly " $fragmentType": "fieldValueCheckbox_softwareFiltersPopup";
};
export type fieldValueCheckbox_softwareFiltersPopup$key = {
  readonly " $data"?: fieldValueCheckbox_softwareFiltersPopup$data;
  readonly " $fragmentSpreads": FragmentRefs<"fieldValueCheckbox_softwareFiltersPopup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "fieldValueCheckbox_softwareFiltersPopup",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "jqlTerm"
    },
    {
      "kind": "FragmentSpread",
      "name": "fieldValueLabel_softwareFiltersPopup"
    }
  ],
  "type": "JiraJqlFieldValue",
  "abstractKey": "__isJiraJqlFieldValue"
};

(node as any).hash = "abae94618a174e328b4973cf970437aa";

export default node;
