/**
 * @generated SignedSource<<796985bbe2685e5cdffe418b4dab1ef7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type calendarUnscheduledPanelCard_calendar$data = {
  readonly __id: string;
  readonly canSchedule?: boolean | null | undefined;
  readonly endDateViewField: {
    readonly __typename: "JiraDatePickerField";
    readonly __id: string;
    readonly date?: AGG$Date | null | undefined;
    readonly dateTime?: AGG$DateTime | null | undefined;
    readonly id: string;
  } | null | undefined;
  readonly id: string;
  readonly issueTypeField: {
    readonly issueType: {
      readonly avatar: {
        readonly small: string | null | undefined;
      } | null | undefined;
      readonly name: string;
    } | null | undefined;
  } | null | undefined;
  readonly key: string;
  readonly requestTypeField: {
    readonly __typename: "JiraServiceManagementRequestTypeField";
    readonly id: string;
    readonly requestType?: {
      readonly avatar: {
        readonly small: string | null | undefined;
      } | null | undefined;
      readonly name: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly startDateViewField: {
    readonly __typename: "JiraDatePickerField";
    readonly __id: string;
    readonly date?: AGG$Date | null | undefined;
    readonly dateTime?: AGG$DateTime | null | undefined;
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"calendarCardAssignee_calendar" | "calendarCardKey_calendar" | "calendarCardPriority_calendar" | "calendarCardStartDate_calendar" | "calendarCardStatus_calendar" | "calendarCardSummary_calendar">;
  readonly " $fragmentType": "calendarUnscheduledPanelCard_calendar";
};
export type calendarUnscheduledPanelCard_calendar$key = {
  readonly " $data"?: calendarUnscheduledPanelCard_calendar$data;
  readonly " $fragmentSpreads": FragmentRefs<"calendarUnscheduledPanelCard_calendar">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = [
  {
    "kind": "Variable",
    "name": "viewId",
    "variableName": "viewId"
  }
],
v2 = [
  {
    "kind": "ScalarField",
    "name": "name"
  },
  {
    "concreteType": "JiraAvatar",
    "kind": "LinkedField",
    "name": "avatar",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "small"
      }
    ]
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v5 = [
  (v0/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      (v3/*: any*/),
      {
        "kind": "ScalarField",
        "name": "date"
      }
    ],
    "type": "JiraDatePickerField"
  },
  {
    "kind": "InlineFragment",
    "selections": [
      (v3/*: any*/),
      {
        "kind": "ScalarField",
        "name": "dateTime"
      }
    ],
    "type": "JiraDateTimePickerField"
  },
  (v4/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "schedulePermissionsEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "name": "calendarUnscheduledPanelCard_calendar",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "key"
    },
    {
      "kind": "FragmentSpread",
      "name": "calendarCardKey_calendar"
    },
    {
      "kind": "FragmentSpread",
      "name": "calendarCardSummary_calendar"
    },
    {
      "kind": "FragmentSpread",
      "name": "calendarCardAssignee_calendar"
    },
    {
      "kind": "FragmentSpread",
      "name": "calendarCardPriority_calendar"
    },
    {
      "kind": "FragmentSpread",
      "name": "calendarCardStatus_calendar"
    },
    {
      "args": (v1/*: any*/),
      "kind": "FragmentSpread",
      "name": "calendarCardStartDate_calendar"
    },
    {
      "condition": "schedulePermissionsEnabled",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "canSchedule",
          "args": [
            {
              "kind": "Literal",
              "name": "permission",
              "value": "SCHEDULE_ISSUES"
            }
          ],
          "kind": "ScalarField",
          "name": "hasProjectPermission",
          "storageKey": "hasProjectPermission(permission:\"SCHEDULE_ISSUES\")"
        }
      ]
    },
    {
      "concreteType": "JiraIssueTypeField",
      "kind": "LinkedField",
      "name": "issueTypeField",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueType",
          "kind": "LinkedField",
          "name": "issueType",
          "plural": false,
          "selections": (v2/*: any*/)
        }
      ]
    },
    {
      "alias": "requestTypeField",
      "args": [
        {
          "kind": "Literal",
          "name": "idOrAlias",
          "value": "requesttype"
        },
        {
          "kind": "Literal",
          "name": "ignoreMissingField",
          "value": true
        }
      ],
      "kind": "LinkedField",
      "name": "fieldByIdOrAlias",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v3/*: any*/),
            {
              "concreteType": "JiraServiceManagementRequestType",
              "kind": "LinkedField",
              "name": "requestType",
              "plural": false,
              "selections": (v2/*: any*/)
            }
          ],
          "type": "JiraServiceManagementRequestTypeField"
        }
      ],
      "storageKey": "fieldByIdOrAlias(idOrAlias:\"requesttype\",ignoreMissingField:true)"
    },
    {
      "args": (v1/*: any*/),
      "kind": "LinkedField",
      "name": "startDateViewField",
      "plural": false,
      "selections": (v5/*: any*/)
    },
    {
      "args": (v1/*: any*/),
      "kind": "LinkedField",
      "name": "endDateViewField",
      "plural": false,
      "selections": (v5/*: any*/)
    },
    (v4/*: any*/)
  ],
  "type": "JiraIssue"
};
})();

(node as any).hash = "45802ff5f2b902ac8b2c8819452a1a62";

export default node;
