import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	scheduleYourWork: {
		id: 'calendar.calendar-unscheduled-panel.schedule-your-work',
		defaultMessage: 'Unscheduled work',
		description: 'Title that shows-up on the Jira calendar view unscheduled items panel',
	},
	closePanel: {
		id: 'calendar.calendar-unscheduled-panel.close-panel',
		defaultMessage: 'Close panel',
		description: 'Label for icon button that closes the calendar unscheduled panel',
	},
	unscheduledDescription: {
		id: 'calendar.calendar-unscheduled-panel.unscheduled-description',
		defaultMessage: 'Drag each item onto the calendar to set a due date for your work.',
		description: 'Copy that shows-up on the Jira calendar view unscheduled items panel',
	},
	unscheduledDescriptionIssueTermRefresh: {
		id: 'calendar.calendar-unscheduled-panel.unscheduled-description-issue-term-refresh',
		defaultMessage: 'Drag each work item onto the calendar to set a due date for the work.',
		description: 'Copy that shows-up on the Jira calendar view unscheduled items panel',
	},
	searchPlaceholder: {
		id: 'calendar.calendar-unscheduled-panel.search-placeholder',
		defaultMessage: 'Search unscheduled items',
		description: 'Search placeholder for search text into unscheduled items',
	},
});
