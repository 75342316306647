import React, { useState, useRef, useCallback, memo, useEffect, type ReactNode } from 'react';
import { Box, Stack, xcss, type XCSS } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { ResizePanelHandle } from './resize-panel-handle/index.tsx';
import {
	retrieveCalendarSidebarWidthFromLocalStorage,
	setCalendarSidebarWidthInLocalStorage,
} from './utils.tsx';

const DEFAULT_WIDTH = 400;
const MIN_WIDTH = 350;
const MAX_WIDTH = 700;

/**
 * Prevent re-renders falling-through in case children isn't properly memoized.
 */
const RenderBoundary = memo(({ children }: { children: ReactNode }) => <>{children}</>);

interface ResizableSidebarPanelProps {
	/**
	 * Panel contents
	 */
	children: ReactNode;
	/**
	 * A test ID for this panel's stack
	 */
	testId?: string;
	/**
	 * Extra styles for the panel's stack
	 */
	xcss?: Array<XCSS | false | undefined>;
}

export function ResizableSidebarPanel({ children, testId, ...props }: ResizableSidebarPanelProps) {
	const [isMouseDown, setIsMouseDown] = useState(false);
	const [isMouseOver, setIsMouseOver] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);
	const flexBasisRef = useRef(DEFAULT_WIDTH);

	const onMouseOut = useCallback(() => {
		setIsMouseOver(false);
	}, []);

	const onMouseOver = useCallback(() => {
		setIsMouseOver(true);
	}, []);

	const handleSetWidth = useCallback((newWidth: number) => {
		if (!Number.isNaN(newWidth) && containerRef.current) {
			const clampedWidth = Math.max(MIN_WIDTH, Math.min(newWidth, MAX_WIDTH));
			flexBasisRef.current = clampedWidth;
			containerRef.current.style.flexBasis = `${clampedWidth}px`;
			setCalendarSidebarWidthInLocalStorage(clampedWidth);
		}
	}, []);

	const getCurrentWidth = useCallback(() => {
		if (!containerRef.current) {
			return DEFAULT_WIDTH;
		}
		const flexBasis = parseFloat(containerRef.current.style.flexBasis);
		return flexBasis || DEFAULT_WIDTH;
	}, []);

	useEffect(() => {
		// Initialize width
		const sideBarwidth = retrieveCalendarSidebarWidthFromLocalStorage();
		if (containerRef.current) {
			containerRef.current.style.flexBasis = `${sideBarwidth ?? DEFAULT_WIDTH}px`;
		}
	}, []);

	return (
		<Box
			xcss={containerStyles}
			ref={containerRef}
			testId="calendar.common.ui.resizable-sidebar-panel.container"
		>
			<Stack
				grow="fill"
				xcss={[
					fg('jira-calendar-business-theme')
						? containerStackStyles
						: containerStackStylesWithoutJwmTheming,
					...(props.xcss ?? []),
					isMouseOver && containerStackHoverStyles,
					isMouseDown && containerStackDownStyles,
				]}
				data-ismousedown={isMouseDown}
				space="space.300"
				testId={testId}
			>
				<ResizePanelHandle
					setWidth={handleSetWidth}
					setIsMouseDown={setIsMouseDown}
					isMouseDown={isMouseDown}
					onMouseOut={onMouseOut}
					onMouseOver={onMouseOver}
					getCurrentWidth={getCurrentWidth}
					minWidth={MIN_WIDTH}
					maxWidth={MAX_WIDTH}
				/>

				<RenderBoundary>{children}</RenderBoundary>
			</Stack>
		</Box>
	);
}
const containerStyles = xcss({
	height: '100%',
	minWidth: `${MIN_WIDTH}px`,
	maxWidth: `${MAX_WIDTH}px`,
	display: 'flex',
	flexShrink: 1,
	flexBasis: `${DEFAULT_WIDTH}px`,
	contain: 'layout style', // Apply containment
	willChange: 'width', // Indicates that the width will change
	transform: 'translateZ(0)', // Trigger hardware acceleration
});

const containerStackStyles = xcss({
	backgroundColor: 'elevation.surface.overlay',
	borderTopLeftRadius: 'border.radius.100',
	borderTopRightRadius: 'border.radius.100',
	boxShadow: 'elevation.shadow.overlay',
	position: 'relative',
	borderLeft: 'solid 2px transparent',
	transition: 'border-left-color 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.12)',
});

const containerStackStylesWithoutJwmTheming = xcss({
	borderTopLeftRadius: 'border.radius.100',
	borderTopRightRadius: 'border.radius.100',
	boxShadow: 'elevation.shadow.overlay',
	position: 'relative',
	borderLeft: 'solid 2px transparent',
	transition: 'border-left-color 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.12)',
});

const containerStackHoverStyles = xcss({
	borderLeftColor: 'color.border.focused',
});

const containerStackDownStyles = xcss({
	borderLeftColor: 'color.border.selected',
});
