/**
 * @generated SignedSource<<b880b1406fe52a17fe4c62806fdcc7d5>>
 * @relayHash ac7c14784baeffcf114e9ecb92ce63dd
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b6afd5d8d683d4843701690a452034fa547250872c39b87e4a7c63bda109fd91

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraSprintState = "ACTIVE" | "CLOSED" | "FUTURE" | "%future added value";
export type JiraSprintUpdateInput = {
  endDate?: string | null | undefined;
  sprintId: string;
  startDate?: string | null | undefined;
};
export type useUpdateSprintDateMutation_updateSprintDateMutation$variables = {
  input: JiraSprintUpdateInput;
};
export type useUpdateSprintDateMutation_updateSprintDateMutation$data = {
  readonly jira: {
    readonly sprintUpdate: {
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly errorType: string | null | undefined;
          readonly statusCode: number | null | undefined;
        } | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly jiraSprint: {
        readonly completionDate: AGG$DateTime | null | undefined;
        readonly endDate: AGG$DateTime | null | undefined;
        readonly goal: string | null | undefined;
        readonly id: string;
        readonly name: string | null | undefined;
        readonly sprintId: string;
        readonly startDate: AGG$DateTime | null | undefined;
        readonly state: JiraSprintState | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type useUpdateSprintDateMutation_updateSprintDateMutation = {
  response: useUpdateSprintDateMutation_updateSprintDateMutation$data;
  variables: useUpdateSprintDateMutation_updateSprintDateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "sprintUpdateInput",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "kind": "ScalarField",
  "name": "message"
},
v4 = {
  "kind": "ScalarField",
  "name": "errorType"
},
v5 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v6 = {
  "kind": "ScalarField",
  "name": "id"
},
v7 = {
  "concreteType": "JiraSprint",
  "kind": "LinkedField",
  "name": "jiraSprint",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "kind": "ScalarField",
      "name": "sprintId"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "state"
    },
    {
      "kind": "ScalarField",
      "name": "goal"
    },
    {
      "kind": "ScalarField",
      "name": "startDate"
    },
    {
      "kind": "ScalarField",
      "name": "endDate"
    },
    {
      "kind": "ScalarField",
      "name": "completionDate"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "useUpdateSprintDateMutation_updateSprintDateMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraSprintMutationPayload",
            "kind": "LinkedField",
            "name": "sprintUpdate",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ]
                  }
                ]
              },
              (v7/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdateSprintDateMutation_updateSprintDateMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraSprintMutationPayload",
            "kind": "LinkedField",
            "name": "sprintUpdate",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v6/*: any*/)
                        ],
                        "type": "BulkMutationErrorExtension"
                      }
                    ]
                  }
                ]
              },
              (v7/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "b6afd5d8d683d4843701690a452034fa547250872c39b87e4a7c63bda109fd91",
    "metadata": {},
    "name": "useUpdateSprintDateMutation_updateSprintDateMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "f2c3303f9b5b65313bef326a3ad258d0";

export default node;
