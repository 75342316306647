import React from 'react';
import { styled } from '@compiled/react';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import type { JiraVersionStatus } from '@atlassian/jira-relay/src/__generated__/versionRenderer_calendar_VersionEventRenderer.graphql';
import { type ReleaseStatus, COMPLETE, RELEASE_STATUS_COLORS, OFF_TRACK } from './constants.tsx';
import { getReleaseStatus } from './utils.tsx';

const CompleteIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		fill="none"
		viewBox="0 0 16 16"
		// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		style={{ pointerEvents: 'none' }}
	>
		<circle r="7" cx="8" cy="8" fill="currentColor" />
		<path
			fill={RELEASE_STATUS_COLORS[COMPLETE]}
			fillRule="evenodd"
			d="M8 16A8 8 0 108 0a8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L7 8.586 5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
			clipRule="evenodd"
		/>
	</svg>
);

const WarningIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		fill="none"
		viewBox="0 0 16 16"
		// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		style={{ pointerEvents: 'none' }}
	>
		<circle r="6" cx="8" cy="8" fill="currentColor" />
		<path
			fill={RELEASE_STATUS_COLORS[OFF_TRACK]}
			fillRule="evenodd"
			d="M.768 9.768a2.5 2.5 0 010-3.536L6.232.768a2.5 2.5 0 013.536 0l5.464 5.464a2.5 2.5 0 010 3.536l-5.464 5.464a2.5 2.5 0 01-3.536 0L.768 9.768zM7 4a1 1 0 112 0v4a1 1 0 01-2 0V4zm1 8a1 1 0 100-2 1 1 0 000 2z"
			clipRule="evenodd"
		/>
	</svg>
);

type ReleaseIconProps = {
	status: JiraVersionStatus;
	releaseDate: string;
};

export const ReleaseIcon = ({ status, releaseDate }: ReleaseIconProps) => {
	const releaseStatus = getReleaseStatus(status, releaseDate);
	const renderIcon = () => {
		switch (releaseStatus) {
			case COMPLETE:
				return <CompleteIcon />;
			case OFF_TRACK:
				return <WarningIcon />;
			default:
				return null;
		}
	};

	if (!releaseStatus) {
		return null;
	}

	return <ReleaseIconContainer status={releaseStatus}>{renderIcon()}</ReleaseIconContainer>;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReleaseIconContainer = styled.span<{ status: ReleaseStatus }>({
	color: token('color.text.inverse', N0),
	width: token('space.150', '12px'),
	height: token('space.150', '12px'),
	padding: 0,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: ({ status }) => RELEASE_STATUS_COLORS[status],
});
