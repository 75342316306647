import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

type Props = React.PropsWithChildren<{
	ariaLabel: string;
}>;

export const SimulateClickOnKeydown = ({ children, ariaLabel }: Props) => (
	<Box
		tabIndex={-1}
		role="button"
		aria-hidden="true"
		aria-label={ariaLabel}
		xcss={eventWrapperStyles}
		onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
			if ((event.key === 'Enter' || event.key === ' ') && event.currentTarget === event.target) {
				// Scroll the event into view so that the popup will open in the right place
				event.currentTarget.scrollIntoView();
				// Simulate a click on the event to open the event popup
				event.currentTarget.click();
			}
		}}
	>
		{children}
	</Box>
);

const eventWrapperStyles = xcss({
	height: 'inherit',
	width: 'inherit',
});
