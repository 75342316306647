/**
 * @generated SignedSource<<0b0477a48bec7f6780df850cb5cf9644>>
 * @relayHash 9589a8a8c4c7aaf91b2c43f36aa69dd8
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b92398f29a22601bade2e4d3fc6becbce47c5d0e938df418afbcef070e9d836b

import type { ConcreteRequest, Query } from 'relay-runtime';
export type headerAddPeopleButton_calendarQuery$variables = {
  cloudId: string;
  hasProjectKey: boolean;
  projectKey: string;
};
export type headerAddPeopleButton_calendarQuery$data = {
  readonly jira: {
    readonly jiraProjectByKey?: {
      readonly key: string;
      readonly name: string;
      readonly projectId: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type headerAddPeopleButton_calendarQuery = {
  response: headerAddPeopleButton_calendarQuery$data;
  variables: headerAddPeopleButton_calendarQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasProjectKey"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v3 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "projectKey"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "projectId"
},
v5 = {
  "kind": "ScalarField",
  "name": "key"
},
v6 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "headerAddPeopleButton_calendarQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "condition": "hasProjectKey",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": (v3/*: any*/),
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "jiraProjectByKey",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "headerAddPeopleButton_calendarQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "condition": "hasProjectKey",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": (v3/*: any*/),
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "jiraProjectByKey",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "b92398f29a22601bade2e4d3fc6becbce47c5d0e938df418afbcef070e9d836b",
    "metadata": {},
    "name": "headerAddPeopleButton_calendarQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "a9b7bb2cf87b83bb037c1b67098f198d";

export default node;
