/*!
 * This is a module in the spirit of @atlaskit/primitives that provides a mechanism
 * to implement JavaScript based responsive layout based on container sizes.
 */
/** @jsx jsx */
import React, {
	type RefObject,
	type Ref,
	type ComponentType,
	type ReactNode,
	useMemo,
} from 'react';
import { css, jsx } from '@compiled/react';
import type { Breakpoint } from '@atlaskit/primitives';
import { useRectObserver } from '@atlassian/jira-react-use-resize-observer/src/index.tsx';

type As =
	| ComponentType<{ children: ReactNode; ref?: Ref<HTMLElement> }>
	| 'article'
	| 'aside'
	| 'dialog'
	| 'div'
	| 'footer'
	| 'header'
	| 'li'
	| 'main'
	| 'nav'
	| 'ol'
	| 'section'
	| 'span'
	| 'ul';

export function useBreakpointObserver({ ref }: { ref: RefObject<HTMLElement> }): Breakpoint {
	const rect = useRectObserver({ ref });
	const width = rect?.width ?? null;
	return useMemo(() => findBreakpoint(width), [width]);
}

/**
 * Breakpoint configuration snapshotted from the @atlaskit/primitives package.
 */
const BREAKPOINTS: { breakpoint: Breakpoint; min: number }[] = [
	{ breakpoint: 'xl', min: 16 * 110 },
	{ breakpoint: 'lg', min: 16 * 90 },
	{ breakpoint: 'md', min: 16 * 64 },
	{ breakpoint: 'sm', min: 16 * 48 },
	{ breakpoint: 'xs', min: 16 * 30 },
	{ breakpoint: 'xxs', min: 16 * 0 },
];

export function findBreakpoint(width: number | null): Breakpoint {
	if (width == null) {
		return 'lg';
	}

	return BREAKPOINTS.find((bp) => width >= bp.min)?.breakpoint ?? 'lg';
}

/**
 * Negative means a is a larger breakpoint than b.
 */
function compareBreakpoints(a: Breakpoint, b: Breakpoint): number {
	const aIndex = BREAKPOINTS.findIndex((bp) => bp.breakpoint === a);
	const bIndex = BREAKPOINTS.findIndex((bp) => bp.breakpoint === b);
	return aIndex - bIndex;
}

export type ContainerShowProps = { children: ReactNode; as?: As; breakpoint: Breakpoint } & (
	| { above: Breakpoint; below?: never }
	| { below: Breakpoint; above?: never }
);

/**
 * Like @atlaskit/primitives/Show, but with a more flexible API so we can hide
 * based on container sizes.
 *
 * Use with `useBreakpointObserver` to hide/show content based on container size.
 */
export function ContainerShow({ children, as, above, below, breakpoint }: ContainerShowProps) {
	const Component = as ?? 'div';

	if (above) {
		return compareBreakpoints(breakpoint, above) <= 0 ? (
			<Component>{children}</Component>
		) : (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			<Component css={css({ display: 'none' })}>{children}</Component>
		);
	}

	if (below) {
		return compareBreakpoints(breakpoint, below) > 0 ? (
			<Component>{children}</Component>
		) : (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			<Component css={css({ display: 'none' })}>{children}</Component>
		);
	}

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	return <Component css={css({ display: 'none' })}>{children}</Component>;
}

type ContainerHideProps = {
	children: ReactNode;
	as?: As;
	breakpoint: Breakpoint;
} & (
	| {
			above: Breakpoint;
			below?: never;
	  }
	| {
			below: Breakpoint;
			above?: never;
	  }
);

/**
 * Like @atlaskit/primitives/Hide, but with a more flexible API so we can hide
 * based on container sizes.
 *
 * Use with `useBreakpointObserver` to hide/show content based on container size.
 */
export function ContainerHide({ children, as, above, below, breakpoint }: ContainerHideProps) {
	const Component = as ?? 'div';

	if (above) {
		return compareBreakpoints(breakpoint, above) >= 0 ? (
			<Component>{children}</Component>
		) : (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			<Component css={css({ display: 'none' })}>{children}</Component>
		);
	}

	if (below) {
		return compareBreakpoints(breakpoint, below) < 0 ? (
			<Component>{children}</Component>
		) : (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			<Component css={css({ display: 'none' })}>{children}</Component>
		);
	}

	return <Component>{children}</Component>;
}
