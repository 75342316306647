import React, { createContext, useContext, useMemo, memo, type ReactNode } from 'react';

export const DEFAULT_EXCLUDED_FIELDS = ['project', 'duedate', 'team[team]', 'watchers'];

type JqlViewContext = 'JSW_PLANS' | 'JWM' | 'SHADOW_REQUEST' | null;

export type CalendarJqlBuilderContext = {
	/**
	 * JiraJqlViewContext: JWM | SHADOW_REQUEST | JSW_PLANS | null
	 *
	 * Instructs the backend to return fields corresponds to the view
	 */
	viewContext: JqlViewContext;
	/**
	 * Explicitly fields from jql-builder
	 *
	 * project - prevent misleading UX where dropdown options show all recently
	 *           used projects instead of projects relevant within the scope.
	 * duedate - the calendar depends on duedate for rendering.
	 * teamm watchers - field not supported on basic search mode and will be addressed by
	 *                  https://atlassian.slack.com/archives/CFGMKF5QC/p1716876752682849
	 */
	excludedFields: string[];
	/**
	 * Number of suggested users count in the assignee filter
	 *
	 * JQL-builder fallback to 5 if this is not defined.
	 */
	suggestedUsersCount?: number;
};

const defaultContext: CalendarJqlBuilderContext = {
	viewContext: null,
	excludedFields: DEFAULT_EXCLUDED_FIELDS,
};

const CalendarJqlBuilderContext = createContext<CalendarJqlBuilderContext>(defaultContext);

export const useCalendarJqlBuilderContext = () => useContext(CalendarJqlBuilderContext);

type Props = Partial<CalendarJqlBuilderContext> & {
	children: ReactNode;
};

export const CalendarJqlBuilderContextProvider = memo(({ children, ...props }: Props) => {
	const context = useMemo(() => ({ ...defaultContext, ...props }), [props]);
	return (
		<CalendarJqlBuilderContext.Provider value={context}>
			{children}
		</CalendarJqlBuilderContext.Provider>
	);
});
