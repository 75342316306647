const EDIT_ISSUE = 'issueaction-edit-issue';
const ASSIGN = 'issueaction-assign-issue';
const ASSIGN_TO_ME = 'issueaction-assign-to-me hide-from-opsbar';
const COMMENT_ISSUE = 'issueaction-comment-issue add-issue-comment';
const LOG_WORK = 'issueaction-log-work';

const AGILE_BOARD = 'issueaction-greenhopper-rapidboard-operation js-rapidboard-operation-issue';

export const RANK_TO_TOP = 'issueaction-greenhopper-rank-top-operation';
export const RANK_TO_BOTTOM = 'issueaction-greenhopper-rank-bottom-operation';

const ATTACH_FILE = 'unified-attach-file';
const ATTACH_SCREENSHOT = 'issueaction-attach-screenshot-html5';
const ADD_VOTE = 'issueaction-vote-issue';
const REMOVE_VOTE = 'issueaction-unvote-issue';
const VIEW_VOTERS = 'issueaction-view-voters';
const WATCH_ISSUE = 'issueaction-watch-issue';
const UNWATCH_ISSUE = 'issueaction-unwatch-issue';
const WATCHERS = 'issueaction-manage-watchers';
const CREATE_SUBTASK = 'issueaction-create-subtask';
const CONVERT_TO_ISSUE = 'issueaction-subtask-to-issue';
const CONVERT_TO_SUBTASK = 'issueaction-issue-to-subtask';
const CREATE_BRANCH = 'devstatus-cta-link';
const CREATE_LINKED_ISSUE = 'issueaction-create-linked-issue';
const MOVE = 'issueaction-move-issue';
const LINK = 'issueaction-link-issue';
const CLONE = 'issueaction-clone-issue';
const LABELS = 'issueaction-edit-labels';
const DELETE = 'issueaction-delete-issue';
const ADD_FIELD = 'issueaction-fields-add';
const PERMISSION_HELPER = 'permissionhelper-trigger';
const NOTIFICATION_HELPER = 'notificationhelper-trigger';
const LINK_ISSUE = 'add-jira-issue-link-link';
const LINK_CONFLUENCE_PAGE = 'add-confluence-page-link-link';
const ADD_WEB_LINK = 'add-web-link-link';

export const OPERATION_IDS = {
	EDIT_ISSUE,
	ASSIGN,
	ASSIGN_TO_ME,
	COMMENT_ISSUE,
	LOG_WORK,
	AGILE_BOARD,
	RANK_TO_TOP,
	RANK_TO_BOTTOM,
	ATTACH_FILE,
	ATTACH_SCREENSHOT,
	ADD_VOTE,
	REMOVE_VOTE,
	VIEW_VOTERS,
	WATCH_ISSUE,
	UNWATCH_ISSUE,
	WATCHERS,
	CREATE_SUBTASK,
	CONVERT_TO_ISSUE,
	CONVERT_TO_SUBTASK,
	CREATE_BRANCH,
	CREATE_LINKED_ISSUE,
	MOVE,
	LINK,
	CLONE,
	LABELS,
	DELETE,
	ADD_FIELD,
	PERMISSION_HELPER,
	NOTIFICATION_HELPER,
} as const;

export const LINK_IDS = {
	LINK_ISSUE,
	LINK_CONFLUENCE_PAGE,
	ADD_WEB_LINK,
} as const;

export const DIALOG_FIELDS = [
	EDIT_ISSUE,
	COMMENT_ISSUE,
	LOG_WORK,
	RANK_TO_TOP,
	RANK_TO_BOTTOM,
	ATTACH_FILE,
	ADD_VOTE,
	REMOVE_VOTE,
	WATCH_ISSUE,
	UNWATCH_ISSUE,
	LINK,
	CLONE,
	LABELS,
	DELETE,
	ADD_FIELD,
	PERMISSION_HELPER,
	NOTIFICATION_HELPER,
	ASSIGN,
	ASSIGN_TO_ME,
	ATTACH_SCREENSHOT,
	CREATE_LINKED_ISSUE,
	CREATE_SUBTASK,
] as const;

export const SKIP_FIELDS = [
	EDIT_ISSUE,
	ASSIGN,
	ASSIGN_TO_ME,
	ATTACH_SCREENSHOT,
	CREATE_LINKED_ISSUE,
	CREATE_BRANCH,
	LINK,
] as const;

export const URL_OVERRIDE_FIELDS = {
	[PERMISSION_HELPER]: '/secure/admin/PermissionHelperAdmin.jspa',
	[NOTIFICATION_HELPER]: '/secure/admin/NotificationHelperAdmin.jspa',
} as const;

export const DIALOG_DATA = {
	[ASSIGN]: {
		id: 'assign-dialog',
		issueMsg: 'thanksIssueAssigned',
	},
	[ASSIGN_TO_ME]: {
		id: 'assign-dialog',
		issueMsg: 'thanksIssueAssigned',
	},
	[COMMENT_ISSUE]: {
		id: 'comment-add-dialog',
		issueMsg: 'thanksIssueCommented',
	},
	[ATTACH_FILE]: {
		id: 'attach-file-dialog',
		issueMsg: 'thanksIssueAttached',
	},
	[DELETE]: {
		id: 'delete-issue-dialog',
		issueMsg: 'thanksIssueDeleted',
	},
	[CLONE]: {
		id: 'clone-issue-dialog',
		issueMsg: 'thanksIssueCloned',
	},
	[LOG_WORK]: {
		id: 'edit-log-work-dialog',
		issueMsg: 'thanksIssueWorklogged',
	},
	[LABELS]: {
		id: 'edit-labels-dialog',
		issueMsg: 'thanksIssueLabelled',
	},
} as const;
