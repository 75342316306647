/**
 * @generated SignedSource<<103c809b551d9a3cb77f462c7fd1aa36>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type filters_calendar_CalendarFilters$data = {
  readonly jiraCalendar: {
    readonly projects: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly key: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly userPreferences: {
    readonly " $fragmentSpreads": FragmentRefs<"main_jqlBuilder_JQLBuilder">;
  } | null | undefined;
  readonly " $fragmentType": "filters_calendar_CalendarFilters";
};
export type filters_calendar_CalendarFilters$key = {
  readonly " $data"?: filters_calendar_CalendarFilters$data;
  readonly " $fragmentSpreads": FragmentRefs<"filters_calendar_CalendarFilters">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "configurationInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeInput"
    }
  ],
  "kind": "Fragment",
  "name": "filters_calendar_CalendarFilters",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "cloudId",
          "variableName": "cloudId"
        }
      ],
      "concreteType": "JiraUserPreferences",
      "kind": "LinkedField",
      "name": "userPreferences",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "main_jqlBuilder_JQLBuilder"
        }
      ]
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "configuration",
          "variableName": "configurationInput"
        },
        {
          "kind": "Variable",
          "name": "scope",
          "variableName": "scopeInput"
        }
      ],
      "concreteType": "JiraCalendar",
      "kind": "LinkedField",
      "name": "jiraCalendar",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 30
            }
          ],
          "concreteType": "JiraProjectConnection",
          "kind": "LinkedField",
          "name": "projects",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraProjectEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "concreteType": "JiraProject",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "key"
                    }
                  ]
                }
              ]
            }
          ],
          "storageKey": "projects(first:30)"
        }
      ]
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "99cd69a14f8650cc5a8b8a8fbc58ab35";

export default node;
