import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	changeIndicatorTooltip: {
		id: 'calendar.common.change-indicator.change-indicator-tooltip',
		defaultMessage: 'To apply changes beyond your calendar, review and save changes.',
		description:
			'It is a text shown in the tooltip of change indicator which has orange border in the container.',
	},
});
