/**
 * @generated SignedSource<<31cd46448848e093271949228f1d3174>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type fieldValuesList_softwareFiltersPopup$data = {
  readonly jira: {
    readonly jqlBuilder: {
      readonly fieldValues: {
        readonly edges: ReadonlyArray<{
          readonly cursor: string;
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"fieldValueCheckbox_softwareFiltersPopup">;
          } | null | undefined;
        } | null | undefined> | null | undefined;
        readonly pageInfo: {
          readonly hasNextPage: boolean;
        };
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "fieldValuesList_softwareFiltersPopup";
};
export type fieldValuesList_softwareFiltersPopup$key = {
  readonly " $data"?: fieldValuesList_softwareFiltersPopup$data;
  readonly " $fragmentSpreads": FragmentRefs<"fieldValuesList_softwareFiltersPopup">;
};

import fieldValuesList_softwareFiltersPopup_RefetchQuery_graphql from './fieldValuesList_softwareFiltersPopup_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "jira",
  "jqlBuilder",
  "fieldValues"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "fieldName"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "jqlContext"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchString"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": fieldValuesList_softwareFiltersPopup_RefetchQuery_graphql
    }
  },
  "name": "fieldValuesList_softwareFiltersPopup",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "cloudId",
              "variableName": "cloudId"
            }
          ],
          "concreteType": "JiraJqlBuilder",
          "kind": "LinkedField",
          "name": "jqlBuilder",
          "plural": false,
          "selections": [
            {
              "alias": "fieldValues",
              "args": [
                {
                  "kind": "Variable",
                  "name": "jqlContext",
                  "variableName": "jqlContext"
                },
                {
                  "kind": "Variable",
                  "name": "jqlTerm",
                  "variableName": "fieldName"
                },
                {
                  "kind": "Variable",
                  "name": "searchString",
                  "variableName": "searchString"
                }
              ],
              "concreteType": "JiraJqlFieldValueConnection",
              "kind": "LinkedField",
              "name": "__filterValuesList_softwareFiltersPopup_fieldValues_connection",
              "plural": false,
              "selections": [
                {
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "hasNextPage"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "endCursor"
                    }
                  ]
                },
                {
                  "concreteType": "JiraJqlFieldValueEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "cursor"
                    },
                    {
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "FragmentSpread",
                          "name": "fieldValueCheckbox_softwareFiltersPopup"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "__typename"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "2acb39e122378da101af04d30f619809";

export default node;
