/**
 * @generated SignedSource<<286fcdb431f412c15d393d48fd4ac671>>
 * @relayHash 21e6667476cde921796fba1c5e4d2f7b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID beff6e26936d88dc4e6fdb686b3fb99fa6930201413b9f5ffb67886a85ec9791

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type moreOptionsFilterDropdownContentInner_softwareFiltersPopup_Query$variables = {
  cloudId: string;
  fieldName: string;
  searchString?: string | null | undefined;
};
export type moreOptionsFilterDropdownContentInner_softwareFiltersPopup_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"fieldValuesList_softwareFiltersPopup">;
};
export type moreOptionsFilterDropdownContentInner_softwareFiltersPopup_Query = {
  response: moreOptionsFilterDropdownContentInner_softwareFiltersPopup_Query$data;
  variables: moreOptionsFilterDropdownContentInner_softwareFiltersPopup_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fieldName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchString"
  }
],
v1 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v2 = {
  "kind": "Literal",
  "name": "first",
  "value": 20
},
v3 = {
  "kind": "Variable",
  "name": "searchString",
  "variableName": "searchString"
},
v4 = [
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "jqlTerm",
    "variableName": "fieldName"
  },
  (v3/*: any*/)
],
v5 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v6 = {
  "kind": "ScalarField",
  "name": "name"
},
v7 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "moreOptionsFilterDropdownContentInner_softwareFiltersPopup_Query",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "fieldName",
            "variableName": "fieldName"
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "fieldValuesList_softwareFiltersPopup"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "moreOptionsFilterDropdownContentInner_softwareFiltersPopup_Query",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              (v1/*: any*/)
            ],
            "concreteType": "JiraJqlBuilder",
            "kind": "LinkedField",
            "name": "jqlBuilder",
            "plural": false,
            "selections": [
              {
                "args": (v4/*: any*/),
                "concreteType": "JiraJqlFieldValueConnection",
                "kind": "LinkedField",
                "name": "fieldValues",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      }
                    ]
                  },
                  {
                    "concreteType": "JiraJqlFieldValueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      },
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isJiraJqlFieldValue"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "jqlTerm"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "displayName"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "picture"
                                  },
                                  (v7/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraJqlUserFieldValue"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraPriority",
                                "kind": "LinkedField",
                                "name": "priority",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "iconUrl"
                                  },
                                  (v6/*: any*/),
                                  (v7/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraJqlPriorityFieldValue"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraProject",
                                "kind": "LinkedField",
                                "name": "project",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraAvatar",
                                    "kind": "LinkedField",
                                    "name": "avatar",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "xsmall"
                                      }
                                    ]
                                  },
                                  (v6/*: any*/),
                                  (v7/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraJqlProjectFieldValue"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraStatusCategory",
                                "kind": "LinkedField",
                                "name": "statusCategory",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "statusCategoryId"
                                  },
                                  (v7/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraJqlStatusFieldValue"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraLabel",
                                "kind": "LinkedField",
                                "name": "label",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraJqlLabelFieldValue"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraGroup",
                                "kind": "LinkedField",
                                "name": "group",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v7/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraJqlGroupFieldValue"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "args": (v4/*: any*/),
                "filters": [
                  "jqlTerm",
                  "jqlContext",
                  "searchString"
                ],
                "handle": "connection",
                "key": "filterValuesList_softwareFiltersPopup_fieldValues",
                "kind": "LinkedHandle",
                "name": "fieldValues"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "beff6e26936d88dc4e6fdb686b3fb99fa6930201413b9f5ffb67886a85ec9791",
    "metadata": {},
    "name": "moreOptionsFilterDropdownContentInner_softwareFiltersPopup_Query",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "12f33acb2f2b054b2775040bfd553070";

export default node;
