import React, { useMemo, useCallback, Fragment } from 'react';
import type { EntryPointProps } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import {
	CalendarCapabilitiesProvider,
	defaultCapabilitiesContext,
	NONE,
} from '@atlassian/jira-calendar/src/common/controllers/capabilities-provider/index.tsx';
import { CalendarAnalyticsContext } from '@atlassian/jira-calendar/src/common/ui/calendar-analytics-context/index.tsx';
import {
	CalendarComponentsProvider,
	type MenuItem,
} from '@atlassian/jira-calendar/src/controllers/calendar-components-provider/index.tsx';
import type { CalendarSettings } from '@atlassian/jira-calendar/src/controllers/calendar-store/types.tsx';
import { GlobalIssueCreateContextProvider } from '@atlassian/jira-calendar/src/controllers/global-issue-create-provider/index.tsx';
import { CalendarJqlBuilderContextProvider } from '@atlassian/jira-calendar/src/controllers/jql-builder-context-provider/index.tsx';
import { CalendarUFOContextProvider } from '@atlassian/jira-calendar/src/controllers/ufo-context-provider/index.tsx';
import { CalendarPage, type CalendarPageProps } from '@atlassian/jira-calendar/src/index.tsx';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { EntryKeys } from '@atlassian/jira-feedback-collector/src/constants.tsx';
import { FeedbackCollectorButton } from '@atlassian/jira-feedback-collector/src/ui/button/index.tsx';
import type { FeedbackCollectorButtonProps } from '@atlassian/jira-feedback-collector/src/ui/button/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	TYPE,
	ASSIGNEE_FIELD_JQL_TERM,
	STATUS_FIELD_JQL_TERM,
} from '@atlassian/jira-jql-builder-basic/src/common/constants.tsx';
import type { IrremovableFieldsConfig } from '@atlassian/jira-jql-builder-basic/src/utils/irremovable-fields/index.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { useCalendarFilterResource } from '@atlassian/jira-router-resources-servicedesk-calendar/src/services/filters/index.tsx';
import { TARGET_FILTER_TYPE as REQUEST_TYPE_FILTER_TYPE } from '@atlassian/jira-router-resources-servicedesk-calendar/src/services/filters/utils.tsx';
import { uifBoardResource } from '@atlassian/jira-router-resources-uif-board/src/index.tsx';
import { APP_NAMES } from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import { JsdGettingStartedPanelShouldRender } from '@atlassian/jira-servicedesk-getting-started-panel/src/async.tsx';
import ServiceDeskAppBase from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/app-base/index.tsx';
import { ServiceDeskDocumentTitle } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/document-title/index.tsx';
import { useProjectContext } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/project-context/index.tsx';
import {
	useViewsRedirect,
	CALENDAR_VIEW_TYPE,
} from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/views-redirect/index.tsx';
import type { UIFBoardCachedDataResult } from '@atlassian/jira-software-uif-early-script/src/index.tsx';
import { MarkProductStart } from '@atlassian/jira-spa-performance-breakdown/src/utils/mark-product-start/index.tsx';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { usePathParam, useResource } from '@atlassian/react-resource-router';
import { UFO_SUFFIX_KEY, METRICS_PAGE_ID, TEAM_NAME } from './constants.tsx';
import messages from './messages.tsx';
import type { Queries, ExtraProps } from './types.tsx';

const disableCapability = {
	visible: false,
	canSchedule: false,
	modalType: NONE,
	canCreate: false,
	canHide: false,
};

type CalendarScreenAttributes = {
	projectType: ProjectType;
	synthetic: boolean;
	projectId: string;
};

export const useCalendarFilters = ({ isSimplified }: { isSimplified: boolean }) => {
	const { data: classicProjectFilters } = useCalendarFilterResource();
	const { formatMessage } = useIntl();

	// Translate the request type filter, as resource doesnot allow for i18n
	const classicProjectFiltersI18n = classicProjectFilters?.map((item) => {
		if (typeof item === 'object' && Object.values(item).includes(REQUEST_TYPE_FILTER_TYPE)) {
			return {
				...item,
				displayName: formatMessage(messages.requestType),
			};
		}

		return item;
	});

	const simplifiedProjectFilters: IrremovableFieldsConfig = useMemo(
		() => [
			{
				...TYPE,
				displayName: formatMessage(messages.requestType),
			},
			STATUS_FIELD_JQL_TERM,
			ASSIGNEE_FIELD_JQL_TERM,
		],
		[formatMessage],
	);

	return isSimplified ? simplifiedProjectFilters : classicProjectFiltersI18n;
};

export const CalendarLayout = ({
	queries: { calendarData },
	extraProps: {
		scope,
		viewRange,
		weekStartsOn,
		dayStartsAt,
		issueEventFields,
		selectedDate,
		projectKey,
		storageKeyForIssueSearchInput,
		storageKeyForViewSettings,
		showIssueKey,
		showWeekends,
		showIn24HourClock,
		colorBy,
	},
}: EntryPointProps<Queries, {}, {}, ExtraProps>) => {
	const projectContext = useProjectContext();
	const { appEditions } = useTenantContext();
	const { formatMessage } = useIntl();
	const irremovableFilters = useCalendarFilters({
		isSimplified: projectContext?.project.isSimplified ?? false,
	});
	const viewsRedirect = fg('jsm_views_of_work_phase_1')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useViewsRedirect({ viewType: CALENDAR_VIEW_TYPE })
		: null;

	const [paramBoardId] = usePathParam('boardId');

	const boardData = fg('jsd_shield_weekly_view_fast_follow')
		? // remove the next line when clean up fg('jsd_shield_weekly_view_fast_follow')
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useResource<UIFBoardCachedDataResult | null>(uifBoardResource)
		: { data: { result: { canEdit: true } } };
	const { canEdit } = boardData.data?.result ?? {};

	const additionalMenuItems: MenuItem[] = useMemo(
		() => [
			{
				title: formatMessage(messages.configureCalendarMenuItemTitle),
				href: `/jira/servicedesk/projects/${projectKey}/boards/${paramBoardId}/?config=filter`,
			},
		],
		[formatMessage, paramBoardId, projectKey],
	);

	const renderFeedbackButton = useCallback(
		({ feedbackContext, ...feedbackCollectorButtonProps }: FeedbackCollectorButtonProps) => (
			<FeedbackCollectorButton
				{...feedbackCollectorButtonProps}
				feedbackContext={[
					...(feedbackContext ?? []),
					{
						id: EntryKeys.PROJECT_TYPE,
						value: 'service_desk',
					},
					{
						id: EntryKeys.EDITION,
						value: appEditions.serviceDesk,
					},
					{
						id: EntryKeys.FEEDBACK_GROUP_ID,
						value: 'jsm.calendar',
					},
				]}
			/>
		),
		[appEditions.serviceDesk],
	);

	const onSettingsChanged = useCallback(
		(settings: CalendarSettings) => {
			localStorage.setItem(storageKeyForViewSettings, JSON.stringify(settings));
		},
		[storageKeyForViewSettings],
	);

	const onFiltered = useCallback(
		(query: string) => {
			localStorage.setItem(storageKeyForIssueSearchInput, query);
		},
		[storageKeyForIssueSearchInput],
	);

	if (projectContext == null) {
		return null;
	}

	// eslint-disable-next-line jira/ff/inline-usage
	const analyticsAttributes: CalendarScreenAttributes = {
		projectId: projectContext?.project.id,
		projectType: SERVICE_DESK_PROJECT,
		synthetic: fg('is_synthetic_tenant'),
	};

	const projectName = projectContext.project.name;
	const props: CalendarPageProps = {
		scope,
		selectedDate,
		viewRange,
		weekStartsOn,
		dayStartsAt,
		issueEventFields,
		calendarQueryRef: calendarData,
		onSettingsChanged,
		onFiltered,
		showWeekends,
		showIssueKey,
		showIn24HourClock,
		colorBy,
	};

	const BoxComponent = fg('jsm_views_of_work_phase_1') ? Box : Fragment;

	if (viewsRedirect) {
		return viewsRedirect;
	}

	// disable inline issue creation for JSM
	// eslint-disable-next-line jira/ff/inline-usage
	const issueEntityCapability = {
		...defaultCapabilitiesContext.issue,
		canCreate: !fg('disable_inline_issue_creation_in_calendar_for_jsm'),
	};

	const ContextualAnalyticsDataView = fg('jsm_views_of_work_phase_1')
		? ContextualAnalyticsData
		: Fragment;

	return (
		<UFOSegment name="jsm-calendar">
			<MarkProductStart />
			<ContextualAnalyticsDataView
				attributes={{
					...(fg('jsm_views_of_work_phase_1') && { isJsmMergedViews: true }),
				}}
			>
				<ServiceDeskAppBase appName={APP_NAMES.CALENDAR}>
					<ServiceDeskDocumentTitle projectName={projectName} section="calendar" />
					<GlobalIssueCreateContextProvider>
						<CalendarUFOContextProvider
							suffixKey={UFO_SUFFIX_KEY}
							packageName={METRICS_PAGE_ID}
							teamName={TEAM_NAME}
						>
							<CalendarCapabilitiesProvider
								sprint={disableCapability}
								version={disableCapability}
								hasShareButton
								irremovableFilters={irremovableFilters || []}
								hasHeader={!fg('jsm_views_of_work_phase_1')}
								accessibleIconColors
								weekViewEnabled={fg('jsd_shield_jsm_calendar_weekly_view')}
								issue={issueEntityCapability}
							>
								<CalendarAnalyticsContext sourceType={SCREEN} attributes={analyticsAttributes}>
									<CalendarComponentsProvider
										{...(!fg('jsm_views_of_work_phase_1') && {
											renderFeedbackButton,
											additionalMenuItems: canEdit ? additionalMenuItems : [],
										})}
									>
										<CalendarJqlBuilderContextProvider>
											<BoxComponent
												paddingInline={
													isVisualRefreshEnabled() && fg('jsm_views_of_work_phase_1')
														? 'space.300'
														: 'space.500'
												}
												paddingBlockStart={
													fg('jsm_views_of_work_phase_1')
														? (!isVisualRefreshEnabled() && 'space.150') || undefined
														: 'space.300'
												}
												xcss={
													isVisualRefreshEnabled() && fg('jsm_views_of_work_phase_1')
														? calendarWrapperStylesVisualRefresh
														: calendarWrapperStyles
												}
											>
												<CalendarPage
													key={
														fg('jsm_calendar_reset_state_on_page_change')
															? projectContext.project.id.toString()
															: undefined
													}
													{...props}
												/>
											</BoxComponent>
										</CalendarJqlBuilderContextProvider>
									</CalendarComponentsProvider>
								</CalendarAnalyticsContext>
							</CalendarCapabilitiesProvider>
						</CalendarUFOContextProvider>
					</GlobalIssueCreateContextProvider>
					<SpaStatePageReady />
				</ServiceDeskAppBase>
				<JsdGettingStartedPanelShouldRender />
			</ContextualAnalyticsDataView>
		</UFOSegment>
	);
};

const calendarWrapperStyles = xcss({
	flexGrow: 1,
	overflow: 'auto',
});

const calendarWrapperStylesVisualRefresh = xcss({
	flexGrow: 1,
	overflow: 'auto',
	marginTop: 'space.negative.025',
});
