/* eslint-disable react/no-find-dom-node */
import React, {
	Component,
	type ComponentType,
	type ElementRef,
	type ElementType,
	type ReactElement,
} from 'react';
import ReactDOM from 'react-dom';
import Button from '@atlaskit/button';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type {
	ASSIGNEE_TYPE as ASSIGNEE,
	CREATOR_TYPE as CREATOR,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	ASSIGNED_ISSUE_ACTION,
	VIEW_PROFILE_ACTION,
} from '@atlassian/jira-profilecard-next/src/common/constants.tsx';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { FieldComponentProps, FieldValue } from '../../../../model/fields/types.tsx';
import { SelectableChildGutterPaddingContainer } from '../../common/styled/index.tsx';

type State = {
	fieldVerticalOffset: number;
	fieldHorizontalOffset: number;
};

export type UserFieldType = typeof ASSIGNEE | typeof CREATOR;

export type Props<T> = {
	isSaving: boolean;
	// @ts-expect-error - TS2344 - Type 'T' does not satisfy the constraint 'keyof DataSelectorPropsMap'.
} & FieldComponentProps<T> & {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		UserSelect: ComponentType<any>;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		ProfileCard: ComponentType<any>;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		AssigneeButton: ComponentType<any>;
		onEditSelection: () => void;
	};

// eslint-disable-next-line jira/react/no-class-components
class SingleUserSelect<T extends UserFieldType> extends Component<Props<T>, State> {
	static defaultProps = {
		isEditable: true,
	};

	constructor(props: Props<T>) {
		super(props);
		this.state = {
			fieldVerticalOffset: 0,
			fieldHorizontalOffset: 0,
		};
	}

	componentDidUpdate(prevProps: Props<T>) {
		if (
			prevProps.width !== this.props.width &&
			this.props.isFieldLocked &&
			this.editingNode &&
			this.editingNode.offsetParent instanceof HTMLElement
		) {
			this.props.onSetEditingFieldPosition(
				this.editingNode.offsetLeft,
				this.editingNode.offsetTop,
				this.editingNode.offsetWidth,
				this.editingNode.offsetParent.offsetWidth,
				this.editingNode.offsetParent.offsetHeight,
			);
		}
	}

	componentWillUnmount() {
		this.node = null;
	}

	onEditStart = () => {
		const { onEditStart } = this.props;
		onEditStart && onEditStart();
	};

	onEditCancel = () => {
		const { onEditCancel } = this.props;
		onEditCancel && onEditCancel();
	};

	onSubmit: (fieldValue: FieldValue<T>) => void = (fieldValue: FieldValue<T>) => {
		const { onSubmit, value } = this.props;
		const isValueUnchanged =
			(value == null && fieldValue == null) ||
			(value && fieldValue && value.accountId === fieldValue.accountId);
		if (isValueUnchanged) {
			return;
		}
		onSubmit && onSubmit(fieldValue);
	};

	onSuccess: (fieldValue: FieldValue<T>) => void = (fieldValue: FieldValue<T>) => {
		const { onEditSuccess } = this.props;
		onEditSuccess && onEditSuccess(fieldValue);
	};

	onFailure = () => {
		const { onEditFailure } = this.props;
		onEditFailure && onEditFailure();
	};

	onMouseEnter = () => {
		if (this.node) {
			if (
				this.state.fieldVerticalOffset !== this.node.offsetTop ||
				this.state.fieldHorizontalOffset !== this.node.offsetLeft
			) {
				this.setState({
					fieldVerticalOffset: this.node.offsetTop,
					fieldHorizontalOffset: this.node.offsetLeft,
				});
			}
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSetRef = (ref: ElementRef<any> | null) => {
		if (ref && ref instanceof HTMLElement) {
			this.node = ref;
			if (
				(this.state.fieldVerticalOffset !== ref.offsetTop ||
					this.state.fieldHorizontalOffset !== ref.offsetLeft) &&
				ref.offsetParent instanceof HTMLElement
			) {
				this.setState({
					fieldVerticalOffset: ref.offsetTop,
					fieldHorizontalOffset: ref.offsetLeft,
				});
			}
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSetEditingRef = (ref: ElementRef<any> | null) => {
		// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'ReactInstance | null | undefined'.
		const node = ReactDOM.findDOMNode(ref);
		if (ref && node instanceof HTMLElement) {
			this.editingNode = node;
			const { onSetEditingFieldPosition } = this.props;
			const { fieldHorizontalOffset, fieldVerticalOffset } = this.state;
			this.setState({
				fieldHorizontalOffset,
				fieldVerticalOffset,
			});

			if (node.offsetParent instanceof HTMLElement) {
				onSetEditingFieldPosition(
					fieldHorizontalOffset,
					fieldVerticalOffset,
					node.offsetWidth,
					node.offsetParent.offsetWidth,
					node.offsetParent.offsetHeight,
				);
			}
		}
	};

	// @ts-expect-error - TS2564 - Property 'node' has no initializer and is not definitely assigned in the constructor.
	node: HTMLElement | null;

	// @ts-expect-error - TS2564 - Property 'editingNode' has no initializer and is not definitely assigned in the constructor.
	editingNode: HTMLElement | null;

	renderAssigneeButton: ElementType = () => {
		const {
			value,
			AssigneeButton,
			isUserPickerDisabled,
			isLastColumn,
			isEditable,
			overrideDisabledAppearance,
		} = this.props;

		const isButtonDisabled = !isEditable || isUserPickerDisabled;

		return (
			<AssigneeButton
				assignee={value}
				isButtonDisabled={isButtonDisabled}
				overrideDisabledAppearance={overrideDisabledAppearance}
				onClick={!isButtonDisabled ? this.onEditStart : undefined}
				onMouseEnter={this.onMouseEnter}
				isLastColumn={isLastColumn}
			/>
		);
	};

	renderFullLengthAvatar() {
		const { isEditable, issueKey, width, isLastColumn, value } = this.props;
		let assigneeButtonKey;
		if (value && value.accountId) {
			assigneeButtonKey = `${issueKey}-${isEditable.toString()}-${value.accountId}`;
		} else {
			assigneeButtonKey = `${issueKey}-${isEditable.toString()}`;
		}

		return (
			<SelectableChildGutterPaddingContainer isLastColumn={isLastColumn}>
				<div
					// eslint-disable-next-line jira/react/no-style-attribute
					style={{ width: width || 'auto' }}
					ref={this.onSetRef}
					data-testid={`issue-table.view.fields.common.single-user-select.${issueKey}`}
				>
					<Button key={assigneeButtonKey} component={this.renderAssigneeButton} />
				</div>
			</SelectableChildGutterPaddingContainer>
		);
	}

	renderWithProfileCard(element: ReactElement) {
		if (!this.props.value) {
			return null;
		}

		const { ProfileCard } = this.props;
		const { accountId } = this.props.value;

		return (
			<ProfileCard
				accountId={accountId}
				TenantContextSubscriber={
					fg('empanada_nin_concurrent_mode_fixes') ? undefined : TenantContextSubscriber
				}
				actions={[VIEW_PROFILE_ACTION, ASSIGNED_ISSUE_ACTION]}
			>
				{element}
			</ProfileCard>
		);
	}

	renderUserSelect() {
		const {
			value,
			width,
			fieldType,
			fieldId,
			issueKey,
			UserSelect,
			rowListRef,
			onSetEditingFieldPosition,
			onEditSelection,
			isSaving,
		} = this.props;

		return (
			<UserSelect
				issueKey={issueKey}
				fieldType={fieldType}
				fieldId={fieldId}
				width={width}
				value={value}
				onSubmit={this.onSubmit}
				onEditCancel={this.onEditCancel}
				onEditSuccess={this.onSuccess}
				onEditFailure={this.onFailure}
				onEditSelection={onEditSelection}
				onComponentLoadFailure={this.onEditCancel}
				rowListRef={rowListRef}
				onSetDropdownLocation={onSetEditingFieldPosition}
				ref={this.onSetEditingRef}
				isSaving={isSaving}
				onSetEditingRef={this.onSetEditingRef}
			/>
		);
	}

	render() {
		const { isFieldLocked } = this.props;

		if (isFieldLocked) {
			return this.renderUserSelect();
		}

		const avatarElement = this.renderFullLengthAvatar();
		if (!this.props.value || !this.props.value.accountId) {
			return avatarElement;
		}
		return this.renderWithProfileCard(avatarElement);
	}
}

// @ts-expect-error - Argument of type 'typeof SingleUserSelect' is not assignable to parameter of type 'ComponentType<WithIntlProvided<{ isSaving: boolean; } & FieldComponentProps<UserFieldType> & { UserSelect: ComponentType<any>; ProfileCard: ComponentType<any>; AssigneeButton: ComponentType<...>; onEditSelection: () => void; }>> & typeof SingleUserSelect'.
export default injectIntl(SingleUserSelect);
