import React, { useCallback, useState, type ReactElement, type ReactNode } from 'react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';
import { Text, Stack } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import CoordinationClient from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { scheduleAfterRender } from '@atlassian/jira-software-react-scheduler/src/index.tsx';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import {
	CALENDAR_VIEW_MESSAGE_ID,
	UNSCHEDULED_PANEL_TARGET,
	VIEW_SETTINGS_PANEL_TARGET,
} from './constants.tsx';
import messages from './messages.tsx';

type StopFunction = () => void;
type CoordinationStopProviderProps = {
	stop?: StopFunction;
	children: (arg1: StopFunction | undefined) => ReactElement;
};

export const CoordinationStopProvider = ({ stop, children }: CoordinationStopProviderProps) =>
	children(stop);

type Props = {
	onToggleUnscheduledPanelVisibility: () => void;
	onToggleViewSettingsPanelVisibility: () => void;
	isUnscheduledPanelOpen: boolean;
	isViewSettingsPanelOpen: boolean;
};

export const CalendarViewOnboarding = ({
	onToggleUnscheduledPanelVisibility,
	onToggleViewSettingsPanelVisibility,
	isUnscheduledPanelOpen,
	isViewSettingsPanelOpen,
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [activeSpotlight, setActiveSpotlight] = useState<number | null>(0);
	const next = useCallback(
		() => setActiveSpotlight((current) => (current !== null ? current + 1 : 0)),
		[],
	);
	const prev = useCallback(
		() => setActiveSpotlight((current) => (current !== null ? current - 1 : 0)),
		[],
	);
	const end = useCallback(() => setActiveSpotlight(null), []);

	// Picking which step we are rendering
	const renderSpotlight = useCallback(
		(stop?: StopFunction) => {
			if (activeSpotlight === 1 && !isViewSettingsPanelOpen) {
				end();
			}

			const numberOfSpotlights = 2;
			const spotlights = [
				<JiraSpotlight
					key={UNSCHEDULED_PANEL_TARGET}
					target={UNSCHEDULED_PANEL_TARGET}
					targetBgColor={token('elevation.surface', colors.N0)}
					actionsBeforeElement={`1/${numberOfSpotlights}`}
					actions={[
						{
							text: formatMessage(messages.nextStepButton),
							onClick: () => {
								!isViewSettingsPanelOpen && onToggleViewSettingsPanelVisibility();
								scheduleAfterRender(next);

								fireUIAnalytics(
									createAnalyticsEvent({}),
									'button clicked',
									'jswCalendarViewSpotlightNext',
								);
							},
						},
						{
							text: formatMessage(messages.dismissStepButton),
							appearance: 'subtle',
							onClick: () => {
								fireUIAnalytics(
									createAnalyticsEvent({}),
									'button clicked',
									'jswCalendarViewSpotlightDismiss',
								);

								end();
								stop && stop();
							},
						},
					]}
					targetRadius={3}
					dialogPlacement="left top"
					dialogWidth={275}
					messageId="calendar.common.ui.onboarding.jira-spotlight"
					messageType="engagement"
				>
					<Stack space="space.150">
						<Text as="p">
							{formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.unscheduledWorkOnboardingTitleIssueTermRefresh
									: messages.unscheduledWorkOnboardingTitle,
							)}
						</Text>
						<Text as="p">
							{formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.unscheduledWorkOnboardingDescriptionIssueTermRefresh
									: messages.unscheduledWorkOnboardingDescription,
							)}
						</Text>
					</Stack>
				</JiraSpotlight>,
				<JiraSpotlight
					key={VIEW_SETTINGS_PANEL_TARGET}
					target={VIEW_SETTINGS_PANEL_TARGET}
					targetBgColor={token('elevation.surface', colors.N0)}
					actionsBeforeElement={`2/${numberOfSpotlights}`}
					actions={[
						{
							text: formatMessage(messages.dismissStepButton),
							onClick: () => {
								isViewSettingsPanelOpen && onToggleViewSettingsPanelVisibility();

								// Fire analytics
								fireUIAnalytics(
									createAnalyticsEvent({}),
									'button clicked',
									'jswCalendarViewSpotlightDismiss',
								);

								end();
								stop && stop();
							},
						},
						{
							text: formatMessage(messages.backStepButton),
							appearance: 'subtle',
							onClick: () => {
								isViewSettingsPanelOpen && onToggleViewSettingsPanelVisibility();
								!isUnscheduledPanelOpen && onToggleUnscheduledPanelVisibility();
								// fire analytics
								fireUIAnalytics(
									createAnalyticsEvent({}),
									'button clicked',
									'jswCalendarViewSpotlightBack',
								);
								scheduleAfterRender(prev);
							},
						},
					]}
					targetRadius={3}
					dialogPlacement="bottom right"
					dialogWidth={275}
					messageId="calendar.common.ui.onboarding.jira-spotlight.1"
					messageType="engagement"
				>
					{formatMessage(messages.viewSettingsOnboardingDescription)}
				</JiraSpotlight>,
			];

			return activeSpotlight != null ? spotlights[activeSpotlight] : null;
		},
		[
			activeSpotlight,
			isViewSettingsPanelOpen,
			formatMessage,
			end,
			isUnscheduledPanelOpen,
			onToggleUnscheduledPanelVisibility,
			onToggleViewSettingsPanelVisibility,
			next,
			createAnalyticsEvent,
			prev,
		],
	);

	return activeSpotlight !== null ? (
		<CoordinationClient messageId={CALENDAR_VIEW_MESSAGE_ID} messageType="engagement">
			<CoordinationStopProvider>
				{(stop) => <SpotlightTransition>{renderSpotlight(stop)}</SpotlightTransition>}
			</CoordinationStopProvider>
		</CoordinationClient>
	) : null;
};

export const UnscheduledPanelSpotlightTarget = ({ children }: { children: ReactNode }) => (
	<>
		<SpotlightTarget name={UNSCHEDULED_PANEL_TARGET}>{children}</SpotlightTarget>
		<EngagementSpotlight engagementId={UNSCHEDULED_PANEL_TARGET} />
	</>
);
export const ViewSettingsPanelSpotlightTarget = ({ children }: { children: ReactNode }) => (
	<>
		<SpotlightTarget name={VIEW_SETTINGS_PANEL_TARGET}>{children}</SpotlightTarget>
		<EngagementSpotlight engagementId={VIEW_SETTINGS_PANEL_TARGET} />
	</>
);
