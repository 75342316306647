import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	titleLabel: {
		id: 'calendar.calendar-renderer.event-renderer.cross-project-version-renderer.flyout.title-label',
		defaultMessage: 'Cross project release',
		description: 'Label for the layout header',
	},
	closeButtonLabel: {
		id: 'calendar.calendar-renderer.event-renderer.cross-project-version-renderer.flyout.close-button-label',
		defaultMessage: 'Close the release layout',
		description: 'Label for the close button in the release layout',
	},
	releasedStatusLabel: {
		id: 'calendar.calendar-renderer.event-renderer.cross-project-version-renderer.flyout.released-status-label',
		defaultMessage: 'RELEASED',
		description: 'Label for the released status',
	},
	unreleasedStatusLabel: {
		id: 'calendar.calendar-renderer.event-renderer.cross-project-version-renderer.flyout.unreleased-status-label',
		defaultMessage: 'UNRELEASED',
		description: 'Label for the unreleased status',
	},
	overdueStatusLabel: {
		id: 'calendar.calendar-renderer.event-renderer.cross-project-version-renderer.flyout.overdue-status-label',
		defaultMessage: 'OVERDUE',
		description: 'Label for the overdue status',
	},
	overdueDaysLabel: {
		id: 'calendar.calendar-renderer.event-renderer.cross-project-version-renderer.flyout.overdue-days-label',
		defaultMessage:
			'{numberDaysOverdue} {numberDaysOverdue, plural, =1 {day} other {days}} overdue',
		description: 'Label for the number of days overdue',
	},
	startDateLabel: {
		id: 'calendar.calendar-renderer.event-renderer.cross-project-version-renderer.flyout.start-date-label',
		defaultMessage: 'Start date',
		description: 'Label for the start date',
	},
	releaseDateLabel: {
		id: 'calendar.calendar-renderer.event-renderer.cross-project-version-renderer.flyout.release-date-label',
		defaultMessage: 'Release date',
		description: 'Label for the release date',
	},
	projectLabel: {
		id: 'calendar.calendar-renderer.event-renderer.cross-project-version-renderer.flyout.project-label',
		defaultMessage: 'Projects',
		description: 'Label for the projects',
	},
});
