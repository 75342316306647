import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	menuButton: {
		id: 'release-flyout.actions-menu.menu-button',
		defaultMessage: 'more actions menu button',
		description: 'More actions menu button in the dropdown for editing this version',
	},
	viewRelease: {
		id: 'release-flyout.actions-menu.view-release',
		defaultMessage: 'View release in project',
		description: 'The dropdown option text of viewing the details of this release',
	},
	filterIssuesByRelease: {
		id: 'release-flyout.actions-menu.filter-issues-by-release',
		defaultMessage: 'Filter issues by this release',
		description: 'The dropdown option text of filtering issues by this release',
	},
	editRelease: {
		id: 'release-flyout.actions-menu.edit-release',
		defaultMessage: 'Edit release',
		description: 'The dropdown option text of editing this release',
	},
	filterIssuesByReleaseIssueTermRefresh: {
		id: 'release-flyout.actions-menu.filter-issues-by-release-issue-term-refresh',
		defaultMessage: 'Filter by this release',
		description: 'The dropdown option text of filtering issues by this release',
	},
});
