import React, { memo } from 'react';
import { useCalendarIssueCreated } from './on-calendar-issue-created/index.tsx';
import { useCalendarIssueDeleted } from './on-calendar-issue-deleted/index.tsx';
import { useCalendarIssueUpdated } from './on-calendar-issue-updated/index.tsx';
import type { SubscriptionProps } from './types.tsx';

const SubscribeToProjectUpdates = (props: SubscriptionProps) => {
	useCalendarIssueDeleted(props);
	useCalendarIssueCreated(props);
	useCalendarIssueUpdated(props);
	return null;
};

export const SubscribeToIssueUpdates = memo(
	({ projectIds, ...rest }: Omit<SubscriptionProps, 'projectId'> & { projectIds: string[] }) => {
		return (
			<>
				{projectIds.map((pid) => (
					<SubscribeToProjectUpdates key={pid} projectId={pid} {...rest} />
				))}
			</>
		);
	},
);
