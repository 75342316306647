import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	searchPlaceholder: {
		id: 'software-filters-popup.filter-section.filter-options.more-options-filter-dropdown-content.search-placeholder',
		defaultMessage: 'Search',
		description:
			'Search placeholder for searching field values on the filter popup mor options dropdown',
	},
});
