/**
 * @generated SignedSource<<e762ab79685b31e0e735af6881f145be>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type projectFieldValueLabel_softwareFiltersPopup$data = {
  readonly project: {
    readonly avatar: {
      readonly xsmall: string | null | undefined;
    } | null | undefined;
    readonly name: string;
  };
  readonly " $fragmentType": "projectFieldValueLabel_softwareFiltersPopup";
};
export type projectFieldValueLabel_softwareFiltersPopup$key = {
  readonly " $data"?: projectFieldValueLabel_softwareFiltersPopup$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectFieldValueLabel_softwareFiltersPopup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "projectFieldValueLabel_softwareFiltersPopup",
  "selections": [
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraAvatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "xsmall"
            }
          ]
        },
        {
          "kind": "ScalarField",
          "name": "name"
        }
      ]
    }
  ],
  "type": "JiraJqlProjectFieldValue"
};

(node as any).hash = "23e87391b93f61d268922ff94c746dae";

export default node;
