export class MoreLinkPopoverBinder {
	private currentDayCellDate: string | null = null;

	registerMoreLinkClick = (dayCellElement: HTMLElement) => {
		const onClickMoreLink = (e: MouseEvent) => {
			if (e.target instanceof HTMLElement && e.target.closest('.fc-more-link')) {
				const dayCellDate = dayCellElement.getAttribute('data-date');
				if (dayCellDate) {
					this.currentDayCellDate = dayCellDate;
				}
			}
		};
		dayCellElement.addEventListener('mousedown', onClickMoreLink);

		return () => dayCellElement.removeEventListener('mousedown', onClickMoreLink);
	};

	getCurrentDayCellDate = () => this.currentDayCellDate;
}

export const moreLinkPopoverBinder = new MoreLinkPopoverBinder();
