import React, {
	useEffect,
	useMemo,
	useRef,
	useState,
	useCallback,
	type MutableRefObject,
} from 'react';
import { styled } from '@compiled/react';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import {
	graphql,
	usePaginationFragment,
	fetchQuery,
	useRelayEnvironment,
	useFragment,
} from 'react-relay';
import Button from '@atlaskit/button';
import ChevronDescIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronAscIcon from '@atlaskit/icon/glyph/chevron-up';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { setInteractionError } from '@atlaskit/react-ufo/set-interaction-error';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import { B200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { LoadingMoreSpinner } from '@atlassian/jira-business-load-more-spinner/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useRectObserver } from '@atlassian/jira-react-use-resize-observer/src/index.tsx';
import type { calendarUnscheduledPanelCardList_calendar_CalendarUnscheduledPanelCardListInner_queryRef$key } from '@atlassian/jira-relay/src/__generated__/calendarUnscheduledPanelCardList_calendar_CalendarUnscheduledPanelCardListInner_queryRef.graphql';
import type { calendarUnscheduledPanelCardList_calendar_queryRef$key } from '@atlassian/jira-relay/src/__generated__/calendarUnscheduledPanelCardList_calendar_queryRef.graphql';
import REFRESH_QUERY, {
	type calendarUnscheduledPanelCardList_calendar_RefetchPaginated_Query,
} from '@atlassian/jira-relay/src/__generated__/calendarUnscheduledPanelCardList_calendar_RefetchPaginated_Query.graphql';
import type {
	JiraViewScopeInput,
	JiraCalendarViewConfigurationInput,
	JiraCalendarIssuesInput,
} from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery.graphql';
import { ScrollStateContextProvider } from '@atlassian/jira-software-fast-virtual/src/services/use-scroll-state/index.tsx';
import { useVirtual } from '@atlassian/jira-software-fast-virtual/src/services/use-virtual/index.tsx';
import type { JQLModel } from '@atlassian/jira-software-filters-popup/src/common/utils/jql-model/index.tsx';
import { FilterPopupButton } from '@atlassian/jira-software-filters-popup/src/index.tsx';
import { PaginationTrigger } from '@atlassian/jira-software-pagination-trigger/src/index.tsx';
import {
	ANALYTICS_ACTION_ISSUE_SUBJECT,
	ANALYTICS_ACTION_SCHEDULED,
	ANALYTICS_SOURCE,
	UI_EVENT_DROPPED,
} from '../../../common/constants.tsx';
import {
	useSortDirection,
	useCalendarActions,
	usePanelExtraFilters,
	useDraggingEvent,
	useViewRange,
} from '../../../controllers/calendar-store/index.tsx';
import { useConnectionActions } from '../../../controllers/connection-store/index.tsx';
import { useCalendarConfigurationInput } from '../../../controllers/use-calendar-configuration-input/index.tsx';
import { useCalendarExperience } from '../../../controllers/use-calendar-experience/index.tsx';
import { useCalendarScopeInput } from '../../../controllers/use-calendar-scope-input/index.tsx';
import { useLogger } from '../../../controllers/use-logger/index.tsx';
import { useScheduleCalendarIssueMutation } from '../../../controllers/use-schedule-calendar-issue-mutation/index.tsx';
import { useUnscheduledIssuesSearchInput } from '../../../controllers/use-unscheduled-issues-search-input/index.tsx';
import { isDraggableCalendarIssue } from '../../../services/draggable-card-type/index.tsx';
import { CalendarUnscheduledPanelCard } from './calendar-unscheduled-panel-card/index.tsx';
import { CalendarUnscheduledPanelEmptyState } from './calendar-unscheduled-panel-empty-state/index.tsx';
import { CalendarUnscheduledPanelRow } from './calendar-unscheduled-panel-row/index.tsx';
import { messages } from './messages.tsx';
import { useRefetchOnGlobalIssueCreate } from './use-refetch-on-global-issue-create/index.tsx';

/**
 * Refetch the data whenever search input changes; but skip the first mount update.
 */
function useAutoRefetchPanelData({
	refetch,
}: {
	refetch: (params: {
		scopeInput: JiraViewScopeInput;
		configurationInput: JiraCalendarViewConfigurationInput;
		unscheduledIssuesSearchInput: JiraCalendarIssuesInput;
	}) => void;
}) {
	const sortDirection = useSortDirection();
	const { scopeInput } = useCalendarScopeInput();
	const { configurationInput } = useCalendarConfigurationInput();
	const { unscheduledIssuesSearchInput } = useUnscheduledIssuesSearchInput();
	const isMounted = useRef(false);
	const lastFetchedInput = useRef<null | JiraCalendarIssuesInput>(null);
	useEffect(() => {
		if (!isMounted.current) {
			isMounted.current = true;
			lastFetchedInput.current = unscheduledIssuesSearchInput;
			return;
		}

		if (isEqual(lastFetchedInput.current, unscheduledIssuesSearchInput)) {
			return;
		}

		lastFetchedInput.current = unscheduledIssuesSearchInput;
		refetch({
			scopeInput,
			configurationInput,
			unscheduledIssuesSearchInput,
		});
	}, [refetch, sortDirection, scopeInput, configurationInput, unscheduledIssuesSearchInput]);
}

interface CalendarUnscheduledPanelCardListProps {
	scrollRef: MutableRefObject<HTMLDivElement | null>;
	queryRef: calendarUnscheduledPanelCardList_calendar_CalendarUnscheduledPanelCardListInner_queryRef$key;
	jqlBuilderQuery?: string;
}

function CalendarUnscheduledPanelCardListInner({
	queryRef,
	jqlBuilderQuery,
	scrollRef,
}: CalendarUnscheduledPanelCardListProps) {
	const { data, loadNext, hasNext, isLoadingNext, refetch } = usePaginationFragment<
		calendarUnscheduledPanelCardList_calendar_RefetchPaginated_Query,
		calendarUnscheduledPanelCardList_calendar_CalendarUnscheduledPanelCardListInner_queryRef$key
	>(
		graphql`
			fragment calendarUnscheduledPanelCardList_calendar_CalendarUnscheduledPanelCardListInner_queryRef on Query
			@argumentDefinitions(
				cursor: { type: "String" }
				first: { type: "Int" }
				scopeInput: { type: "JiraViewScopeInput!" }
				configurationInput: { type: "JiraCalendarViewConfigurationInput!" }
				unscheduledIssuesSearchInput: { type: "JiraCalendarIssuesInput" }
				viewId: { type: "ID" }
				schedulePermissionsEnabled: { type: "Boolean!" }
			)
			@refetchable(queryName: "calendarUnscheduledPanelCardList_calendar_RefetchPaginated_Query") {
				panelJira: jira {
					jiraCalendar(scope: $scopeInput, configuration: $configurationInput)
						@optIn(to: "JiraCalendar") {
						unscheduledIssues(input: $unscheduledIssuesSearchInput, first: $first, after: $cursor)
							@optIn(to: "JiraFieldConnectionErrors")
							@connection(key: "calendarUnscheduledPanelCardList_calendar__unscheduledIssues") {
							__id

							edges {
								node {
									id
									...calendarUnscheduledPanelCard_calendar
										@arguments(
											viewId: $viewId
											schedulePermissionsEnabled: $schedulePermissionsEnabled
										)
								}
							}
							errors {
								identifier
								message
								extensions {
									statusCode
									errorType
								}
							}
						}
					}
				}
			}
		`,
		queryRef,
	);

	// This is needed because the issue search API is returning sometimes empty cursors and such;
	// duplicated keys cause warnings/errors we want to avoid.
	const edges = useMemo(
		() =>
			uniqBy(
				data?.panelJira?.jiraCalendar?.unscheduledIssues?.edges,
				(edge) => edge?.node?.id,
			).filter((edge) => edge?.node != null),
		[data],
	);

	const loadError = useMemo(() => {
		const err = data?.panelJira?.jiraCalendar?.unscheduledIssues?.errors;
		if (!err || err.length === 0) return null;

		return {
			message: err[0].message || '',
			name: err[0].identifier || 'calendar.unscheduled-panel-load',
			extensions: [
				err[0].extensions?.map((extension) => ({
					statusCode: extension?.statusCode,
					errorType: extension?.errorType,
				})),
			],
		};
	}, [data]);

	const { formatMessage } = useIntl();
	const sortDirection = useSortDirection();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const viewRange = fg('jsd_shield_jsm_calendar_weekly_view') ? useViewRange() : 'month';
	const {
		togglePanelSortDirection,
		setPanelExtraFilters,
		setPanelIsLoading,
		toggleUnscheduledPanelFilterVisibility,
	} = useCalendarActions();

	const { setUnscheduledPanelConnectionId } = useConnectionActions();

	useEffect(() => {
		if (!fg('calendar_update_on_issue_modal_schedule')) return;
		setUnscheduledPanelConnectionId(data?.panelJira?.jiraCalendar?.unscheduledIssues?.__id);
	}, [data, setUnscheduledPanelConnectionId]);

	const onClickTogglePanelSortDirection = useCallback(() => {
		togglePanelSortDirection();
	}, [togglePanelSortDirection]);

	const onFilterCloseOpen = useCallback(() => {
		toggleUnscheduledPanelFilterVisibility();
	}, [toggleUnscheduledPanelFilterVisibility]);

	const environment = useRelayEnvironment();
	const { refetchUnscheduledPanelListExperience, viewUnscheduledPanelListExperience } =
		useCalendarExperience();

	const { logError } = useLogger();

	useEffect(() => {
		viewUnscheduledPanelListExperience.start();
	}, [viewUnscheduledPanelListExperience]);

	useEffect(() => {
		if (!loadError) {
			viewUnscheduledPanelListExperience.success();
			return;
		}

		viewUnscheduledPanelListExperience.failure();

		logError(
			'load.calendar.unscheduled-panel.failure',
			'Failed to load unschedule panel list calendar data',
			loadError,
		);
	}, [loadError, logError, viewUnscheduledPanelListExperience]);

	const refetchWithoutSuspense = useCallback(
		({
			scopeInput,
			configurationInput,
			unscheduledIssuesSearchInput,
		}: {
			scopeInput: JiraViewScopeInput;
			configurationInput: JiraCalendarViewConfigurationInput;
			unscheduledIssuesSearchInput: JiraCalendarIssuesInput;
		}) => {
			setPanelIsLoading(true);

			refetchUnscheduledPanelListExperience.start();

			fetchQuery<calendarUnscheduledPanelCardList_calendar_RefetchPaginated_Query>(
				environment,
				REFRESH_QUERY,
				{
					scopeInput,
					configurationInput,
					unscheduledIssuesSearchInput,
					schedulePermissionsEnabled: fg('calendar_schedule_permissions_enabled'),
				},
				{
					fetchPolicy: 'network-only',
				},
			).subscribe({
				complete() {
					refetch(
						{
							scopeInput,
							configurationInput,
							unscheduledIssuesSearchInput,
						},
						{ fetchPolicy: 'store-only' },
					);

					setPanelIsLoading(false);

					refetchUnscheduledPanelListExperience.success();
				},
				error(error: Error) {
					setPanelIsLoading(false);

					setInteractionError(refetchUnscheduledPanelListExperience.id, {
						name: 'refetch.calendar.unscheduled-panel.failure',
						errorMessage: `Failed to refetch unschedule panel list calendar data. Error: ${error}`,
					});

					refetchUnscheduledPanelListExperience.failure();

					logError(
						'refetch.calendar.unscheduled-panel.failure',
						'Failed to refetch unschedule panel list calendar data',
						error,
					);
				},
			});
		},
		[environment, refetch, refetchUnscheduledPanelListExperience, setPanelIsLoading, logError],
	);

	useAutoRefetchPanelData({ refetch: refetchWithoutSuspense });

	useRefetchOnGlobalIssueCreate({ refetch: refetchWithoutSuspense });

	const measurementCache = useMemo(() => new Map(), []);
	const { rows, totalSize, reinitialize } = useVirtual({
		cacheOptions: {
			getCacheKey(index) {
				return edges[index]?.node?.id;
			},
			cache: measurementCache,
		},
		getDefaultRowSize() {
			return 68;
		},
		overscanHeight: 800,
		rowCount: edges.length,
	});
	// Re-initialize when the edges change.
	useEffect(() => {
		if (!edges.length) return;
		reinitialize();
	}, [edges, reinitialize]);
	const listContainerRef = useRef<HTMLDivElement>(null);
	const rect = useRectObserver({ ref: listContainerRef });
	const onChangeFilters = useCallback(
		(_jql: string, jqlModel: JQLModel) => {
			setPanelExtraFilters(jqlModel);
		},
		[setPanelExtraFilters],
	);
	const extraFilters = usePanelExtraFilters();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const draggingEvent = useDraggingEvent();
	const isDraggingIssue = useMemo(() => draggingEvent?.type === 'issue', [draggingEvent?.type]);
	const [isDroppingActive, setIsDroppingActive] = useState(false);

	const { scopeInput } = useCalendarScopeInput();
	const { configurationInput } = useCalendarConfigurationInput();
	const { unscheduledIssuesSearchInput } = useUnscheduledIssuesSearchInput();
	const { scheduleCalendarIssue } = useScheduleCalendarIssueMutation({
		// explicitly not set the connectionId here, as we only want to refresh the panel list instead of optimistically adding the new item
		connectionId: undefined,
	});

	const isEmpty = edges.length === 0;

	useEffect(() => {
		if (!scrollRef?.current) return;

		return dropTargetForElements({
			element: scrollRef.current,
			canDrop: ({ source }) => isDraggableCalendarIssue(source.data),
			onDragEnter: () => {
				setIsDroppingActive(true);
			},
			onDragLeave: () => {
				setIsDroppingActive(false);
			},
			onDrop: async (args: { source: { data: Record<string, unknown> } }) => {
				const { data: draggingData } = args.source;
				if (isDraggableCalendarIssue(draggingData) && draggingData.source === 'calendar') {
					scheduleCalendarIssue({
						issueAri: draggingData.issueAri,
						issueRecordId: draggingData.issueRecordId,
						startDateFieldRecordId: draggingData.startDateFieldRecordId,
						endDateFieldRecordId: draggingData.endDateFieldRecordId,
						startDate: draggingData.startDate ? new Date(draggingData.startDate) : undefined,
						removeCardFromConnectionId: draggingData.connectionRecordId,
						endDate: undefined,
						issueKey: draggingData.issueKey,
						canSchedule: draggingData.canSchedule,
					});

					fireUIAnalytics(
						createAnalyticsEvent({
							action: ANALYTICS_ACTION_SCHEDULED,
							actionSubject: ANALYTICS_ACTION_ISSUE_SUBJECT,
						}),
						ANALYTICS_SOURCE,
						{
							issueSource: draggingData.source,
							calendarEvent: UI_EVENT_DROPPED,
							isUnscheduled: true,
							viewRange,
						},
					);

					refetchWithoutSuspense({
						scopeInput,
						configurationInput,
						unscheduledIssuesSearchInput,
					});
				}
			},
		});
	}, [
		configurationInput,
		scheduleCalendarIssue,
		refetchWithoutSuspense,
		scopeInput,
		scrollRef,
		unscheduledIssuesSearchInput,
		createAnalyticsEvent,
		viewRange,
	]);

	return (
		<>
			<Inline space="space.050" spread="space-between">
				<Inline>
					<Button appearance="subtle" onClick={onClickTogglePanelSortDirection}>
						<Inline space="space.050" alignBlock="center">
							{formatMessage(messages.mostRecent)}
							{sortDirection === 'ASC' ? (
								<ChevronAscIcon size="medium" label={formatMessage(messages.sortingDirectionAsc)} />
							) : (
								<ChevronDescIcon
									size="medium"
									label={formatMessage(messages.sortingDirectionDesc)}
								/>
							)}
						</Inline>
					</Button>
				</Inline>

				<Inline>
					<FilterPopupButton
						onChange={onChangeFilters}
						jqlBuilderQuery={jqlBuilderQuery}
						initialValue={extraFilters}
						onOpen={onFilterCloseOpen}
						onClose={onFilterCloseOpen}
					/>
				</Inline>
			</Inline>
			<Box
				xcss={[
					boxStyles,
					isEmpty && emptyBoxStyles,
					isDraggingIssue && boxDroppingStyles,
					isDroppingActive && boxDroppingActiveStyles,
				]}
				ref={scrollRef}
				testId="calendar.ui.calendar-unscheduled-panel.calendar-unscheduled-panel-card-list.container"
			>
				{isEmpty && <CalendarUnscheduledPanelEmptyState />}
				<ListContainer
					ref={listContainerRef}
					// eslint-disable-next-line jira/react/no-style-attribute
					style={{
						height: totalSize,
					}}
				>
					{rows?.map((row) => {
						const edge = edges[row.index];

						return (
							edge?.node && (
								<CalendarUnscheduledPanelRow
									top={row.top}
									width={rect?.width ?? 300}
									forceRemeasure={row.forceRemeasure}
									measure={row.measure}
									key={edge.node.id}
								>
									<CalendarUnscheduledPanelCard
										issueRef={edge.node}
										connectionRecordId={data?.panelJira?.jiraCalendar?.unscheduledIssues?.__id}
									/>
								</CalendarUnscheduledPanelRow>
							)
						);
					})}
				</ListContainer>

				<PaginationTrigger
					pageSize={50}
					hasNextPage={hasNext}
					isLoadingNext={isLoadingNext}
					loadNext={loadNext}
				/>

				<LoadingRepositionHack>
					{/* TODO: Currently this is misaligned due to the padding of the parent element
            not being considered on this element's width. */}
					<LoadingMoreSpinner
						isLoading={isLoadingNext}
						loadingMessage={formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.loadingMoreMessageIssueTermRefresh
								: messages.loadingMoreMessage,
						)}
					/>
				</LoadingRepositionHack>
			</Box>
		</>
	);
}

export function CalendarUnscheduledPanelCardList({
	queryRef,
	jqlBuilderQuery,
}: {
	queryRef: calendarUnscheduledPanelCardList_calendar_queryRef$key;
	jqlBuilderQuery?: string;
}) {
	const data = useFragment(
		graphql`
			fragment calendarUnscheduledPanelCardList_calendar_queryRef on Query
			@argumentDefinitions(
				cursor: { type: "String" }
				first: { type: "Int" }
				scopeInput: { type: "JiraViewScopeInput!" }
				configurationInput: { type: "JiraCalendarViewConfigurationInput!" }
				unscheduledIssuesSearchInput: { type: "JiraCalendarIssuesInput" }
				viewId: { type: "ID" }
				schedulePermissionsEnabled: { type: "Boolean!" }
			) {
				...calendarUnscheduledPanelCardList_calendar_CalendarUnscheduledPanelCardListInner_queryRef
					@arguments(
						cursor: $cursor
						first: $first
						scopeInput: $scopeInput
						configurationInput: $configurationInput
						unscheduledIssuesSearchInput: $unscheduledIssuesSearchInput
						viewId: $viewId
						schedulePermissionsEnabled: $schedulePermissionsEnabled
					)
			}
		`,
		queryRef,
	);

	const ref = useRef<HTMLDivElement>(null);

	return (
		<ScrollStateContextProvider scrollRef={ref}>
			<Stack xcss={listContainerStyles} space="space.100" grow="fill">
				<CalendarUnscheduledPanelCardListInner
					scrollRef={ref}
					queryRef={data}
					jqlBuilderQuery={jqlBuilderQuery}
				/>
			</Stack>
		</ScrollStateContextProvider>
	);
}

export const CalendarUnscheduledPanelCardListFallback = () => (
	<Stack
		xcss={listContainerStyles}
		space="space.100"
		grow="fill"
		alignBlock="center"
		alignInline="center"
	>
		<Spinner />
	</Stack>
);

const listContainerStyles = xcss({
	borderRadius: 'border.radius.100',
	background: token('color.background.accent.gray.subtlest', '#f4f5f7'),
	padding: 'space.100',
	overflow: 'hidden',
	height: '100%',
});

const boxDroppingStyles = xcss({
	position: 'relative',
	'::after': {
		content: '""',
		position: 'absolute',
		inset: 'space.0',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		background: token('color.background.selected', colors.B50),
		opacity: 0.25,
	},
});

const boxDroppingActiveStyles = xcss({
	outline: `2px solid ${token('color.border.focused', B200)}`,
	outlineOffset: 'space.025',
	'::after': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		background: token('color.background.selected.hovered', colors.B75),
		opacity: 0.5,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ListContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LoadingRepositionHack = styled.div({
	position: 'relative',

	left: `calc(-${token('space.250', '24px')})`,
});

const boxStyles = xcss({
	overflowY: 'scroll',
	height: '100%',
	overflowX: 'hidden',
});

const emptyBoxStyles = xcss({
	overflowY: 'hidden',
	border: `2px dashed ${token('color.border', '#dfe1e6')}`,
	borderRadius: '3px',
});
