import React, { type ReactNode } from 'react';
import Button from '@atlaskit/button';
import type { JQLModel } from '../../utils/jql-model/index.tsx';

+14;
export interface FilterButtonProps {
	/**
	 * The JQL model representing filter state
	 */
	jqlModel: JQLModel;
	/**
	 * The jqlTerm for this filter option
	 */
	optionValue: string | null | undefined;
	/**
	 * The field name for this filter option
	 */
	fieldName: string;
	/**
	 * Contents of the filter button
	 */
	children: ReactNode;
	/**
	 * Called when the filter button is clicked.
	 */
	onToggleFilter: (fieldName: string, value: string) => void;
}

/**
 * A button that toggles a filter value on or off, rendered on the filter popup.
 */
export function FilterButton({
	jqlModel,
	fieldName,
	optionValue,
	onToggleFilter,
	children,
}: FilterButtonProps) {
	const isSelected =
		(optionValue && jqlModel.whereClauses[fieldName]?.includes(optionValue)) || false;

	return (
		<Button
			spacing="none"
			testId="software-filters-popup.common.ui.filter-button.button"
			isSelected={isSelected}
			onClick={() => {
				if (!optionValue) return;
				onToggleFilter(fieldName, optionValue);
			}}
			appearance="subtle"
			role="checkbox"
			aria-checked={isSelected}
		>
			{children}
		</Button>
	);
}
