import type { JQLModel } from '@atlassian/jira-software-filters-popup/src/common/utils/jql-model/index.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type {
	CalendarColorBy,
	CalendarDayStart,
	CalendarStoreState,
	CalendarViewRange,
	DraggingEvent,
} from './types.tsx';
import { withReadyCheck, dateRangeForDate } from './utils.tsx';

/**
 * Sets the "extra JQL query" to filter issues by. This is combined with the "base query" or
 * "base context" of the page's search. This is provided by the JQL builder component.
 */
export function setExtraQuery(extraQuery: string): Action<CalendarStoreState> {
	return ({ setState }) => {
		setState({
			extraQuery,
		});
	};
}

/**
 * Updates the visible date range to be the month around a certain date.
 */
export function setDateRange({ currentDate }: { currentDate: Date }): Action<CalendarStoreState> {
	return ({ setState }) => {
		setState({
			dateRange: dateRangeForDate(currentDate),
		});
	};
}

/**
 * Open and close the "unscheduled issues panel"
 */
export function toggleUnscheduledPanelVisibility(): Action<CalendarStoreState> {
	return withReadyCheck(({ setState, getState }) => {
		const state = getState();
		setState({
			unscheduledPanelState: {
				...state.unscheduledPanelState,
				isOpen: !state.unscheduledPanelState.isOpen,
			},
		});
	});
}

/**
 * Set the visibility of the unscheduled panel.
 */
export function setUnscheduledPanelVisibility(isVisible: boolean): Action<CalendarStoreState> {
	return withReadyCheck(({ setState, getState }) => {
		const state = getState();
		setState({
			unscheduledPanelState: {
				...state.unscheduledPanelState,
				isOpen: isVisible,
			},
		});
	});
}

/**
 * Open and close the filter option under unscheduled issues panel
 */
export function toggleUnscheduledPanelFilterVisibility(): Action<CalendarStoreState> {
	return withReadyCheck(({ setState, getState }) => {
		const state = getState();
		setState({
			unscheduledPanelState: {
				...state.unscheduledPanelState,
				isFilterOpen: !state.unscheduledPanelState.isFilterOpen,
			},
		});
	});
}

/**
 * Set the visibility of the issue view within the unscheduled panel.
 */
export function setUnscheduledPanelIssueViewVisibility(
	isIssueViewOpen: boolean,
): Action<CalendarStoreState> {
	return withReadyCheck(({ setState, getState }) => {
		const state = getState();

		setState({
			unscheduledPanelState: {
				...state.unscheduledPanelState,
				isIssueViewOpen,
			},
		});
	});
}

/**
 * Update the unscheduled issues panel search string.
 *
 * When this changes, the panel is responsible for refetching the query.
 */
export function setPanelSearchString(search: string): Action<CalendarStoreState> {
	return withReadyCheck(({ setState, getState }) => {
		const state = getState();

		setState({
			unscheduledPanelState: {
				...state.unscheduledPanelState,
				search,
			},
		});
	});
}

export function setPanelExtraFilters(jql: JQLModel | null): Action<CalendarStoreState> {
	return withReadyCheck(({ setState, getState }) => {
		const state = getState();

		setState({
			unscheduledPanelState: {
				...state.unscheduledPanelState,
				extraFilters: jql,
			},
		});
	});
}

/**
 * Update the unscheduled issues panel sorting order.
 *
 * When this changes, the panel is responsible for refetching the query.
 */
export function togglePanelSortDirection(): Action<CalendarStoreState> {
	return withReadyCheck(({ setState, getState }) => {
		const state = getState();
		const sortDirection = state.unscheduledPanelState.sortDirection === 'ASC' ? 'DESC' : 'ASC';

		setState({
			unscheduledPanelState: {
				...state.unscheduledPanelState,
				sortDirection,
			},
		});
	});
}

/**
 * Show or hide versions/releases from the calendar.
 */
export function toggleShowReleases(): Action<CalendarStoreState> {
	return withReadyCheck(({ setState, getState }) => {
		const state = getState();

		setState({
			showReleases: !state.showReleases,
		});
	});
}

/**
 * Show or hide sprints from the calendar.
 */
export function toggleShowSprints(): Action<CalendarStoreState> {
	return withReadyCheck(({ setState, getState }) => {
		const state = getState();

		setState({
			showSprints: !state.showSprints,
		});
	});
}
/**
 * Show or hide issues from the calendar.
 */
export function toggleShowIssues(): Action<CalendarStoreState> {
	return withReadyCheck(({ setState, getState }) => {
		const state = getState();

		setState({
			showIssues: !state.showIssues,
		});
	});
}

/**
 * Change the first day of the week.
 */
export function setWeekStartsOn(
	weekStartsOn: 'monday' | 'sunday' | 'saturday',
): Action<CalendarStoreState> {
	return withReadyCheck(({ setState }) => {
		setState({
			weekStartsOn,
		});
	});
}

/**
 * Change the start time of the day.
 */
export function setDayStartsAt(dayStartsAt: CalendarDayStart): Action<CalendarStoreState> {
	return withReadyCheck(({ setState }) => {
		setState({
			dayStartsAt,
		});
	});
}

/**
 * Change the issue color settings.
 */
export function setColorByValue(colorBy: CalendarColorBy): Action<CalendarStoreState> {
	return withReadyCheck(({ setState }) => {
		setState({
			colorBy,
		});
	});
}

/**
 * Update the current entity being dragged. This will be used to:
 *
 * * Update the event element to represent that it's dragged
 * * Update pointer-events on other elements to prevent them from being drop-zones
 */
export function setDraggingEvent(draggingEvent: DraggingEvent | null): Action<CalendarStoreState> {
	return withReadyCheck(({ setState }) => {
		setState({
			draggingEvent,
		});
	});
}

/**
 * Change the loading state.
 */
export function setIsLoading(isLoading: boolean): Action<CalendarStoreState> {
	return withReadyCheck(({ setState }) => {
		setState({
			isLoading,
		});
	});
}

export function setPanelIsLoading(isLoading: boolean): Action<CalendarStoreState> {
	return withReadyCheck(({ setState, getState }) => {
		const state = getState();

		setState({
			unscheduledPanelState: {
				...state.unscheduledPanelState,
				isLoading,
			},
		});
	});
}

/**
 * Open and close the "view settings" panel
 */
export function setViewSettingsPanelVisibility(isOpen: boolean): Action<CalendarStoreState> {
	return withReadyCheck(({ setState, getState }) => {
		const state = getState();

		setState({
			viewSettingsPanelState: {
				...state.viewSettingsPanelState,
				isOpen,
			},
		});
	});
}

/**
 * Toggle current state of the "view settings" panel
 */
export function toggleViewSettingsPanelVisibility(): Action<CalendarStoreState> {
	return withReadyCheck(({ setState, getState }) => {
		const state = getState();

		setState({
			viewSettingsPanelState: {
				...state.viewSettingsPanelState,
				isOpen: !state.viewSettingsPanelState.isOpen,
			},
		});
	});
}

/**
 * Show or hide done items on the calendar.
 */
export function setHideDoneItems(hideDoneItems: boolean): Action<CalendarStoreState> {
	return withReadyCheck(({ setState }) => {
		setState({
			hideDoneItems,
		});
	});
}

/**
 * Show or hide weekends on the calendar.
 */
export function toggleShowWeekends(): Action<CalendarStoreState> {
	return withReadyCheck(({ setState, getState }) => {
		const state = getState();

		setState({
			showWeekends: !state.showWeekends,
		});
	});
}

/**
 * Toggle to turn on or off the 24 hour clock on weekly view.
 */
export function toggleUse24HourClock(): Action<CalendarStoreState> {
	return withReadyCheck(({ setState, getState }) => {
		const state = getState();

		setState({
			showIn24HourClock: !state.showIn24HourClock,
		});
	});
}

/**
 * Show or hide issue keys on issues on the calendar.
 */
export function toggleShowIssueKeys(): Action<CalendarStoreState> {
	return withReadyCheck(({ setState, getState }) => {
		const state = getState();

		setState({
			showIssueKey: !state.showIssueKey,
		});
	});
}

/**
 * Set the page cursor for the issues connection.
 */
export function setIssuesPageCursor(cursor: string | null): Action<CalendarStoreState> {
	return ({ setState, getState }) => {
		const state = getState();
		if (state.stage !== 'ready') throw new Error('Trying to update a pending calendar store');

		setState({
			issuesPageCursor: cursor,
		});
	};
}

/**
 * Set the page cursor for the issues connection.
 */
export function resetIssuesPageCursor(): Action<CalendarStoreState> {
	return ({ dispatch }) => {
		dispatch(setIssuesPageCursor(null));
	};
}

/**
 * Sets the view mode (Month, Week) of the calendar.
 */
export function setViewRange(viewRange: CalendarViewRange): Action<CalendarStoreState> {
	return withReadyCheck(({ setState }) => {
		setState({
			viewRange,
		});
	});
}

/**
 * Sets if the datetime field is configured for calendar
 */
export function setIsDateTimeConfigured(isDateTimeConfigured: boolean): Action<CalendarStoreState> {
	return withReadyCheck(({ setState, getState }) => {
		setState({
			calendarFieldsConfig: {
				...getState().calendarFieldsConfig,
				isDateTimeConfigured,
			},
		});
	});
}
