/**
 * @generated SignedSource<<a7bd546c7dace2523b0d76d61f72e4da>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraScenarioType = "ADDED" | "DELETED" | "DELETEDFROMJIRA" | "UPDATED" | "%future added value";
export type JiraVersionStatus = "ARCHIVED" | "RELEASED" | "UNRELEASED" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type crossProjectVersionRenderer_calendar_CrossProjectVersionEventRenderer$data = {
  readonly crossProjectVersionScenarioValues: {
    readonly name: string | null | undefined;
    readonly scenarioType: JiraScenarioType | null | undefined;
  } | null | undefined;
  readonly name: string;
  readonly projects: ReadonlyArray<{
    readonly avatar: {
      readonly xsmall: string | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly name: string;
  } | null | undefined> | null | undefined;
  readonly releaseDate: AGG$DateTime | null | undefined;
  readonly status: JiraVersionStatus;
  readonly " $fragmentSpreads": FragmentRefs<"flyout_calendar_CrossProjectVersionFlyout">;
  readonly " $fragmentType": "crossProjectVersionRenderer_calendar_CrossProjectVersionEventRenderer";
};
export type crossProjectVersionRenderer_calendar_CrossProjectVersionEventRenderer$key = {
  readonly " $data"?: crossProjectVersionRenderer_calendar_CrossProjectVersionEventRenderer$data;
  readonly " $fragmentSpreads": FragmentRefs<"crossProjectVersionRenderer_calendar_CrossProjectVersionEventRenderer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "crossProjectVersionRenderer_calendar_CrossProjectVersionEventRenderer",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "releaseDate"
    },
    {
      "kind": "ScalarField",
      "name": "status"
    },
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "projects",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "id"
        },
        (v0/*: any*/),
        {
          "concreteType": "JiraAvatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "xsmall"
            }
          ]
        }
      ]
    },
    {
      "concreteType": "JiraCrossProjectVersionPlanScenarioValues",
      "kind": "LinkedField",
      "name": "crossProjectVersionScenarioValues",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "scenarioType"
        },
        (v0/*: any*/)
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "flyout_calendar_CrossProjectVersionFlyout"
    }
  ],
  "type": "JiraCrossProjectVersion"
};
})();

(node as any).hash = "5c37877cfdcd2349df3221462cb3e585";

export default node;
