import { useMemo } from 'react';
import { createUFOExperiences, type CalendarExperiences } from '../../experiences.tsx';
import { useCalendarUFOExperienceContext } from '../ufo-context-provider/index.tsx';

export function useCalendarExperience() {
	const { suffixKey } = useCalendarUFOExperienceContext();
	const experiences: CalendarExperiences = useMemo(
		() => createUFOExperiences({ suffixKey }),
		[suffixKey],
	);
	return experiences;
}
