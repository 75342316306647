/*!
 * Define a few combinators for JQL building
 * When combining OR/AND clauses we wrap them in parenthesis.
 *
 * This is configurable only because `extraQuery` can be a full query (with ORDER...), so
 * without proper JQL AST manipulation it's not safe to put a parenthesis token after it.
 */

import { fg } from '@atlassian/jira-feature-gating';

/**
 * Wraps the given clause in parentheses if it is not empty.
 *
 * @param clause - The clause to wrap in parentheses.
 * @returns The clause wrapped in parentheses, or an empty string if the clause is empty.
 */
export const paren = (clause: string) => (clause?.trim()?.length > 0 ? `(${clause})` : '');

/**
 * Combines an array of clauses into a single string using the specified operator.
 *
 * @param clauses - An array of clauses to be combined.
 * @param operator - The operator to be used for combining the clauses.
 * @returns The combined string of clauses.
 */
const combineClauses = (clauses: string[], operator: string): string =>
	clauses
		.map((clause) => clause.trim())
		.filter((clause) => clause.length > 0)
		.join(` ${operator} `);

/**
 * Combines an array of clauses using the logical AND operator.
 *
 * @param clauses - The array of clauses to be combined.
 * @param wrapInParens - Optional parameter to specify whether the combined clauses should be wrapped in parentheses. Default is true.
 * @returns The combined clauses as a string.
 */
export const and = (clauses: string[], wrapInParens = true): string => {
	const result = combineClauses(clauses, 'AND');
	return wrapInParens ? paren(result) : result;
};

/**
 * Combines an array of clauses using the logical OR operator and wraps them in parentheses.
 *
 * @param clauses - An array of clauses to be combined.
 * @returns The combined clauses wrapped in parentheses.
 */
export const or = (clauses: string[]): string => paren(combineClauses(clauses, 'OR'));

/**
 * Generates a in range JQL query string for the provided field and range.
 *
 * @param field - The clause field.
 * @param range - The range, specified by a start and end value.
 * @returns The JQL query string.
 */
export const inRange = (field: string, range: { start: string; end: string }): string =>
	and([`${field} >= ${range.start}`, `${field} <= ${range.end}`]);

/**
 * Creates a JQL (Jira Query Language) base query string based on the provided project keys.
 * If there is only one project key, the query will be `project = "<projectKey>"`.
 * If there are multiple project keys, the query will be `project in ("<projectKey1>", "<projectKey2>", ...)`.
 *
 * @param projectKeys An array of project keys.
 * @returns The JQL base query string.
 */
export function createBaseQuery(projectKeys: string[]): string {
	if (projectKeys.length === 0 || projectKeys.some((key) => key.trim().length === 0)) {
		return '';
	}
	if (projectKeys.length === 1) {
		return `project = "${projectKeys[0]}"`;
	}
	return `project in ("${projectKeys.join('", "')}")`;
}

export function createAdditionalFilterQuery(extraQuery: string, isHidingDoneItems: boolean) {
	const hideDoneItemsQuery = "StatusCategory != 'Complete'";
	const andHideDoneItemsQuery = `AND ${hideDoneItemsQuery}`;

	const query = fg('calendar-hide-done-query-param')
		? extraQuery.replace(andHideDoneItemsQuery, '').replace(hideDoneItemsQuery, '').trim()
		: extraQuery;

	if (query && isHidingDoneItems) {
		return and([query, hideDoneItemsQuery], false);
	}
	if (!query && isHidingDoneItems) {
		return hideDoneItemsQuery;
	}
	return query;
}
