/**
 * @generated SignedSource<<4f1fd916443b7ebb0f79cc69c1114262>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type issueTypeField_calendar$data = {
  readonly admin: {
    readonly canPerform: boolean;
  } | null | undefined;
  readonly id: string;
  readonly issueTypes: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __id: string;
        readonly avatar: {
          readonly small: string | null | undefined;
        } | null | undefined;
        readonly hierarchy: {
          readonly level: number | null | undefined;
        } | null | undefined;
        readonly id: string;
        readonly name: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number | null | undefined;
  } | null | undefined;
  readonly key: string;
  readonly projectStyle: JiraProjectStyle | null | undefined;
  readonly projectType: JiraProjectType | null | undefined;
  readonly " $fragmentType": "issueTypeField_calendar";
};
export type issueTypeField_calendar$key = {
  readonly " $data"?: issueTypeField_calendar$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueTypeField_calendar">;
};

import issueTypeField_calendar_RefetchPaginated_Query_graphql from './issueTypeField_calendar_RefetchPaginated_Query.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "issueTypes"
],
v1 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": issueTypeField_calendar_RefetchPaginated_Query_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "issueTypeField_calendar",
  "selections": [
    {
      "alias": "issueTypes",
      "concreteType": "JiraIssueTypeConnection",
      "kind": "LinkedField",
      "name": "__inlineCardCreate_calendar_issueTypes_connection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "name"
                },
                {
                  "concreteType": "JiraAvatar",
                  "kind": "LinkedField",
                  "name": "avatar",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "small"
                    }
                  ]
                },
                {
                  "concreteType": "JiraIssueTypeHierarchyLevel",
                  "kind": "LinkedField",
                  "name": "hierarchy",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "level"
                    }
                  ]
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "__id"
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "name": "__typename"
                }
              ]
            },
            {
              "kind": "ScalarField",
              "name": "cursor"
            }
          ]
        },
        {
          "kind": "ScalarField",
          "name": "totalCount"
        },
        {
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "endCursor"
            },
            {
              "kind": "ScalarField",
              "name": "hasNextPage"
            }
          ]
        }
      ]
    },
    {
      "alias": "admin",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "EDIT_PROJECT_CONFIG"
        }
      ],
      "concreteType": "JiraProjectAction",
      "kind": "LinkedField",
      "name": "action",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "canPerform"
        }
      ],
      "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
    },
    {
      "kind": "ScalarField",
      "name": "projectType"
    },
    {
      "kind": "ScalarField",
      "name": "projectStyle"
    },
    {
      "kind": "ScalarField",
      "name": "key"
    },
    (v1/*: any*/)
  ],
  "type": "JiraProject"
};
})();

(node as any).hash = "0446f4c6405c741ed2d1a251879aa2d4";

export default node;
