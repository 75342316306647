import React, { type ReactNode } from 'react';
import { ConditionalNudgeWrapper } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/conditional-nudge-wrapper/index.tsx';
import { AddItemsCalendarNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/jwm-calendar-nudge-tour/add-items-calendar/async.tsx';

export const TodayWrapper = ({ children }: { children: ReactNode }) => {
	return (
		<ConditionalNudgeWrapper conditionsToApplyWrapper Wrapper={AddItemsCalendarNudgeAsync}>
			{children}
		</ConditionalNudgeWrapper>
	);
};
