import { useCallback, useRef } from 'react';
import memoizeOne from 'memoize-one';
import type { JWMView } from '@atlassian/jira-business-common/src/common/types/jwm-view.tsx';
import { FILTER_PARAM } from '@atlassian/jira-business-constants/src/index.tsx';
import { useJqlContext } from '@atlassian/jira-business-entity-common/src/controllers/jql-context/index.tsx';
import type { BusinessIssueField } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/types.tsx';
import { GraphQLErrors } from '@atlassian/jira-business-graphql-errors/src/index.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import {
	SPRINT_TYPE,
	FIX_VERSIONS_TYPE,
	GOALS_CF_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';
import type {
	LoadOptions,
	AvatarOption,
	SelectOption,
	LozengeOption,
	GoalOption,
} from '../../common/types.tsx';
import {
	EMPTY_TRANSFORMED_DATA,
	transformCategories,
	transformUsers,
	transformStatuses,
	transformPriorities,
	transformDefaultField,
	transformIssueTypes,
	transformSprints,
	transformGoals,
	transformParent,
} from '../../common/utils/transform-field-values/index.tsx';
import type {
	FieldValues,
	PriorityFieldValues,
	UserFieldValues,
	IssueTypeFieldValues,
	StatusFieldValues,
	DefaultFieldValues,
	SprintFieldValues,
	GoalsFieldValues,
} from '../../common/utils/transform-field-values/types.tsx';
import { useFetchTeams } from '../fetch-teams/index.tsx';
import { hydrateFilterValues } from '../hydrate-values/index.tsx';
import { transformHydratedValues, mergeHydratedValues } from '../hydrate-values/utils.tsx';
import { CATEGORY_SEARCH_QUERY, INITIAL_DATA_QUERY, SEARCH_QUERY } from './gql.tsx';
import type { FieldValuesGqlResponseData, CategoryFieldValuesGqlResponseData } from './types.tsx';
import { transformInitialData, fireFieldValuesErrorAnalytics, useGoalsJqlTerm } from './utils.tsx';

const MAX_NUMBER_OF_OPTIONS = 20; // min number to render two rows of the priority filter
const MAX_INITIAL_NUMBER_OF_OPTIONS = 50; // Increased initial number of options fetched
const EMPTY_FIELD_DATA = { totalCount: 0, options: [] };
const ACTIVE_SPRINTS_JQL = '(sprint in openSprints() OR sprint in futureSprints())';

const fetchInitialDataInternal = async (
	cloudId: string,
	jqlContext: string,
	jqlURLParam?: string,
	allowedFieldTypes?: Set<string>,
	goalsJqlTerm?: string,
) => {
	try {
		const response = await performPostRequest(
			'/rest/gira/1/?operation=JWMInitialFilterFieldValues',
			{
				body: JSON.stringify({
					query: INITIAL_DATA_QUERY,
					variables: {
						cloudId,
						first: MAX_INITIAL_NUMBER_OF_OPTIONS,
						jql: jqlContext,
						shouldFetchSoftwareFields:
							Boolean(allowedFieldTypes?.has(FIX_VERSIONS_TYPE)) ||
							Boolean(allowedFieldTypes?.has(SPRINT_TYPE)),
						shouldFetchGoalsField: Boolean(allowedFieldTypes?.has(GOALS_CF_TYPE) && !!goalsJqlTerm),
						goalsJqlTerm: goalsJqlTerm || '',
						sprintsJqlContext: allowedFieldTypes?.has(SPRINT_TYPE)
							? `${jqlContext} AND ${ACTIVE_SPRINTS_JQL}`
							: undefined,
					},
				}),
			},
		);

		if (response.data?.jira?.jqlBuilder == null && response.data?.jwmCategoryOptions == null) {
			throw new GraphQLErrors(response.errors);
		}

		const hydratedValues = jqlURLParam ? await hydrateFilterValues(cloudId, jqlURLParam) : null;

		const transformedInitialData = transformInitialData(response.data);

		if (hydratedValues !== null) {
			const transformedHydratedValues = transformHydratedValues(hydratedValues);
			return mergeHydratedValues(transformedInitialData, transformedHydratedValues);
		}

		return transformedInitialData;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		fireFieldValuesErrorAnalytics('initialiseFilterData', 'Failed to initialise filter data', err);
	}
	return null;
};

const memoizedFetchInitialData = memoizeOne(fetchInitialDataInternal);

export const useFetchFieldValues = ({
	view,
	allowedFieldTypes,
	filterFields,
}: {
	view?: JWMView;
	allowedFieldTypes?: Set<string>;
	filterFields?: BusinessIssueField[];
}) => {
	const cloudId = useCloudId();
	const jqlContext = useJqlContext();
	const [jqlURLParam] = useQueryParam(FILTER_PARAM);
	const jqlURLParamRef = useRef(jqlURLParam);
	const goalsJqlTerm = useGoalsJqlTerm(filterFields);

	const fetchTeamsService = useFetchTeams();

	const fetchInitialData = useCallback(async () => {
		return Promise.all([
			memoizedFetchInitialData(
				cloudId,
				jqlContext,
				jqlURLParamRef.current,
				allowedFieldTypes,
				goalsJqlTerm,
			),
			fetchTeamsService({ first: MAX_NUMBER_OF_OPTIONS }),
		]).then((values) => {
			let result = EMPTY_TRANSFORMED_DATA;
			values.forEach((value) => {
				result = {
					...result,
					...value,
				};
			});
			return result;
		});
	}, [allowedFieldTypes, cloudId, fetchTeamsService, goalsJqlTerm, jqlContext]);

	const fetchData = useCallback(
		async ({
			fieldName,
			query = '',
			first = MAX_NUMBER_OF_OPTIONS,
			afterCursor,
			jqlContext: jqlContextProp,
		}: {
			fieldName: string;
			query?: string;
			first?: number;
			afterCursor?: string;
			jqlContext?: string;
		}): Promise<FieldValues> => {
			const response: FieldValuesGqlResponseData = await performPostRequest(
				'/rest/gira/1/?operation=JWMFilterFieldValues',
				{
					body: JSON.stringify({
						query: SEARCH_QUERY,
						variables: {
							cloudId,
							first,
							jqlTerm: fieldName,
							jql: jqlContextProp ?? jqlContext,
							searchString: query,
							after: afterCursor,
						},
					}),
				},
			);

			if (response.data?.jira?.jqlBuilder?.fieldValues == null) {
				throw new GraphQLErrors(response.errors);
			}

			return response.data.jira.jqlBuilder.fieldValues;
		},
		[cloudId, jqlContext],
	);

	const fetchLabels: LoadOptions<SelectOption> = useCallback(
		async ({ query = '', afterCursor, first }) => {
			try {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const fieldValuesData = (await fetchData({
					fieldName: 'labels',
					query,
					first,
					afterCursor,
				})) as DefaultFieldValues;

				return {
					totalCount: fieldValuesData.totalCount,
					options: transformDefaultField(fieldValuesData),
				};
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				fireFieldValuesErrorAnalytics('searchFilterLabels', 'Failed to search labels', err);
			}

			return EMPTY_FIELD_DATA;
		},
		[fetchData],
	);

	const fetchComponents: LoadOptions<SelectOption> = useCallback(
		async ({ query = '', afterCursor, first }) => {
			try {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const fieldValuesData = (await fetchData({
					fieldName: 'component',
					query,
					first,
					afterCursor,
				})) as DefaultFieldValues;

				return {
					totalCount: fieldValuesData.totalCount,
					options: transformDefaultField(fieldValuesData),
				};
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				fireFieldValuesErrorAnalytics('searchFilterComponents', 'Failed to search components', err);
			}

			return EMPTY_FIELD_DATA;
		},
		[fetchData],
	);

	const fetchPriorities: LoadOptions<AvatarOption> = useCallback(
		async ({ query = '', afterCursor, first }) => {
			try {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const fieldValuesData = (await fetchData({
					fieldName: 'priority',
					query,
					first,
					afterCursor,
				})) as PriorityFieldValues;

				return {
					totalCount: fieldValuesData.totalCount,
					options: transformPriorities(fieldValuesData),
				};
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				fireFieldValuesErrorAnalytics('searchFilterPriorities', 'Failed to search priorities', err);
			}

			return EMPTY_FIELD_DATA;
		},
		[fetchData],
	);

	const fetchUsers: LoadOptions<AvatarOption> = useCallback(
		async ({ query = '', afterCursor, first }) => {
			try {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const fieldValuesData = (await fetchData({
					fieldName: 'assignee',
					query,
					first,
					afterCursor,
				})) as UserFieldValues;

				return {
					totalCount: fieldValuesData.totalCount,
					options: transformUsers(fieldValuesData),
				};
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				fireFieldValuesErrorAnalytics('searchFilterUsers', 'Failed to search users', err);
			}

			return EMPTY_FIELD_DATA;
		},
		[fetchData],
	);

	const fetchIssueTypes: LoadOptions<AvatarOption> = useCallback(
		async ({ query = '', afterCursor, first }) => {
			try {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const fieldValuesData = (await fetchData({
					fieldName: 'issuetype',
					query,
					first,
					afterCursor,
				})) as IssueTypeFieldValues;

				let options = transformIssueTypes(fieldValuesData);

				if (view === 'board') {
					options = options.filter((option) => option.hierarchyLevel !== -1);
				}

				return {
					totalCount: fieldValuesData.totalCount,
					options,
				};
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				fireFieldValuesErrorAnalytics(
					'searchFilterIssueTypes',
					'Failed to search issue types',
					err,
				);
			}

			return EMPTY_FIELD_DATA;
		},
		[fetchData, view],
	);

	const fetchStatuses: LoadOptions<LozengeOption> = useCallback(
		async ({ query = '', afterCursor, first }) => {
			try {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const fieldValuesData = (await fetchData({
					fieldName: 'status',
					query,
					first,
					afterCursor,
				})) as StatusFieldValues;

				return {
					totalCount: fieldValuesData.totalCount,
					options: transformStatuses(fieldValuesData),
				};
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				fireFieldValuesErrorAnalytics('searchFilterStatuses', 'Failed to search statuses', err);
			}

			return EMPTY_FIELD_DATA;
		},
		[fetchData],
	);

	const fetchCategories: LoadOptions<SelectOption> = useCallback(
		async ({ query = '', afterCursor, first = MAX_NUMBER_OF_OPTIONS }) => {
			try {
				const response: CategoryFieldValuesGqlResponseData = await performPostRequest(
					'/rest/gira/1/?operation=JWMCategoryFieldValuesSearch',
					{
						body: JSON.stringify({
							query: CATEGORY_SEARCH_QUERY,
							variables: {
								first,
								jql: jqlContext,
								searchQuery: query,
								after: afterCursor,
							},
						}),
					},
				);

				if (response.errors != null) {
					throw new GraphQLErrors(response.errors);
				}

				if (response.data?.jira?.jwmCategoryOptions?.edges == null) {
					throw new Error('GraphQL response has no data');
				}

				return {
					totalCount: response.data.jira.jwmCategoryOptions.totalCount,
					options: transformCategories(response.data.jira.jwmCategoryOptions),
				};
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				fireFieldValuesErrorAnalytics('searchFilterCategories', 'Failed to search categories', err);
			}

			return EMPTY_FIELD_DATA;
		},
		[jqlContext],
	);

	const fetchSprints: LoadOptions<SelectOption> = useCallback(
		async ({ query = '', afterCursor, first }) => {
			try {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const fieldValuesData = (await fetchData({
					fieldName: 'sprint',
					query,
					first,
					afterCursor,
					jqlContext: `${jqlContext} AND ${ACTIVE_SPRINTS_JQL}`,
				})) as SprintFieldValues;

				return {
					totalCount: fieldValuesData.totalCount,
					options: transformSprints(fieldValuesData),
				};
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				fireFieldValuesErrorAnalytics('searchFilterSprints', 'Failed to search sprints', err);
			}

			return EMPTY_FIELD_DATA;
		},
		[fetchData, jqlContext],
	);

	const fetchFixVersions: LoadOptions<SelectOption> = useCallback(
		async ({ query = '', afterCursor, first }) => {
			try {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const fieldValuesData = (await fetchData({
					fieldName: 'fixVersion',
					query,
					first,
					afterCursor,
				})) as DefaultFieldValues;
				return {
					totalCount: fieldValuesData.totalCount,
					options: transformDefaultField(fieldValuesData),
				};
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				fireFieldValuesErrorAnalytics(
					'searchFilterFixVersion',
					'Failed to search fix version',
					err,
				);
			}
			return EMPTY_FIELD_DATA;
		},
		[fetchData],
	);

	const fetchParents: LoadOptions<SelectOption> = useCallback(
		async ({ query = '', afterCursor, first }) => {
			try {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const fieldValuesData = (await fetchData({
					fieldName: 'parent',
					query,
					first,
					afterCursor,
				})) as DefaultFieldValues;

				return {
					totalCount: fieldValuesData.totalCount,
					options: transformParent(fieldValuesData),
				};
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				fireFieldValuesErrorAnalytics('searchFilterParent', 'Failed to search parents', err);
			}

			return EMPTY_FIELD_DATA;
		},
		[fetchData],
	);

	const fetchGoals: LoadOptions<GoalOption> = useCallback(
		async ({ query = '', afterCursor, first }) => {
			if (!goalsJqlTerm) return EMPTY_FIELD_DATA;

			try {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const fieldValuesData = (await fetchData({
					fieldName: goalsJqlTerm,
					query,
					first,
					afterCursor,
				})) as GoalsFieldValues;

				return {
					totalCount: fieldValuesData.totalCount,
					options: transformGoals(fieldValuesData),
				};
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				fireFieldValuesErrorAnalytics('searchFilterGoals', 'Failed to search goals', err);
			}

			return EMPTY_FIELD_DATA;
		},
		[fetchData, goalsJqlTerm],
	);

	const fetchTeams: LoadOptions<SelectOption> = useCallback(
		async ({ query = '', afterCursor, first }) => {
			try {
				const fieldValuesData = await fetchTeamsService({
					query,
					first,
					after: afterCursor,
				});

				return fieldValuesData[TEAMS_PLATFORM_CF_TYPE];
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				fireFieldValuesErrorAnalytics('searchFilterTeams', 'Failed to search teams', err);
			}

			return EMPTY_FIELD_DATA;
		},
		[fetchTeamsService],
	);

	return {
		fetchInitialData,
		fetchLabels,
		fetchComponents,
		fetchPriorities,
		fetchUsers,
		fetchIssueTypes,
		fetchStatuses,
		fetchCategories,
		fetchSprints,
		fetchFixVersions,
		fetchParents,
		fetchTeams,
		fetchGoals,
	};
};
