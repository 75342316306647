import {
  JiraIssueTypeAriResourceOwner,
  JiraIssueTypeAriResourceType
} from "./chunk-YBV4ZT7H.mjs";
import {
  RegisteredAri
} from "./chunk-YG2E6ZVD.mjs";
import {
  AriParser
} from "./chunk-Q7FABYST.mjs";

// src/jira/issue-type/manifest.ts
var jiraIssueTypeAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^[a-zA-Z0-9_\\-.]{1,255}$"),
  // eslint-disable-line no-useless-escape
  resourceOwner: JiraIssueTypeAriResourceOwner,
  resourceType: JiraIssueTypeAriResourceType,
  resourceIdSlug: "{issueTypeId}",
  resourceIdSegmentFormats: {
    issueTypeId: /[!a-zA-Z0-9\-_.~@:{}=]+(\/[!a-zA-Z0-9\-_.~@:{}=]+)*/
    // eslint-disable-line no-useless-escape
  }
};

// src/jira/issue-type/index.ts
var JiraIssueTypeAri = class _JiraIssueTypeAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._siteId = opts.cloudId || "", this._issueTypeId = opts.resourceIdSegmentValues.issueTypeId;
  }
  get siteId() {
    return this._siteId;
  }
  get issueTypeId() {
    return this._issueTypeId;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: jiraIssueTypeAriStaticOpts.qualifier,
      platformQualifier: jiraIssueTypeAriStaticOpts.platformQualifier,
      cloudId: opts.siteId,
      resourceOwner: jiraIssueTypeAriStaticOpts.resourceOwner,
      resourceType: jiraIssueTypeAriStaticOpts.resourceType,
      resourceId: `${opts.issueTypeId}`,
      resourceIdSegmentValues: {
        issueTypeId: opts.issueTypeId
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, jiraIssueTypeAriStaticOpts);
    return new _JiraIssueTypeAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, jiraIssueTypeAriStaticOpts);
    return new _JiraIssueTypeAri(opts);
  }
  getVariables() {
    return {
      siteId: this.siteId,
      issueTypeId: this.issueTypeId
    };
  }
};

export {
  JiraIssueTypeAri
};
