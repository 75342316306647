import { useMemo } from 'react';
import type { JiraCalendarVersionsInput } from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery.graphql';

export function useVersionsSearchInput(hasSharedReleases = false): {
	versionsSearchInput: JiraCalendarVersionsInput;
} {
	const versionsSearchInput = useMemo<JiraCalendarVersionsInput>(
		() => ({
			versionStatuses: ['RELEASED', 'UNRELEASED'],
			...(hasSharedReleases ? { includeSharedReleases: hasSharedReleases } : {}),
		}),
		[hasSharedReleases],
	);

	return { versionsSearchInput };
}
