import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	numberOfIssues: {
		id: 'servicedesk.queues.agent-view.layout.queues-details.issue-count.number-of-issues',
		defaultMessage: `{issueCount, plural,
            =1 {{issueCount, number} issue}
            other {{issueCount, number} issues}
            }`,
		description: 'The number of requests (aka. issues) in the given queue',
	},
	numberOfFilteredIssues: {
		id: 'servicedesk.queues.agent-view.layout.queues-details.issue-count.number-of-filtered-issues',
		defaultMessage: `{filteredIssueCount, plural,
            =1 {{filteredIssueCount, number} issue found}
            other {{filteredIssueCount, number} issues found}
            }`,
		description: 'The number of requests (aka. issues) in the given queue when queue is filtered',
	},
	refreshResults: {
		id: 'servicedesk.queues.agent-view.layout.queues-details.issue-count.refresh-results',
		defaultMessage: 'Refresh results',
		description: 'Refresh the results of the request search',
	},
	numberOfIssuesIssueTermRefresh: {
		id: 'servicedesk.queues.agent-view.layout.queues-details.issue-count.number-of-issues-issue-term-refresh',
		defaultMessage: `{issueCount, plural,
            =1 {{issueCount, number} issue}
            other {{issueCount, number} issues}
            }`,
		description: 'The number of requests (aka. issues) in the given queue',
	},
	numberOfFilteredIssuesIssueTermRefresh: {
		id: 'servicedesk.queues.agent-view.layout.queues-details.issue-count.number-of-filtered-issues-issue-term-refresh',
		defaultMessage: `{filteredIssueCount, plural,
            =1 {{filteredIssueCount, number} issue found}
            other {{filteredIssueCount, number} issues found}
            }`,
		description: 'The number of requests (aka. issues) in the given queue when queue is filtered',
	},
});
