/**
 * @generated SignedSource<<707363ba9cbd8a37714d1cc9f5ddb28e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type calendarRenderer_calendar_EventRenderer_queryRef$data = {
  readonly " $fragmentSpreads": FragmentRefs<"placeholderRenderer_calendar_PlaceholderEventRenderer">;
  readonly " $fragmentType": "calendarRenderer_calendar_EventRenderer_queryRef";
};
export type calendarRenderer_calendar_EventRenderer_queryRef$key = {
  readonly " $data"?: calendarRenderer_calendar_EventRenderer_queryRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"calendarRenderer_calendar_EventRenderer_queryRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "configurationInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeInput"
    }
  ],
  "kind": "Fragment",
  "name": "calendarRenderer_calendar_EventRenderer_queryRef",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "configurationInput",
          "variableName": "configurationInput"
        },
        {
          "kind": "Variable",
          "name": "scopeInput",
          "variableName": "scopeInput"
        }
      ],
      "kind": "FragmentSpread",
      "name": "placeholderRenderer_calendar_PlaceholderEventRenderer"
    }
  ],
  "type": "Query"
};

(node as any).hash = "208427d3a7e8bb9638dd3ea5b5cfc41c";

export default node;
