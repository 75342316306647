import React, { type ReactNode, Component, type KeyboardEvent } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import keycode from 'keycode';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import type { ActiveItemChangeCallback } from '../../../../../../model/callbacks/index.tsx';
import type {
	CellComponentType,
	CellWrapperComponentType,
	RenderSidebarIcon,
} from '../../../../../../model/cell-component/index.tsx';
import { type ColumnId, SUMMARY, type Column } from '../../../../../../model/columns/index.tsx';
import {
	type CellNavigationDirection,
	UP,
	DOWN,
	LEFT,
	RIGHT,
} from '../../../../../../model/navigation-directions/index.tsx';
import {
	ACTIVE_CELL_TYPE,
	type ActiveCell,
	type NavigateCell,
} from '../../../../../../model/navigation/index.tsx';
import type { Optional } from '../../../../../../model/optional/index.tsx';
import type { RowId } from '../../../../../../model/rows/index.tsx';

const getWrapperStyles = (width: number) => ({
	flex: '0 0 auto',
	width: `${width}px`,
});

const NAVIGATION_KEYS = {
	[keycode('up')]: UP,
	[keycode('right')]: RIGHT,
	[keycode('down')]: DOWN,
	[keycode('left')]: LEFT,
} as const;

type Props = {
	rowId: RowId;
	columnId: ColumnId;
	width: number;
	depth: number;
	column?: Column; // make this required when we tidy up the feature flag css_support_for_virtual_table
	maxDepth: number;
	activatedFrom: Optional<CellNavigationDirection>;
	isInCellNavigationMode: boolean;
	isActive: boolean;
	isRowTemporary: boolean;
	isRowHovered: boolean;
	isRowKeyboardActive: boolean;
	shouldHydrateFully: boolean;
	shouldUseCssForRendering: boolean;
	confirmToNavigate: boolean;
	CellComponent: CellComponentType;
	NonTemporaryCellWrapper: Optional<CellWrapperComponentType>;
	canBeMultiLine: boolean;
	activeItemChanged: ActiveItemChangeCallback;
	clearActiveItem: () => void;
	setActiveCell: (arg1: ActiveCell) => void;
	navigateToCell: (cellAndDirection: NavigateCell) => void;
	onCellMount: () => void;
	/*
        parentId and isDraggable props are used in hover function of DropTarget
     */
	parentId: Optional<ColumnId>;
	renderSidebarIcon?: RenderSidebarIcon;
};

// eslint-disable-next-line jira/react/no-class-components
class CellWrapper extends Component<Props> {
	static defaultProps = {
		isRowTemporary: false,
		isRowHovered: false,
		isRowKeyboardActive: false,
		confirmToNavigate: true,
	};

	shouldComponentUpdate(nextProps: Props) {
		return (
			this.props.rowId !== nextProps.rowId ||
			this.props.columnId !== nextProps.columnId ||
			this.props.width !== nextProps.width ||
			this.props.depth !== nextProps.depth ||
			this.props.maxDepth !== nextProps.maxDepth ||
			this.props.activatedFrom !== nextProps.activatedFrom ||
			this.props.isActive !== nextProps.isActive ||
			this.props.shouldHydrateFully !== nextProps.shouldHydrateFully ||
			this.props.isRowHovered !== nextProps.isRowHovered ||
			this.props.isRowKeyboardActive !== nextProps.isRowKeyboardActive ||
			this.props.confirmToNavigate !== nextProps.confirmToNavigate ||
			this.props.activeItemChanged !== nextProps.activeItemChanged ||
			this.props.renderSidebarIcon !== nextProps.renderSidebarIcon
		);
	}

	componentDidUpdate(prevProps: Props) {
		const { rowId, columnId, isActive, activeItemChanged } = this.props;
		if (!prevProps.isActive && isActive) {
			activeItemChanged({ rowId, columnId });
		}
	}

	onFocus = () => {
		const { rowId, columnId, setActiveCell, isInCellNavigationMode } = this.props;
		if (isInCellNavigationMode) {
			setActiveCell({ type: ACTIVE_CELL_TYPE, rowId, columnId });
		}
	};

	onBlur = () => {
		const { clearActiveItem, isInCellNavigationMode } = this.props;
		if (isInCellNavigationMode) {
			clearActiveItem();
		}
	};

	onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
		const { confirmToNavigate, isInCellNavigationMode, columnId } = this.props;
		let direction;

		if (!isInCellNavigationMode) {
			return;
		}

		/* Special handling for enter since it can be ignored */
		if (event.keyCode === keycode('enter') && confirmToNavigate) {
			direction = DOWN;
		} else {
			direction = NAVIGATION_KEYS[event.keyCode];
		}

		if (direction) {
			event.preventDefault();
			event.stopPropagation();

			const { rowId, navigateToCell } = this.props;
			navigateToCell({ rowId, columnId, direction });
		}
	};

	getTargetRef = (div?: HTMLElement | null) => {
		this.targetRef = div;
	};

	targetRef: HTMLElement | undefined | null;

	render() {
		const {
			rowId,
			width,
			column,
			columnId,
			activatedFrom,
			isActive,
			isRowTemporary,
			isRowHovered,
			isRowKeyboardActive,
			CellComponent,
			NonTemporaryCellWrapper,
			onCellMount,
			canBeMultiLine,
			shouldHydrateFully,
			shouldUseCssForRendering,
			renderSidebarIcon,
		} = this.props;

		const onMultiLineCellUpdated = canBeMultiLine ? onCellMount : noop;

		const commonProps = {
			rowId,
			width,
			columnId,
			activatedFrom,
			isActive,
			isRowTemporary,
			isRowHovered,
			isRowKeyboardActive,
			onMount: onMultiLineCellUpdated,
			onUpdate: onMultiLineCellUpdated,
			canBeMultiLine,
			shouldHydrateFully,
		};

		/* We just use the wrapping element for bubbling */

		if (renderSidebarIcon) {
			const colWidth = width;

			const renderCell = (): ReactNode => {
				if (columnId === SUMMARY) {
					// Leave 60px space for icon summary cells
					const colAdjust = 60;

					if (column && shouldUseCssForRendering && fg('css_support_for_virtual_table')) {
						return (
							<CellWithIconIconWrapper>
								<Box
									xcss={[cellHoverStyles]}
									style={{
										minWidth: column.minWidth - colAdjust,
										maxWidth: column.maxWidth - colAdjust,
									}}
								>
									<CellComponent {...commonProps} />
								</Box>
								{(isRowHovered || isRowKeyboardActive) && (
									<SidebarIcon>{renderSidebarIcon(rowId)}</SidebarIcon>
								)}
							</CellWithIconIconWrapper>
						);
					}

					return (
						<CellWithIconIconWrapper>
							<RestrictedWidthCell width={colWidth - colAdjust}>
								<CellComponent {...commonProps} />
							</RestrictedWidthCell>
							{(isRowHovered || isRowKeyboardActive) && (
								<SidebarIcon>{renderSidebarIcon(rowId)}</SidebarIcon>
							)}
						</CellWithIconIconWrapper>
					);
				}

				return <CellComponent {...commonProps} />;
			};

			if (column && shouldUseCssForRendering && fg('css_support_for_virtual_table')) {
				return (
					<Box
						ref={this.getTargetRef}
						style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
						onFocus={this.onFocus}
						onBlur={this.onBlur}
						onKeyDown={this.onKeyDown}
						xcss={cellStyles}
						testId={`common.components.virtual-table.table.content.rows.common.cell-wrapper-row${rowId}-${columnId}`}
						role={fg('jsm_virtual_table_a11y') ? 'cell' : 'presentation'}
					>
						{isRowTemporary || NonTemporaryCellWrapper === undefined ? (
							<>{renderCell()}</>
						) : (
							<NonTemporaryCellWrapper Cell={CellComponent} {...commonProps} />
						)}
					</Box>
				);
			}

			return fg('jsm_virtual_table_a11y') ? (
				<Box
					ref={this.getTargetRef}
					style={{ width: `${width}px` }}
					xcss={cellWrapperStyles}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					onKeyDown={this.onKeyDown}
					testId={`common.components.virtual-table.table.content.rows.common.cell-wrapper-row${rowId}-${columnId}`}
					role="cell"
				>
					{isRowTemporary || NonTemporaryCellWrapper === undefined ? (
						<>{renderCell()}</>
					) : (
						<NonTemporaryCellWrapper Cell={CellComponent} {...commonProps} />
					)}
				</Box>
			) : (
				<div
					ref={this.getTargetRef}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={getWrapperStyles(width)}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					onKeyDown={this.onKeyDown}
					data-testid={`common.components.virtual-table.table.content.rows.common.cell-wrapper-row${rowId}-${columnId}`}
					role="presentation"
				>
					{isRowTemporary || NonTemporaryCellWrapper === undefined ? (
						<>{renderCell()}</>
					) : (
						<NonTemporaryCellWrapper Cell={CellComponent} {...commonProps} />
					)}
				</div>
			);
		}

		if (column && shouldUseCssForRendering && fg('css_support_for_virtual_table')) {
			return (
				<Box
					ref={this.getTargetRef}
					style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					onKeyDown={this.onKeyDown}
					xcss={cellStyles}
					testId={`common.components.virtual-table.table.content.rows.common.cell-wrapper-row${rowId}-${columnId}`}
					role={fg('jsm_virtual_table_a11y') ? 'cell' : 'presentation'}
				>
					{isRowTemporary || NonTemporaryCellWrapper === undefined ? (
						<CellComponent {...commonProps} />
					) : (
						<NonTemporaryCellWrapper Cell={CellComponent} {...commonProps} />
					)}
				</Box>
			);
		}

		return fg('jsm_virtual_table_a11y') ? (
			<Box
				ref={this.getTargetRef}
				style={{ width: `${width}px` }}
				xcss={cellWrapperStyles}
				onFocus={this.onFocus}
				onBlur={this.onBlur}
				onKeyDown={this.onKeyDown}
				testId={`common.components.virtual-table.table.content.rows.common.cell-wrapper-row${rowId}-${columnId}`}
				role="cell"
			>
				{isRowTemporary || NonTemporaryCellWrapper === undefined ? (
					<CellComponent {...commonProps} />
				) : (
					<NonTemporaryCellWrapper Cell={CellComponent} {...commonProps} />
				)}
			</Box>
		) : (
			<div
				ref={this.getTargetRef}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={getWrapperStyles(width)}
				onFocus={this.onFocus}
				onBlur={this.onBlur}
				onKeyDown={this.onKeyDown}
				// eslint-disable-next-line jira/integration/enforce-data-testid-usage
				data-test-id={`common.components.virtual-table.table.content.rows.common.cell-wrapper-row${rowId}-${columnId}`}
				role="presentation"
			>
				{isRowTemporary || NonTemporaryCellWrapper === undefined ? (
					<CellComponent {...commonProps} />
				) : (
					<NonTemporaryCellWrapper Cell={CellComponent} {...commonProps} />
				)}
			</div>
		);
	}
}

export default CellWrapper;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const SidebarIcon = styled.div({
	marginTop: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const RestrictedWidthCell = styled.div<{ width: number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => `${props.width}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const CellWithIconIconWrapper = styled.div({
	display: 'flex',
	alignItems: 'flex-start',
});

const cellStyles = xcss({
	width: '100%',
});

const cellWrapperStyles = xcss({
	flex: '0 0 auto',
});

const cellHoverStyles = xcss({
	width: 'calc(100% - 60px)',
});
