import { useMemo } from 'react';
import type { JiraCalendarCrossProjectVersionsInput } from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery.graphql';

export function useCrossProjectVersionsSearchInput(): {
	crossProjectVersionsSearchInput: JiraCalendarCrossProjectVersionsInput;
} {
	const crossProjectVersionsSearchInput = useMemo<JiraCalendarCrossProjectVersionsInput>(
		() => ({ statuses: ['RELEASED', 'UNRELEASED'] }),
		[],
	);

	return { crossProjectVersionsSearchInput };
}
