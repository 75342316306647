/**
 * List of error messages that should be handled by the relay error handler.
 * These errors are considered non-service faults and do not impact the Service Level Objective (SLO).
 */
const ALLOWED_RELAY_ERROR_MESSAGES = ['status code is: 429'];

/**
 * Determines whether a given error should be handled by the relay error handler.
 * This function checks if the error message contains any of the allowed messages.
 *
 * @param {Error} error - The error object to be evaluated.
 * @returns {boolean} Returns true if the error is allowed to be handled, false otherwise.
 */
export const isRelayErrorAllowed = (error: Error) =>
	ALLOWED_RELAY_ERROR_MESSAGES.some((message) => error.message.includes(message));
