import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	menuButton: {
		id: 'sprint-flyout.sprint-actions-menu.menu-button',
		defaultMessage: 'Sprint actions',
		description: 'More actions menu button in the sprint flyout.',
	},
	editSprint: {
		id: 'sprint-flyout.sprint-actions-menu.edit-sprint',
		defaultMessage: 'Edit sprint',
		description: 'Button in the Sprint actions menu for editing this sprint',
	},
});
