// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';

import { N0, N100, N200, N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

const dayTextColor = token('color.text.subtlest', N100);
const todayColor = token('color.background.neutral.bold', N500);
const numberColor = token('color.text.subtle', N200);
const todayNumberColor = token('color.text.inverse', N0);

const DAY_NUMBER_SIZE = 40;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const DayText = styled.div({
	font: token('font.heading.xxsmall'),
	color: dayTextColor,
	marginTop: 0,
});
DayText.displayName = 'DayText';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const GridDayContainer = styled.div<{ isPast: boolean }>((props) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	opacity: props.isPast ? 0.57 : 1,
}));
GridDayContainer.displayName = 'GridDayContainer';

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const GridDayNumber = styled.div<{ isToday: boolean }>`
	font: ${token('font.heading.small')};
	margin-top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${(props) => (props.isToday ? todayNumberColor : numberColor)};
	background-color: ${(props) => (props.isToday ? todayColor : 'transparent')};
	border-radius: ${(props) => (props.isToday ? `${DAY_NUMBER_SIZE / 2}px` : '0')};
	width: ${DAY_NUMBER_SIZE}px;
	height: ${DAY_NUMBER_SIZE}px;
	white-space: nowrap;
`;
GridDayNumber.displayName = 'GridDayNumber';
