import React, { useEffect, useRef } from 'react';
import type { LoadMoreFn } from 'react-relay';
import type { OperationType } from 'relay-runtime';

interface PaginationTriggerProps<T extends OperationType> {
	loadNext: LoadMoreFn<T>;
	pageSize: number;
	hasNextPage: boolean;
	isLoadingNext: boolean;
}

/**
 * A component that uses intersection observer to trigger a relay pagination fetch when
 * infinite scrolling.
 *
 * Similar to https://relay.dev/docs/tutorial/connections-pagination/
 *
 * @example
 * ```tsx
 * const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment(FRAGMENT, ref);
 *
 * return (
 *    <Stack>
 *      {data.edges.map((edge) => <Item node={edge.node} key={edge.cursor} />)}
 *
 *      <PaginationTrigger
 *          loadNext={loadNext}
 *          pageSize={10}
 *          hasNextPage={hasNext}
 *          isLoadingNext={isLoadingNext}
 *      />
 *    </Stack>
 * );
 *
 * ```
 */
export function PaginationTrigger<T extends OperationType>({
	loadNext,
	pageSize,
	hasNextPage,
	isLoadingNext,
}: PaginationTriggerProps<T>) {
	const ref = useRef(null);
	useEffect(() => {
		if (!ref.current) return;

		const observer = new IntersectionObserver((entries) => {
			if (hasNextPage && !isLoadingNext && entries.some((e) => e.isIntersecting)) {
				loadNext(pageSize);
			}
		});
		observer.observe(ref.current);

		return () => {
			observer.disconnect();
		};
	}, [hasNextPage, isLoadingNext, loadNext, pageSize]);

	return <div aria-hidden="true" ref={ref} data-testid="software-pagination-trigger.div" />;
}
