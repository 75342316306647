import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	createIssue: {
		id: 'calendar.calendar-renderer.event-renderer.placeholder-renderer.inline-card-create.create-issue',
		defaultMessage: 'Create',
		description: 'CTA to create an issue in calendar view',
	},
	summaryFieldLabel: {
		id: 'calendar.calendar-renderer.event-renderer.placeholder-renderer.inline-card-create.summary-field-label',
		defaultMessage: 'Summary',
		description:
			'Field label for the issue summary input when creating a new issue in calendar view',
	},
	summaryPlaceholder: {
		id: 'calendar.calendar-renderer.event-renderer.placeholder-renderer.inline-card-create.summary-placeholder',
		defaultMessage: 'What needs to be done?',
		description:
			'Placeholder for the issue summary input when creating a new issue in calendar view',
	},
});
