import { useMemo } from 'react';
import { createBaseQuery } from '../../common/utils/jql-query-helper/index.tsx';
import { useCalendarScope } from '../calendar-store/index.tsx';
import type { CalendarScope } from '../calendar-store/types.tsx';

function buildBaseQuery(scope: CalendarScope) {
	return scope.type === 'plan' ? '' : createBaseQuery(scope.projectKeys);
}

/**
 * Custom hook that returns the base JQL query for the calendar based on calendar scope.
 *
 * TODO: For board scoped calendar, this should be the board JQL query as saved
 * in the board settings page. This controls the filter VALUES that will be shown.
 *
 * @returns An object containing the base JQL query.
 */
export function useJqlBaseQuery(): {
	baseQuery: string;
} {
	const scope = useCalendarScope();

	const baseQuery = useMemo<string>(() => buildBaseQuery(scope), [scope]);
	return { baseQuery };
}
