import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import { batchActions } from 'redux-batched-actions';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { fg } from '@atlassian/jira-feature-gating';
import { getFieldTypesEligibleForJsonData } from '@atlassian/jira-servicedesk-queues-common/src/json-fields/index.tsx';
import { FETCH_SIZE_IN_INITIAL_LOAD } from '@atlassian/jira-servicedesk-queues-common/src/constants/index.tsx';
import { LOAD_ISSUES_ACTION_SOURCE_PAGE } from '../../../model/index.tsx';
import { maybeIssuesInLocalStorage } from '../../../services/issue/local-storage/index.tsx';
import { createSortedQueue } from '../../../services/sort/index.tsx';
import { experienceTrackingResetAction } from '../../../state/actions/experience-tracking/index.tsx';
import { filterQueryChangedAction } from '../../../state/actions/filter/index.tsx';
import { loadIssuesAction, loadIssuesSuccessAction } from '../../../state/actions/issue/index.tsx';
import { reloadPageDataAction } from '../../../state/actions/page/index.tsx';
import { QUEUE_CHANGED, setQueueAction } from '../../../state/actions/queue/index.tsx';
import { pageChangedAction } from '../../../state/actions/table/index.tsx';
import type { Action } from '../../../state/actions/types.tsx';
import type { State } from '../../../state/reducers/types.tsx';
import { getCreateAnalyticsEvent } from '../../../state/selectors/analytic/index.tsx';
import { isQueueMateriallyDifferent } from '../../../state/selectors/queue/index.tsx';
import { hasPageError } from '../../../state/selectors/ui/index.tsx';
import { getIsInitialLoad } from '../../../state/selectors/ssr/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(QUEUE_CHANGED).switchMap((action) => {
		const state = store.getState();
		const isInitialLoad = getIsInitialLoad(state);
		const { queue } = action.payload;

		const createAnalyticsEvent = getCreateAnalyticsEvent(state);

		const sortedQueue = fg('clean_local_storage_sorting')
			? queue
			: createSortedQueue(queue, createAnalyticsEvent);

		const queueId = sortedQueue.id;

		if (hasPageError(state)) {
			return Observable.of(reloadPageDataAction(queueId));
		}

		if (!isQueueMateriallyDifferent(state, sortedQueue)) {
			return Observable.of(setQueueAction(sortedQueue));
		}

		const columnTypesAsJson = getFieldTypesEligibleForJsonData();
		// remove manual sorting with clean_local_storage_sorting feature flag removal
		const { columns, jql, manualSorting } = sortedQueue;

		// in initial load, fetch a smaller batch size for performance concerns
		const shouldOverwriteFetchSize =
			fg('issuelist_reduce_issue_count_in_initial_load') && isInitialLoad;

		const actions = [
			experienceTrackingResetAction(), // Need to reset experience tracking before/with changing the queueId
			...[filterQueryChangedAction(null)],
			setQueueAction(sortedQueue),
			pageChangedAction(1),
			loadIssuesAction(
				0,
				LOAD_ISSUES_ACTION_SOURCE_PAGE,
				true,
				shouldOverwriteFetchSize ? FETCH_SIZE_IN_INITIAL_LOAD : undefined,
			),
		];

		// clean up with clean_local_storage_sorting feature flag removal
		const sortedBy = manualSorting && manualSorting.sortedBy;
		const sortOrder = manualSorting && manualSorting.sortOrder;
		return maybeIssuesInLocalStorage(
			queueId,
			jql,
			columns,
			columnTypesAsJson,
			null,
			// remove next two lines with clean_local_storage_sorting feature flag removal
			fg('clean_local_storage_sorting') ? undefined : sortedBy,
			fg('clean_local_storage_sorting') ? undefined : sortOrder,
		).cata(
			() => Observable.of(batchActions(actions)),
			// @ts-expect-error - TS7006 - Parameter 'issues' implicitly has an 'any' type.
			(issues) =>
				Observable.of(
					batchActions([
						...actions,
						loadIssuesSuccessAction(issues, queueId, 0, LOAD_ISSUES_ACTION_SOURCE_PAGE),
					]),
				),
		);
	});
