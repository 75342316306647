import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { FieldDataSelectorChildrenProps } from '@atlassian/jira-issue-table/src/model/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import log from '../../../../log/index.tsx';
import type { Issue } from '../../../../model/index.tsx';
import type { ResponseField } from '../../../../rest/issue/fields/types.tsx';
import type { HtmlFieldResponse } from '../../../../rest/issue/types.tsx';
import { htmlTransformer } from './html-fields/index.tsx';
import type {
	TransformerInput,
	TransformableField,
	StoredFieldUpdater,
} from './json-fields/common/types.tsx';
import { getHtmlOverrideFieldTransformer, getJsonTransformer } from './json-fields/index.tsx';

const LOCATION = 'servicedesk.queues.selectors.fields-transformers';

const skipTransformer = (fieldType: string, field: ResponseField) => {
	const errMessage = `No transformer for the field of type '${fieldType}', passing it straight to issue-table from redux`;
	fireErrorAnalytics({
		meta: {
			id: 'selectorsFieldsTransformers',
			packageName: 'jiraServicedeskQueuesAgentView',
			teamName: 'jsd-shield',
		},
		error: new Error(errMessage),
	});
	log.safeErrorWithoutCustomerData(LOCATION, errMessage);

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return {
		// @ts-expect-error - TS2352 - Conversion of type '{ fieldId: string; value: User | null; isEditable: boolean; noJsonFieldTransformer: boolean; fieldType: string; } | { fieldId: string; value: User; isEditable: boolean; noJsonFieldTransformer: boolean; fieldType: string; } | ... 9 more ... | { ...; }' to type 'FieldDataSelectorChildrenProps' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
		noJsonFieldTransformer: true,
		fieldType,
		...field,
	} as FieldDataSelectorChildrenProps;
};

export const transformField = (
	issue: Issue,
	field: ResponseField,
	fieldType: string,
	storedFieldUpdater: StoredFieldUpdater<TransformableField>,
): FieldDataSelectorChildrenProps => {
	const htmlOverrideFieldTransformer = getHtmlOverrideFieldTransformer(fieldType);
	if (htmlOverrideFieldTransformer) {
		return htmlOverrideFieldTransformer(issue, field);
	}

	// @ts-expect-error - TS2339 - Property 'fieldAsHtml' does not exist on type 'ResponseField'. | TS2339 - Property 'fieldCssClass' does not exist on type 'ResponseField'.
	if (field.fieldAsHtml != null || field.fieldCssClass != null) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return htmlTransformer(field as HtmlFieldResponse);
	}

	const jsonFieldTransformer = getJsonTransformer(fieldType);
	if (!jsonFieldTransformer) {
		return skipTransformer(fieldType, field);
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const storedValue = field as TransformableField;

	const transformerInputParams: TransformerInput = {
		storedValue,
		storedFieldUpdater,
	};

	if (fg('handle-queues-column-exceptions')) {
		// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'GenericFieldDataTransformerInput<AssigneeFieldResponse> & GenericFieldDataTransformerInput<CreatorFieldResponse> & ... 9 more ... & GenericFieldDataTransformerInput<...>'.
		const transformedFieldData = jsonFieldTransformer(transformerInputParams);

		// @ts-expect-error - TS2339 - Property 'value' does not exist on type 'unknown'.
		const { value: responseValue } = storedValue;

		if (responseValue && responseValue.error) {
			return {
				...transformedFieldData,
				value: responseValue,
			};
		}
		return transformedFieldData;
	}

	// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'GenericFieldDataTransformerInput<AssigneeFieldResponse> & GenericFieldDataTransformerInput<CreatorFieldResponse> & ... 9 more ... & GenericFieldDataTransformerInput<...>'.
	return jsonFieldTransformer(transformerInputParams);
};
