import React from 'react';
import { Text } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	from: number;
	to: number;
	total: number;
	hasSurpassedMaxCount?: boolean;
	testId?: string;
};

export function PageInfo({ testId, from, to, total, hasSurpassedMaxCount = false }: Props) {
	const { formatMessage } = useIntl();
	return (
		<Text testId={testId}>
			{formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.paginationInfoIssueTermRefresh
					: messages.paginationInfo,
				{ from, to, total, hasSurpassedMaxCount },
			)}
		</Text>
	);
}
