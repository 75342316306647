/**
 * @generated SignedSource<<ab6152df18dc9fbd606df1b0b496ccfe>>
 * @relayHash 7c9074eed631b0ec1a0765cc13355f78
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2bf471b7f62bc544fcf6ba07c90ed8abb855c77504c411d0d4708200c5f771fc

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraCalendarMode = "DAY" | "MONTH" | "WEEK" | "%future added value";
export type JiraCalendarWeekStart = "MONDAY" | "SATURDAY" | "SUNDAY" | "%future added value";
export type JiraSprintState = "ACTIVE" | "CLOSED" | "FUTURE" | "%future added value";
export type JiraVersionStatus = "ARCHIVED" | "RELEASED" | "UNRELEASED" | "%future added value";
export type JiraCalendarViewConfigurationInput = {
  date?: AGG$DateTime | null | undefined;
  endDateField?: string | null | undefined;
  mode?: JiraCalendarMode | null | undefined;
  startDateField?: string | null | undefined;
  viewId?: string | null | undefined;
  weekStart?: JiraCalendarWeekStart | null | undefined;
};
export type JiraCalendarCrossProjectVersionsInput = {
  activeWithin?: JiraDateTimeWindow | null | undefined;
  searchString?: string | null | undefined;
  statuses?: ReadonlyArray<JiraVersionStatus | null | undefined> | null | undefined;
};
export type JiraDateTimeWindow = {
  end?: AGG$DateTime | null | undefined;
  start?: AGG$DateTime | null | undefined;
};
export type JiraViewScopeInput = {
  ids?: ReadonlyArray<string> | null | undefined;
  projectKeys?: JiraProjectKeysInput | null | undefined;
};
export type JiraProjectKeysInput = {
  cloudId: string;
  keys?: ReadonlyArray<string> | null | undefined;
};
export type JiraCalendarSprintsInput = {
  sprintStates?: ReadonlyArray<JiraSprintState> | null | undefined;
};
export type calendarRenderer_jira_refetchQuery$variables = {
  configurationInput: JiraCalendarViewConfigurationInput;
  crossProjectVersionsSearchInput?: JiraCalendarCrossProjectVersionsInput | null | undefined;
  scopeInput: JiraViewScopeInput;
  skipCrossProjectVersionSearch: boolean;
  skipSprintSearch: boolean;
  sprintsSearchInput?: JiraCalendarSprintsInput | null | undefined;
};
export type calendarRenderer_jira_refetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"calendarRenderer_jira">;
};
export type calendarRenderer_jira_refetchQuery = {
  response: calendarRenderer_jira_refetchQuery$data;
  variables: calendarRenderer_jira_refetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "configurationInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "crossProjectVersionsSearchInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scopeInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skipCrossProjectVersionSearch"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skipSprintSearch"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sprintsSearchInput"
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "name": "canPerform"
  }
],
v2 = {
  "args": [
    {
      "kind": "Literal",
      "name": "type",
      "value": "CREATE_ISSUES"
    }
  ],
  "concreteType": "JiraProjectAction",
  "kind": "LinkedField",
  "name": "action",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": "action(type:\"CREATE_ISSUES\")"
},
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = {
  "kind": "ScalarField",
  "name": "hasNextPage"
},
v5 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "name": "hasPreviousPage"
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "name": "name"
},
v7 = {
  "kind": "ScalarField",
  "name": "startDate"
},
v8 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v9 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v10 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v11 = [
  (v10/*: any*/),
  {
    "kind": "ScalarField",
    "name": "fieldId"
  },
  (v3/*: any*/)
],
v12 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 30
  }
],
v13 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v14 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v15 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v16 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "endCursor"
    },
    (v4/*: any*/)
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "calendarRenderer_jira_refetchQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "configurationInput",
            "variableName": "configurationInput"
          },
          {
            "kind": "Variable",
            "name": "crossProjectVersionsSearchInput",
            "variableName": "crossProjectVersionsSearchInput"
          },
          {
            "kind": "Variable",
            "name": "scopeInput",
            "variableName": "scopeInput"
          },
          {
            "kind": "Variable",
            "name": "skipCrossProjectVersionSearch",
            "variableName": "skipCrossProjectVersionSearch"
          },
          {
            "kind": "Variable",
            "name": "skipSprintSearch",
            "variableName": "skipSprintSearch"
          },
          {
            "kind": "Variable",
            "name": "sprintsSearchInput",
            "variableName": "sprintsSearchInput"
          }
        ],
        "kind": "FragmentSpread",
        "name": "calendarRenderer_jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "calendarRenderer_jira_refetchQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "configuration",
                "variableName": "configurationInput"
              },
              {
                "kind": "Variable",
                "name": "scope",
                "variableName": "scopeInput"
              }
            ],
            "concreteType": "JiraCalendar",
            "kind": "LinkedField",
            "name": "jiraCalendar",
            "plural": false,
            "selections": [
              {
                "alias": "projectsPermission",
                "concreteType": "JiraProjectConnection",
                "kind": "LinkedField",
                "name": "projects",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraProjectEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "projectId"
                          },
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "condition": "skipCrossProjectVersionSearch",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "input",
                        "variableName": "crossProjectVersionsSearchInput"
                      }
                    ],
                    "concreteType": "JiraCrossProjectVersionConnection",
                    "kind": "LinkedField",
                    "name": "crossProjectVersions",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "concreteType": "JiraCrossProjectVersionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraCrossProjectVersion",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "releaseDate"
                              },
                              {
                                "concreteType": "JiraCrossProjectVersionPlanScenarioValues",
                                "kind": "LinkedField",
                                "name": "crossProjectVersionScenarioValues",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "scenarioType"
                                  }
                                ]
                              },
                              {
                                "kind": "ScalarField",
                                "name": "status"
                              },
                              {
                                "concreteType": "JiraProject",
                                "kind": "LinkedField",
                                "name": "projects",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  (v6/*: any*/),
                                  (v8/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "projectTypeName"
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "condition": "skipSprintSearch",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "input",
                        "variableName": "sprintsSearchInput"
                      }
                    ],
                    "concreteType": "JiraSprintConnection",
                    "kind": "LinkedField",
                    "name": "sprints",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "concreteType": "JiraSprintEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraSprint",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "sprintId"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "boardId"
                              },
                              (v6/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "goal"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "state"
                              },
                              (v7/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "endDate"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "completionDate"
                              },
                              (v9/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "name": "startDateField",
                "plural": false,
                "selections": (v11/*: any*/)
              },
              {
                "kind": "LinkedField",
                "name": "endDateField",
                "plural": false,
                "selections": (v11/*: any*/)
              },
              {
                "args": (v12/*: any*/),
                "concreteType": "JiraProjectConnection",
                "kind": "LinkedField",
                "name": "projects",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraProjectEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v6/*: any*/),
                          (v8/*: any*/),
                          (v2/*: any*/),
                          {
                            "args": (v13/*: any*/),
                            "concreteType": "JiraIssueTypeConnection",
                            "kind": "LinkedField",
                            "name": "issueTypes",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueType",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v6/*: any*/),
                                      {
                                        "concreteType": "JiraAvatar",
                                        "kind": "LinkedField",
                                        "name": "avatar",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "small"
                                          }
                                        ]
                                      },
                                      {
                                        "concreteType": "JiraIssueTypeHierarchyLevel",
                                        "kind": "LinkedField",
                                        "name": "hierarchy",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "level"
                                          }
                                        ]
                                      },
                                      (v9/*: any*/),
                                      (v10/*: any*/)
                                    ]
                                  },
                                  (v14/*: any*/)
                                ]
                              },
                              (v15/*: any*/),
                              (v16/*: any*/)
                            ],
                            "storageKey": "issueTypes(first:10)"
                          },
                          {
                            "args": (v13/*: any*/),
                            "handle": "connection",
                            "key": "inlineCardCreate_calendar_issueTypes",
                            "kind": "LinkedHandle",
                            "name": "issueTypes"
                          },
                          {
                            "alias": "admin",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "type",
                                "value": "EDIT_PROJECT_CONFIG"
                              }
                            ],
                            "concreteType": "JiraProjectAction",
                            "kind": "LinkedField",
                            "name": "action",
                            "plural": false,
                            "selections": (v1/*: any*/),
                            "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "projectType"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "projectStyle"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "key"
                          },
                          (v10/*: any*/)
                        ]
                      },
                      (v14/*: any*/)
                    ]
                  },
                  (v15/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": "projects(first:30)"
              },
              {
                "args": (v12/*: any*/),
                "handle": "connection",
                "key": "inlineCardCreate_calendar_projects",
                "kind": "LinkedHandle",
                "name": "projects"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "2bf471b7f62bc544fcf6ba07c90ed8abb855c77504c411d0d4708200c5f771fc",
    "metadata": {},
    "name": "calendarRenderer_jira_refetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "8183c9ec4329ff42f1e3698e4bdce0bb";

export default node;
