import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import type { inlineCardCreate_calendar$key } from '@atlassian/jira-relay/src/__generated__/inlineCardCreate_calendar.graphql';
import { useCalendarUFOExperienceContext } from '../../../../../controllers/ufo-context-provider/index.tsx';
import { CalendarInlineCardCreate } from './main.tsx';

export interface InlineCardCreateProps {
	setInitialFocusRef: (element: HTMLElement | null) => void;
	queryRef: inlineCardCreate_calendar$key;
	connectionId?: string;
	startDate: Date;
	endDate: Date;
	onClose?: () => void;
}

export function InlineCardCreate({ queryRef, ...dateProps }: InlineCardCreateProps) {
	const projectsData = useFragment(
		graphql`
			fragment inlineCardCreate_calendar on Query
			@argumentDefinitions(
				scopeInput: { type: "JiraViewScopeInput!" }
				configurationInput: { type: "JiraCalendarViewConfigurationInput!" }
			) {
				...main_calendar_CalendarInlineCardCreate
					@arguments(scopeInput: $scopeInput, configurationInput: $configurationInput)
			}
		`,
		queryRef,
	);

	const { packageName, teamName } = useCalendarUFOExperienceContext();

	return (
		<JSErrorBoundary
			id="jira-calendar-icc"
			packageName={packageName}
			teamName={teamName}
			fallback="flag"
		>
			<CalendarInlineCardCreate queryRef={projectsData} {...dateProps} />
		</JSErrorBoundary>
	);
}
