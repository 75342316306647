import type { AGGError } from '@atlassian/jira-relay-errors/src/index.tsx';

export type GraphQLErrors =
	| AGGError[]
	| readonly {
			readonly extensions:
				| {
						readonly errorType: string | null | undefined;
						readonly statusCode: number | null | undefined;
				  }
				| null
				| undefined;
			readonly message: string | null | undefined;
	  }[]
	| null
	| undefined;

export class CalendarGraphQLError extends Error {
	traceId: string | undefined;

	statusCode: number | undefined;

	message: string;

	constructor(
		public errorMessage: string,
		public errors: GraphQLErrors,
		public traceIds?: string[],
	) {
		super(errorMessage);
		this.errors = errors;

		// Get first trace id
		this.traceId = traceIds?.[0];

		// Get first status code
		this.statusCode = errors
			?.map((graphQLError) =>
				'extensions' in graphQLError ? graphQLError?.extensions?.statusCode : null,
			)
			.filter(Boolean)?.[0];

		// Combine error messages
		this.message =
			errors
				?.map((graphQLError) => graphQLError?.message)
				.filter(Boolean)
				.join('; ') ?? errorMessage;
	}
}
