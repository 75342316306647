/** @jsx jsx */
import { useEffect, useState } from 'react';
import { css, jsx } from '@compiled/react';
import { graphql, usePaginationFragment } from 'react-relay';
import Button from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { MAX_SUMMARY_LENGTH } from '@atlassian/jira-platform-inline-card-create/src/common/constants.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { inlineCardCreate_calendar_RefetchPaginated_Query } from '@atlassian/jira-relay/src/__generated__/inlineCardCreate_calendar_RefetchPaginated_Query.graphql';
import type { main_calendar_CalendarInlineCardCreate$key } from '@atlassian/jira-relay/src/__generated__/main_calendar_CalendarInlineCardCreate.graphql';
import { useCalendarCapabilities } from '../../../../../common/controllers/capabilities-provider/index.tsx';
import { useCreateCalendarIssueMutation } from '../../../../../controllers/use-create-calendar-issue-mutation/index.tsx';
import { type IssueTypeOption, IssueTypeField } from './issue-type-field/index.tsx';
import { messages } from './messages.tsx';
import {
	PROJECT_FIELD_NEXT_PAGE_OPTION_VALUE,
	ProjectField,
	type ProjectFieldOption,
} from './project-field/index.tsx';

interface FormValues {
	project?: ProjectFieldOption | null;
	summary: string;
	issueType?: IssueTypeOption | null;
}

interface InlineCardCreateProps {
	setInitialFocusRef: (element: HTMLElement | null) => void;
	queryRef: main_calendar_CalendarInlineCardCreate$key;
	connectionId?: string;
	startDate: Date;
	endDate: Date;
	onClose?: () => void;
}

export function CalendarInlineCardCreate({
	setInitialFocusRef,
	queryRef,
	connectionId,
	startDate,
	endDate,
	onClose,
}: InlineCardCreateProps) {
	const { formatMessage } = useIntl();
	const { hasBusinessExperiment } = useCalendarCapabilities();
	const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
		inlineCardCreate_calendar_RefetchPaginated_Query,
		main_calendar_CalendarInlineCardCreate$key
	>(
		graphql`
			fragment main_calendar_CalendarInlineCardCreate on Query
			@argumentDefinitions(
				cursor: { type: "String" }
				first: { type: "Int", defaultValue: 30 }
				scopeInput: { type: "JiraViewScopeInput!" }
				configurationInput: { type: "JiraCalendarViewConfigurationInput!" }
			)
			@refetchable(queryName: "inlineCardCreate_calendar_RefetchPaginated_Query") {
				jira {
					jiraCalendar(scope: $scopeInput, configuration: $configurationInput)
						@optIn(to: "JiraCalendar") {
						startDateField {
							fieldId
						}
						endDateField {
							fieldId
						}
						projects(first: $first, after: $cursor)
							@connection(key: "inlineCardCreate_calendar_projects") {
							edges {
								node {
									id
									name
									avatar {
										xsmall
									}
									action(type: CREATE_ISSUES) {
										canPerform
									}
									...issueTypeField_calendar
								}
							}
							totalCount
						}
					}
				}
			}
		`,
		queryRef,
	);

	const loadNextHandler = (...args: Parameters<typeof loadNext>) => {
		fireUIAnalytics(createAnalyticsEvent({}), 'projectField loadedNextPage', 'jiraCalendarViewIcc');

		return loadNext(...args);
	};

	const projectOptions = data.jira?.jiraCalendar?.projects?.edges
		?.filter((project) => {
			if (!hasBusinessExperiment) {
				return true;
			}

			if (!project || !project.node || !project.node.action) {
				return false;
			}

			return project.node.action.canPerform;
		})
		?.map<ProjectFieldOption | undefined>((project) => {
			// TODO: Remove below as it is unreachable once business_projects_shared_calendar is cleaned up
			if (!project || !project.node) {
				return undefined;
			}

			return {
				iconUrl: project.node.avatar?.xsmall ?? '',
				label: project.node.name ?? '',
				value: project.node.id ?? '',
				ref: project.node,
			};
		})
		.filter((project): project is ProjectFieldOption => Boolean(project));

	if (hasNext) {
		projectOptions?.push({
			iconUrl: '',
			label: '',
			value: PROJECT_FIELD_NEXT_PAGE_OPTION_VALUE,
			isDisabled: true,
		});
	}

	const [formValues, setFormValues] = useState<FormValues>({
		project: projectOptions?.[0],
		summary: '',
	});

	const { createCalendarIssue } = useCreateCalendarIssueMutation({
		connectionId,
		dateFieldIds: {
			startDateId: data.jira?.jiraCalendar?.startDateField?.fieldId ?? '',
			endDateId: data.jira?.jiraCalendar?.endDateField?.fieldId ?? 'duedate',
		},
	});

	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'popup opened', 'jiraCalendarViewIcc', {
			numProjects: data.jira?.jiraCalendar?.projects?.totalCount,
		});
	}, [createAnalyticsEvent, data.jira?.jiraCalendar?.projects?.totalCount]);

	const handleSubmit = (event?: React.FormEvent | React.MouseEvent) => {
		event?.preventDefault();

		fireUIAnalytics(createAnalyticsEvent({}), 'form submitted', 'jiraCalendarViewIcc');

		if (!formValues.issueType || !formValues.project) {
			return;
		}

		createCalendarIssue({
			issueTypeRecordId: formValues.issueType?.id,
			issueTypeAri: formValues.issueType?.value,
			summary: formValues.summary.trim(),
			projectAri: formValues.project?.value,
			startDate: startDate.getTime() === endDate.getTime() ? undefined : startDate,
			endDate,
		});

		onClose?.();
	};

	return (
		<Box xcss={wrapperStyles}>
			<form onSubmit={handleSubmit}>
				{projectOptions && projectOptions.length > 1 && (
					<ProjectField
						options={projectOptions}
						onChange={(selectedOption) => {
							setFormValues((state) => ({ ...state, project: selectedOption }));

							if (formValues.project) {
								fireUIAnalytics(
									createAnalyticsEvent({
										actionSubject: 'projectField',
										action: 'selectionChanged',
									}),
									'projectField selectionChanged',
									'jiraCalendarViewIcc',
								);
							}
						}}
						value={formValues.project}
						isLoadingNext={isLoadingNext}
						loadNext={loadNextHandler}
					/>
				)}
				<textarea
					ref={setInitialFocusRef}
					aria-label={formatMessage(messages.summaryFieldLabel)}
					css={textareaStyles}
					maxLength={MAX_SUMMARY_LENGTH}
					placeholder={formatMessage(messages.summaryPlaceholder)}
					onChange={(event) => {
						const content = event.target.value.replace(/[\n\r]+/g, ' ');
						setFormValues((state) => ({ ...state, summary: content }));
					}}
					onKeyDown={(event) => {
						if (event.key === 'Enter' && !event.nativeEvent.isComposing) {
							event.preventDefault();
							handleSubmit();
						}
					}}
					value={formValues.summary}
				/>
				<Box xcss={footerStyles}>
					<Box xcss={issueTypeFieldWrapperStyles}>
						<IssueTypeField
							queryRef={formValues.project?.ref ?? null}
							onChange={(selectedOption) => {
								setFormValues((state) => ({ ...state, issueType: selectedOption }));
								if (formValues.issueType) {
									fireUIAnalytics(
										createAnalyticsEvent({}),
										'issueTypeField selectionChanged',
										'jiraCalendarViewIcc',
									);
								}
							}}
							value={formValues.issueType}
						/>
					</Box>
					<Button
						appearance="primary"
						spacing="compact"
						type="submit"
						onClick={handleSubmit}
						isDisabled={formValues.summary.trim().length === 0}
					>
						{formatMessage(messages.createIssue)}
					</Button>
				</Box>
			</form>
		</Box>
	);
}

const wrapperStyles = xcss({
	width: '260px',
	padding: 'space.100',
});

const textareaStyles = css({
	boxSizing: 'border-box',
	width: '100%',
	border: 'none',
	outline: 'none',
	margin: 0,
	marginBottom: token('space.100'),
	paddingTop: token('space.050'),
	paddingRight: token('space.050'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.050'),
	resize: 'none',
	backgroundColor: token('elevation.surface'),
	color: token('color.text'),
	font: token('font.body'),

	'&::placeholder': {
		color: token('color.text.subtlest'),
		opacity: 1,
	},
});

const issueTypeFieldWrapperStyles = xcss({
	flexShrink: 1,
	minWidth: '0',
});

const footerStyles = xcss({
	display: 'flex',
	width: '100%',
	justifyContent: 'space-between',
	gap: 'space.150',
});
