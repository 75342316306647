import { useMemo } from 'react';
import type { JiraCalendarIssuesInput } from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery.graphql';
import { createAdditionalFilterQuery } from '../../common/utils/jql-query-helper/index.tsx';
import { useExtraQuery, useHideDoneItems } from '../calendar-store/index.tsx';

export function useIssuesSearchInput(): {
	issuesSearchInput: JiraCalendarIssuesInput;
} {
	const extraQuery = useExtraQuery();

	const isHidingDoneItems = useHideDoneItems();

	const issuesSearchInput = useMemo<JiraCalendarIssuesInput>(
		() => ({
			additionalFilterQuery: createAdditionalFilterQuery(extraQuery, isHidingDoneItems),
		}),
		[extraQuery, isHidingDoneItems],
	);

	return { issuesSearchInput };
}
