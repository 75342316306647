/**
 * @generated SignedSource<<5904aa2cdd9d341180cdb195564e4140>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type filterSection_softwareFiltersPopup$data = {
  readonly totalCount: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"filterOptions_softwareFiltersPopup">;
  readonly " $fragmentType": "filterSection_softwareFiltersPopup";
};
export type filterSection_softwareFiltersPopup$key = {
  readonly " $data"?: filterSection_softwareFiltersPopup$data;
  readonly " $fragmentSpreads": FragmentRefs<"filterSection_softwareFiltersPopup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "filterSection_softwareFiltersPopup",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "filterOptions_softwareFiltersPopup"
    },
    {
      "kind": "ScalarField",
      "name": "totalCount"
    }
  ],
  "type": "JiraJqlFieldValueConnection"
};

(node as any).hash = "6a1ff4332fcb28ea95eb107ce52b69d9";

export default node;
