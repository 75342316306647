import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	message: {
		id: 'shared-software-dates.upsell-banner.message',
		defaultMessage:
			'To select {maxConnectedProjects, plural, one {# project} other {up to # projects}}, upgrade to Premium.<cta>Try it for free.</cta>',
		description: 'Upsell message below the projects picker for admin user with billing permission',
	},
	messageNoBillingPermission: {
		id: 'shared-software-dates.upsell-banner.message-no-billing-permission',
		defaultMessage: 'To select multiple projects, ask your admin to upgrade to Premium.',
		description:
			'Upsell message below the projects picker for admin user without billing permission',
	},
});
