import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { useQueryLoader } from 'react-relay';
import { Inline, Stack } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import type {
	moreOptionsFilterDropdownContentInner_softwareFiltersPopup_Query,
	moreOptionsFilterDropdownContentInner_softwareFiltersPopup_Query$variables,
} from '@atlassian/jira-relay/src/__generated__/moreOptionsFilterDropdownContentInner_softwareFiltersPopup_Query.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { messages } from './messages.tsx';
import {
	type MoreOptionsFilterDropdownContentInnerProps,
	QUERY,
	MoreOptionsFilterDropdownContentInner,
} from './more-options-filter-dropdown-content-inner/index.tsx';

/**
 * We refetch the field options whenever the search input field changes.
 */
export function useRefetchOnSearchChange({
	search,
	fieldName,
	cloudId,
	loadQuery,
}: {
	search: string;
	fieldName: string;
	cloudId: string;
	loadQuery: (
		variables: moreOptionsFilterDropdownContentInner_softwareFiltersPopup_Query$variables,
	) => void;
}) {
	const variables = useMemo(
		() => ({
			fieldName,
			cloudId,
			searchString: search,
		}),
		[cloudId, fieldName, search],
	);
	const previousVariables = useRef(variables);
	const doRefetch = useMemo(
		() =>
			debounce(
				(v: moreOptionsFilterDropdownContentInner_softwareFiltersPopup_Query$variables) =>
					loadQuery(v),
				200,
			),
		[loadQuery],
	);
	useEffect(() => {
		if (isEqual(previousVariables.current, variables)) return;

		previousVariables.current = variables;

		doRefetch(variables);
	}, [cloudId, variables, doRefetch, search]);
}

/**
 * The contents of the "More options filter dropdown", renders
 * a search input and an infinite scrolling paginated list of
 * filter options for a certain field.
 */
export function MoreOptionsFilterDropdownContent({
	fieldName,
	jqlModel,
	onToggleFilter,
}: Pick<MoreOptionsFilterDropdownContentInnerProps, 'fieldName' | 'jqlModel' | 'onToggleFilter'>) {
	// TODO: call load query outside of this
	const cloudId = useCloudId();
	const [queryRef, loadQuery] =
		useQueryLoader<moreOptionsFilterDropdownContentInner_softwareFiltersPopup_Query>(QUERY);
	useEffect(() => {
		loadQuery({
			fieldName,
			cloudId,
		});
	}, [cloudId, fieldName, loadQuery]);

	const [search, setSearch] = useState('');
	const onChangeSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value);
	}, []);
	useRefetchOnSearchChange({ search, fieldName, cloudId, loadQuery });

	const fallback = (
		<Stack grow="fill" alignBlock="center" alignInline="center">
			<Spinner />
		</Stack>
	);
	const { formatMessage } = useIntl();

	return (
		<FieldValuesContentContainer>
			<Inline>
				<Textfield
					testId="software-filters-popup.ui.filter-section.filter-options.more-options-filter-dropdown-content.textfield"
					isCompact
					placeholder={formatMessage(messages.searchPlaceholder)}
					onChange={onChangeSearch}
					value={search ?? ''}
				/>
			</Inline>

			<FieldValuesScrollContainer>
				<Placeholder fallback={fallback} name="FiltersPopupOptions">
					{queryRef ? (
						<MoreOptionsFilterDropdownContentInner
							fieldName={fieldName}
							jqlModel={jqlModel}
							onToggleFilter={onToggleFilter}
							queryRef={queryRef}
						/>
					) : (
						fallback
					)}
				</Placeholder>
			</FieldValuesScrollContainer>
		</FieldValuesContentContainer>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldValuesContentContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	width: 300,
	height: 300,
	paddingTop: token('space.100', '8px'),
	paddingRight: token('space.100', '8px'),
	paddingBottom: token('space.100', '8px'),
	paddingLeft: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldValuesScrollContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
	flex: 1,
	overflowY: 'scroll',
	overflowX: 'hidden',
});
