import React, { useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { editReleaseItem_calendar$key } from '@atlassian/jira-relay/src/__generated__/editReleaseItem_calendar.graphql';
import { ActionMenuItem } from '@atlassian/jira-release-flyout/src/ui/actions-menu/index.tsx';
import { messages } from './messages.tsx';

export const EditReleaseItem = ({
	versionRef,
	onEditRelease,
	onClosePopup,
}: {
	versionRef: editReleaseItem_calendar$key;
	onEditRelease: () => void;
	onClosePopup: () => void;
}) => {
	const { formatMessage } = useIntl();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const data = useFragment(
		graphql`
			fragment editReleaseItem_calendar on JiraVersion {
				canEdit
			}
		`,
		versionRef,
	);

	const onEdit = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'versionEdit',
			}),
			'releaseFlyout',
		);
		onEditRelease();
		onClosePopup();
	}, [createAnalyticsEvent, onClosePopup, onEditRelease]);

	return (
		<ActionMenuItem isDisabled={!data.canEdit} onClick={onEdit}>
			{formatMessage(messages.editRelease)}
		</ActionMenuItem>
	);
};
