import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	am: {
		id: 'calendar.common.utils.dates.am',
		defaultMessage: 'AM',
		description: 'Ante meridiem (before noon) time indicator used in the calendar',
	},
	pm: {
		id: 'calendar.common.utils.dates.pm',
		defaultMessage: 'PM',
		description: 'Post meridiem (after noon) time indicator used in the calendar',
	},
	localTimeString: {
		id: 'calendar.common.utils.dates.local-time-string',
		defaultMessage: '{hours}:{minutes} {ampm}',
		description:
			'Returns localling formatted time string. Hours are in 12-hour format. Eg input: 10 in hours, 14 minutes and AM/PM, and expected to return localised string 10:14 AM',
	},
});
