import { useMemo } from 'react';
import { ConnectionHandler, graphql, useSubscription } from 'react-relay';
import type { onCalendarIssueUpdatedSubscription } from '@atlassian/jira-relay/src/__generated__/onCalendarIssueUpdatedSubscription.graphql.ts';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { isKnownWebSocketError } from '../../../common/utils/is-websocket-error/index.tsx';
import {
	useCalendarRendererConnectionId,
	useUnscheduledPanelConnectionId,
} from '../../../controllers/connection-store/index.tsx';
import { useCalendarExperience } from '../../../controllers/use-calendar-experience/index.tsx';
import { useIssuesSearchInput } from '../../../controllers/use-issues-search-input/index.tsx';
import { useLogger } from '../../../controllers/use-logger/index.tsx';
import { useUnscheduledIssuesSearchInput } from '../../../controllers/use-unscheduled-issues-search-input/index.tsx';
import type { SubscriptionProps } from '../types.tsx';
import { isClientSideResponse, isServerSideResponse } from '../utils.tsx';

export const ERROR_LOCATION = 'calendar.update-calendar-issue.error';
export const ERROR_MESSAGE_FAILED_TO_UPDATE = 'Failed to update issue';
export const INVALID_ISSUE_UPDATED = 'Invalid issue updated';

export const useCalendarIssueUpdated = ({
	projectId,
	scope,
	configuration,
	viewId,
}: SubscriptionProps) => {
	const cloudId = useCloudId();
	const { updateCalendarIssueSubscriptionExperience } = useCalendarExperience();
	const { logError, logWarn } = useLogger();
	const { issuesSearchInput } = useIssuesSearchInput();
	const { unscheduledIssuesSearchInput } = useUnscheduledIssuesSearchInput();

	const calendarRendererConnectionId = useCalendarRendererConnectionId();
	const unscheduledPanelConnectionId = useUnscheduledPanelConnectionId();

	useSubscription<onCalendarIssueUpdatedSubscription>(
		useMemo(
			() => ({
				subscription: graphql`
					subscription onCalendarIssueUpdatedSubscription(
						$scope: JiraViewScopeInput
						$configuration: JiraCalendarViewConfigurationInput
						$cloudId: ID!
						$projectIds: [String!]!
						$viewId: ID
						$issuesSearchInput: JiraCalendarIssuesInput
						$unscheduledIssuesSearchInput: JiraCalendarIssuesInput
					) {
						jira {
							onCalendarIssueUpdated(
								scope: $scope
								configuration: $configuration
								projectIds: $projectIds
								cloudId: $cloudId
								issuesInput: $issuesSearchInput
								unscheduledIssuesInput: $unscheduledIssuesSearchInput
							) {
								scenarioIssueLike {
									__id
									__typename
									... on JiraIssue {
										...issueRenderer_calendar_IssueEventRenderer
											@arguments(viewId: $viewId, schedulePermissionsEnabled: false)
									}
								}
								errors {
									extensions {
										statusCode
									}
									message
								}
								isUnscheduled
								isInScope
							}
						}
					}
				`,
				variables: {
					cloudId,
					scope,
					configuration,
					projectIds: [projectId],
					viewId,
					issuesSearchInput,
					unscheduledIssuesSearchInput,
				},
				onError: (error) => {
					if (isKnownWebSocketError(error)) {
						return;
					}
					logError(ERROR_LOCATION, ERROR_MESSAGE_FAILED_TO_UPDATE, error);
				},
				updater: (store, data) => {
					updateCalendarIssueSubscriptionExperience.start();

					try {
						if (
							data?.jira?.onCalendarIssueUpdated?.errors?.length &&
							data.jira.onCalendarIssueUpdated.errors.length > 0
						) {
							data.jira.onCalendarIssueUpdated.errors.forEach((e) => {
								if (e.extensions?.some((ex) => isClientSideResponse(ex?.statusCode))) {
									logWarn(ERROR_LOCATION, e.message ?? 'Unknown error');
								} else if (e.extensions?.some((ex) => isServerSideResponse(ex?.statusCode))) {
									logError(ERROR_LOCATION, e.message ?? 'Unknown error');
								}
							});

							if (
								data.jira.onCalendarIssueUpdated.errors.some((e) =>
									e.extensions?.some((ex) => isServerSideResponse(ex.statusCode)),
								)
							) {
								// Fail when a server side error is thrown.
								updateCalendarIssueSubscriptionExperience.failure();
							} else {
								// Silently handle when updated issue is out of calendar scope (i.e. 4xx error).
								updateCalendarIssueSubscriptionExperience.abort();
							}
							return;
						}

						const issue = data?.jira?.onCalendarIssueUpdated?.scenarioIssueLike;
						if (!issue) {
							throw Error(INVALID_ISSUE_UPDATED);
						} else if (issue.__typename === 'JiraScenarioIssue') {
							// Silently handle when updated issue is a JiraScenarioIssue.
							updateCalendarIssueSubscriptionExperience.abort();
							return;
						}

						const record = store.get(issue.__id);
						const isPlan = !!record?.getLinkedRecord('planScenarioValues', {
							viewId,
						});

						if (!record) {
							throw Error('Issue missing from store.');
						} else if (isPlan) {
							// Silently handle when previous issue is a JiraScenarioIssue.
							updateCalendarIssueSubscriptionExperience.abort();
							return;
						}

						const calendarRendererRecord = calendarRendererConnectionId
							? store.get(calendarRendererConnectionId)
							: null;
						if (!calendarRendererRecord) {
							throw Error('Unable to get calendar renderer record from store.');
						}

						const unscheduledPanelRecord = unscheduledPanelConnectionId
							? store.get(unscheduledPanelConnectionId)
							: null;

						// Add/remove issue from calendar renderer if in/out of scope
						if (data?.jira?.onCalendarIssueUpdated?.isInScope) {
							const calendarRendererEdge = ConnectionHandler.createEdge(
								store,
								calendarRendererRecord,
								record,
								'JiraIssue',
							);
							ConnectionHandler.insertEdgeBefore(calendarRendererRecord, calendarRendererEdge);
						} else {
							ConnectionHandler.deleteNode(calendarRendererRecord, issue.__id);
						}

						// Add/remove issue from unscheduled panel if in/out of unscheduled scope
						if (data?.jira?.onCalendarIssueUpdated?.isUnscheduled) {
							if (unscheduledPanelRecord) {
								const unscheduledPanelEdge = ConnectionHandler.createEdge(
									store,
									unscheduledPanelRecord,
									record,
									'JiraIssue',
								);
								ConnectionHandler.insertEdgeBefore(unscheduledPanelRecord, unscheduledPanelEdge);
							}
						} else if (unscheduledPanelRecord) {
							ConnectionHandler.deleteNode(unscheduledPanelRecord, issue.__id);
						}

						updateCalendarIssueSubscriptionExperience.success();
					} catch (error) {
						if (error instanceof Error && !isKnownWebSocketError(error)) {
							updateCalendarIssueSubscriptionExperience.failure();
							logError(ERROR_LOCATION, error.message);
						}
					}
				},
			}),
			[
				cloudId,
				scope,
				configuration,
				projectId,
				viewId,
				issuesSearchInput,
				unscheduledIssuesSearchInput,
				logError,
				updateCalendarIssueSubscriptionExperience,
				calendarRendererConnectionId,
				unscheduledPanelConnectionId,
				logWarn,
			],
		),
	);
};
