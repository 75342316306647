import React from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import Lozenge from '@atlaskit/lozenge';
import { Stack, Inline } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { calendarCardStartDate_calendar$key } from '@atlassian/jira-relay/src/__generated__/calendarCardStartDate_calendar.graphql';

const DATE_FORMAT_OPTIONS = {
	year: 'numeric',
	month: 'short',
	day: 'numeric',
} as const;

interface CalendarCardStartDateProps {
	issueRef: calendarCardStartDate_calendar$key;
}

export function CalendarCardStartDate({ issueRef }: CalendarCardStartDateProps) {
	const data = useFragment(
		graphql`
			fragment calendarCardStartDate_calendar on JiraIssue
			@argumentDefinitions(viewId: { type: "ID" }) {
				startDateViewField(viewId: $viewId) @optIn(to: "JiraPlansSupport") {
					name
					... on JiraDatePickerField {
						__typename
						date
					}
					... on JiraDateTimePickerField {
						__typename
						dateTime
					}
				}
			}
		`,
		issueRef,
	);
	const { formatDate } = useIntl();

	const name = data?.startDateViewField?.name;
	const startDate = data?.startDateViewField?.date ?? data?.startDateViewField?.dateTime;

	if (!startDate) return null;

	const dateString = formatDate(startDate, {
		...DATE_FORMAT_OPTIONS,
		...(fg('calendar_unscheduled_start_date_bug') ? { timeZone: 'UTC' } : {}),
	});

	return (
		<Stack
			space="space.050"
			testId="calendar.ui.calendar-unscheduled-panel.calendar-unscheduled-panel-card-list.calendar-unscheduled-panel-card.calendar-card-start-date"
		>
			<FieldLabel>{name}</FieldLabel>
			<Inline grow="hug">
				<Lozenge>{dateString}</Lozenge>
			</Inline>
		</Stack>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldLabel = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N800),
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	font: token('font.body.small'),
	fontWeight: token('font.weight.medium'),
});
