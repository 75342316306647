import log from '@atlassian/jira-common-util-logging/src/log.tsx';

const SIDEBAR_WIDTH_KEY = 'calendar:sidebar:width';

export function retrieveCalendarSidebarWidthFromLocalStorage(): number | undefined {
	try {
		const serializedValue = localStorage.getItem(SIDEBAR_WIDTH_KEY);
		return serializedValue !== null ? Number(JSON.parse(serializedValue)) : undefined;
	} catch {
		return undefined;
	}
}

export function setCalendarSidebarWidthInLocalStorage(value: number) {
	try {
		const serializedValue = JSON.stringify(value);
		localStorage.setItem(SIDEBAR_WIDTH_KEY, serializedValue);
	} catch (error) {
		log.safeErrorWithoutCustomerData(
			'calendar.store-calendar-sidebar-width.error',
			`Error storing calendar sidebar width in local storage: ${error}`,
		);
	}
}
