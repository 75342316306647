/*!
 * Principles taken in this store:
 *
 * * NEVER CREATE A GLOBAL HOOK WITH NO SELECTOR
 * * Document things
 */
import { fg } from '@atlassian/jira-feature-gating';
import {
	type Action,
	createContainer,
	createStore,
	createStateHook,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import { DEFAULT_DAY_STARTS_AT } from '../../common/constants.tsx';
import * as actions from './actions.tsx';
import type {
	CalendarStoreState,
	CalendarState,
	CalendarScope,
	CalendarViewRange,
	CalendarWeekStart,
	CalendarDayStart,
	CalendarIssueEventFields,
	ConsumerCustomApi,
	CalendarColorBy,
} from './types.tsx';
import { dateRangeForDate } from './utils.tsx';

const initialState: CalendarStoreState = { stage: 'pending' };

type CalendarContainerProps = {
	calendarScope: CalendarScope;
	currentDate: Date;
	viewRange?: CalendarViewRange;
	hideDoneItems?: boolean;
	showWeekends?: boolean;
	showVersions?: boolean;
	showSprints?: boolean;
	showIssues?: boolean;
	showIssueKey?: boolean;
	showIn24HourClock?: boolean;
	weekStartsOn?: CalendarWeekStart;
	colorBy?: CalendarColorBy;
	dayStartsAt?: CalendarDayStart;
	issueEventFields?: CalendarIssueEventFields;
	/**
	 * The JQL string as passed through by the JQL builder component.
	 *
	 * This is a "sub-query" on top of the base query that the app is currently using.
	 */
	extraQuery?: string;
} & ConsumerCustomApi;

export const CalendarStoreContainer = createContainer<CalendarContainerProps>({
	displayName: 'CalendarStoreContainer',
});

const CalendarStore = createStore({
	containedBy: CalendarStoreContainer,
	actions,
	initialState,
	handlers: {
		onInit:
			(): Action<CalendarStoreState, CalendarContainerProps> =>
			(
				{ setState },
				{
					calendarScope,
					currentDate,
					viewRange,
					hideDoneItems,
					showWeekends,
					showVersions,
					showSprints,
					showIssues,
					showIssueKey,
					showIn24HourClock,
					weekStartsOn,
					colorBy,
					dayStartsAt,
					issueEventFields,
					extraQuery,
					onScheduled,
					onFiltered,
					onSettingsChanged,
					refetchApiRef,
				}: CalendarContainerProps,
			) => {
				const state: CalendarState = {
					scope: calendarScope,
					stage: 'ready',
					extraQuery: extraQuery ?? '',
					dateRange: dateRangeForDate(currentDate),
					unscheduledPanelState: {
						// TODO: This should be persisted and hydrated on load
						isOpen: false,
						search: null,
						sortDirection: 'DESC',
						extraFilters: null,
						isLoading: false,
						isIssueViewOpen: false, // Added property
						isFilterOpen: false, // Filter option under unscheduled panel
					},
					viewSettingsPanelState: {
						isOpen: false,
					},
					calendarFieldsConfig: {
						isDateTimeConfigured: false,
					},
					hideDoneItems: hideDoneItems ?? false,
					showWeekends: showWeekends ?? false,
					showReleases: showVersions ?? false,
					showSprints: showSprints ?? false,
					showIssues: showIssues ?? true,
					showIssueKey: showIssueKey ?? false,
					showIn24HourClock: showIn24HourClock ?? false,
					viewRange: viewRange ?? 'month',
					weekStartsOn: weekStartsOn ?? 'sunday',
					colorBy: colorBy ?? 'status',
					dayStartsAt: dayStartsAt ?? DEFAULT_DAY_STARTS_AT,
					issueEventFields: issueEventFields ?? {
						startDateField: 'startdate',
						endDateField: 'duedate',
					},
					draggingEvent: null,
					isLoading: false,
					onScheduled,
					onFiltered,
					onSettingsChanged,
					refetchApiRef,
					issuesPageCursor: null,
				};
				setState(state);
			},
	},
});

export const useCalendarActions = createActionsHook(CalendarStore);

/**
 * Create a state hook asserting that the state is ready
 */
function createCalendarStateHook<T>(selector: (state: CalendarState) => T) {
	return createStateHook(CalendarStore, {
		selector: (state) => {
			if (state.stage !== 'ready') {
				throw new Error('Trying to select from pending store');
			}
			return selector(state);
		},
	});
}

export const useCalendarScope = createCalendarStateHook((state) => state.scope);
export const useExtraQuery = createCalendarStateHook((state) => state.extraQuery);
export const useDateRange = createCalendarStateHook((state) => state.dateRange);
export const useUnscheduledPanelState = createCalendarStateHook(
	(state) => state.unscheduledPanelState,
);
export const useIsUnscheduledPanelOpen = createCalendarStateHook(
	(state) => state.unscheduledPanelState.isOpen,
);
export const usePanelExtraFilters = createCalendarStateHook(
	(state) => state.unscheduledPanelState.extraFilters,
);
export const useSortDirection = createCalendarStateHook(
	(state) => state.unscheduledPanelState.sortDirection,
);
export const useIsUnscheduledPanelIssueViewOpen = createCalendarStateHook(
	(state) => state.unscheduledPanelState.isIssueViewOpen,
);
export const useIsUnscheduledPanelFilterOptionOpen = createCalendarStateHook(
	(state) => state.unscheduledPanelState.isFilterOpen,
);
export const useWeekStartsOn = createCalendarStateHook((state) => state.weekStartsOn);
export const useColorBy = createCalendarStateHook((state) => state.colorBy);
export const useDayStartsAt = createCalendarStateHook((state) => state.dayStartsAt);
export const useViewRange = createCalendarStateHook((state) => state.viewRange);
export const useIssueStartDateField = createCalendarStateHook(
	(state) => state.issueEventFields.startDateField,
);
export const useIssueEndDateField = createCalendarStateHook(
	(state) => state.issueEventFields.endDateField,
);
export const useHideDoneItems = createCalendarStateHook((state) => state.hideDoneItems);
export const useShowWeekends = createCalendarStateHook((state) => state.showWeekends);
export const useShowIssueKey = createCalendarStateHook((state) => state.showIssueKey);
export const useShowReleases = createCalendarStateHook((state) => state.showReleases);
export const useShowSprints = createCalendarStateHook((state) => state.showSprints);
export const useShowIn24HourClock = createCalendarStateHook((state) => state.showIn24HourClock);
export const useShowIssues = createCalendarStateHook((state) => state.showIssues);
export const useDraggingEvent = createCalendarStateHook((state) => state.draggingEvent);
export const useCalendarIsLoading = createCalendarStateHook((state) => state.isLoading);
export const useCalendarSettings = createCalendarStateHook((state) => {
	return {
		viewRange: state.viewRange,
		weekStartsOn: state.weekStartsOn,
		colorBy: state.colorBy,
		showWeekends: state.showWeekends,
		showIssueKey: state.showIssueKey,
		showIn24HourClock: state.showIn24HourClock,
		dayStartsAt: state.dayStartsAt,
	};
});
export const useCalendarPanelIsLoading = createCalendarStateHook(
	(state) => state.unscheduledPanelState.isLoading,
);
export const useCalendarRefetchApiRef = createCalendarStateHook((state) => state.refetchApiRef);
export const useCalendarCallbacks = createCalendarStateHook((state) => ({
	onScheduled: state.onScheduled,
	onFiltered: state.onFiltered,
	onSettingsChanged: state.onSettingsChanged,
}));
export const useCalendarOnScheduled = createCalendarStateHook((state) => state.onScheduled);
export const useIsViewSettingsOpen = createCalendarStateHook(
	(state) => state.viewSettingsPanelState.isOpen,
);
export const useIssuesPageCursor = createCalendarStateHook((state) => state.issuesPageCursor);
export const useIsDateTimeConfigured = createCalendarStateHook(
	(state) =>
		fg('jsd_shield_jsm_calendar_weekly_view') && state.calendarFieldsConfig.isDateTimeConfigured,
);
