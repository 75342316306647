import React from 'react';
import { type PreloadedQuery, graphql, usePreloadedQuery } from 'react-relay';
import type { moreOptionsFilterDropdownContentInner_softwareFiltersPopup_Query } from '@atlassian/jira-relay/src/__generated__/moreOptionsFilterDropdownContentInner_softwareFiltersPopup_Query.graphql';
import { FieldValuesList } from '../../../../../common/ui/field-values-list/index.tsx';
import type { JQLModel } from '../../../../../common/utils/jql-model/index.tsx';

export const QUERY = graphql`
	query moreOptionsFilterDropdownContentInner_softwareFiltersPopup_Query(
		$cloudId: ID!
		$fieldName: String!
		$searchString: String
	) {
		...fieldValuesList_softwareFiltersPopup
			@arguments(cloudId: $cloudId, fieldName: $fieldName, searchString: $searchString, first: 20)
	}
`;

export interface MoreOptionsFilterDropdownContentInnerProps {
	/**
	 * Name of the field rendered
	 */
	fieldName: string;
	/**
	 * The JQL model for the current query state, used to check if an option is selected
	 */
	jqlModel: JQLModel;
	/**
	 * Called when an option is clicked with its jqlTerm
	 */
	onToggleFilter: (fieldName: string, value: string) => void;
	/**
	 * Query reference for the initial options to render. The list will handle fetching further
	 * paginated results
	 */
	queryRef: PreloadedQuery<moreOptionsFilterDropdownContentInner_softwareFiltersPopup_Query>;
}

/**
 * Renders the list of filter options inside of a "more options" dropdown.
 */
export function MoreOptionsFilterDropdownContentInner({
	fieldName,
	onToggleFilter,
	jqlModel,
	queryRef,
}: MoreOptionsFilterDropdownContentInnerProps) {
	const data = usePreloadedQuery<moreOptionsFilterDropdownContentInner_softwareFiltersPopup_Query>(
		QUERY,
		queryRef,
	);

	return (
		<FieldValuesList
			fieldName={fieldName}
			queryRef={data ?? null}
			onToggleFilter={onToggleFilter}
			jqlModel={jqlModel}
		/>
	);
}
