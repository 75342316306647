/**
 * @generated SignedSource<<897739c2f9784bd5c6c8d9745294b23d>>
 * @relayHash 8db6e0cd88e1c708a67c7a6bb7fd5b8f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 441de3c880d4177455c0d6e8ae98a6f2af92fa46c233a198cc39edf9e4a252a5

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type editReleaseModalQuery$variables = {
  versionAri: string;
};
export type editReleaseModalQuery$data = {
  readonly jira: {
    readonly version: {
      readonly " $fragmentSpreads": FragmentRefs<"editReleaseForm_softwareReleasesReleaseModalsRelay">;
    };
  };
};
export type editReleaseModalQuery = {
  response: editReleaseModalQuery$data;
  variables: editReleaseModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "versionAri"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "versionAri"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "editReleaseModalQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v1/*: any*/),
                "kind": "LinkedField",
                "name": "version",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "editReleaseForm_softwareReleasesReleaseModalsRelay"
                      }
                    ],
                    "type": "JiraVersion"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.version"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editReleaseModalQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "kind": "LinkedField",
            "name": "version",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "versionId"
                  },
                  (v4/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "startDate"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "releaseDate"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "description"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "status"
                  },
                  {
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "projectId"
                      },
                      (v3/*: any*/)
                    ]
                  },
                  {
                    "concreteType": "JiraVersionDriverResult",
                    "kind": "LinkedField",
                    "name": "driverData",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "driver",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "accountId"
                          },
                          (v4/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "picture"
                          },
                          (v3/*: any*/)
                        ]
                      },
                      {
                        "concreteType": "QueryError",
                        "kind": "LinkedField",
                        "name": "queryError",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "message"
                          }
                        ]
                      }
                    ]
                  }
                ],
                "type": "JiraVersion"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "441de3c880d4177455c0d6e8ae98a6f2af92fa46c233a198cc39edf9e4a252a5",
    "metadata": {},
    "name": "editReleaseModalQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "78bd9d672cd7c6a79f8f0214003cbacd";

export default node;
