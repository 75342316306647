import React, { useCallback, useRef, useEffect } from 'react';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { Flex, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	ANALYTICS_ACTION_ISSUE_SUBJECT,
	ANALYTICS_ACTION_SCHEDULED,
} from '../../../common/constants.tsx';
import { useScheduleErrorFlag } from '../../../common/ui/error-flags/index.tsx';
import { useCalendarActions } from '../../../controllers/calendar-store/index.tsx';
import { moreLinkPopoverBinder } from '../../../controllers/more-link-popover-binder/index.tsx';
import {
	type DroppableCalendarDayCell,
	isDraggableCalendarIssue,
	makeDroppableCalendarDayCell,
} from '../../../services/draggable-card-type/index.tsx';
import type { SlotLaneContentProps, WeekViewCalendarMutation } from './types.tsx';
import { WeekViewDropTargetController, type DragEnterProps } from './utils.tsx';

const SlotLaneContent = ({
	slotLaneArgs,
	calendarMutations,
	showWeekends,
	isDateTimeConfigured,
	tableCellsBySlot,
}: SlotLaneContentProps) => {
	// list number of days to calculate the date for each slot
	const noOfDays = Array.from({ length: showWeekends ? 7 : 5 }, (_, i) => i);
	const { view, time } = slotLaneArgs;

	const slotWrapperRefs = useRef<HTMLDivElement[]>([]);
	const releaseDayCell = useRef(new Map<HTMLElement, () => void>());

	const { showErrorFlag } = useScheduleErrorFlag();

	// fullcalendar would possibly call the stale dayCellDidMount,
	// so we store the latest calendarMutations in a ref and use it in the callbacks.
	const calendarMutationsRef = useRef<WeekViewCalendarMutation>(calendarMutations);
	Object.assign(calendarMutationsRef.current, calendarMutations);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const formattedDate = new Date(view.activeStart);
	// get the time from the slotLaneArgs
	formattedDate.setMilliseconds(time?.milliseconds || 0);

	const { setDraggingEvent } = useCalendarActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const uiAnalyticsEvent = createAnalyticsEvent({
		action: ANALYTICS_ACTION_SCHEDULED,
		actionSubject: ANALYTICS_ACTION_ISSUE_SUBJECT,
	});

	const bindDragAndDrop = useCallback(
		(el: HTMLElement, index: number) => {
			if (!el) {
				return;
			}
			const droppingOverCell = new Date(formattedDate);
			droppingOverCell.setDate(droppingOverCell.getDate() + index);

			const controller = new WeekViewDropTargetController(
				el,
				droppingOverCell,
				calendarMutationsRef.current,
				tableCellsBySlot.current,
				uiAnalyticsEvent,
				isDateTimeConfigured,
				showErrorFlag,
			);

			const unregisterClickEvent = moreLinkPopoverBinder.registerMoreLinkClick(el);
			const release = dropTargetForElements({
				element: el,
				onDragEnter(data: DragEnterProps) {
					const { data: sourceData } = data.source;
					const issueId = typeof sourceData.issueAri === 'string' ? sourceData.issueAri : '';

					setDraggingEvent({ type: 'issue', id: issueId });
					controller.onDragEnter(data);
				},
				onDragLeave() {
					controller.onDragLeave();
					setDraggingEvent(null);
				},
				onDrop(data: DragEnterProps) {
					controller.onDrop(data);
					setDraggingEvent(null);
				},
				getData(args): DroppableCalendarDayCell {
					const { data } = args.source;
					if (isDraggableCalendarIssue(data) && data.draggedDate) {
						const draggedDate = new Date(data.draggedDate);
						const { startDate, endDate } = controller.getDateRange(data);

						// If the current day cell is no more within the range of the dragged event, we want to hide it from the popover.
						if (
							(startDate && startDate.getTime() > draggedDate.getTime()) ||
							endDate.getTime() < draggedDate.getTime()
						) {
							return makeDroppableCalendarDayCell({ isEventDraggedAway: true });
						}
					}

					return makeDroppableCalendarDayCell({ isEventDraggedAway: false });
				},
			});

			tableCellsBySlot.current.set(droppingOverCell.getTime(), el);
			releaseDayCell.current.set(el, () => {
				release();
				tableCellsBySlot.current.delete(droppingOverCell.getTime());
				unregisterClickEvent?.();
			});
		},
		[
			formattedDate,
			isDateTimeConfigured,
			setDraggingEvent,
			showErrorFlag,
			tableCellsBySlot,
			uiAnalyticsEvent,
		],
	);

	useEffect(() => {
		const releaseDayCellRef = releaseDayCell.current;
		const slotWrapper = slotWrapperRefs.current;

		slotWrapper.forEach((child, index) => bindDragAndDrop(child, index));

		return () => {
			releaseDayCellRef.forEach((release) => {
				release();
			});
		};
	}, [bindDragAndDrop, noOfDays]);

	return (
		<Flex direction="row" alignItems="center" xcss={height100Styles}>
			{noOfDays.map((day) => {
				return (
					<Flex
						ref={(el) => {
							slotWrapperRefs.current[day] = el;
						}}
						direction="column"
						justifyContent="space-between"
						key={day}
						xcss={[contentStyles, height100Styles]}
						testId={`calendar.ui.calendar-renderer.slot-lane-content.day-${day}`}
					>
						&nbsp;
					</Flex>
				);
			})}
		</Flex>
	);
};

export { SlotLaneContent };

const height100Styles = xcss({
	height: '100%',
});

const contentStyles = xcss({
	flex: 1,
});
