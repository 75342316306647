import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { Inline, xcss } from '@atlaskit/primitives';
import StatusLozenge from '@atlassian/jira-common-components-status-lozenge/src/view.tsx';
import {
	statusCategoryForId,
	type StatusCategory,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';
import type { calendarCardStatus_calendar$key } from '@atlassian/jira-relay/src/__generated__/calendarCardStatus_calendar.graphql';

interface CalendarCardStatusProps {
	issueRef: calendarCardStatus_calendar$key;
}

export function CalendarCardStatus({ issueRef }: CalendarCardStatusProps) {
	const data = useFragment(
		graphql`
			fragment calendarCardStatus_calendar on JiraIssue {
				statusField {
					status {
						name
						statusCategory {
							statusCategoryId
						}
					}
				}
			}
		`,
		issueRef,
	);

	const statusName = data.statusField?.status?.name;
	const statusCategory = data.statusField?.status?.statusCategory?.statusCategoryId;

	if (!statusName) {
		return null;
	}

	return (
		<Inline xcss={overflowStyles}>
			<StatusLozenge
				name={statusName ?? ''}
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				category={statusCategoryForId(statusCategory ?? '1') as StatusCategory}
			/>
		</Inline>
	);
}

const overflowStyles = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
