import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emailRequestSectionMessageTitle: {
		id: 'servicedesk-email-request-integration-section-message.email-request-section-message.title',
		defaultMessage: 'Discover how requests come into your queues from email',
		description:
			'This text is shown as a section message title on the queues page in Jira Service Management. It informs users about how requests are received via email.',
	},
	emailRequestSectionMessageDescription: {
		id: 'servicedesk-email-request-integration-section-message.email-request-section-message.description',
		defaultMessage: "Send a test email to your project's inbox at <link>{email}</link>",
		description:
			"This text is shown as a section message description on the queues page in Jira Service Management. It prompts users to send a test email to their project's inbox.",
	},
	emailRequestSectionMessageDescription2: {
		id: 'servicedesk-email-request-integration-section-message.email-request-section-message.description-2',
		defaultMessage:
			'The email request usually arrives in your queues within 30 seconds, though times may vary by email provider.',
		description:
			'This text is shown in section message description on the queues page in Jira Service Management. It informs user that the issue creation via email channel usually takes 30 mins to be visible on queues',
	},
	emailRequestSectionMessageAction: {
		id: 'servicedesk-email-request-integration-section-message.email-request-section-message.action',
		defaultMessage: 'Send a test email',
		description:
			'This text is for an action link in the email request section message on the queues page in Jira Service Management. When clicked, it allows users to send a test email.',
	},
	sendEmailIconLabel: {
		id: 'servicedesk-email-request-integration-section-message.email-request-section-message.send-email-icon-label',
		defaultMessage: 'Send email',
		description:
			'This label is used for the send email icon in the email request section message action. It is read by screen readers to describe the action of the icon.',
	},
	closeSectionMessageIconLabel: {
		id: 'servicedesk-email-request-integration-section-message.email-request-section-message.close-section-message-icon-label',
		defaultMessage: 'Close section message',
		description:
			'This label is used for the close icon in the email request section message. It is read by screen readers to indicate that the icon closes the section message.',
	},
	addEmailAccountSectionMessageTitle: {
		id: 'servicedesk-email-request-integration-section-message.add-email-account-section-message.title',
		defaultMessage: 'Prefer using your own email account to get requests?',
		description:
			'This text is shown as a section message title on the queues page in Jira Service Management. It asks users if they want to use their own email account for requests.',
	},
	addEmailAccountSectionMessageDescription: {
		id: 'servicedesk-email-request-integration-section-message.add-email-account-section-message.description',
		defaultMessage:
			'No problem! Just connect a custom account and get requests from it in your queues.',
		description:
			'This text is shown as a section message description on the queues page in Jira Service Management. It informs users that they can connect their own email account to receive requests.',
	},
	addEmailAccountSectionMessageAction1: {
		id: 'servicedesk-email-request-integration-section-message.add-email-account-section-message.action1',
		defaultMessage: 'Add an email account',
		description:
			'This text is for an action link in the add email account section message on the queues page in Jira Service Management. When clicked, it allows users to add a custom email account.',
	},
	addEmailAccountSectionMessageAction2: {
		id: 'servicedesk-email-request-integration-section-message.add-email-account-section-message.action2',
		defaultMessage: 'Read more on emailed requests',
		description:
			'This text is for an action link in the add email account section message on the queues page in Jira Service Management. When clicked, it provides more information about handling emailed requests.',
	},
	defaultEmailSubject: {
		id: 'servicedesk-email-request-integration-section-message.email-request-section-message.default-email-subject',
		defaultMessage: '*Test email* Login help',
		description:
			'This text is used as the default subject for a test email when a user clicks on the "Send a test email" action link in the email request section message.',
	},
	defaultEmailBody: {
		id: 'servicedesk-email-request-integration-section-message.email-request-section-message.default-email-body',
		defaultMessage: "Hey, I can't log in to the system. Can you help me reset my password?",
		description:
			'This text is used as the default body for a test email when a user clicks on the "Send a test email" action link in the email request section message.',
	},
	emailLinkCopyButtonLabel: {
		id: 'servicedesk-getting-started-page.set-up-basics.review-request-channels.email-link-copy-button-label',
		defaultMessage: 'copy email address',
		description: 'Button to copy the email address to the clipboard',
	},
});
