export type Position = { pageX: number; pageY: number };
export type Rectangle = Pick<DOMRect, 'left' | 'right' | 'top' | 'bottom'>;

/**
 * Return true if coordinates fit in a rectangle
 */
export function boundsCheck(rect: Rectangle, input: Position): boolean {
	return (
		input.pageX >= rect.left &&
		input.pageX <= rect.right &&
		input.pageY >= rect.top &&
		input.pageY <= rect.bottom
	);
}

/**
 * When an event is clicked, look-up the date where the pointer dragged it.
 * This will be used as a pivot point to compensate the dragging operation from.
 */
export function findDraggedDate(
	eventElement: HTMLDivElement,
	input: Position | undefined | null,
): string | null {
	if (!input) return null;

	const weekRow = eventElement.closest('tr');
	const weekRowChildren = Array.from(weekRow?.children ?? []);
	const dayCells = weekRowChildren.filter((child) => child.classList.contains('fc-day'));
	const draggedCell = dayCells.find((day) => boundsCheck(day.getBoundingClientRect(), input));
	const draggedDate = draggedCell?.getAttribute('data-date');
	return draggedDate ?? null;
}
