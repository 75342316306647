import { type RefObject, useRef, useState, useEffect, useCallback } from 'react';

export const isEventFromMoreLinkPopover = (eventElement: HTMLElement) =>
	eventElement.closest('.fc-event-main') === null;

/**
 * Hook to determine if the event should be removed from the popover after dragging.
 * We manually hide the dragged away event from the popover because we implement our own dnd and the popover doesn't update automatically.
 */
export const useIsVisibleInMoreLinkPopover = (eventRef: RefObject<HTMLDivElement>) => {
	const isFromMoreLinkPopover = useRef(false);
	const [isVisible, setIsVisible] = useState(true);

	useEffect(() => {
		if (eventRef.current) {
			isFromMoreLinkPopover.current = isEventFromMoreLinkPopover(eventRef.current);
		}
	}, [eventRef]);

	const onDropEvent = useCallback((isEventDraggedAway: boolean) => {
		if (isFromMoreLinkPopover.current && isEventDraggedAway) {
			setIsVisible(false);
		}
	}, []);

	return {
		isVisible,
		onDropEvent,
	};
};
