import React, { useRef, useLayoutEffect } from 'react';
import { usePreloadedQuery, graphql, type PreloadedQuery } from 'react-relay';
import { Inline, Stack, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useViewMode } from '@atlassian/jira-issue-context-service/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import {
	flushResizeObservers,
	useRectObserver,
} from '@atlassian/jira-react-use-resize-observer/src/index.tsx';
import type { ui_jiraCalendarQuery } from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery.graphql';
import { ReleaseFlyoutUFOContextProvider } from '@atlassian/jira-release-flyout/src/controllers/ufo-context-provider/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';
import { ANALYTICS_SOURCE, SELECTED_ISSUE } from '../common/constants.tsx';
import {
	useCalendarCapabilities,
	PLATFORM,
} from '../common/controllers/capabilities-provider/index.tsx';
import { useCalendarIssueViewMode } from '../common/controllers/use-calendar-issue-view-mode/index.tsx';
import { ResizableSidebarPanel } from '../common/ui/resizable-sidebar-panel/index.tsx';
import {
	useCalendarActions,
	useIsUnscheduledPanelIssueViewOpen,
	useIsUnscheduledPanelOpen,
} from '../controllers/calendar-store/index.tsx';
import { useCalendarUFOExperienceContext } from '../controllers/ufo-context-provider/index.tsx';
import { useJqlBaseQuery } from '../controllers/use-jql-base-query/index.tsx';
import { CalendarIssueViewModal } from './calendar-issue-view-modal/index.tsx';
import { CalendarRenderer, type CalendarRendererProps } from './calendar-renderer/index.tsx';
import { CalendarSidebarIssueApp } from './calendar-sidebar-issue-app/index.tsx';
import {
	CalendarTopControlsBar,
	type CalendarTopControlsBarProps,
} from './calendar-top-controls-bar/index.tsx';
import {
	CalendarUnscheduledPanel,
	type CalendarUnscheduledPanelProps,
} from './calendar-unscheduled-panel/index.tsx';
import { Header } from './header/index.tsx';

function TopControls({ queryRef, jqlBuilderQuery }: CalendarTopControlsBarProps) {
	const { packageName, teamName } = useCalendarUFOExperienceContext();
	return (
		<UFOSegment name="jira-calendar-top-controls-bar">
			<JSErrorBoundary
				id="jira-calendar-top-controls-bar"
				packageName={packageName}
				teamName={teamName}
				fallback="flag"
			>
				<CalendarTopControlsBar queryRef={queryRef} jqlBuilderQuery={jqlBuilderQuery} />
			</JSErrorBoundary>
		</UFOSegment>
	);
}

function UnscheduledPanel({ queryRef, jqlBuilderQuery }: CalendarUnscheduledPanelProps) {
	const { packageName, teamName } = useCalendarUFOExperienceContext();
	return (
		<UFOSegment name="jira-calendar-unscheduled-panel">
			<JSErrorBoundary
				id="jira-calendar-unscheduled-panel"
				packageName={packageName}
				teamName={teamName}
				fallback="flag"
			>
				<Placeholder fallback={null} name="CalendarUnscheduledPanel">
					<CalendarUnscheduledPanel queryRef={queryRef} jqlBuilderQuery={jqlBuilderQuery} />
				</Placeholder>
			</JSErrorBoundary>
		</UFOSegment>
	);
}

function CalendarIssueViewSidebar() {
	const { closeIssueView } = useCalendarIssueViewMode();
	const { packageName, teamName } = useCalendarUFOExperienceContext();
	return (
		<UFOSegment name="jira-calendar-issue-view-sidebar-panel">
			<JSErrorBoundary
				id="jira-calendar-issue-view-sidebar-panel"
				packageName={packageName}
				teamName={teamName}
				fallback="flag"
			>
				<Placeholder fallback={null} name="CalendarIssueViewSidebarPanel">
					{/* analyticsSource will need to be updated to take the value from entrypoint */}
					{/* since this component will be now rendered in other projects */}
					<CalendarSidebarIssueApp analyticsSource={ANALYTICS_SOURCE} onClose={closeIssueView} />
				</Placeholder>
			</JSErrorBoundary>
		</UFOSegment>
	);
}

function Renderer({ issuesQueryRef, calendarQueryRef, versionsQueryRef }: CalendarRendererProps) {
	const { suffixKey, packageName, teamName } = useCalendarUFOExperienceContext();
	return (
		<UFOSegment name="jira-calendar-renderer">
			<JSErrorBoundary
				id="jira-calendar-renderer"
				packageName={packageName}
				teamName={teamName}
				fallback="page"
			>
				<Placeholder fallback={null} name="CalendarRenderer">
					<Stack grow="fill" xcss={calendarRendererInlineStyles}>
						<ReleaseFlyoutUFOContextProvider location="calendar" suffixKey={suffixKey}>
							<CalendarRenderer
								issuesQueryRef={issuesQueryRef}
								calendarQueryRef={calendarQueryRef}
								versionsQueryRef={versionsQueryRef}
							/>
						</ReleaseFlyoutUFOContextProvider>
					</Stack>
				</Placeholder>
			</JSErrorBoundary>
		</UFOSegment>
	);
}

interface CalendarSidebarPanelProps {
	children: React.ReactNode;
}

const CalendarSidebarPanel = ({ children }: CalendarSidebarPanelProps) => {
	const isUnscheduledPanelShown = useIsUnscheduledPanelOpen();

	return (
		<ResizableSidebarPanel
			xcss={[
				fg('jira-calendar-business-theme')
					? sidebarPanelInlineStyles
					: sidebarPanelInlineStylesWithoutJwmTheming,
				isUnscheduledPanelShown ? sidebarPanelStyles : undefined,
			]}
			testId="calendar.ui.container"
		>
			{children}
		</ResizableSidebarPanel>
	);
};

interface CalendarContentProps {
	/**
	 * The preloaded query for the Jira calendar.
	 */
	calendarQueryRef: PreloadedQuery<ui_jiraCalendarQuery>;
}

export function CalendarContent({ calendarQueryRef }: CalendarContentProps) {
	const additionalFilterQuery =
		calendarQueryRef?.variables?.issuesSearchInput?.additionalFilterQuery ?? '';
	const {
		hasHeader,
		issue: { modalType },
	} = useCalendarCapabilities();

	const { baseQuery } = useJqlBaseQuery();

	const data = usePreloadedQuery<ui_jiraCalendarQuery>(
		graphql`
			query ui_jiraCalendarQuery(
				$cloudId: ID!
				# This is used to set the scope of the calendar search
				$scopeInput: JiraViewScopeInput!
				# This is used to provide configuration settings for the calendar
				$configurationInput: JiraCalendarViewConfigurationInput!
				# This is used to filter issues
				$issuesSearchInput: JiraCalendarIssuesInput!
				# This function is used to determine the page size and cursor for issues pagination.
				# Note: The API enforces a maximum page size of 50 items.
				$issuesPageSize: Int = 50
				# This is used to determine the cursor for issues pagination
				$issuesPageCursor: String = null
				# This is used to filter issues for the panel
				$unscheduledIssuesSearchInput: JiraCalendarIssuesInput
				# This is used to filter versions
				$versionsSearchInput: JiraCalendarVersionsInput
				# This is used to filter cross project versions
				$crossProjectVersionsSearchInput: JiraCalendarCrossProjectVersionsInput
				# This is used to filter sprints
				$sprintsSearchInput: JiraCalendarSprintsInput
				# This allows skipping cross project version search
				$skipCrossProjectVersionSearch: Boolean = true
				# This allows skipping sprint search
				$skipSprintSearch: Boolean = true
				# This allows skipping issue panel
				$skipPanelIssueSearch: Boolean = false
				# This is used to determine the date fields specific to view
				$viewId: ID
				# This when enabled will skip field config. This is used, whether to fetch or skip date field type (date or datetime).
				$skipFieldConfig: Boolean = true
				# schedulePermissionsEnabled is to determine loading schedule permissions for issues
				$schedulePermissionsEnabled: Boolean!
				# This is to enable a connection on the issuesV2 query for pagination
				$pageLoadMoreEnabled: Boolean!
				# Determines if jiraCalendar.versions is used. This takes into account "skipVersionSearch" and fg('plan-calendar-versions-v2-adoption')
				# FG-cleanup: plan-calendar-versions-v2-adoption -- consolidate "skipVersionsV1Search" and "skipVersionsV2Search" back to "skipVersionSearch"
				$skipVersionsV1Search: Boolean = true
				# Determines if jiraCalendar.versionsV2 is used. This takes into account "skipVersionSearch" and fg('plan-calendar-versions-v2-adoption')
				$skipVersionsV2Search: Boolean = true
			) @preloadable {
				...calendarRendererIssues_jira
					@arguments(
						scopeInput: $scopeInput
						configurationInput: $configurationInput
						issuesSearchInput: $issuesSearchInput
						issuesPageSize: $issuesPageSize
						issuesPageCursor: $issuesPageCursor
						viewId: $viewId
						skipFieldConfig: $skipFieldConfig
						schedulePermissionsEnabled: $schedulePermissionsEnabled
						pageLoadMoreEnabled: $pageLoadMoreEnabled
					)
				...calendarRendererVersions_jira
					@arguments(
						scopeInput: $scopeInput
						configurationInput: $configurationInput
						versionsSearchInput: $versionsSearchInput
						viewId: $viewId
						skipVersionsV1Search: $skipVersionsV1Search
						skipVersionsV2Search: $skipVersionsV2Search
					)
				...calendarRenderer_jira
					@arguments(
						skipSprintSearch: $skipSprintSearch
						skipCrossProjectVersionSearch: $skipCrossProjectVersionSearch
						scopeInput: $scopeInput
						configurationInput: $configurationInput
						crossProjectVersionsSearchInput: $crossProjectVersionsSearchInput
						sprintsSearchInput: $sprintsSearchInput
					)
				...calendarUnscheduledPanel_calendar
					@skip(if: $skipPanelIssueSearch)
					@arguments(
						scopeInput: $scopeInput
						configurationInput: $configurationInput
						unscheduledIssuesSearchInput: $unscheduledIssuesSearchInput
						viewId: $viewId
						schedulePermissionsEnabled: $schedulePermissionsEnabled
					)
				...calendarTopControlsBar_calendar
					@arguments(
						cloudId: $cloudId
						scopeInput: $scopeInput
						configurationInput: $configurationInput
						unscheduledIssuesSearchInput: $unscheduledIssuesSearchInput
						skipPanelIssueSearch: $skipPanelIssueSearch
						versionsSearchInput: $versionsSearchInput
						skipVersionsV1Search: $skipVersionsV1Search
						skipVersionsV2Search: $skipVersionsV2Search
						sprintsSearchInput: $sprintsSearchInput
						skipSprintSearch: $skipSprintSearch
						issuesSearchInput: $issuesSearchInput
						issuesPageSize: $issuesPageSize
						issuesPageCursor: $issuesPageCursor
					)
				...calendarIssueViewModal_calendar
					@arguments(scopeInput: $scopeInput, configurationInput: $configurationInput)
			}
		`,
		calendarQueryRef,
	);

	const containerRef = useRef<HTMLDivElement | null>(null);
	const containerRect = useRectObserver({
		ref: containerRef,
	});
	const headerRef = useRef<HTMLDivElement | null>(null);
	const headerRect = useRectObserver({
		ref: headerRef,
	});

	const issueViewMode = useViewMode();
	const [selectedIssueKey] = useQueryParam(SELECTED_ISSUE);
	const isUnscheduledPanelShown = useIsUnscheduledPanelOpen();
	const isUnscheduledPanelIssueViewOpen = useIsUnscheduledPanelIssueViewOpen();
	const { setUnscheduledPanelVisibility } = useCalendarActions();

	if (
		selectedIssueKey &&
		isUnscheduledPanelShown &&
		issueViewMode === 'SIDEBAR' &&
		!isUnscheduledPanelIssueViewOpen
	) {
		setUnscheduledPanelVisibility(false);
	}

	const showIssueDetails = modalType === PLATFORM;
	const isSidebarShown =
		isUnscheduledPanelShown ||
		(showIssueDetails && issueViewMode === 'SIDEBAR' && selectedIssueKey);

	useLayoutEffect(() => {
		flushResizeObservers();
	}, [isSidebarShown]);

	const jqlBuilderQuery = baseQuery;

	// Note early returning here will break the refs code above.

	return (
		<>
			<Stack grow="fill" ref={containerRef}>
				<div ref={headerRef}>{hasHeader && <Header />}</div>

				<div
					// eslint-disable-next-line jira/react/no-style-attribute
					style={{
						height:
							containerRect != null && headerRect != null
								? containerRect.height - headerRect.height
								: '100%',
					}}
				>
					<Placeholder
						name="CalendarInner"
						fallback={
							<Stack grow="fill" alignBlock="center" alignInline="center">
								<Spinner />
							</Stack>
						}
					>
						<Inline grow="fill" space="space.200" xcss={containerInlineStyles}>
							<Stack
								grow="fill"
								xcss={containerStackStyles}
								space={fg('jira-calendar-business-theme') ? 'space.0' : 'space.200'}
							>
								<TopControls queryRef={data} jqlBuilderQuery={additionalFilterQuery} />
								<Renderer issuesQueryRef={data} calendarQueryRef={data} versionsQueryRef={data} />
							</Stack>
							{isSidebarShown && (
								<CalendarSidebarPanel>
									{isUnscheduledPanelShown ? (
										<UnscheduledPanel queryRef={data} jqlBuilderQuery={jqlBuilderQuery} />
									) : (
										<>{!isUnscheduledPanelIssueViewOpen && <CalendarIssueViewSidebar />}</>
									)}
								</CalendarSidebarPanel>
							)}
						</Inline>
					</Placeholder>
				</div>
			</Stack>
			{((showIssueDetails &&
				(issueViewMode === 'MODAL' || issueViewMode === 'DEFAULT') &&
				selectedIssueKey) ||
				isUnscheduledPanelIssueViewOpen) && (
				<CalendarIssueViewModal
					data-testid="calendar.ui.calendar-modal-issue-app"
					queryRef={data}
				/>
			)}
		</>
	);
}

const sidebarPanelStyles = xcss({
	padding: 'space.250',
});

const containerInlineStyles = xcss({ height: '100%' });

const containerStackStyles = xcss({ width: 'auto', height: '100%' });

const calendarRendererInlineStyles = xcss({ height: '100%' });

const sidebarPanelInlineStyles = xcss({ height: '100%', marginTop: 'space.250' });

const sidebarPanelInlineStylesWithoutJwmTheming = xcss({ height: '100%' });
