import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	openIssueInSidebar: {
		id: 'servicedesk.queues.agent-view.layout.open-issue-in-sidebar',
		defaultMessage: 'Open issue in sidebar',
		description: 'View the highlighted issue in the sidebar by clicking this button',
	},
	openIssueInSidebarIssueTermRefresh: {
		id: 'servicedesk.queues.agent-view.layout.open-issue-in-sidebar-issue-term-refresh',
		defaultMessage: 'Open issue in sidebar',
		description: 'View the highlighted issue in the sidebar by clicking this button',
	},
});
