import { B400, G400, R400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export const ON_TRACK = 'ON_TRACK' as const;
export const OFF_TRACK = 'OFF_TRACK' as const;
export const COMPLETE = 'COMPLETE' as const;
export type ReleaseStatus = typeof ON_TRACK | typeof OFF_TRACK | typeof COMPLETE;

export const RELEASE_STATUS_COLORS = {
	[ON_TRACK]: token('color.icon.information', B400),
	[OFF_TRACK]: token('color.icon.danger', R400),
	[COMPLETE]: token('color.icon.success', G400),
};
