/*!
 * A few parts of this are copied over from business/calendar view settings, which are fairly similar.
 */
import React, { useMemo, useCallback, useEffect, useRef, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import Link from '@atlaskit/link';
import { MenuGroup, Section } from '@atlaskit/menu';
import { type Breakpoint, Box, Inline, xcss, Text, Stack } from '@atlaskit/primitives';
import type { SingleValue, ActionMeta } from '@atlaskit/react-select';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import Select, { type StylesConfig } from '@atlaskit/select';
import Spinner from '@atlaskit/spinner';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import {
	useIssueContextActions,
	useViewMode,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { JiraPopup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { viewSettings_calendar_CalendarViewSettings$key } from '@atlassian/jira-relay/src/__generated__/viewSettings_calendar_CalendarViewSettings.graphql';
import { ViewSettingsToggle } from '@atlassian/jira-software-view-settings/src/common/ui/view-settings-toggle/index.tsx';
import { ViewSettingsButton } from '@atlassian/jira-software-view-settings/src/ui/view-settings-button/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';
import {
	useCalendarCapabilities,
	PLATFORM,
} from '../../../common/controllers/capabilities-provider/index.tsx';
import { ViewSettingsPanelSpotlightTarget } from '../../../common/ui/onboarding/index.tsx';
import { ContainerShow, type ContainerShowProps } from '../../../common/ui/responsive/index.tsx';
import {
	useCalendarActions,
	useWeekStartsOn,
	useDayStartsAt,
	useShowReleases,
	useShowSprints,
	useShowIssues,
	useIsViewSettingsOpen,
	useShowWeekends,
	useShowIn24HourClock,
	useHideDoneItems,
	useShowIssueKey,
	useCalendarCallbacks,
	useViewRange,
	useColorBy,
} from '../../../controllers/calendar-store/index.tsx';
import type {
	CalendarDayStart,
	CalendarSettings,
} from '../../../controllers/calendar-store/types.tsx';
import { useCalendarUFOExperienceContext } from '../../../controllers/ufo-context-provider/index.tsx';
import { ConnectedProjectsPicker } from './connected-projects-picker/index.tsx';
import { DayStartsAtOptions } from './day-starts-at/index.tsx';
import { messages } from './messages.tsx';

interface CalendarViewSettingsProps {
	breakpoint?: Breakpoint;
	queryRef: viewSettings_calendar_CalendarViewSettings$key;
}

type WeekStartsOnOption = {
	value: 'monday' | 'sunday' | 'saturday';
	label: string;
};

type ColorByOption = {
	value: 'status' | 'none';
	label: string;
};

const HIDE_DONE_QUERY_PARAM = 'hideDone';
// The type of "base" argument refers to internal types of @emotions.
// @ts-expect-error - TS7006 - Parameter 'base' implicitly has an 'any' type.
const menuPortal = (base) => ({
	...base,
	zIndex: 9999,
});
const weekStartMenuPortalStyles: StylesConfig<WeekStartsOnOption, false> = {
	menuPortal,
};
const colorByMenuPortalStyles: StylesConfig<ColorByOption, false> = {
	menuPortal,
};

const softwareReleasesA11yDescriptionId =
	'calendar.ui.calendar-top-controls-bar.view-settings.software-releases-a11y-description-id';

export function CalendarViewSettings({ breakpoint, queryRef }: CalendarViewSettingsProps) {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { packageName, teamName } = useCalendarUFOExperienceContext();

	const { setViewSettingsPanelVisibility, toggleViewSettingsPanelVisibility } =
		useCalendarActions();
	const viewMode = useViewMode();
	const isOpen = useIsViewSettingsOpen();
	const [weekStartsOpen, setWeekStartsOpen] = useState(false);
	const [colorByOpen, setColorByOpen] = useState(false);

	const [, { setDetailViewAsSidebar, setDetailViewAsModal }] = useIssueContextActions();

	const handleClick = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			'viewSettings',
		);
		toggleViewSettingsPanelVisibility();
	};
	const handleClose = useCallback(() => {
		if (weekStartsOpen) return;
		setViewSettingsPanelVisibility(false);
	}, [setViewSettingsPanelVisibility, weekStartsOpen]);

	const data = useFragment<viewSettings_calendar_CalendarViewSettings$key>(
		graphql`
			fragment viewSettings_calendar_CalendarViewSettings on Query
			@argumentDefinitions(
				scopeInput: { type: "JiraViewScopeInput!" }
				configurationInput: { type: "JiraCalendarViewConfigurationInput!" }
				versionsSearchInput: { type: "JiraCalendarVersionsInput" }
				sprintsSearchInput: { type: "JiraCalendarSprintsInput" }
				skipSprintSearch: { type: "Boolean!" }
				issuesSearchInput: { type: "JiraCalendarIssuesInput!" }
				issuesPageSize: { type: "Int" }
				issuesPageCursor: { type: "String" }
				skipVersionsV1Search: { type: "Boolean!" }
				skipVersionsV2Search: { type: "Boolean!" }
			) {
				jira {
					jiraCalendar(scope: $scopeInput, configuration: $configurationInput)
						@optIn(to: "JiraCalendar") {
						versions(input: $versionsSearchInput) @skip(if: $skipVersionsV1Search) {
							edges {
								__typename
							}
						}
						versionsV2(input: $versionsSearchInput) @skip(if: $skipVersionsV2Search) {
							edges {
								__typename
							}
						}
						sprints(input: $sprintsSearchInput) @skip(if: $skipSprintSearch) {
							totalCount
						}
						issuesV2(input: $issuesSearchInput, first: $issuesPageSize, after: $issuesPageCursor)
							@optIn(to: "JiraPlansSupport") {
							totalIssueSearchResultCount
						}
					}
				}
			}
		`,
		queryRef,
	);

	const {
		issue: { visible: isIssueVisible, modalType, canHide: canHideIssue },
		sprint: { visible: isSprintVisible, canHide: canHideSprint },
		version: { visible: isVersionVisible, canHide: canHideVersion },
		viewSettings: { hasHideDoneIssuesToggle },
		hasSharedReleases,
		weekViewEnabled,
	} = useCalendarCapabilities();
	const {
		setHideDoneItems,
		toggleShowWeekends,
		toggleShowReleases,
		toggleShowSprints,
		toggleShowIssueKeys,
		toggleUse24HourClock,
		toggleShowIssues,
		setWeekStartsOn,
		setDayStartsAt,
		setColorByValue,
	} = useCalendarActions();

	const { onSettingsChanged } = useCalendarCallbacks();
	const showWeekends = useShowWeekends();
	const showIn24HourClock = useShowIn24HourClock();
	const issueKeyToggleValue = useShowIssueKey();
	const hideDoneItems = useHideDoneItems();
	const releasesToggleValue = useShowReleases();
	const sprintsToggleValue = useShowSprints();
	const issuesToggleValue = useShowIssues();
	const weekStartsOn = useWeekStartsOn();
	const colorBy = useColorBy();
	const dayStartsAt = useDayStartsAt();
	const viewRange = useViewRange();
	const { formatMessage } = useIntl();
	const showIssueDetails = modalType === PLATFORM;
	const showSprints =
		isSprintVisible && (data?.jira?.jiraCalendar?.sprints?.totalCount ?? 0) > 0 && canHideSprint;

	const versionsLength = fg('plan-calendar-versions-v2-adoption')
		? data?.jira?.jiraCalendar?.versionsV2?.edges?.length ?? 0
		: data?.jira?.jiraCalendar?.versions?.edges?.length ?? 0;

	const showVersions = isVersionVisible && versionsLength > 0 && canHideVersion;

	const showIssues =
		isIssueVisible &&
		(data?.jira?.jiraCalendar?.issuesV2?.totalIssueSearchResultCount ?? 0) > 0 &&
		canHideIssue;

	const calendarSettings: CalendarSettings = useMemo(
		() => ({
			viewRange,
			weekStartsOn,
			dayStartsAt,
			showIn24HourClock,
			showWeekends,
			showIssueKey: issueKeyToggleValue,
			colorBy,
			...(showVersions ? { showVersions: releasesToggleValue } : {}),
			...(showSprints ? { showSprints: sprintsToggleValue } : {}),
			...(showIssues ? { showIssues: issuesToggleValue } : {}),
			hideDoneItems,
		}),
		[
			viewRange,
			weekStartsOn,
			dayStartsAt,
			showIn24HourClock,
			showWeekends,
			issueKeyToggleValue,
			showVersions,
			releasesToggleValue,
			showSprints,
			sprintsToggleValue,
			showIssues,
			issuesToggleValue,
			hideDoneItems,
			colorBy,
		],
	);

	// Ensures url is read before overwritten by default hideDone value
	const hideDoneUpdatedFromQuery = useRef(false);

	const [hideDoneQuery, setHideDoneQuery] = useQueryParam(HIDE_DONE_QUERY_PARAM);

	useEffect(() => {
		if (!hasHideDoneIssuesToggle || !fg('calendar-hide-done-query-param')) return;
		if (!hideDoneUpdatedFromQuery.current) {
			// Only overrides persisted view settings if given in query
			if (hideDoneQuery) {
				setHideDoneItems(hideDoneQuery === 'true');
				onSettingsChanged?.({
					...calendarSettings,
					hideDoneItems: hideDoneQuery === 'true',
				});
			} else {
				setHideDoneQuery(hideDoneItems ? 'true' : undefined);
			}
			hideDoneUpdatedFromQuery.current = true;
			return;
		}
		setHideDoneQuery(hideDoneItems ? 'true' : undefined);
	}, [
		calendarSettings,
		hasHideDoneIssuesToggle,
		hideDoneItems,
		hideDoneQuery,
		onSettingsChanged,
		setHideDoneItems,
		setHideDoneQuery,
	]);

	const weekStartsOnOptions = useMemo(
		() =>
			[
				{
					value: 'monday',
					label: formatMessage(messages.monday),
				},
				{
					value: 'saturday',
					label: formatMessage(messages.saturday),
				},
				{
					value: 'sunday',
					label: formatMessage(messages.sunday),
				},
			] as const,
		[formatMessage],
	);

	const colorByOptions = useMemo(
		() =>
			[
				{
					value: 'status',
					label: formatMessage(messages.status),
				},
				{
					value: 'none',
					label: formatMessage(messages.none),
				},
			] as const,
		[formatMessage],
	);

	const handleHideDoneItemsToggle = useCallback(() => {
		setHideDoneItems(!hideDoneItems);

		onSettingsChanged?.({
			...calendarSettings,
			hideDoneItems: !hideDoneItems,
		});
	}, [setHideDoneItems, hideDoneItems, onSettingsChanged, calendarSettings]);

	const handleWeekendsToggleChange = useCallback(() => {
		toggleShowWeekends();

		onSettingsChanged?.({
			...calendarSettings,
			showWeekends: !showWeekends,
		});

		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'toggled',
				actionSubject: 'showWeekends',
			}),
			'viewSettings',
			{ value: !showWeekends },
		);
	}, [createAnalyticsEvent, showWeekends, toggleShowWeekends, onSettingsChanged, calendarSettings]);

	const handleIssueKeyToggleChange = useCallback(() => {
		toggleShowIssueKeys();

		onSettingsChanged?.({
			...calendarSettings,
			showIssueKey: !issueKeyToggleValue,
		});

		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'toggled',
				actionSubject: 'showIssueKeys',
			}),
			'viewSettings',
			{ value: !issueKeyToggleValue },
		);
	}, [
		createAnalyticsEvent,
		issueKeyToggleValue,
		toggleShowIssueKeys,
		onSettingsChanged,
		calendarSettings,
	]);

	const handleIssueToggleChange = useCallback(() => {
		toggleShowIssues();

		onSettingsChanged?.({
			...calendarSettings,
			showIssues: !issuesToggleValue,
		});

		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'toggled',
				actionSubject: 'showIssues',
			}),
			'viewSettings',
			{ value: !issuesToggleValue },
		);
	}, [
		createAnalyticsEvent,
		issuesToggleValue,
		toggleShowIssues,
		onSettingsChanged,
		calendarSettings,
	]);

	const handleSprintToggleChange = useCallback(() => {
		toggleShowSprints();

		onSettingsChanged?.({
			...calendarSettings,
			showSprints: !sprintsToggleValue,
		});

		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'toggled',
				actionSubject: 'showSprints',
			}),
			'viewSettings',
			{ value: !sprintsToggleValue },
		);
	}, [
		createAnalyticsEvent,
		sprintsToggleValue,
		toggleShowSprints,
		onSettingsChanged,
		calendarSettings,
	]);

	const handleReleaseToggleChange = useCallback(() => {
		toggleShowReleases();

		onSettingsChanged?.({
			...calendarSettings,
			showVersions: !releasesToggleValue,
		});

		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'toggled',
				actionSubject: 'showReleases',
			}),
			'viewSettings',
			{ value: !releasesToggleValue },
		);
	}, [
		createAnalyticsEvent,
		releasesToggleValue,
		toggleShowReleases,
		onSettingsChanged,
		calendarSettings,
	]);

	const handleWeekStartsOnChange = useCallback(
		(newOption: SingleValue<WeekStartsOnOption>, _actionMeta: ActionMeta<WeekStartsOnOption>) => {
			if (!newOption) {
				return;
			}

			setWeekStartsOn(newOption.value);
			onSettingsChanged?.({
				...calendarSettings,
				weekStartsOn: newOption.value,
			});

			setWeekStartsOpen(false);

			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'selected',
					actionSubject: 'weekStartsOn',
				}),
				'viewSettings',
				{ value: newOption.value },
			);
		},
		[setWeekStartsOn, onSettingsChanged, calendarSettings, createAnalyticsEvent],
	);

	const handleColorByChange = useCallback(
		(newOption: SingleValue<ColorByOption>, _actionMeta: ActionMeta<ColorByOption>) => {
			if (!newOption) {
				return;
			}

			setColorByValue(newOption.value);
			onSettingsChanged?.({
				...calendarSettings,
				colorBy: newOption.value,
			});

			setColorByOpen(false);

			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'selected',
					actionSubject: 'colorBy',
				}),
				'viewSettings',
				{ value: newOption.value },
			);
		},
		[setColorByValue, onSettingsChanged, calendarSettings, createAnalyticsEvent],
	);

	const onDayStartsAtChange = useCallback(
		(newOption: CalendarDayStart) => {
			setDayStartsAt(newOption);

			onSettingsChanged?.({
				...calendarSettings,
				dayStartsAt: newOption,
			});

			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'selected',
					actionSubject: 'dayStartsAt',
				}),
				'viewSettings',
				{ value: newOption },
			);
		},
		[calendarSettings, createAnalyticsEvent, onSettingsChanged, setDayStartsAt],
	);

	const handleCalendarViewModeClick = useCallback(() => {
		if (viewMode === 'SIDEBAR') {
			setDetailViewAsModal();
		} else {
			setDetailViewAsSidebar();
		}
	}, [setDetailViewAsModal, setDetailViewAsSidebar, viewMode]);

	const handle24HourClockToggleChange = useCallback(() => {
		toggleUse24HourClock();

		onSettingsChanged?.({
			...calendarSettings,
			showIn24HourClock: !showIn24HourClock,
		});

		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'toggled',
				actionSubject: '24hourTime',
			}),
			'viewSettings',
			{ value: !showIn24HourClock },
		);
	}, [
		calendarSettings,
		createAnalyticsEvent,
		onSettingsChanged,
		showIn24HourClock,
		toggleUse24HourClock,
	]);

	const { Show } = useMemo(
		() => ({
			Show: (
				props:
					| {
							children: React.ReactNode;
							as?: ContainerShowProps['as'];
							above?: never;
							below: Breakpoint;
					  }
					| {
							children: React.ReactNode;
							as?: ContainerShowProps['as'];
							above: Breakpoint;
							below?: never;
					  },
			) => <ContainerShow {...props} breakpoint={breakpoint ?? 'lg'} />,
		}),
		[breakpoint],
	);

	const getMenuPortalTarget = () => {
		if (!__SERVER__) {
			return document.body;
		}
		return undefined;
	};

	const content = (
		<Box xcss={[popupStyles]}>
			<MenuGroup>
				<Box xcss={menuContainerStyles}>
					{showIssueDetails && (
						<Section title={formatMessage(messages.calendarSettings)}>
							<ViewSettingsToggle
								testId="calendar.ui.calendar-top-controls-bar.view-settings.open-issues-in-sidebar"
								title={formatMessage(
									expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
										? messages.openIssuesInSidebarIssueTermRefresh
										: messages.openIssuesInSidebar,
								)}
								onChange={handleCalendarViewModeClick}
								isChecked={viewMode === 'SIDEBAR'}
								viewAs="Popup"
							/>
						</Section>
					)}
					<Section title={formatMessage(messages.viewOptions)}>
						{hasHideDoneIssuesToggle && showIssueDetails && (
							<ViewSettingsToggle
								testId="calendar.ui.calendar-top-controls-bar.view-settings.hide-done-items"
								title={formatMessage(
									fg('jira-issue-terminology-refresh-m3')
										? messages.hideDoneItemsIssueTermRefresh
										: messages.hideDoneItems,
								)}
								onChange={handleHideDoneItemsToggle}
								isChecked={hideDoneItems}
								viewAs="Popup"
							/>
						)}
						<ViewSettingsToggle
							title={formatMessage(
								expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
									? messages.showIssueKeysIssueTermRefresh
									: messages.showIssueKeys,
							)}
							isChecked={issueKeyToggleValue}
							viewAs="Popup"
							onChange={handleIssueKeyToggleChange}
							testId="calendar.ui.calendar-top-controls-bar.view-settings.show-issue-keys-toggle"
						/>
					</Section>
					{(showSprints || showVersions || showIssues) && (
						<Section title={formatMessage(messages.showCalendarItems)} hasSeparator>
							{showIssues && (
								<ViewSettingsToggle
									title={formatMessage(
										fg('jira-issue-terminology-refresh-m3')
											? messages.issuesIssueTermRefresh
											: messages.issues,
									)}
									isChecked={issuesToggleValue}
									viewAs="Popup"
									onChange={handleIssueToggleChange}
									testId="calendar.ui.calendar-top-controls-bar.view-settings.show-issues-toggle"
								/>
							)}
							{showVersions && (
								<ViewSettingsToggle
									title={formatMessage(messages.releases)}
									isChecked={releasesToggleValue}
									viewAs="Popup"
									onChange={handleReleaseToggleChange}
									testId="calendar.ui.calendar-top-controls-bar.view-settings.show-releases-toggle"
								/>
							)}
							{showSprints && (
								<ViewSettingsToggle
									title={formatMessage(messages.sprints)}
									isChecked={sprintsToggleValue}
									viewAs="Popup"
									onChange={handleSprintToggleChange}
									testId="calendar.ui.calendar-top-controls-bar.view-settings.show-sprints-toggle"
								/>
							)}
						</Section>
					)}
					<Section hasSeparator>
						<Inline
							xcss={[popupMarginStyles, rowSpaceStyles]}
							alignBlock="center"
							grow="fill"
							space="space.100"
							spread="space-between"
						>
							<label htmlFor="calendar-view-settings-color-by">
								{formatMessage(messages.colorBy)}
							</label>

							<Select
								id="calendar-view-settings-color-by"
								labelId="calendar-view-settings-color-by"
								onChange={handleColorByChange}
								isSearchable={false}
								options={colorByOptions}
								menuPortalTarget={getMenuPortalTarget()}
								// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
								styles={colorByMenuPortalStyles}
								value={colorByOptions.find((option) => option.value === colorBy)}
								isMulti={false}
								menuIsOpen={colorByOpen}
								onMenuOpen={() => setColorByOpen(true)}
								onMenuClose={() => setColorByOpen(false)}
								{...(fg('jira_view_settings_project_picker_dropdown') && { menuPlacement: 'auto' })}
							/>
						</Inline>
					</Section>
					<Section title={formatMessage(messages.weekSettings)} hasSeparator>
						<ViewSettingsToggle
							title={formatMessage(messages.showWeekends)}
							isChecked={showWeekends}
							viewAs="Popup"
							onChange={handleWeekendsToggleChange}
							testId="calendar.ui.calendar-top-controls-bar.view-settings.show-weekends-toggle"
						/>
						<Inline
							xcss={popupMarginStyles}
							alignBlock="center"
							grow="fill"
							space="space.100"
							spread="space-between"
						>
							<label htmlFor="calendar-view-settings-week-settings">
								{formatMessage(messages.weekStartsOn)}
							</label>

							{/* menuPortalTarget is set to Prevent mounting into menu, which will overflow */}
							<Select
								{...(fg('calendar_label_a11y_fix')
									? { inputId: 'calendar-view-settings-week-settings' }
									: { id: 'calendar-view-settings-week-settings' })}
								onChange={handleWeekStartsOnChange}
								isSearchable={false}
								options={weekStartsOnOptions}
								menuPortalTarget={getMenuPortalTarget()}
								// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
								styles={weekStartMenuPortalStyles}
								value={weekStartsOnOptions.find((option) => option.value === weekStartsOn)}
								isMulti={false}
								menuIsOpen={weekStartsOpen}
								onMenuOpen={() => setWeekStartsOpen(true)}
								onMenuClose={() => setWeekStartsOpen(false)}
								{...(fg('jira_view_settings_project_picker_dropdown') && { menuPlacement: 'auto' })}
							/>
						</Inline>
					</Section>

					{hasSharedReleases && (
						<Section title={formatMessage(messages.softwareReleaseDates)}>
							<Stack space="space.100" xcss={[popupMarginStyles, sharedReleasesContainerStyles]}>
								<Text size="small">
									<FormattedMessage
										{...messages.softwareReleaseDatesDescription}
										values={{
											a: (chunks) => (
												<Link
													onClick={() =>
														fireUIAnalytics(
															createAnalyticsEvent({}),
															'link clicked',
															'releaseInCalendarLearnMore',
														)
													}
													target="_blank"
													href="https://support.atlassian.com/jira-work-management/docs/surface-software-team-releases-in-business-team-calendars/"
													aria-describedby={softwareReleasesA11yDescriptionId}
												>
													{chunks}
												</Link>
											),
											t: (chunks) => (
												<Text id={softwareReleasesA11yDescriptionId} size="small">
													{chunks}
												</Text>
											),
										}}
									/>
								</Text>
								<UFOSegment name="calendar-connected-projects-picker">
									<JSErrorBoundary
										id="calendar-connected-projects-picker"
										packageName={packageName}
										teamName={teamName}
										fallback="flag"
									>
										<Placeholder
											name="calendar-connected-projects-picker"
											fallback={<Spinner interactionName="load" />}
										>
											<ConnectedProjectsPicker />
										</Placeholder>
									</JSErrorBoundary>
								</UFOSegment>
							</Stack>
						</Section>
					)}
					{weekViewEnabled && fg('jsd_shield_weekly_view_fast_follow') && (
						<Section title={formatMessage(messages.timeSettings)}>
							<ViewSettingsToggle
								title={formatMessage(messages.use24HourClock)}
								isChecked={showIn24HourClock}
								viewAs="Popup"
								onChange={handle24HourClockToggleChange}
								testId="calendar.ui.calendar-top-controls-bar.view-settings.time-setting-24-hour-toggle"
							/>
							<DayStartsAtOptions
								dayStartsAt={dayStartsAt}
								onDayStartsAtChange={onDayStartsAtChange}
								testId="calendar.ui.calendar-top-controls-bar.view-settings.time-setting-day-starts-at"
							/>
						</Section>
					)}
				</Box>
			</MenuGroup>
		</Box>
	);

	return (
		<JiraPopup
			messageType="transactional"
			messageId="calendar.ui.calendar-top-controls-bar.view-settings.jira-popup"
			isOpen={isOpen}
			onClose={handleClose}
			placement="bottom-end"
			content={() =>
				fg('software_calendar_quickstart_onboarding') ? (
					<ViewSettingsPanelSpotlightTarget>{content}</ViewSettingsPanelSpotlightTarget>
				) : (
					content
				)
			}
			trigger={({ ref, 'aria-controls': ariaControls }) => (
				<Box ref={ref} aria-controls={ariaControls}>
					<ViewSettingsButton
						isSelected={isOpen}
						onClick={handleClick}
						removeMargins
						ShowDI={Show}
						isThemedButton
					/>
				</Box>
			)}
		/>
	);
}

const DEFAULT_POPUP_OFFSET_TOP = 215;
const DROPDOWN_HEADER_HEIGHT = 52;

const popupStyles = xcss({
	maxHeight: `calc(100vh - ${DEFAULT_POPUP_OFFSET_TOP}px - ${DROPDOWN_HEADER_HEIGHT}px)`,
});

const menuContainerStyles = xcss({
	marginBottom: 'space.200',
});

const popupMarginStyles = xcss({
	paddingLeft: 'space.200',
	paddingRight: 'space.200',
	/**
	 * We will use a larger panel
	 */
	minWidth: '300px',
});

const rowSpaceStyles = xcss({
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
});

const sharedReleasesContainerStyles = xcss({
	maxWidth: '320px',
});
