import React, { useMemo } from 'react';
import FeedbackIcon from '@atlaskit/icon/glyph/feedback';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import {
	EntryKeys,
	ENTRYPOINT_ID_JIRA_PLATFORM,
} from '@atlassian/jira-feedback-collector/src/constants.tsx';
import type { FeedbackCollectorButtonProps } from '@atlassian/jira-feedback-collector/src/ui/button/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import normaliseUrl from '@atlassian/jira-platform-url-normalisation/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { CalendarScope } from '../../controllers/calendar-store/types.tsx';
import { APP_ID } from '../constants.tsx';
import messages from './messages.tsx';

const GiveFeedbackButtonIcon = () => <FeedbackIcon primaryColor={token('color.icon')} label="" />;

export type FeedbackButtonProps = {
	scope: CalendarScope;
	renderFeedbackButton: ((props: FeedbackCollectorButtonProps) => React.ReactNode) | undefined;
};

export const FeedbackButton = ({ scope, renderFeedbackButton }: FeedbackButtonProps) => {
	const { formatMessage } = useIntl();
	const { isAdmin, isSiteAdmin, cloudId } = useTenantContext();

	const feedbackContext = useMemo(
		() => [
			{
				id: EntryKeys.OPT_OUT,
				value: 'No',
			},
			{
				id: EntryKeys.FEEDBACK_COLLECTOR_LOCATION,
				value: APP_ID,
			},
			{
				id: EntryKeys.CLOUD_ID,
				value: cloudId,
			},
			{
				id: EntryKeys.LOCATION,

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				value: normaliseUrl(window?.location?.href ?? ''),
			},
			{
				id: EntryKeys.REFERER,

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				value: document.referrer ? normaliseUrl(document.referrer) : '',
			},
			{
				id: EntryKeys.USER_AGENT,

				value: fg('jfp-magma-undefined-navigator')
					? globalThis.navigator?.userAgent
					: navigator.userAgent, // eslint-disable-line jira/jira-ssr/no-unchecked-globals-usage
			},
			{
				id: EntryKeys.SCREEN_RESOLUTION,
				value: fg('jfp-magma-undefined-navigator')
					? `${globalThis.window?.screen?.width} x ${globalThis.window?.screen?.height}`
					: `${window.screen.width} x ${window.screen.height}`, // eslint-disable-line jira/jira-ssr/no-unchecked-globals-usage
			},
			{
				id: EntryKeys.IS_ADMIN,
				value: isAdmin.toString(),
			},
			{
				id: EntryKeys.IS_SITE_ADMIN,
				value: isSiteAdmin.toString(),
			},
			{
				id: 'CALENDAR_SCOPE',
				value: JSON.stringify(scope),
			},
		],
		[cloudId, isAdmin, isSiteAdmin, scope],
	);

	return (
		renderFeedbackButton?.({
			testId: 'calendar.common.feedback-collector-button.feedback-button',
			entrypointId: ENTRYPOINT_ID_JIRA_PLATFORM,
			feedbackTitle: formatMessage(messages.title),
			feedbackTitleDetails: formatMessage(messages.titleDescription),
			feedbackContext,
			icon: GiveFeedbackButtonIcon,
		}) ?? null
	);
};
