import React, { useCallback, useMemo, useState, useEffect, type SyntheticEvent } from 'react';
import { graphql, useFragment } from 'react-relay';
import Button from '@atlaskit/button/new';
import Pagination from '@atlaskit/pagination';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, type UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import type {
	issuesPaginator_calendar$key,
	issuesPaginator_calendar$data,
} from '@atlassian/jira-relay/src/__generated__/issuesPaginator_calendar.graphql';
import {
	useCalendarIsLoading,
	useIssuesPageCursor,
} from '../../../controllers/calendar-store/index.tsx';
import { LazyLoadIssues } from './LazyLoadIssues.tsx';
import messages from './messages.tsx';
import { PageInfo } from './page-info/index.tsx';

export type JiraScenarioIssueLikeConnection = issuesPaginator_calendar$data;
export type PageCursors = NonNullable<JiraScenarioIssueLikeConnection['pageCursors']>;
export type PageLike = NonNullable<NonNullable<PageCursors['around']>[0]>;

export type PaginationHandler = (cursor: string | null) => void;

/**
 * Higher-order function that wraps a callback function with page cursors.
 * @param callback - The callback function that receives the page cursors.
 * @returns A function that takes page cursors and returns the result of the callback function.
 */
function withPageCursors<T>(callback: (pageCursors: PageCursors) => T[]) {
	return (pageCursors: PageCursors | null | undefined): T[] => {
		if (!pageCursors) {
			return [];
		}
		return callback(pageCursors);
	};
}

/**
 * Retrieves the pages from the provided page cursors.
 * @param pageCursors - The page cursors object containing the first, around, and last cursors.
 * @returns An array of PageLike objects representing the pages.
 */
const getPages = withPageCursors((pageCursors: PageCursors): PageLike[] => {
	const { first, around, last } = pageCursors;
	return [first, ...(around ?? []), last].filter(Boolean);
});

/**
 * Retrieves an array of page numbers based on the provided page cursors.
 * @param pageCursors - The page cursors object containing information about the first, last, and around pages.
 * @returns An array of page numbers.
 */
const getPageNumbers = withPageCursors((pageCursors: PageCursors): number[] => {
	const { first, around, last } = pageCursors;
	const firstPageNumber = first?.pageNumber ?? around?.[0]?.pageNumber ?? 1;
	const lastPageNumber = last?.pageNumber ?? around?.[around.length - 1]?.pageNumber ?? 1;
	return Array.from(
		{ length: lastPageNumber - firstPageNumber + 1 },
		(_, i) => i + firstPageNumber,
	);
});

/**
 * Retrieves a page from an array of pages based on the specified page number.
 *
 * @param pages - An array of pages.
 * @param pageNumber - The page number to retrieve.
 * @returns The page with the specified page number, or null if not found.
 */
function getPage(pages: PageLike[], pageNumber: number): PageLike | null | undefined {
	return pages.find((page: PageLike) => page?.pageNumber === pageNumber);
}

/**
 * Custom hook for handling pagination logic.
 *
 * @param {JiraScenarioIssueLikeConnection} issueConnection - The issue connection object containing pagination data.
 * @param {PaginationHandler} onPaginate - The pagination handler function.
 * @returns {Object} - An object containing the pagination state and functions.
 */
export function usePagination(
	{
		pageInfo,
		pageCursors,
		issueNavigatorPageInfo,
		totalIssueSearchResultCount,
		isCappingIssueSearchResult,
	}: JiraScenarioIssueLikeConnection,
	onPaginate: PaginationHandler,
) {
	const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const issuesPageCursor = useIssuesPageCursor();

	const pages = useMemo(() => getPages(pageCursors), [pageCursors]);
	const pageNumbers: number[] = useMemo(() => getPageNumbers(pageCursors), [pageCursors]);

	const hasPages = fg('jira_calendar_load_more_pagination')
		? pageInfo?.hasNextPage
		: pageInfo?.hasNextPage || pageInfo?.hasPreviousPage;
	const firstPosition = issueNavigatorPageInfo?.firstIssuePosition ?? 0;
	const lastPosition = issueNavigatorPageInfo?.lastIssuePosition ?? 0;
	const totalCount = totalIssueSearchResultCount ?? 0;
	const isSearchResultsCapped = isCappingIssueSearchResult ?? false;

	useEffect(() => {
		if (!issuesPageCursor) {
			setSelectedIndex(0);
		}
	}, [issuesPageCursor]);

	const onPageChange = useCallback(
		(event: SyntheticEvent, pageNumber: number) => {
			const page = getPage(pages, pageNumber);
			if (page?.cursor) {
				onPaginate(pageNumber === 1 ? null : page?.cursor);
			}

			setSelectedIndex(pageNumber - 1);
		},
		[onPaginate, pages],
	);

	return {
		pages,
		pageNumbers,
		selectedIndex,
		firstPosition,
		lastPosition,
		totalCount,
		isSearchResultsCapped,
		hasPages,
		onPageChange,
	};
}

type Props = {
	onChange: (cursor: string | null) => void;
	issues: issuesPaginator_calendar$key;
};

/**
 * Renders the issues paginator component.
 *
 * @param onChange - The callback function to handle page change.
 * @param issues - The reference to the issue connection query ref.
 * @returns The rendered issues paginator component.
 */
export function IssuesPaginator({ onChange, issues }: Props) {
	const data = useFragment<issuesPaginator_calendar$key>(
		graphql`
			fragment issuesPaginator_calendar on JiraScenarioIssueLikeConnection
			@argumentDefinitions(pageSize: { type: "Int" }) {
				totalIssueSearchResultCount
				isCappingIssueSearchResult
				issueNavigatorPageInfo {
					firstIssuePosition
					lastIssuePosition
				}
				pageCursors(pageSize: $pageSize, maxCursors: 7) {
					first {
						pageNumber
						cursor
					}
					around {
						pageNumber
						cursor
					}
					last {
						pageNumber
						cursor
					}
				}
				pageInfo {
					hasNextPage
					hasPreviousPage
					endCursor
				}
				...LazyLoadIssues
			}
		`,
		issues,
	);

	const {
		pageNumbers,
		selectedIndex,
		firstPosition,
		lastPosition,
		totalCount,
		isSearchResultsCapped,
		hasPages,
		onPageChange,
	} = usePagination(data, onChange);
	const { formatMessage } = useIntl();
	const isLoading = useCalendarIsLoading();

	const onLoadMore = useCallback(
		(_: React.MouseEvent<HTMLButtonElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) => {
			if (data?.pageInfo?.endCursor) {
				onChange(data.pageInfo.endCursor);
				fireUIAnalytics(analyticsEvent, 'calendarLoadMore');
			}
		},
		[data?.pageInfo?.endCursor, onChange],
	);

	return (
		<Box
			testId="calendar.ui.calendar-renderer.issues-paginator.box"
			paddingBlock="space.050"
			xcss={hasPages ? containerWithPaginatorStyles : containerStyles}
		>
			{hasPages && (
				<>
					<Box xcss={pageInfoStyles}>
						<PageInfo
							testId="calendar.ui.calendar-renderer.issues-paginator.page-info"
							from={fg('jira_calendar_load_more_pagination') ? 1 : firstPosition}
							to={lastPosition}
							total={totalCount}
							hasSurpassedMaxCount={isSearchResultsCapped}
						/>
					</Box>
					<Box xcss={paginationStyles}>
						{fg('jira_calendar_load_more_pagination') ? (
							<>
								<Button
									testId="calendar.ui.calendar-renderer.issues-paginator.pagination"
									onClick={onLoadMore}
									isLoading={isLoading}
								>
									{formatMessage(messages.loadMore)}
								</Button>
								{fg('jira_calendar_lazy_load') && (
									<LazyLoadIssues onChange={onChange} queryRef={data} />
								)}
							</>
						) : (
							<Pagination
								testId="calendar.ui.calendar-renderer.issues-paginator.pagination"
								label="Page"
								nextLabel="Next"
								previousLabel="Previous"
								pageLabel="Page"
								selectedIndex={selectedIndex}
								pages={pageNumbers}
								max={7}
								onChange={onPageChange}
							/>
						)}
					</Box>
				</>
			)}
		</Box>
	);
}

const containerWithPaginatorStyles = xcss({
	display: 'grid',
	gridTemplateColumns: 'repeat(3, 1fr)',
	gridTemplateRows: 'auto',
	gridTemplateAreas: '". middle right"',
	height: '40px',
	alignItems: 'center',
	borderRadius: '0px 0px 2px 2px',
	backgroundColor: 'elevation.surface',
	border: `1px solid ${token('color.border', '#ebecf0')}`,
	borderTop: 'none',
});

const containerStyles = xcss({
	display: 'grid',
	gridTemplateColumns: 'repeat(3, 1fr)',
	gridTemplateRows: 'auto',
	gridTemplateAreas: '". middle right"',
	height: '40px',
});

const pageInfoStyles = xcss({
	gridArea: 'middle',
	justifySelf: 'center',
	alignSelf: 'center',
});

const paginationStyles = xcss({
	gridArea: 'right',
	justifySelf: 'end',
	paddingRight: 'space.100',
});
