import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	clear: {
		id: 'software-filters-popup.filter-section.section-header.clear',
		defaultMessage: 'Clear {fieldName}',
		description:
			"Label for the button to clear a certain field's selected filters on the filters popup",
	},
	clearOld: {
		id: 'software-filters-popup.filter-section.section-header.clear-old',
		defaultMessage: 'Clear',
		description:
			"Label for the button to clear a certain field's selected filters on the filters popup",
	},
});
