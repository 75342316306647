import { useEffect } from 'react';
import type {
	JiraViewScopeInput,
	JiraCalendarViewConfigurationInput,
	JiraCalendarIssuesInput,
} from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery.graphql';
import { useGlobalIssueCreateContext } from '../../../../controllers/global-issue-create-provider/index.tsx';
import { useCalendarConfigurationInput } from '../../../../controllers/use-calendar-configuration-input/index.tsx';
import { useCalendarScopeInput } from '../../../../controllers/use-calendar-scope-input/index.tsx';
import { useUnscheduledIssuesSearchInput } from '../../../../controllers/use-unscheduled-issues-search-input/index.tsx';

/**
 * Refetch unschedule panel when a global issue is created.
 *
 * @param projectKey - The project key.
 * @param refetch - The refetch function.
 */
export function useRefetchOnGlobalIssueCreate({
	refetch,
}: {
	refetch: (params: {
		scopeInput: JiraViewScopeInput;
		configurationInput: JiraCalendarViewConfigurationInput;
		unscheduledIssuesSearchInput: JiraCalendarIssuesInput;
	}) => void;
}) {
	const { scopeInput } = useCalendarScopeInput();
	const { configurationInput } = useCalendarConfigurationInput();
	const { unscheduledIssuesSearchInput } = useUnscheduledIssuesSearchInput();
	const { handleGlobalIssueCreate } = useGlobalIssueCreateContext();

	useEffect(() => {
		handleGlobalIssueCreate((_, issues) => {
			if (!issues?.length) {
				return;
			}

			const variables = {
				scopeInput,
				configurationInput,
				unscheduledIssuesSearchInput,
			};

			refetch(variables);
		});
	}, [
		handleGlobalIssueCreate,
		refetch,
		scopeInput,
		configurationInput,
		unscheduledIssuesSearchInput,
	]);
}
