/**
 * Filter out known WebSocket errors
 */
export const isKnownWebSocketError = (error: Error): boolean => {
	if (
		error.message.includes('CloseEvent') ||
		error.message.includes('Ping timeout') ||
		error.message.startsWith('Event: error')
	) {
		return true;
	}

	return false;
};
