/**
 * @generated SignedSource<<98ecd977417300abf601397d51e41d1f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type userFieldValueLabel_softwareFiltersPopup$data = {
  readonly user: {
    readonly name: string;
    readonly picture: AGG$URL;
  } | null | undefined;
  readonly " $fragmentType": "userFieldValueLabel_softwareFiltersPopup";
};
export type userFieldValueLabel_softwareFiltersPopup$key = {
  readonly " $data"?: userFieldValueLabel_softwareFiltersPopup$data;
  readonly " $fragmentSpreads": FragmentRefs<"userFieldValueLabel_softwareFiltersPopup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "userFieldValueLabel_softwareFiltersPopup",
  "selections": [
    {
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "name"
        },
        {
          "kind": "ScalarField",
          "name": "picture"
        }
      ]
    }
  ],
  "type": "JiraJqlUserFieldValue"
};

(node as any).hash = "21e1c2053e672eb2cd01e7e67977243d";

export default node;
